import axios, { CancelToken } from 'axios'

import { IThunkBaseAction } from '../../generic-state'
import IGetStoredComponentValue from '../Types/IGetStoredComponentValue'
import { displayErrorToaster } from '../../notifications'
import {
    storedOptionsDataFetchedAction,
    storedOptionsDataFetchFailedAction,
    storedOptionsFinishedLoadingForComponentAction,
    storedOptionsIsFetchingDataAction,
} from './StoredOptionsActions'
import { selectStoredOptionsComponentAllOptions } from './StoredOptionsSelectors'
import { getStoredOptionsRequestParameters } from '../Utilities/StoredOptionsQueryUtils'
import IStoredOptionsComponentQueryConfiguration from '../Types/IStoredOptionsComponentQueryConfiguration'
import IStoredOptionsOptionIdentifierObject from '../Types/IStoredOptionsOptionIdentifierObject'
import { queryOptions } from '../WebApi/StoredOptionsWebApi'
import IFetchStoredOptionsDataThunkOptions from '../Types/IFetchStoredOptionsDataThunkOptions'
import { getLogger } from '../../log'
import { getValueInSet } from '../../generic-utilities'
import { get, isEmpty } from 'lodash-es'

const Log = getLogger('stored-options.StoredOptionsThunks')

export const valuePickerWithOptionsDataFetchFailedThunk =
    (fetchErrorText: string, valuePickerId: string): IThunkBaseAction =>
    async (dispatch) => {
        dispatch(displayErrorToaster(fetchErrorText))

        dispatch(storedOptionsDataFetchFailedAction(valuePickerId))
    }

export const fetchStoredOptionsDataThunk =
    <TOption extends IStoredOptionsOptionIdentifierObject>(
        componentId: string,
        componentQueryConfiguration: IStoredOptionsComponentQueryConfiguration,
        getStoredComponentValue: IGetStoredComponentValue,
        {
            useCachedValues = false,
            shouldDataBeSavedToStore = true,
            itemIdField = 'Id',
        }: IFetchStoredOptionsDataThunkOptions = {},
        dynamicParameters?: Record<string, unknown>,
        dataFetchCancelToken?: CancelToken
    ): IThunkBaseAction<TOption[] | null> =>
    async (dispatch, getState) => {
        const useCache =
            useCachedValues &&
            isEmpty(componentQueryConfiguration.dependsOn) &&
            selectStoredOptionsComponentAllOptions(getState(), componentId).length > 0

        if (useCache) {
            return null
        }

        const {
            dependsOn,
            optionsEndPointUrl: url,
            fetchErrorText,
            limit,
            extraRows,
            additionalDataFetchParameters = {},
        } = componentQueryConfiguration

        dispatch(storedOptionsIsFetchingDataAction(componentId))

        try {
            const queryParams = getStoredOptionsRequestParameters(
                { getStoredComponentValue, dependsOn, limit, extraRows },
                dynamicParameters,
                additionalDataFetchParameters
            )

            const { ListData: options } = await queryOptions<TOption>(url, queryParams, dataFetchCancelToken)

            if (!options) {
                Log.error('ListData was null')
                return null
            }

            const idsOfOptionsAsSet = new Set(options.map((option) => get(option, itemIdField)))

            const selectedValues = getValueInSet(getStoredComponentValue(componentId)) as ReadonlySet<number>

            const allCurrentlySelectedValuesAreWithinTheItemsFetched = [...selectedValues].every((id) =>
                idsOfOptionsAsSet.has(id)
            )

            let uniqueAllOptions = options

            if (!allCurrentlySelectedValuesAreWithinTheItemsFetched) {
                const { ListData: currentlySelectedValueObjects } = await queryOptions<TOption>(
                    url,
                    { ...queryParams, Filters: { ...queryParams.Filters, Ids: [...selectedValues] } },
                    dataFetchCancelToken
                )

                if (!currentlySelectedValueObjects) {
                    Log.error('ListData was null')
                    return null
                }

                const idsOfFetchedValueObjectsAsSet = new Set(
                    currentlySelectedValueObjects.map((item) => get(item, itemIdField))
                )

                uniqueAllOptions = options
                    .filter((option) => !idsOfFetchedValueObjectsAsSet.has(get(option, itemIdField)))
                    .concat(currentlySelectedValueObjects)
            }

            if (shouldDataBeSavedToStore) {
                dispatch(storedOptionsDataFetchedAction(uniqueAllOptions, componentId))
            } else {
                dispatch(storedOptionsFinishedLoadingForComponentAction(componentId))
            }

            return uniqueAllOptions
        } catch (e) {
            if (!axios.isCancel(e)) {
                dispatch(valuePickerWithOptionsDataFetchFailedThunk(fetchErrorText, componentId))
            }

            return null
        }
    }
