import { TDataSourceItemId } from '../../data-source-types'
import { EUserGroup } from '../Types/IMessage'
import IMessageThread, { EMessageType } from '../Types/IMessageThread'
import { INewCommentForm } from '../Types/IMessagingState'
import INewComment from '../Types/INewComment'
import { PlanierTheme } from '../../theme/'

export const getCommentThreadColor = (thread: IMessageThread, { colors }: PlanierTheme): string => {
    if (thread.LatestMessage.CreatedByUserGroup === EUserGroup.Internal) {
        return colors.primaryCyan11
    }

    if (thread.LatestMessage.CreatedByUserGroup === EUserGroup.External) {
        return colors.secondaryYellow11
    }

    return colors.primaryChampion11
}

export const createNewCommentThreadsForEventIds = (
    form: INewCommentForm,
    EventIds: TDataSourceItemId[]
): { Data: INewComment }[] => {
    return [
        {
            Data: {
                Content: form.message ?? '',
                Type: EMessageType.Comment,
                EventIds,
                IsVisibleForEmployee: form.isVisibleForEmployee ?? false,
                IsVisibleForExternalUser: form.isVisibleForExternalUser ?? false,
            },
        },
    ]
}
