import { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { Button, Checkbox, Icon, NumberInput, ReadOnlyText } from '../../../generic-components'
import makeRequest, { createCancelToken, ERequestMethod } from '../../../rest-api'
import { Dropdown } from '../../../generic-components-dropdown'

import { Grid } from '@material-ui/core'
import IPricingDependencyConfiguration from './IPricingDependencyConfiguration'
import { useSalaryDependencies } from './usePricingDependencies'
import fetchTransactionPricing, { fetchSalaryRoundingPrecision } from './FetchTransactionPricing'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import { CancelTokenSource } from 'axios'
import { round } from '../../../generic-utilities'

interface IPrice {
    totalPrice: number
    pricingBasisId: number
    basisPrice: number
    persentage: number
    salaryEnabled: boolean
}

const GridWithMargin = styled(Grid)`
    margin-right: 25px;
`

const GridWithSmallMargin = styled(Grid)`
    margin-right: 8px;
`

const StyledContainerGrid = styled(Grid)``

const EditContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.primaryChampion11};
    padding: 12px 56px 12px 32px;
    margin: 8px -56px 12px -32px;
`

interface ITransactionPricePickerProps {
    onChange: (value: IPrice) => void
    value: IPrice | null
    dependencyConfiguration: IPricingDependencyConfiguration
    error?: string
    isInitialValueSet: boolean
}

interface ISalaryBasisIdentifier {
    Id: number
    Name: string
}

interface ISalaryBasisRequestResponse {
    ListData: ISalaryBasisIdentifier[]
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const OtherPricingBasisId = 3
const DefaultRoundingPrecision = 2

const TransactionSalaryPicker: React.FC<ITransactionPricePickerProps> = ({
    value,
    onChange,
    isInitialValueSet,
    dependencyConfiguration,
}) => {
    const dependencyValues = useSalaryDependencies(dependencyConfiguration)

    const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
    const [isIdentifierRequestLoading, setIsIdentifierRequestLoading] = useState<boolean>(false)
    const [pricingBasisIdentifiers, setpricingBasisIdentifiers] = useState<ISalaryBasisIdentifier[]>([])
    const [roundingPrecision, setRoundingPrecision] = useState<number>(DefaultRoundingPrecision)

    const [newPriceIsLoading, setNewPriceIsLoading] = useState<boolean>(false)

    const isLoading = newPriceIsLoading || isIdentifierRequestLoading

    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>()

    useEffect(() => {
        ;(async () => {
            const precision = await fetchSalaryRoundingPrecision()

            if (precision) {
                setRoundingPrecision(precision)
            }
        })()
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchPricingData = () => {
        const fetchData = async () => {
            if (isInitialValueSet) {
                return
            }

            setNewPriceIsLoading(true)

            if (cancelTokenSource) {
                cancelTokenSource.cancel()
            }

            const newTokenSource = createCancelToken()

            setCancelTokenSource(newTokenSource)

            const transactionPrice = await fetchTransactionPricing(dependencyValues, newTokenSource.token)

            if (transactionPrice) {
                const newValue = {
                    pricingBasisId: transactionPrice.salaryBasisId ?? OtherPricingBasisId,
                    totalPrice: transactionPrice.salaryPrice ?? 0,
                    basisPrice: transactionPrice.salaryBasePrice ?? 0,
                    persentage: transactionPrice.salaryPersentage ?? 0,
                    salaryEnabled: transactionPrice.isSalaryEnabled ?? false,
                }

                onChange(newValue)
            }

            setNewPriceIsLoading(false)
        }

        fetchData()
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchPricingData()
    }, [dependencyValues])

    const createTotalPriceWithCorrectPrecision = (persentage: number, basisPrice: number) => {
        return round((persentage / 100.0) * basisPrice, roundingPrecision)
    }

    const onSalaryEnabledChange = () => {
        const newTotalValue = {
            totalPrice: value?.totalPrice ?? 0,
            pricingBasisId: value?.pricingBasisId ?? OtherPricingBasisId,
            basisPrice: value?.basisPrice ?? 0,
            persentage: value?.persentage ?? 0,
            salaryEnabled: !value?.salaryEnabled,
        }

        onChange(newTotalValue)
    }

    const onPricePersentageChange = (newValue: number) => {
        const newTotalValue = {
            pricingBasisId: OtherPricingBasisId,
            totalPrice: createTotalPriceWithCorrectPrecision(newValue, value?.basisPrice ?? 0),
            basisPrice: value?.basisPrice ?? 0,
            persentage: newValue,
            salaryEnabled: value?.salaryEnabled ?? false,
        }
        onChange(newTotalValue)
    }

    const onBasisPriceChange = (newValue: number) => {
        const newTotalValue = {
            pricingBasisId: OtherPricingBasisId,
            totalPrice: createTotalPriceWithCorrectPrecision(value?.persentage ?? 0, newValue),
            basisPrice: newValue,
            persentage: value?.persentage ?? 0,
            salaryEnabled: value?.salaryEnabled ?? false,
        }
        onChange(newTotalValue)
    }

    const onPricingBasisChange = (newValue: number) => {
        const newTotalValue = {
            pricingBasisId: newValue,
            totalPrice: createTotalPriceWithCorrectPrecision(value?.persentage ?? 0, value?.basisPrice ?? 0),
            basisPrice: value?.basisPrice ?? 0,

            //If we change the pricing basis to users input value, change the persentage to 100
            persentage: newValue === OtherPricingBasisId ? 100 : value?.persentage ?? 0,
            salaryEnabled: value?.salaryEnabled ?? false,
        }

        onChange(newTotalValue)
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsIdentifierRequestLoading(true)

            try {
                const pricingResponse = await makeRequest<ISalaryBasisRequestResponse>({
                    url: 'palkka/Palkkaperuste/Identifier',
                    method: ERequestMethod.POST,
                    data: {},
                })

                if (pricingResponse && pricingResponse.ListData) {
                    setpricingBasisIdentifiers(pricingResponse.ListData)
                }
            } finally {
                setIsIdentifierRequestLoading(false)
            }

            //Set Price and selected pricing basis id
        }

        fetchData()
    }, [])

    const internalOnPricingBasisChange = (newValue: Set<number>) => {
        if (newValue.size !== 0) {
            const valueToSelect = newValue.values().next().value
            onPricingBasisChange(valueToSelect)
        }
    }

    const editIcon = isEditOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'

    return (
        <Container>
            <StyledContainerGrid container paddingBottom="12px" alignItems="flex-end">
                <GridWithMargin item>
                    <NumberInput value={value?.totalPrice ?? 0} label={'Palkka'} unit={'€'} disabled={true} />
                </GridWithMargin>
                <GridWithMargin paddingBottom="8px" item>
                    <Button variant="text" onClick={() => setIsEditOpen(!isEditOpen)} endIcon={editIcon}>
                        Muokkaa
                    </Button>
                </GridWithMargin>
                <GridWithMargin paddingBottom="8px" item>
                    <LoadingIndicatorInline disableShrink isLoading={isLoading} paddingLeft="0" size="25px" />
                </GridWithMargin>
            </StyledContainerGrid>
            <StyledContainerGrid container alignItems="center">
                <GridWithSmallMargin item>
                    <Checkbox value={value?.salaryEnabled ?? false} onClick={onSalaryEnabledChange} />
                </GridWithSmallMargin>
                <GridWithSmallMargin item>
                    <ReadOnlyText usage={'dropdown_label'}>Palkka hyväksytty</ReadOnlyText>
                </GridWithSmallMargin>
                <GridWithSmallMargin item>
                    <Icon
                        size={14}
                        colorTheme={'primary'}
                        tooltip={
                            'Poistamalla palkan hyväksynnän, toteumarivin palkkamäärä käsitellään nollana sen rivin arvosta huolimatta'
                        }
                    >
                        info_outlined
                    </Icon>
                </GridWithSmallMargin>
            </StyledContainerGrid>
            {isEditOpen ? (
                <EditContainer>
                    <div style={{ paddingBottom: '12px' }}>
                        <Dropdown<ISalaryBasisIdentifier>
                            defaultText={'Hinnoittelutapa'}
                            itemOptionLabelFields={['Name']}
                            itemValueLabelFields={['Name']}
                            textForMultipleSelected={''}
                            itemIdField={'Id'}
                            isLoading={isIdentifierRequestLoading}
                            multiselect={false}
                            disabled={true}
                            selectAction={internalOnPricingBasisChange}
                            items={pricingBasisIdentifiers}
                            values={new Set(value ? [value.pricingBasisId] : [OtherPricingBasisId])}
                            valueFieldType={'form'}
                        />
                    </div>
                    <StyledContainerGrid alignItems="flex-end" container>
                        <GridWithSmallMargin item>
                            <NumberInput
                                disabled={isLoading}
                                fireChangeOnBlur={true}
                                value={value?.basisPrice ?? 0}
                                label={'Määräytyminen'}
                                onChange={onBasisPriceChange}
                                unit={'€'}
                            />
                        </GridWithSmallMargin>
                        <GridWithSmallMargin item paddingBottom="14px">
                            <ReadOnlyText usage="h4">X</ReadOnlyText>
                        </GridWithSmallMargin>
                        <GridWithSmallMargin item>
                            <NumberInput
                                value={value?.persentage ?? 0}
                                unit={'%'}
                                label={''}
                                disabled={isLoading}
                                fireChangeOnBlur={true}
                                onChange={onPricePersentageChange}
                            />
                        </GridWithSmallMargin>
                        <GridWithSmallMargin item paddingBottom="14px">
                            <ReadOnlyText usage="h4">=</ReadOnlyText>
                        </GridWithSmallMargin>
                        <GridWithSmallMargin item>
                            <NumberInput value={value?.totalPrice ?? 0} label={''} disabled={true} unit={'€'} />
                        </GridWithSmallMargin>
                    </StyledContainerGrid>
                </EditContainer>
            ) : null}
        </Container>
    )
}

export default TransactionSalaryPicker
