import * as React from 'react'
import Skeleton from '@material-ui/core/Skeleton'
import styled from '@emotion/styled'
import { range } from 'lodash-es'

interface ICalendarLoadingGroupNodesProps {
    segmentAmount: number
}

const GroupNodeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    width: 200px;
`

const GroupNodeText = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 12px;
`

const GroupNodes = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    overflow: hidden;
`

const GroupNode = () => (
    <GroupNodeContainer>
        <Skeleton height="36px" variant="circular" width="36px" />
        <GroupNodeText>
            <Skeleton width="80%" />
            <Skeleton width="50%" />
        </GroupNodeText>
    </GroupNodeContainer>
)

const CalendarLoadingGroupNodes: React.FunctionComponent<ICalendarLoadingGroupNodesProps> = ({ segmentAmount }) => (
    <GroupNodes>
        {range(segmentAmount).map((segmentNumber) => (
            <GroupNode key={segmentNumber} />
        ))}
    </GroupNodes>
)
export default CalendarLoadingGroupNodes
