import IEvent, { EEventTypeCategory } from '../../event/Types/IEvent'
import { PlanierTheme } from '../../theme'
import { ColorOptions } from '../../theme/MepcoLightTheme'

interface IDayState {
    hasAnnualLeave: boolean
    hasEventGroup: boolean
    hasNotReservingGroup: boolean
    hasOtherNotWorktime: boolean
    hasOtherWorkTime: boolean
    hasWorktimePreference: boolean
    hasReservingGroup: boolean
    hasSickLeave: boolean
    hasTimeoffPreference: boolean
    hasWorkShiftEvent: boolean
}

export type EventTypeIndicator = {
    color: ColorOptions[keyof ColorOptions]
    icon?: string
    border?: boolean
}

export const parseDayState = (events: IEvent[]): IDayState => {
    const hasWorkShiftEvent = events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.Shift) > -1
    const hasWorktimePreference =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.WorktimePreference) > -1
    const hasTimeoffPreference =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.OfftimePreference) > -1
    const hasReservingGroup =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.ReservingEventGroup) > -1
    const hasNotReservingGroup =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.NonReservingEventGroup) > -1
    const hasEventGroup =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.EventGroup) > -1
    const hasOtherWorkTime =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.OtherWorktime) > -1
    const hasOtherNotWorktime =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.OtherNotWorktime) > -1
    const hasAnnualLeave =
        events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.AnnualLeave) > -1
    const hasSickLeave = events.findIndex((event) => event.EventTypeCategory?.Id === EEventTypeCategory.SickLeave) > -1

    return {
        hasAnnualLeave,
        hasEventGroup,
        hasNotReservingGroup,
        hasOtherNotWorktime,
        hasOtherWorkTime,
        hasWorktimePreference,
        hasReservingGroup,
        hasSickLeave,
        hasTimeoffPreference,
        hasWorkShiftEvent,
    }
}

const getEventTypeIndicatorMap = ({ colors }: PlanierTheme): Record<EEventTypeCategory, EventTypeIndicator> => ({
    [EEventTypeCategory.Shift]: {
        color: colors.primaryTeal120,
    },
    [EEventTypeCategory.OfftimePreference]: {
        color: colors.semanticWarning100,
        icon: 'cancel',
    },
    [EEventTypeCategory.WorktimePreference]: {
        color: colors.semanticSuccess100,
        icon: 'thumb_up',
    },
    [EEventTypeCategory.ReservingEventGroup]: {
        color: colors.neutralsGrey60,
    },
    [EEventTypeCategory.NonReservingEventGroup]: {
        color: colors.neutralsGrey60,
    },
    [EEventTypeCategory.EventGroup]: {
        color: colors.neutralsGrey60,
    },
    [EEventTypeCategory.OtherNotWorktime]: {
        color: colors.primaryTeal120,
        icon: 'BorderDashed',
    },
    [EEventTypeCategory.OtherWorktime]: {
        color: colors.primaryTeal120,
        icon: 'BorderSolid',
    },
    [EEventTypeCategory.AnnualLeave]: {
        color: colors.secondaryYellow60,
        border: true,
    },
    [EEventTypeCategory.SickLeave]: {
        color: colors.secondaryPink100,
    },
})

export const eventTypeIndicatorProps = (eventType: EEventTypeCategory, theme: PlanierTheme): EventTypeIndicator => {
    return (
        getEventTypeIndicatorMap(theme)[eventType] || {
            color: theme.colors.primaryCyan11,
        }
    )
}

export const getEventColor = (category: EEventTypeCategory, theme: PlanierTheme): ColorOptions[keyof ColorOptions] => {
    const { colors, componentExtensions } = theme

    switch (category) {
        case EEventTypeCategory.Shift:
            return colors.primaryTeal120
        case EEventTypeCategory.AnnualLeave:
            return colors.secondaryYellow60

        default:
            return componentExtensions.global.bgPrimary
    }
}

export const dayColorLogic = (dayState: IDayState): EEventTypeCategory => {
    const eventMapping: Record<string, EEventTypeCategory> = {
        hasWorkShiftEvent: EEventTypeCategory.Shift,
        hasAnnualLeave: EEventTypeCategory.AnnualLeave,
        hasSickLeave: EEventTypeCategory.SickLeave,
        hasOtherWorkTime: EEventTypeCategory.OtherWorktime,
        hasOtherNotWorktime: EEventTypeCategory.OtherNotWorktime,
    }

    for (const condition in eventMapping) {
        if ((dayState as any)[condition]) {
            return eventMapping[condition]
        }
    }

    return EEventTypeCategory.OtherNotWorktime
}
