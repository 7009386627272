import { createReducer } from 'typesafe-actions'
import { Action } from 'redux'
import moment from 'moment'

import { IDataSourceItem, TDataSourceItemId } from '../../data-source-types'
import IDataSourceReducerState from '../Types/IDataSourceReducerState'
import IDataSource, { TDataSourceId } from '../Types/IDataSource'
import {
    dataSourceOverrideFetchParametersRemoveAction,
    dataSourceOverrideFetchParametersSetAction,
    dataSourceReplaceItemAction,
    dataSourceResetDataAction,
    dataSourceResetValuePickerIdsAction,
    dataSourceSetAllFetchRequestRelatedDataAction,
    dataSourceSetDataAction,
    dataSourceSetFetchFiltersParametersAction,
    dataSourceSetFetchParametersAction,
    dataSourceSetOffsetAction,
    dataSourceSetSortSettingsAction,
    dataSourceSetValuePickerIdsAction,
    dataSourcesSetValuePickerIdsAction,
    initialiseDataSourceAction,
} from './DataSourceActions'
import { DEFAULT_OVERRIDE_REQUEST_PARAMETERS } from '../Constants/DataSourceQueryConstants'
import { getLogger } from '../../log'

const Log = getLogger('data-source.DataSourceReducer')

const INITIAL_STATE = new Map()

export const SINGLE_DATA_SOURCE_INITIAL_STATE = {
    accumulatedData: [],
    orderedItemIds: [],
    data: new Map(),
    configuration: null,
    valuePickerIds: [],
    fetchParameters: {},
    fetchFiltersParameters: {},
    groupData: [],
    isDataFetched: false,
    timestamp: null,
    sortSettings: new Map(),
    offset: 0,
    overrideFetchParameters: DEFAULT_OVERRIDE_REQUEST_PARAMETERS,
    dataStatus: null,
}

const objectIsNotTruncated = (obj: IDataSourceItem) => {
    return Object.keys(obj).length > 2
}

const logUninitialisedWarning = (action: Action, dataSourceId: TDataSourceId) =>
    Log.warn(`Action ${action.type} dispatched to an uninitialised data source ${dataSourceId}`)

const dataSourceReducer = createReducer<IDataSourceReducerState>(INITIAL_STATE)
    .handleAction(initialiseDataSourceAction, (state, action) => {
        const configuration = action.payload
        const dataSource = { ...SINGLE_DATA_SOURCE_INITIAL_STATE, configuration }

        return new Map(state).set(configuration.Id, dataSource)
    })
    .handleAction(dataSourceSetDataAction, (state, action) => {
        const { dataSourceId, accumulatedData, orderedItemIds, data, groupData, dataStatus } = action.payload

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const newData = new Map<TDataSourceItemId, IDataSourceItem>(data)

        newData.forEach((value, key) => {
            if (objectIsNotTruncated(value)) {
                return
            }
            const keyValue = dataSource.data.get(key)
            if (keyValue !== undefined) {
                newData.set(key, keyValue)
            }
        })

        const newDataSource: IDataSource = {
            ...dataSource,
            accumulatedData,
            data: newData,
            orderedItemIds,
            groupData,
            isDataFetched: true,
            dataStatus,
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourceReplaceItemAction, (state, action) => {
        const dataSourceId = action.meta

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const { item, previousId } = action.payload

        const { data, orderedItemIds } = dataSource
        const newData = new Map(data)
        let newOrderedItemIds = [...orderedItemIds]

        if (previousId !== item.Id) {
            // If the ID of the item changed,
            // remove the previous item before adding the new item...
            newData.delete(previousId)

            // ...and replace the previous ID with the new ID...
            newOrderedItemIds = orderedItemIds.map((itemId) => (itemId !== previousId ? itemId : item.Id))
        }

        newData.set(item.Id, item)

        const newDataSource = {
            ...dataSource,
            data: newData,
            orderedItemIds: newOrderedItemIds,
            timestamp: moment().unix(),
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourceSetValuePickerIdsAction, (state, action) => {
        const dataSourceId = action.meta

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const newValuePickerIds = action.payload
        const existingValuePickerIds = dataSource.valuePickerIds
        const uniqueCombinedValuePickerIds = new Set([...newValuePickerIds, ...existingValuePickerIds])

        const newDataSource = {
            ...dataSource,
            valuePickerIds: [...uniqueCombinedValuePickerIds],
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourcesSetValuePickerIdsAction, (state, action) => {
        const dataSourceIds = action.meta

        const initializedDataSources = dataSourceIds.filter((id) => state.get(id))

        if (initializedDataSources.length !== dataSourceIds.length) {
            const nonInitializedDataSources = dataSourceIds.filter((id) => !state.get(id))
            nonInitializedDataSources.forEach((id) => logUninitialisedWarning(action, id))

            if (initializedDataSources.length === 0) {
                return state
            }
        }

        const newDataSourcesState = new Map(state)
        const newValuePickerIds = action.payload

        initializedDataSources.forEach((dataSourceId) => {
            const dataSource = state.get(dataSourceId) as IDataSource
            const existingValuePickerIds = dataSource.valuePickerIds

            const uniqueCombinedValuePickerIds = new Set([...newValuePickerIds, ...existingValuePickerIds])

            const newDataSource = {
                ...dataSource,
                valuePickerIds: [...uniqueCombinedValuePickerIds],
            }

            newDataSourcesState.set(dataSourceId, newDataSource)
        })

        return newDataSourcesState
    })
    .handleAction(dataSourceSetFetchFiltersParametersAction, (state, action) => {
        const dataSourceId = action.meta

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const fetchParameters = action.payload

        const newDataSource = {
            ...dataSource,
            fetchFiltersParameters: fetchParameters,
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourceSetAllFetchRequestRelatedDataAction, (state, action) => {
        const dataSourceId = action.meta

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const {
            allFetchRequestRelatedData: {
                valuePickerIds,
                fetchParametersForRootOfTheBody,
                fetchParametersForFilters,
                fetchParametersForSortSettings,
            },
        } = action.payload

        const newDataSource = {
            ...dataSource,
            valuePickerIds,
            fetchParameters: fetchParametersForRootOfTheBody,
            fetchFiltersParameters: fetchParametersForFilters,
            sortSettings: fetchParametersForSortSettings,
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourceSetSortSettingsAction, (state, action) => {
        const dataSourceId = action.meta

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const { sortType, sortByParameter } = action.payload
        const { sortSettings } = dataSource

        const newSortSettings = new Map(sortSettings)
        const alreadySortedByThisParameter = newSortSettings.has(sortByParameter)

        // make sure the keys are in the same order as how the user has ordered them
        if (alreadySortedByThisParameter) {
            newSortSettings.delete(sortByParameter)
        }

        if (sortType) {
            newSortSettings.set(sortByParameter, sortType)
        }

        const newDataSource = {
            ...dataSource,
            sortSettings: newSortSettings,
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourceSetFetchParametersAction, (state, action) => {
        const dataSourceId = action.meta

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const fetchParameters = action.payload

        const newDataSource = {
            ...dataSource,
            fetchParameters,
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourceResetDataAction, (state, action) => {
        const dataSourceId = action.payload

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const newData = new Map()

        const newDataSource = {
            ...dataSource,
            data: newData,
            groupData: [],
            orderedItemIds: [],
            isDataFetched: false,
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourceSetOffsetAction, (state, action) => {
        const dataSourceId = action.meta

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const { offset } = action.payload

        const newDataSource = {
            ...dataSource,
            offset,
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourceOverrideFetchParametersSetAction, (state, action) => {
        const dataSourceId = action.meta

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const overrideFetchParameters = action.payload

        const newDataSource = {
            ...dataSource,
            overrideFetchParameters,
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourceOverrideFetchParametersRemoveAction, (state, action) => {
        const dataSourceId = action.payload

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const newDataSource = {
            ...dataSource,
            overrideFetchParameters: DEFAULT_OVERRIDE_REQUEST_PARAMETERS,
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })
    .handleAction(dataSourceResetValuePickerIdsAction, (state, action) => {
        const dataSourceId = action.payload

        const dataSource = state.get(dataSourceId)
        if (!dataSource) {
            logUninitialisedWarning(action, dataSourceId)
            return state
        }

        const newDataSource = {
            ...dataSource,
            valuePickerIds: [],
        }

        return new Map(state).set(dataSourceId, newDataSource)
    })

export default dataSourceReducer
