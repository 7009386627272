import { AutoSizer, CellMeasurer, CellMeasurerCache, List as VirtualizedList, ListRowProps } from 'react-virtualized'
import * as React from 'react'
import styled from '@emotion/styled'

import {
    ISelectRowsFunction,
    TAllValues,
    TNewlySelected,
    TRowTextComponent,
    TSelectedValues,
} from '../../../Interfaces'
import MenuContentRow from './MenuContentRow'
import NoContentText from './NoContentText'

// There seems to be some issues with the VirtualizedList's own typings so cast to any here
const StyledVirtualizedList = styled<any>(VirtualizedList)`
    border-top: none;
    background-color: ${({ theme }) => theme.colors.neutralsWhite100};
    outline: 0;
`

const MASS_SELECTION_ROW_COUNT = 1

interface IMenuContentListProps<T> {
    listHeight: number
    itemIdField: keyof T
    itemOptionLabelFields: (keyof T)[]
    items: T[]
    selectAction: ISelectRowsFunction<T>
    selectAllAction: ISelectRowsFunction<boolean>
    selectedNewItems: TNewlySelected<T>
    values: TAllValues<T>
    selectedValueItems: TSelectedValues<T>
    selectValueRowAction: ISelectRowsFunction<T>
    RowTextComponent: TRowTextComponent<T>
    multiselect: boolean
    componentWidth: number
    fullWidth: boolean
    isLoading: boolean
    isReadOnly?: boolean
}
const DEFAULT_ROW_HEIGHT = 50

export default class MenuContentList<T> extends React.Component<IMenuContentListProps<T>> {
    private _cache: CellMeasurerCache

    rowRenderer = ({ key, parent, index, style }: ListRowProps): JSX.Element => {
        return (
            <CellMeasurer cache={this._cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                <MenuContentRow {...this.props} index={index} style={style} />
            </CellMeasurer>
        )
    }

    private initialiseCache() {
        this._cache = new CellMeasurerCache({
            fixedWidth: true,
            minHeight: DEFAULT_ROW_HEIGHT,
        })
    }

    private noRowsRenderer = () => {
        return <NoContentText isLoading={this.props.isLoading} />
    }

    render(): React.ReactNode {
        this.initialiseCache()

        const { items, listHeight, multiselect, componentWidth, fullWidth } = this.props

        let totalFilteredRowsCount = items.length

        if (multiselect && items.length > 0) {
            totalFilteredRowsCount += MASS_SELECTION_ROW_COUNT
        }

        return (
            <AutoSizer disableHeight>
                {({ width }) => (
                    <StyledVirtualizedList
                        deferredMeasurementCache={this._cache}
                        height={listHeight}
                        noRowsRenderer={this.noRowsRenderer}
                        rowCount={totalFilteredRowsCount}
                        rowHeight={this._cache.rowHeight}
                        rowRenderer={this.rowRenderer}
                        width={fullWidth ? width : componentWidth}
                    />
                )}
            </AutoSizer>
        )
    }
}
