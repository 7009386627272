import * as Yup from 'yup'

export const PASSWORD_MODAL_ID = 'password-change.PasswordChangeForm'
export const PASSWORD_CHANGE_IN_PROGRESS_OPERATION_ID = 'legacy-newer.PasswordChangeInProgress'

/**
 * Checks that there are 12-32 characters, at least one number or special character, and at least one capital letter.
 */
export const passwordAllowedRegex = new RegExp(
    /(?=^.{12,32}$)(?=.*?[A-Z].*?)(?=.*?[a-z].*?)(?=.*?\d.*?)(?<Password>.*)/
)

export const passwordResetFormValidationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('password-change.ValidationError.ValueMissing'),
    newPassword1: Yup.string()
        .required('password-change.ValidationError.ValueMissing')
        .matches(passwordAllowedRegex, 'password-change.ValidationError.NewPasswordsNoMatchRequirements'),
    newPassword2: Yup.string()
        .required('password-change.ValidationError.ValueMissing')
        .matches(passwordAllowedRegex)
        .oneOf([Yup.ref('newPassword1')], 'password-change.ValidationError.NewPasswordsNoMatch'),
})
