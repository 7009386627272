import { useDispatch, useSelector } from 'react-redux'
import * as React from 'react'

import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'

import ColumnManagementModalButton from './ColumnManagementModalButton'
import EConfigurableListModal from '../../Types/EConfigurableListModal'
import {
    selectColumnSortSettings,
    selectColumnWidths,
    selectIsColumnManagementAllowed,
    selectIsListSelectable,
    selectListConfigurationForColumns,
    selectPinnedColumns,
} from '../../State/ConfigurableListSelectors'
import MassSelectionCheckbox from './MassSelectionCheckbox'
import { openModalAction } from '../../../modal'
import RowHeaderElement from './RowHeaderElement'
import { ROW_HEIGHT } from '../../Constants/RowConstants'
import ColumnEditControls from './ColumnEditControls'
import { remToPx } from '../../../generic-utilities'
import { difference, isEmpty, isNil } from 'lodash-es'
import IListViewColumnModel from '../../../configurable-list/interfaces/IListViewColumnModel'

type IOwnProps = {
    isLoadingInitialData: boolean
    listId: string
}

const Container = styled.tr`
    height: ${ROW_HEIGHT}px;
    padding-left: 18px;
    display: inline-flex;
    top: 0;
    min-width: 100%;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.neutralsWhite100};
`

const CheckboxContainer = styled.div`
    margin-right: 24.5px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
`

const CheckboxContainerOuter = styled.th<{ pinned?: boolean }>`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.neutralsWhite100};

    ${({ pinned }) =>
        pinned &&
        `
        position: sticky;
        left: -1px;
        z-index: 1;
    `}
`

const RowHeadersContainer = styled.div<{ checkboxVisible?: boolean }>`
    display: flex;
    flex: 1 1 100%;
    margin-left: ${({ checkboxVisible }) => (!checkboxVisible ? '12px' : 0)};
`

const PinnedColumnsContainer = styled.div<{ checkboxVisible?: boolean }>`
    display: flex;
    position: sticky;
    z-index: 1;
    margin-right: 5px;
    border-right: 1px solid ${({ theme }) => theme.colors.neutralsGrey30};
    background-color: ${({ theme }) => theme.colors.neutralsWhite100};
    left: ${({ checkboxVisible }) => (!checkboxVisible ? 30 : 38)}px;
`

const TH = styled.th`
    display: flex;
    width: 100%;
    padding: 0;
`

/**
 * Renderöi listan sarakkeiden otsikot.
 */
const RowHeaders = ({ isLoadingInitialData, listId }: IOwnProps): JSX.Element => {
    const dispatch = useDispatch()

    const sortBy = useSelector((state: RootState) => selectColumnSortSettings(state, listId))
    const columnManagementAllowed = useSelector((state: RootState) => selectIsColumnManagementAllowed(state, listId))
    const columnMetadata = useSelector((state: RootState) => selectListConfigurationForColumns(state, listId))
    const massSelectable = useSelector((state: RootState) => selectIsListSelectable(state, listId))
    const columnWidths = useSelector((state: RootState) => selectColumnWidths(state, listId))
    const pinnedColumns = useSelector((state: RootState) => selectPinnedColumns(state, listId))

    const hasPinnedColumns = !isEmpty(pinnedColumns)

    const handleColumnManagementButtonClick = (): void => {
        dispatch(openModalAction(`${EConfigurableListModal.ColumnManagement}-${listId}`))
    }

    const renderColumns = (columns: IListViewColumnModel[], isPinned = false) => {
        return columns.map((column, index) => {
            const { Id, Header, HeaderComponent, Visible, Width, SortByParameter, Tooltip } = column
            const sortOrderForColumn = sortBy.get(SortByParameter)

            if (!Visible) {
                return null
            }

            const defaultWidth = remToPx(Width)
            const columnWidth = columnWidths?.[Id] || defaultWidth

            return (
                <ColumnEditControls
                    key={Id}
                    id={Id}
                    listId={listId}
                    defaultWidth={defaultWidth}
                    sortByParameter={SortByParameter}
                    isIcon={HeaderComponent?.ComponentId === 'Icon' || Header === ''}
                    isLastPinned={isPinned && index === columns.length - 1}
                >
                    <RowHeaderElement
                        headerComponent={HeaderComponent}
                        id={Id}
                        listId={listId}
                        sortByParameter={SortByParameter}
                        sortOrder={sortOrderForColumn}
                        text={Header}
                        tooltip={Tooltip}
                        width={columnWidth}
                        isDefaultWidth={isNil(columnWidths?.[Id])}
                    />
                </ColumnEditControls>
            )
        })
    }

    return (
        <Container>
            {massSelectable && (
                <CheckboxContainerOuter pinned={hasPinnedColumns}>
                    <CheckboxContainer>
                        <MassSelectionCheckbox isLoading={isLoadingInitialData} listId={listId} />
                    </CheckboxContainer>
                </CheckboxContainerOuter>
            )}

            <TH>
                {hasPinnedColumns && (
                    <PinnedColumnsContainer checkboxVisible={massSelectable}>
                        {renderColumns(pinnedColumns, true)}
                    </PinnedColumnsContainer>
                )}
                <RowHeadersContainer checkboxVisible={massSelectable}>
                    {renderColumns(difference(columnMetadata, pinnedColumns))}
                </RowHeadersContainer>
            </TH>

            {columnManagementAllowed && <ColumnManagementModalButton onClick={handleColumnManagementButtonClick} />}
        </Container>
    )
}

export default RowHeaders
