import { FieldProps } from 'formik'
import * as React from 'react'
import moment from 'moment'

import TranslatedDatepicker, { ITranslatedDatepickerProps } from './TranslatedDatepicker'

export interface IFormikDatepickerOwnProps extends ITranslatedDatepickerProps, FieldProps {}

class FormikDatepicker extends React.Component<IFormikDatepickerOwnProps> {
    private onChange = (date: any) => {
        if (!date) {
            return
        }

        this.props.form.setFieldValue(this.props.field.name, moment(date))
    }

    render(): React.ReactNode {
        const {
            field: { value, ...field },
            ...passProps
        } = this.props

        return <TranslatedDatepicker {...field} selected={value} {...passProps} onChange={this.onChange} />
    }
}

export default FormikDatepicker
