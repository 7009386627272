import { FC } from 'react'
import styled from '@emotion/styled'
import { Icon, ReadOnlyText } from '../../../generic-components'
import { light_grey_110 } from 'constants/Styles'
import { useAppTheme } from '../../../theme/'

interface IHeaderProps {
    title?: string
    renderCancel?: boolean
    onClose?: () => void
}

const TitleContainer = styled.div`
    display: flex;
    padding-left: 32px;
    padding-right: 20px;
    height: 70px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
    user-select: none;
`

const PaddedIcon = styled.div`
    padding: 12px;
    padding-top: 16px;
    justify-content: center;
    align-items: center;
    &:hover {
        cursor: pointer;
        background-color: ${light_grey_110};
    }
    border-radius: 4px;
    z-index: 1;
`

const Header: FC<IHeaderProps> = ({ title, renderCancel, onClose }) => {
    const theme = useAppTheme()

    return (
        <TitleContainer data-testid="STC_FORM_HEADER">
            <ReadOnlyText rawColor={theme.componentExtensions.text.primary} usage={'h2'} testId="STC_FORM_TITLE">
                {title ?? ''}
            </ReadOnlyText>
            {renderCancel ? (
                <PaddedIcon onClick={onClose} data-testid="STC_FORM_CLOSE">
                    <Icon size={24}>close</Icon>
                </PaddedIcon>
            ) : null}
        </TitleContainer>
    )
}

export default Header
