import * as React from 'react'
import { Fragment, Suspense, useCallback } from 'react'
import ValuePickerStateless from '../ValuePicker/ValuePickerStateless'
import { SideEffect } from '../../../types'
import { setValuePickerValueAction } from '../../State/ValuePickerActions'
import { useDispatch, useSelector } from 'react-redux'
import { isArray, isEmpty, isNil, isSet } from 'lodash-es'
import ITogglePickerConfiguration from '../../types/ITogglePickerConfiguration'
import { TValuePickerConfiguration } from '../../types/TValuePickerConfiguration'
import { selectValuePickerValue, ValuePicker } from '../../index'
import { RootState } from 'typesafe-actions'
import { ValuePickerKey, valuePickerMap } from './valuePickerMap'
import EValuePickerType from '../../types/EValuePickerType'
import ValuePickerComponentTooltip from './ValuePickerComponentTooltip'
import styled from '@emotion/styled'

export type ValuePickerConfiguration = {
    ValuePickerType: EValuePickerType
}

type Props = {
    configuration: ValuePickerConfiguration
    valuePickerId: string
    valuePickerComponentProperties: any
    [key: string]: any
    value?: any
    required?: boolean
    isRequired?: boolean
    isReadOnly?: boolean
    useCachedValuesForOptions?: boolean
    dropdownWidth?: number | null
    dropdownMenuWidth?: number | null
    isInitialValueSet?: boolean
    label?: string
    Label?: string
    contextId?: string
    context?: 'form' | 'list' | 'unknown'
    notes?: string
    error?: string
    onChange?: (value: any) => void
    fallbackComponent?: 'ValuePickerStateless' | 'ValuePicker'
    tooltip?: string | null
}

const Container = styled.div`
    display: flex;
    align-items: flex-end;
`

const ValuePickerComponent = ({
    configuration,
    fallbackComponent = 'ValuePickerStateless',
    ...props
}: Props): JSX.Element | null => {
    const { onChange, valuePickerId, contextId, value, valuePickerComponentProperties, label, Label } = props

    const dispatch = useDispatch()

    const valueFromStore = useSelector((state: RootState) => selectValuePickerValue(state, valuePickerId, contextId))
    const valueToUse = value ?? valueFromStore

    const handleBooleanChange = useCallback(() => {
        const currentValue = valueToUse as boolean
        const toggleConfigs = configuration as ITogglePickerConfiguration

        const booleanSideEffects = !currentValue
            ? toggleConfigs.TrueValueSideEffects
            : toggleConfigs.FalseValueSideEffects

        dispatch(setValuePickerValueAction(!currentValue, valuePickerId, contextId, booleanSideEffects))
    }, [configuration, contextId, dispatch, valueToUse, valuePickerId])

    const handleOnChange = useCallback(
        (changedValue: any, sideEffects?: SideEffect[]) => {
            onChange && onChange(valuePickerId)

            if (typeof valueToUse === 'boolean') {
                handleBooleanChange()
                return
            }

            const isArrayLike = isSet(changedValue) || isArray(changedValue)

            const newValue = isNil(changedValue) || (isArrayLike && isEmpty(changedValue)) ? null : changedValue

            dispatch(setValuePickerValueAction(newValue, valuePickerId, contextId, sideEffects))
        },
        [onChange, valuePickerId, valueToUse, dispatch, contextId, handleBooleanChange]
    )

    const valuePickerKey = configuration.ValuePickerType as ValuePickerKey

    if (valuePickerKey === EValuePickerType.Hidden) {
        return null
    }

    const Component = valuePickerMap[valuePickerKey]

    if (!Component) {
        const Fallback = fallbackComponent === 'ValuePickerStateless' ? ValuePickerStateless : ValuePicker

        return (
            <Fallback
                {...configuration}
                {...props}
                valueFromStore={valueToUse}
                ValuePickerId={valuePickerId}
                configuration={configuration as TValuePickerConfiguration}
            />
        )
    }

    const ComponentContainer = props.tooltip ? Container : Fragment

    return (
        <ComponentContainer>
            <Suspense fallback={null}>
                <Component
                    {...configuration}
                    {...props}
                    {...valuePickerComponentProperties}
                    label={label ?? Label}
                    value={valueToUse}
                    onChange={handleOnChange}
                />
            </Suspense>

            {props.tooltip && <ValuePickerComponentTooltip tooltip={props.tooltip} isMultiline={props.Multiline} />}
        </ComponentContainer>
    )
}

export default ValuePickerComponent
