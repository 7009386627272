import { JSX, useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { selectMessageRecipients } from '../State/MessagingSelectors'
import { IEmployeeIdentifier } from '../Types/IMessageThread'
import { setSelectedRecipients } from '../State/MessagingReducer'
import { useQuery } from '../../generic-utilities'
import { Chip, ChipWithPopover, Dropdown, Icon, IFilterChipOption } from '../../generic-components'
import { translate } from '../../localization'
import { debounce, drop, isEmpty, take, uniqBy } from 'lodash-es'
import { ERequestMethod } from '../../rest-api'

const VISIBLE_RECIPIENTS_AMOUNT = 10

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    padding-left: 32px;
`

const RecipientChipContainer = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 20px;
`

const OverflowRecipientsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 140px;
`

const OverflowRecipient = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
`

const RecipientPicker = (): JSX.Element => {
    const dispatch = useDispatch()

    const [filter, setFilter] = useState<string>('')
    const setFilterDebounced = debounce((searchTerm: string) => setFilter(searchTerm), 600)

    const { data } = useQuery<{ ListData: IEmployeeIdentifier[] }>(
        '/Tyontekija/Identifier',
        ERequestMethod.POST,
        {
            ExtraRows: 'Omit',
            Limit: 250,
            Filters: { Vapaatekstihaku: filter },
        },
        [filter]
    )

    const recipients = useSelector(selectMessageRecipients)
    const recipientIds = recipients.map(({ Id }) => Id)

    const visibleRecipients = take(recipients, VISIBLE_RECIPIENTS_AMOUNT)
    const overflowRecipients = drop(recipients, VISIBLE_RECIPIENTS_AMOUNT)

    const handleOnChange = (values: number[]) => {
        const filterById = (dataset: IEmployeeIdentifier[]) => dataset.filter(({ Id }) => values.includes(Id))

        const newValues = filterById(data?.ListData || [])
        const existingValues = filterById(recipients)

        dispatch(setSelectedRecipients(uniqBy([...existingValues, ...newValues], 'Id')))
    }

    const handleDelete = (employeeId: number) => {
        const newValues = recipients.filter(({ Id }) => Id !== employeeId)

        dispatch(setSelectedRecipients(newValues))
    }

    const overflowChip = !isEmpty(overflowRecipients) && (
        <ChipWithPopover label={`+ ${overflowRecipients.length} ${translate('tyontekijat-definite')}`}>
            <OverflowRecipientsContainer>
                {overflowRecipients.map(({ Name, Id }) => (
                    <OverflowRecipient key={`overflow-recipient-${Id}`}>
                        <span>{Name}</span>
                        <button onClick={() => handleDelete(Id)}>
                            <Icon>clear</Icon>
                        </button>
                    </OverflowRecipient>
                ))}
            </OverflowRecipientsContainer>
        </ChipWithPopover>
    )

    const recipientChips = !isEmpty(visibleRecipients) && (
        <RecipientChipContainer>
            {visibleRecipients.map(({ Name, Id }) => (
                <Chip key={Name} label={Name} variant="filled" showDeleteIcon onEndIconClick={() => handleDelete(Id)} />
            ))}

            {overflowChip}
        </RecipientChipContainer>
    )

    return (
        <Container>
            <div>
                <Dropdown
                    label={translate('tyontekija')}
                    placeholder={translate('select')}
                    value={recipientIds}
                    options={(data?.ListData || []) as IFilterChipOption[]}
                    onChange={handleOnChange}
                    onSearchTermChange={setFilterDebounced}
                    itemOptionLabelFields={['CompoundName']}
                    isMultiselect
                    useFilter
                    autoFocusFilter
                />
            </div>

            {recipientChips}
        </Container>
    )
}

export default RecipientPicker
