import * as React from 'react'
import styled from '@emotion/styled'

import Styles from 'constants/Styles'

interface ICalendarSegmentProps {
    backgroundShading: boolean
    children: React.ReactNodeArray
}

const SegmentContainer = styled.div`
    height: 30px;
    flex-direction: row;

    position: relative;
    align-items: center;
    background-color: ${({ backgroundShading }: ICalendarSegmentProps) =>
        backgroundShading ? Styles.calendar.segmentBackgroundShading : Styles.calendar.segmentBackgroundWhite};
`

/**
 * Calendar row or column.
 */
const CalendarSegment: React.FunctionComponent<ICalendarSegmentProps> = ({ backgroundShading, children }) => (
    <SegmentContainer backgroundShading={backgroundShading}>{children}</SegmentContainer>
)

export default CalendarSegment
