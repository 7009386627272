import { createAsyncThunk } from '@reduxjs/toolkit'
import IMessageThread from '../Types/IMessageThread'
import IMessage from '../Types/IMessage'
import { setSelectedEvents, setViewedMessageThread } from './MessagingReducer'
import { displayErrorToaster, displaySuccessToaster } from '../../notifications'
import { IEvent } from '../../event'
import {
    fetchCommentsForEvents,
    fetchMessagesForThread,
    fetchMessageThreadsForEmployees,
    patchMessageThreadStatus,
    postNewComment,
    postNewMessageThread,
    replyToThread,
} from '../Api/MessagingApi'
import {
    createNewMessageThreadsForEmployeeIds,
    isSelectedIdsEvents,
    isSelectedIdsMessageThreads,
} from '../Utils/messageUtils'
import {
    selectCommentThreads,
    selectMessageRecipients,
    selectNewCommentForm,
    selectNewMessageForm,
    selectSelectedEmployeeIds,
    selectSelectedEventIds,
    selectSelectedThreadIds,
} from './MessagingSelectors'
import { RootState } from 'typesafe-actions'
import { createNewCommentThreadsForEventIds } from '../Utils/commentUtils'
import { closeModalAction } from '../../modal'
import { IMessagingDataSourceItem } from '../Types/IMessagingDataSourceItem'
import { fetchDataSourceDataThunk, selectDataSourceDataStatus } from '../../data-source'
import { IThunkBaseAction } from '../../generic-state'
import { isEmpty } from 'lodash-es'

export const getMessageThreadsForEmployee = createAsyncThunk<IMessageThread[], void, { state: RootState }>(
    'messaging-fetch-threads',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const EmployeeIds = selectSelectedEmployeeIds(state)
            const ThreadIds = selectSelectedThreadIds(state)
            const messageThreads = await fetchMessageThreadsForEmployees(EmployeeIds, ThreadIds)
            return messageThreads.ListData
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const getMessagesForThread = createAsyncThunk<IMessage[], IMessageThread>(
    'messaging-fetch-thread',
    async (thread: IMessageThread, { rejectWithValue, dispatch }) => {
        try {
            const messageThread = await fetchMessagesForThread([thread.Id])
            dispatch(setViewedMessageThread(thread))
            return messageThread.ListData
        } catch (e) {
            dispatch(displayErrorToaster(e.message))
            return rejectWithValue(e)
        }
    }
)

export const startNewMessageThread = createAsyncThunk<boolean, void, { state: RootState }>(
    'messaging-start-new-thread',
    async (_, { rejectWithValue, dispatch, getState }) => {
        try {
            const state = getState()
            const selectedIds = selectMessageRecipients(state)
            const Ids = selectedIds.map(({ Id }) => Id)
            const newMessageForm = selectNewMessageForm(state)
            const payload = createNewMessageThreadsForEmployeeIds(newMessageForm, Ids ? [...Ids] : [])

            await postNewMessageThread(payload)

            dispatch(getMessageThreadsForEmployee())

            if (Ids.length > 1) {
                await dispatch(closeMessageModal('MessagingForm', 'ThreadMessage'))
            }

            dispatch(displaySuccessToaster('messaging.messageSent'))

            return true
        } catch (e) {
            dispatch(displayErrorToaster(e.message))

            return rejectWithValue(e)
        }
    }
)

export const replyToMessageThread = createAsyncThunk<IMessage[], void, { state: RootState }>(
    'messaging-reply-to-thread',
    async (_, { rejectWithValue, dispatch, getState }) => {
        try {
            const { replyMessageValue, viewedMessageThread } = getState().messaging

            if (viewedMessageThread?.Id) {
                const payload = [
                    {
                        Data: {
                            Content: replyMessageValue,
                            ThreadId: viewedMessageThread.Id,
                        },
                    },
                ]
                const response = await replyToThread(payload)
                if (response) {
                    const messageThread = await fetchMessagesForThread([viewedMessageThread.Id])
                    return messageThread.ListData
                }
            }
            return []
        } catch (e) {
            dispatch(displayErrorToaster(e.message))
            return rejectWithValue(e)
        }
    }
)

export const getCommentsForSelectedItemIds = createAsyncThunk<IMessageThread[], void, { state: RootState }>(
    'messaging-fetch-comments-for-selected-ids',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const EventIds = selectSelectedEventIds(state)
            const comments = await fetchCommentsForEvents(EventIds)
            return comments.ListData
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const commentingFormInitialized = createAsyncThunk<
    IMessageThread[],
    IEvent[] | IMessagingDataSourceItem[],
    { state: RootState }
>(
    'messaging-fetch-event-comments',
    async (events: IEvent[] | IMessagingDataSourceItem[] | IMessageThread[], { rejectWithValue, dispatch }) => {
        try {
            if (!isEmpty(events)) {
                const getEvents = (): IEvent[] => {
                    const event = events[0]

                    if (isSelectedIdsEvents(event)) {
                        return events as IEvent[]
                    }

                    if (isSelectedIdsMessageThreads(event)) {
                        return (events as IMessageThread[]).map(({ Event }) => Event)
                    }

                    return []
                }

                dispatch(setSelectedEvents(getEvents()))
                dispatch(getCommentsForSelectedItemIds())
            }
            return []
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const addCommentToEvent = createAsyncThunk<boolean, void, { state: RootState }>(
    'messaging-create-new-comment',
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            const state = getState()
            const EventIds = selectSelectedEventIds(state)
            const newCommentForm = selectNewCommentForm(state)
            const payload = createNewCommentThreadsForEventIds(newCommentForm, EventIds)
            await postNewComment(payload)
            dispatch(getCommentsForSelectedItemIds())
            return true
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const updateMessageThreadIsReadStatus = createAsyncThunk<boolean, boolean, { state: RootState }>(
    'messaging-update-message-thread-is-read-status',
    async (IsRead, { rejectWithValue, getState }) => {
        try {
            const { viewedMessageThread } = getState().messaging

            if (viewedMessageThread) {
                const payload = [
                    {
                        Data: {
                            ThreadId: viewedMessageThread?.Id,
                            IsRead,
                        },
                    },
                ]

                await patchMessageThreadStatus(payload)
                return true
            }

            return false
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const updateMessageThreadIsReadStatusById = createAsyncThunk<
    { ThreadId?: number; IsRead: boolean },
    { ThreadId?: number; IsRead: boolean },
    { state: RootState }
>('messaging-update-message-thread-is-read-status', async ({ IsRead, ThreadId }, { rejectWithValue }) => {
    try {
        if (ThreadId) {
            const payload = [
                {
                    Data: {
                        ThreadId,
                        IsRead,
                    },
                },
            ]
            await patchMessageThreadStatus(payload)
        }

        return {
            ThreadId,
            IsRead,
        }
    } catch (e) {
        return rejectWithValue(e)
    }
})

export const markEventCommentsAsRead = createAsyncThunk<void, void, { state: RootState }>(
    'messaging-mark-event-comments-as-read',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState()
            const commentThreads = selectCommentThreads(state)
            await Promise.all(
                commentThreads.data.map(async (Thread) => {
                    if (!Thread.LatestMessage.IsRead) {
                        await patchMessageThreadStatus([
                            {
                                Data: {
                                    ThreadId: Thread.Id,
                                    EventId: Thread.Event?.Id,
                                    IsRead: true,
                                },
                            },
                        ])
                    }
                })
            )
        } catch (e) {
            rejectWithValue(e)
        }
    }
)

export const closeMessageModal =
    (formId: string, dataSourceId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        dispatch(closeModalAction(formId))

        const dataSourceInitialized = selectDataSourceDataStatus(getState(), dataSourceId)

        if (dataSourceInitialized) {
            await dispatch(fetchDataSourceDataThunk(dataSourceId, { showLoadingSpinner: false }))
        }
    }
