import * as React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import { Translate, TTranslatable } from '../../../localization'

interface IH2Props {
    label: React.ReactNode
    className?: string
}

interface ITranslatedH2Props {
    label: TTranslatable
    className?: string
}

const Header = styled(Typography)`
    margin-bottom: 10px;
`

const TranslatedH2: React.FunctionComponent<ITranslatedH2Props> = ({ className, label }) => {
    return (
        <Translate translate={{ label }}>
            {({ label: H2Label }) => <H2 className={className} label={H2Label} />}
        </Translate>
    )
}

const H2: React.FunctionComponent<IH2Props> = ({ className, label }) => (
    <Header className={className} variant="h2">
        {label}
    </Header>
)

export { H2, TranslatedH2 }
