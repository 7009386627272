import * as React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { Translation } from '../../localization'
import Styles from 'constants/Styles'

const ErrorText = styled(Typography)`
    color: red;
`

const LoadingContainer = styled.div`
    width: 536px;
    height: 536px;
    background-color: ${Styles.mainColor.white};
    display: flex;
    justify-content: center;
    align-items: center;
`

const FormViewFailedInitializationIndicator: React.FunctionComponent = () => (
    <LoadingContainer>
        <ErrorText variant="h2">{Translation.translateKey('form-view.FormViewLoadFailed')}</ErrorText>
    </LoadingContainer>
)

export default FormViewFailedInitializationIndicator
