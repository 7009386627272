export const legacyCalendarTheme = {
    blockHover: '#ABCBE3',
    actionBarBoxShadow: 'rgba(0, 0, 0, 0.5)',
    expandableCalendarBoxShadow: 'rgba(0, 0, 0, 0.32)',
    greyFiller: 'rgba(237, 240, 242, 0.5)',
    greyFillerWithoutOpacity: '#F2F5F5',
    innerBorder: '#edf0f2',
    segmentBackgroundShading: 'rgba(245, 247, 250, 0.5)',
    segmentBackgroundWhite: 'rgba(255, 255, 255, 0.5)',
    published: 'rgba(68, 140, 193, 1)',
    notPublishedHover: 'rgba(68, 140, 193, 0.1)',
    filled: 'rgba(68, 140, 193, 0.3)',
    filledHover: 'rgba(68, 140, 193, 0.4)',
    transferBackground: '#EFF7FC',
    transferBorder: '#448CC1',
    transferTemporaryBorder: '#CAE6F7',
    esto: 'rgba(238, 93, 127, 1)',
    estoBackground: 'rgba(238, 93, 127, 0.3)',
    estoHover: 'rgba(238, 93, 127, 0.4)',
    tyrkky: 'rgba(0, 171, 132, 1)',
    tyrkkyBackground: 'rgba(0, 171, 132, 0.3)',
    tyrkkyHover: 'rgba(0, 171, 132, 0.4)',
    muuTapahtuma: 'rgba(116, 88, 138, 1)',
    muuTapahtumaBackground: 'rgba(116, 88, 138, 0.3)',
    muuTapahtumaHover: 'rgba(116, 88, 138, 0.4)',
    selectedNodeBackground: 'rgba(56, 163, 206, 0.2)',
}

export type LegacyCalendarThemeOptions = typeof legacyCalendarTheme
