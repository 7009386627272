import * as React from 'react'
import styled from '@emotion/styled'
import Button from './Button'
import { useAppTheme } from '../../../theme/'

type TButtonProps = React.ComponentProps<typeof Button>

export interface IStyledButtonProps extends Omit<TButtonProps, 'variant'> {
    iconButton?: boolean
    noBorder?: boolean
    variant?: TButtonProps['variant'] | 'attention'
    small?: boolean
    testId?: string | undefined
}

interface IButtonWithStylesProps extends IStyledButtonProps {
    label: string
    background: string
    hoverBackground: string | null
}

// Stripping props from DOM elements manually since styled components doesn't
// do that automatically for 3rd party components.
const ButtonWithStyles = styled<React.FC<IButtonWithStylesProps>>(
    ({ iconButton, variant, small, label, background, hoverBackground, ...rest }) => (
        <Button variant={variant === 'attention' ? undefined : variant} {...rest} />
    )
)`
    height: ${({ small }) => (small ? '24px' : '32px')};
    white-space: nowrap;
    border-radius: ${({ small, theme }) => (small ? theme.tokens.radiusButtonS : theme.tokens.radiusButtonM)};

    & .MuiButton-label {
        font-weight: normal;
        padding: ${({ small }) => (small ? '4px' : '8px')};
        height: 16px;
        line-height: ${({ small }) => (small ? '22px' : '24px')};
        font-size: ${({ small }) => (small ? '14px' : '16px')};
        transition: ${({ theme }) => `color ${theme.tokens.transitionQuick}`};
        ${({ startIcon, endIcon }) =>
            (startIcon || endIcon) &&
            `
            padding: 10px 8px;
        `};
    }

    .MuiButton-label,
    .MuiIcon-root {
        color: ${({ label }) => label};
    }

    :hover {
        background-color: ${({ theme }) => theme.componentExtensions.buttons.bgButtonPrimaryDefault};
        color: ${({ theme }) => theme.componentExtensions.buttons.textButtonPrimaryActive};

        & .MuiButton-label,
        .MuiIcon-root {
            color: ${({ theme, variant }) =>
                variant === 'attention'
                    ? theme.componentExtensions.buttons.textButtonDestructiveHover
                    : theme.componentExtensions.buttons.textButtonPrimaryActive};
        }
    }

    ${({ disabled, theme }) =>
        disabled &&
        `
        background-color: ${theme.componentExtensions.buttons.bgButtonPrimaryDisabled};
    `};

    ${({ variant, disabled, theme }) =>
        variant === 'outlined' &&
        `
        background-color: ${theme.colors.neutralsWhite100};

        :hover {
            background-color: ${theme.componentExtensions.buttons.bgButtonPrimaryHover};
        }

        & .MuiButton-label {
            color: ${
                disabled
                    ? theme.componentExtensions.buttons.textButtonSecondaryDisabled
                    : theme.componentExtensions.buttons.textButtonPrimaryActive
            };
        }
    `}

    /* This could be combined to the function above, but it seems to cause some issues with parsing for the snapshot tests */
    ${({ variant, disabled, theme }) =>
        variant === 'outlined' &&
        `
        border: 1px solid ${
            disabled
                ? theme.componentExtensions.buttons.borderButtonSecondaryDisabled
                : theme.componentExtensions.buttons.borderButtonSecondaryDefault
        };

    `}

    ${({ iconButton }) =>
        iconButton &&
        `
        height: 32px;
        width: 32px;
        min-width: 32px;

        // targeting the icon inside the button wrapped inside label
        & .MuiButton-label div {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `}

    ${({ variant, disabled, label, background, hoverBackground }) => {
        if (variant !== 'attention' && variant !== 'text' && variant !== 'contained') {
            return null
        }

        return `
            & .MuiButton-label {
                color: ${label};
            }

            background-color: ${background};

            ${
                !disabled &&
                `
                :hover {
                    background-color: ${hoverBackground}
                }
            `
            }
        `
    }}
`

const StyledButton: React.FunctionComponent<IStyledButtonProps> = ({
    iconButton,
    variant = 'contained',
    startIcon,
    endIcon,
    noBorder,
    small,
    ...props
}) => {
    const theme = useAppTheme()
    const getColorsForVariant = (disabled = props?.disabled) => {
        switch (variant) {
            case 'attention': {
                return {
                    label: disabled
                        ? theme.componentExtensions.buttons.textButtonDestructiveDisabled
                        : theme.componentExtensions.buttons.textButtonDestructiveDefault,
                    background: disabled
                        ? theme.componentExtensions.buttons.bgButtonDestructiveDisabled
                        : theme.componentExtensions.buttons.bgButtonDestructiveDefault,
                    hoverBackground: theme.componentExtensions.buttons.bgButtonDestructiveHover,
                }
            }
            case 'text': {
                return {
                    label: disabled
                        ? theme.componentExtensions.buttons.bgButtonPrimaryDisabled
                        : theme.componentExtensions.buttons.bgButtonPrimaryDefault,
                    background: 'transparent',
                    hoverBackground: theme.componentExtensions.buttons.bgButtonPrimaryHover,
                }
            }
            case 'contained': {
                return {
                    label: theme.componentExtensions.buttons.textButtonPrimaryDefault,
                    background: disabled
                        ? theme.componentExtensions.buttons.borderButtonSecondaryDisabled
                        : theme.componentExtensions.buttons.bgButtonPrimaryDefault,
                    hoverBackground: theme.componentExtensions.buttons.bgButtonPrimaryHover,
                }
            }
            default: {
                return { label: '', background: '', hoverBackground: null }
            }
        }
    }

    const { label, background, hoverBackground } = getColorsForVariant()

    return (
        <ButtonWithStyles
            endIcon={endIcon}
            iconButton={iconButton}
            startIcon={startIcon}
            variant={iconButton ? (noBorder ? 'text' : 'outlined') : variant}
            label={label}
            background={background}
            hoverBackground={hoverBackground}
            small={small}
            {...props}
        />
    )
}

export default StyledButton
