import { extend, get, includes, indexOf, isObject, keys, values } from 'lodash-es'

import Sanasto from '../Constants/Sanasto/Yleinen'
import { getLogger } from '../../log'

const Log = getLogger('localization.Translation')

const AsiakaskohtaisetSanastot = {
    Seure: require('../Constants/Sanasto/Seure'),
    Opteam: require('../Constants/Sanasto/Opteam'),
}

const DefaultLocale = 'fi'
const NotTranslated = null
const ParameterPlaceholderPrefix = '{'
const ParameterPlaceholderPostfix = '}'

let _locale = DefaultLocale
let _translationsById = {}
let _translationsByKey = Sanasto

function insertParameter(translation: any, key: string, value: any): any {
    if (translation.indexOf(key) > -1) {
        return translation.replace(key, value)
    } else {
        Log.warn('Placeholder $0 not found in translation $1.', key, translation)
        return translation
    }
}

function insertNumberedParameters(translation: any, parameters: any): any {
    let translated = translation
    for (let i = 0; i < parameters.length; i++) {
        const key = ParameterPlaceholderPrefix + i + ParameterPlaceholderPostfix
        const value = parameters[i]
        translated = insertParameter(translated, key, value)
    }
    return translated
}

function insertNamedParameters(translation: any, parameters: any): any {
    let translated = translation
    for (const name in parameters) {
        const key = ParameterPlaceholderPrefix + name + ParameterPlaceholderPostfix
        const value = parameters[name]
        translated = insertParameter(translated, key, value)
    }
    return translated
}

function isLocale(value: string): boolean {
    return value !== 'Kuvaus'
}

function supportedLocales(): string[] {
    const locales: string[] = []
    const sanastoItem = values(_translationsByKey)[0]
    for (const key in sanastoItem) {
        if (Object.prototype.hasOwnProperty.call(sanastoItem, key) && isLocale(key)) {
            locales.push(key)
        }
    }
    return locales
}

function insertInto(translation: any, parameters: any): string {
    if (Array.isArray(parameters)) {
        return insertNumberedParameters(translation, parameters)
    } else if (isObject(parameters)) {
        return insertNamedParameters(translation, parameters)
    } else {
        return translation
    }
}

function isSupportedLocaleisLocale(locale: string): boolean {
    return indexOf(supportedLocales(), locale) !== -1
}

function translateForCurrentLocale(translation: any): any {
    if (!translation) {
        return NotTranslated
    } else if (translation[_locale]) {
        return translation[_locale]
    } else {
        Log.warn('No translation for locale $0 for translation identifier $1.', _locale, translation.ID)
        return NotTranslated
    }
}

function translationForId(id: number): any {
    if (Object.prototype.hasOwnProperty.call(_translationsById, id)) {
        return (_translationsById as any)[id]
    } else {
        Log.warn('Unknown translation identifier $0.', id)
        return null
    }
}

function _translationForKey(key: string): any {
    const translation = get(_translationsByKey, key)

    if (!translation) {
        Log.warn('Unknown translation key $0.', key)
        return null
    }

    return translation
}

function translationError(idOrKey: any): string {
    return '!!! ' + idOrKey + ' !!!'
}

/**
 * Apuluokka merkkijonojen kääntämiseen.
 * Tukee sekä Contactorissa aiemmin käytettyä ID-pohjaiseta järjestelmää, jonka käännökset
 * on tuodaan palvelimelta, sekä uutta key-pohjaista järjestelmää, jonka käännökset
 * ovat UusiSanasto.js-tiedostossa.
 */
export default class Translation {
    /**
     * @returns {string} Käytössä olevan localen. Esimerkiksi 'fi'.
     */
    static getLocale(): string {
        return _locale
    }

    /**
     * Lataa annetun asiakkaan asiakaskohtaiset käännökset.
     * Tällä hetkellä asiakaskohtaisia käännöksiä on seuraaville asiakkaille:
     * - Seure
     * @param customerName Asiakkaan nimi.
     */
    static loadCustomerSpecificTranslations(customerName: string): void {
        if (!includes(keys(AsiakaskohtaisetSanastot), customerName)) {
            Log.warn('Customer specific translations do not exist for customer $0. Not loaded.', customerName)
            return
        }

        extend(_translationsByKey, (AsiakaskohtaisetSanastot as any)[customerName].default)
        Log.debug('Loaded customer specific translations for $0.', customerName)
    }

    /**
     * Ottaa annetut ID-pohjaiset käännökset käyttöön.
     * @param translations
     */
    static loadIdTranslations(translations: Record<string, unknown>): void {
        if (translations) {
            Log.debug('Custom id-based translations loaded.')
            _translationsById = translations
        }
    }

    /**
     * Ottaa annetut avainpohjaiset käännökset käyttöön.
     * @param translations
     */
    static loadKeyTranslations(translations: Record<string, unknown>): void {
        if (translations) {
            Log.debug('Custom key-based translations loaded.')
            _translationsByKey = translations as any
        } else {
            Log.debug('Default key-based translations loaded.')
            _translationsByKey = Sanasto
        }
    }

    /**
     * Asettaa käytössä olevan localen.
     * @param locale Localen kaksikirjaiminen tunnus, esim. fi.
     */
    static setLocale(locale: string): void {
        let usedLocale = locale

        if (!isSupportedLocaleisLocale(locale)) {
            Log.warn('Unsupported locale $0. Defaulting to $1.', locale, DefaultLocale)
            Log.debug('Available locales are $0.', supportedLocales())
            usedLocale = DefaultLocale
        }

        Log.debug('Locale set to $0.', usedLocale)
        _locale = usedLocale
    }

    /**
     * (VANHA TAPA, VANHA SANASTO)
     * Kääntää annetun ID:n käytössä olevalle localelle ja sijoittaa siihen annetut parametrit.
     * @param id {integer} Käännöksen ID.
     * @param parameters Valinnaiset parametrit joko taulukkona tai objektina. Taulukkoparametrit sijoitetaan
     *        järjestyksessä käännöksessä oleville, {n}-merkityille paikoille, jossa n on taulukon indeksi.
     *        Objektin arvot (value) sijoitetaan käännöksessä oleville, {xxx}-merkityille paikoille, jossa xxx on
     *        objektin avain (key).
     * @returns {*} ID:tä vastaava käännös käytössä olevalle localelle.
     */
    static translateId(id: number, parameters?: Record<string, unknown>): string {
        const translation = translationForId(id)
        const translatedId = translateForCurrentLocale(translation)
        if (translatedId === NotTranslated) {
            return translationError(id)
        } else {
            return insertInto(translatedId, parameters)
        }
    }

    /**
     * (UUSI TAPA, UUSI SANASTO)
     * Kääntää annetun keyn käytössä olevalle localelle ja sijoittaa siihen annetut parametrit.
     * @param key {string} Käännöksen key.
     * @param parameters Valinnaiset parametrit joko taulukkona tai objektina. Taulukkoparametrit sijoitetaan
     *        järjestyksessä käännöksessä oleville, {n}-merkityille paikoille, jossa n on taulukon indeksi.
     *        Objektin arvot (value) sijoitetaan käännöksessä oleville, {xxx}-merkityille paikoille, jossa xxx on
     *        objektin avain (key).
     * @returns {*} key:tä vastaava käännös käytössä olevalle localelle.
     */
    static translateKey(key: string, parameters?: unknown): string {
        const translation = _translationForKey(key)
        const translatedKey = translateForCurrentLocale(translation)
        if (translatedKey === NotTranslated) {
            return translationError(key)
        } else {
            return insertInto(translatedKey, parameters)
        }
    }

    /**
     * (UUSI TAPA, UUSI SANASTO)
     * Kääntää monta avainta kerralla.
     * HUOM! Ei voi käyttää parametrisoitujen käännösten kanssa!
     * @param translationKeys {string[]} Käännöksen key.
     * @returns {*} key:tä vastaavat käännökset käytössä olevalle localelle.
     */
    static translateManyKeys(translationKeys: string[]): string[] {
        return translationKeys.map((key) => this.translateKey(key))
    }

    static has(key: unknown): key is string {
        if (typeof key !== 'string') {
            return false
        }
        return get(_translationsByKey, key)
    }

    static async loadStorybookTranslations(): Promise<void> {
        const storybookTranslations = await import('../Constants/StorybookDictionary')

        _translationsByKey = { ..._translationsByKey, ...storybookTranslations.default }
    }
}

// shorthand for Translation.translateKey
export const translate = (key: string, parameters?: unknown): string => Translation.translateKey(key, parameters)
