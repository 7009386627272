import DayPickerBase from './DayPickerBase'
import { Moment } from 'moment'
import { IValuePickerCommonOwnProps } from '../../value-picker'

export interface ISingleDayPickerProps extends IValuePickerCommonOwnProps<Moment | undefined> {
    shouldDateBeDisabled?: (val: string) => boolean
}

const SingleDayPicker: React.FC<ISingleDayPickerProps> = (props) => {
    const value = props.value ? [props.value] : []

    const onChange = (val: Moment[]) => props.onChange(val.length > 0 ? val[0] : undefined)

    return <DayPickerBase {...props} value={value} onChange={onChange} multiselect={false} />
}

export default SingleDayPicker
