import { getJSONItemFromLocalStorage, setJSONItemToLocalStorage } from '../../generic-utilities'
import { omit } from 'lodash-es'

type ColumnOrder = Record<string, number | undefined>
type PinnedColumns = string[]
type ColumnWidth = Record<string, number> | undefined

const COLUMN_ORDER_KEY = 'columnOrder'
const PINNED_COLUMNS_KEY = 'pinnedColumns'
const COLUMN_WIDTHS_KEY = 'columnWidths'

const saveValueToStorage = <T>(key: string, listId: string, value: T): void => {
    const savedValue = getJSONItemFromLocalStorage<Record<string, T>>(key)

    setJSONItemToLocalStorage(key, {
        ...savedValue,
        [listId]: value,
    })
}

export const saveColumnOrder = (listId: string, value: ColumnOrder): void => {
    saveValueToStorage(COLUMN_ORDER_KEY, listId, value)
}

export const savePinnedColumns = (listId: string, value: PinnedColumns): void => {
    saveValueToStorage(PINNED_COLUMNS_KEY, listId, value)
}

export const saveColumnWidths = (listId: string, value: ColumnWidth): void => {
    saveValueToStorage(COLUMN_WIDTHS_KEY, listId, value)
}

export const removeColumnOrder = (listId: string): void => {
    const savedValue = getJSONItemFromLocalStorage<Record<string, ColumnOrder>>(COLUMN_ORDER_KEY)

    setJSONItemToLocalStorage(COLUMN_ORDER_KEY, omit(savedValue, listId))
}

export const removePinnedColumns = (listId: string): void => {
    const savedValue = getJSONItemFromLocalStorage<Record<string, PinnedColumns>>(PINNED_COLUMNS_KEY)

    setJSONItemToLocalStorage(PINNED_COLUMNS_KEY, omit(savedValue, listId))
}

export const removeColumnWidths = (listId: string): void => {
    const savedValue = getJSONItemFromLocalStorage<Record<string, PinnedColumns>>(COLUMN_WIDTHS_KEY)

    setJSONItemToLocalStorage(COLUMN_WIDTHS_KEY, omit(savedValue, listId))
}

export const resetColumnSettings = (listId: string): void => {
    removeColumnOrder(listId)
    removePinnedColumns(listId)
    removeColumnWidths(listId)
}
