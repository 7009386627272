import { errorOnPromiseFailed } from 'action-creators/ErrorActions'
import { IAsetus } from '../reducers/OsiokohtaisetAsetuksetReducer'
import { haeNavigointiItem } from '../webApi/NavigointiItemWebApi'
import { fetchOsiokohtaisetAsetukset, saveOsiokohtaisetAsetukset } from '../webApi/OsiokohtaisetAsetuksetWebApi'
import {
    alustaOsiokohtaisetAsetukset,
    asetaOsionAsetuksienArvoja,
    asetaOsionNimi,
} from '../actions/OsiokohtaisetAsetuksetActions'
import { IThunkBaseAction } from '../../generic-state'
import {
    asyncOperationFailedWithErrorDisplayThunk,
    asyncOperationStartedAction,
    asyncOperationSucceededAction,
} from '../../async-operation'
import { isNull } from 'lodash-es'

export const haeOsiokohtaisetAsetukset =
    (pageId: number): IThunkBaseAction =>
    async (dispatch) => {
        const componentName = 'OsiokohtaisetAsetukset'
        dispatch(asyncOperationStartedAction(componentName))

        try {
            const [osiokohtaisetAsetukset, navigointiItem] = await Promise.all([
                fetchOsiokohtaisetAsetukset(pageId),
                haeNavigointiItem(pageId),
            ])

            if (isNull(navigointiItem)) {
                throw new Error('OsiokohtaisetAsetukset.osiotaEiLoytynyt')
            }

            dispatch(alustaOsiokohtaisetAsetukset(osiokohtaisetAsetukset))
            dispatch(asetaOsionNimi(navigointiItem.Nimi))
            dispatch(asyncOperationSucceededAction(componentName))
        } catch (error) {
            dispatch(asyncOperationFailedWithErrorDisplayThunk(error, componentName))
        }
    }

export const tallennaOsiokohtaisetAsetukset =
    (tallennettavatAsetukset: IAsetus[]): IThunkBaseAction =>
    async (dispatch) => {
        try {
            await saveOsiokohtaisetAsetukset(tallennettavatAsetukset)

            dispatch(asetaOsionAsetuksienArvoja(tallennettavatAsetukset))
        } catch (error) {
            dispatch(errorOnPromiseFailed(error))

            throw error
        }
    }
