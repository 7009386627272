import { useEffect, useRef } from 'react'

/**
 * Remembers the previous value for the given variable.
 * Can be used for example to remember the previous props or state,
 * to have behavior similar to componentDidUpdate (which receives the
 * previous props and state as parameters) in class components.
 */
const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>()

    // store the current value in the ref object whenever the component changes
    useEffect(() => {
        ref.current = value
    }, [value])

    // return the previous value (done before the useEffect above is run)
    return ref.current
}

export default usePrevious
