import * as React from 'react'

import { Icon } from '../../../../generic-components'
import styled from '@emotion/styled'
import { light_grey_140, primary_100 } from 'constants/Styles'

interface IOwnProps {
    groupType: 'main' | 'sub' | ''
    groupOpened: boolean
    handleGroupButtonClick: (e: React.MouseEvent) => void
    marginLeft: string
}

interface IListGroupButtonProps extends IOwnProps {}

const StyledIcon = styled(Icon)<{ color: string; transparent?: boolean; marginLeft: string }>`
    color: ${({ color }) => color});
    opacity: ${({ transparent }) => (transparent ? 0 : 1)};
    position: absolute;
    margin-left: ${({ marginLeft }) => marginLeft};

`
/**
 * Icon and behaviour changes according to groupType
 */

export const ListGroupButtonUnconnected: React.FC<IListGroupButtonProps> = ({
    groupType,
    groupOpened = false,
    handleGroupButtonClick,
    marginLeft = '0px',
}) => {
    if (groupType === 'sub') {
        return (
            <StyledIcon color={light_grey_140} marginLeft={marginLeft}>
                subdirectory_arrow_right
            </StyledIcon>
        )
    } else if (groupType === 'main') {
        if (groupOpened) {
            return (
                <StyledIcon onClick={handleGroupButtonClick} color={primary_100} marginLeft={marginLeft}>
                    keyboard_arrow_downward
                </StyledIcon>
            )
        } else {
            return (
                <StyledIcon onClick={handleGroupButtonClick} color={primary_100} marginLeft={marginLeft}>
                    keyboard_arrow_right
                </StyledIcon>
            )
        }
    } else {
        return (
            <StyledIcon color={light_grey_140} marginLeft={'0px'}>
                transparent subdirectory_arrow_right
            </StyledIcon>
        )
    }
}

export default ListGroupButtonUnconnected
