import { useState } from 'react'
import * as React from 'react'

import TextInput from 'components/atoms/TextInput'
import styled from '@emotion/styled'
import InputAdornment from '@material-ui/core/InputAdornment'

interface IHoursInputProps {
    /**
     * The total value in minutes.
     */
    valueInMinutes: number
    onChange: (totalMinutes: number) => void
    onBlur?: () => void
}

const getHoursAndMinutes = (valueInMinutes: number) => {
    const overflowMinutes = valueInMinutes % 60
    const hours = (valueInMinutes - overflowMinutes) / 60

    return {
        hours,
        minutes: overflowMinutes,
    }
}

const isMinutesWithinAllowedRange = (minutes: number) => {
    return minutes >= 0 && minutes < 60
}

const isHoursWithinAllowedRange = (hours: number) => {
    return hours >= 0 && hours <= 9999
}

const Container = styled.div`
    max-width: 150px;
    display: flex;
`

const StyledTextInput = styled(TextInput)`
    .MuiInput-input {
        text-align: right;
    }
`

const StyledHoursInput = styled(StyledTextInput)`
    padding-right: 25px;
`

const hoursInputProps = {
    endAdornment: <InputAdornment position="end">h</InputAdornment>,
}

const minutesInputProps = {
    endAdornment: <InputAdornment position="end">min</InputAdornment>,
}

const HoursInput: React.FC<IHoursInputProps> = ({ onBlur, valueInMinutes, onChange }) => {
    const { hours, minutes } = getHoursAndMinutes(valueInMinutes)
    const [{ isHoursTouched, isMinutesTouched }, setIsTouched] = useState({
        isHoursTouched: false,
        isMinutesTouched: false,
    })

    const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const hoursGiven = Number(event.target.value)

        if (!isHoursWithinAllowedRange(hoursGiven)) {
            return
        }

        const hoursAsMinutes = Number(event.target.value) * 60

        onChange(hoursAsMinutes + minutes)
    }

    const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const minutesGiven = Number(event.target.value)

        if (!isMinutesWithinAllowedRange(minutesGiven)) {
            return
        }

        const hoursAsMinutes = Number(hours) * 60

        onChange(hoursAsMinutes + Number(minutesGiven))
    }

    const handleBlur = (subField: 'hours' | 'minutes') => {
        const newIsHoursTouched = isHoursTouched ? isHoursTouched : subField === 'hours'
        const newIsMinutesTouched = isMinutesTouched ? isMinutesTouched : subField === 'minutes'

        if (newIsHoursTouched !== isHoursTouched || newIsMinutesTouched !== isMinutesTouched) {
            setIsTouched({ isHoursTouched: newIsHoursTouched, isMinutesTouched: newIsMinutesTouched })
        }

        if (newIsHoursTouched && newIsMinutesTouched) {
            onBlur && onBlur()
        }
    }

    const handleHoursBlur = () => {
        handleBlur('hours')
    }

    const handleMinutesBlur = () => {
        handleBlur('minutes')
    }

    return (
        <Container>
            <StyledHoursInput
                allowDecimals={false}
                MuiInputProps={hoursInputProps}
                onBlur={onBlur && handleHoursBlur}
                onChange={handleHoursChange}
                type="number"
                value={hours}
            />
            <StyledTextInput
                allowDecimals={false}
                MuiInputProps={minutesInputProps}
                onBlur={onBlur && handleMinutesBlur}
                onChange={handleMinutesChange}
                type="number"
                value={minutes}
            />
        </Container>
    )
}

export default HoursInput
