import IAsyncOperationState from '../Types/IAsyncOperationState'
import { createAction } from 'typesafe-actions'
import { CancelTokenSource } from 'axios'

const mapAsyncOperation = (
    id: IAsyncOperationState['id'] = 'global',
    other: IAsyncOperationState['other'] = null,
    cancelTokenSource?: CancelTokenSource
) => ({
    id,
    other,
    cancelTokenSource,
})

export const asyncOperationStartedAction = createAction('async-operation.AsyncOperationStarted', mapAsyncOperation)()

export const asyncOperationSucceededAction = createAction(
    'async-operation.asyncOperationSucceededAction',
    mapAsyncOperation
)()

export const asyncOperationFailedAction = createAction(
    'async-operation.asyncOperationFailedAction',
    (error: Error, id: IAsyncOperationState['id'] = 'global', other: IAsyncOperationState['other'] = null) => ({
        error,
        id,
        other,
    })
)()
