import * as React from 'react'

import StyledRadioButtonGroup, { IStyledRadioButtonGroupProps } from './StyledRadioButtonGroup'
import { Translate } from '../../../../localization'

export interface ITranslatedRadioButtonGroupProps extends IStyledRadioButtonGroupProps {}

const TranslatedRadioButtonGroup: React.FunctionComponent<ITranslatedRadioButtonGroupProps> = (props) => (
    <Translate translate={{}}>{(translated) => <StyledRadioButtonGroup {...translated} {...props} />}</Translate>
)

export default TranslatedRadioButtonGroup
