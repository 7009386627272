import { RootState } from 'typesafe-actions'
import { createSelector } from 'reselect'

import IModalModel from '../Types/IModalModel'
import IModalReducerState from '../Types/IModalReducerState'

type TModalId = string

const selectModals = (state: RootState): IModalReducerState => {
    return state.modal
}

const selectModalState = (state: RootState, modalId: string | null): IModalModel | null => {
    if (modalId === null) {
        return null
    }

    return state.modal.get(modalId) ?? null
}

export const selectIsModalOpen = (state: RootState, modalId: string | null): boolean => {
    const modalState = selectModalState(state, modalId)

    return modalState?.isOpen ?? false
}

export const selectModalProps = <TProps extends Record<string, unknown> | undefined>(
    state: RootState,
    modalId: string | null
): TProps | undefined => {
    const modalState = selectModalState(state, modalId)

    return modalState ? (modalState.props as TProps) : undefined
}

export const selectOpenModalIds = createSelector(selectModals, (modal): TModalId[] => {
    return [...modal].filter(([, modalState]) => modalState.isOpen).map(([modalId]) => modalId)
})

export const selectOpenModals = createSelector(
    selectOpenModalIds,
    selectModals,
    (openModalIds, modals): IModalReducerState => {
        return openModalIds.reduce((openModals, modalId) => {
            return openModals.set(modalId, modals.get(modalId) as IModalModel)
        }, new Map<TModalId, IModalModel>())
    }
)
