import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import IValuePickerDependency from '../types/IValuePickerDependency'

/**
 * Calculates query parameters based on the selected values from the value picker. Currently supports only primitive type values.
 *
 * @param dependsOn - An array of value picker dependencies.
 * @returns A string representing the calculated query parameters.
 */
export const useValuePickerValuesAsQueryParameters = (dependsOn: IValuePickerDependency[]): string => {
    const valuePicker = useSelector((state: RootState) => state.valuePicker)
    const result = []

    for (const { ValuePickerId, ParameterName } of dependsOn) {
        const parameterValue = (valuePicker.get(ValuePickerId)?.value as any) ?? undefined

        if (typeof parameterValue === 'undefined') {
            continue
        }

        // NOTE: Complex values like objects or arrays are not supported yet. Please feel free to extend this function if needed.
        if (
            typeof parameterValue !== 'string' &&
            typeof parameterValue !== 'number' &&
            typeof parameterValue !== 'boolean'
        ) {
            throw new Error('Only string, number or boolean values are supported.')
        }

        result.push(`${ParameterName}=${encodeURIComponent(parameterValue)}`)
    }

    return result.length > 0 ? `?${result.join('&')}` : ''
}
