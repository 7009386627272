import moment from 'moment'

const KeyPlaceholderPrefix = '$'
const ModuleNameSeparator = ': '

function shouldInsert(parameters: any): boolean {
    return Array.isArray(parameters) && parameters.length > 0
}

function ensureIsArray(parameters: any): any[] {
    return Array.isArray(parameters) ? parameters : [parameters]
}

function parseArrayParameter(parameter: any[]): string {
    const values: string[] = []
    for (let i = 0; i < parameter.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        values.push(parse(parameter[i]))
    }
    return '( ' + values.join(', ') + ' )'
}

function parseObjectParameter(parameter: any): string {
    const keyValuePairs: string[] = []

    for (const key in parameter) {
        const value = parameter[key]
        keyValuePairs.push(key + '=' + value)
    }
    return '{ ' + keyValuePairs.join('; ') + ' }'
}

function parse(parameter: any): string {
    if (parameter === undefined) {
        return '<undefined>'
    } else if (parameter === null) {
        return '<null>'
    } else if (Array.isArray(parameter)) {
        return parseArrayParameter(parameter)
    } else if (typeof parameter === 'string') {
        return parameter
    } else if (moment.isMoment(parameter)) {
        return parameter.toString()
    } else if (typeof parameter === 'object') {
        return parseObjectParameter(parameter)
    } else {
        return parameter.toString()
    }
}

function insertInto(message: string, parameters: any[]): string {
    const paramsAsArray = ensureIsArray(parameters)
    let replacedMessage = message
    for (let i = 0; i < paramsAsArray.length; i++) {
        const key = KeyPlaceholderPrefix + i
        const value = parse(paramsAsArray[i])
        if (message.indexOf(key) > -1) {
            replacedMessage = replacedMessage.replace(key, value)
        }
    }
    return replacedMessage
}

/**
 * Lokituksen apuluokka.
 *
 * Käytä tätä luokkaa luomalla siitä uusi instanssi moduulin nimellä. Kutsu sen jälkeen haluamaasi lokitusmetodia.
 *
 * Voit antaa viestille (message) myäs parametrejä, jolloin niiden toString() arvoilla korvataan järjestyksessä
 * viestissä olevat $0..$n-tagit. Anna parametrit viestin jälkeen pilkuilla eroteltuna.
 *
 * @note älä käytä console.[error|log|warn] suoraan, jotta lokitus voidaan tarvittaessa helposti poistaa tuotannosta.
 */
/* eslint-disable no-console */
export default class Log {
    private _moduleName: string
    private _muted: boolean

    private static _muteLoggers = false
    private static _nonMutedLoggers: Set<string> = new Set()

    /**
     * @constructor
     * @param moduleName Moduulin nimi, josta lokitetaan. Näytetään lokiviestien alusssa.
     */
    constructor(moduleName: string) {
        this._moduleName = moduleName
        this._muted = false
    }

    /**
     * Hiljennä kaikki loggerit.
     */
    static muteAll(): void {
        Log.muteAllExcept()
    }

    static unmuteAll(): void {
        Log._muteLoggers = false
    }

    /**
     * Hiljennä kaikki paitsi erikseen määritellyt loggerit.
     */
    static muteAllExcept(...nonMuted: string[]): void {
        Log._muteLoggers = true
        Log._nonMutedLoggers = new Set(nonMuted)
    }

    /**
     * Lokittaa debug-viestin. Näytetään yleensä mustalla selaimen konsolissa.
     */
    debug(message: string, ...parametersToInsertIntoMessage: any[]): void {
        this.log(console.log, message, parametersToInsertIntoMessage)
    }

    /**
     * Lokittaa virheviestin. Näytetään yleensä punaisella selaimen konsolissa.
     */
    error(message: string, ...parametersToInsertIntoMessage: any[]): void {
        this.log(console.error, message, parametersToInsertIntoMessage)
    }

    /**
     * Lokittaa virheobjektin. Tämän avulla saa stack tracen näkyviin.
     */
    errorObject(objectToLog: Error): void {
        if (objectToLog) {
            console.error(objectToLog)
            if (objectToLog.stack) {
                console.error(objectToLog.stack)
            }
        }
    }

    /**
     * Hiljentää lokin.
     * Kutsu tätä 'äänekkäille' komponenteille, joiden lokin haluat kääntää päälle vain devatessa.
     */
    mute(): void {
        this._muted = true
    }

    /**
     * Lokittaa varoitusviestin. Näytetään yleensä keltaisella selaimen konsolissa.
     */
    warn(message: string, ...parametersToInsertIntoMessage: any[]): void {
        this.log(console.warn, message, parametersToInsertIntoMessage)
    }

    private log(loggingMethod: () => void, message: string, parameters: any): void {
        const isMuted = this._muted || (Log._muteLoggers && !Log._nonMutedLoggers.has(this._moduleName))

        if (!isMuted) {
            const logMessage = shouldInsert(parameters) ? insertInto(message, parameters) : message
            loggingMethod.call(console, this._moduleName + ModuleNameSeparator + logMessage)
        }
    }
}
/* eslint-enable no-console */
