import { RootState } from 'typesafe-actions'
import { createSelector } from 'reselect'

import { selectSelectedItemIds } from '../../../configurable-list'

/**
 * @deprecated
 * Remove once we get the events page to fully use configurable things
 */
export const selectSelectedEventIds = createSelector(
    (state: RootState) => selectSelectedItemIds(state, 'VuorotJaVapaatList'),
    (selectedItemIds): string[] => [...selectedItemIds] as string[]
)
