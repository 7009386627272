import * as React from 'react'
import { MomentInput } from 'moment'
import styled from '@emotion/styled'
import { max, min } from 'lodash-es'

import DateUtility from 'common/DateUtility'
import { H3, Tooltip } from '../../../generic-components'
import Styles from 'constants/Styles'
import { Translation } from '../../../localization'
import IEvent from '../../Types/IEvent'

interface ISubHeaderProps {
    events: IEvent[]
}

interface IIdentifierObject {
    Id: string | number
    Nimi: string
}

const getUniqueEmployeeNames = (employees: IIdentifierObject[]) => {
    const uniques = new Set(employees.map(({ Nimi }) => Nimi))

    return [...uniques].join(', ')
}

const getDateRange = (startDates: MomentInput[], endDates: MomentInput[]) =>
    `${DateUtility.asFullDate(min(startDates))} - ${DateUtility.asFullDate(max(endDates))}`

const TooltipContent = styled.span`
    display: inline-flex;
`

const EmployeesContainer = styled.span`
    color: ${Styles.mainColor.blue};
    font-size: ${Styles.font.levelThreeTitle.size};
    font-style: italic;
    font-family: ${Styles.font.levelThreeTitle.family};
    margin-top: 6px;

    /* Wrap the text to one line with an ellipsis in the end. */
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: ${Styles.font.levelThreeTitle.size};
    max-height: ${Styles.font.levelThreeTitle.size};
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
`

const getSingleTapahtumaSubheader = (event: IEvent): string => {
    const { Alkamisaika, Ammattinimike, Tyontekija } = event

    const employeeText = Tyontekija?.Nimi ? `${Tyontekija.Nimi}, ` : ''
    const jobtitleText = Ammattinimike?.Nimi ? `${Ammattinimike.Nimi}, ` : ''

    const subHeader = `${employeeText}${jobtitleText}${DateUtility.asFullDate(Alkamisaika)}`

    return subHeader
}

const SubHeader: React.FunctionComponent<ISubHeaderProps> = ({ events }) => {
    if (events.length === 1) {
        const subHeader = getSingleTapahtumaSubheader(events[0])

        return <H3 label={subHeader} />
    }

    const amountOfTapahtumas = Translation.translateKey('Palkkahallinto.DeletionModal.TapahtumasAmount', {
        amount: events.length,
    })

    const employees = events
        .map(({ Tyontekija }) => Tyontekija)
        .filter((employee): employee is IIdentifierObject => employee !== null)
    const employeesText = getUniqueEmployeeNames(employees)
    const startDates = events.map(({ Alkamisaika }) => Alkamisaika)
    const endDates = events.map(({ Loppumisaika }) => Loppumisaika)
    const dateRange = getDateRange(startDates, endDates)

    return (
        <div>
            <H3 label={amountOfTapahtumas} />

            {employeesText && (
                <Tooltip title={employeesText}>
                    <TooltipContent>
                        <H3 label={`${Translation.translateKey('asx-tyontekijat')}:`} />
                        &nbsp;<EmployeesContainer as="span">{employeesText}</EmployeesContainer>
                    </TooltipContent>
                </Tooltip>
            )}

            <H3 label={`${Translation.translateKey('Palkkahallinto.DeletionModal.Daterange')}: ${dateRange}`} />
        </div>
    )
}

export default SubHeader
