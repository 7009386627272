import { createReducer } from '@reduxjs/toolkit'
import { palkkausPageInitialized } from './palkkausActions'

export interface IPalkkausState {
    IFrameUrl: string | null
    AuthenticationToken: string | null
}

export interface IState {
    PalkkausPage: IPalkkausState
}

const initialState: IPalkkausState = {
    IFrameUrl: null,
    AuthenticationToken: null,
}

export const palkkausReducer = createReducer<IPalkkausState>(initialState, (builder) => {
    builder.addCase(palkkausPageInitialized, (state, action) => {
        const { iframeUrl, authToken } = action.payload

        state.IFrameUrl = iframeUrl
        state.AuthenticationToken = authToken

        return state
    })
})

export default palkkausReducer
