import ExtraComponent from './ExtraComponent'
import SelectedItemExtras from './SelectedItemExtras'
import { EValuePickerExtraComponent, ValuePickerExtra } from './types'
import { lazy, LazyExoticComponent } from 'react'

const InfoTextLabel = lazy(() => import('./Components/InfoTextLabel'))
const DownloadIcon = lazy(() => import('./Components/DownloadIcon'))
const HelperText = lazy(() => import('./Components/HelperText'))

const valuePickerExtrasMapping: Record<string, LazyExoticComponent<any>> = { InfoTextLabel, DownloadIcon, HelperText }

export { ExtraComponent, ValuePickerExtra, EValuePickerExtraComponent, SelectedItemExtras, valuePickerExtrasMapping }
