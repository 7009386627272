import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import EValuePickerType from '../types/EValuePickerType'

export const alueValuePickerConfiguration: TValuePickerConfiguration = {
    ValuePickerId: 'area',
    DependsOn: [],
    ItemOptionLabelFields: ['Nimi'],
    EnableTextSearch: true,
    OptionsEndpointUrl: '/Alue/Identifier',
    ValuePickerType: EValuePickerType.Dropdown,
    DataRequestParameterName: 'alueIdt',
    Text: {
        FetchError: 'Alueiden haku epäonnistui',
        Label: 'Alueet',
        MultipleSelected: 'aluetta',
    },
    TextSearchProps: ['Nimi'],
    ItemValueLabelFields: ['Nimi'],
    UseDynamicApiSearch: false,
    Limit: null,
    ExtraRows: 'Include',
    DefaultValues: null,
}
