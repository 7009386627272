import * as React from 'react'

import Icon from '.'

interface ILockIconProps {
    value: boolean
    tooltip: string
}

const LockIcon: React.FC<ILockIconProps> = ({ value = false, tooltip = '' }) => {
    return value ? (
        <Icon color={'rgb(109, 110, 113)'} tooltip={tooltip}>
            lock
        </Icon>
    ) : null
}

export default LockIcon
