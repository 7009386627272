import { formatForDataUsage, toDateObject } from '../../dates'
import { getValueAsArray } from '../../generic-utilities'
import { DayPickerV2 } from '../../generic-components'
import { IValuePickerCommonOwnProps } from '../../value-picker'
import { Moment } from 'moment'

export interface IBaseDayPickerProps extends IValuePickerCommonOwnProps<Moment[]> {
    multiselect: boolean
    shouldDateBeDisabled?: (val: string) => boolean
}

const DayPickerBase = ({
    label,
    value,
    multiselect,
    onChange,
    errors,
    required,
    valuePickerId,
}: IBaseDayPickerProps): JSX.Element => {
    const values = getValueAsArray(value) ?? []

    const formattedValues = values.map((x) => formatForDataUsage(x))

    const _onChange = (newValues: string[]) => {
        const newValueObjects = newValues.map((x) => toDateObject(x))

        onChange(newValueObjects)
    }

    return (
        <DayPickerV2
            label={label}
            multiselect={multiselect}
            onChange={_onChange}
            required={required}
            value={formattedValues}
            errors={errors}
            valuePickerId={valuePickerId}
        />
    )
}

export default DayPickerBase
