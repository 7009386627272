import { useEffect } from 'react'

const useOnWindowResize = (callback: () => void): void => {
    useEffect(() => {
        window.addEventListener('resize', callback)

        return () => {
            window.removeEventListener('resize', callback)
        }
    }, [callback])
}

export default useOnWindowResize
