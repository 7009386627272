import * as React from 'react'
import styled from '@emotion/styled'

import IAmmattinimike from 'interfaces/IAmmattinimike'
import TextIcon from 'components/atoms/TextIcon'
import { ListElementText } from '../../../configurable-list-components'

interface ITehtavaProps {
    ammattinimike: IAmmattinimike | null
    erikoistaidot?: any[] // TODO: tyypitys
}

export const AmmattinimikeText: React.FunctionComponent<{ ammattinimike: IAmmattinimike }> = ({ ammattinimike }) => (
    <ListElementText displayValue={ammattinimike.Nimi} />
)

const Container = styled.div`
    width: inherit;
    display: inline-flex;
    align-items: center;
`

/**
 * Komponentti tehtävän esittämiseen käyttöliittymässä.
 *
 * Näyttää tehtävän nimen ja ikonin, jos tehtävään liittyy erikoistaitoja.
 * Anna erikoistaidot erikoistaidot-propsissa.
 *
 * Esimerkki:
 * <Tehtava>Nuohooja</Tehtava>
 */
const Tehtava: React.FunctionComponent<ITehtavaProps> = ({ ammattinimike, erikoistaidot }) => {
    if (!ammattinimike) {
        return null
    }

    if (erikoistaidot === undefined) {
        return <AmmattinimikeText ammattinimike={ammattinimike} />
    }

    return (
        <Container>
            <AmmattinimikeText ammattinimike={ammattinimike} />
            &nbsp;
            <TextIcon>erikoistaitoja-lyhenne</TextIcon>
        </Container>
    )
}

export default Tehtava
