import * as React from 'react'
import { ListElementText } from '../../configurable-list-components'

interface ITuntipalkkaProps {
    palkka: number
    info?: string
}

const Tuntipalkka: React.FC<ITuntipalkkaProps> = ({ palkka, info }) => (
    <ListElementText displayValue={palkka} overridingTooltip={info} />
)

export default Tuntipalkka
