import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { LOGO_MAX_WIDTH } from '../../logo'
import { selectCurrentPageNavigationItems } from '../State/NavigationSelectors'
import Styles from 'constants/Styles'
import { useOnWindowResize } from '../../generic-utilities'
import FontSizeUtility from 'utilities/FontSizeUtility'
import { APP_BAR_PAGE_TITLE_MAX_WIDTH_IN_PIXELS } from './../Constants/StyleConstants'
import INavigationItem from '../Types/INavigationItem'

const getLogoSpace = () => {
    const logoRightMargin = 20
    const logoLeftMargin = 20

    return LOGO_MAX_WIDTH + logoRightMargin + logoLeftMargin
}

const calculateBreakpoint = (navigationItems: INavigationItem[], fontSize: number) => {
    const navIconWidth = Styles.layout.navigationIcon.maxWidth

    const tabsTotalWidth = navigationItems.length * parseFloat(Styles.layout.tab.width)
    const sideMarginsForTabs = 2 * parseFloat(Styles.layout.tab.marginSides)
    const widthOfIconNotifyingAboutUnreadMessages = 24

    const breapointAsRem = tabsTotalWidth + sideMarginsForTabs + parseFloat(navIconWidth)

    const breakpointAsPixels =
        fontSize * breapointAsRem +
        widthOfIconNotifyingAboutUnreadMessages +
        getLogoSpace() +
        APP_BAR_PAGE_TITLE_MAX_WIDTH_IN_PIXELS

    return breakpointAsPixels
}

const getNavItemsFitHorizontally = (navigationItems: INavigationItem[], fontSize: number) => {
    const breakpoint = calculateBreakpoint(navigationItems, fontSize)
    const viewportWidth = document.documentElement.clientWidth

    if (!viewportWidth) {
        return false
    }

    return viewportWidth > breakpoint
}

const useNavigationItemsFitHorizontally = (): boolean => {
    const [navItemsFitHorizontally, setNavItemsFitHorizontally] = useState(false)
    const navItems = useSelector(selectCurrentPageNavigationItems)

    const fontSize = FontSizeUtility.getFontSizeAsNumber()

    const setNavigationItemsFitHorizontallyIfChanged = useCallback(() => {
        const navItemsFitHorizontallyNow = getNavItemsFitHorizontally(navItems, fontSize)

        if (navItemsFitHorizontally !== navItemsFitHorizontallyNow) {
            setNavItemsFitHorizontally(navItemsFitHorizontallyNow)
        }
    }, [fontSize, navItems, navItemsFitHorizontally])

    useEffect(() => {
        setNavigationItemsFitHorizontallyIfChanged()
    }, [fontSize, navItemsFitHorizontally, setNavigationItemsFitHorizontallyIfChanged])

    useOnWindowResize(setNavigationItemsFitHorizontallyIfChanged)

    return navItemsFitHorizontally
}

export default useNavigationItemsFitHorizontally
