import moment, { isMoment, Moment } from 'moment'

export const getValueInSet = <T>(value: T): Set<T> => {
    if (value === null || value === undefined) {
        return new Set()
    }

    if (value instanceof Set) {
        return value
    }

    if (Array.isArray(value)) {
        return new Set(value)
    }

    return new Set([value])
}

export const getValueAsSet = <T>(value: T): T | Set<T> | null | undefined => {
    if (value === null || value === undefined) {
        return value
    }

    if (value instanceof Set) {
        return value
    }

    if (Array.isArray(value)) {
        return new Set(value)
    }

    return value
}

export interface IDateRangeV2 {
    startDate: Moment | string
    endDate: Moment | string
}

export const getValueAsDateRangeV2 = (value: IDateRangeV2): IDateRangeV2 | null | undefined => {
    if (value === null || value === undefined) {
        return value
    }

    if (value.startDate && !isMoment(value.startDate)) {
        value.startDate = moment(value.startDate)
    }

    if (value.endDate && !isMoment(value.endDate)) {
        value.endDate = moment(value.endDate)
    }

    return value
}

export interface IDateRange {
    start: Moment | string
    end: Moment | string
}

export const getValueAsDateRange = (value: IDateRange): IDateRange | null | undefined => {
    if (value === null || value === undefined) {
        return value
    }

    if (value.start && !isMoment(value.start)) {
        value.start = moment(value.start)
    }

    if (value.end && !isMoment(value.end)) {
        value.end = moment(value.end)
    }

    return value
}
