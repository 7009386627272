import * as React from 'react'
import Skeleton from '@material-ui/core/Skeleton'
import styled from '@emotion/styled'
import { range } from 'lodash-es'

interface ICalendarLoadingSegmentsProps {
    segmentAmount: number
}

const MainContentNodesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
`

const MainContentSegmentContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`

const MainNode = styled.div`
    height: 46px;
    display: flex;
    flex-grow: 1;
    margin-left: 4px;
    margin-top: 1px;
    margin-bottom: 1px;
    padding: 2px;
    flex-direction: row;
`

const MiddleText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    margin-left: 25px;
    margin-right: 25px;
`

const TimeTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`

const StartTimeSkeleton = styled(Skeleton)`
    margin-right: 16px;
`

const MAIN_CONTENT_NODE_AMOUNT = 12

const MainContentNode = () => (
    <MainNode>
        <Skeleton height="100%" variant="rectangular" width="20px" />
        <MiddleText>
            <Skeleton height="14px" width="28px" />
            <TimeTextContainer>
                <StartTimeSkeleton height="26px" width="36px" />
                <Skeleton height="26px" width="36px" />
            </TimeTextContainer>
        </MiddleText>
    </MainNode>
)

const MainContentSegment: React.FunctionComponent = () => (
    <MainContentSegmentContainer>
        {range(MAIN_CONTENT_NODE_AMOUNT).map((nodeNumber) => (
            <MainContentNode key={nodeNumber} />
        ))}
    </MainContentSegmentContainer>
)

const CalendarLoadingSegments: React.FunctionComponent<ICalendarLoadingSegmentsProps> = ({ segmentAmount }) => (
    <MainContentNodesContainer>
        {range(segmentAmount).map((segmentNumber) => (
            <MainContentSegment key={segmentNumber} />
        ))}
    </MainContentNodesContainer>
)
export default CalendarLoadingSegments
