import { IPageData } from './Types/IPageData'
// eslint-disable-next-line @typescript-eslint/no-unused-vars

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PAGE_BASE_URL = '/Page'

// TODO: doesn't do any request yet since there is no API
// once there is an API and the page data is all in the database,
// remove this switch case and and comments and the eslint rule disablings in this file
// Also remove the skipping of tests in `Application.test.tsx` at the same
export const fetchPageData = async (id: string): Promise<IPageData | null> => {
    /* const pageData = await makeRequest<IPageData | null>({
        method: ERequestMethod.GET,
        url: `${PAGE_BASE_URL}/${id}`,
    })

    return pageData */
    const Id = id

    switch (id) {
        case 'CollectiveAgreements': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['ClaList'],
                FormIds: null,
            }
        }
        case 'EventTypes': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['TapahtumatyyppiList'],
                FormIds: null,
            }
        }
        case 'EventTypesV2': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['EventTypes'],
                FormIds: null,
            }
        }
        case 'EventTypesWithPayrollProperties': {
            return {
                Id,
                HeaderText: 'Tapahtumatyypit',
                DescriptionText: null,
                ListIds: ['EventTypesWithPayrollProperties'],
                FormIds: null,
            }
        }
        case 'ReasonsForOrders': {
            return {
                Id,
                HeaderText: 'Tilauksen syyt',
                DescriptionText: null,
                ListIds: ['TilauksenSyyListView'],
                FormIds: null,
            }
        }
        case 'ShiftApprovalStatus': {
            return {
                Id,
                HeaderText: 'Työvuorojen hyvaksyntästatukset',
                DescriptionText: null,
                ListIds: ['TyovuorohyvaksyntastatusListView'],
                FormIds: null,
            }
        }
        case 'Translations': {
            return {
                Id,
                HeaderText: 'Kielivalinnat',
                DescriptionText: null,
                ListIds: ['KieliListView'],
                FormIds: null,
            }
        }
        case 'SalaryCategories': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['PalkkalajiListView'],
                FormIds: null,
            }
        }
        case 'Nationalities': {
            return {
                Id,
                HeaderText: 'Kansalaisuudet',
                DescriptionText: null,
                ListIds: ['KansalaisuusListView'],
                FormIds: null,
            }
        }
        case 'FormsOfWorkingHours': {
            return {
                Id,
                HeaderText: 'Työaikamuodot',
                DescriptionText: null,
                ListIds: ['TyoaikamuotoListView'],
                FormIds: null,
            }
        }
        case 'LaskuttavatYritykset': {
            return {
                Id,
                HeaderText: 'Laskuttavat yritykset',
                DescriptionText: null,
                ListIds: ['LaskuttavatYritykset'],
                FormIds: null,
            }
        }
        case 'EmployeesAndDomains': {
            return {
                Id,
                HeaderText: 'Työntekijät ja alueet',
                DescriptionText:
                    '#Employees and domains\n\nHere you can add employees to domains and remove them from them.',
                ListIds: ['TyontekijaIdentifierList', 'TyontekijaAlueRelationList'],
                FormIds: null,
            }
        }
        case 'Reports': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'WorkUnitExtraOrders': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'WorkUnitExtraEmployees': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'WorkUnitExtraEvents': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'WorkUnitExtraWorkUnitData': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'WorkUnitExtraMessages': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'WorkUnitExtraNews': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'CrmWorkUnitContacts': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'CrmTagManagement': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'CrmTickets': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'CrmPopupNewTicket': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'CrmContacts': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'IntegrationsMain': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'IntegrationsPartners': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'OsiokohtaisetAsetukset': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'PayrollAdministrationEvents': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'PayrollAdministrationEventsV2': {
            return {
                Id,
                HeaderText: 'Tapahtumat',
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'PayrollAdministrationTransactions': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'ShiftlistPackages': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'WorkforceScheduling': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'ChangeLog': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'MaintenanceExceptionLog': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'MaintenanceCompanies': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: null,
                FormIds: null,
            }
        }
        case 'WorkUnitJobTitles': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['WorkUnitJobTitles'],
                FormIds: null,
            }
        }
        case 'EmployeeExperiences': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['EmployeeExperiences'],
                FormIds: null,
            }
        }
        case 'TransferParameters': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['TransferTargetParameters'],
                FormIds: null,
            }
        }
        case 'views': {
            return {
                Id,
                HeaderText: 'Ylläpitokäyttöliittymät',
                DescriptionText: null,
                ListIds: ['MaintenanceViewList'],
                FormIds: null,
            }
        }
        case 'MaintenanceWageTables': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['PalkkasoluList'],
                FormIds: null,
            }
        }
        case 'Employees': {
            return {
                Id,
                HeaderText: 'Työntekijät',
                DescriptionText: null,
                ListIds: ['Employees'],
                FormIds: null,
            }
        }
        case 'EmployeesHybrid': {
            return {
                Id,
                HeaderText: 'Työntekijöiden kontaktointi',
                DescriptionText: null,
                ListIds: ['EmployeesHybrid'],
                FormIds: null,
            }
        }
        case 'EmployeeSkills': {
            return {
                Id,
                HeaderText: 'Työntekijäerikoistaidot',
                DescriptionText: null,
                ListIds: ['EmployeeSkills'],
                FormIds: null,
            }
        }
        case 'EmployeeContracts': {
            return {
                Id,
                HeaderText: 'Työsopimukset',
                DescriptionText: null,
                ListIds: ['EmployeeContracts'],
                FormIds: null,
            }
        }
        case 'AnnualLeaveBalances': {
            return {
                Id,
                HeaderText: 'Vuosilomasaldot',
                DescriptionText: null,
                ListIds: ['AnnualLeaveBalances'],
                FormIds: null,
            }
        }
        case 'CalculatoryPayrollTransactionRows': {
            return {
                Id,
                HeaderText: 'Laskennallinen toteuma',
                DescriptionText: null,
                ListIds: ['CalculatoryPayrollTransactionRows'],
                FormIds: null,
            }
        }
        case 'WorkUnits': {
            return {
                Id,
                HeaderText: 'Työkohteet',
                DescriptionText: null,
                ListIds: ['WorkUnits'],
                FormIds: null,
            }
        }
        case 'FulfillmentRates': {
            return {
                Id,
                HeaderText: 'Täyttöasteet',
                DescriptionText: null,
                ListIds: ['FulfillmentRates'],
                FormIds: null,
            }
        }
        case 'Projects': {
            return {
                Id,
                HeaderText: 'Projektit',
                DescriptionText: null,
                ListIds: ['Projects'],
                FormIds: null,
            }
        }
        case 'RolePermissions': {
            return {
                Id,
                HeaderText: 'Käyttöoikeudet',
                DescriptionText: null,
                ListIds: ['RolePermissions'],
                FormIds: null,
            }
        }
        case 'Users': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['Users'],
                FormIds: null,
            }
        }
        case 'Pools': {
            return {
                Id,
                HeaderText: 'Poolit',
                DescriptionText: null,
                ListIds: ['Pools'],
                FormIds: null,
            }
        }
        case 'Areas': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['Areas'],
                FormIds: null,
            }
        }
        case 'JobTitles': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['JobTitles'],
                FormIds: null,
            }
        }
        case 'Industries': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['Industries'],
                FormIds: null,
            }
        }
        case 'SalaryCategory': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['PalkkalajiListView'],
                FormIds: null,
            }
        }
        case 'WorktimeRules': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['Worktimerules'],
                FormIds: null,
            }
        }
        case 'Customers': {
            return {
                Id,
                HeaderText: 'Asiakkaat',
                DescriptionText: null,
                ListIds: ['Customers'],
                FormIds: null,
            }
        }
        case 'PaymentPrices': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['PaymentPrices'],
                FormIds: null,
            }
        }
        case 'PlannedTime': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['PlannedTime'],
                FormIds: null,
            }
        }
        case 'InvoicePrices': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['InvoicePrices'],
                FormIds: null,
            }
        }
        case 'Skills': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['Skills'],
                FormIds: null,
            }
        }
        case 'AdditionalService': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['AdditionalServices'],
                FormIds: null,
            }
        }
        case 'Booking': {
            return {
                Id,
                HeaderText: 'Vuorojen varaus',
                DescriptionText: null,
                ListIds: ['Booking'],
                FormIds: null,
            }
        }
        case 'EventTemplates': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['EventTemplate'],
                FormIds: null,
            }
        }
        case 'ContractFileTemplates': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['ContractFileTemplate'],
                FormIds: null,
            }
        }
        case 'Periods': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['Period'],
                FormIds: null,
            }
        }
        case 'TransferBatches': {
            return {
                Id,
                HeaderText: 'Palkka- ja laskutuskaudet',
                DescriptionText: null,
                ListIds: ['TransferBatches'],
                FormIds: null,
            }
        }
        case 'TransferBatches2': {
            return {
                Id,
                HeaderText: 'Siirrot',
                DescriptionText: null,
                ListIds: ['TransferBatches2'],
                FormIds: null,
            }
        }
        case 'MepcoHrmMapping': {
            return {
                Id,
                HeaderText: 'Mepco HRM -asetukset',
                DescriptionText: null,
                ListIds: ['MepcoHrmMapping'],
                FormIds: null,
            }
        }
        case 'Companies': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['Company'],
                FormIds: null,
            }
        }
        case 'PayGroups': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['PayGroup'],
                FormIds: null,
            }
        }
        case 'VATClasses': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['VATClass'],
                FormIds: null,
            }
        }
        case 'CostCodes': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['CostCode'],
                FormIds: null,
            }
        }
        case 'TransferTargets': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['TransferTargets'],
                FormIds: null,
            }
        }
        case 'Registers': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['Registers'],
                FormIds: null,
            }
        }
        case 'DefaultEventAvailabilityConstraints': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['DefaultEventAvailabilityConstraints'],
                FormIds: null,
            }
        }
        case 'RegisterItems': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['RegisterItems'],
                FormIds: null,
            }
        }
        case 'DynamicProperties': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['DynamicProperties'],
                FormIds: null,
            }
        }
        case 'ListViewColumns': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['ListViewColumns'],
                FormIds: null,
            }
        }
        case 'FormViewFields': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['FormViewFields'],
                FormIds: null,
            }
        }
        case 'InvoicingGroups': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['InvoicingGroup'],
                FormIds: null,
            }
        }
        case 'Messages': {
            return {
                Id,
                HeaderText: 'Viestintä',
                DescriptionText: null,
                ListIds: ['Messages'],
                FormIds: null,
            }
        }
        case 'Bulletins': {
            return {
                Id,
                HeaderText: 'Viestintä',
                DescriptionText: null,
                ListIds: ['Bulletins'],
                FormIds: null,
            }
        }
        case 'Comments': {
            return {
                Id,
                HeaderText: 'Viestintä',
                DescriptionText: null,
                ListIds: ['Comments'],
                FormIds: null,
            }
        }
        case 'CancellationReasons': {
            return {
                Id,
                HeaderText: null,
                DescriptionText: null,
                ListIds: ['CancellationReasons'],
                FormIds: null,
            }
        }
        case 'MessageTemplates': {
            return {
                Id,
                HeaderText: 'Viestipohjat',
                DescriptionText: null,
                ListIds: ['MessageTemplates'],
                FormIds: null,
            }
        }
        case 'EventTypeTags': {
            return {
                Id,
                HeaderText: 'Tapahtumatyyppimerkinnät',
                DescriptionText: null,
                ListIds: ['EventTypeTags'],
                FormIds: null,
            }
        }
        case 'Teams': {
            return {
                Id,
                HeaderText: 'Tiimit',
                DescriptionText: null,
                ListIds: ['Teams'],
                FormIds: null,
            }
        }
        case 'EmployeeWorkTimeBalances': {
            return {
                Id,
                HeaderText: 'Työaikasaldot',
                DescriptionText: null,
                ListIds: ['EmployeeWorkTimeBalances'],
                FormIds: null,
            }
        }
        case 'WorkUnitAttachments': {
            return {
                Id,
                HeaderText: 'Tiedostot',
                DescriptionText: null,
                ListIds: ['WorkUnitAttachments'],
                FormIds: null,
            }
        }
        case 'WorkUnitGroups': {
            return {
                Id,
                HeaderText: 'Organisaatiot',
                DescriptionText: null,
                ListIds: ['WorkUnitGroups'],
                FormIds: null,
            }
        }
        case 'EmployeeAttachments': {
            return {
                Id,
                HeaderText: 'Tiedostot',
                DescriptionText: null,
                ListIds: ['EmployeeAttachments'],
                FormIds: null,
            }
        }
        default: {
            return null
        }
    }
}
