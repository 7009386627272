import { FieldProps } from 'formik'
import * as React from 'react'

import ErrorableComponent from './ErrorableComponent'

type TRequiredFormPropsInField = Pick<FieldProps['form'], 'errors' | 'touched'>
type TRequiredFieldPropsInField = Pick<FieldProps['field'], 'name'>

type TErrorableComponentProps = React.ComponentProps<typeof ErrorableComponent>

interface IErrorableFieldProps extends Pick<TErrorableComponentProps, 'isSpaceAlwaysReservedForError'> {
    children: React.ReactNode
    immediateErrors?: ReadonlyMap<string, string>
    form: TRequiredFormPropsInField
    field: TRequiredFieldPropsInField
}

/**
 * Component to display validation error if needed within Formik as
 * a direct child of Formik's Field component.
 *
 * Renders the validation error by bordering the content and showing
 * the error text only if the field is touched and if there any errors, or
 * if the error is given in the immediateErrors prop.
 */
const ErrorableField: React.FC<IErrorableFieldProps> = ({
    form: { errors, touched },
    field: { name },
    immediateErrors,
    children,
    isSpaceAlwaysReservedForError,
}) => {
    const error = errors[name]
    const isTouched = touched[name]

    const errored: any =
        (isTouched && error) ||
        (immediateErrors ? immediateErrors.has(name) && immediateErrors.get(name) === error : undefined)

    return (
        <ErrorableComponent
            error={errored ? (error as string) : null}
            isSpaceAlwaysReservedForError={isSpaceAlwaysReservedForError}
        >
            {children}
        </ErrorableComponent>
    )
}

export default ErrorableField
