import { IOsionAsetus, IAsetus } from '../reducers/OsiokohtaisetAsetuksetReducer'
import makeRequest, { ERequestMethod } from '../../rest-api'

export const fetchOsiokohtaisetAsetukset = async (pageId: number): Promise<IOsionAsetus[]> =>
    makeRequest<IOsionAsetus[]>({
        method: ERequestMethod.GET,
        url: `/Asetus/Sivu/${pageId}`,
    })

export const saveOsiokohtaisetAsetukset = async (settingsToSave: IAsetus[]): Promise<void> =>
    makeRequest<void>({
        method: ERequestMethod.PATCH,
        url: '/Asetus',
        data: settingsToSave,
    })
