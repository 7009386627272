import { FieldProps } from 'formik'
import * as React from 'react'

import TranslatedRadioButtonGroup, { ITranslatedRadioButtonGroupProps } from './TranslatedRadioButtonGroup'

export interface IFormikRadioButtonGroupOwnProps extends ITranslatedRadioButtonGroupProps, FieldProps {}

class FormikRadioButtonGroup extends React.Component<IFormikRadioButtonGroupOwnProps> {
    render(): React.ReactNode {
        const {
            field: { value, ...field },
            ...passProps
        } = this.props

        return <TranslatedRadioButtonGroup {...field} value={value} {...passProps} />
    }
}

export default FormikRadioButtonGroup
