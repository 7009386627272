import makeRequest, { ERequestMethod } from '../../rest-api'
import { TDataSourceItemId } from '../../data-source-types'
import { TDateStringDataFormat } from '../../dates'
import { IEvent } from '../../event'
import { IIdentifier } from '../../event/Types/IEvent'
import { CancelToken } from 'axios'

export const fetchEventsForEmployee = async (
    EmployeeIds: TDataSourceItemId[],
    AikavaliAlkuaika: { Alku: TDateStringDataFormat; Loppu: TDateStringDataFormat }, // @todo fix to correct type
    cancelToken: CancelToken
): Promise<{ ListData: IEvent[] }> =>
    makeRequest<{ ListData: IEvent[] }>({
        method: ERequestMethod.POST,
        url: '/v2/Tapahtuma/Search',
        data: {
            Filters: {
                EmployeeIds,
                AikavaliAlkuaika,
            },
            GetUpdateData: false,
        },
        cancelToken,
    })

export const fetchEventTypeCategories = async (): Promise<{ ListData: IIdentifier[] }> =>
    makeRequest<{ ListData: IIdentifier[] }>({
        method: ERequestMethod.POST,
        url: '/v2/Event/TypeCategory/Identifier',
        data: {
            ExtraRows: 'Include',
            Filters: {},
            GroupBy: [],
            Limit: 200,
            Offset: 0,
            SortBy: [],
        },
    })
