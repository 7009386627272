// eslint-disable-next-line no-shadow
enum EHttpStatus {
    OK = 200,
    Created = 201,
    Unauthorized = 401,
    Forbidden = 403,
    Timeout = 408,
    InternalServerError = 500,
}

export default EHttpStatus
