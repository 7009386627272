import { createReducer } from 'typesafe-actions'

import { setPageData } from './PageActions'
import { IPageData } from '../Types/IPageData'

const initialState: ReadonlyMap<string, IPageData> = new Map()

const pageReducer = createReducer(initialState).handleAction(setPageData, (state, action) => {
    const { Id } = action.payload
    return new Map(state).set(Id, action.payload)
})

export default pageReducer
