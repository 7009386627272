import * as React from 'react'

import styled from '@emotion/styled'

import { Color, getColorCode } from 'constants/Styles'
import { ReadOnlyText } from '.'
import Icon from './Icon'

type Props = {
    borderColor: Color | string
    backgroundColor: Color | string
    textColor?: Color | string // defaults to borderColor if not set
    text: string
    translate?: boolean
    icon?: string
}

const Box = styled.div<{ color: string; borderColor: string }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 6px;
    gap: 5px;
    height: 19px;
    border-color: ${({ borderColor }) => borderColor};
    border-style: solid;
    border-width: 1px;
    background: ${({ color }) => color};
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-transform: uppercase;
`

const isColor = (color: Color | string): color is Color => {
    return !color.includes('#')
}

const TextBoxWithBackgroundColor = ({
    borderColor,
    backgroundColor,
    textColor = borderColor,
    text,
    translate,
    icon,
}: Props): JSX.Element => {
    const backgroundColorToUse = !isColor(backgroundColor) ? backgroundColor : getColorCode(backgroundColor) ?? '#fff'
    const borderColorToUse = !isColor(borderColor) ? borderColor : getColorCode(borderColor) ?? '#fff'
    const textColorToUse = !isColor(textColor) ? textColor : getColorCode(textColor)

    const useRawColor = !isColor(textColor)

    return (
        <Box color={backgroundColorToUse} borderColor={borderColorToUse}>
            {icon && (
                <Icon color={textColorToUse || borderColorToUse} size={16}>
                    {icon}
                </Icon>
            )}
            <ReadOnlyText
                translate={translate}
                rawColor={useRawColor ? textColor ?? borderColor : undefined}
                color={!useRawColor ? textColor ?? borderColor : undefined}
                usage="event_block"
            >
                {text}
            </ReadOnlyText>
        </Box>
    )
}

export default TextBoxWithBackgroundColor
