import * as React from 'react'

import { ListElementText } from '../../configurable-list-components'

export interface IProjectNumberProps {
    description: string
    number: string
}

const ProjectNumber: React.FunctionComponent<IProjectNumberProps> = ({ description, number }) => {
    if (!description) {
        return null
    }

    return <ListElementText displayValue={`${description}${number ? ` (${number})` : ''}`} />
}

export default ProjectNumber
