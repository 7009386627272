import styled from '@emotion/styled'
import { JSX, useContext } from 'react'
import { Button, Checkbox, ReadOnlyText, TextInputV2, Tooltip } from '../../generic-components'
import { useDispatch, useSelector } from 'react-redux'
import {
    newCommentFormMessageChanged,
    newCommentFormVisibilityForEmployeeChanged,
    newCommentFormVisibilityForExternalUserChanged,
} from '../State/MessagingReducer'
import {
    selectNewCommentFormIsLoading,
    selectNewCommentFormIsVisibleForEmployee,
    selectNewCommentFormIsVisibleForExternalUser,
    selectNewCommentFormMessage,
} from '../State/MessagingSelectors'
import { addCommentToEvent } from '../State/MessagingThunks'
import LoginUserContext from 'context/LoginUserContext'
import { IEvent } from '../../event'
import { EUserType } from 'interfaces/ILoginKayttaja'

const Container = styled.div<{ height: number }>`
    display: flex;
    margin: 0;
    transition: height 0.4s ease-in-out;
    min-height: ${({ height }) => `${height}px` ?? '50px'};
    overflow: hidden;
`

const ActionButtonContainer = styled.div<{ withBorder: boolean }>`
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    margin-top: 12px;
    ${({ withBorder, theme }) =>
        withBorder
            ? `border-top: 1px solid ${theme.componentExtensions.border.secondary}; padding: 24px 32px;`
            : 'padding: 0 32px 24px 32px;'}
`

const NewMessageFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    border-top: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
`

const OpenNewMessageButtonContainer = styled.div`
    padding-left: 32px;
`

const InputContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 12px;
    padding: 24px 32px 0 32px;
    overflow-y: auto;
`

const InlineNotification = styled.div`
    display: flex;
    margin-left: 32px;
    flex-direction: row;
    align-items: center;
    gap: 6px;
`

type Props = {
    showForm: boolean
    toggleShowForm: () => void
    isGroupMessage: boolean
    onClose: () => void
    events: IEvent[]
}

const NewCommentForm = ({ showForm = false, toggleShowForm, isGroupMessage, onClose, events }: Props): JSX.Element => {
    const dispatch = useDispatch()

    const user = useContext(LoginUserContext)

    const message = useSelector(selectNewCommentFormMessage)
    const isVisibleForEmployee = useSelector(selectNewCommentFormIsVisibleForEmployee)
    const isVisibleForExternalUser = useSelector(selectNewCommentFormIsVisibleForExternalUser)
    const isLoading = useSelector(selectNewCommentFormIsLoading)

    const handleNewCommentFormMessageChanged = (value: string) => {
        dispatch(newCommentFormMessageChanged(value))
    }

    const handleNewCommentFormVisibilityForEmployeeChanged = () => {
        dispatch(newCommentFormVisibilityForEmployeeChanged(!isVisibleForEmployee))
    }

    const handleNewCommentFormVisibilityForExternalUserChanged = () => {
        dispatch(newCommentFormVisibilityForExternalUserChanged(!isVisibleForExternalUser))
    }

    const handleSendNewCommentForm = () => {
        dispatch(addCommentToEvent())
    }

    const isInternalUser =
        (user?.Tyyppi === EUserType.Toiminnanohjauskayttaja && !user.IsExternalUser) ||
        user?.Tyyppi === EUserType.Jarjestelmakayttaja

    const getHeight = () => {
        if (showForm) {
            return 370
        }
        return 50
    }

    const disableShowToEmployeeSelection = events && events.length === 1 && !events[0].EmployeeId
    const canComment = events?.every((e) => e.CanComment ?? true) ?? true

    return (
        <Container height={getHeight()}>
            {showForm ? (
                <NewMessageFormContainer>
                    <InputContainer>
                        <TextInputV2
                            value={message ?? ''}
                            multiline
                            overrideStyle={{ width: '416px' }}
                            onChange={handleNewCommentFormMessageChanged}
                        />
                        {isInternalUser && (
                            <>
                                <ReadOnlyText translate usage="h4" color="primary_120">
                                    messaging.commentVisibility
                                </ReadOnlyText>
                                <InlineNotification>
                                    <Checkbox
                                        onClick={handleNewCommentFormVisibilityForEmployeeChanged}
                                        value={true}
                                        disabled
                                    />
                                    <ReadOnlyText translate usage="dropdown_selection">
                                        messaging.visibilityInternal
                                    </ReadOnlyText>
                                </InlineNotification>
                                <InlineNotification>
                                    <Checkbox
                                        value={isVisibleForExternalUser}
                                        onClick={handleNewCommentFormVisibilityForExternalUserChanged}
                                    />
                                    <ReadOnlyText translate usage="dropdown_selection">
                                        messaging.visibilityExternal
                                    </ReadOnlyText>
                                </InlineNotification>
                                <InlineNotification>
                                    <Checkbox
                                        value={isVisibleForEmployee}
                                        onClick={handleNewCommentFormVisibilityForEmployeeChanged}
                                        disabled={disableShowToEmployeeSelection}
                                    />
                                    <ReadOnlyText translate usage="dropdown_selection">
                                        messaging.visibilityEmployee
                                    </ReadOnlyText>
                                </InlineNotification>
                            </>
                        )}
                    </InputContainer>
                    <ActionButtonContainer withBorder={isGroupMessage}>
                        <Button onClick={handleSendNewCommentForm} variant="contained" disabled={isLoading}>
                            <ReadOnlyText translate usage="button" color="white">
                                messaging.formSend
                            </ReadOnlyText>
                        </Button>
                        <Button onClick={onClose} variant="text">
                            <ReadOnlyText translate usage="button" color="primary_120">
                                messaging.formClose
                            </ReadOnlyText>
                        </Button>
                    </ActionButtonContainer>
                </NewMessageFormContainer>
            ) : (
                <OpenNewMessageButtonContainer>
                    <Tooltip title={!canComment ? 'messaging.newCommentsDisabled' : ''}>
                        <Button onClick={toggleShowForm} variant="text" disabled={!canComment} startIcon="add">
                            <ReadOnlyText translate usage="button" color="primary_120">
                                messaging.showFormButton
                            </ReadOnlyText>
                        </Button>
                    </Tooltip>
                </OpenNewMessageButtonContainer>
            )}
        </Container>
    )
}

export default NewCommentForm
