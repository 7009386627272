import styled from '@emotion/styled'
import { ReadOnlyText } from '../../../generic-components'

type Props = {
    title?: string
    value?: unknown
    renderValue?: (value: unknown) => JSX.Element
}

const SummaryLabel = styled.div`
    display: flex;
    align-items: baseline;
    gap: 12px;
`

const SummaryTitle = styled(ReadOnlyText)`
    ::after {
        content: ':';
    }
`

const FormViewSummaryLabel = ({ title, value, renderValue }: Props): JSX.Element | null => {
    if (!title || !value) {
        return null
    }

    return (
        <SummaryLabel data-testid="form-view-summary-label">
            <SummaryTitle usage="h5" translate>
                {title}
            </SummaryTitle>

            {renderValue ? renderValue(value) : <ReadOnlyText usage="bodyM">{value}</ReadOnlyText>}
        </SummaryLabel>
    )
}

export default FormViewSummaryLabel
