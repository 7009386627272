import { ComponentProps, FC, ReactNode } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import GroupCounters from './GroupCounters'
import { Icon, Tooltip, TooltipWithEllipsis } from '../../generic-components'

// TODO: move this interface within calendar (or calendar-components package or something like that)
interface IGroupNodeAction {
    onClick: () => void
    tooltip: string
    icon: string
}

// TODO: Import from calendar. To be able to do it, we need to make a component registry
// for the calendar components in the same way there is for for example for list components
interface IGroupByData {
    groupMainTitle: string | null
    groupSecondaryTitle: string
    groupColor: string
}

interface IGroupNodeTemplateProps {
    primaryText: ReactNode
    additionalInfo?: ReactNode
    kpiData: string[] | null
    actions?: IGroupNodeAction[]
    infoText?: string
    groupedByData: null | IGroupByData
    /**
     * By default when the group node is grouped, the group's text overrides
     * any primary text you pass. If you want to override it, you need to
     * provide this prop to be sure that you know what you're doing.
     */
    overridePrimaryTextEvenWhenGrouped?: boolean
}

const tooltipWrapperClassName = 'pln-GroupNode-tooltipWrapper'

const Container = styled.div`
    display: flex;
    width: 200px;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 8px 0px 12px;
    height: inherit;

    .${tooltipWrapperClassName} {
        height: 50%;
    }
`

const PrimaryText = styled(Typography)`
    font-weight: bold;
`
interface IAdditionalInfoContainerProps extends ComponentProps<typeof Typography> {
    textColor: string | null
}

const AdditionalInfoContainer = styled<FC<IAdditionalInfoContainerProps>>(Typography, {
    shouldForwardProp: (prop: keyof IAdditionalInfoContainerProps) => prop !== 'textColor',
})`
    ${({ children, textColor }) =>
        children &&
        `
        padding-right: 10px;
        color: ${textColor};
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `}
`

const ActionsContainer = styled.div`
    position: relative;
    height: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
`

const ActionsInnerContainer = styled.div`
    height: 20px;
`

const ContentContainer = styled.div<{ actionsCount: number }>`
    display: flex;
    flex-direction: column;
    height: inherit;
    max-width: ${({ actionsCount }) => 100 - actionsCount * 10}%;
    padding-bottom: 1px;
`

const TextRow = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /* Ensure underlining works correctly (in work unit / employee links in legacy environments) */
    padding-bottom: 1px;
    margin-bottom: -1px;
    margin-top: -1px;

    line-height: 14px;
`

const SubTextRow = styled.div`
    display: flex;
`

const GroupNode: FC<IGroupNodeTemplateProps> = ({
    groupedByData,
    primaryText,
    additionalInfo,
    kpiData = null,
    overridePrimaryTextEvenWhenGrouped,
    actions,
    infoText,
}) => {
    const color = groupedByData?.groupColor ?? null
    const primaryTextToUse =
        !overridePrimaryTextEvenWhenGrouped && groupedByData ? groupedByData.groupMainTitle ?? '' : primaryText
    const secondaryTextToUse = groupedByData?.groupSecondaryTitle ?? additionalInfo

    return (
        <Container>
            <ContentContainer actionsCount={actions?.length ?? 0}>
                {primaryTextToUse && (
                    <Tooltip title={primaryTextToUse + ' ' + (infoText ?? '')}>
                        <TextRow>
                            <PrimaryText noWrap variant="caption">
                                {primaryTextToUse}
                            </PrimaryText>
                        </TextRow>
                    </Tooltip>
                )}
                {secondaryTextToUse ||
                    (kpiData && kpiData.length > 0 && (
                        <SubTextRow>
                            {secondaryTextToUse && (
                                <TooltipWithEllipsis childWrapperElement={null} title={secondaryTextToUse}>
                                    {(ref) => (
                                        <AdditionalInfoContainer ref={ref} textColor={color} variant="caption">
                                            {secondaryTextToUse}
                                        </AdditionalInfoContainer>
                                    )}
                                </TooltipWithEllipsis>
                            )}
                            <GroupCounters counters={kpiData} />
                        </SubTextRow>
                    ))}
            </ContentContainer>

            <ActionsContainer>
                <ActionsInnerContainer>
                    {actions?.map(({ onClick, tooltip, icon }) => (
                        <Icon colorTheme="primary" key={icon} onClick={onClick} tooltip={tooltip}>
                            {icon}
                        </Icon>
                    ))}
                </ActionsInnerContainer>
            </ActionsContainer>
        </Container>
    )
}

export default GroupNode
