const ID_DEFAULT_FIELD = 'Id'

/**
 * Muuttaa tai lisää kenttiä halutulle oliolle taulukossa.
 *
 * HUOM. jotta tämä tämä toimii. Olion täytyy olla "littana", eli sillä ei saa
 * olla sisäkkäisiä olioita. Datan täytyy siis olla normalisoitu.
 * // TODO: Normalisointiin ohjeita.
 *
 * @param arrayToCopyTo Taulukko, jossa muokattava tieto on.
 * @param updateData Päivitettävä data (oliona), joka sisältää kenttiä ja niiden arvoja jotka lisätään tai päivitetään
 *                   yksittäiselle oliolle taulukossa.
 * @param idValue Päivitettävän olion id.
 * @param idField ID-kenttä, jota oliot käyttävät. Defaulttina 'Id'.
 */
export const updateInArray = (
    arrayToCopyTo: any[],
    updateData: Record<string, unknown>,
    idValue: number,
    idField: string = ID_DEFAULT_FIELD
): any[] => {
    const updatedArray: any[] = arrayToCopyTo.map((olio) => {
        return olio[idField] === idValue ? { ...olio, ...updateData } : olio
    })

    return updatedArray
}

/**
 * Joko lisää arvon settiin tai poistaa sen siitä.
 * Ei muokkaa olemassa olevaa settiä vaan tekee muutokset kopioon.
 *
 * @param setObject Set, joka voi olla numero tai string tyyppinen
 * @param value arvo, joka lisätään settiin tai poistetaan siitä
 */
export function removeFromOrAddToSet<T>(setObject: ReadonlySet<T>, value: T): ReadonlySet<T> {
    const copiedSet = new Set<T>(setObject)

    if (!copiedSet.has(value)) {
        return copiedSet.add(value)
    }

    copiedSet.delete(value)
    return copiedSet
}

/**
 * Joko palauttaa tyhjän setin tai setin, jossa on kaikki annetut arvot
 *
 * @param values taulukko numeroita tai stringejä
 * @param add pistetäänkö arvot Settiin vai palautetaanko tyhjä Set
 */
export function createEmptySetOrWithAllGivenValues<T>(values: T[], add: boolean): Set<T> {
    return !add ? new Set<T>() : new Set<T>(values)
}
