import { CancelToken } from 'axios'

import { IThunkBaseAction } from '../../generic-state'
import {
    selectChangedDependentValuePickerConfigurations,
    selectValuePickerConfiguration,
    selectValuePickerValue,
    setValuePickerValueAction,
    TValuePickerWithOptionsConfiguration,
} from '../../value-picker'
import {
    fetchStoredOptionsDataThunk,
    IFetchStoredOptionsDataThunkOptions,
    IStoredOptionsOptionIdentifierObject,
    TStoredOptionsSelectedItemIds,
} from '../../stored-options'

export const fetchValuePickerWithOptionsDataThunk =
    <TOption extends IStoredOptionsOptionIdentifierObject>(
        valuePickerId: string,
        thunkOptions?: IFetchStoredOptionsDataThunkOptions,
        dynamicParameters?: Record<string, unknown>,
        dataFetchCancelToken?: CancelToken
    ): IThunkBaseAction<TOption[] | null> =>
    async (dispatch, getState) => {
        const valuePickerConfiguration = selectValuePickerConfiguration(getState(), valuePickerId)

        if (!valuePickerConfiguration) {
            return null
        }

        const {
            DependsOn: dependsOn,
            OptionsEndpointUrl: optionsEndPointUrl,
            Text: { FetchError: fetchErrorText },
            Limit: limit,
            ExtraRows: extraRows,
            AdditionalDataFetchParameters: additionalDataFetchParameters = {},
            ItemIdField: itemIdField,
        } = valuePickerConfiguration as TValuePickerWithOptionsConfiguration

        const dependsOnToUse = dependsOn.map(
            ({
                AdditionalParams: additionalParams,
                FilterType: filterType,
                ValuePickerId: componentId,
                ParameterName: parameterName,
            }) => ({
                additionalParams,
                filterType,
                componentId,
                parameterName,
            })
        )
        const queryConfiguration = {
            dependsOn: dependsOnToUse,
            optionsEndPointUrl,
            fetchErrorText,
            limit,
            extraRows,
            additionalDataFetchParameters,
        }

        const getStoredComponentValue = (valuePickerIdToQueryFor: string) =>
            selectValuePickerValue(getState(), valuePickerIdToQueryFor)

        return dispatch(
            fetchStoredOptionsDataThunk<TOption>(
                valuePickerId,
                queryConfiguration,
                getStoredComponentValue,
                { ...thunkOptions, itemIdField },
                dynamicParameters,
                dataFetchCancelToken
            )
        )
    }

export const fetchDataForDependentValuePickerWithOptionsThunk =
    (changedValuePickerId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const dependentValuePickers = selectChangedDependentValuePickerConfigurations(getState(), changedValuePickerId)

        dependentValuePickers.forEach(({ ValuePickerId }) =>
            dispatch(fetchValuePickerWithOptionsDataThunk(ValuePickerId, { useCachedValues: false }))
        )
    }

interface ISetValuePickerWithOptionsValueThunkOptions {
    fetchDataForDependentValuePickers?: boolean
}

export const setValuePickerWithOptionsValueThunk =
    (
        valuePickerId: string,
        selected: TStoredOptionsSelectedItemIds,
        contextId?: string,
        { fetchDataForDependentValuePickers = true }: ISetValuePickerWithOptionsValueThunkOptions = {},
        sideEffects?: [{ key: string; value: string }]
    ): IThunkBaseAction =>
    async (dispatch) => {
        dispatch(setValuePickerValueAction(selected, valuePickerId, contextId, sideEffects))

        if (fetchDataForDependentValuePickers) {
            dispatch(fetchDataForDependentValuePickerWithOptionsThunk(valuePickerId))
        }
    }
