import * as React from 'react'
import styled from '@emotion/styled'
import PopoverWithComponent from '../../PopoverWithComponent'
import Checkbox from '../Checkbox'

export interface IDropdownOption {
    Id: Identifier
    Name: string
}

const StyledPopoverWithComponent = styled(PopoverWithComponent)``

export type Identifier = string | number

export interface IPopoverMenuProps {
    label: string
    onChange?: (val: Identifier[]) => void
    isMultiselect: boolean
    value: Identifier[]
    options: IDropdownOption[]
    isLoading?: boolean
    viewComponentRenderer: (openFunc: (e: React.MouseEvent) => void) => React.ReactNode
}

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 330px;
`

interface IOptionRow {
    Id: Identifier
    Label: string
    IsSelected: boolean
    onClick: (id: Identifier) => void
}

const OptionRow = ({ Id, IsSelected, Label, onClick }: IOptionRow) => {
    return <Checkbox value={IsSelected} onClick={() => onClick(Id)} label={Label} id={`${Id}-${Label}`} />
}

const PopoverMenu: React.FunctionComponent<IPopoverMenuProps> = ({
    viewComponentRenderer,
    onChange,
    value,
    options,
}) => {
    const selectedValues = new Set(value)

    const onSelect = (id: Identifier) => {
        const selectedValueCopy = new Set(selectedValues)
        selectedValueCopy.add(id)

        const newValues = [...selectedValueCopy]

        onChange && onChange(newValues)
    }

    const onUnSelect = (id: Identifier) => {
        const selectedValueCopy = new Set(selectedValues)
        selectedValueCopy.delete(id)

        const newValues = [...selectedValueCopy]

        onChange && onChange(newValues)
    }

    return (
        <StyledPopoverWithComponent
            anchorOriginHorizontal="left"
            openPopoverRenderer={(onMenuOpen) => viewComponentRenderer(onMenuOpen)}
        >
            {
                <ContentContainer>
                    {options.map((opt) => (
                        <OptionRow
                            key={opt.Id}
                            Id={opt.Id}
                            Label={opt.Name}
                            IsSelected={selectedValues.has(opt.Id)}
                            onClick={selectedValues.has(opt.Id) ? onUnSelect : onSelect}
                        />
                    ))}
                </ContentContainer>
            }
        </StyledPopoverWithComponent>
    )
}

export default PopoverMenu
