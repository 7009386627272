import * as React from 'react'
import styled from '@emotion/styled'

import { Icon } from '../../../generic-components'

interface INodeSelectedProps {
    infoText: string | null
    nodeSelected: boolean
    nodeIdWithPrefix: string
}

const StyledInfoIcon = styled(Icon)`
    display: flex;
`

const Container = styled.div`
    visibility: hidden;
    position: absolute;
    display: inline-block;
    right: 5px;
    cursor: help;
    z-index: 1;
`

export const nodeIdPrefix = 'nodeinfo'

const NodeInfo: React.FunctionComponent<INodeSelectedProps> = ({ infoText, nodeSelected, nodeIdWithPrefix }) => {
    if (!infoText || nodeSelected) {
        return null
    }

    return (
        <Container id={nodeIdWithPrefix}>
            <StyledInfoIcon colorTheme="primary" size={14} tooltip={infoText}>
                info
            </StyledInfoIcon>
        </Container>
    )
}

export default NodeInfo
