import styled from '@emotion/styled'
import * as React from 'react'
import { JSX, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setColumnWidthAction } from '../../State/ConfigurableListActions'
import { selectColumnWidth, selectColumnWidths, selectPinnedColumns } from '../../State/ConfigurableListSelectors'
import { RootState } from 'typesafe-actions'
import IconButton from '@material-ui/core/IconButton'
import { Icon, IMenuItemProps, MenuWithItems } from '../../../generic-components'
import { hideColumnThunk, pinColumnThunk } from '../../Thunks/ColumnManagementThunks'
import { openModalAction } from '../../../modal'
import EConfigurableListModal from '../../Types/EConfigurableListModal'
import { useAppTheme } from '../../../theme'
import { setColumnSortedAndFetchSortedDataThunk } from '../../Thunks/ConfigurableListThunks'
import { IDataSourceSortParameter } from '../../../data-source-types'
import { translate } from '../../../localization'
import { saveColumnWidths } from '../../Utilities/StorageUtils'
import { isNil } from 'lodash-es'

type Props = {
    id: string
    listId: string
    defaultWidth: number
    sortByParameter: IDataSourceSortParameter['Property']
    isIcon?: boolean
    isLastPinned?: boolean
}

const ABSOLUTE_MIN_WIDTH = 50

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
`

const ActionsContainer = styled.div<{ hovering?: boolean; dragging?: boolean; isLastPinned?: boolean }>`
    position: absolute;
    right: ${({ isLastPinned }) => (isLastPinned ? '-5px' : '0')};
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: ${({ hovering, dragging }) => (!hovering && !dragging ? '0' : '1')};
`

const DragHandle = styled.div<{ dragging?: boolean }>`
    height: 100%;
    width: 5px;
    cursor: ew-resize;
    border-left: 2px solid
        ${({ theme, dragging }) =>
            !dragging ? theme.componentExtensions.border.secondary : theme.componentExtensions.global.action};

    &:hover {
        border-left-color: ${({ theme }) => theme.componentExtensions.global.action};
    }
`

// ensures the cursor stays on the drag handle while dragging
const DragHandleBackground = styled.div`
    position: absolute;
    right: 0;
    height: 100%;
    cursor: ew-resize;
    z-index: 1;
    width: 100px;
    transform: translateX(50px);
`

const ColumnActionsMenu = ({ id, listId, sortByParameter }: Omit<Props, 'defaultWidth'>): JSX.Element => {
    const dispatch = useDispatch()

    const { componentExtensions } = useAppTheme()

    const pinnedColumns = useSelector((state: RootState) => selectPinnedColumns(state, listId))
    const isPinned = pinnedColumns.some(({ Id }) => Id === id)

    const handleSort = (sortOrder: IDataSourceSortParameter['Order']) => {
        dispatch(setColumnSortedAndFetchSortedDataThunk(listId, sortOrder, sortByParameter, true))
    }

    const sortActions: IMenuItemProps[] = !isNil(sortByParameter)
        ? [
              {
                  label: translate('configurableList.columnActions.ascending'),
                  icon: 'arrow_upward',
                  onClick: () => handleSort('DESC'),
              },
              {
                  label: translate('configurableList.columnActions.descending'),
                  icon: 'arrow_downward',
                  onClick: () => handleSort('ASC'),
              },
          ]
        : []

    const menuItems: IMenuItemProps[] = [
        ...sortActions,
        {
            label: translate(`configurableList.columnActions.${!isPinned ? 'pinColumn' : 'unpinColumn'}`),
            icon: !isPinned ? 'push_pin' : 'clear',
            onClick: () => dispatch(pinColumnThunk(id, listId)),
        },
        {
            label: translate('configurableList.columnActions.hideColumn'),
            icon: 'visibility_off',
            onClick: () => dispatch(hideColumnThunk(id, listId)),
        },
        {
            label: translate('configurable-list.ColumnManagementModal.EditColumns'),
            icon: 'view_column',
            onClick: () => dispatch(openModalAction(`${EConfigurableListModal.ColumnManagement}-${listId}`)),
        },
    ]

    return (
        <MenuWithItems
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: -50,
            }}
            menuItemsData={menuItems}
        >
            {(onMenuOpen) => (
                <IconButton size="small" onClick={onMenuOpen} data-testid="open-column-actions-button">
                    <Icon color={componentExtensions.icons.iconAction}>more_vert</Icon>
                </IconButton>
            )}
        </MenuWithItems>
    )
}

const ColumnEditControls = ({
    id,
    listId,
    defaultWidth,
    sortByParameter,
    isIcon,
    isLastPinned,
    children,
}: PropsWithChildren<Props>): JSX.Element => {
    const dispatch = useDispatch()

    const [hovering, setHovering] = useState<boolean>(false)
    const [dragging, setDragging] = useState<boolean>(false)

    const containerRef = useRef<HTMLDivElement | null>(null)
    const dragHandleRef = useRef<HTMLDivElement | null>(null)
    const startWidth = useRef<number>(0)
    const startPosition = useRef<number>(0)

    const currentWidths = useSelector((state: RootState) => selectColumnWidths(state, listId))
    const columnWidth = useSelector((state: RootState) => selectColumnWidth(state, listId, id)) || defaultWidth

    const onDragStart = useCallback(
        ({ clientX }: PointerEvent) => {
            startWidth.current = columnWidth
            startPosition.current = clientX

            setDragging(true)
        },
        [columnWidth]
    )

    const onDragEnd = useCallback(() => {
        if (!dragging) {
            return
        }

        setDragging(false)
        saveColumnWidths(listId, currentWidths)
    }, [currentWidths, dragging, listId])

    const onMouseMove = useCallback(
        ({ clientX }: PointerEvent) => {
            if (!dragging) {
                return
            }

            const moveDistance = clientX - startPosition.current

            const minWidth = !isIcon && defaultWidth > ABSOLUTE_MIN_WIDTH ? defaultWidth / 2 : ABSOLUTE_MIN_WIDTH

            const newColumnWidth = Math.max(startWidth.current + moveDistance, minWidth)

            dispatch(setColumnWidthAction(id, newColumnWidth, listId))
        },
        [defaultWidth, dispatch, dragging, id, isIcon, listId]
    )

    useEffect(() => {
        const container = containerRef.current
        const dragHandle = dragHandleRef.current

        if (!dragHandle || !container) {
            return
        }

        const onSelect = (event: PointerEvent) => {
            if (dragging) {
                event.preventDefault()
            }
        }

        const onMouseOver = () => setHovering(true)
        const onMouseOut = () => setHovering(false)

        container.addEventListener('mouseover', onMouseOver)
        container.addEventListener('mouseout', onMouseOut)

        dragHandle.addEventListener('mousedown', onDragStart)

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onDragEnd)
        document.addEventListener('selectstart', onSelect)

        return () => {
            container.removeEventListener('mouseover', onMouseOver)
            container.removeEventListener('mouseout', onMouseOut)

            dragHandle.removeEventListener('mousedown', onDragStart)

            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onDragEnd)
            document.removeEventListener('selectstart', onSelect)
        }
    }, [dragging, onDragEnd, onDragStart, onMouseMove])

    return (
        <Container ref={containerRef}>
            {children}

            <ActionsContainer hovering={hovering} dragging={dragging} isLastPinned={isLastPinned}>
                <ColumnActionsMenu id={id} listId={listId} sortByParameter={sortByParameter} />

                <DragHandle ref={dragHandleRef} dragging={dragging} data-testid="drag-handle" />
                {dragging && <DragHandleBackground />}
            </ActionsContainer>
        </Container>
    )
}

export default ColumnEditControls
