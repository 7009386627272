import { createAction } from 'typesafe-actions'

import ILoginKayttaja from 'interfaces/ILoginKayttaja'
import IOhjeinfo from 'interfaces/IOhjeinfo'

interface IApplicationInitializingData {
    readonly sisaanKirjautunutKayttaja: ILoginKayttaja
    readonly tyylit: Map<string, string>
    readonly ohjeet: IOhjeinfo[]
    readonly asetukset: Map<string, string | number | boolean>
    readonly logoutUrl: string
}

export const initializeApplicationAction = createAction('ApplicationInitialised')<IApplicationInitializingData>()
export const userPilotInitializedAction = createAction('UserPilotInitialized')()
export const setCookiesAcceptedAction = createAction('SetCookiesAcceptedAction')<boolean>()
