import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchListDataThunk } from '../Thunks/ConfigurableListThunks'
import { dataSourceSetOffsetAction, selectDataSourceOffset, TDataSourceId } from '../../data-source'
import { MAX_LIST_ROWS } from '../components/ListContent/constants/ListConstants'
import { RootState } from 'typesafe-actions'

const isBottomReached = () => {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight
    const body = document.body
    const html = document.documentElement
    const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
    )
    const windowBottom = windowHeight + window.pageYOffset
    let isBottom = false
    if (Math.ceil(windowBottom) >= docHeight) {
        isBottom = true
    }
    return isBottom
}

const useFetchMoreDataAtScrollToBottom = (
    allItemsCount: number,
    dataSourceId: TDataSourceId | null,
    listId: string,
    shouldAddScrollListener: boolean,
    hasNoMoreDataToFetch: boolean
): boolean => {
    const dispatch = useDispatch()
    const offsetValue = useSelector<RootState, number>((state) => selectDataSourceOffset(state, dataSourceId))
    const [isLoadingMoreData, setIsLoadingMoreData] = useState(false)

    const trackScrolling = useCallback(
        async (event) => {
            event.preventDefault()
            if (!isBottomReached()) {
                return
            } else if (isLoadingMoreData === false) {
                const newOffset = offsetValue + MAX_LIST_ROWS
                setIsLoadingMoreData(true)

                dispatch(dataSourceSetOffsetAction(dataSourceId, newOffset))
                await dispatch(fetchListDataThunk(listId, { resetSelected: false, showLoadingSpinner: false }))
                setIsLoadingMoreData(false)
            }
        },
        [dataSourceId, dispatch, isLoadingMoreData, listId, offsetValue]
    )
    useEffect(() => {
        if (shouldAddScrollListener && allItemsCount > MAX_LIST_ROWS && !hasNoMoreDataToFetch) {
            document.addEventListener('scroll', trackScrolling)
        }
        return () => {
            if (shouldAddScrollListener && allItemsCount > MAX_LIST_ROWS && !hasNoMoreDataToFetch) {
                document.removeEventListener('scroll', trackScrolling)
            }
        }
    }, [shouldAddScrollListener, trackScrolling, allItemsCount, hasNoMoreDataToFetch])

    return isLoadingMoreData
}

export default useFetchMoreDataAtScrollToBottom
