import PropTypes from 'prop-types'
import * as React from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'

import Styles from 'constants/Styles'

interface IOwnProps {
    children: JSX.Element
    open: boolean
    zIndex: number
    disableBackgroundScoll: boolean
}

const Container = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: ${(props: IOwnProps) => props.zIndex};
`

/**
 * Luo suoraan bodyn alle overlayn. Tällöin ei tule ongelmia dialogien päällekkäin
 * pinoamisen kanssa (ainakin iPhonella stacking context toimii sen verran eri
 * tavalla että se voi tuottaa ongelmia jos DOM-puussa yritetään laittaa useampi
 * dialogi päällekkäin) kun komponentit pinotaan mounttaus-järjestyksessä.
 *
 * Huom. komponentin renderöidessä overlayn, komponentti samalla disabloi body-elementistä
 * skrollauksen kokonaan ja palauttaa sen kun overlay poistuu. Mobiiliselaimilla muuten
 * käy helposti niin että käyttäjä ei saa overlayta skrollattua vaan että skrollataankin
 * taustaa. Pöytäselaimilla efekti ei ole ihan niin paha, mutta silti hieman häiritsevää,
 * että taustaa voisi skrollata jos hiiren siirtää pois varsinaisen skrollattavan overlay-alueen
 * päältä.
 * Varmuuden vuoksi myös disabloi skrollauksen Contactorin juurielementiltä (eka div-elemntti
 * bodyn alla).
 */
class OverlayUnderBody extends React.Component<IOwnProps> {
    static readonly defaultProps = {
        open: false,
        zIndex: Styles.layer.overlay,
        disableBackgroundScoll: false,
    }

    static readonly propTypes = {
        children: PropTypes.element.isRequired,
        open: PropTypes.bool,
        zIndex: PropTypes.number,
    }

    componentDidMount(): void {
        if (this.props.disableBackgroundScoll && this.props.open) {
            this.disableBodyScroll()
        }
    }

    componentDidUpdate(oldProps: IOwnProps): void {
        if (!this.props.disableBackgroundScoll) {
            return
        }
        const openNow = this.props.open

        const justOpened = openNow && !oldProps.open
        const justClosed = !openNow && oldProps.open

        if (justOpened) {
            this.disableBodyScroll()
        } else if (justClosed) {
            this.resetBodyScroll()
        }
    }

    componentWillUnmount(): void {
        if (!this.props.disableBackgroundScoll) {
            return
        }
        this.resetBodyScroll()
    }

    private resetBodyScroll() {
        document.body.style.overflow = ''

        this.setContactorRootElementOverflow('')
    }

    private disableBodyScroll() {
        document.body.style.overflow = 'hidden'

        this.setContactorRootElementOverflow('hidden')
    }

    private setContactorRootElementOverflow(overflowValue: string) {
        const contactorRootElement = document.body.children[0] as HTMLElement
        if (contactorRootElement) {
            contactorRootElement.style.overflow = overflowValue
        }
    }

    render(): React.ReactNode {
        if (!this.props.open) {
            return null
        }

        return ReactDOM.createPortal(<Container {...this.props}>{this.props.children}</Container>, document.body)
    }
}

export default OverlayUnderBody
