import * as React from 'react'
import ReactDatepicker, { ReactDatePickerProps } from 'react-datepicker'

import Styles from 'constants/Styles'

export interface IStyledDatepickerProps extends ReactDatePickerProps {}

const StyledDatepicker: React.FunctionComponent<IStyledDatepickerProps> = (props) => (
    <ReactDatepicker dateFormat={Styles.date.displayFormat} {...props} />
)

export default StyledDatepicker
