import { Moment } from 'moment'
import { IDataSourceGroupData, IDataSourceItem, IDataSourceWindowFunctionData } from '../../../data-source-types'
import { IDisplayAction, TFunctionalityAction } from '../../../view-engine'

export type CalendarContentType = 'event'

export interface IDataSourceReponse {
    ListData: IEventModelRaw[]
    GroupingResponse: IDataSourceGroupData[]
}

export interface ICalendarConfiguration {
    valuePickerIds: string[]
    groupingConfiguration: IGroupingConfiguration
}

export interface ICalendarNodeContent {
    id: string
    day: Moment
    info: string | null
    type: CalendarContentType
    width: number
    indent: number
    colorId: string
    isDisabled: boolean
    isSelectable: boolean
    data: IEventModel
}

export interface IMetrics {
    overallMetrics: IGroupData[]
    workUnitMetrics: IWorkUnitMetrics[]
}

export interface IFormattedCalendarData {
    startDate: Moment
    endDate: Moment
    idMap: string[][]
    items: ICalendarNodeContent[]
    sizeY: number
    sizeX: number
    groups: IGroupData[]
    metrics: IMetrics
    totalGroup: IDataSourceWindowFunctionData[]
}

export interface ICalendarContentState {
    isContentInitialized: boolean
    idMap: string[][]
    groups: IGroupData[]
    totalGroup: IDataSourceWindowFunctionData[]
    items: ICalendarNodeContent[]
    tooMuchDataToRender: boolean
}

export interface IWorkUnitMetrics {
    workUnitId: string
    metrics: IDataSourceGroupData[]
}

export interface ICalendarState extends ICalendarContentState, ICalendarStructureState {
    showDayCalculations: boolean
    metrics: {
        overallMetrics: IGroupData[]
        workUnitMetrics: IWorkUnitMetrics[]
    }
}

export interface ICalendarAction {
    Functionality: TFunctionalityAction
    Display: IDisplayAction
    IsPrimaryAction?: boolean
    IsDoubleClickAction?: boolean
    RequiresMultipleItems?: boolean
}

export interface ICalendarStructureState {
    holidays: { Date: string; Name: string }[]
    startDay: Moment
    nextStartDay: Moment
    endDay: Moment
    nextEndDay: Moment
    sizeX: number
    nextSizeX: number
    sizeY: number
    eventTemplates: IEventTemplate[]
    isStructureInitialized: boolean
    cursorIndexX: number | null
    cursorIndexY: number | null
    clipBoardContent: string[]
    clipBoardAction: 'copy' | 'cut' | undefined
    selectedCells: { x: number; y: number }[]
    pendingForActionContent: number[]
    pendingAction: string | null
    calendarConfiguration: ICalendarConfiguration
    isEditOpen: boolean
    isCreateOpen: boolean
    IsEditedTextValid: boolean
    isEventTimeEdited: boolean
    editedTimeAsText: string
    areSelectedCellsLoading: boolean
}

export interface IEventModel extends IDataSourceItem {
    StartTime: Moment
    IsPublished: boolean
    JobTitle?: {
        Id: number
        Lyhenne: string | null
        Name: string
    }
    WorkUnit?: {
        Id: number
        Name: string
    }
    Info: string
    IsRecurringEvent: boolean
    ColorId: string | null
    HasEmployee: boolean
    EndTime: Moment
    EventClassId: number
    EventType: {
        Id: number
        Lyhenne: string
        Name: string
    }
    Skills: {
        Id: number
        Name: string
    }[]
    WorkTimeRuleViolations: string[]
    Employee: {
        Id: number
        Name: string
    } | null
}

export interface IEventModelRaw extends Omit<IEventModel, 'StartTime' | 'EndTime'> {
    StartTime: string
    EndTime: string
}

export type DisplayType = 'MetricsOnly' | 'AggregateEvents' | 'Events' | 'EmptyEventsOnly' | 'None'

export type GroupType = 'WorkUnit' | 'JobTitle' | 'Employee'

export interface IGroupingConfiguration {
    type: GroupType
    displayType: DisplayType
    subGroup?: IGroupingConfiguration
}

export interface IGroupProperties {
    workunitId?: number
    workunitName?: string
    jobTitleId?: number
    jobTitleName?: string
    employeeId?: number
    employeeName?: string
}

export interface IEventTemplate {
    Id: number
    ApplicableWorkUnitIds: number[]
    ApplicableJobTitleIds: number[]
    ApplicableEmployeeIds: number[]
    Name: string
}

export interface IGroupData {
    type: GroupType
    itemIds: string[] | null
    displayGroupData: boolean
    displayType: DisplayType
    groupProperties: IGroupProperties
    metrics: IGroupDataMetric[]
    infoText: string
}

export interface IGroupDataMetric {
    label: string
    value: string
    unit: string
}

//eslint-disable-next-line no-shadow
export enum EventClassId {
    Shift = 1,
    Break = 2,
    Other = 3,

    Task = 5,
    Wish = 6,
    Block = 7,

    AssignableOther = 8,
    Transfer = 9,

    V_and_X_Days = 12,
}

export const FALLBACK_EVENT_CLASS = 3
