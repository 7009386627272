import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { useContext, useState } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'

import { Logo } from '../../../../logo'
import { MainNavigationLegacy } from '../../../../navigation'
import Styles from 'constants/Styles'

import TyylitContext from 'context/TyylitContext'

const StyledIconbutton = styled(IconButton)`
    margin-left: 1.536rem;
    color: ${Styles.mainColor.white};

    @media only screen and (max-width: 400px) {
        margin-left: -4px;
    }
`

const IconButtonContainer = styled.div`
    display: flex;
`
// The z-index here fixes a potential IE problem with z-indexes and position
// see: http://stackoverflow.com/questions/12517158/z-index-in-internet-explorer-not-working/12517272#12517272
const MainContainer = styled.div`
    z-index: 10000;
    position: -ms-device-fixed;
`

const StyledDrawer = styled(Drawer)`
    /* Remove the horizontal scrollbar */
    & .MuiDrawer-paper {
        overflow-x: hidden;
    }
`

const LogoContainer = styled.div<{ backgroundColor: string | undefined }>`
    height: 4.571rem;
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding-top: 0.675rem;
    width: 100%;
`

const MainDrawer: React.FC = () => {
    const stylesContext = useContext(TyylitContext)
    const [drawerOpen, setDrawerOpen] = useState(false)

    const _renderIconButton = () => {
        return (
            <IconButtonContainer>
                <StyledIconbutton onClick={handleDrawerOpen}>
                    <MenuIcon />
                </StyledIconbutton>
            </IconButtonContainer>
        )
    }

    const handleDrawerClose = React.useCallback(() => {
        setDrawerOpen(false)
    }, [])

    const handleDrawerOpen = React.useCallback(() => {
        setDrawerOpen(true)
    }, [])

    if (!drawerOpen) {
        return _renderIconButton()
    }

    return (
        <MainContainer>
            <StyledDrawer onClose={handleDrawerClose} open={drawerOpen}>
                <LogoContainer backgroundColor={stylesContext.get('header.navigation.backgroundColor')}>
                    <Logo />
                </LogoContainer>
                <MainNavigationLegacy closeDrawerCallback={handleDrawerClose} navItemsInDrawer />
            </StyledDrawer>
        </MainContainer>
    )
}

export default MainDrawer
