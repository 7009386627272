import { getPrimaryEventColor, getSecondaryEventColor, PlanierTheme } from './AppTheme'

export const getBackgroundColorForId = (colorId: string, theme: PlanierTheme): string => {
    const { colors } = theme

    switch (colorId) {
        case 'event_block':
            return colors.semanticWarning11
        case 'event_wish':
            return colors.semanticSuccess11
        case 'event_other':
            return colors.secondaryPink30
        case 'event_base':
            return colors.primaryTeal11
        case 'event1':
        case 'event_1':
            return getSecondaryEventColor('event1')
        case 'event2':
        case 'event_2':
            return getSecondaryEventColor('event2')
        case 'event3':
        case 'event_3':
            return getSecondaryEventColor('event3')
        case 'event4':
        case 'event_4':
            return getSecondaryEventColor('event4')
        case 'event5':
        case 'event_5':
            return getSecondaryEventColor('event5')
        case 'event6':
        case 'event_6':
            return getSecondaryEventColor('event6')
        default:
            return '#fff'
    }
}

export const getTextColorForId = (colorId: string, theme: PlanierTheme): string => {
    const { colors } = theme

    switch (colorId) {
        case 'event_block':
            return colors.semanticWarning140
        case 'event_wish':
            return colors.semanticSuccess140
        case 'event_other':
            return '#474b6a'
        case 'event_base':
            return colors.primaryTeal120
        case 'event1':
        case 'event_1':
            return getPrimaryEventColor('event1')
        case 'event2':
        case 'event_2':
            return getPrimaryEventColor('event2')
        case 'event3':
        case 'event_3':
            return getPrimaryEventColor('event3')
        case 'event4':
        case 'event_4':
            return getPrimaryEventColor('event4')
        case 'event5':
        case 'event_5':
            return getPrimaryEventColor('event5')
        case 'event6':
        case 'event_6':
            return getPrimaryEventColor('event6')
        default:
            return '#fff'
    }
}
