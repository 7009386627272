import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { ReadOnlyText } from '../../../../generic-components'

import { closeModalAction, ConfirmationDialog, selectIsModalOpen } from '../../../../modal'
import { selectSelectedEvents } from '../../State/CalendarState'
import { Moment } from 'moment'

import { CALENDAR_DATASOURCE_ID } from '../../Constants'
import { executeDataItemActionThunk } from '../../../../data-source'
import { displayErrorToaster } from '../../../../notifications'
import { uniq, uniqBy } from 'lodash-es'

interface IPublishEventProps {
    formId: string
    title: string
}

const PUBLISH_PERMISSION = 'PUBLISH'

const PUBLISH_LABEL = 'Julkaistaan vain julkaisemattomat tapahtumat valituista tapahtumista'

const PublishSummary: React.FC<{
    numberOfEvents: number
    numberOfPublishableEvents: number
    StartDate: Moment
    EndDate: Moment
    EventTypes: string[]
    Workunits: string[]
}> = (props) => {
    return (
        <>
            <ReadOnlyText usage="list_data">{`Julkaistavat tapahtumat: ${props.numberOfPublishableEvents} kpl`}</ReadOnlyText>
            <ReadOnlyText usage="list_data">{`Tapahtumia yhteensä: ${props.numberOfEvents} kpl`}</ReadOnlyText>
            <ReadOnlyText usage="list_data">{`Aikaväli: ${props.StartDate.format('DD.MM.YYYY')}–${props.EndDate.format(
                'DD.MM.YYYY'
            )}`}</ReadOnlyText>
            <ReadOnlyText usage="list_data">{`Tapahtumatyypit: ${props.EventTypes.join(', ')}`}</ReadOnlyText>
            <ReadOnlyText usage="list_data">{`Työkohteet: ${props.Workunits.join(', ')}`}</ReadOnlyText>
            <div style={{ paddingTop: 10 }} />
            <ReadOnlyText usage="list_data">{PUBLISH_LABEL}</ReadOnlyText>
        </>
    )
}

// Julkaistavat tapahtumat:1 kpl
// Tapahtumia yhteensä:1 kpl
// Aikaväli:02/13/2022–02/13/2022
// Tapahtumatyypit:Vuoro
// Asiakkaat:Asiakas Ahonen
// Julkaistaan vain julkaisemattomat tapahtumat valituista tapahtumista

const PublishEvents: React.FC<IPublishEventProps> = ({
    formId,
    title = 'workforce-scheduling.PublishEvents.ConfirmationDialog.title',
}) => {
    const isOpen = useSelector((state: RootState) => selectIsModalOpen(state, formId))
    const dispatch = useDispatch()
    const selectedEvents = useSelector(selectSelectedEvents)

    if (!isOpen || selectedEvents.length === 0) {
        return null
    }

    const distinctEvents = uniqBy(selectedEvents, (x) => x.Id)

    const publishableEvents = distinctEvents.filter((x) =>
        x.Kayttooikeudet.find((y) => y.Toiminto === PUBLISH_PERMISSION && y.OnkoSallittu)
    )

    const totalPublishableEvents = publishableEvents.length
    const totalEvents = distinctEvents.length

    const earliestStart = publishableEvents.map((x) => x.StartTime).sort((left, right) => left.diff(right))[0]
    const latestEnd = publishableEvents.map((x) => x.StartTime).sort((left, right) => right.diff(left))[0]

    const eventTypes = uniq(publishableEvents.map((x) => x.EventType.Name))

    const workUnits = uniq(publishableEvents.filter((x) => x.WorkUnit).map((x) => x.WorkUnit.Name))

    const actionId = 'Event_Publish_Events'

    const eventIds = publishableEvents.map((x) => x.Id)

    const handleSubmit = async () => {
        dispatch(closeModalAction(formId))

        await Promise.all([
            dispatch(executeDataItemActionThunk(CALENDAR_DATASOURCE_ID, [...eventIds], actionId)),
        ]).catch((e) => {
            dispatch(
                displayErrorToaster(e, {
                    startOfErrorMessage: 'workforce-scheduling.PublishEventsFailed',
                })
            )
        })
    }

    const extraContent = (
        <PublishSummary
            numberOfEvents={totalEvents}
            numberOfPublishableEvents={totalPublishableEvents}
            StartDate={earliestStart}
            EndDate={latestEnd}
            EventTypes={eventTypes}
            Workunits={workUnits}
        />
    )

    return (
        <ConfirmationDialog
            confirmButtonText="workforce-scheduling.PublishEvents"
            extraContent={extraContent}
            modalId={formId}
            modalOpen
            onSubmit={handleSubmit}
            title={title}
        />
    )
}

export default PublishEvents
