import * as React from 'react'
import styled from '@emotion/styled'

import Styles from 'constants/Styles'
import DrawerTab from './DrawerTab'
import URL from 'common/URL'
import INavigationSectionWithSubsections from '../../../../Types/INavigationSectionWithSubsections'
import { Expandable } from '../../../../../generic-components'
import SectionLabel from './SectionLabel'
import INavigationSubsection from '../../../../Types/INavigationSubsection'

interface ISectionProps {
    item: INavigationSectionWithSubsections
    closeDrawerCallback?: () => void
    currentPageSection: INavigationSectionWithSubsections | null
    currentPageSubsection: INavigationSubsection | null
}

const getSectionUrl = (section: INavigationSectionWithSubsections) => {
    return section.subsectionObjects.length > 1 ? null : section.subsectionObjects[0].Url
}

const StyledExpandable = styled(Expandable)`
    & .Mui-expanded {
        min-height: 42px;
        max-height: 42px;
        margin-top: 0;
        margin-bottom: 0;
        align-items: center;
    }

    & .MuiAccordionSummary-content {
        margin: 0;
    }

    & .MuiAccordionSummary-root {
        padding: 0 10px 0 10px;
        min-height: 42px;
        max-height: 42px;
        :hover {
            background-color: ${Styles.mainColor.lightGrey};
        }
    }

    & .MuiAccordionDetails-root {
        flex-direction: column;
        padding: 0;
    }
`

const Section: React.FC<ISectionProps> = ({ item, closeDrawerCallback, currentPageSection, currentPageSubsection }) => {
    const renderNavigationItem = React.useCallback(
        (url: string, label: string, indented = false, subsection = null) => {
            return (
                <DrawerTab
                    closeDrawerCallback={closeDrawerCallback}
                    currentPageSubsection={currentPageSubsection}
                    indented={indented}
                    key={url}
                    subsection={subsection}
                    to={URL.oldClient(url)}
                >
                    {label}
                </DrawerTab>
            )
        },
        [closeDrawerCallback, currentPageSubsection]
    )

    const sectionUrl = getSectionUrl(item)

    if (sectionUrl) {
        return renderNavigationItem(sectionUrl, item.Translation)
    }

    const isCurrentPageSection = Boolean(currentPageSection && currentPageSection.Id === item.Id)

    return (
        <div>
            <StyledExpandable
                defaultExpanded={isCurrentPageSection}
                label={
                    <SectionLabel currentPageSection={currentPageSection} section={item}>
                        {item.Translation}
                    </SectionLabel>
                }
            >
                {item.subsectionObjects.map((subsection) =>
                    renderNavigationItem(subsection.Url, subsection.Translation, true, subsection)
                )}
            </StyledExpandable>
        </div>
    )
}

export default Section
