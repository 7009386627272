import * as React from 'react'

import Styles from 'constants/Styles'
import SubHeading from 'components/atoms/SubHeading'

export interface IListEndReachedTextProps {
    isLoading: boolean
    maxResultsDisplayed: boolean
    allItemsCount: number
}

const ListEndReachedText: React.FunctionComponent<IListEndReachedTextProps> = ({
    isLoading,
    maxResultsDisplayed,
    allItemsCount,
}) => {
    if (isLoading || !maxResultsDisplayed) {
        return null
    }

    return (
        <div style={Styles.layout.listPlaceholderContainer}>
            <SubHeading {...Styles.layout.placeholderText} translationParameters={{ amount: allItemsCount }}>
                list-end-reached
            </SubHeading>
        </div>
    )
}

export default ListEndReachedText
