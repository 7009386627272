import * as React from 'react'

import Styles from 'constants/Styles'
import SubHeading from 'components/atoms/SubHeading'

interface IOwnProps {
    isLoadingInitialData: boolean
    listIsEmpty: boolean
    isDataFetched: boolean
}

const NoContentText: React.FunctionComponent<IOwnProps> = ({ isLoadingInitialData, listIsEmpty, isDataFetched }) => {
    if (!listIsEmpty) {
        return null
    }

    const noContentText = !isDataFetched
        ? 'configurable-list.NoContentText.NotFetched'
        : isLoadingInitialData
        ? 'list-downloading-data'
        : 'list-no-data-found'

    return (
        <div style={Styles.layout.listPlaceholderContainer}>
            <SubHeading {...Styles.layout.placeholderText}>{noContentText}</SubHeading>
        </div>
    )
}

export default NoContentText
