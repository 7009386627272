import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { get, isNil, pick } from 'lodash-es'
import IFormViewConfigurationV2 from '../../Types/V2/IFormViewConfigurationV2'
import FormViewSummaryLabel from './FormViewSummaryLabel'
import { selectDataSourceItem } from '../../../data-source'
import { Link, ReadOnlyText } from '../../../generic-components'

type Props = {
    itemIds?: number[]
    formConfiguration?: IFormViewConfigurationV2
}

type SummaryField = {
    field: string
    translation: string
    additionalFields?: string[]
    renderValue?: (value: unknown) => JSX.Element
}

const summaryFields: Record<string, SummaryField[]> = {
    WorkunitModifyForm: [
        { field: 'Nimi', translation: 'Työkohde' },
        { field: 'Id', translation: 'ID' },
        {
            field: 'Organization',
            additionalFields: ['Id'],
            translation: 'Organization',
            renderValue: ({ Name, additionalData }: { Name: string; additionalData: { Id: string } }) => (
                <Link
                    to={`/#/Page/WorkUnitGroups?workUnit=${additionalData.Id}`}
                    muiLinkProps={{ underline: 'always' }}
                >
                    <ReadOnlyText usage="linkM">{Name}</ReadOnlyText>
                </Link>
            ),
        },
    ],
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 24px 0;
    margin: 0 32px;
    border-bottom: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
`

const SummaryLabels = ({ formConfiguration, itemIds }: Required<Props>): JSX.Element => {
    const summaryData = useSelector((state: RootState) =>
        selectDataSourceItem(state, formConfiguration.DataSourceId, itemIds[0])
    )

    const fields = summaryFields[formConfiguration.Id]

    return (
        <Container>
            {fields.map(({ field, additionalFields, translation, renderValue }) => {
                const value = get(summaryData, field)

                if (!value) {
                    return null
                }

                const valueToUse = additionalFields
                    ? { ...value, additionalData: pick(summaryData, additionalFields || []) }
                    : value

                return (
                    <FormViewSummaryLabel
                        key={field}
                        title={translation}
                        value={valueToUse}
                        renderValue={renderValue}
                    />
                )
            })}
        </Container>
    )
}

const FormViewSummary = ({ formConfiguration, itemIds }: Props): JSX.Element | null => {
    const hasSummary =
        !isNil(formConfiguration) && Object.keys(summaryFields).includes(formConfiguration.Id) && itemIds?.length === 1

    if (!hasSummary) {
        return null
    }

    return <SummaryLabels formConfiguration={formConfiguration} itemIds={itemIds} />
}

export default FormViewSummary
