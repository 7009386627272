import { RootState } from 'typesafe-actions'
import { IPageData } from '../Types/IPageData'
import { PAGE_LOADING_ASYNC_OPERATION_ID } from '../PageConstants'
import { selectIsAsyncOperationIsInProgress } from '../../async-operation'

export const selectPageData = (state: RootState, pageId: string | null): IPageData | null => {
    if (pageId === null) {
        return null
    }

    return state.pageData.get(pageId) ?? null
}

export const selectIsLoadingPageData = (state: RootState): boolean => {
    return selectIsAsyncOperationIsInProgress(state, PAGE_LOADING_ASYNC_OPERATION_ID)
}
