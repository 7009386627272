import { FC } from 'react'

import { Chip } from '../../../../generic-components'
import { Translation } from '../../../../localization'

interface IValueFieldChipProps {
    itemCount: number
    label: string
    disabled: boolean
    onClick: (e: React.MouseEvent) => void
    spaceReservedForChipSelectionCount: number | undefined
}

const ValueFieldChip: FC<IValueFieldChipProps> = ({
    spaceReservedForChipSelectionCount,
    onClick,
    itemCount,
    label,
    disabled,
}) => {
    const labelToUse = Translation.has(label) ? Translation.translateKey(label) : label

    return (
        <Chip
            disabled={disabled}
            isSelectionIconRendered
            label={labelToUse}
            onClick={onClick}
            selectionCount={itemCount}
            spaceReservedForSelectionCount={spaceReservedForChipSelectionCount}
        />
    )
}

export default ValueFieldChip
