import IDataSourceConfiguration from '../../../Types/IDataSourceConfiguration'
import { PermissionableAction } from 'interfaces/IActionPermission'
import EDataSourceActionMethods from '../../../Constants/EDataSourceActionMethods'

export const getTestConfiguration = (configuration?: Partial<IDataSourceConfiguration>): IDataSourceConfiguration => {
    return {
        Id: 'VuorotJaVapaatDataSource',
        Url: 'Tapahtuma/Search',
        Properties: [
            { Id: 'VuorotJaVapaatDataSource_Alkamisaika', Property: 'Alkamisaika', DataType: 'DateTime' },
            {
                Id: 'VuorotJaVapaatDataSource_Ammattinimike',
                Property: 'Ammattinimike',
                DataType: 'Ammattinimiketunniste',
            },
            { Id: 'VuorotJaVapaatDataSource_Ammattinimike_Nimi', Property: 'Ammattinimike.Nimi', DataType: 'string' },
            { Id: 'VuorotJaVapaatDataSource_Asiakas', Property: 'Asiakas', DataType: 'Asiakastunniste' },
            { Id: 'VuorotJaVapaatDataSource_AsiakasHyvaksynyt', Property: 'AsiakasHyvaksynyt', DataType: 'bool' },
            { Id: 'VuorotJaVapaatDataSource_Id', Property: 'Id', DataType: 'int' },
            { Id: 'VuorotJaVapaatDataSource_Info', Property: 'Info', DataType: 'string' },
            { Id: 'VuorotJaVapaatDataSource_InfoColor', Property: 'InfoColor', DataType: 'bool' },
            { Id: 'VuorotJaVapaatDataSource_KestoMinuutit', Property: 'KestoMinuutit', DataType: 'double' },
            { Id: 'VuorotJaVapaatDataSource_Laskutushinta', Property: 'Laskutushinta', DataType: 'decimal' },
            { Id: 'VuorotJaVapaatDataSource_Lisatiedot', Property: 'Lisatiedot', DataType: 'string' },
            { Id: 'VuorotJaVapaatDataSource_LisatiedotKuitattu', Property: 'LisatiedotKuitattu', DataType: 'bool' },
            {
                Id: 'VuorotJaVapaatDataSource_LisatiedotTyontekijalta',
                Property: 'LisatiedotTyontekijalta',
                DataType: 'string',
            },
            { Id: 'VuorotJaVapaatDataSource_Loppumisaika', Property: 'Loppumisaika', DataType: 'DateTime' },
            { Id: 'VuorotJaVapaatDataSource_Lounasminuutit', Property: 'Lounasminuutit', DataType: 'int' },
            { Id: 'VuorotJaVapaatDataSource_Palkkatyyppi_Nimi', Property: 'Palkkatyyppi.Nimi', DataType: 'string' },
            { Id: 'VuorotJaVapaatDataSource_Projektinumero_Numero', Property: 'Projektinumero.Id', DataType: 'int' },
            {
                Id: 'VuorotJaVapaatDataSource_Projektinumero_Selite',
                Property: 'Projektinumero.Nimi',
                DataType: 'string',
            },
            { Id: 'VuorotJaVapaatDataSource_Ruokaperintahinta', Property: 'Ruokaperintahinta', DataType: 'decimal' },
            {
                Id: 'VuorotJaVapaatDataSource_Tapahtumatyyppi_Nimi',
                Property: 'Tapahtumatyyppi.Nimi',
                DataType: 'string',
            },
            {
                Id: 'VuorotJaVapaatDataSource_Tapahtumatyyppi_Varikoodi',
                Property: 'Tapahtumatyyppi.Varikoodi',
                DataType: 'string',
            },
            { Id: 'VuorotJaVapaatDataSource_Tes', Property: 'Tes', DataType: 'Testunniste' },
            { Id: 'VuorotJaVapaatDataSource_Tuntipalkka', Property: 'Tuntipalkka', DataType: 'decimal' },
            { Id: 'VuorotJaVapaatDataSource_TyoaikatapahtumaId', Property: 'TyoaikatapahtumaId', DataType: 'int' },
            {
                Id: 'VuorotJaVapaatDataSource_Tyoaikatapahtumatyyppi',
                Property: 'Tyoaikatapahtumatyyppi',
                DataType: 'int',
            },
            { Id: 'VuorotJaVapaatDataSource_Tyontekija', Property: 'Tyontekija', DataType: 'string' },
            { Id: 'VuorotJaVapaatDataSource_Tyontekija_Nimi', Property: 'Tyontekija.Nimi', DataType: 'string' },
            { Id: 'VuorotJaVapaatDataSource_TyontekijaHyvaksynyt', Property: 'TyontekijaHyvaksynyt', DataType: 'bool' },
            {
                Id: 'VuorotJaVapaatDataSource_Tyosopimustyyppi_Nimi',
                Property: 'Tyosopimustyyppi.Nimi',
                DataType: 'string',
            },
            { Id: 'VuorotJaVapaatDataSource_Tyosuhdemuoto_Nimi', Property: 'Tyosuhdemuoto.Nimi', DataType: 'string' },
            { Id: 'VuorotJaVapaatDataSource_Urakkapalkkamaara', Property: 'Urakkapalkkamaara', DataType: 'double' },
        ],
        DependsOn: [],
        Actions: [
            {
                Id: PermissionableAction.VUORO_HYVAKSY,
                DataPropertyParameters: [{ Key: 'tapahtumaIdt', PropertyPath: 'Id' }],
                EndpointUrl: '/Tapahtuma/Hyvaksynnanmuutokset?hyvaksytty=true',
                Method: EDataSourceActionMethods.POST,
                StaticParameters: {},
                SuccessMessage: 'Tapahtumien hyväksyminen onnistui.',
                DataSourceId: 'VuorotJaVapaatDataSource',
            },
            {
                Id: PermissionableAction.PERU_VUORO_HYVAKSY,
                DataPropertyParameters: [{ Key: 'tapahtumaIdt', PropertyPath: 'Id' }],
                EndpointUrl: '/Tapahtuma/Hyvaksynnanmuutokset?hyvaksytty=false',
                Method: EDataSourceActionMethods.POST,
                StaticParameters: {},
                SuccessMessage: 'Tapahtumien hyväksynnän peruminen onnistui.',
                DataSourceId: 'VuorotJaVapaatDataSource',
            },
            {
                Id: 'VuorotJaVapaatDataSourceMetadata_Action_Kasittele',
                DataSourceId: 'VuorotJaVapaatDataSource',
            },
        ],
        ...configuration,
    }
}
