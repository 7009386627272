import { compact } from 'lodash-es'

export const getTestId = (idSegments: (string | undefined)[], uniqueId?: string | number): string | undefined => {
    if (!uniqueId) {
        return
    }

    const id = String(uniqueId).replaceAll(' ', '_')
    const segments = compact(idSegments).join('_')

    return `${segments}_${id}`.toUpperCase()
}
