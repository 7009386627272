import { TARVENAKYMA_CALENDAR_ID, TYONTEKIJANAKYMA_CALENDAR_ID } from './WorkforceSchedulingConstants'

export const DEMAND_VIEW_CALENDAR_MODE_VALUE_PICKER_ID = `${TARVENAKYMA_CALENDAR_ID}_CalendarMode`
export const EMPLOYEE_VIEW_CALENDAR_MODE_VALUE_PICKER_ID = `${TYONTEKIJANAKYMA_CALENDAR_ID}_CalendarMode`

export const DEMAND_VIEW_EVENT_GROUP_TYPE_VALUE_PICKER_ID = `${TARVENAKYMA_CALENDAR_ID}_EventGroupType`
export const VALUE_PICKER_HIDE_GROUPS_WITH_NO_EVENTS = 'HideGroupsWithNoEvents'

export const VALUE_PICKER_WORK_UNIT = 'WorkforceScheduling_Calendar_Filter_WorkUnit'
export const VALUE_PICKER_EMPLOYEES = 'WorkforceScheduling_Calendar_Filter_Employees'
export const VALUE_PICKER_DOMAIN = 'WorkforceScheduling_Calendar_Filter_Domain'
export const VALUE_PICKER_POOL = 'WorkforceScheduling_Calendar_Filter_Pool'

export const VALUE_PICKER_PLANNING_PERIODS_RECENT = 'WorkforceScheduling_PlanningPeriods_Recent'
