import { useRef, useState, useCallback, useEffect } from 'react'

const defaultScrollBarWidth = 17

interface IUseScrollbarWidthReturnValue {
    scrollbarWidth: number
    setRefForElement: (element: HTMLDivElement) => void
    recalculateScrollbarWidth: () => void
}

type TDirection = 'vertical' | 'horizontal'

/**
 * Sometimes you have elements such that it can effect things in a negative way
 * if there is or isn't a scrollbar. In such a situation you might have to do things
 * such as add padding or width equaling the width of the scrollbar. There are two
 * issues however, that make it not so simple in all situations:
 *   1. You might not know whether or not there is a scrollbar in the first place. You
 *      could of course make the scrollbar always be there with overflow: scroll but that's
 *      pretty ugly.
 *   2. The scrollbar can be of varying width depending on the browser. For example on Safari
 *      scrollbar doesn't take any width.
 *
 * This hook addresses those issues by calculating the width of the scrollbar dynamically -
 * if there is no scrollbar present it also notices that.
 *
 * The hook returns an object in which there's a ref function and the scrollbar width.
 * Pass the ref to the element for which you want to calculate the scrollbar width.
 *
 * By default calculates the scrollbar width for vertical scrollbar, i.e. the one affecting
 * width. You can calculate the height of the horizontal scrollbar by passing parameter 'horizontal'
 *
 */
const useScrollbarWidth = (scrollbarDirection: TDirection = 'vertical'): IUseScrollbarWidthReturnValue => {
    const refForElement = useRef<HTMLDivElement | null>(null)
    const [scrollbarWidth, setScrollbarWidth] = useState(defaultScrollBarWidth)

    const calculateScrollbarWidth = useCallback(() => {
        if (!refForElement.current) {
            return
        }

        const newScrollbarWidth =
            scrollbarDirection === 'vertical'
                ? refForElement.current.offsetWidth - refForElement.current.clientWidth
                : refForElement.current.offsetHeight - refForElement.current.clientHeight

        setScrollbarWidth(newScrollbarWidth)
    }, [scrollbarDirection])

    const setRefForElement = useCallback(
        (element: HTMLDivElement) => {
            if (!refForElement.current) {
                refForElement.current = element
            }

            calculateScrollbarWidth()
        },
        [calculateScrollbarWidth]
    )

    useEffect(() => {
        calculateScrollbarWidth()
    }, [calculateScrollbarWidth, scrollbarDirection])

    return {
        scrollbarWidth,
        setRefForElement,
        recalculateScrollbarWidth: calculateScrollbarWidth,
    }
}

export default useScrollbarWidth
