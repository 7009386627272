import { FC, ReactNode } from 'react'
import ReadOnlyText from '../../ReadOnlyText'

export interface ILabelProps {
    text?: string
    children?: ReactNode
    disabled?: boolean
    required?: boolean
}

const Label: FC<ILabelProps> = ({ text, children, required }) => {
    const labelText = required ? text + ' *' : text
    return (
        <div>
            <ReadOnlyText usage="dropdown_label">{labelText}</ReadOnlyText>
            {children}
        </div>
    )
}

export default Label
