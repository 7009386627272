import { useRef } from 'react'
import * as React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from './MenuItem'
import styled from '@emotion/styled'
import MuiMenuItem from '@material-ui/core/MenuItem'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '../Icon'
import IMenuItemProps from './Types/IMenuItemProps'

export interface ISubMenuProps {
    label: string
    icon?: string
    subMenu: IMenuItemProps[]
    closeMenu?: () => void
    isSpaceToBeReservedForIcon: boolean
}

const MenuItemContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledMenu = styled(Menu)`
    & .MuiPopover-paper {
        min-width: 230px;
    }
`

const subMenuedItemRightIconClassName = 'pln-subMenu-right-icon'
const itemOpeningSubMenuRightIconContainerClassName = 'pln-subMenu-right-icon-container'

const StyledMenuItem = styled(MuiMenuItem)`
    /* Having no space seems essential here (breaks styles if there's a space after '&') */
    &.MuiMenuItem-root {
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        justify-content: flex-start;
        min-width: 230px;
        padding: 6px 0 6px 16px;
    }
    /* ..But here it seems necessary. Something weird about CSS or a bug with emotion? */
    & .MuiListItemIcon-root {
        min-width: 40px;
    }

    .${itemOpeningSubMenuRightIconContainerClassName} {
        position: absolute;
        right: 0;
    }
`

const SubMenu: React.FC<ISubMenuProps> = ({ subMenu, icon, label, closeMenu, isSpaceToBeReservedForIcon }) => {
    const styledMenuRef = useRef(null)

    const [isSubMenuOpened, setIsSubMenuOpened] = React.useState<boolean>(false)

    const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
        event.preventDefault()
        event.stopPropagation()

        //check if there are nested sub menus
        const ifSubMenuExists =
            (event.target as HTMLLIElement).querySelectorAll(subMenuedItemRightIconClassName).length > 0
        const element = event.target as HTMLElement

        if (isSubMenuOpened && !ifSubMenuExists) {
            //If list item is selected, close the whole menu
            if (element.tagName === 'LI') {
                setIsSubMenuOpened(false)
                closeMenu && closeMenu()
            }
            return
        }
        setIsSubMenuOpened(true)
    }

    const handleClose = () => {
        setIsSubMenuOpened(false)
        closeMenu && closeMenu()
    }

    const hasAnyItemsWithIcon = isSubMenuOpened ? subMenu.some((item) => Boolean(item.icon)) : false

    return (
        <StyledMenuItem onClick={handleClick} ref={styledMenuRef}>
            {(isSpaceToBeReservedForIcon || icon) && <ListItemIcon>{icon && <Icon>{icon}</Icon>}</ListItemIcon>}
            {label}
            <ListItemIcon className={itemOpeningSubMenuRightIconContainerClassName}>
                <ArrowRightIcon className={subMenuedItemRightIconClassName} />
            </ListItemIcon>
            {isSubMenuOpened && (
                <StyledMenu
                    anchorEl={styledMenuRef.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    getContentAnchorEl={null}
                    keepMounted
                    onClose={handleClose}
                    open
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <MenuItemContainer>
                        {subMenu.map((menuItemData) => {
                            return (
                                <MenuItem
                                    icon={menuItemData.icon}
                                    isSpaceToBeReservedForIcon={hasAnyItemsWithIcon}
                                    key={menuItemData.label}
                                    label={menuItemData.label}
                                    onClick={menuItemData.onClick}
                                    onCloseMenu={handleClose}
                                    subMenu={menuItemData.subMenu}
                                    isDisabled={menuItemData.isDisabled}
                                />
                            )
                        })}
                    </MenuItemContainer>
                </StyledMenu>
            )}
        </StyledMenuItem>
    )
}

export default SubMenu
