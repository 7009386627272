import * as React from 'react'
import styled from '@emotion/styled'
import { ReadOnlyText } from '../../../generic-components'
import { Translation } from '../../../localization'

export interface IDateHeaderNodeProps {
    value: string
    weekNumber?: number
    publicHoliday?: string
}

interface IHeaderNode {
    showLeftBorder: boolean
}

const HeaderNode = styled.div<{ showLeftBorder: IHeaderNode }>`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    border-right-color: ${({ theme }) => theme.colors.neutralsGrey30};
    border-right-style: solid;
    border-right-width: thin;
    border-top: solid;
    border-top-width: thin;
    border-top-color: ${({ theme }) => theme.colors.neutralsGrey30};
    border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: ${({ theme }) => theme.colors.neutralsGrey30};
    border-left: ${({ showLeftBorder, theme }) => (showLeftBorder ? `2px solid ${theme.colors.neutralsGrey30}` : '')};
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
`

const PublicHolidayPlaceHolder = styled.div`
    height: 10px;
`
const WeeknumberPlaceHolder = styled.div`
    height: 10px;
`

const DateHeaderNode: React.FunctionComponent<IDateHeaderNodeProps> = ({ value, weekNumber, publicHoliday }) => (
    <HeaderNode showLeftBorder={weekNumber !== undefined}>
        <Row>
            {publicHoliday ? (
                <ReadOnlyText
                    textOverflow="ellipsis"
                    color="primary_120"
                    fontSize="10"
                    fontWeight="400"
                    usage="bodyXS"
                    lineHeight="14px"
                >
                    {publicHoliday}
                </ReadOnlyText>
            ) : (
                <PublicHolidayPlaceHolder />
            )}
        </Row>
        <Row>
            <ReadOnlyText lineHeight="14px" usage="bodyXS">
                {value}
            </ReadOnlyText>
        </Row>
        <Row>
            {weekNumber ? (
                <ReadOnlyText
                    usage="bodyXS"
                    fontSize="10"
                    fontWeight="700"
                    color="dark_40"
                    lineHeight="14px"
                >{`${Translation.translateKey('vko')} ${weekNumber}`}</ReadOnlyText>
            ) : (
                <WeeknumberPlaceHolder />
            )}
        </Row>
    </HeaderNode>
)

export default DateHeaderNode
