import { CancelToken } from 'axios'
import FileSaver from 'file-saver'

import IDataSourceAction from '../Types/IDataSourceAction'
import IDataSourceConfiguration from '../Types/IDataSourceConfiguration'
import makeRequest, { ERequestMethod, IRequestAdditionalConfig, makeAxiosRequest } from '../../rest-api'
import tyovuorolistapakettiDataSourceMetadata from '../tmp/TyovuorolistapakettiDataSourceMetadata'
import { VIEW_ENGINE_URL } from '../Constants/DataSourceConstants'
import {
    IDataSourceDataRequestParameters,
    IDataSourceItem,
    IListViewDataContainer,
    TDataSourceItemId,
} from '../../data-source-types'
import tarvenakymaDataSourceConfiguration from '../tmp/TarvenakymaDataSourceConfiguration'
import { tyontekijanakymaDataSourceConfiguration } from '../tmp/TyontekijanakymaDataSourceConfiguration'
import { tyontekijaAlueRelationDataSourceConfiguration } from '../tmp/TyontekijaAlueRelationDataSourceConfiguration'
import IDataSourceActionCreateRequest from '../Types/IDataSourceActionCreateRequest'
import IDataSourceActionUpdateRequest from '../Types/IDataSourceActionUpdateRequest'
import IDataSourceActionRemoveRequest from '../Types/IDataSourceActionRemoveRequest'

interface IDataSourceConfigurationFromApi extends Pick<IDataSourceConfiguration, 'Id' | 'Url' | 'Properties'> {}

export const queryDataSourceMetadata = async (dataSourceId: string): Promise<IDataSourceConfiguration> => {
    // fetch different dataSource's configuration for specified dataSourceId
    const dataSourceCloneMap: Record<string, string> = {
        EmployeeCalendar: 'Event',
    }

    const dataSourceToQuery = dataSourceCloneMap[dataSourceId] || dataSourceId

    const dataSourceMetadataFromApi = await makeRequest<IDataSourceConfigurationFromApi>({
        method: ERequestMethod.GET,
        url: `${VIEW_ENGINE_URL}/DataSource?id=${dataSourceToQuery}`,
    })

    // @@TODO Temporary until db tables are patched
    const dataSourceMetadata: IDataSourceConfiguration = {
        Actions: [],
        DependsOn: [],
        ...dataSourceMetadataFromApi,
    }

    if (dataSourceId === 'TyovuorolistapakettiDataSource') {
        return tyovuorolistapakettiDataSourceMetadata
    } else if (dataSourceId === 'TarvenakymaDataSource') {
        return tarvenakymaDataSourceConfiguration(dataSourceMetadata)
    } else if (dataSourceId === 'TyontekijanakymaDataSource') {
        return tyontekijanakymaDataSourceConfiguration(dataSourceMetadata)
    } else if (dataSourceId === 'TyontekijaAlueRelationDataSource') {
        return tyontekijaAlueRelationDataSourceConfiguration(dataSourceMetadata)
    }

    return { ...dataSourceMetadata, Id: dataSourceId }
}

export const queryAndDownloadDataSourceData = async (urlPath: string, body: unknown): Promise<void> => {
    const response = await makeAxiosRequest<Blob>({
        method: ERequestMethod.POST,
        url: urlPath,
        data: body,
        responseType: 'blob',
    })

    const headers = response.headers

    const dispositionHeader = headers['content-disposition']

    const filename = readFileNameFromHeader(dispositionHeader) ?? 'data.csv'

    const data = response.data

    FileSaver.saveAs(data, filename)
}

export const queryDataSourceData = async (
    urlPath: string,
    data: IDataSourceDataRequestParameters,
    cancelToken?: CancelToken
): Promise<IListViewDataContainer<IDataSourceItem>> => {
    const response = await makeRequest<IListViewDataContainer<IDataSourceItem>, IDataSourceDataRequestParameters>({
        method: ERequestMethod.POST,
        url: urlPath,
        data,
        cancelToken,
    })

    // @@TODO Only temporary until all used APIs are updated.
    if (!response.GroupData) {
        return {
            ...response,
            GroupData: [],
        }
    }
    if (!response.AccumulatedData) {
        return {
            ...response,
            AccumulatedData: [],
        }
    }

    return response
}

export const queryDataSourceAction = async <T = void>(
    { EndpointUrl, Method }: IDataSourceAction,
    requestBody: Record<string, unknown>,
    requestOptions?: IRequestAdditionalConfig
): Promise<T> => {
    if (!Method || !EndpointUrl) {
        throw new Error('Method or EndPointUrl were missing')
    }

    return makeRequest({
        method: ERequestMethod[Method],
        url: EndpointUrl,
        data: requestBody,
        ...requestOptions,
    })
}
export const queryDataSourceCreateAction = async <T = void>(
    { EndpointUrl, Method }: IDataSourceAction,
    data: Record<string, unknown>,
    requestOptions?: IRequestAdditionalConfig
): Promise<T> => {
    if (!Method || !EndpointUrl) {
        throw new Error('Method or EndPointUrl were missing')
    }

    return makeRequest<T, IDataSourceActionCreateRequest>({
        method: ERequestMethod[Method],
        url: EndpointUrl,
        data: {
            Items: [{ Data: data }],
        },
        ...requestOptions,
    })
}

export const queryDataSourceUpdateAction = async <T = void>(
    { EndpointUrl, Method }: IDataSourceAction,
    itemIds: TDataSourceItemId[],
    data: Record<string, unknown>,
    requestOptions?: IRequestAdditionalConfig
): Promise<T> => {
    if (!Method || !EndpointUrl) {
        throw new Error('Method or EndPointUrl were missing')
    }

    return makeRequest<T, IDataSourceActionUpdateRequest<TDataSourceItemId>>({
        method: ERequestMethod[Method],
        url: EndpointUrl,
        data: {
            Ids: itemIds,
            Data: data,
        },
        ...requestOptions,
    })
}

export const queryDataSourceDeleteAction = async <T = void>(
    { EndpointUrl, Method }: IDataSourceAction,
    itemIds: TDataSourceItemId[],
    requestOptions?: IRequestAdditionalConfig
): Promise<T> => {
    if (!Method || !EndpointUrl) {
        throw new Error('Method or EndPointUrl were missing')
    }

    return makeRequest<T, IDataSourceActionRemoveRequest<TDataSourceItemId>>({
        method: ERequestMethod[Method],
        url: EndpointUrl,
        data: {
            Remove: itemIds.map((Id) => Id),
        },
        ...requestOptions,
    })
}

const readFileNameFromHeader = (dispositionHeader: string) => {
    if (dispositionHeader && dispositionHeader.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(dispositionHeader)

        if (matches !== null && matches[1]) {
            return matches[1].replace(/['"]/g, '')
        }
    }

    return undefined
}

export const downloadDataSourceData = async (
    dataSourceId: string,
    requestParams: IDataSourceDataRequestParameters,
    columns: string[],
    useGroupedData?: boolean
): Promise<void> => {
    const url = `${VIEW_ENGINE_URL}/DataSource/${dataSourceId}/Excel`

    const response = await makeAxiosRequest<Blob>({
        method: ERequestMethod.POST,
        data: { searchRequest: requestParams, requestedProperties: columns, useGroupedData: useGroupedData ?? false },
        url,
        responseType: 'blob',
    })

    const headers = response.headers

    const dispositionHeader = headers['content-disposition']

    const filename = readFileNameFromHeader(dispositionHeader) ?? 'data.csv'

    const data = response.data

    FileSaver.saveAs(data, filename)
}
