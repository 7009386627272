import * as React from 'react'
import styled from '@emotion/styled'

import MenuWithItems from './MenuWithItems'

type TMenuWithItemsProps = React.ComponentProps<typeof MenuWithItems>

export interface IContextMenuProps extends Pick<TMenuWithItemsProps, 'onOpen' | 'menuItemsData'> {
    children: React.ReactElement | null
    className?: string
    onOpen?: (e?: React.MouseEvent) => void
    isDisabled?: boolean
}

const ContextMenuWrapperElement = styled.div`
    display: inline-block;
    cursor: context-menu;
`

export const ContextMenu: React.FC<IContextMenuProps> = ({
    isDisabled = false,
    menuItemsData,
    children,
    className,
    onOpen,
}) => {
    if (menuItemsData.length === 0) {
        return children
    }

    return (
        <MenuWithItems anchorPosition="mouse" menuItemsData={menuItemsData} onOpen={onOpen}>
            {(onMenuOpen) => (
                <ContextMenuWrapperElement className={className} onContextMenu={isDisabled ? undefined : onMenuOpen}>
                    {children}
                </ContextMenuWrapperElement>
            )}
        </MenuWithItems>
    )
}

export default ContextMenu
