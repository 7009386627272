import * as React from 'react'
import { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { ApplicationBar, ApplicationBarLegacy, PageContainer } from '../../../legacy-newer'
import PageTitles from 'constants/PageTitles'
import { fetchPageDataThunk, IPageData, selectPageData } from '../../../page'
import { BoundThunk } from '../../../generic-state'
import _Log from 'common/Log'
import { useNavigationItemsFitHorizontally } from '../../../navigation'
import { getIsLegacyEnvironment } from '../../../legacy-environment-adapter'
import { setCurrentPageAction } from '../../../navigation/State/NavigationActions'

const Log = new _Log('ApplicationPage')

interface IOwnProps {
    children: (pageData: IPageData | null) => React.ReactNode
    routerMatch: RouteComponentProps<{ pageId?: string }>['match']
    pageId: string | null
    isFullHeight?: boolean
}

interface IStateProps {
    pageData: IPageData | null
}

interface IDispatchProps {
    fetchPageData: BoundThunk<typeof fetchPageDataThunk>
}

interface IApplicationPageProps extends IOwnProps, IStateProps, IDispatchProps {}

const ApplicationPageUnconnected: React.FC<IApplicationPageProps> = ({
    children,
    fetchPageData,
    routerMatch,
    pageId,
    isFullHeight,
    pageData,
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        const pageIdToUse = pageId ?? routerMatch.params.pageId

        if (!pageIdToUse) {
            Log.error('No pageId for the page')
            return
        }

        dispatch(setCurrentPageAction(pageIdToUse))

        fetchPageData(pageIdToUse)
    }, [dispatch, fetchPageData, pageId, routerMatch.params.pageId])

    const getPageTitle = () => {
        if (pageData?.HeaderText) {
            return pageData.HeaderText
        }

        const currentPath = routerMatch.path as keyof typeof PageTitles
        const matchingPath = PageTitles[currentPath] || ''

        return matchingPath
    }

    const pageTitle = getPageTitle()
    const pageDescription = pageData?.DescriptionText ?? null

    const navItemsFitHorizontally = useNavigationItemsFitHorizontally()

    const applicationBar = getIsLegacyEnvironment() ? (
        <ApplicationBarLegacy
            key={pageTitle}
            navItemsFitHorizontally={navItemsFitHorizontally}
            pageDescription={pageDescription}
            pageTitle={pageTitle}
        />
    ) : (
        <ApplicationBar key={pageTitle} pageDescription={pageDescription} pageTitle={pageTitle} />
    )

    return (
        <PageContainer
            header={applicationBar}
            navItemsFitHorizontally={navItemsFitHorizontally}
            pageDescription={pageDescription}
            pageTitle={pageTitle}
            isFullHeight={isFullHeight}
        >
            {children(pageData)}
        </PageContainer>
    )
}

const mapStateToProps = (state: RootState, { pageId, routerMatch }: IOwnProps): IStateProps => {
    const pageIdToUse = pageId ?? routerMatch.params.pageId ?? null
    const pageData = selectPageData(state, pageIdToUse)

    return {
        pageData,
    }
}

const mapDispathToProps = {
    fetchPageData: fetchPageDataThunk,
}

export default connect(mapStateToProps, mapDispathToProps)(ApplicationPageUnconnected)
