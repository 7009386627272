import * as React from 'react'
import { Translation } from '../../localization'
import SpecialCalendarNode from './SpecialCalendarNode'
import { useAppTheme } from '../../theme/'

export interface ITyrkkyCalendarNodeProps {
    endDatetime: string
    startDatetime: string
}

const TyrkkyCalendarNode: React.FunctionComponent<ITyrkkyCalendarNodeProps> = ({ endDatetime, startDatetime }) => {
    const { colors } = useAppTheme()

    return (
        <SpecialCalendarNode
            backgroundColor={colors.semanticSuccess11}
            hoverColor={colors.neutralsGrey11}
            rawColor={colors.semanticSuccess140}
            label={Translation.translateKey('calendar.Tyrkky')}
            startDatetime={startDatetime}
            endDatetime={endDatetime}
        />
    )
}

export default TyrkkyCalendarNode
