import * as React from 'react'
import { Suspense, useCallback, useContext } from 'react'

import EValuePickerType from '../../types/EValuePickerType'
import ValuePickerComponentRegistry from '../../ValuePickerComponentRegistry'
import { getLogger } from '../../../log'
import { selectValuePickerValue } from '../../State/ValuePickerSelectors'
import { setValuePickerValueAction } from '../../State/ValuePickerActions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { Moment } from 'moment'
import { Identifier, ITimerangeValue } from '../../../generic-components'
import { Translation } from '../../../localization'

const Log = getLogger('value-picker.ValuePicker')

export interface IValuePickerProps {
    ValuePickerId: string
    ValuePickerType: string
    onChange?: (value: any) => void
    useCachedValuesForOptions?: boolean
    dropdownWidth?: number | null
    dropdownMenuWidth?: number | null
    [key: string]: any
    required?: boolean
    label?: string
    contextId?: string
    context?: 'form' | 'list' | 'unknown'
}

interface IStateProps {
    valueFromStore: unknown
}

interface IProps extends IValuePickerProps, IStateProps {}

const ValuePicker: React.FunctionComponent<IProps> = ({
    useCachedValuesForOptions = false,
    required = false,
    ...props
}) => {
    const { ValuePickerType: valuePickerType, label: OverridingLabel, ValuePickerId, contextId } = props

    const dispatch = useDispatch()

    const valueFromStore = useSelector((state: RootState) => selectValuePickerValue(state, ValuePickerId, contextId))

    const setValuePickerValue = useCallback(
        (input: any) => dispatch(setValuePickerValueAction(input, ValuePickerId, contextId)),
        [ValuePickerId, contextId, dispatch]
    )

    const label =
        OverridingLabel && Translation.has(OverridingLabel)
            ? Translation.translateKey(OverridingLabel)
            : OverridingLabel
            ? OverridingLabel
            : props.Label && Translation.has(props.Label)
            ? Translation.translateKey(props.Label)
            : props.label && Translation.has(props.label)
            ? Translation.translateKey(props.label)
            : props.Label || props.label

    const { components } = useContext(ValuePickerComponentRegistry)

    if (!components) {
        Log.warn('Value picker component registry not initialised')
        return null
    }

    switch (valuePickerType) {
        case EValuePickerType.AdditionalServicesPicker: {
            const { onChange, ValuePickerId: valuePickerId } = props

            const { AdditionalServicesPicker } = components

            return (
                <Suspense fallback={null}>
                    <AdditionalServicesPicker label={label} onChange={onChange} valuePickerId={valuePickerId} />
                </Suspense>
            )
        }

        case EValuePickerType.TagList:
        case EValuePickerType.Dropdown: {
            const {
                EnableTextSearch: enableTextSearch,
                ValuePickerId: valuePickerId,
                Text: text,
                TextSearchProps: textSearchProps,
                multiselect,
                ItemOptionLabelFields,
                ItemValueLabelFields,
                disabled,
                UseDynamicApiSearch: useDynamicApiSearch,
                ItemIdField: itemIdField,
                MainColorField,
                SupportColorField,
                ItemLabelFields,
                onChange,
                dropdownWidth,
                dropdownMenuWidth,
                SingleValueLink,
                valueFieldType,
                spaceReservedForChipSelectionCount,
            } = props

            const translatedText = {
                FetchError: Translation.has(text.FetchError)
                    ? Translation.translateKey(text.FetchError)
                    : text.FetchError,
                Label: Translation.has(text.Label) ? Translation.translateKey(text.Label) : text.Label,
                MultipleSelected: Translation.has(text.MultipleSelected)
                    ? Translation.translateKey(text.MultipleSelected)
                    : text.MultipleSelected,
            }

            const commonProps = {
                enableTextSearch,
                valuePickerId,
                textSearchProps,
                multiselect,
                disabled,
                useDynamicApiSearch,
                itemIdField,
                onChange,
                text: translatedText,
                spaceReservedForChipSelectionCount,
            }

            const { ValuePickerWithOptions } = components

            switch (valuePickerType) {
                case EValuePickerType.Dropdown: {
                    return (
                        <ValuePickerWithOptions
                            {...commonProps}
                            errors={
                                Translation.has('Pakollinen valinta')
                                    ? Translation.translateKey('Pakollinen valinta')
                                    : 'Pakollinen valinta'
                            }
                            dropdownMenuWidth={dropdownMenuWidth}
                            dropdownWidth={dropdownWidth}
                            itemOptionLabelFields={ItemOptionLabelFields}
                            itemValueLabelFields={ItemValueLabelFields}
                            required={required}
                            singleValueLinkStart={SingleValueLink?.LinkStart ?? null}
                            singleValueLinkTooltip={SingleValueLink?.Tooltip ?? null}
                            useCachedValuesForOptions={useCachedValuesForOptions}
                            valueFieldType={props.context === 'form' ? 'form' : valueFieldType}
                            valuePickerType={valuePickerType}
                            valuePickerId={valuePickerId}
                        />
                    )
                }
                case EValuePickerType.TagList: {
                    return (
                        <ValuePickerWithOptions
                            {...commonProps}
                            itemLabelFields={ItemLabelFields}
                            mainColorField={MainColorField}
                            required={required}
                            supportColorField={SupportColorField}
                            useCachedValuesForOptions={useCachedValuesForOptions}
                            valuePickerType={valuePickerType}
                            valuePickerId={valuePickerId}
                        />
                    )
                }
                default:
                    return null
            }
        }

        case EValuePickerType.TimeRange: {
            const { ValuePickerId: valuePickerId, Text } = props

            const { PageTimeRangePicker } = components

            const translatedText = Translation.has(Text) ? Translation.translateKey(Text) : Text
            return <PageTimeRangePicker text={label || translatedText} valuePickerId={valuePickerId} />
        }

        case EValuePickerType.CheckboxPicker: {
            const { ValuePickerId: valuePickerId } = props

            const { CheckboxPicker } = components

            const booleanValue = valueFromStore as boolean

            const setValueToStore = (input: boolean) => setValuePickerValue(input)

            return (
                <CheckboxPicker
                    label={label}
                    onChange={setValueToStore}
                    value={booleanValue}
                    valuePickerId={valuePickerId}
                />
            )
        }

        case EValuePickerType.TimeRangePicker: {
            const { ValuePickerId: valuePickerId } = props

            const { TimeRangePicker } = components

            const timerangeValue = valueFromStore as ITimerangeValue

            const setValueToStore = (input: ITimerangeValue) => setValuePickerValue(input)

            return (
                <TimeRangePicker
                    {...props?.valuePickerComponentProperties}
                    value={timerangeValue}
                    errors={
                        Translation.has('Pakollinen valinta')
                            ? Translation.translateKey('Pakollinen valinta')
                            : 'Pakollinen valinta'
                    }
                    onChange={setValueToStore}
                    valuePickerId={valuePickerId}
                />
            )
        }

        case EValuePickerType.TimePicker: {
            const { ValuePickerId: valuePickerId } = props

            const { TimePicker } = components

            const timeValue = valueFromStore as Moment

            const setValueToStore = (input: Moment) => setValuePickerValue(input)

            return (
                <TimePicker
                    value={timeValue}
                    errors={
                        Translation.has('Pakollinen valinta')
                            ? Translation.translateKey('Pakollinen valinta')
                            : 'Pakollinen valinta'
                    }
                    label={label}
                    onChange={setValueToStore}
                    valuePickerId={valuePickerId}
                />
            )
        }

        case EValuePickerType.DateRange: {
            const { ValuePickerId: valuePickerId } = props

            const { PageDateRangePicker } = components

            return <PageDateRangePicker valuePickerId={valuePickerId} />
        }

        case EValuePickerType.DateRangeV2: {
            const { DateRangeV2Picker } = components
            const setValueToStore = (input: { startDate: Moment; endDate: Moment }) => {
                setValuePickerValue(input)

                props.onChange && props.onChange(input)
            }

            return (
                <DateRangeV2Picker
                    value={valueFromStore as { startDate: Moment; endDate: Moment }}
                    onChange={setValueToStore}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.ConfigurableList: {
            const { ValuePickerId: valuePickerId, ListId: listId, UsePageTemplate: usePageTemplate } = props

            const { ConfigurableListValuePicker } = components

            return (
                <ConfigurableListValuePicker
                    listId={listId}
                    usePageTemplate={usePageTemplate}
                    valuePickerId={valuePickerId}
                />
            )
        }

        case EValuePickerType.TextSearch: {
            const { TextSearchPicker } = components

            const stringValue = valueFromStore as string
            const setValueToStore = (input: string) => setValuePickerValue(input)

            return (
                <TextSearchPicker
                    value={stringValue}
                    onChange={setValueToStore}
                    label={label}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.DropdownV3: {
            const { IdentifierUrl, UseDynamicApiSearch, ItemOptionLabelFields } = props

            const { DropdownV3Picker } = components

            const identifierListValue = (valueFromStore ?? []) as Identifier[]

            const setValueToStore = (input: Identifier[]) => {
                setValuePickerValue(input)
            }

            return (
                <DropdownV3Picker
                    value={identifierListValue}
                    label={label}
                    onChange={setValueToStore}
                    identifierUrl={IdentifierUrl}
                    UseDynamicApiSearch={UseDynamicApiSearch}
                    valuePickerId={ValuePickerId}
                    itemOptionLabelFields={ItemOptionLabelFields}
                />
            )
        }

        case EValuePickerType.DayPickerWithRangeAndWeekdays: {
            const momentValue = valueFromStore as Moment[]

            const setValueToStore = (input: Moment[]) => setValuePickerValue(input)

            const { DayPickerWithRangeAndWeekdays } = components

            return (
                <DayPickerWithRangeAndWeekdays
                    errors={
                        Translation.has('Pakollinen valinta')
                            ? Translation.translateKey('Pakollinen valinta')
                            : 'Pakollinen valinta'
                    }
                    value={momentValue}
                    label={OverridingLabel}
                    onChange={setValueToStore}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.RadioButtonListValuePicker: {
            const radioButtonListValue = valueFromStore as number

            const setValueToStore = (input: number) => setValuePickerValue(input)

            const { RadioButtonListValuePicker } = components

            const { OptionsEndpointUrl } = props

            return (
                <RadioButtonListValuePicker
                    OptionsEndpointUrl={OptionsEndpointUrl}
                    valuePickerId={ValuePickerId}
                    onChange={setValueToStore}
                    value={radioButtonListValue}
                    {...props.valuePickerComponentProperties}
                />
            )
        }

        case EValuePickerType.DynamicValuePicker: {
            // In future we might wanna do something like being able to attach custom
            // components in the fly for dynamic value pickers, but for now the dynamic
            // value picker exists only for handling value picker related state without
            // a component.
            return null
        }

        default:
            return null
    }
}

export default ValuePicker
