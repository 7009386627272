import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import * as React from 'react'
import styled from '@emotion/styled'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { uniqueId } from 'lodash-es'

import Styles, { dark_80 } from 'constants/Styles'
import { translate } from '../../../localization'
import {
    convertNumericStringToNumber,
    endsInSeparator,
    ensureDecimalSeparatorIsDot,
    hasCorrectDecimalSeparatorOrNoSeparator,
    isNumeric,
} from '../../../generic-utilities'

interface ITextInputPropsWithStyles extends Pick<TextFieldProps, 'size'> {
    autoFocus?: boolean
    disabled?: boolean
    doNotTranslate?: boolean
    allowDecimals?: boolean
    floatingLabelFixed?: boolean
    /**
     * @deprecated
     * Use `label` instead
     */
    floatingLabelText?: string
    /**
     * @deprecated
     * Use `helperText` with `error` prop instead
     */
    errorText?: string
    id?: string
    maxLength?: number
    type?: string
    width?: string
    zIndex?: number
    classes: {
        labelRoot: string
    }
    autoComplete?: string
    onBlur?: (e: React.FocusEvent<any>) => void
    onKeyDown?: () => void
    label?: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    name?: string
    placeholder?: string
    error?: boolean
    helperText?: React.ReactNode
    fullWidth?: boolean
    className?: string
    required?: boolean
    onClick?: (e: React.MouseEvent) => void
    MuiInputProps?: TextFieldProps['InputProps']
    variant?: TextFieldProps['variant']
    /**
     * @deprecated Use onChange instead.
     */
    changeAction?: ((value: string) => void) | { dispatch: (value: string) => void }
    value: string | number | null
    testId?: string
}

export type ITextInputProps = Omit<ITextInputPropsWithStyles, 'classes'>

export const Input = styled(TextField)`
    width: ${({ width }: ITextInputPropsWithStyles) => width};
    bottom: inherit;
    z-index: ${({ zindex }: any) => zindex};
    font-size: ${Styles.font.levelThreeTitle.size};
    font-family: ${Styles.font.levelThreeTitle.family};
`

// TODO: need to convert this to function component.
// Or preferably actually create a new one.

/**
 * Yksirivinen tekstikenttä.
 * visibleWidth - Merkkien määrä, joka kentässä suunnilleen on kerrallaan näkyvissä
 *
 * Esimerkki:
 * <TextInput value={ this.props.myShortText } />
 */
class TextInput extends React.Component<ITextInputPropsWithStyles> {
    static displayName = 'TextInput'

    id = this.props.id ?? uniqueId('ctr_')

    state = {
        inCompleteNumberInput: '',
    }

    static propTypes = {
        autoComplete: PropTypes.string,
        disabled: PropTypes.bool,
        doNotTranslate: PropTypes.bool,
        errorText: PropTypes.string,
        floatingLabelFixed: PropTypes.bool,
        floatingLabelText: PropTypes.string,
        id: PropTypes.node,
        maxLength: PropTypes.number,
        onBlur: PropTypes.func,
        onKeyDown: PropTypes.func,
        type: PropTypes.string,
        width: PropTypes.string,
        zIndex: PropTypes.number,
    }

    static defaultProps = {
        doNotTranslate: true,
        floatingLabelFixed: false,
        floatingLabelText: '',
        width: '100%',
        zIndex: undefined,
        autoFocus: false,
        label: '',
        error: false,
        autoComplete: 'off',
        allowDecimals: true,
        variant: 'standard',
        value: '',
    }

    private getInputLabelProps = () => ({
        classes: {
            root: this.props.classes.labelRoot,
        },
    })

    private getTypeToRenderWith() {
        const { type } = this.props
        return type === 'number' ? 'text' : type
    }

    private numberDidNotChange(numericStringInput: string) {
        // If the number ends in a separator, it might be a change if there's an existing number
        // and the user simply removes the numbers after. However, we'll treat it as if it didn't
        // change and consider it sort of temporary and assume that the user will add something
        // after the separator. This way we don't call the change callback unnecessarily.
        if (endsInSeparator(numericStringInput)) {
            return true
        }

        return (
            this.props.value !== null &&
            convertNumericStringToNumber(this.props.value.toString()) ===
                convertNumericStringToNumber(numericStringInput)
        )
    }

    // Old flux related way to handle the input
    private handleChangeOld = (event: React.ChangeEvent<HTMLInputElement>) => {
        const changeAction = this.props.changeAction
        if (!changeAction) {
            return
        }

        const value = event.target.value

        if (typeof changeAction === 'function') {
            changeAction(value)
        } else {
            changeAction.dispatch(value)
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange, type, allowDecimals } = this.props

        const input = event.target.value

        if (
            type === 'number' &&
            ((allowDecimals === false && !isNumeric(input, allowDecimals)) ||
                (allowDecimals && !isNumeric(input) && hasCorrectDecimalSeparatorOrNoSeparator(input)))
        ) {
            return
        } else if (type === 'number' && isNumeric(input) && this.numberDidNotChange(input)) {
            this.setState({ inCompleteNumberInput: input })
            return
        } else if (type === 'number' && isNumeric(input)) {
            event.target.value = ensureDecimalSeparatorIsDot(input)
            if (this.state.inCompleteNumberInput) {
                this.setState({ inCompleteNumberInput: '' })
            }
        }

        if (onChange) {
            onChange(event)
        } else {
            this.handleChangeOld(event)
        }
    }

    getValue() {
        const { value, type } = this.props

        if (value === null || value === undefined) {
            return ''
        }

        if (this.state.inCompleteNumberInput) {
            return this.state.inCompleteNumberInput
        }

        if (type === 'number') {
            return value.toString().replace('.', ',')
        }

        return value
    }

    render(): React.ReactNode {
        const {
            autoComplete,
            autoFocus,
            disabled,
            doNotTranslate,
            errorText,
            floatingLabelText,
            maxLength,
            onBlur,
            onKeyDown,
            width,
            zIndex,
            label,
            name,
            placeholder = translate('typeHere'),
            helperText,
            error: hasError,
            fullWidth,
            className,
            required,
            onClick,
            MuiInputProps,
            variant,
            size,
            testId,
        } = this.props

        const errorTextOld = errorText ? translate(errorText) : undefined
        let labelOld = floatingLabelText

        if (!doNotTranslate) {
            labelOld = floatingLabelText ? translate(floatingLabelText) : undefined
        }

        const value = this.getValue()
        const type = this.getTypeToRenderWith()

        return (
            <Input
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                className={className}
                disabled={disabled}
                error={hasError}
                FormHelperTextProps={errorTextOld ? { error: true } : undefined}
                fullWidth={fullWidth}
                helperText={helperText ? helperText : errorTextOld}
                id={this.id}
                InputLabelProps={this.getInputLabelProps()}
                InputProps={MuiInputProps}
                inputProps={{ maxLength }}
                label={label || labelOld}
                name={name}
                onBlur={onBlur}
                onChange={this.handleChange}
                onClick={onClick}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                required={required}
                size={size}
                type={type}
                value={value}
                variant={variant}
                width={width}
                zindex={zIndex}
                data-testid={testId}
            />
        )
    }
}

export default withStyles({
    labelRoot: {
        fontFamily: Styles.font.levelThreeTitle.family,
        fontSize: Styles.font.levelThreeTitle.size,
        fontWeight: 400,
        color: dark_80,
    },
})(TextInput as any)
