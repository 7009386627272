import AppBar from '@material-ui/core/AppBar'
import * as React from 'react'
import styled from '@emotion/styled'

import ApplicationBarTitle from './ApplicationBarTitle'
import MainMenu from 'components/main/molecules/MainMenu'
import { MainNavigation } from '../../../navigation'
import Styles from 'constants/Styles'

interface IOwnProps {
    pageTitle: string
    pageDescription: string | null
}

interface IApplicationBarProps extends IOwnProps {}

const StyledAppBar = styled(AppBar)`
    background-color: ${Styles.planierColor.blueTurquoise.turquoisePrimary};
    padding: 0px;
    min-height: ${Styles.layout.mainNavigationHeight};
    box-shadow: none;
    display: flex;
    height: ${Styles.layout.mainNavigationHeight};
    position: relative;
`

const AppBarContentContainer = styled.div`
    display: flex;
    height: 100%;
`

const ApplicationBar: React.FC<IApplicationBarProps> = ({ pageTitle, pageDescription }) => {
    return (
        <StyledAppBar>
            <AppBarContentContainer>
                <MainNavigation />

                <ApplicationBarTitle pageDescription={pageDescription} pageTitle={pageTitle} />

                <MainMenu navItemsInDrawer />
            </AppBarContentContainer>
        </StyledAppBar>
    )
}

export default ApplicationBar
