import { IDropdownProps, IState } from './../Dropdown'

export const formValueItemMap = <T>(
    items: IDropdownProps<T>['items'],
    values: IDropdownProps<T>['values'],
    itemIdField: IDropdownProps<T>['itemIdField'],
    formerValueItems: IState<T>['valueItems']
): Map<T[keyof T], T> => {
    type TIdValuePair = [T[keyof T], T]

    if (!values) {
        return new Map<T[keyof T], T>([])
    }

    const newValueItems = items
        .filter((item) => values.has(item[itemIdField]))
        .map((item): TIdValuePair => [item[itemIdField], item])

    // we must keep the former valueItems in case they are no longer in the items list so we merge the new ones with the old
    const mergedValueItems = new Map<T[keyof T], T>([...formerValueItems, ...newValueItems])

    // ...but because we might now have valueItems that aren't actually values anymore, we delete these
    if (mergedValueItems.size !== values.size) {
        mergedValueItems.forEach((item, itemId) => {
            if (!values.has(itemId)) {
                mergedValueItems.delete(itemId)
            }
        })
    }

    return mergedValueItems
}

export const anyValuesMissingFromItems = (
    items: IDropdownProps<any>['items'],
    values: IDropdownProps<any>['values'],
    itemIdField: IDropdownProps<any>['itemIdField']
): boolean => {
    const valueItems = items.filter((item) => values.has(item[itemIdField]))

    return values.size !== valueItems.length
}
