import * as React from 'react'
import styled from '@emotion/styled'

import { Icon } from '../../../generic-components'

interface ISelectedFrameProps {
    selected: boolean
}

const SelectedFrame = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border: ${({ theme }) => `3px solid ${theme.colors.primaryTeal120}`};
`

const QuarterCircle = styled.div`
    width: 13px;
    height: 13px;
    background-color: ${({ theme }) => theme.colors.primaryTeal120};
    border-radius: 0 0 0 13px;
    margin-left: auto;
`

const StyledIcon = styled(Icon)`
    margin: -2px 0 10px 3px;
`

const SelectedNodeFrame: React.FunctionComponent<ISelectedFrameProps> = ({ selected }) => {
    if (!selected) {
        return null
    }

    return (
        <SelectedFrame>
            <QuarterCircle>
                <StyledIcon color="white" size={12}>
                    check
                </StyledIcon>
            </QuarterCircle>
        </SelectedFrame>
    )
}

export default SelectedNodeFrame
