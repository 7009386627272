import * as React from 'react'

import { EActionFunctionalityType, handleViewEngineActionThunk } from '../../view-engine'
import { ReadOnlyText } from '../../generic-components'
import { setModalProps } from '../../modal/State/ModalActions'
import { useAppDispatch } from '../../redux'
import { getTestId } from '../../test'

export interface IEmployeeLink {
    name: string
    id: number
    noLinkText?: string
    showModalBackdrop?: boolean
}

export const OpenEmployeeEditForm = {
    FunctionalityType: EActionFunctionalityType.ConfigurableModal,
    DataSourceActionId: '',
    FormId: 'EmployeeModifyForm',
    AdditionalPropertyMapping: [],
    AdditionalInitialValueSourceProperties: [],
    AdditionalInitialValues: [],
    IgnoreInitialValueFields: [],
    PermissionId: 'EDIT',
}

const EmployeeLink: React.FunctionComponent<IEmployeeLink> = ({ name, id, noLinkText, showModalBackdrop }) => {
    const dispatch = useAppDispatch()

    if (noLinkText) {
        return (
            <ReadOnlyText isLink={false} textOverflow="ellipsis" color="dark_100" usage="legacy_list_element">
                {noLinkText}
            </ReadOnlyText>
        )
    }
    const EDIT_PERMISSIONS = [{ Toiminto: 'EDIT', OnkoSallittu: true, SyyKieltoon: null }]

    const openEmployeeForm = async () => {
        await dispatch(
            handleViewEngineActionThunk({
                items: [{ Id: id, Kayttooikeudet: EDIT_PERMISSIONS }],
                functionalityAction: OpenEmployeeEditForm,
                dataSourceId: 'Employee',
                initializeDataSource: true,
                additionalRequestData: {},
            })
        )

        if (showModalBackdrop) {
            dispatch(setModalProps(OpenEmployeeEditForm.FormId, { showBackdrop: true }))
        }
    }

    const testId = getTestId(['LIST_LINK_EMPLOYEE'], name)

    return (
        <ReadOnlyText
            isLink={true}
            linkOnClick={openEmployeeForm}
            textOverflow="ellipsis"
            usage="list_element_link"
            tooltipEnabled
            testId={testId}
        >
            {name}
        </ReadOnlyText>
    )
}

export default EmployeeLink
