import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { selectAreValuePickersInitialised, selectValuePickerConfigurations } from '../State/ValuePickerSelectors'
import { fetchValuePickerConfigurationsThunk } from '../Thunks/ValuePickerThunks'
import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import { isEqual } from 'lodash-es'

interface IUseValuePickerConfigurationsReturn {
    valuePickerConfigurations: TValuePickerConfiguration[]
    areValuePickersInitialized: boolean
}

/**
 * Fetches and returns the configurations for the given value picker IDs.
 */
const useValuePickerConfigurations = (valuePickerIds: string[]): IUseValuePickerConfigurationsReturn => {
    const dispatch = useDispatch()

    const valuePickerConfigurations = useSelector(
        (state: RootState) => selectValuePickerConfigurations(state, valuePickerIds),
        isEqual
    )

    const areValuePickersInitialized = useSelector((state: RootState) =>
        selectAreValuePickersInitialised(state, valuePickerIds)
    )

    useEffect(() => {
        dispatch(fetchValuePickerConfigurationsThunk(valuePickerIds))
        // No dependencies intentionally. The thunk simply doesn't do anything if all the value pickers
        // already have the configurations loaded.
    })

    return {
        valuePickerConfigurations,
        areValuePickersInitialized,
    }
}

export default useValuePickerConfigurations
