import moment from 'moment'

import IValuePicker from '../types/IValuePicker'
import IValuePickerReducerCombined from '../types/IValuePickerReducerCombined'
import TValuePickerId from '../types/TValuePickerId'
import EValuePickerType from '../types/EValuePickerType'
import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import IValuePickerReducerState from '../types/IValuePickerReducerState'
import IValuePickerRegistryComponents from '../types/IValuePickerRegistryComponents'
import IDropdownValuePickerConfiguration from '../types/ValuePickerWithOptions/DropdownValuePicker/IDropdownValuePickerConfiguration'
import ITagListValuePickerConfiguration from '../types/ValuePickerWithOptions/TagListValuePicker/ITagListValuePickerConfiguration'
import valuePickerReducer from '../State/ValuePickerReducer'

export const getTestValuePickerId = (): TValuePickerId => 'TestValuePicker'
export const getNonExistingTestValuePickerId = (): TValuePickerId => 'NonExistingValuePicker'
export const getTestDropdownValuePickerId = (): TValuePickerId => 'TestDropdownValuePicker'
export const getTestTagListValuePickerId = (): TValuePickerId => 'TestTagListValuePicker'
export const getTestTextSearchValuePickerId = (): TValuePickerId => 'TestTextSearchValuePicker'

export const getTestValuePickerDataurl = (): string => 'testValuePickerDataUrl'

export const getTestDropdownValuePickerConfiguration = (): IDropdownValuePickerConfiguration => ({
    ValuePickerId: getTestDropdownValuePickerId(),
    ValuePickerType: EValuePickerType.Dropdown,
    DependsOn: [],
    DataRequestParameterName: 'sdokso',
    ItemIdField: 'Id',
    ItemOptionLabelFields: ['Id', 'Name'],
    OptionsEndpointUrl: getTestValuePickerDataurl(),
    ExtraRows: 'Include',
    ItemValueLabelFields: ['Name'],
    Text: { FetchError: 'Fetch error', Label: 'Test Dropdown Value Picker', MultipleSelected: 'multiple' },
    UseDynamicApiSearch: true,
    Limit: null,
    DefaultValues: null,
})

export const getTestTagListValuePickerConfiguration = (): ITagListValuePickerConfiguration => ({
    ValuePickerId: getTestTagListValuePickerId(),
    ValuePickerType: EValuePickerType.TagList,
    DependsOn: [],
    DataRequestParameterName: 'sdokso',
    ItemLabelFields: ['Name'],
    MainColorField: 'Color',
    SupportColorField: 'AnotherColor',
    ItemIdField: 'Id',
    OptionsEndpointUrl: getTestValuePickerDataurl(),
    ExtraRows: 'Include',
    Text: { FetchError: 'Fetch error' },
    UseDynamicApiSearch: true,
    Limit: null,
    DefaultValues: null,
})

export const getTestDataForValuePickerWithOptions = (): Record<string, unknown>[] => [
    { Id: 1, Name: 'testItem1', Color: '#00A2B8', AnotherColor: '#99D5EF' },
    { Id: 2, Name: 'testItem2', Color: '#694E4F', AnotherColor: '#D2C9CA' },
    { Id: 3, Name: 'testItem3', Color: '#AD6C36', AnotherColor: '#E6D2C2' },
    { Id: 4, Name: 'testItem4', Color: '#74588A', AnotherColor: '#D5CCDB' },
]

export const getTestValuePickerConfiguration = (): TValuePickerConfiguration => ({
    ValuePickerId: getTestValuePickerId(),
    ValuePickerType: EValuePickerType.DateRange,
    DependsOn: [],
    DataRequestParameterName: 'testValuePickerParam',
    DefaultValues: {
        StartFromCurrentDay: -1,
        StartFromCurrentDayUnit: 'year',
        EndFromCurrentDay: 0,
        EndFromCurrentDayUnit: 'year',
    },
})

export const getTestValuePicker = (): IValuePicker => ({
    configuration: getTestValuePickerConfiguration(),
    value: {
        start: moment('2019-10-01T00:00:00Z'),
        end: moment('2019-10-02T00:00:00Z'),
    },
    isInitialized: true,
})

export const getDropdownValuePicker = (): IValuePicker => ({
    configuration: getTestDropdownValuePickerConfiguration(),
    value: new Set(),
})

export const getTestDependingValuePickerId = (): TValuePickerId => 'DependingTestValuePicker'

export const getTestDependingValuePickerConfiguration = (): TValuePickerConfiguration => ({
    ValuePickerId: getTestDependingValuePickerId(),
    ValuePickerType: EValuePickerType.DateRange,
    DependsOn: [
        {
            ValuePickerId: getTestValuePickerId(),
            ParameterName: 'testValuePickerParam',
        },
    ],
    DataRequestParameterName: 'testValuePickerParam',
    DefaultValues: {
        StartFromCurrentDay: -1,
        StartFromCurrentDayUnit: 'year',
        EndFromCurrentDay: 0,
        EndFromCurrentDayUnit: 'year',
    },
})

export const getTestDependingValuePicker = (): IValuePicker => ({
    configuration: getTestDependingValuePickerConfiguration(),
    value: {
        start: moment('2019-11-01T00:00:00Z'),
        end: moment('2019-11-02T00:00:00Z'),
    },
})

export const getTestConfigurableListValuePickerId = (): TValuePickerId => 'ListTestValuePicker'

export const getTestConfigurableListValuePickerConfiguration = (): TValuePickerConfiguration => ({
    ValuePickerId: getTestConfigurableListValuePickerId(),
    ValuePickerType: EValuePickerType.ConfigurableList,
    ListId: 'TestValuePickerList',
    DependsOn: [],
    DataRequestParameterName: '',
    UsePageTemplate: false,
    DefaultValues: null,
})

export const getTestConfigurableListValuePicker = (): IValuePicker => ({
    configuration: getTestConfigurableListValuePickerConfiguration(),
    value: new Set(),
})

export const getTestValuePickerReducerState = (): IValuePickerReducerState =>
    new Map([
        [getTestValuePickerId(), getTestValuePicker()],
        [getTestDependingValuePickerId(), getTestDependingValuePicker()],
        [getTestConfigurableListValuePickerId(), getTestConfigurableListValuePicker()],
        [getTestDropdownValuePickerId(), getDropdownValuePicker()],
    ])

export const getTestValuePickerCombinedReducerState = (): IValuePickerReducerCombined => ({
    valuePicker: getTestValuePickerReducerState(),
})

export const getMockValuePickerRegistryComponents = (
    components?: Partial<IValuePickerRegistryComponents>
): IValuePickerRegistryComponents => ({
    PageTimeRangePicker: () => null,
    PageDateRangePicker: () => null,
    ConfigurableListValuePicker: () => null,
    ValuePickerWithOptions: () => null,
    TextSearchPicker: () => null,
    TogglePicker: () => null,
    AdditionalServicesPicker: () => null,
    ...components,
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTestValuePickerDependentReducerMap = () => ({
    valuePicker: valuePickerReducer,
})
