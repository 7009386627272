import styled from '@emotion/styled'

const PageContainer = styled.div`
    display: inherit;
    flex-direction: inherit;
    width: inherit;
    height: inherit;
`

export default PageContainer
