import * as React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { RootState } from 'typesafe-actions'

import {
    selectDataSourceId,
    selectIsListSelectable,
    selectListBulkActions,
    selectListStaticActions,
    selectSelectedItemIds,
} from '../../State/ConfigurableListSelectors'
import {
    IDataSourceAction,
    selectActionConfigurations,
    selectDataSourceItemsByIds,
    TDataSourceId,
} from '../../../data-source'
import { IDataSourceItem, TDataSourceItemId } from '../../../data-source-types'
// @@ The corresponding module import does not work with jest
import {
    executeDownloadActionThunk,
    executeListItemActionThunk,
    openListActionConfirmationDialogThunk,
    openListModalThunk,
} from '../../Thunks/ConfigurableListThunks'
import { IListViewButtonAction } from '../../interfaces/TDisplayAction'
import IListViewAction from '../../interfaces/IListViewAction'
import ListActionButtons from './ListActionButtons'
import SelectedRowsCounter from '../SelectedRowsCounter'
import TFunctionalityAction, { IListViewConfigurableListModalAction } from '../../interfaces/TFunctionalityAction'
import IListActionConfirmationDialogModalProps from '../../interfaces/IListActionConfirmationDialogModalProps'
import { openFormViewConfigurableModalThunk, openFormViewCustomModalThunk } from '../../../form-view'
import { BoundThunk } from '../../../generic-state'

interface IStateProps {
    actionConfigurations: IDataSourceAction[]
    bulkActions: IListViewAction<IListViewButtonAction, TFunctionalityAction>[]
    dataSourceId: TDataSourceId | null
    isSelectable: boolean
    selectedItemIds: ReadonlySet<TDataSourceItemId>
    selectedItems: IDataSourceItem[]
    staticActions: IListViewAction<IListViewButtonAction, TFunctionalityAction>[]
}

interface IDispatchProps {
    executeListItemAction: BoundThunk<typeof executeListItemActionThunk>
    openListActionConfirmationDialog: BoundThunk<typeof openListActionConfirmationDialogThunk>
    openFormViewConfigurableModal: BoundThunk<typeof openFormViewConfigurableModalThunk>
    openFormViewCustomModal: BoundThunk<typeof openFormViewCustomModalThunk>
    openListModal: BoundThunk<typeof openListModalThunk>
    executeDownloadAction: BoundThunk<typeof executeDownloadActionThunk>
}

interface IOwnProps {
    listId: string
    onModalSubmitCallback?: () => void
}

export interface IListActionButtonsContainerProps extends IOwnProps, IStateProps, IDispatchProps {}

export class ListActionButtonsContainer extends React.Component<IListActionButtonsContainerProps> {
    private handleBulkActionClickWithConfigurableModal = (modalId: string) => {
        const { dataSourceId, openFormViewConfigurableModal, selectedItemIds, onModalSubmitCallback } = this.props

        openFormViewConfigurableModal(
            modalId,
            dataSourceId,
            [...selectedItemIds],
            undefined,
            undefined,
            {},
            onModalSubmitCallback
        )
    }

    private handleBulkActionClickWithCustomModal = (modalId: string, dataSourceId: string) => {
        const { selectedItemIds } = this.props

        this.props.openFormViewCustomModal(modalId, dataSourceId, [...selectedItemIds])
    }

    private handleStaticActionClick = (modalId: string) => {
        const { dataSourceId, openFormViewConfigurableModal } = this.props

        openFormViewConfigurableModal(modalId, dataSourceId, [])
    }

    private handleConfirmationActionButtonClick = (
        callbackProps: Pick<IListActionConfirmationDialogModalProps, 'actionConfiguration' | 'confirmationDialogTexts'>
    ) => {
        const { listId, openListActionConfirmationDialog, selectedItemIds } = this.props
        const { actionConfiguration, confirmationDialogTexts } = callbackProps

        const confirmationDialogProps: IListActionConfirmationDialogModalProps = {
            actionConfiguration,
            confirmationDialogTexts,
            listId,
            itemIds: [...selectedItemIds],
        }

        openListActionConfirmationDialog(confirmationDialogProps)
    }

    private handleDownloadActionButtonClick = (actionConfiguration: IDataSourceAction) => {
        const { listId, executeDownloadAction, selectedItemIds } = this.props

        executeDownloadAction(listId, [...selectedItemIds], actionConfiguration.Id)
    }

    private handleImmediateActionButtonClick = (actionConfiguration: IDataSourceAction) => {
        const { executeListItemAction, listId, selectedItemIds } = this.props

        executeListItemAction(listId, [...selectedItemIds], actionConfiguration.Id)
    }

    handleListModalButtonClick = (listModalFunctionalityConfiguration: IListViewConfigurableListModalAction): void => {
        const { openListModal, selectedItemIds, listId: sourceListId } = this.props
        const {
            ListId: listIdForModal,
            ValuePickerPropertyMapping,
            ModalTitle,
            HiddenValuePickerIds,
        } = listModalFunctionalityConfiguration

        openListModal({
            sourceListId,
            listIdForModal,
            selectedItemIds: [...selectedItemIds],
            valuePickerValuesPropertyMapping: ValuePickerPropertyMapping,
            modalTitle: ModalTitle,
            hiddenValuePickerIds: HiddenValuePickerIds,
        })
    }

    render(): React.ReactNode {
        const { actionConfigurations, bulkActions, selectedItemIds, staticActions, isSelectable } = this.props

        const buttonsDisabled = selectedItemIds.size === 0

        return (
            <Grid item>
                <Grid container spacing={1}>
                    <ListActionButtons
                        actionConfigurations={actionConfigurations}
                        actions={staticActions}
                        onConfirmationButtonClick={this.handleConfirmationActionButtonClick}
                        onCustomModalClick={this.handleBulkActionClickWithCustomModal}
                        onDownloadButtonClick={this.handleDownloadActionButtonClick}
                        onImmediateButtonClick={this.handleImmediateActionButtonClick}
                        onListModalButtonClick={this.handleListModalButtonClick}
                        onModalButtonClick={this.handleStaticActionClick}
                    />

                    {isSelectable && (actionConfigurations.length > 0 || bulkActions.length > 0) && (
                        <React.Fragment>
                            <SelectedRowsCounter selectedItemsAmount={selectedItemIds.size} />

                            <ListActionButtons
                                actionConfigurations={actionConfigurations}
                                actions={bulkActions}
                                selectedItems={this.props.selectedItems}
                                disabled={buttonsDisabled}
                                onConfirmationButtonClick={this.handleConfirmationActionButtonClick}
                                onCustomModalClick={this.handleBulkActionClickWithCustomModal}
                                onDownloadButtonClick={this.handleDownloadActionButtonClick}
                                onImmediateButtonClick={this.handleImmediateActionButtonClick}
                                onListModalButtonClick={this.handleListModalButtonClick}
                                onModalButtonClick={this.handleBulkActionClickWithConfigurableModal}
                            />
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state: RootState, { listId }: IOwnProps): IStateProps => {
    const dataSourceId = selectDataSourceId(state, listId)
    const bulkActions = selectListBulkActions(state, listId)
    const staticActions = selectListStaticActions(state, listId)
    const isSelectable = selectIsListSelectable(state, listId)
    const selectedItemIds = selectSelectedItemIds(state, listId)
    const selectedItems = [...selectDataSourceItemsByIds(state, dataSourceId, [...selectedItemIds])]

    return {
        actionConfigurations: selectActionConfigurations(state, dataSourceId),
        bulkActions,
        dataSourceId,
        isSelectable,
        selectedItemIds,
        staticActions,
        selectedItems,
    }
}

const mapDispatchToProps = {
    executeListItemAction: executeListItemActionThunk,
    openListActionConfirmationDialog: openListActionConfirmationDialogThunk,
    openFormViewConfigurableModal: openFormViewConfigurableModalThunk,
    openFormViewCustomModal: openFormViewCustomModalThunk,
    openListModal: openListModalThunk,
    executeDownloadAction: executeDownloadActionThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListActionButtonsContainer)
