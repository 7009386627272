import { Notification } from 'react-notification-system'
import { createReducer } from 'typesafe-actions'

import {
    clearNotifications,
    displayErrorToaster,
    displaySuccessToaster,
    displayWarningToaster,
} from '../Actions/NotificationActions'
import { errorOnPromiseFailed } from 'action-creators/ErrorActions'

// eslint-disable-next-line no-shadow
enum ENotificationLevels {
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning',
    INFO = 'info',
}

const initialState: Notification[] = []

export const notificationReducer = createReducer(initialState)
    .handleAction(displayWarningToaster, (state, action) => {
        return [{ level: ENotificationLevels.WARNING, message: action.payload.message, autoDismiss: 8 }]
    })
    .handleAction(displaySuccessToaster, (state, action) => {
        return [{ level: ENotificationLevels.SUCCESS, message: action.payload.message }]
    })
    .handleAction([displayErrorToaster, errorOnPromiseFailed], (state, action) => {
        return [{ level: ENotificationLevels.ERROR, message: action.payload.message, autoDismiss: 8 }]
    })
    .handleAction(clearNotifications, () => {
        return []
    })

export default notificationReducer
