import IGroupToRegister from './Types/IGroupToRegister'
import TDirection from './Types/TDirection'
import TElementWithCustomProperties from './Types/TElementWithCustomProperties'

const scrollGroups = new Map<string, TElementWithCustomProperties[]>()

const synchronizeScrollingForElements = (
    element: TElementWithCustomProperties,
    scrollSyncedElements: TElementWithCustomProperties[],
    directions: Set<TDirection> = new Set(['vertical', 'horizontal'])
) => {
    if (directions.has('horizontal')) {
        let scrollX = element.scrollLeft
        const xRate = scrollX / (element.scrollWidth - element.clientWidth)
        const xPositionChanged = scrollX !== element.eX

        if (xPositionChanged) {
            scrollSyncedElements.forEach((otherEl) => {
                scrollX = Math.round(xRate * (otherEl.scrollWidth - otherEl.clientWidth))
                otherEl.eX = scrollX

                if (Math.round(otherEl.scrollLeft - scrollX) !== 0) {
                    otherEl.scrollLeft = scrollX
                }
            })
        }

        element.eX = scrollX
    }

    if (directions.has('vertical')) {
        let scrollY = element.scrollTop
        const yRate = scrollY / (element.scrollHeight - element.clientHeight)
        const yPositionChanged = scrollY !== element.eY

        if (yPositionChanged) {
            scrollSyncedElements.forEach((otherEl) => {
                scrollY = Math.round(yRate * (otherEl.scrollHeight - otherEl.clientHeight))
                otherEl.eY = scrollY

                if (Math.round(otherEl.scrollTop - scrollY) !== 0) {
                    otherEl.scrollTop = scrollY
                }
            })
        }

        element.eY = scrollY
    }
}

const removeEventListenersForGroupElements = (groupId: string) => {
    if (!scrollGroups.has(groupId)) {
        return
    }

    const groupElements = scrollGroups.get(groupId) as TElementWithCustomProperties[]

    groupElements.forEach((element) => {
        element.removeEventListener('scroll', element[`syn-${groupId}`])
    })
}

const resetGroups = (groupIds: string[]): void => {
    groupIds.forEach((groupId) => {
        if (!scrollGroups.has(groupId)) {
            return
        }

        const groupElements = scrollGroups.get(groupId) as TElementWithCustomProperties[]

        groupElements.forEach((element) => {
            element.removeEventListener('scroll', element[`syn-${groupId}`])
        })

        scrollGroups.delete(groupId)
    })
}

/*
 * This is vastly adapted version of syncscroll ( https://github.com/purely-smile/syncscroll )
 * script. In the original there is no possibility to separate between horizontal and vertical
 * scrolling, but that is what we need.
 */
const registerElementsForSyncedScrollGroup = ({ directions, elements, groupId }: IGroupToRegister): void => {
    if (!scrollGroups.has(groupId)) {
        scrollGroups.set(groupId, [])
    }

    const allGroupElements = scrollGroups.get(groupId) as TElementWithCustomProperties[]
    const newGroupElements = elements.filter((element) => !allGroupElements.some((el) => el === element))

    newGroupElements.forEach((element) => allGroupElements.push(element))

    removeEventListenersForGroupElements(groupId)

    const directionsAsSet = new Set(directions)

    // create the event listeners
    allGroupElements.forEach((element) => {
        const otherElements = allGroupElements.filter((el) => element !== el)

        element.addEventListener(
            'scroll',
            (element[`syn-${groupId}`] = () => {
                synchronizeScrollingForElements(element, otherElements, directionsAsSet)
            })
        )
    })
}

export { registerElementsForSyncedScrollGroup, resetGroups }
