import PropTypes from 'prop-types'
import { Component } from 'react'
import reactCss from 'reactcss'

import ValidationError from 'components/molecules/ValidationError'

/**
 * Näyttää validointivirheen halutun ikonin tooltipissä. Oletusarvoisesti punainen *-symboli.
 * Buttonin perään tulee punainen huutomerkki (error) - ikoni. Koska validointivirhe-ikoni on
 * buttoneilla keskitettynä ja lähempänä painiketta kuin muilla komponenteilla, tulee Buttonille
 * antaa buttonStyles - propsi jotta buttonien erilaiset tyylit aktivoituvat.
 * Sijoittuu validoitavan arvon esittävän komponentin viereen. Anna haluttu komponentti
 * tämän komponentin sisällä.
 * Suunniteltu toimimaan yhdessä Validator-luokkien kanssa. Komponentin message-propertyyn
 * voi siis asettaa suoraan jonkin _errors arvon. Validointivirhettä ei näytetä, jos sitä ei ole.
 *
 * Esimerkki:
 * <ValidationErrorAfter message={ tiketti._errors.Otsikko }>
 *     <TextInput />
 * </ValidationErrorAfter>
 */

const BUTTON = 'Button'
const ERROR = 'error'
const ASTERISKI = '*'

export default class ValidationErrorAfter extends Component {
    styles() {
        return reactCss({
            default: {
                component: {
                    display: 'inline',
                    flexGrow: 1,
                    bottom: 'inherit',
                },
                container: {
                    display: 'flex',
                    width: '100%',
                    bottom: 'inherit',
                },
            },
        })
    }

    _annaValidationErrorilleIlmiasu() {
        return this.props.children.type && this.props.children.type.name === BUTTON
            ? { errorIcon: ERROR, buttonStyles: true }
            : { errorIcon: ASTERISKI, buttonStyles: false }
    }

    render() {
        const { errorIcon, buttonStyles } = this._annaValidationErrorilleIlmiasu()

        return (
            <span style={this.styles().container}>
                <span style={this.styles().component}>{this.props.children}</span>
                <ValidationError buttonStyles={buttonStyles} errorIcon={errorIcon}>
                    {this.props.message}
                </ValidationError>
            </span>
        )
    }
}

ValidationErrorAfter.displayName = 'ValidationErrorAfter'

ValidationErrorAfter.propTypes = {
    children: PropTypes.element,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
}
