// eslint-disable-next-line no-shadow
enum EListViewActionFunctionalityType {
    Confirmation = 'ConfirmationAction',
    Immediate = 'ImmediateAction',
    ConfigurableModal = 'ConfigurableModalAction',
    CustomModal = 'CustomModalAction',
    ListModal = 'ListModal',
    Download = 'DownloadAction',
    ShowOverflow = 'ShowOverflow',
}

export default EListViewActionFunctionalityType
