import { extend } from 'lodash-es'
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'
import reactCss from 'reactcss'

import Label from 'components/atoms/Label'
import _Log from 'common/Log'
import Styles from 'constants/Styles'

const Log = new _Log('Table')

/**
 * Taulukkomuotoinen asettelu (ilman tablea).
 *
 * Anna sarakkeiden sisällöt elementin sisällä taulukkoina. Yksi taulukko vastaa yhtä saraketta,
 * ja renderöidään ylhäältä alaspäin.
 *
 * Esimerkki - Taulukko, jossa on kaksi saraketta ja kaksi riviä:
 * <Table>
 *     [ <Empty />, <Empty /> ],
 *     [ <Empty />, <Empty /> ]
 * </Table>
 *
 * Halutessasi voit antaa sarakkeille otsikot columnHeaders-propsissa. Otsikot pitää antaa taulukossa
 * lokalisaatiokoodeina, ja niitä on oltava yhtä monta kuin sarakkeita. Jos et halua antaa jollekin
 * sarakkeelle otsikkoa, voit antaa kielikoodin tilalle null-arvon.
 *
 * Esimerkki - Taulukko, jossa kahdella kolmesta sarakkeesta on sarakeotsikko:
 * <Table columnHeaders={[ 'column-one', 'column-two', null ]}>
 *     [ <Empty />, <Empty /> ],
 *     [ <Empty />, <Empty /> ],
 *     [ <Empty />, <Empty /> ]
 * </Table>
 *
 * Halutessasi voit antaa sarakkeille myös omia tyylittelyjä. Tätä voi käyttää esimerkiksi tilanteessa,
 * jossa haluat kontrolloida sarakkeiden suhteellistä leveyttä. Tyylit pitää antaa taulukossa olioina,
 * ja niitä on oltava yhtä monta kuin sarakkeita. Jos et halua antaa jollekin sarakkeelle tyyliä, voit
 * antaa olion tilalle null-arvon.
 *
 * Esimerkki - Taulukko, jossa sarakkeiden suhteelliset leveydet ovat 2:1:1
 * <Table columnStyles={[ { flexGrow: 2 }, { flexGrow: 1 }, { flexGrow: 1 } ]}>
 *     [ <Empty />, <Empty /> ],
 *     [ <Empty />, <Empty /> ],
 *     [ <Empty />, <Empty /> ]
 * </Table>
 *
 * Taulukon riveille (pl. sarakeotsikkorivi ja footer) renderöidään oletuksena reunaviivat. Voit
 * renderöidä taulukon ilman reunoja antamalla parametrin withBorders arvolla false.
 * Voit antaa taulukolle myös footer footer-propsissa. Footer renderöidään taulukon alapuolelle
 * koko taulukon levyisenä.
 */
export default class Table extends Component {
    columns = createRef()

    componentDidMount() {
        if (this.columns.current) {
            this._adjustRowHeights(this.columns.current.children)
        }
    }

    componentDidUpdate() {
        if (this.columns.current) {
            this._adjustRowHeights(this.columns.current.children)
        }
    }

    styles() {
        return reactCss({
            default: {
                columnBaseStyle: {
                    display: 'flex',
                    flexDirection: 'column',
                },
                columnContainer: {
                    display: 'flex',
                    width: '100%',
                },
                container: {
                    width: '100%',
                },
                footer: {
                    padding: Styles.layout.smallPadding,
                },
                headerRowCell: {
                    backgroundColor: Styles.mainColor.darkGrey,
                    color: Styles.mainColor.white,
                    paddingBottom: Styles.layout.smallPadding,
                    paddingLeft: Styles.layout.smallPadding,
                    paddingTop: Styles.layout.smallPadding,
                },
            },
        })
    }

    _adjustCellHeightsOnRow(columns, row, height) {
        const columnCount = columns.length
        for (let column = 0; column < columnCount; column++) {
            const cell = columns[column].children[row]
            cell.style.height = height + 'px'
        }
    }

    _adjustRowHeights(columns) {
        const rowCount = columns[0].children.length
        for (let row = 0; row < rowCount; row++) {
            const maxHeight = this._findMaxHeight(columns, row)
            this._adjustCellHeightsOnRow(columns, row, maxHeight)
        }
    }

    _cellStyle(isFirstRow, isLastRow, isFirstColumn, isLastColumn) {
        const withBorders = this.props.withBorders
        if (isFirstRow || !withBorders) {
            return {}
        }

        return {
            borderBottom: withBorders && isLastRow ? 'solid 1px ' + Styles.mainColor.darkGrey : undefined,
            borderLeft: withBorders && isFirstColumn ? 'solid 1px ' + Styles.mainColor.darkGrey : undefined,
            borderRight:
                withBorders && isLastColumn
                    ? 'solid 1px ' + Styles.mainColor.darkGrey
                    : 'dotted 1px ' + Styles.mainColor.darkGrey,
            borderTop: withBorders ? 'solid 1px ' + Styles.mainColor.darkGrey : undefined,
            padding: Styles.layout.smallPadding,
        }
    }

    _columnStyle(index) {
        const baseStyle = this.styles().columnBaseStyle
        if (this.props.columnStyles) {
            const columnStyle = this.props.columnStyles[index]
            return columnStyle !== null ? extend(baseStyle, columnStyle) : baseStyle
        } else {
            return baseStyle
        }
    }

    _findMaxHeight(columns, row) {
        let maxHeight = 0
        const columnCount = columns.length
        for (let column = 0; column < columnCount; column++) {
            const cell = columns[column].children[row]
            const cellHeight = cell.scrollHeight > cell.offsetHeight ? cell.scrollHeight : cell.offsetHeight
            maxHeight = cellHeight > maxHeight ? cellHeight : maxHeight
        }
        return maxHeight
    }

    _hasHeaders() {
        return this.props.columnHeaders !== undefined
    }

    _renderColumn(contents, index) {
        return (
            <div key={'column' + index} style={this._columnStyle(index)}>
                {contents}
            </div>
        )
    }

    _renderColumnHeader(header, index) {
        const headerText = header ? <Label color={Styles.mainColor.white}>{header}</Label> : <div>&nbsp;</div>
        return (
            <div key={'header' + index} style={this.styles().headerRowCell}>
                {headerText}
            </div>
        )
    }

    _renderColumnWithHeader(header, contents, index) {
        if (contents.length === 0) {
            return <div key={'column' + index} />
        }

        return (
            <div key={'column' + index} style={this._columnStyle(index)}>
                {this._renderColumnHeader(header, index)}
                {contents}
            </div>
        )
    }

    _renderFooter() {
        if (!this.props.footer) {
            return undefined
        }

        return (
            <div key="footer" style={this.styles().footer}>
                {this.props.footer}
            </div>
        )
    }

    _renderWithHeaders(columnHeaders, columns) {
        return columns.map((column, index) => {
            const header = columnHeaders[index]
            return this._renderColumnWithHeader(header, column, index)
        })
    }

    _renderWithoutHeaders(columns) {
        return columns.map(this._renderColumn.bind(this))
    }

    _styleCells(cells, isFirstColumn, isLastColumn, columnIndex) {
        return cells.map((cell, index) => {
            const isFirstRow = false // Otsikkorivi on eka rivi, tässä stailataan sisältöä.
            const isLastRow = index === cells.length - 1
            const style = this._cellStyle(isFirstRow, isLastRow, isFirstColumn, isLastColumn)
            return (
                <div key={'column' + columnIndex + 'row' + index} style={style}>
                    {cell}
                </div>
            )
        })
    }

    _styleColumns(columns) {
        return columns.map((column, index) => {
            const isFirstColumn = index === 0
            const isLastColumn = index === columns.length - 1
            return this._styleCells(column, isFirstColumn, isLastColumn, index)
        })
    }

    _validate() {
        const columnsCount = this.props.children.length
        if (this.props.columnHeaders) {
            const columnHeadersCount = this.props.columnHeaders.length
            if (columnHeadersCount !== columnsCount) {
                Log.warn(
                    'The amount of column headers $0, differs from amount of columns $1',
                    columnHeadersCount,
                    columnsCount
                )
            }
        }
        if (this.props.columnStyles) {
            const columnStylesCount = this.props.columnStyles.length
            if (columnStylesCount !== columnsCount) {
                Log.warn(
                    'The amount of column styles $0, differs from amount of columns $1',
                    columnStylesCount,
                    columnsCount
                )
            }
        }
    }

    render() {
        this._validate()
        const columnHeaders = this.props.columnHeaders
        const styledColumns = this._styleColumns(this.props.children)
        const columns =
            columnHeaders === undefined
                ? this._renderWithoutHeaders(styledColumns)
                : this._renderWithHeaders(columnHeaders, styledColumns)
        return (
            <div style={this.styles().container}>
                <div key="columns" ref={this.columns} style={this.styles().columnContainer}>
                    {columns}
                </div>
                {this._renderFooter()}
            </div>
        )
    }
}

Table.defaultProps = {
    withBorders: true,
}

Table.displayName = 'Table'

Table.propTypes = {
    children: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.element)).isRequired,
    columnHeaders: PropTypes.arrayOf(PropTypes.string),
    columnStyles: PropTypes.arrayOf(PropTypes.object),
    footer: PropTypes.element,
    withBorders: PropTypes.bool,
}
