import { Component, ReactNode, CSSProperties } from 'react'
import * as React from 'react'
import PropTypes from 'prop-types'
import Popover, { PopoverOrigin } from '@material-ui/core/Popover'

interface IOwnProps {
    anchorElement: HTMLElement | null
    anchorOrigin?: PopoverOrigin
    children: ReactNode
    onClose: () => void
    open: boolean
    style: CSSProperties
    transformOrigin: PopoverOrigin
}

const paperStyles: CSSProperties = {
    overflowY: 'auto',
}

/**
 * Wrappaa Material-ui:n Popover-komponentin. Popover on overlay, jonka saa
 * suljettua klikkaamalla mitä tahansa muuta kuin popoveria.
 *
 * Anna vähintään children joka näytetään popoverissa, tila popoverin näkyvyydestä,
 * sekä tapahtuma jotka laukeaa popoverin sulkiessa (kun käyttäjä klikkaa jotakin
 * muuta kuin itse popoveria).
 *
 * Esim.
 * <MaterialPopover
 *     onClose={ this.jokuAction.dispatch }
 *     open={ jokuBooleanEhto } >
 *     <Image>joku_lapsi_tassa</Image>
 * </MaterialPopover>
 */
export default class MaterialPopover extends Component<IOwnProps> {
    static readonly displayName = 'MaterialPopover'

    static readonly propTypes = {
        anchorOrigin: PropTypes.object,
        children: PropTypes.object.isRequired,
        anchorElement: PropTypes.object,
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool,
        popoverStyle: PropTypes.object,
        transformOrigin: PropTypes.object,
    }

    static readonly defaultProps = {
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        transformOrigin: { horizontal: 'right', vertical: 'top' },
    }

    render(): React.ReactNode {
        const { anchorElement, anchorOrigin, children, onClose, open, style, transformOrigin } = this.props

        return (
            <Popover
                anchorEl={anchorElement}
                anchorOrigin={anchorOrigin}
                onClose={onClose}
                open={open}
                PaperProps={{ style: { ...paperStyles, ...style } }}
                transformOrigin={transformOrigin}
            >
                {children}
            </Popover>
        )
    }
}
