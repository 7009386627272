import MockAdapter from 'axios-mock-adapter/types'

import { WebApiMock } from '../../test'
import { getTestListConfiguration, getTestListId } from './ConfigurableListTestData'
import { AxiosRequestConfig } from 'axios'

export default class ConfigurableListWebApiMock extends WebApiMock {
    constructor(axiosMock?: MockAdapter) {
        super(axiosMock)
    }

    mockListConfigurationFetchRequest(configuration = getTestListConfiguration(), statusCode = 200): void {
        this.axiosMock.onGet(`/ViewEngine/ListView?id=${configuration.Id}`).reply(statusCode, configuration)
    }

    getHistoryListConfigurationFetchRequest(listId = getTestListId()): AxiosRequestConfig | null {
        return this.getHistoryForRequest(`/ViewEngine/ListView?id=${listId}`, 'get')
    }
}
