import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import { TooltipWithEllipsis } from '../../generic-components'
import moment, { Moment } from 'moment'
import styled from '@emotion/styled'

export interface IListElementTimeProps {
    displayValue: string | Moment
    className?: string
}

const formatTime = (value: string | Moment): string => {
    if (!value) {
        return ''
    }

    const momentValue = moment(value)

    return momentValue.format('HH:mm')
}

const DisplayElementTime = styled(Typography)`
    font-family: Open Sans;
    line-height: 20px;
`

const ListElementTime: React.FunctionComponent<IListElementTimeProps> = ({ className, displayValue }) => (
    <TooltipWithEllipsis title={displayValue}>
        {(ref) => (
            <DisplayElementTime className={className} display="inline" noWrap ref={ref} variant="listElement">
                {formatTime(displayValue)}
            </DisplayElementTime>
        )}
    </TooltipWithEllipsis>
)

export default ListElementTime
