import { AxiosError } from 'axios'
import { createAction } from 'typesafe-actions'

import { Translation } from '../../localization'
import { isServerError } from '../../rest-api'
import { getLogger } from '../../log'

const Log = getLogger('notifications.NotificationActions')

interface IToasterOptionsError {
    startOfErrorMessage?: string
}

const getDisplayMessage = (message: string | JSX.Element): string | JSX.Element => {
    if (typeof message !== 'string') {
        return message
    }

    return Translation.has(message) ? Translation.translateKey(message) : message
}

export const displayWarningToaster = createAction('Warning', (message: string | JSX.Element) => {
    const messageToDisplay = getDisplayMessage(message)
    return {
        message: messageToDisplay,
    }
})()

export const displaySuccessToaster = createAction('Success', (message: string) => {
    const messageToDisplay = getDisplayMessage(message)
    return {
        message: messageToDisplay,
    }
})()

export const clearNotifications = createAction('ClearNotifications')()

const isErrorObject = (message: unknown | Error): message is Error => {
    return (message as Error).stack !== undefined
}

const getErrorMessageToDisplay = (
    errorOrMessage: string | Error | AxiosError | JSX.Element,
    options?: IToasterOptionsError
): string | JSX.Element => {
    let notificationErrorStart = `${Translation.translateKey('notification-Error')} `

    if (options?.startOfErrorMessage) {
        const maybeTranslatedStartOfMessage = Translation.has(options.startOfErrorMessage)
            ? Translation.translateKey(options.startOfErrorMessage)
            : options.startOfErrorMessage

        notificationErrorStart = `${maybeTranslatedStartOfMessage}. `
    }

    if (isServerError(errorOrMessage)) {
        return errorOrMessage.response?.data.Message
            ? notificationErrorStart + errorOrMessage.response.data.Message
            : notificationErrorStart
    }

    if (isErrorObject(errorOrMessage)) {
        const { message } = errorOrMessage
        const errorMessage = Translation.has(message) ? Translation.translateKey(message) : message

        return notificationErrorStart + errorMessage
    }

    if (typeof errorOrMessage !== 'string') {
        return errorOrMessage
    }

    return Translation.has(errorOrMessage) ? Translation.translateKey(errorOrMessage) : errorOrMessage
}

export const displayErrorToaster = createAction(
    'Error',
    (message: string | Error | AxiosError | JSX.Element, options?: IToasterOptionsError) => {
        const messageToDisplay = getErrorMessageToDisplay(message, options)

        if (typeof messageToDisplay === 'string') {
            Log.error(messageToDisplay)
        }

        return {
            message: messageToDisplay,
        }
    }
)()
