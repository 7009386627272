import moment, { Moment } from 'moment'
import { isNull, isObject } from 'lodash-es'

interface IValidTimeRangeValue {
    startTime: Moment
    endTime: Moment
}

interface ITimeRange {
    startTime: null | Moment
    endTime: null | Moment
}

export const isTimeRange = (timeRange: unknown): timeRange is ITimeRange => {
    if (!isObject(timeRange)) {
        return false
    }

    const { startTime, endTime } = timeRange as { startTime: unknown; endTime: unknown }

    if (startTime === null && endTime === null) {
        return true
    }

    const isStartValidMoment = moment.isMoment(startTime) && startTime.isValid()
    const isEndValidMoment = moment.isMoment(endTime) && endTime.isValid()

    return (isStartValidMoment || startTime === null) && (isEndValidMoment || endTime === null)
}

export const isValidTimeRange = (timeRange: unknown): timeRange is IValidTimeRangeValue => {
    if (!isTimeRange(timeRange)) {
        return false
    }

    const { startTime, endTime } = timeRange
    return startTime !== null && endTime !== null
}

export const isNullableMoment = (value: unknown): value is Moment | null => moment.isMoment(value) || isNull(value)

interface IDateRangeNullable {
    start: Moment | null
    end: Moment | null
}

export const isNullableDateTimeRange = (dateRange: unknown): dateRange is IDateRangeNullable => {
    const dateRangeTyped = dateRange as IDateRangeNullable

    if (!isObject(dateRangeTyped)) {
        return false
    }

    if (!isNullableMoment(dateRangeTyped.start)) {
        return false
    }
    if (!isNullableMoment(dateRangeTyped.end)) {
        return false
    }

    return true
}
