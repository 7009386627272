import styled from '@emotion/styled'
import { JSX, useContext, useEffect, useRef } from 'react'
import { EMessageType } from '../Types/IMessageThread'
import Message from './Message'
import LoginUserContext from 'context/LoginUserContext'
import { Icon, TextInputV2, TranslatedButton } from '../../generic-components'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectMessageThreadsMessages,
    selectReplyMessageValue,
    selectReplyToThread,
    selectShowMessageThread,
    selectViewedMessageThread,
} from '../State/MessagingSelectors'
import {
    resetThreadMessages,
    resetViewedMessageThread,
    setReplyMessageValue,
    setShowMessageThread,
} from '../State/MessagingReducer'
import CenteredLoadingIndicator from './CenteredLoadingIncicator'
import { replyToMessageThread, updateMessageThreadIsReadStatusById } from '../State/MessagingThunks'
import { useAppTheme } from '../../theme/'

const TRANSITION_DURATION = 400

const bottomActionsHeight = 80

const MessagesContainer = styled.div`
    padding: 32px 32px 32px 64px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    overflow-y: auto;
`

const ReplyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px 32px;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 12px;
    border-top: 1px solid ${({ theme }) => theme.colors.neutralsGrey30};
    padding: 24px 32px;
    width: 100%;
    height: ${bottomActionsHeight}px;
`

const CloseMessageThreadPanel = styled.div`
    position: absolute;
    height: calc(100% - ${bottomActionsHeight}px);
    width: 48px;
    background-color: ${({ theme }) => theme.colors.neutralsWhite100};
    display: flex;
    align-items: flex-start;
    justify-content: center;
    transition: ${({ theme }) => `background-color ${theme.tokens.transitionQuick}`};
    padding-top: 24px;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.neutralsGrey11};
    }
`

const ThreadContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 600px;
`

export const MessageThreadForm = (): JSX.Element => {
    const dispatch = useDispatch()
    const { colors } = useAppTheme()

    const bottomRef = useRef<null | HTMLDivElement>(null)
    const user = useContext(LoginUserContext)
    const replyMessageValue = useSelector(selectReplyMessageValue)
    const showMessageThread = useSelector(selectShowMessageThread)
    const viewedMessageThread = useSelector(selectViewedMessageThread)
    const replyToMessageThreadStatus = useSelector(selectReplyToThread)
    const messages = useSelector(selectMessageThreadsMessages)

    const handleReplyMessageValueChange = (value: string) => {
        dispatch(setReplyMessageValue(value))
    }

    const closeMessageThread = () => {
        dispatch(setShowMessageThread(!showMessageThread))

        // reset threads after transition animation
        setTimeout(() => {
            dispatch(resetThreadMessages())
            dispatch(resetViewedMessageThread())
        }, TRANSITION_DURATION)
    }

    const handleReplyToThread = async () => {
        await dispatch(replyToMessageThread())
        scrollToBottom()
    }

    const scrollToBottom = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    useEffect(() => {
        if (showMessageThread) {
            setTimeout(() => {
                if (showMessageThread) {
                    dispatch(updateMessageThreadIsReadStatusById({ ThreadId: viewedMessageThread?.Id, IsRead: true }))
                }
            }, 1000) // 1 second

            // scroll to bottom after animation
            setTimeout(() => {
                scrollToBottom()
            }, TRANSITION_DURATION)
        }
    }, [viewedMessageThread, showMessageThread, dispatch])

    const showReplyControls =
        viewedMessageThread?.Type === EMessageType.MobileMessage && !messages.isFetching && showMessageThread

    return (
        <>
            <CloseMessageThreadPanel onClick={closeMessageThread}>
                <Icon color={colors.primaryTeal120} size={18}>
                    arrow_back_ios
                </Icon>
            </CloseMessageThreadPanel>

            <ThreadContent>
                <MessagesContainer>
                    {messages.isFetching || (replyToMessageThreadStatus.isFetching && <CenteredLoadingIndicator />)}

                    {messages.error && <>{messages.error}</>}
                    {messages.data.map((message) => (
                        <Message
                            key={message.CreatedTimestamp}
                            message={message}
                            isOwn={message.CreatedByLogin?.Id === user?.Id}
                        />
                    ))}
                    <div ref={bottomRef} />
                </MessagesContainer>

                {showReplyControls && (
                    <>
                        <ReplyContainer>
                            <TextInputV2
                                value={replyMessageValue}
                                onChange={handleReplyMessageValueChange}
                                placeholder="messaging.writeAnswer"
                                overrideStyle={{ width: '488px' }}
                                disabled={replyToMessageThreadStatus.isFetching}
                                maxRows={15}
                                multiline
                                autoFocus
                            />
                        </ReplyContainer>

                        <ButtonContainer>
                            <TranslatedButton onClick={closeMessageThread} variant="text" color="primary_120">
                                messaging.formClose
                            </TranslatedButton>

                            <TranslatedButton
                                onClick={handleReplyToThread}
                                disabled={replyToMessageThreadStatus.isFetching || !replyMessageValue.trim()}
                            >
                                messaging.formSend
                            </TranslatedButton>
                        </ButtonContainer>
                    </>
                )}
            </ThreadContent>
        </>
    )
}
