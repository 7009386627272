import { MomentInput } from 'moment'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import Tooltip from '../../generic-components/Tooltip'
import styled from '@emotion/styled'
import {
    asFullDate,
    asFullDateAndDayAbbreviation,
    asFullDateTimeWithDayAbbreviation,
} from '../../dates/Utilities/DateUtilities'

type Props = {
    children: MomentInput | null
    displayTimeInTooltip?: boolean
    showTooltip?: boolean
    dateTypographyProps?: TypographyProps
}

const Text = styled(Typography)`
    font-family: Open Sans;
`

const ListElementDate = ({
    children,
    displayTimeInTooltip = false,
    showTooltip = false,
    dateTypographyProps,
}: Props): JSX.Element | null => {
    if (!children) {
        return null
    }

    const fullDate = asFullDate(children)
    const renderedDate = (
        <Text display="inline" noWrap variant="listElement" {...dateTypographyProps}>
            {fullDate}
        </Text>
    )

    if (!showTooltip) {
        return renderedDate
    }

    const tooltipContent = displayTimeInTooltip
        ? asFullDateTimeWithDayAbbreviation(children)
        : asFullDateAndDayAbbreviation(children)

    return <Tooltip title={tooltipContent}>{renderedDate}</Tooltip>
}

export default ListElementDate
