import { IDataSourceItem } from '../../../data-source-types'
import IFormViewFieldConfigurationV2 from '../../Types/V2/IFormViewFieldConfigurationV2'
import { get } from 'lodash-es'
import { chain } from 'lodash'

const nonEmptyOrNull = () => null

export const getValueForField = (
    items: IDataSourceItem[],
    propertyName: string | null,
    field: IFormViewFieldConfigurationV2,
    initialFormValuesOverride: Record<string, unknown>
): unknown => {
    if (propertyName === null || propertyName === '') {
        return nonEmptyOrNull()
    }

    const initialValueOverride = get(initialFormValuesOverride, propertyName || '')

    if (initialValueOverride) {
        if (Array.isArray(initialValueOverride)) {
            const distictValues = chain(initialValueOverride)
                .map((x) => x)
                .uniq()
                .value()

            if (field.IsReducedToSingleValue) {
                return distictValues.length === 1 ? distictValues[0] : null
            }

            return distictValues
        }

        return initialValueOverride
    }

    if (items.length === 0) {
        return null
    }

    if (!field.IsReducedToSingleValue) {
        return propertyName
            ? chain(items)
                  .map((item) => get(item, propertyName))
                  .uniq()
                  .value()
            : []
    }

    let value: unknown
    if (propertyName) {
        const distinctValues = chain(items)
            .map((item) => get(item, propertyName))
            .uniq()
            .value()
        value = distinctValues.length === 1 ? distinctValues[0] : null
    } else {
        value = null
    }

    // Set value pickers' initial empty values to null
    return typeof value !== 'undefined' ? value : null
}
