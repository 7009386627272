import * as React from 'react'
import styled from '@emotion/styled'

import TranslatedTypography from './TranslatedTypography'
import Styles from 'constants/Styles'

const ErrorText = styled(TranslatedTypography)`
    color: ${Styles.supplementaryColor.red};
    display: inline;
`

export interface IErrorMessageProps {
    children: string
}

const ErrorMessage: React.FC<IErrorMessageProps> = ({ children }) => <ErrorText>{children}</ErrorText>

export default ErrorMessage
