import { useEffect, useRef } from 'react'

type TCallback = () => void

/**
 * Hook to use JavaScript's setInterval function in a component.
 */
const useInterval = (callback: TCallback, delay: number, shouldClearInterval?: boolean): void => {
    const savedCallbackRef = useRef<TCallback | null>(null)
    const intervalIdRef = useRef<number | null>(null)

    useEffect(() => {
        if (!shouldClearInterval) {
            savedCallbackRef.current = callback
        }
    }, [callback, shouldClearInterval])

    useEffect(() => {
        if (shouldClearInterval && intervalIdRef.current) {
            window.clearInterval(intervalIdRef.current)
            intervalIdRef.current = null
            savedCallbackRef.current = null
        }
    }, [shouldClearInterval])

    useEffect(() => {
        if (shouldClearInterval) {
            return
        }

        const tick = () => {
            if (!savedCallbackRef.current) {
                return
            }
            savedCallbackRef.current()
        }

        intervalIdRef.current = window.setInterval(tick, delay)

        return () => {
            if (intervalIdRef.current) {
                window.clearInterval(intervalIdRef.current)
            }
        }
    }, [delay, shouldClearInterval])
}

export default useInterval
