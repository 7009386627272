import { ComponentProps, ReactElement } from 'react'
import { TAllValues, TNewlySelected, TSelectedValues, TValueFieldType } from '../../Interfaces'
import ValueFieldDefault from './ValueFieldDefault'
import ValueFieldChip from './ValueFieldChip'
import ValueFieldForm from './ValueFieldForm'

type TValueFieldChipProps = ComponentProps<typeof ValueFieldChip>

interface IValueFieldProps<T> extends Pick<TValueFieldChipProps, 'spaceReservedForChipSelectionCount'> {
    defaultText: string
    disabled: boolean
    isLoading: boolean
    label?: string
    itemIdField: keyof T
    isRequired?: boolean
    itemValueLabelFields: (keyof T)[]
    items: T[]
    onClick: (e: React.MouseEvent) => void
    selectedNewItems: TNewlySelected<T>
    selectedValueItems: TSelectedValues<T>
    textForMultipleSelected: string
    translate: boolean
    values: TAllValues<T>
    valueTextOverride?: string
    typeName: undefined | string
    multiselect: boolean
    valueItems: ReadonlyMap<T[keyof T], T>
    valueFieldType: TValueFieldType | undefined
    errors?: string
    isReadOnly?: boolean
    valuePickerId?: string
}

function ValueField<T>({
    spaceReservedForChipSelectionCount,
    valueFieldType = 'default',
    ...props
}: IValueFieldProps<T>): ReactElement | null {
    switch (valueFieldType) {
        case 'form': {
            return <ValueFieldForm<T> {...props} />
        }
        case 'default': {
            return <ValueFieldDefault<T> {...props} />
        }
        case 'chip': {
            const { disabled, defaultText, onClick, values } = props
            return (
                <ValueFieldChip
                    disabled={disabled}
                    itemCount={values.size}
                    label={defaultText}
                    onClick={onClick}
                    spaceReservedForChipSelectionCount={spaceReservedForChipSelectionCount}
                />
            )
        }
        default:
            return null
    }
}

export default ValueField
