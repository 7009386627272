import { isEqual } from 'lodash-es'

import AbstractTitle, { IAbstractTitleProps } from 'components/atoms/AbstractTitle'
import Styles from 'constants/Styles'

/**
 * 2. tason otsikko.
 *
 * Käytä sivun sisällä, esimerkiksi listauksien tai osioiden otsikkona.
 */
export default class Heading extends AbstractTitle<IAbstractTitleProps> {
    static readonly displayName = 'Heading'

    constructor(props: IAbstractTitleProps) {
        super(props)
    }

    shouldComponentUpdate(nextProps: IAbstractTitleProps): boolean {
        return !isEqual(nextProps, this.props)
    }

    protected styles(): any {
        const classes = super.styles()
        classes.heading.fontSize = Styles.font.levelTwoTitle.size
        classes.icon.fontSize = Styles.font.levelTwoTitle.size
        classes.subHeading.fontSize = Styles.font.levelTwoTitle.size
        classes.heading.fontWeight = Styles.font.levelTwoTitle.fontWeight
        return classes
    }
}
