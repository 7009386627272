import { TGroupsToRegisterForSyncedScrolling } from '../../generic-utilities'

/*
 * This file doesn't quite contain only pure constants, but still seems to be the
 * most logical place for it.
 */

const CALENDAR_HORIZONTAL_CONTENT_SCROLL_GROUP = {
    groupId: 'CalendarHorizontalContent',
    directions: ['horizontal' as const],
}

/**
 * Note: Calendar vertical content isn't meant to be synced with possible other
 * calendars, so don't use this without editing the groupId to have the calendarId.
 */
const CALENDAR_VERTICAL_CONTENT_SCROLL_GROUP_BASE = {
    groupId: 'CalendarVerticalContent-',
    directions: ['vertical' as const],
}

export const CALENDAR_HORIZONTAL_SCROLL_GROUPS: TGroupsToRegisterForSyncedScrolling = [
    CALENDAR_HORIZONTAL_CONTENT_SCROLL_GROUP,
]

const cachedVerticalScrollGroups = new Map<string, TGroupsToRegisterForSyncedScrolling>()

/**
 * Returns the vertical synced scroll group. This is calendar specific, so it can't be saved directly
 * in a constant but is instead created per calendar.
 * The group is cached so that we don't create a new one every time. This way we prevent unnecessary
 * re-renders.
 */
export const getCalendarVerticalContentScrollGroups = (calendarId: string): TGroupsToRegisterForSyncedScrolling => {
    const scrollGroupId = `CalendarVerticalContent-${calendarId}`

    if (!cachedVerticalScrollGroups.has(scrollGroupId)) {
        cachedVerticalScrollGroups.set(scrollGroupId, [
            {
                ...CALENDAR_VERTICAL_CONTENT_SCROLL_GROUP_BASE,
                groupId: scrollGroupId,
            },
        ])
    }

    return cachedVerticalScrollGroups.get(scrollGroupId) as TGroupsToRegisterForSyncedScrolling
}

const cachedScrollGroups = new Map<string, TGroupsToRegisterForSyncedScrolling>()

/**
 * Returns all the scroll groups for the calendar, i.e. one for the vertical - unique per calendar -
 * and one for the horizontal (synced between multiple calendars).
 * Caches the scroll groups so that we don't cause unnecessary re-renders.
 */
export const getAllCalendarScrollGroups = (calendarId: string): TGroupsToRegisterForSyncedScrolling => {
    if (!cachedScrollGroups.has(calendarId)) {
        cachedScrollGroups.set(calendarId, [
            CALENDAR_HORIZONTAL_CONTENT_SCROLL_GROUP,
            ...getCalendarVerticalContentScrollGroups(calendarId),
        ])
    }

    return cachedScrollGroups.get(calendarId) as TGroupsToRegisterForSyncedScrolling
}
