import { FormikValues } from 'formik'

import { IDataSourceItem } from '../../../data-source-types'
import { IDataSourceConfiguration } from '../../../data-source'
import IFormViewFieldModel from '../../Types/IFormViewFieldModel'
import { IFormViewComponentRegistry } from '../../FormViewComponentRegistry'
import { getValueForField } from './getValueForField'
import { getInputPropertyName } from './InternalUtils'
import { TUniqueValuesCountForField, TUniqueValuesCountForFields } from '../../../form-view-types'
import { getLogger } from '../../../log'
import { get, set } from 'lodash-es'

const Log = getLogger('form-view.compileInitialValues')

const getValueForNonIgnoredField = (
    ignoreInitialValueFields: string[],
    field: IFormViewFieldModel,
    items: IDataSourceItem[],
    propertyName: string | null,
    initialFormValuesOverride: Record<string, unknown>
) => {
    return ignoreInitialValueFields.includes(field.Id)
        ? null
        : getValueForField(items, propertyName, field, initialFormValuesOverride)
}

const getFormattedComponentInitialValue = (
    field: IFormViewFieldModel,
    value: unknown,
    uniqueValuesForField: TUniqueValuesCountForField,
    getFieldComponent: IFormViewComponentRegistry['getFieldComponent']
) => {
    if (field.InputComponentId === null) {
        return value
    }

    const Component = getFieldComponent(field.InputComponentId)

    if (!Component || !Component.formatInitialValue) {
        return value
    }

    return Component.formatInitialValue(
        value,
        /* There's a TS error somewhere in the line but it's hard to track. Bypassing it here by
        using any shouldn't affect anywhere else though so we'll just do that. */
        uniqueValuesForField as any
    )
}

export const compileInitialValues = (
    items: IDataSourceItem[],
    fields: IFormViewFieldModel[],
    dataSource: IDataSourceConfiguration,
    uniqueValuesCountForFields: TUniqueValuesCountForFields,
    getFieldComponent: IFormViewComponentRegistry['getFieldComponent'],
    ignoreInitialValueFields: string[],
    initialFormValuesOverride: Record<string, unknown>
): FormikValues => {
    const initialValues: FormikValues = {}

    for (const field of fields) {
        const initialValueOverride = get(initialFormValuesOverride, field.Id)

        if (initialValueOverride) {
            set(initialValues, field.Id, initialValueOverride)
            continue
        }

        const mapping = field.DataSourcePropertyMapping

        if (mapping.length === 1 && mapping[0].ComponentParameterName === null) {
            // Give the data source property value as a scalar to the input component.
            const { DataSourcePropertyId } = mapping[0]

            let propertyName: string | null
            try {
                propertyName = getInputPropertyName(field.Id, DataSourcePropertyId, dataSource)
            } catch (error) {
                Log.warn(error.message)
                continue
            }

            const itemValue = getValueForNonIgnoredField(
                ignoreInitialValueFields,
                field,
                items,
                propertyName,
                initialFormValuesOverride
            )
            const uniqueValuesForField = uniqueValuesCountForFields[field.Id]

            const value = getFormattedComponentInitialValue(field, itemValue, uniqueValuesForField, getFieldComponent)

            set(initialValues, field.Id, value)
        } else {
            // Give the data source property value(s) as an object to the input component.
            // e.g. for time range.
            const values = {}
            for (const { ComponentParameterName, DataSourcePropertyId } of mapping) {
                if (!ComponentParameterName) {
                    Log.warn(
                        `Form field '${field.Id}': ComponentParameterName should be non-null when` +
                            ` there are multiple data source properties mapped to a form view input.`
                    )
                    continue
                }

                let propertyName: string | null
                try {
                    propertyName = getInputPropertyName(field.Id, DataSourcePropertyId, dataSource)
                } catch (error) {
                    Log.warn(error.message)
                    continue
                }

                set(
                    values,
                    ComponentParameterName,
                    getValueForNonIgnoredField(
                        ignoreInitialValueFields,
                        field,
                        items,
                        propertyName,
                        initialFormValuesOverride
                    )
                )
            }

            const uniqueValuesForField = uniqueValuesCountForFields[field.Id]

            set(
                initialValues,
                field.Id,
                getFormattedComponentInitialValue(field, values, uniqueValuesForField, getFieldComponent)
            )
        }
    }

    return initialValues
}
