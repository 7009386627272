import * as React from 'react'

import renderDataAsVerticalSummary from './renderFunctions/renderDataAsVerticalSummary'
import { TDataSourceId } from '../Types/IDataSource'
import AggregatedData from './AggregatedData'

interface IOwnProps {
    dataSourceId: TDataSourceId | null
}

export interface IAggregatedWindowFunctionDataRowProps extends IOwnProps {}

/**
 * Renders dataSource's aggregated data horizontally.
 */
export const AggregatedDataSummary: React.FunctionComponent<IAggregatedWindowFunctionDataRowProps> = ({
    dataSourceId,
}) => {
    return <AggregatedData dataSourceId={dataSourceId} renderData={renderDataAsVerticalSummary} />
}

export default AggregatedDataSummary
