import * as React from 'react'

import IMainAxisHeader, { IMainAxisHeaderNode } from '../../../../Types/IMainAxisHeader'
import { MIN_COLUMN_WIDTH } from '../../../../Constants/CalendarConstants'
import NodesContainer from './HeaderNodesContainer'
import NodesBeforeInitialNodes from './NodesBeforeInitialNodes'
import NodesAfterInitialNodes from './NodesAfterInitialNodes'

interface IHeaderNodesProps {
    nodes: IMainAxisHeader['nodes']
    component: IMainAxisHeader['component']
    width: IMainAxisHeader['width']
}

const HeaderNode: React.FC<{ last: boolean; widthPersentage: number }> = (props) => {
    return (
        <div
            style={{
                display: 'flex',
                boxSizing: 'border-box',
                minWidth: MIN_COLUMN_WIDTH.combined,
                width: `calc(${props.widthPersentage}%)`,
            }}
        >
            {props.children}
        </div>
    )
}

const HeaderNodes: React.FC<IHeaderNodesProps> = ({ width, nodes, component: HeaderNodeComponent }) => {
    const { beforeInitialNodes, initialNodes, afterInitialNodes } = nodes
    const totalHeaderNodesCount = beforeInitialNodes.length + initialNodes.length + afterInitialNodes.length

    const renderHeaderNode = ({ value, weekNumber, publicHoliday }: IMainAxisHeaderNode, index: number) => (
        <HeaderNode key={value} widthPersentage={width} last={index === totalHeaderNodesCount - 1}>
            <HeaderNodeComponent value={value} weekNumber={weekNumber} publicHoliday={publicHoliday} />
        </HeaderNode>
    )

    return (
        <React.Fragment>
            <NodesBeforeInitialNodes totalHeaderNodesCount={totalHeaderNodesCount}>
                {beforeInitialNodes.map(renderHeaderNode)}
            </NodesBeforeInitialNodes>
            <NodesContainer
                childHeadersCount={initialNodes.length}
                hasContent
                totalHeaderNodesCount={totalHeaderNodesCount}
            >
                {initialNodes.map(renderHeaderNode)}
            </NodesContainer>
            <NodesAfterInitialNodes totalHeaderNodesCount={totalHeaderNodesCount}>
                {afterInitialNodes.map(renderHeaderNode)}
            </NodesAfterInitialNodes>
        </React.Fragment>
    )
}

export default HeaderNodes
