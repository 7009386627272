const sectionMap = {
    työvuorosuunnittelu: 'wsp',
    vuorot: 'shifts',
    tvs: 'wsp2',
    työntekijät: 'employees',
    työkohteet: 'work_units',
    asiakkaat: 'customers',
    poolit: 'pools',
    tapahtumat: 'events',
    toteumat: 'transactions',
    palkkajalaskutuskaudet: 'payroll_and_invoicing',
    ylläpito: 'administration',
    messages: 'communication',
}

export const getNavigationSectionId = (name: string): string => {
    const sectionName: string = name.replace(/[-\s]/g, '').toLocaleLowerCase()
    const sectionId: string = sectionMap[sectionName as keyof typeof sectionMap]

    return `SIDE_NAVI_${sectionId}`.toUpperCase()
}
