import * as React from 'react'
import { ReadOnlyText, SystemInternalLink } from '../../generic-components'
import styled from '@emotion/styled'

export interface IWorkunitLinkLegacy {
    name: string
    id: number
    domain: string
}

const StyledLink = styled(SystemInternalLink)`
    overflow: hidden;
`

const WorkunitLinkLegacy: React.FunctionComponent<IWorkunitLinkLegacy> = ({ name, id, domain }) => {
    const valueLinkWithValue = 'https://' + domain + '/BSP/customerInfo.aspx?CustomerID=' + id

    return (
        <StyledLink
            openInNewTab={true}
            to={valueLinkWithValue}
            windowParams="toolbar=yes,scrollbars=yes,resizable=yes,left=300,width=1100,height=900"
        >
            <ReadOnlyText color="dark_100" usage="legacy_list_element" textOverflow="ellipsis" tooltipEnabled>
                {name}
            </ReadOnlyText>
        </StyledLink>
    )
}

export default WorkunitLinkLegacy
