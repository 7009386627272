import { createReducer } from 'typesafe-actions'

import { initializeApplicationAction } from 'action-creators'
import ILoginKayttaja from 'interfaces/ILoginKayttaja'

const initialState = null

const userReducer = createReducer<ILoginKayttaja | null>(initialState).handleAction(
    [initializeApplicationAction],
    (state, action) => {
        return action.payload.sisaanKirjautunutKayttaja
    }
)

export default userReducer
