import * as React from 'react'
import styled from '@emotion/styled'

interface IRequirableFieldProps {
    children: React.ReactNode
    required?: boolean
    className?: string
}

const Container = styled.div`
    display: flex;
    width: 100%;
`

const RequiredSpaceContainer = styled.div`
    width: 10px;
    text-align: right;
`

const ChildContainer = styled.div`
    width: 100%;
`

/**
 * Component to show that the field is required if needed.
 * Shows this by displaying a star on the right side of the
 * child component.
 * If the field is not required, reserves empty space in the
 * place of the star sign.
 */
const RequirableField: React.FC<IRequirableFieldProps> = ({ children, required = false, className }) => {
    return (
        <Container className={className}>
            <ChildContainer>{children}</ChildContainer>
            <RequiredSpaceContainer>{required && '*'}</RequiredSpaceContainer>
        </Container>
    )
}

RequirableField.defaultProps = {
    required: false,
}

export default RequirableField
