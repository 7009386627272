import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import styled from '@emotion/styled'

import INavigationSectionWithSubsections from '../../../Types/INavigationSectionWithSubsections'
import Section from './Section'
import INavigationSubsection from '../../../Types/INavigationSubsection'
import {
    selectSectionsWithSubsections,
    selectCurrentSectionWithSubsections,
    selectCurrentSubsection,
} from '../../../State/NavigationSelectors'

const Container = styled.div`
    min-width: 230px;
`

interface IStateProps {
    items: INavigationSectionWithSubsections[]

    currentPageSection: INavigationSectionWithSubsections | null
    currentPageSubsection: INavigationSubsection | null
}

interface IOwnProps {
    closeDrawerCallback?: () => void
}

interface ITabsInDrawerProps extends IStateProps, IOwnProps {}

const TabsInDrawer: React.FC<ITabsInDrawerProps> = ({
    closeDrawerCallback,
    items,
    currentPageSection,
    currentPageSubsection,
}) => {
    return (
        <Container>
            {items.map((item) => (
                <Section
                    closeDrawerCallback={closeDrawerCallback}
                    currentPageSection={currentPageSection}
                    currentPageSubsection={currentPageSubsection}
                    item={item}
                    key={item.Id}
                />
            ))}
        </Container>
    )
}

const mapStateToProps = (state: RootState): IStateProps => {
    return {
        items: selectSectionsWithSubsections(state),
        currentPageSection: selectCurrentSectionWithSubsections(state),
        currentPageSubsection: selectCurrentSubsection(state),
    }
}

export default connect(mapStateToProps)(TabsInDrawer)
