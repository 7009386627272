import * as React from 'react'
import Grid from '@material-ui/core/Grid'

import { Button } from '../../../generic-components'
import { Tooltip } from '../../../generic-components'
import { getTestId } from '../../../test'

export interface IListBulkActionButtonProps {
    disabled: boolean
    formId: string
    label: string
    onClick: (formId: string) => void
    tooltip?: string
    testId?: string
}

export class ListBulkActionButton extends React.Component<IListBulkActionButtonProps> {
    private handleActionButtonClick = () => {
        const { formId } = this.props

        this.props.onClick(formId)
    }

    render(): React.ReactNode {
        const { disabled, label, tooltip, testId } = this.props

        return (
            <Grid item>
                <Tooltip title={tooltip}>
                    <Button
                        disabled={disabled}
                        onClick={this.handleActionButtonClick}
                        testId={getTestId(['LIST_BULK_ACTION', 'BTN'], testId)}
                    >
                        {label}
                    </Button>
                </Tooltip>
            </Grid>
        )
    }
}

export default ListBulkActionButton
