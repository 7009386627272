import { FC } from 'react'
import Icon from './Icon'

interface IPoolTypeIconProps {
    isPublic: boolean
}

const PoolTypeIcon: FC<IPoolTypeIconProps> = ({ isPublic }) => {
    const tooltip = isPublic ? 'Yleinen pooli' : 'Työkohteen oma pooli'
    const icon = isPublic ? 'group' : 'supervised_user_circle'

    return (
        <Icon tooltip={tooltip} size={22}>
            {icon}
        </Icon>
    )
}

export default PoolTypeIcon
