import * as React from 'react'
import Accordion, { AccordionProps } from '@material-ui/core/Accordion'
import AccordionSummary, { AccordionSummaryClassKey, AccordionSummaryProps } from '@material-ui/core/AccordionSummary'
import AccordionDetails, { AccordionDetailsClassKey, AccordionDetailsProps } from '@material-ui/core/AccordionDetails'
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles'
import styled from '@emotion/styled'

import Icon from '../Icon'
import { TTranslatable } from '../../localization'
import { TranslatedH2 } from '../Titles/H2'

export interface IExpandableProps {
    children: React.ReactNode
    expansionPanelDetailsStyles?: StyleRules<AccordionDetailsClassKey>
    expansionPanelSummaryStyles?: StyleRules<AccordionSummaryClassKey>
    label: TTranslatable | JSX.Element
    muiExpansionPanelDetailsProps?: Partial<AccordionDetailsProps>
    muiExpansionPanelProps?: Partial<AccordionProps>
    muiExpansionPanelSummaryProps?: Partial<AccordionSummaryProps>
    defaultExpanded?: boolean
    className?: string
    classNameLabelContainer?: string
}

const StyledExpansionPanel = styled(Accordion)`
    box-shadow: none;
`

const labelIsElement = (label: IExpandableProps['label']): label is JSX.Element => React.isValidElement(label)

const StyledIcon = styled(Icon)`
    cursor: pointer;
`

const expandIcon = (
    <StyledIcon colorTheme="primary" size={24}>
        expand_more
    </StyledIcon>
)

const defaultCustomStyles: StyleRules = {}

const useExpansionPanelSummaryStyles = makeStyles<Theme, StyleRules<AccordionSummaryClassKey>>({
    root: (customStyles) => ({ ...customStyles.root }),
    content: (customStyles) => ({ ...customStyles.content }),
    expanded: (customStyles) => ({ ...customStyles.expanded }),
})

const useExpansionPanelDetailsStyles = makeStyles<Theme, StyleRules<AccordionDetailsClassKey>>({
    root: (customStyles) => ({ ...customStyles.root }),
})

const StyledExpansionSummary = styled(AccordionSummary)`
    width: 100%;
    padding: 0 16px;
`

const StyledExpandableContent = styled(AccordionDetails)`
    display: flex;
`

const Expandable: React.FunctionComponent<IExpandableProps> = ({
    children,
    expansionPanelDetailsStyles = defaultCustomStyles,
    expansionPanelSummaryStyles = defaultCustomStyles,
    defaultExpanded = false,
    label,
    muiExpansionPanelDetailsProps,
    muiExpansionPanelProps,
    muiExpansionPanelSummaryProps,
    className,
    classNameLabelContainer,
}) => {
    const summaryClasses = useExpansionPanelSummaryStyles(expansionPanelSummaryStyles)
    const detailsClasses = useExpansionPanelDetailsStyles(expansionPanelDetailsStyles)

    return (
        <StyledExpansionPanel className={className} defaultExpanded={defaultExpanded} {...muiExpansionPanelProps}>
            <StyledExpansionSummary expandIcon={expandIcon} {...muiExpansionPanelSummaryProps} classes={summaryClasses}>
                <div className={classNameLabelContainer}>
                    {labelIsElement(label) ? label : <TranslatedH2 label={label} />}
                </div>
            </StyledExpansionSummary>

            <StyledExpandableContent {...muiExpansionPanelDetailsProps} classes={detailsClasses}>
                {children}
            </StyledExpandableContent>
        </StyledExpansionPanel>
    )
}

export default Expandable
