import moment from 'moment'

export const setItemToLocalStorage = (key: string, value: string): void => {
    localStorage.setItem(key, value)
}

export const setJSONItemToLocalStorage = (key: string, value: unknown): void => {
    setItemToLocalStorage(key, JSON.stringify(value))
}

export const getItemFromLocalStorage = (key: string): string | null => {
    return localStorage.getItem(key)
}

export const getJSONItemFromLocalStorage = <T>(key: string): T | null => {
    const item = getItemFromLocalStorage(key)

    if (!item) {
        return null
    }

    try {
        return JSON.parse(item)
    } catch (e) {
        return null
    }
}

export const getCookieSettings = (): { date: string; categories: Record<'functional', boolean> } | null => {
    const cookieSettings = getJSONItemFromLocalStorage<{ date: string; categories: Record<'functional', boolean> }>(
        'cookieSettings'
    )

    if (cookieSettings) {
        const cookieSettingsExpired = moment().diff(moment(cookieSettings.date), 'years') >= 1

        if (cookieSettingsExpired) {
            localStorage.removeItem('cookieSettings')

            return null
        }
    }

    return cookieSettings
}
