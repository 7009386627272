import { createReducer } from 'typesafe-actions'

import IStoredOptionsReducerState from '../Types/IStoredOptionsReducerState'
import IStoredOptionsComponentWithState from '../Types/IStoredOptionsComponentWithState'
import {
    storedOptionsInitializeAction,
    storedOptionsDataFetchFailedAction,
    storedOptionsIsFetchingDataAction,
    storedOptionsDataFetchedAction,
    storedOptionsResetOptionsForComponentsAction,
    storedOptionsFinishedLoadingForComponentAction,
    storedOptionsSetDataAction,
} from './StoredOptionsActions'
export const INITIAL_STATE: IStoredOptionsReducerState = {}

const INITIAL_COMPONENT_STATE: IStoredOptionsComponentWithState = {
    fetching: false,
    fetchFailed: false,
    fetchSucceeded: true,
    options: [],
}

const storedOptionsReducer = createReducer(INITIAL_STATE)
    .handleAction(storedOptionsInitializeAction, (state, action) => {
        const storedOptionsComponentId = action.payload

        // Use existing data if present with the same key.
        return { [storedOptionsComponentId]: INITIAL_COMPONENT_STATE, ...state }
    })
    .handleAction(storedOptionsFinishedLoadingForComponentAction, (state, action) => {
        const storedOptionsComponentId = action.payload

        const storedOptionsComponentState = state[storedOptionsComponentId] || INITIAL_COMPONENT_STATE

        const newStoredOptionsComponentState: IStoredOptionsComponentWithState = {
            ...storedOptionsComponentState,
            fetching: false,
            fetchFailed: false,
            fetchSucceeded: true,
        }

        return { ...state, [storedOptionsComponentId]: newStoredOptionsComponentState }
    })
    .handleAction(storedOptionsDataFetchedAction, (state, action) => {
        const options = action.payload
        const storedOptionsComponentId = action.meta

        const storedOptionsComponentState = state[storedOptionsComponentId] || INITIAL_COMPONENT_STATE

        const newStoredOptionsComponentState: IStoredOptionsComponentWithState = {
            ...storedOptionsComponentState,
            fetching: false,
            fetchFailed: false,
            fetchSucceeded: true,
            options,
        }

        return { ...state, [storedOptionsComponentId]: newStoredOptionsComponentState }
    })
    .handleAction(storedOptionsSetDataAction, (state, action) => {
        const options = action.payload
        const storedOptionsComponentId = action.meta

        const storedOptionsComponentState = state[storedOptionsComponentId] || INITIAL_COMPONENT_STATE

        const newStoredOptionsComponentState: IStoredOptionsComponentWithState = {
            ...storedOptionsComponentState,
            options,
        }

        return { ...state, [storedOptionsComponentId]: newStoredOptionsComponentState }
    })
    .handleAction(storedOptionsIsFetchingDataAction, (state, action) => {
        const storedOptionsComponentId = action.payload

        const storedOptionsComponentState = state[storedOptionsComponentId] || INITIAL_COMPONENT_STATE

        const newStoredOptionsComponentState: IStoredOptionsComponentWithState = {
            ...storedOptionsComponentState,
            fetching: true,
            fetchFailed: false,
            fetchSucceeded: false,
        }

        return {
            ...state,
            [storedOptionsComponentId]: newStoredOptionsComponentState,
        }
    })
    .handleAction(storedOptionsDataFetchFailedAction, (state, action) => {
        const storedOptionsComponentId = action.payload

        const storedOptionsComponentState = state[storedOptionsComponentId] || INITIAL_COMPONENT_STATE

        const newStoredOptionsComponentState: IStoredOptionsComponentWithState = {
            ...storedOptionsComponentState,
            fetching: false,
            fetchFailed: true,
            fetchSucceeded: false,
        }

        return {
            ...state,
            [storedOptionsComponentId]: newStoredOptionsComponentState,
        }
    })
    .handleAction(storedOptionsResetOptionsForComponentsAction, (state, action) => {
        const componentIds = action.payload

        const componentStateWithOptionsReset = componentIds.reduce(
            (storedOptionsComponentNewState, storedOptionsComponentId) => {
                const storedOptionsComponentState = state[storedOptionsComponentId] || INITIAL_COMPONENT_STATE

                const newStoredOptionsComponentState: IStoredOptionsComponentWithState = {
                    ...storedOptionsComponentState,
                    options: [],
                }

                return {
                    ...storedOptionsComponentNewState,
                    [storedOptionsComponentId]: newStoredOptionsComponentState,
                }
            },
            {} as IStoredOptionsReducerState
        )

        return {
            ...state,
            ...componentStateWithOptionsReset,
        }
    })

export default storedOptionsReducer
