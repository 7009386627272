import styled from '@emotion/styled'
import { loadingPlaceholder } from 'constants/Styles'

type Props = {
    width: number
    height: number
    autoWidth?: boolean
}

const LoadingPlaceholder = styled.div<Props>`
    ${({ width, height }) => loadingPlaceholder({ width, height })};
    width: ${({ width, autoWidth }) => (!autoWidth ? `${width}px` : 'auto')};
    height: ${({ height }) => height}px;
`

export default LoadingPlaceholder
