import * as React from 'react'
import styled from '@emotion/styled'

import Styles from 'constants/Styles'

interface IHrProps {
    width?: string
    withMargin?: boolean
}

const HorizontalRow = styled.div`
    border: 1px solid ${Styles.supplementaryColor.concreteGrey};
    opacity: 0.5;
    width: ${({ width }: IHrProps) => width || '100%'};
    margin: ${({ withMargin }) => (withMargin ? '40px 0 20px 0' : '0')};
`

/**
 * Piirtää leveyssuuntaisen viivan.
 */
const Hr: React.FC<IHrProps> = ({ width = '100%', withMargin = false }) => (
    <HorizontalRow width={width} withMargin={withMargin} />
)

export default Hr
