// eslint-disable-next-line no-shadow
enum EActionFunctionalityType {
    Confirmation = 'ConfirmationAction',
    Immediate = 'ImmediateAction',
    ConfigurableModal = 'ConfigurableModalAction',
    CustomModal = 'CustomModalAction',
    ListModal = 'ListModalAction',
    Download = 'DownloadAction',
}

export default EActionFunctionalityType
