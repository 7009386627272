import { FieldProps } from 'formik'
import * as React from 'react'

import TranslatedTextInput, { TTranslatedTextInputProps } from './TranslatedTextInput'

interface IFormikTextInputOwnProps extends FieldProps {
    /**
     * Without this basically just shows that the field as errored, by displaying
     * the label with red color. Otherwise shows the error text below the text field.
     */
    displayErrorAsHelperText?: boolean
}

// we can pass value to `FormikTextInput` within the `field` prop so value of `TextInput` needs
// to be optional here
type TTranslatedTextInputPropsWithOptionalValues = Omit<TTranslatedTextInputProps, 'value'> & {
    value?: TTranslatedTextInputProps['value']
}

export type TFormikTextInputProps = IFormikTextInputOwnProps & TTranslatedTextInputPropsWithOptionalValues

/**
 * @deprecated
 *
 * Use `FormikTextInput` located in `generic-components` instead.
 */
const FormikTextInput: React.FunctionComponent<TFormikTextInputProps> = ({
    meta,
    field,
    form,
    displayErrorAsHelperText = true,
    testId,
    ...passProps
}) => {
    const isTouched = meta.touched
    const error = meta.error

    const isError = Boolean(isTouched && error)
    const helperText = displayErrorAsHelperText ? (isTouched === false ? undefined : isTouched) && error : undefined

    return <TranslatedTextInput testId={testId} {...field} error={isError} helperText={helperText} {...passProps} />
}

export default FormikTextInput
