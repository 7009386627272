import { ReactComponent as BorderDashed } from './border-dashed.svg'
import { ReactComponent as BorderDashedLarge } from './border-dashed-large.svg'
import { ReactComponent as BorderSolid } from './border-solid.svg'
import { ReactComponent as BorderSolidLarge } from './border-solid-large.svg'

const icons = { BorderDashed, BorderDashedLarge, BorderSolid, BorderSolidLarge }

export type SvgIconOptions = keyof typeof icons

export const isSvgIcon = (icon: string): icon is SvgIconOptions => {
    return icon in icons
}

export default icons
