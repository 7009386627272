import * as React from 'react'

import TapahtumaCalendarNode from './TapahtumaNode/TapahtumaCalendarNode'
import { IEvent } from '../../event'
import { useAppTheme } from '../../theme/'

export interface IMuuTapahtumaCalendarNodeProps {
    endDatetime: string
    published: boolean
    startDatetime: string
    tapahtumatyyppiShorthand: string
    tyoaikasaantorikkeet: string[] | null
    tyontekijaId?: number | null
    item: IEvent
    colorId: string
    isRecurring: boolean
}

const MuuTapahtumaCalendarNode = ({
    endDatetime,
    published,
    startDatetime,
    tapahtumatyyppiShorthand,
    tyoaikasaantorikkeet,
    tyontekijaId,
    item,
    isRecurring,
}: IMuuTapahtumaCalendarNodeProps): JSX.Element => {
    const { colors, eventTemplates } = useAppTheme()
    const filled = typeof tyontekijaId === 'number'

    const overridingBackgroundColor = filled ? colors.secondaryPink30 : '#fff'

    return (
        <TapahtumaCalendarNode
            backgroundColor={overridingBackgroundColor}
            borderColor={eventTemplates.primary.event1}
            endDatetime={endDatetime}
            hoverColor={colors.neutralsGrey11}
            isOnPlanningPeriod={item.IsOnPlanningPeriod}
            mainColor={eventTemplates.primary.event1}
            published={published}
            colorId={undefined}
            startDatetime={startDatetime}
            upperText={tapahtumatyyppiShorthand}
            warnings={tyoaikasaantorikkeet}
            isRecurring={isRecurring}
        />
    )
}

export default MuuTapahtumaCalendarNode
