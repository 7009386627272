import IListViewModel from '../interfaces/IListViewModel'
import makeRequest, { ERequestMethod } from '../../rest-api'
import { VIEW_ENGINE_URL } from '../../data-source'
import { IListViewColumnUserSettingsObject } from '../interfaces/IListViewUserSettingsForColumns'

interface IListViewModelFromApi
    extends Pick<
        IListViewModel,
        | 'Id'
        | 'DisplayName'
        | 'DataSourceId'
        | 'IsSelectable'
        | 'RowSelectable'
        | 'AllowColumnManagement'
        | 'AutoFetchData'
        | 'Columns'
        | 'FilterIds'
        | 'StaticActions'
        | 'BulkActions'
        | 'RowActions'
        | 'AdditionalDataFetchParameters'
        | 'FilterItselfDataRequestParameterName'
    > {}

export const getListViewMetaData = async (listId: string): Promise<IListViewModel> => {
    return await makeRequest<IListViewModelFromApi>({
        method: ERequestMethod.GET,
        url: `${VIEW_ENGINE_URL}/ListView?id=${listId}`,
    })
}

export const saveColumnUserSettingsToServer = async (
    listId: string,
    columnData: Partial<IListViewColumnUserSettingsObject>[]
): Promise<void> =>
    await makeRequest<void>({
        method: ERequestMethod.POST,
        url: `${VIEW_ENGINE_URL}/ListView/${listId}/UserSetting`,
        data: columnData,
    })

export const resetUserCustomSettingsForColumns = async (listId: string): Promise<void> =>
    await makeRequest<void>({
        method: ERequestMethod.DELETE,
        url: `${VIEW_ENGINE_URL}/ListView/${listId}/UserSetting`,
    })
