import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import { TooltipWithEllipsis } from '../../generic-components'
import moment, { Moment } from 'moment'
import styled from '@emotion/styled'

export interface IListElementDateTimeProps {
    displayValue: string | Moment
    className?: string
}

const formatDateTime = (value: string | Moment): string => {
    if (!value) {
        return ''
    }

    const momentValue = moment(value)

    return momentValue.format('DD.MM.YYYY HH:mm:ss')
}

const DisplayElementText = styled(Typography)`
    font-family: Open Sans;
    line-height: 20px;
`

const ListElementDateTime: React.FunctionComponent<IListElementDateTimeProps> = ({ className, displayValue }) => (
    <TooltipWithEllipsis title={displayValue}>
        {(ref) => (
            <DisplayElementText className={className} display="inline" noWrap ref={ref} variant="listElement">
                {formatDateTime(displayValue)}
            </DisplayElementText>
        )}
    </TooltipWithEllipsis>
)

export default ListElementDateTime
