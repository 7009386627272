import * as React from 'react'
import { MomentInput } from 'moment'

import ListElementText from './ListElementText'
import DateUtility from 'common/DateUtility'

export interface IListElementDateRangeProps {
    start: MomentInput
    end: MomentInput
}

/**
 * Display the given dates separated by a hyphen. Used as a generic list row element
 * in the configurable list.
 */
const ListElementDateRange: React.FunctionComponent<IListElementDateRangeProps> = ({ start, end }) => (
    <ListElementText displayValue={`${DateUtility.asDateRange(start, end)}`} />
)

export default ListElementDateRange
