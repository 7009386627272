import * as React from 'react'
import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'
import { dark_60, primary_120 } from 'constants/Styles'

interface ITextStyleProps {
    width?: string
    height?: string
}

export interface ITextInputInline {
    value: string | null
    disabled?: boolean
    onChange: (input: string | null) => void
    onBlur?: (event: FocusEvent) => void
    onClick?: (e: React.MouseEvent) => void
    placeholder?: string
    type?: 'number' | 'text' | 'time'
    overrideStyle?: ITextStyleProps
    errors?: string
    isRequiredField?: boolean
    isValid?: boolean
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

const TextInput: React.FC<ITextInputInline> = ({
    value,
    disabled,
    onChange,
    onClick,
    onBlur,
    placeholder,
    type,
    overrideStyle,
    errors,
    isValid,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        onChange(event.target.value)
    }

    const hasValue = Boolean(value)

    const _height = '40px'

    const _onClick = (e: React.MouseEvent) => {
        e.stopPropagation()

        onClick && onClick(e)
    }

    return (
        <Container>
            <TextField
                autoFocus
                overrideStyle={overrideStyle}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                multiline={false}
                rows={undefined}
                style={{ width: overrideStyle?.width ?? 80, height: _height }}
                onChange={handleChange}
                onClick={_onClick}
                onBlur={onBlur}
                type={type}
                variant="standard"
                InputProps={{
                    style: {
                        fontFamily: 'Inter, Arial',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '10px',
                        lineHeight: '12px',
                        height: _height,
                        color: isValid ? primary_120 : dark_60,
                    },
                    disableUnderline: true,
                }}
                error={!!errors}
                InputLabelProps={{
                    style: {
                        fontFamily: 'Inter, arial',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: hasValue ? '#000000' : '#8E8F91',
                    },
                }}
            />
        </Container>
    )
}

export default TextInput
