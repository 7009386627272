import * as React from 'react'
import styled from '@emotion/styled'
import Typography, { TypographyProps } from '@material-ui/core/Typography'

import INavigationSubsection from '../../../../Types/INavigationSubsection'
import Styles from 'constants/Styles'

interface IDrawerTabProps {
    closeDrawerCallback: undefined | (() => void)
    children: string
    to: string
    currentPageSubsection: INavigationSubsection | null
    subsection: INavigationSubsection | null
    indented: boolean
}

interface IStyledLinkProps {
    component: 'a'
    href: string
    subsectionIsTheCurrentPage: boolean
    indented: boolean
}

const StyledLink = styled<React.FC<TypographyProps & IStyledLinkProps>>(
    ({ subsectionIsTheCurrentPage, indented, ...props }) => <Typography {...props} />
)`
    display: flex;
    position: relative;
    text-decoration: none;
    z-index: 1;
    width: 100%;
    justify-content: flex-start;
    background: none;
    padding-left: 10px;
    line-height: 3rem;

    :hover {
        background-color: ${Styles.mainColor.lightGrey};
    }

    ${({ indented }) =>
        indented &&
        `
        padding-left: 30px;
    `}

    ${({ subsectionIsTheCurrentPage }) =>
        subsectionIsTheCurrentPage &&
        `
        background-color: ${Styles.supplementaryColor.lightCyan};
        :hover {
            background-color: ${Styles.supplementaryColor.lightCyan};
        }
    `}
`

const DrawerTab: React.FC<IDrawerTabProps> = ({
    to,
    children,
    closeDrawerCallback,
    subsection,
    currentPageSubsection,
    indented,
}) => {
    const subsectionIsTheCurrentPage = Boolean(subsection && subsection.Id === currentPageSubsection?.Id)
    const colorTheme = subsectionIsTheCurrentPage ? 'primary' : undefined

    return (
        <StyledLink
            color={colorTheme}
            component="a"
            href={to}
            indented={indented}
            onClick={closeDrawerCallback}
            subsectionIsTheCurrentPage={subsectionIsTheCurrentPage}
            variant="subtitle1"
        >
            {children}
        </StyledLink>
    )
}

export default DrawerTab
