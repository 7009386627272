import * as React from 'react'
import { FieldProps } from 'formik'
import { Moment } from 'moment'

import ErrorableField from '../../Form/ErrorableField'
import MultiDatepicker, { IMultiDatepickerProps } from './MultiDatepicker'

interface IFormikMultiDatepickerOwnProps extends FieldProps {}

type IFormikMultiDatepickerProps = IFormikMultiDatepickerOwnProps &
    Pick<IMultiDatepickerProps, 'singleDay' | 'disabled' | 'minDate'>

export default class FormikMultiDatepicker extends React.Component<IFormikMultiDatepickerProps> {
    static defaultProps = {
        disabled: false,
    }

    handleBlur = (): void => {
        const {
            field,
            form: { setFieldTouched, validateOnBlur },
        } = this.props

        const isTouched = true
        setFieldTouched(field.name, isTouched, validateOnBlur)
    }

    handleDaySelection = (selectedDays: Moment[]): void => {
        const {
            form: { setFieldValue },
            field: { name },
        } = this.props

        setFieldValue(name, selectedDays)
    }

    render(): React.ReactNode {
        const { field, form, singleDay, disabled, minDate } = this.props

        return (
            <ErrorableField field={field} form={form}>
                <MultiDatepicker
                    disabled={disabled}
                    handleSelection={this.handleDaySelection}
                    minDate={minDate}
                    onBlur={this.handleBlur}
                    singleDay={singleDay}
                    value={field.value}
                />
            </ErrorableField>
        )
    }
}
