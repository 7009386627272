import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { IDataSourceAggregatedDataRow } from '../../data-source-types'
import { TDataSourceId } from '../Types/IDataSource'
import { selectAggregatedData, selectAccumulatedData } from '../State/DataSourceSelectors'

interface IStateProps {
    aggregatedData: IDataSourceAggregatedDataRow[]
    oldAccumulatedData: IDataSourceAggregatedDataRow[]
}

interface IOwnProps {
    dataSourceId: TDataSourceId | null
    renderData: (aggregatedData: IDataSourceAggregatedDataRow[]) => JSX.Element
}

export interface IAggregatedDataProps extends IStateProps, IOwnProps {}

export const AggregatedDataUnconnected: React.FC<IAggregatedDataProps> = ({
    aggregatedData,
    oldAccumulatedData,
    renderData,
}) => {
    if (aggregatedData.length === 0 && oldAccumulatedData.length === 0) {
        return null
    }

    return aggregatedData.length > 0 ? renderData(aggregatedData) : renderData(oldAccumulatedData)
}

const mapStateToProps = (state: RootState, { dataSourceId }: IOwnProps): IStateProps => ({
    aggregatedData: selectAggregatedData(state, dataSourceId),
    oldAccumulatedData: selectAccumulatedData(state, dataSourceId),
})

export default connect(mapStateToProps)(AggregatedDataUnconnected)
