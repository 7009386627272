import MockAdapter from 'axios-mock-adapter'
import axios, { AxiosRequestConfig } from 'axios'

export const createAxiosMock = (): MockAdapter => new MockAdapter(axios)

type TMethod = 'get' | 'post' | 'patch' | 'put' | 'delete'

export const getHistoryForMockedRequest = (
    axiosMock: MockAdapter,
    method: TMethod,
    url: string
): AxiosRequestConfig[] => {
    if (!axiosMock.history || !axiosMock.history[method]) {
        return []
    }

    return axiosMock.history[method].filter((axiosRequestObject) => axiosRequestObject.url?.includes(url))
}
