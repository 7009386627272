import { TSortType } from '../../data-source-types'

type SortOrder = TSortType | undefined

const defaultSortType: TSortType = 'ASC'

const changeSortTypeOrGetDefault = (sortOrder: SortOrder): SortOrder => {
    switch (sortOrder) {
        case 'ASC':
            return 'DESC'
        case 'DESC':
            return undefined
        default:
            return defaultSortType
    }
}

export default changeSortTypeOrGetDefault
