import * as React from 'react'
import styled from '@emotion/styled'

type Props = {
    icon?: string | null
    children: React.ReactNode
    className?: string
}

//TODO IF WE FALLBACK TO ICONS, CHENGE 0 TO iconSize
const IconPlaceholder = styled.div`
    width: ${0};
`

const FieldItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 18px 32px 4px;
    width: 100%;
`

const IconContainer = styled.div`
    margin-right: 7px;
`

const FieldItemContentContainer = styled.div`
    min-height: 49px;
    display: flex;
    flex-direction: row;
    width: 100%;
`

const ChildContainer = styled.div`
    display: flex;
    flex-direction: column;
`

/**
 * You can use this in forms when there are fields for which you need to display
 * an icon. The component simply renders the icon or an empty space for it if the
 * icon isn't given, and then next to it renders the actual content given with
 * children property next
 */
const FieldContainer = ({ children }: Props): JSX.Element => {
    const iconSpace = <IconPlaceholder />

    return (
        <FieldItemContainer>
            <IconContainer>{iconSpace}</IconContainer>
            <FieldItemContentContainer>
                <ChildContainer>{children}</ChildContainer>
            </FieldItemContentContainer>
        </FieldItemContainer>
    )
}

export default FieldContainer
