import { keyframes } from '@emotion/react'

const fabrik = 'Fabrik, arial'
const inter = 'Inter, arial'

export const light_grey_100 = '#F5F7FA'

export const white = '#ffffff'
export const black = '#000000'
export const dark_100 = '#2A2A2A'
export const dark_80 = '#7F7F7F'
export const dark_60 = '#BFBFBF'
export const dark_40 = dark_60
export const dark_20 = '#E8E8E8'

export const primary_20 = '#E3F0F1'
export const primary_30 = primary_20
export const primary_40 = primary_30
export const primary_60 = '#68AEB0'
export const primary_80 = primary_60
export const primary_100 = '#026063'
export const primary_120 = primary_100
export const primary_140 = '#02484A'

export const event_other = '#474B6A'
export const event_other_secondary = '#FDDCE0'
export const event_other_tertiary = '#F98A97'
export const event_4 = '#9D5943'
export const event_4_secondary = '#FFF0EB'

export const success_100 = '#007A60'
export const success_60 = '#66AFA0'
export const success_30 = '#B3D7CF'
export const success_10 = '#E3F0EE'

export const light_grey_140 = '#BFBFBF'
export const light_grey_120 = light_grey_140
export const light_grey_110 = '#E8E8E8'
export const light_grey_20 = '#F8F6F6'

export const alert_100 = '#c72c2c'
export const alert_60 = '#dd8080'
export const alert_30 = '#eec0c0'
export const alert_10 = '#f9e8e8'

export const orange_140 = '#906800'
export const orange_120 = '#CCA204'
export const orange_100 = '#FFCB05'
export const orange_60 = '#FFE069'
export const orange_20 = '#FFF9E3'

export const event_transition = '#448CC1'
export const event_transition_2 = '#E9F6FF'

export const blue = '#1D588F'

export type Color =
    | 'error'
    | 'event_transition'
    | 'event_transition_2'
    | 'event_other'
    | 'event_other_secondary'
    | 'event_other_tertiary'
    | 'event_4'
    | 'event_4_secondary'
    | 'primary'
    | 'white'
    | 'black'
    | 'blue'
    | 'dark_100'
    | 'dark_80'
    | 'dark_60'
    | 'dark_40'
    | 'dark_20'
    | 'primary_140'
    | 'primary_120'
    | 'primary_100'
    | 'primary_80'
    | 'primary_60'
    | 'primary_40'
    | 'primary_30'
    | 'primary_20'
    | 'primary_10'
    | 'secondary_140'
    | 'secondary_120'
    | 'secondary_100'
    | 'secondary_60'
    | 'secondary_20'
    | 'light_grey_120'
    | 'light_grey_110'
    | 'light_grey_100'
    | 'light_grey_80'
    | 'light_grey_60'
    | 'light_grey_20'
    | 'alert_100'
    | 'alert_60'
    | 'alert_30'
    | 'alert_10'
    | 'success_100'
    | 'success_60'
    | 'success_30'
    | 'success_10'
    | 'orange_140'
    | 'orange_120'
    | 'orange_100'
    | 'orange_60'
    | 'orange_20'

export type TextTransform = 'uppercase' | 'lowercase' | 'capitalize' | 'none'

export const getColorCode = (color: Color | undefined): string | undefined => {
    switch (color) {
        case 'white':
            return white
        case 'black':
            return black
        case 'blue':
            return blue

        case 'dark_100':
            return dark_100
        case 'dark_80':
            return dark_80
        case 'dark_60':
            return dark_60
        case 'dark_40':
            return dark_40
        case 'dark_20':
            return dark_20

        case 'primary_120':
            return primary_120
        case 'primary_100':
            return primary_100
        case 'primary_80':
            return primary_80
        case 'primary_60':
            return primary_60
        case 'primary_40':
            return primary_40
        case 'primary_30':
            return primary_30

        case 'alert_100':
            return alert_100
        case 'alert_60':
            return alert_60
        case 'alert_30':
            return alert_30
        case 'alert_10':
            return alert_10

        case 'success_100':
            return success_100
        case 'success_60':
            return success_60
        case 'success_30':
            return success_30
        case 'success_10':
            return success_10

        case 'orange_100':
            return orange_100

        case 'event_other':
            return event_other
        case 'event_other_secondary':
            return event_other_secondary
        case 'event_other_tertiary':
            return event_other_secondary

        case 'event_4':
            return event_4
        case 'event_4_secondary':
            return event_4_secondary
        //deprecated

        case 'primary':
            return primary_120

        case 'error':
            return alert_100

        case 'event_transition':
            return event_transition

        case 'event_transition_2':
            return event_transition_2

        default:
            return undefined
    }
}

export const getSelectableColorIds = (): string[] => ['event_1', 'event_2', 'event_3', 'event_4', 'event_5', 'event_6']

export const getColor100ForColorId = (colorId: string): string => {
    if (colorId === null || colorId === undefined) {
        return '#fff'
    }

    switch (colorId) {
        case 'event_block':
            return '#983c52'
        case 'event_wish':
            return success_100
        case 'event_other':
            return event_other
        case 'event_base':
            return primary_140
        case 'event_1':
            return '#3E3474'
        case 'event_2':
            return '#506A99'
        case 'event_3':
            return '#906800'
        case 'event_4':
            return '#983C52'
        case 'event_5':
            return '#007A60'
        case 'event_6':
            return '#C72C2C'
        default:
            return '#fff'
    }
}

export const getColor60ForColorId = (colorId: string): string => {
    switch (colorId) {
        case 'event_block':
            return '#fef3f5'
        case 'event_wish':
            return '#307755'
        case 'event_other':
            return '#53459b'
        case 'event_base':
            return primary_30
        case 'event_1':
            return '#f0eff9'
        case 'event_2':
            return '#f3f7ff'
        case 'event_3':
            return '#fffae6'
        case 'event_4':
            return '#fef3f5'
        case 'event_5':
            return '#f2f8eb'
        case 'event_6':
            return '#eed9e8'
        default:
            return '#fff'
    }
}

export const getColor40ForColorId = (colorId: string): string => {
    switch (colorId) {
        case 'event_block':
            return alert_30
        case 'event_wish':
            return '#f2f8eb'
        case 'event_other':
            return '#f0eff9'
        case 'event_base':
            return primary_40
        case 'event_1':
            return '#ECF3FF'
        case 'event_2':
            return '#F0F7FC'
        case 'event_3':
            return '#FFF5CD'
        case 'event_4':
            return '#FFF0EB'
        case 'event_5':
            return '#DBE9DA'
        case 'event_6':
            return '#EED9E8'

        default:
            return '#fff'
    }
}

export const getColor30ForColorId = (colorId: string): string => {
    switch (colorId) {
        case 'event_block':
            return alert_10
        case 'event_wish':
            return success_10
        case 'event_other':
            return '#F7F5F8'
        case 'event_base':
            return primary_30
        case 'event_1':
            return '#F6F8FC'
        case 'event_2':
            return '#F5FBFE'
        case 'event_3':
            return '#FCF8EB'
        case 'event_4':
            return '#FFF8F5'
        case 'event_5':
            return '#F3FBF2'
        case 'event_6':
            return '#FDF3FA'
        default:
            return '#fff'
    }
}

export const getPreviewColorForColorId = (colorId: string): string => {
    switch (colorId) {
        case 'event_1':
            return '#6352bc'
        case 'event_2':
            return '#6b88bd'
        case 'event_3':
            return '#dbaf00'
        case 'event_4':
            return '#be4b66'
        case 'event_5':
            return '#609e81'
        case 'event_6':
            return '#b93d96'
        default:
            return '#fff'
    }
}

/**
 * Yleiset, Haahtela-brändin mukaiset tyylimäärittelyt.
 */
export default {
    border: {
        radius: '5px',
        style: 'solid 2px ', // Älä hävitä lopussa olevaa välilyöntiä.
        // Voi käyttää box-shadow-tyylimääritteellä näyttämään kivan varjostuksen. Ei välttämättä tarvitse borderia tämän kanssa
        borderBoxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    date: {
        displayFormat: 'D.M.YYYY',
    },
    font: {
        levelOneTitle: {
            family: fabrik,
            size: '1.429rem',
            textTransform: 'uppercase',
            fontWeight: '700',
        },
        levelTwoTitle: {
            family: fabrik,
            size: '1.429rem',
            fontWeight: '700',
        },
        levelThreeTitle: {
            family: fabrik,
            size: '1.071rem',
        },
        text: {
            family: inter,
            size: '1.0rem',
        },
        small: {
            family: inter,
            size: '0.857rem',
        },
    },
    tooltip: {
        boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
    },
    layer: {
        background: 1,
        flowContent: 10,
        staticContent: 100,
        overlay: 1000,
        popup: 1300,
        dropdownMenu: 10000,
        tooltip: Number.MAX_SAFE_INTEGER - 1,
        loadingIndicator: Number.MAX_SAFE_INTEGER,
    },
    layout: {
        buttonBorderRadius: '3px',
        defaultMargin: '8px',
        defaultPadding: '8px',
        drawerWidth: '18.8rem',
        dropdownMenuMinHeight: '300px',
        smallPadding: '4px',
        pageDefaultLeftPadding: '1.250rem',
        filterAndListMinWidth: '69rem',
        paddingLeftForFilters: '1.3rem',
        wideTextAreaMaxWidth: '85.7rem',
        listIconWidth: '3.286',
        listButtonInfoIconSize: '1.5rem',
        listButtonInfoPicWidth: '240px',
        listRowAndHeaderHeight: '3.57rem',
        listRowHeight: '3.57rem',
        listRowHeightWithinForm: '5rem',
        listSubHeadingContainerHeight: '1.5rem',
        mainNavigationHeight: '4.571rem',
        pageContainer: {
            display: 'inherit',
            flexDirection: 'inherit',
            width: 'inherit',
            height: 'inherit',
        },
        expansionArrowBlock: {
            width: '1.500rem',
            margin: '0 0 0 0.714rem',
            bottom: '-0.3rem',
            cursor: 'pointer',
        },
        navigationIcon: {
            maxWidth: '8.214rem',
        },
        titleContainer: {
            paddingLeft: '1.25rem',
            marginTop: '1rem',
            position: 'absolute',
        },
        listTitleContainer: {
            marginBottom: '1.275rem',
        },
        listTitleMobileContainerMobile: {
            marginBottom: '1.275rem',
            paddingLeft: '0.25rem',
        },
        formTitleContainer: {
            paddingBottom: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
        },
        buttonContainer: {
            marginLeft: '-0.2rem',
            paddingBottom: '1.4rem',
            paddingLeft: '1.250rem',
            flexShrink: 0,
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: '2.143rem 2.143rem 1.714rem',
        },
        formContainerMobile: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: '0.7rem 0.7rem 1.714rem',
        },
        formContent: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            height: '100%',
            transform: 'translate3d(0, 0, 0)', // korjaa Applen laitteilla tapahtuvaa tekstien poistumista skrollatessa
        },
        formContentWithoutScrolling: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            transform: 'translate3d(0, 0, 0)',
        },
        formContentMobile: {
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            transform: 'translate3d(0, 0, 0)',
        },
        formButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: 'auto',
            padding: '0.286rem 0rem 0.286rem 0.286rem',
            marginLeft: '0.286rem',
            flexShrink: 0,
            transform: 'translate3d(0, 0, 0)', // korjaa Applen laitteilla tapahtuvaa tekstien poistumista skrollatessa
        },
        filter: {
            display: 'inline-block',
            paddingRight: '8px',
            minWidth: '17.7rem',
            marginBottom: '8px',
        },
        dateRangeFilterContainer: {
            display: 'inline-block',
            paddingRight: '8px',
            minWidth: '22rem',
            marginBottom: '8px',
        },
        filterContainer: {
            display: 'inherit',
            alignItems: 'inherit',
            flexDirection: 'inherit',
        },
        tab: {
            marginSides: '1.429rem',
            width: '8.500rem',
            fontFamily: inter,
            fontSize: '1rem',
        },
        listContainer: {
            display: 'flex',
            width: '100%',
            height: '100%',
        },
        listPlaceholderContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            minHeight: '3.5rem',
        },
        placeholderText: {
            color: 'rgba(109, 110, 113, 0.5)',
            fontFamily: inter,
            fontSize: '1.071rem',
            fontStyle: 'italic',
        },
    },
    transition: {
        quick: '0.16s ease-in-out',
    },
    mainColor: {
        black: black,
        blue: white,
        secondaryBlue: primary_30,
        darkGrey: dark_80,
        white: white,
        lightGrey: dark_20,
    },
    // https://projects.invisionapp.com/d/main#/console/20673366/441086910/preview
    planierColor: {
        orange: {
            bright: '#FFCB05',
            medium: '#CCA204',
            light: '#FFE069',
        },
        blueTurquoise: {
            textColor: primary_100,
            turquoisePrimary: '#026063',
            turquoiseSecondary: '#B3E3EA',
            turquoiseTertiary: '#E3F0F1',
        },
        gray: {
            veryDark: '#2A2A2A',
            dark: '#7F7F7F',
            medium: '#BFBFBF',
            light: '#E8E8E8',
            darkText: '#434547',
        },
        white: '#FFFFFF',
    },
    overlay: {
        color: primary_80,
    },
    supplementaryColor: {
        haahtelaBlueLight: '#54b8e6',
        checkboxGrey: '#a1b4c0',
        opaqueDarkGrey: 'rgba(109, 110, 113, 0.4)',
        blueGrey: '#7a95a6',
        concreteGrey: '#d0dbe2',
        brown: '#b9975b',
        darkBlue: '#026063',
        darkOrange: '#d3734e',
        green: '#00ab84',
        smokeyBlue: '#38a3ce',
        lightBlue: '#72d0f4',
        lightBlueGrey: '#eff6fc',
        lightCyan: '#e0f5ff',
        brightBlue: '#4bbbed',
        lightGreen: '#71ac00',
        lightViolet: '#ab619c',
        orange: '#e87722',
        tyrkkyGreen: '#BDECB6',
        red: '#ee5d7f',
        turquoise: '#5fbecc',
        seaGreen: '#59c8af',
        yellow: '#fdc230',
        basicGrey: '#ccc',
        blackGrey: '#333',
        disabledGrey: '#f9f9f9',
        buttonDisabled: '#dce3e7',
        buttonEnabled: '#7095a6',
        pagePlaceHolderText: '#9f9fa4',
        rowDivider: '#edf0f2',
        rowDividerDark: '#dce3e7',
        columnHeader: '#939398',
        listRowActionDisabledGrey: '#939398',
    },
    button: {
        textButtonHover: 'rgba(122, 149, 166, 0.1)',
    },
    calendar: {
        blockHover: '#ABCBE3',
        actionBarBoxShadow: 'rgba(0, 0, 0, 0.5)',
        expandableCalendarBoxShadow: 'rgba(0, 0, 0, 0.32)',
        greyFiller: 'rgba(237, 240, 242, 0.5)',
        greyFillerWithoutOpacity: '#F2F5F5',
        innerBorder: '#edf0f2',
        segmentBackgroundShading: 'rgba(245, 247, 250, 0.5)',
        segmentBackgroundWhite: 'rgba(255, 255, 255, 0.5)',
        published: 'rgba(68, 140, 193, 1)',
        notPublishedHover: 'rgba(68, 140, 193, 0.1)',
        filled: 'rgba(68, 140, 193, 0.3)',
        filledHover: 'rgba(68, 140, 193, 0.4)',
        transferBackground: '#EFF7FC',
        transferBorder: '#448CC1',
        transferTemporaryBorder: '#CAE6F7',
        esto: 'rgba(238, 93, 127, 1)',
        estoBackground: 'rgba(238, 93, 127, 0.3)',
        estoHover: 'rgba(238, 93, 127, 0.4)',
        tyrkky: 'rgba(0, 171, 132, 1)',
        tyrkkyBackground: 'rgba(0, 171, 132, 0.3)',
        tyrkkyHover: 'rgba(0, 171, 132, 0.4)',
        muuTapahtuma: 'rgba(116, 88, 138, 1)',
        muuTapahtumaBackground: 'rgba(116, 88, 138, 0.3)',
        muuTapahtumaHover: 'rgba(116, 88, 138, 0.4)',
        selectedNodeBackground: 'rgba(56, 163, 206, 0.2)',
    },
    /* Material UI theme styling */
    palette: {
        primary: {
            main: primary_100,
        },
    },
    typography: {
        dialogHeader: {
            color: '#2A2A2A',
            fontFamily: fabrik,
            fontSize: '18px',
            fontWeight: 700,
            letterSpacing: 0,
            lineHeight: '23px',
        },
        listHeader: {
            fontFamily: inter,
            fontWeight: 600,
            color: '#2A2A2A',
            letterSpacing: 0,
            lineHeight: '18px',
            fontSize: '14px',
        },
        listElement: {
            fontFamily: inter,
            fontWeight: 'normal' as const,
            color: '#2A2A2A',
            letterSpacing: 0,
            lineHeight: '18px',
            fontSize: '14px',
        },
        h1: {
            fontFamily: fabrik,
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            color: '#2A2A2A',
        },
        h2: {
            fontFamily: fabrik,
            fontSize: '22px',
            lineHeight: '22px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            color: '#2A2A2A',
        },
        h3: {
            fontFamily: fabrik,
            fontSize: '20px',
            lineHeight: '26px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            color: '#2A2A2A',
        },
        h4: {
            fontFamily: fabrik,
            fontSize: '18px',
            lineHeight: '24px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            color: '#2A2A2A',
        },
        h5: {
            fontFamily: fabrik,
            fontSize: '16px',
            lineHeight: '22px',
            fontWeight: 'bold',
            fontStyle: 'normal',
            color: '#2A2A2A',
        },
        h6: {
            fontFamily: fabrik,
            fontSize: '12px',
            lineHeight: '20px',
            fontWeight: 'normal',
            color: '#2A2A2A',
        },
        subtitle1: {
            fontFamily: inter,
            fontSize: '16px',
            lineHeight: '16px',
            fontWeight: 400,
            color: '#2A2A2A',
        },
        subtitle2: {
            fontFamily: inter,
            fontSize: '15px',
            lineHeight: '15px',
            fontWeight: 700,
            fontStyle: 'italic',
            color: '#2A2A2A',
        },
        bodyS: {
            fontFamily: inter,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400,
            color: '#2A2A2A',
        },
        bodyXS: {
            fontFamily: inter,
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: 400,
            color: '#2A2A2A',
        },
        // Named 'small' in some design specs.
        caption: {
            fontFamily: inter,
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 400,
            color: '#2A2A2A',
        },
        button: {
            fontFamily: inter,
            fontSize: '16px',
            color: '#2A2A2A',
            lineHeight: '21px',
            letterSpacing: 0,
            textTransformation: 'uppercase',
        },
    },
    typographyExtensions: {
        dropdown_label: {
            fontFamily: inter,
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            color: '#2A2A2A',
        },
        dropdown_selection: {
            fontFamily: inter,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '17px',
            color: black,
        },
        dropdown_placeholder: {
            fontFamily: inter,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '17px',
            color: dark_60,
        },
        list_header: {
            fontFamily: inter,
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '18px',
            color: '#2A2A2A',
        },
        list_data: {
            fontFamily: inter,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#2A2A2A',
        },
        list_link: {
            fontFamily: inter,
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '20px',
            color: primary_100,
        },
        event_block: {
            fontFamily: inter,
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '11px',
            lineHeight: '14px',
            color: primary_120,
        },
        h7: {
            fontFamily: fabrik,
            fontStyle: 'normal',
            fontWeight: 600, // semi-bold
            fontSize: '16px',
            lineHeight: '22px',
            color: '#2A2A2A',
        },
        H1: {
            fontFamily: fabrik,
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '22px',
            lineHeight: '27px',
            color: primary_120,
        },
        h8: {
            fontFamily: fabrik,
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            color: '#2A2A2A',
        },
        legacyListElement: {
            fontFamily: inter,
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            color: '#2A2A2A',
        },
        smallbutton: {
            fontFamily: inter,
            fontSize: '14px',
            color: '#2A2A2A',
            lineHeight: '22px',
            textTransform: <TextTransform>'uppercase',
        },
    },
    /* END Material UI theme styling */

    /**
     * Luo hexadecimal-muotoisen värin. Tekee tämän käyttäen
     * hashia, jonka se luo saamastaan merkkijonoparametrista. Näin
     * samalle merkkijonolle saadaan aina sama väri.
     */
    createColor: (str: string): string => {
        let hash = 0
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
        let colour = '#'
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff
            colour += ('00' + value.toString(16)).substr(-2)
        }

        return colour
    },
}

export const loadingPlaceholder = ({ width, height }: { width: number; height: number }): Record<string, string> => {
    const placeholderAnimation = keyframes`
      from {
        background-position: ${width}px 0;
      }

      to {
        background-position: ${width * 3}px 0;
      }
    `

    return {
        background: `linear-gradient(to right, rgba(0, 0, 0, 0.1) 8%, rgba(0, 0, 0, 0.2) 38%, rgba(0, 0, 0, 0.1) 54%);`,
        backgroundSize: `${width * 2}px ${height * 2}px`,
        animationName: placeholderAnimation,
        animationDuration: '3s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    }
}
