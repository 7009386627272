import { MutableRefObject, useLayoutEffect, useRef, useState, useCallback } from 'react'

interface IUseDoAllChildrenFitContainerHorizontallyReturn {
    doChildrenFitContainer: boolean
    containerRef: MutableRefObject<HTMLDivElement | null>
    overflowingElementsStartIndex: number | null
    checkAndSaveDoChildrenFitContainerNew: () => void
}

interface IUseDoAllChildrenFitContainerHorizontally {
    /**
     * Whether or not the children are allowed to wrap to multiple lines
     * or if the items are only rendered horizontally. In the first case
     * even though the items actually align vertically, we might still want
     * to know whether they all would actually fit horizontally.
     *
     * When the items are allowed to wrap vertically, we do the check whether
     * or not the items fit horizontally by checking if there are any elements
     * that have a different bottom position than the first element. Thus *IMPORTANT*
     * the check assumes that all the elements have the same height and similar padding
     * and such.
     *
     * When the items are only horizontally, the check is done by comparing the scroll
     * width (the total width needed) of the container element to the client width
     * (the actual width the component has).
     */
    itemsWrapVertically?: boolean
}

const getOverflowingElementsStartIndex = (containerElement: Element, itemsWrapVertically: boolean): number | null => {
    if (itemsWrapVertically) {
        const firstElementBottomPosition = containerElement.children[0].getBoundingClientRect().bottom
        const newOverflowingElementsStartIndex: null | number = Array.from(containerElement.children).findIndex(
            (childElement) => childElement.getBoundingClientRect().bottom !== firstElementBottomPosition
        )

        return newOverflowingElementsStartIndex === -1 ? null : newOverflowingElementsStartIndex
    } else {
        const leftPositionOfContainer = containerElement.getBoundingClientRect().left
        const containerWidthForNonOverflowingElements = containerElement.clientWidth

        const newOverflowingElementsStartIndex = Array.from(containerElement.children).findIndex((childElement) => {
            const elementEndPositionRelativeToContainer =
                childElement.getBoundingClientRect().right - leftPositionOfContainer
            const isElementOverflowing = elementEndPositionRelativeToContainer > containerWidthForNonOverflowingElements

            return isElementOverflowing
        })

        return newOverflowingElementsStartIndex === -1 ? null : newOverflowingElementsStartIndex
    }
}

const useDoAllChildrenFitContainerHorizontally = ({
    itemsWrapVertically = false,
}: IUseDoAllChildrenFitContainerHorizontally = {}): IUseDoAllChildrenFitContainerHorizontallyReturn => {
    const containerRef = useRef<null | HTMLDivElement>(null) // TODO: should allow for other than div elements as containers
    const [doChildrenFitContainer, setDoChildrenFitContainer] = useState(true)

    const [overflowingElementsStartIndex, setOverflowingElementsStartIndex] = useState<null | number>(null)

    const checkAndSaveDoChildrenFitContainerNew = useCallback(() => {
        if (containerRef.current === null) {
            return
        }

        const overflowingElementsStartIndexNew = getOverflowingElementsStartIndex(
            containerRef.current,
            itemsWrapVertically
        )

        if (overflowingElementsStartIndexNew !== overflowingElementsStartIndex) {
            setOverflowingElementsStartIndex(overflowingElementsStartIndexNew)
        }

        const doChildrenFitContainerNew = Boolean(overflowingElementsStartIndexNew)
        if (doChildrenFitContainerNew !== doChildrenFitContainer) {
            setDoChildrenFitContainer(doChildrenFitContainerNew)
        }
    }, [doChildrenFitContainer, itemsWrapVertically, overflowingElementsStartIndex])

    useLayoutEffect(() => {
        checkAndSaveDoChildrenFitContainerNew()
    }, [checkAndSaveDoChildrenFitContainerNew])

    return {
        containerRef,
        doChildrenFitContainer,
        overflowingElementsStartIndex,
        checkAndSaveDoChildrenFitContainerNew,
    }
}

export default useDoAllChildrenFitContainerHorizontally
