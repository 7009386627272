import * as React from 'react'
import { MouseEvent } from 'react'

import { Icon } from '../../../../generic-components'
import styled from '@emotion/styled'

import SortType from 'constants/SortType'
import { IDataSourceSortParameter } from '../../../../data-source-types'
import { useTheme } from '@emotion/react'
import IconButton from '@material-ui/core/IconButton'

export interface ISortIconProps {
    className?: string
    handleHeaderClick?: (event: MouseEvent) => void
    sortOrder: IDataSourceSortParameter['Order'] | undefined
}

const getIcon = (sortOrder: ISortIconProps['sortOrder']): string => {
    switch (sortOrder) {
        case SortType.ASC:
            return 'arrow_downward'
        case SortType.DESC:
            return 'arrow_upward'
        default:
            return ''
    }
}

const Button = styled(IconButton)`
    margin-left: 7px;
`

export const SortIcon = ({ className, handleHeaderClick, sortOrder }: ISortIconProps): JSX.Element | null => {
    const { componentExtensions } = useTheme()

    const icon = getIcon(sortOrder)

    if (!icon) {
        return null
    }

    return (
        <Button size="small" onClick={handleHeaderClick}>
            <Icon className={className} color={componentExtensions.icons.iconAction}>
                {icon}
            </Icon>
        </Button>
    )
}

export default SortIcon
