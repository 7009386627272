import { connect } from 'react-redux'
import * as React from 'react'
import { RootState } from 'typesafe-actions'

import { selectSelectedEventIds } from '../../../../tapahtuma/State/Selectors/TapahtumaSelector'
import { closeAllModalsAction } from '../../../../modal'
import { EventDeletionModal } from '../../../../event'
import { VUOROT_JA_VAPAAT_LIST_ID, VUOROT_JA_VAPAAT_DATA_SOURCE_ID } from '../Constants/VuorotJaVapaatConstants'
import { resetAllSelectedListItemsAction } from '../../../../configurable-list'

interface IOwnProps {
    tapahtumaId?: string
    onDeletionModalClose: () => void
}

interface IDispatchProps {
    closeAllModals: typeof closeAllModalsAction
    resetAllSelectedListItems: typeof resetAllSelectedListItemsAction
}

interface IStateProps {
    tapahtumaIds: string[]
}

export interface IVuorotJaVapaatFormsProps extends IOwnProps, IStateProps, IDispatchProps {}

export class VuorotJaVapaatFormsUnconnected extends React.Component<IVuorotJaVapaatFormsProps> {
    render(): React.ReactNode {
        const { tapahtumaId, tapahtumaIds, onDeletionModalClose, resetAllSelectedListItems } = this.props

        const resetAllSelectedListItemsAfterSubmit = () => resetAllSelectedListItems(VUOROT_JA_VAPAAT_LIST_ID)

        return (
            <React.Fragment>
                <EventDeletionModal
                    dataSourceId={VUOROT_JA_VAPAAT_DATA_SOURCE_ID}
                    deletionUrl="/Tapahtuma"
                    eventIds={tapahtumaId ? [tapahtumaId] : tapahtumaIds}
                    onClose={onDeletionModalClose}
                    resetSelectedEventsAfterSubmit={resetAllSelectedListItemsAfterSubmit}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: RootState): IStateProps => {
    const tapahtumaIds = selectSelectedEventIds(state)

    return {
        tapahtumaIds,
    }
}

const mapDispatchToProps: IDispatchProps = {
    closeAllModals: closeAllModalsAction,
    resetAllSelectedListItems: resetAllSelectedListItemsAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(VuorotJaVapaatFormsUnconnected)
