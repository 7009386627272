import { RootState } from 'typesafe-actions'
import createCachedSelector from 're-reselect'

import {
    EViewDataStatus,
    IDataSourceAccumulatedData,
    IDataSourceAllFetchRelatedData,
    IDataSourceDataRequestParametersOverride,
    IDataSourceGroupData,
    IDataSourceItem,
    IDataSourceSortParameter,
    IDataSourceSortParameters,
    TDataSourceItemId,
} from '../../data-source-types'
import IDataSourceData from '../Types/IDataSourceData'
import IDataSource, { TDataSourceId } from '../Types/IDataSource'
import IDataSourceAction from '../Types/IDataSourceAction'
import { getDataSourceExcelOperationId } from '../Utilities/AsyncOperationIds'
import { selectAsyncOperationOtherData, selectIsAsyncOperationIsInProgress } from '../../async-operation'
import { IDataSourceConfiguration } from '..'
import IDataSourcePropertyModel from '../Types/IDataSourcePropertyModel'
import { getAggregatedData } from '../Utilities/GroupDataUtilities'
import { DEFAULT_OVERRIDE_REQUEST_PARAMETERS } from '../Constants/DataSourceQueryConstants'
import IEmptyCellNode from '../../calendar/Types/IEmptyCellNode'

const defaultCacheKey = 'dataSourceNotInitialized'

const getDataSource = (programState: RootState, dataSourceId: TDataSourceId | null): IDataSource | undefined => {
    if (dataSourceId === null) {
        return undefined
    }

    const dataSources = programState.dataSource

    return dataSources.get(dataSourceId)
}

export const selectDataSourceConfiguration = (
    programState: RootState,
    dataSourceId: TDataSourceId | null
): IDataSourceConfiguration | null => {
    const dataSource = getDataSource(programState, dataSourceId)

    return dataSource ? dataSource.configuration : null
}

export const selectAccumulatedData = (
    programState: RootState,
    dataSourceId: TDataSourceId | null
): IDataSourceAccumulatedData[] => {
    const dataSource = getDataSource(programState, dataSourceId)

    return dataSource?.accumulatedData ?? []
}

export const selectActionConfigurations = (
    programState: RootState,
    dataSourceId: TDataSourceId | null
): IDataSourceAction[] => {
    const configuration = selectDataSourceConfiguration(programState, dataSourceId)

    return configuration ? configuration.Actions : []
}

export const selectDataFetchURL = (programState: RootState, dataSourceId: TDataSourceId | null): string => {
    const configuration = selectDataSourceConfiguration(programState, dataSourceId)

    return configuration ? configuration.Url : ''
}

export const selectDataItemIds = (programState: RootState, dataSourceId: TDataSourceId | null): TDataSourceItemId[] => {
    const dataSource = getDataSource(programState, dataSourceId)

    return dataSource ? dataSource.orderedItemIds : []
}

export const selectDataSourceData = (programState: RootState, dataSourceId: TDataSourceId | null): IDataSourceData => {
    const dataSource = getDataSource(programState, dataSourceId)

    return dataSource ? dataSource.data : (new Map() as IDataSourceData)
}

export const selectDataSourceItem = (
    programState: RootState,
    dataSourceId: TDataSourceId | null,
    itemId: TDataSourceItemId
): IDataSourceItem | undefined => {
    const data = selectDataSourceData(programState, dataSourceId)
    return data.get(itemId)
}

export const selectDataSourceItems = createCachedSelector(selectDataSourceData, (data): IDataSourceItem[] => {
    return [...data.values()]
})((state, dataSourceId) => dataSourceId ?? defaultCacheKey)

export const selectDataSourceItemsByIds = (
    programState: RootState,
    dataSourceId: TDataSourceId | null,
    itemIds: TDataSourceItemId[] | null
): IDataSourceItem[] =>
    (itemIds ?? [])
        .map((id) => selectDataSourceItem(programState, dataSourceId, id))
        .filter((data) => data) as IDataSourceItem[]

export const selectEmptyNodeCellsByIds = (
    programState: RootState,
    calendarId: string,
    itemIds: string[]
): IEmptyCellNode[] =>
    itemIds.map((id) => selectEmptyCellNode(programState, calendarId, id)).filter((data) => data) as IEmptyCellNode[]

// @@TODO This should exist in another module
export const selectLoadingFieldName = (dataSourceId: TDataSourceId | null): string => {
    return `dataSource-${dataSourceId}`
}

export const selectIsDataSourceDataLoading = (state: RootState, dataSourceId: TDataSourceId | null): boolean => {
    const loadingFieldName = selectLoadingFieldName(dataSourceId)

    return selectIsAsyncOperationIsInProgress(state, loadingFieldName)
}

export const selectIsDataSourceInitialized = (programState: RootState, dataSourceId: TDataSourceId | null): boolean => {
    const dataSource = getDataSource(programState, dataSourceId)

    return Boolean(dataSource && dataSource.configuration)
}

export const selectAreDataSourcesInitialized = (state: RootState, dataSourceIds: TDataSourceId[]): boolean => {
    return dataSourceIds.every((id) => selectIsDataSourceInitialized(state, id))
}

export const selectIsLoadingItem = (
    state: RootState,
    dataSourceId: TDataSourceId | null,
    rowItemId: TDataSourceItemId
): boolean => {
    const loadingFieldName = selectLoadingFieldName(dataSourceId)

    if (!selectIsAsyncOperationIsInProgress(state, loadingFieldName)) {
        return false
    }

    const rowLoadingData = selectAsyncOperationOtherData(state, loadingFieldName)

    if (!rowLoadingData) {
        return false
    }

    const { itemId } = rowLoadingData as { itemId?: string | number }

    const rowIsLoading = itemId === rowItemId
    return rowIsLoading
}

export const selectDataSourcePropertyName = (
    programState: RootState,
    dataSourceId: TDataSourceId | null,
    dataSourcePropertyId: string
): string | null => {
    const configuration = selectDataSourceConfiguration(programState, dataSourceId)

    if (!configuration) {
        return null
    }

    const property = configuration.Properties.find(({ Id }) => Id === dataSourcePropertyId)

    if (!property) {
        return null
    }

    return property.Property
}

export const selectDataSourceActionById = (
    state: RootState,
    dataSourceId: TDataSourceId | null,
    actionId: string
): IDataSourceAction | null => {
    const configuration = selectDataSourceConfiguration(state, dataSourceId)

    if (!configuration) {
        return null
    }

    const action = configuration.Actions.find(({ Id }) => Id === actionId)

    if (!action) {
        return null
    }

    return action
}

export const selectDataSourceDependentDataSourceIds = (
    state: RootState,
    dataSourceId: TDataSourceId | null
): string[] => {
    if (!dataSourceId) {
        return []
    }

    return [...state.dataSource.keys()].reduce((dependentIds, currentDataSourceId) => {
        const configuration = selectDataSourceConfiguration(state, currentDataSourceId)

        if (!configuration || !configuration.DependsOn.includes(dataSourceId)) {
            return dependentIds
        }

        return [...dependentIds, currentDataSourceId]
    }, [])
}

export const selectDataSourceValuePickerIds = (state: RootState, dataSourceId: TDataSourceId): string[] => {
    const dataSource = getDataSource(state, dataSourceId)

    return dataSource ? dataSource.valuePickerIds : []
}

export const selectDataSourceFetchFiltersParameters = (
    state: RootState,
    dataSourceId: TDataSourceId
): Record<string, unknown> => {
    const dataSource = getDataSource(state, dataSourceId)

    return dataSource?.fetchFiltersParameters ?? {}
}

export const selectDataSourceFetchParameters = (
    state: RootState,
    dataSourceId: TDataSourceId
): Record<string, unknown> => {
    const dataSource = getDataSource(state, dataSourceId)

    return dataSource?.fetchParameters ?? {}
}

export const selectDataSourceGroupData = (
    state: RootState,
    dataSourceId: TDataSourceId | null
): IDataSourceGroupData[] => {
    const dataSource = getDataSource(state, dataSourceId)

    return dataSource?.groupData ?? []
}

export const selectIsDataSourceDataFetched = (state: RootState, dataSourceId: TDataSourceId | null): boolean => {
    const dataSource = getDataSource(state, dataSourceId)

    return dataSource ? dataSource.isDataFetched : false
}

export const selectDataSourcePropertiesConfiguration = (
    programState: RootState,
    dataSourceId: TDataSourceId | null
): IDataSourcePropertyModel[] => {
    const configuration = selectDataSourceConfiguration(programState, dataSourceId)

    return configuration ? configuration.Properties : []
}

export const selectAggregatedData = createCachedSelector(
    selectDataSourceGroupData,
    getAggregatedData
)((state, dataSourceId) => dataSourceId ?? defaultCacheKey)

export const selectDataSourceSortSettings = (
    programState: RootState,
    dataSourceId: TDataSourceId | null
): IDataSourceSortParameters => {
    const dataSource = getDataSource(programState, dataSourceId)

    return dataSource?.sortSettings ?? new Map()
}

export const selectDataSourceSortSettingsAsArray = createCachedSelector(
    selectDataSourceSortSettings,
    (sortSettings) => {
        const sortBy: IDataSourceSortParameter[] = []

        sortSettings.forEach((value, key) => {
            sortBy.unshift({ Order: value, Property: key })
        })

        return sortBy
    }
)((state, dataSourceId) => dataSourceId ?? defaultCacheKey)

export const selectIsDataSourceBeingDownloadedToExcel = (
    state: RootState,
    dataSourceId: TDataSourceId | null
): boolean => {
    if (!dataSourceId) {
        return false
    }

    const operationId = getDataSourceExcelOperationId(dataSourceId)
    const isInProgress = selectIsAsyncOperationIsInProgress(state, operationId)

    return isInProgress
}

export const selectDataSourceAllFetchRequestRelatedData = createCachedSelector(
    selectDataSourceValuePickerIds,
    selectDataSourceFetchFiltersParameters,
    selectDataSourceFetchParameters,
    selectDataSourceSortSettings,
    (valuePickerIds, fetchFiltersParameters, fetchParameters, sortSettings): IDataSourceAllFetchRelatedData => {
        return {
            valuePickerIds,
            fetchParametersForFilters: fetchFiltersParameters,
            fetchParametersForRootOfTheBody: fetchParameters,
            fetchParametersForSortSettings: sortSettings,
        }
    }
)((state, dataSourceId) => dataSourceId ?? defaultCacheKey)

export const selectDataSourceOffset = (programState: RootState, dataSourceId: TDataSourceId | null): number => {
    const dataSource = getDataSource(programState, dataSourceId)

    return dataSource?.offset ?? 0
}

export const selectDataSourceOverrideFetchParameters = (
    state: RootState,
    dataSourceId: TDataSourceId | null
): IDataSourceDataRequestParametersOverride => {
    const dataSource = getDataSource(state, dataSourceId)

    return dataSource?.overrideFetchParameters ?? DEFAULT_OVERRIDE_REQUEST_PARAMETERS
}

export const selectDataSourceDataStatus = (
    state: RootState,
    dataSourceId: TDataSourceId | null
): EViewDataStatus | null => {
    const dataSource = getDataSource(state, dataSourceId)

    return dataSource?.dataStatus ?? null
}
