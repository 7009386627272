import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import EValuePickerType from '../types/EValuePickerType'

const suunnittelujaksoValuePickers: TValuePickerConfiguration[] = [
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'Suunnittelujakso/Search',
        ValuePickerId: 'WorkforceScheduling_PlanningPeriods',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PointOfViewPlanningPeriodVersionIds',
        ItemIdField: 'ActiveVersionId',
        Text: {
            FetchError: 'Työvuorolistojen haku epäonnistui',
            Label: 'Työvuorolista',
            MultipleSelected: 'Ttyövuorolistaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        // ResetValuePickerOnChange: ['WorkforceScheduling_Calendar_Filter_WorkUnit', 'WorkforceScheduling_Calendar_Filter_Employees'],
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: 'Suunnittelujakso/Identifier',
        ValuePickerId: 'WorkforceScheduling_PlanningPeriods_Recent',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PointOfViewPlanningPeriodVersionIds',
        ItemIdField: 'ActiveVersionId',
        Text: {
            FetchError: 'Työvuorolistojen haku epäonnistui',
            Label: 'Työvuorolista',
            MultipleSelected: 'Ttyövuorolistaa',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 5,
        ExtraRows: 'Omit',
        DefaultValues: null,
        AdditionalDataFetchParameters: {
            SortBy: [{ Property: 'Id', Order: 'DESC' }],
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Tyyppi/Identifier',
        ValuePickerId: 'WorkforceScheduling_EventType',
        ValuePickerType: EValuePickerType.DropdownV2,
        DataRequestParameterName: 'tapahtumatyyppiIdt',
        Text: {
            FetchError: 'Tapahtumatyyppien haku epäonnistui',
            Label: 'Tapahtumatyyppi',
            MultipleSelected: 'tapahtumatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: -1,
        multiselect: false,
        AdditionalDataFetchParameters: {
            Filters: { InsertableEventTypesOnly: true },
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/v2/Tapahtuma/Tyyppi/Identifier',
        ValuePickerId: 'WorkforceScheduling_Calendar_Filter_EventType',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'TyontekijaTapahtumatyyppiIdt',
        Text: {
            FetchError: 'Tapahtumatyyppien haku epäonnistui',
            Label: 'Tapahtumatyypit',
            MultipleSelected: 'tapahtumatyyppiä',
        },
        TextSearchProps: ['Nimi'],
        valueFieldType: 'chip',
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'DateRangeV2Picker',
        Label: null,
        ValuePickerType: EValuePickerType.DateRangeV2,
        DataRequestParameterName: 'AikavaliAlkuaika',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        IdentifierUrl: '/Alue/Identifier',
        ValuePickerId: 'Area_V3',
        Label: 'Alue',
        ValuePickerType: EValuePickerType.DropdownV3,
        DataRequestParameterName: 'alueIdt',
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        IdentifierUrl: '/v2/Tapahtuma/Tyyppi/Identifier',
        ValuePickerId: 'EventType_V3',
        Label: 'Tapahtumatyyppi',
        ValuePickerType: EValuePickerType.DropdownV3,
        DataRequestParameterName: 'TapahtumatyyppiIdt',
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        IdentifierUrl: '/v2/Tapahtuma/Staffing/Type/Identifier',
        ValuePickerId: 'EventTypeFilter_Staffing',
        Label: 'Tapahtumatyyppi',
        ValuePickerType: EValuePickerType.DropdownV3,
        DataRequestParameterName: 'TapahtumatyyppiIdt',
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Text: {
            FetchError: 'Tapahtumatyyppien haku epäonnistui',
            Label: 'Tapahtumatyypit',
            MultipleSelected: 'tapahtumatyyppiä',
        },
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'Area_V3',
                ParameterName: 'AlueIdt',
            },
            {
                ValuePickerId: 'IndustryWorkUnitIndustry_V3',
                ParameterName: 'WorkUnitIndustryIds',
            },
            {
                ValuePickerId: 'WorkUnitGroup_FilterV3',
                ParameterName: 'WorkUnitGroupIds',
            },
        ],
        ItemOptionLabelFields: ['Id', 'Name'],
        IdentifierUrl: '/Asiakas/Identifier',
        ValuePickerId: 'Workunit_V3',
        Label: 'Työkohde',
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerType: EValuePickerType.DropdownV3,
        DataRequestParameterName: 'asiakasIdt',
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohde',
            MultipleSelected: 'työkohdetta',
        },
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        IdentifierUrl: '/Asiakas/WorkUnitIndustry/Identifier',
        ValuePickerId: 'IndustryWorkUnitIndustry_V3',
        Label: 'Työkohteen toimiala',
        OptionsEndpointUrl: '/Asiakas/WorkUnitIndustry/Identifier',
        ValuePickerType: EValuePickerType.DropdownV3,
        DataRequestParameterName: 'WorkUnitIndustryIds',
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Text: {
            FetchError: 'Työkohteiden toimialojen haku epäonnistui',
            Label: 'Työkohteiden toimialat',
            MultipleSelected: 'työkohteen toimialaa',
        },

        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        IdentifierUrl: '/Ammattinimike/Identifier',
        ValuePickerId: 'JobTitle_V3',
        Label: 'Ammattinimike',
        ValuePickerType: EValuePickerType.DropdownV3,
        DataRequestParameterName: 'ammattinimikeIdt',
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'Pool_V3',
                ParameterName: 'PoolIdList',
            },
        ],
        ItemOptionLabelFields: ['Id', 'Name'],
        IdentifierUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'Employee_V3',
        Label: 'Työntekijä',
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerType: EValuePickerType.DropdownV3,
        DataRequestParameterName: 'tyontekijaIdt',
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijä',
            MultipleSelected: 'työntekijää',
        },
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'Workunit_V3',
                ParameterName: 'WorkUnitIds',
            },
            {
                ValuePickerId: 'Area_V3',
                ParameterName: 'AreaIds',
            },
        ],
        ItemOptionLabelFields: ['DisplayName'],
        IdentifierUrl: '/Pooli/Identifier',
        OptionsEndpointUrl: '/Pooli/Identifier',
        UseDynamicApiSearch: true,
        ValuePickerId: 'Pool_V3',
        Label: 'Pooli',
        ValuePickerType: EValuePickerType.DropdownV3,
        DataRequestParameterName: 'poolIds',
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
        Text: {
            FetchError: 'Poolien haku epäonnistui',
            Label: 'Pooli',
            MultipleSelected: 'poolia',
        },
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Alue/Identifier',
        ValuePickerId: 'WorkforceScheduling_Calendar_Filter_Domain',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'alueIdt',
        Text: {
            FetchError: 'Alueiden haku epäonnistui',
            Label: 'Alue',
            MultipleSelected: 'aluetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        valueFieldType: 'chip',
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'WorkforceScheduling_Calendar_Filter_Domain', ParameterName: 'AlueIdt' }],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'WorkforceScheduling_Calendar_Filter_WorkUnit',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohde',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 200,
        valueFieldType: 'chip',
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'WorkforceScheduling_Calendar_Filter_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tes/Identifier',
        ValuePickerId: 'WorkforceScheduling_Calendar_Filter_Cla',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tesIdt',
        valueFieldType: 'chip',
        Text: {
            FetchError: 'Tessien haku epäonnistui',
            Label: 'TES',
            MultipleSelected: 'tessiä',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'WorkforceScheduling_Calendar_Filter_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
        ],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        valueFieldType: 'chip',
        OptionsEndpointUrl: '/Ammattinimike/Identifier',
        ValuePickerId: 'WorkforceScheduling_Calendar_Filter_JobTitle',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'ammattinimikeIdt',
        Text: {
            FetchError: 'Ammattinimikkeiden haku epäonnistui',
            Label: 'Ammattinimike',
            MultipleSelected: 'ammattinimikettä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'WorkforceScheduling_Calendar_Filter_Pool',
                ParameterName: 'pooliIdt.IdList',
                AdditionalParams: { 'pooliIdt.FilterType': 'Any' },
            },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        valueFieldType: 'chip',
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'WorkforceScheduling_Calendar_Filter_Employees',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'WorkforceScheduling_PlanningPeriods', ParameterName: 'SuunnittelujaksoVersioIdt' },
            { ValuePickerId: 'ParentEvent', ParameterName: 'ParentId' },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'WorkforceScheduling_Event_Form_WorkUnit',
        ValuePickerType: EValuePickerType.DropdownV2,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohde',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
        multiselect: false,
    },
    {
        DependsOn: [
            { ValuePickerId: 'WorkforceScheduling_PlanningPeriods', ParameterName: 'SuunnittelujaksoVersioIdt' },
            {
                ValuePickerId: 'WorkforceScheduling_Event_Form_WorkUnit',
                ParameterName: 'asiakasIdt.IdList',
                AdditionalParams: { 'asiakasIdt.FilterType': 'Any' },
            },
            {
                ValuePickerId: 'WorkforceScheduling_Event_Form_Employees',
                ParameterName: 'tyontekijaIdt.IdList',
                AdditionalParams: { 'tyontekijaIdt.FilterType': 'Any' },
            },
            { ValuePickerId: 'ParentEvent', ParameterName: 'ParentId' },
        ],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Ammattinimike/Identifier',
        ValuePickerId: 'WorkforceScheduling_Event_Form_JobTitle',
        ValuePickerType: EValuePickerType.DropdownV2,
        DataRequestParameterName: 'ammattinimikeIdt',
        Text: {
            FetchError: 'Ammattinimikkeiden haku epäonnistui',
            Label: 'Ammattinimike',
            MultipleSelected: 'ammattinimikettä',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        DefaultValues: null,
        multiselect: false,
        AdditionalDataFetchParameters: {
            Filters: { GetSkillDescriptions: true },
        },
    },
    {
        DependsOn: [
            { ValuePickerId: 'WorkforceScheduling_PlanningPeriods', ParameterName: 'SuunnittelujaksoVersioIdt' },
            {
                ValuePickerId: 'IncludeEmployeeIdentifiersForEventIds',
                ParameterName: 'IncludeEmployeeIdentifiersForEventIds',
            },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'WorkforceScheduling_Event_Form_Employees',
        ValuePickerType: EValuePickerType.DropdownV2,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            { ValuePickerId: 'WorkforceScheduling_PlanningPeriods', ParameterName: 'SuunnittelujaksoVersioIdt' },
            {
                ValuePickerId: 'IncludeEmployeeIdentifiersForEventIds',
                ParameterName: 'IncludeEmployeeIdentifiersForEventIds',
            },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'WorkforceScheduling_Event_Form_Active_Employees',
        ValuePickerType: EValuePickerType.DropdownV2,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        AdditionalDataFetchParameters: {
            Filters: { StatusIdt: [1] },
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'WorkforceScheduling_Calendar_Filter_WorkUnit',
                ParameterName: 'WorkUnitIds',
            },
        ],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Pooli/Identifier',
        ValuePickerId: 'WorkforceScheduling_Calendar_Filter_Pool',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolIds',
        Text: {
            FetchError: 'Poolien haku epäonnistui',
            Label: 'Poolit',
            MultipleSelected: 'poolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: true,
        valueFieldType: 'chip',
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Name'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Customer/Identifier',
        ValuePickerId: 'WorkforceScheduling_Event_Form_Customer',
        ValuePickerType: EValuePickerType.DropdownV2,
        DataRequestParameterName: 'CustomerId',
        Text: {
            FetchError: 'Asiakkaiden haku epäonnistui',
            Label: 'Asiakas',
            MultipleSelected: 'Asiakasta',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['Name'],
        UseDynamicApiSearch: false,
        Limit: 200,
        ExtraRows: 'Include',
        DefaultValues: null,
        multiselect: false,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Name'],
        IdentifierUrl: '/Asiakas/WorkUnitGroup/Identifier',
        ValuePickerId: 'WorkUnitGroup_FilterV3',
        Label: 'Organisaatio',
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerType: EValuePickerType.DropdownV3,
        DataRequestParameterName: 'WorkUnitGroupIds',
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Text: {
            FetchError: 'Organisaatioiden haku epäonnistui',
            Label: 'Organisaatio',
            MultipleSelected: 'Organisaatiota',
        },
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
]

export default suunnittelujaksoValuePickers
