import makeRequest, { ERequestMethod } from '../../rest-api'

const TAPAHTUMA_SET_ADDITIONAL_INFO_ACKNOWLEDGEMENT_STATUS = '/Tapahtuma/Lisatietokuittausmuutokset'
export const setAdditionalInfoAcknowledgementStatus = async (
    acknowledge: boolean,
    tapahtumaIds: string[]
): Promise<void> =>
    makeRequest({
        method: ERequestMethod.POST,
        url: TAPAHTUMA_SET_ADDITIONAL_INFO_ACKNOWLEDGEMENT_STATUS,
        params: { kuitattu: acknowledge },
        data: { tapahtumaIdt: tapahtumaIds },
    })
