import Chip, { ChipProps } from '@material-ui/core/Chip'
import * as React from 'react'
import styled from '@emotion/styled'

interface IOwnProps {
    isDisabled?: boolean
    label: string
    mainColor: string
    supportColor: string
    selected?: boolean
    setSelected?: (selected: boolean) => void
    size: 'small' | 'medium'
    onDelete?: () => void
}

const StyledChip = styled<React.FC<IOwnProps & ChipProps>>(
    ({ mainColor, isDisabled, setSelected, supportColor, ...props }) => <Chip {...props} />
)`
    background-color: #ffffff;
    border: 1px solid ${(props) => props.mainColor};
    color: ${(props) => props.mainColor};
    font-family: Inter, arial;
    font-size: 14px;
    font-weight: 500;
    min-width: 60px;
    max-width: 180px;

    ${(props) =>
        props.selected &&
        `
            background-color: ${props.mainColor};
            color: #ffffff;
        `}

    ${(props) =>
        props.isDisabled &&
        `
            pointer-events: none;
        `}

    & .MuiChip-label {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & .MuiChip-deleteIcon {
        color: ${(props) => props.supportColor};
    }

    :focus {
        background-color: #ffffff;
        border: 1px solid ${(props) => props.mainColor};
        color: ${(props) => props.mainColor};

        ${(props) =>
            props.selected &&
            `
                background-color: ${props.mainColor};
                color: #ffffff;
            `}
    }
`

const Tag: React.FunctionComponent<IOwnProps> = ({
    isDisabled = false,
    label,
    mainColor,
    supportColor,
    selected = false,
    setSelected,
    size = 'medium',
    onDelete,
}) => {
    const handleClick = () => {
        setSelected && setSelected(!selected)
    }

    const handleDelete = () => {
        onDelete && onDelete()
    }

    return (
        <StyledChip
            isDisabled={isDisabled}
            label={label}
            mainColor={mainColor}
            onClick={handleClick}
            onDelete={selected ? handleDelete : undefined}
            selected={selected}
            setSelected={setSelected}
            size={size}
            supportColor={supportColor}
        />
    )
}

export default Tag
