import * as React from 'react'

import MoreButton from './MoreButton'
import { MenuWithItems } from '../../Menu'

type TMenuWithItemsPropsLimited = Omit<React.ComponentProps<typeof MenuWithItems>, 'children'>

type TMoreButtonProps = React.ComponentProps<typeof MoreButton>

interface IMoreButtonWithMenuProps extends TMenuWithItemsPropsLimited, Pick<TMoreButtonProps, 'className' | 'icon'> {}

const MoreButtonWithMenu: React.FC<IMoreButtonWithMenuProps> = ({ className, icon, ...menuWithItemsProps }) => {
    return (
        <MenuWithItems {...menuWithItemsProps}>
            {(onMenuOpen) => <MoreButton className={className} icon={icon} onClick={onMenuOpen} />}
        </MenuWithItems>
    )
}

export default MoreButtonWithMenu
