import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import styled from '@emotion/styled'

import ListContainerGrid from '../ListContainerGrid'
import ListActionButtons from '../ListActionButtons'
import { selectIsListAutoFetchDataEnabled, selectListViewFilterIds } from '../../State/ConfigurableListSelectors'
import ListFetchButton from '../ListFetchButton'
import { TValuePickerId, ValuePickerGroup } from '../../../value-picker'
import ListExcelButton from '../ListExcelExportButton/index'
import { difference } from 'lodash-es'

export interface IOwnProps {
    accumulatedData?: React.ReactNode
    additionalButtons?: React.ReactNode
    isValuePickerGroupFullWidth?: boolean
    listId: string
    omitFilterIds?: TValuePickerId[]
    valuePickersVisibilityTogglable?: boolean
    renderWithinPaper?: boolean
    showExcelButton?: boolean
    onAfterVisibilityWasToggled?: () => void
    onModalSubmitCallback?: () => void
}

interface IStateProps {
    filterIds: TValuePickerId[]
    isAutoFetchEnabled: boolean
}

interface IButtonsWithValuePickersProps extends IOwnProps, IStateProps {}

interface IContainerProps {
    renderWithinPaper: boolean
    paperProps: PaperProps
}

const Container = styled<React.FC<IContainerProps>>(({ renderWithinPaper, paperProps, ...rest }) =>
    renderWithinPaper ? <Paper {...paperProps} {...rest} /> : <div {...rest} />
)`
    margin: 0 8px 8px 8px;
`

const ButtonsWithValuePickersUnconnected: React.FC<IButtonsWithValuePickersProps> = ({
    accumulatedData,
    additionalButtons,
    filterIds,
    isAutoFetchEnabled,
    isValuePickerGroupFullWidth,
    listId,
    renderWithinPaper = false,
    valuePickersVisibilityTogglable = false,
    showExcelButton = false,
    onAfterVisibilityWasToggled,
    onModalSubmitCallback,
}) => {
    const fetchContentButton = !isAutoFetchEnabled && <ListFetchButton listId={listId} />

    return (
        <Container paperProps={{ elevation: 2 }} renderWithinPaper={renderWithinPaper}>
            <ValuePickerGroup
                fetchContentButton={fetchContentButton}
                isFullWidth={isValuePickerGroupFullWidth}
                onAfterVisibilityWasToggled={onAfterVisibilityWasToggled}
                valuePickerIds={filterIds}
                visibilityTogglable={valuePickersVisibilityTogglable}
            />
            <ListContainerGrid
                accumulatedData={accumulatedData}
                buttons={
                    <React.Fragment>
                        <ListActionButtons listId={listId} onModalSubmitCallback={onModalSubmitCallback} />
                        {additionalButtons}
                    </React.Fragment>
                }
                excelButton={showExcelButton && <ListExcelButton listId={listId} />}
            />
        </Container>
    )
}

const mapStateToProps = (state: RootState, { listId, omitFilterIds = [] }: IOwnProps) => ({
    isAutoFetchEnabled: selectIsListAutoFetchDataEnabled(state, listId),
    filterIds: difference(selectListViewFilterIds(state, listId), omitFilterIds),
})

export default connect(mapStateToProps)(ButtonsWithValuePickersUnconnected)
