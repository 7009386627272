import * as React from 'react'

import { Color } from 'constants/Styles'
import { TextBoxWithBackgroundColor } from '../../generic-components'
import { useAppTheme } from '../../theme'

// eslint-disable-next-line no-shadow
enum EEventOfferResponseStatus {
    Pending = 1,
    Declined = 2,
    Accepted = 3,
}

export interface IIListElementOfferResponseStatus {
    value: EEventOfferResponseStatus
}

const ListElementOfferResponseStatus: React.FunctionComponent<IIListElementOfferResponseStatus> = ({ value }) => {
    const { colors, eventTemplates } = useAppTheme()

    let color: Color | string = 'event_transition'
    let backgroundColor: Color | string = 'event_transition_2'
    let borderColor = null

    let text = 'ODOTTAA'

    switch (value) {
        case EEventOfferResponseStatus.Declined:
            color = eventTemplates.primary.event4
            backgroundColor = eventTemplates.secondary.event4
            borderColor = colors.secondaryPink100
            text = 'HYLÄTTY'
            break
        case EEventOfferResponseStatus.Accepted:
            color = colors.semanticSuccess120
            backgroundColor = colors.semanticSuccess11
            borderColor = colors.semanticSuccess11
            text = 'HYVÄKSYTTY'
            break

        case EEventOfferResponseStatus.Pending:
        default:
            color = eventTemplates.primary.event2
            backgroundColor = eventTemplates.secondary.event2
            borderColor = '#b6d0ff'
            text = 'ODOTTAA'
            break
    }

    if (!value) {
        return null
    }

    return (
        <TextBoxWithBackgroundColor
            borderColor={borderColor || color}
            text={text}
            backgroundColor={backgroundColor}
            textColor={color}
        />
    )
}

export default ListElementOfferResponseStatus
