import { useEffect, useMemo, useRef } from 'react'
import { debounce } from 'lodash-es'

const useDebounce = (callback: () => void, timeout = 600): (() => void) => {
    const ref = useRef<any>()

    useEffect(() => {
        ref.current = callback
    }, [callback])

    return useMemo(() => {
        const func = () => {
            ref.current?.()
        }

        return debounce(func, timeout)
    }, [timeout])
}

export default useDebounce
