const employeeCalendarTranslations = {
    employeeCalendar: {
        addNewEvent: {
            fi: 'Lisää tapahtuma',
            sv: '',
            en: 'Add event',
            ru: '',
            sk: '',
            pl: '',
        },
        noSelectedDays: {
            fi: 'Ei valittuja päiviä',
            sv: '',
            en: 'No selected days',
            ru: '',
            sk: '',
            pl: '',
        },
        selectedDaysEvents: {
            fi: 'Valittujen päivien tapahtumat',
            sv: '',
            en: 'Selected days events',
            ru: '',
            sk: '',
            pl: '',
        },
        noWorkUnit: {
            fi: 'Ei työkohdetta',
            sv: '',
            en: 'No workunit',
            ru: '',
            sk: '',
            pl: '',
        },
        noEvents: {
            fi: 'Ei tapahtumia',
            sv: '',
            en: 'No events',
            ru: '',
            sk: '',
            pl: '',
        },
        selectionInfo: {
            fi: 'Klikkaa kalenteria nähdäksesi päivän tapahtumat. Valitse aikaväli klikkaamalla ja pitämällä Shift-näppäintä pohjassa samaan aikaan. Valitse useita yksittäisiä päiviä klikkaamalla ja pitämällä Ctrl- tai Cmd-näppäintä pohjassa samaan aikaan.',
            sv: '',
            en: "Click on the calendar to see the day's events. Select a time range by clicking and holding down the Shift key at the same time. Select multiple individual days by clicking and holding down the Ctrl or Cmd key at the same time.",
            ru: '',
            sk: '',
            pl: '',
        },
    },
}

export default employeeCalendarTranslations
