import { RootState } from 'typesafe-actions'
import modalReducer from '../State/ModalReducer'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getModalReducerMap = () => ({
    modal: modalReducer,
})

export const getModalStoreState = (): Partial<RootState> => {
    const modalState: RootState['modal'] = new Map()
    return { modal: modalState }
}
