import isMobile from 'ismobilejs'
import EOperatingSystem from './Types/EOperatingSystem'

export const browserIsOnAppleDevice = (): boolean => {
    return isMobile().apple.device
}

export const browserIsOnAndroidDevice = (): boolean => {
    return isMobile().android.device
}

export const getDeviceOperatingSystem = (): EOperatingSystem => {
    let operatingSystem: EOperatingSystem = EOperatingSystem.Other
    if (browserIsOnAndroidDevice()) {
        operatingSystem = EOperatingSystem.Android
    } else if (browserIsOnAppleDevice()) {
        operatingSystem = EOperatingSystem.iOS
    }
    return operatingSystem
}

export const browserIsOnMobileDevice = (): boolean => {
    const isMobileDevice = isMobile().phone || isMobile().tablet
    return isMobileDevice
}
