import { CancelTokenSource } from 'axios'
import { RootState } from 'typesafe-actions'

import IAsyncOperationState from '../Types/IAsyncOperationState'

const getOperationState = (state: RootState, id: string) => state.asyncOperationState?.get(id)

export const selectIsAsyncOperationIsInProgress = (state: RootState, id: string): boolean => {
    const operationState = getOperationState(state, id)

    return operationState ? operationState.isInProgress : false
}

export const selectAsyncOperationCancellationToken = (state: RootState, id: string): CancelTokenSource | null => {
    const operationState = getOperationState(state, id)

    return operationState && operationState.cancelTokenSource ? operationState.cancelTokenSource : null
}

export const selectIsAsyncOperationIsReady = (state: RootState, id: string): boolean => {
    const operationState = getOperationState(state, id)

    return operationState ? operationState.ready : false
}

export const selectAsyncOperationError = (state: RootState, id: string): Error | null => {
    const operationState = getOperationState(state, id)

    return operationState ? operationState.error : null
}

export const selectAsyncOperationOtherData = (state: RootState, id: string): IAsyncOperationState['other'] => {
    const operationState = getOperationState(state, id)

    return operationState ? operationState.other : null
}
