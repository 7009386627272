import * as React from 'react'
import styled from '@emotion/styled'

interface IRowTextProps {
    checkboxSelectable: boolean
    rowItemComponentInstance: JSX.Element
}

const Container = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export default class RowText extends React.Component<IRowTextProps> {
    render(): React.ReactNode {
        const { rowItemComponentInstance } = this.props

        return <Container>{rowItemComponentInstance}</Container>
    }
}
