import ILoginKayttooikeudet from './ILoginKayttooikeudet'

export enum EUserType {
    AktivoimatonTyonhakija = -1,
    Tyonhakija = 1,
    Tyontekija = 2,
    AsiakkaanYhteyshenkilo = 3,
    Toiminnanohjauskayttaja = 4,
    Jarjestelmakayttaja = 5,
}

export default interface ILoginKayttaja {
    readonly Etunimi: string
    readonly Id: number
    readonly Kieli: number | string
    readonly Osio: number
    readonly Sahkoposti: string
    readonly Sukunimi: string
    readonly Tunnus: string | number
    readonly Tyyppi: EUserType
    readonly Kayttooikeudet: ILoginKayttooikeudet
    readonly IsExternalUser: boolean
}
