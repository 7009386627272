import { useEffect, useRef } from 'react'
import * as React from 'react'
import Tab, { TabProps } from '@material-ui/core/Tab'
import Tabs, { TabsActions } from '@material-ui/core/Tabs'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import Styles from 'constants/Styles'
import URL from 'common/URL'
import INavigationItem from '../../Types/INavigationItem'
import { selectCurrentPageNavigationItems } from '../../State/NavigationSelectors'

interface IStateProps {
    items: INavigationItem[]
}

interface ITabsOnNavBarProps extends IStateProps {}

const StyledTabs = styled(Tabs)`
    justify-content: center;
    margin: 0 ${Styles.layout.tab.marginSides};

    & .MuiTabs-indicator {
        height: 3px;
        background-color: ${Styles.mainColor.white};
    }

    & .MuiTabs-flexContainer {
        height: 100%;
    }
`

const StyledTab = styled<React.FC<TabProps & { component: string; href: string }>>(Tab)`
    display: flex;
    font-family: ${Styles.layout.tab.fontFamily};
    font-size: ${Styles.layout.tab.fontSize};
    justify-content: center;
    min-width: ${Styles.layout.tab.width};
    color: ${Styles.mainColor.white};
    padding: 6px 12px;
`

const TabsOnNavBar: React.FC<ITabsOnNavBarProps> = ({ items }) => {
    const currentPagePath = window.location.href.split(window.location.origin + '/')[1]

    const activeTab = items.find((item) => {
        return item.url.includes(currentPagePath)
    })

    const tabsActionRef = useRef<TabsActions>(null)
    useEffect(() => {
        if (tabsActionRef.current) {
            tabsActionRef.current.updateIndicator()
        }
    }, [items])

    return (
        <StyledTabs action={tabsActionRef} value={activeTab?.url ?? false}>
            {items.map(({ label, url }) => (
                <StyledTab component="a" href={URL.oldClient(url)} key={url} label={label} value={url} />
            ))}
        </StyledTabs>
    )
}

const mapStateToProps = (state: RootState): IStateProps => {
    return {
        items: selectCurrentPageNavigationItems(state),
    }
}

export default connect(mapStateToProps)(TabsOnNavBar)
