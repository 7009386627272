import * as React from 'react'
import styled from '@emotion/styled'
import MaterialUiIcon, { IconProps as MaterialUiIconProps } from '@material-ui/core/Icon'

import Tooltip from '../Tooltip/Tooltip'
import Styles from 'constants/Styles'
import { isSvgIcon } from '../../../icons'
import SvgIcon from './SvgIcon'

type TTooltipProps = React.ComponentProps<typeof Tooltip>

interface IIconProps {
    children: string
    tooltip?: TTooltipProps['title']
    backgroundColor?: string
    colorTheme?: MaterialUiIconProps['color']
    color?: string
    onClick?: (event: React.MouseEvent) => void | Promise<void>
    className?: string
    size?: number
    disabled?: boolean
    testId?: string
    tooltipChildWrapperElement?: TTooltipProps['childWrapperElement']
    hideIfNoTooltip?: boolean
    leaveDelay?: number
}

const StyledIcon = styled<React.FC<Omit<MaterialUiIconProps, 'color'> & IIconProps>>(
    ({ disabled, backgroundColor, color, colorTheme, size, ...props }) => (
        <MaterialUiIcon color={colorTheme} {...props} />
    )
)`
    && {
        ${({ disabled, backgroundColor }) =>
            (disabled || backgroundColor) &&
            `
        background-color: ${disabled ? Styles.supplementaryColor.buttonDisabled : backgroundColor};
    `}

        font-size: ${({ size }) => size}px;
        color: ${({ color }) => color};

        ${({ onClick }) =>
            onClick &&
            `
        cursor: pointer;
    `}
    }

    transition: color ${({ theme }) => theme.tokens.transitionQuick};
`

const Icon: React.FC<IIconProps> = ({
    backgroundColor,
    children,
    tooltip,
    color,
    colorTheme,
    onClick,
    className,
    leaveDelay,
    disabled = false,
    size = 19.6,
    testId,
    tooltipChildWrapperElement = 'div',
    hideIfNoTooltip = false,
}) => {
    const handleClick = (event: React.MouseEvent) => {
        if (!disabled && onClick) {
            onClick && onClick(event)
        } else if (disabled) {
            // In case an icon container has a click handler, clicking a disabled icon
            // would call that even though it'd probabl not be very logical from the user's point of view
            // (for example activating a list row). So stopping the bubbling here but not calling the
            // click handler, we'll ensure that doesn't happen.
            event.stopPropagation()
        }
    }

    if (isSvgIcon(children)) {
        return <SvgIcon icon={children} color={color} />
    }

    if (!hideIfNoTooltip || tooltip) {
        return (
            <Tooltip childWrapperElement={tooltipChildWrapperElement} title={tooltip} leaveDelay={leaveDelay}>
                <StyledIcon
                    backgroundColor={backgroundColor}
                    className={className}
                    color={color}
                    colorTheme={colorTheme}
                    data-testid={testId}
                    disabled={disabled}
                    onClick={onClick ? handleClick : undefined}
                    size={size}
                >
                    {children}
                </StyledIcon>
            </Tooltip>
        )
    }

    return null
}

export default Icon
