import moment from 'moment'

const KeyPlaceholderPrefix = '$'
const ModuleNameSeparator = ': '

function shouldInsert(parameters: unknown): parameters is unknown[] {
    return Array.isArray(parameters) && parameters.length > 0
}

function ensureIsArray(parameters: unknown): unknown[] {
    return Array.isArray(parameters) ? parameters : [parameters]
}

function parseArrayParameter(parameter: unknown[]): string {
    const values: string[] = []
    for (let i = 0; i < parameter.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        values.push(parse(parameter[i]))
    }
    return '( ' + values.join(', ') + ' )'
}

function parseObjectParameter(parameter: Record<string, unknown>): string {
    const keyValuePairs: string[] = []

    for (const key in parameter) {
        const value = parameter[key]
        keyValuePairs.push(key + '=' + value)
    }
    return '{ ' + keyValuePairs.join('; ') + ' }'
}

function parse(parameter: unknown): string {
    if (parameter === undefined) {
        return '<undefined>'
    } else if (parameter === null) {
        return '<null>'
    } else if (Array.isArray(parameter)) {
        return parseArrayParameter(parameter)
    } else if (typeof parameter === 'string') {
        return parameter
    } else if (moment.isMoment(parameter)) {
        return parameter.toString()
    } else if (typeof parameter === 'object') {
        return parseObjectParameter(parameter as Record<string, unknown>)
    } else if ((parameter as any).toString) {
        return (parameter as any).toString()
    } else {
        // eslint-disable-next-line no-console
        console.error('type not handled')
        return ''
    }
}

function insertInto(message: string, parameters: unknown[]): string {
    const paramsAsArray = ensureIsArray(parameters)
    let replacedMessage = message
    for (let i = 0; i < paramsAsArray.length; i++) {
        const key = KeyPlaceholderPrefix + i
        const value = parse(paramsAsArray[i])
        if (message.indexOf(key) > -1) {
            replacedMessage = replacedMessage.replace(key, value)
        }
    }
    return replacedMessage
}

/* eslint-disable no-console */
class Log {
    private _moduleName: string
    private _muted: boolean

    private static _muteLoggers = false
    private static _nonMutedLoggers: Set<string> = new Set()

    constructor(moduleName: string) {
        this._moduleName = moduleName
        this._muted = false
    }

    static muteAll(): void {
        Log.muteAllExcept()
    }

    static unmuteAll(): void {
        Log._muteLoggers = false
    }

    static muteAllExcept(...nonMuted: string[]): void {
        Log._muteLoggers = true
        Log._nonMutedLoggers = new Set(nonMuted)
    }

    debug(message: string, ...parametersToInsertIntoMessage: unknown[]): void {
        this.log(console.log, message, parametersToInsertIntoMessage)
    }

    error(message: string, ...parametersToInsertIntoMessage: unknown[]): void {
        this.log(console.error, message, parametersToInsertIntoMessage)
    }

    mute(): void {
        this._muted = true
    }

    warn(message: string, ...parametersToInsertIntoMessage: unknown[]): void {
        this.log(console.warn, message, parametersToInsertIntoMessage)
    }

    private log(loggingMethod: () => void, message: string, parameters: unknown): void {
        const isMuted = this._muted || (Log._muteLoggers && !Log._nonMutedLoggers.has(this._moduleName))

        if (!isMuted) {
            const logMessage = shouldInsert(parameters) ? insertInto(message, parameters) : message
            loggingMethod.call(console, this._moduleName + ModuleNameSeparator + logMessage)
        }
    }
}
/* eslint-enable no-console */

/**
 * Get a logger instance. Use for example:
 * ```
 * const Log = getLogger('some-module-name.FileNameInModule)
 * Log.debug('debug message')
 * ```
 */
export const getLogger = (loggingModule: string): Log => {
    const logger = new Log(loggingModule)

    return logger
}

/**
 * Returns the Log class itself for static use (for example to mute things).
 *
 * Don't use this to get the class for creating an instance of it, but use
 * `getLogger` to get the instance.
 */
export const getLogStatic = (): typeof Log => {
    return Log
}
