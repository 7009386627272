import PropTypes from 'prop-types'
import { Component } from 'react'
import TimeInputUtility from '../TimeInputUtility'

import { AVAILABLE_HOURS, AVAILABLE_MINUTES } from '../TimeInputUtility'
import Styles from 'constants/Styles'
import styled from '@emotion/styled'

const ContainerGrid = styled.div`
    background-color: ${Styles.mainColor.white};
    border: 1px solid ${Styles.supplementaryColor.basicGrey};
    position: absolute;
    z-index: ${Styles.layer.dropdownMenu};
    width: 114px;
    display: flex;
`

const TimeUnitColumnGrid = styled.div`
    font-size: 14px;
    border-left: 1px solid #e8e8e8;
    box-sizing: border-box;
    width: 56px;
    overflow: hidden;
    position: relative;
    max-height: 192px;

    :hover {
        overflow-y: scroll;
    }
`

const TimeUnitList = styled.ul`
    list-style-type: none;
    list-style: none;
    box-sizing: border-box;
    margin: 0px;
    width: 100%;
    padding-left: 0px;
    padding-bottom: 160px;
`

const TimeUnitItem = styled.li`
    list-style: none;
    box-sizing: content-box;
    margin: 0px;
    padding-left: 12px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    user-select: none;
    transition: background 0.3s;
    line-height: 32px;
    height: 32px;

    :hover {
        background-color: #e6f7ff;
    }
`

/**
 * Ottaa vastaan ja lähettää Momentteja. Käytetty komponentti desktopissa ei
 * ole käyttökelpoinen mobiilissa, joten tehdään mobiiliselaimille hieman eri
 * tavalla.
 */
export default class TimeDropdownMenu extends Component {
    renderHours() {
        return this.renderTimeUnits(AVAILABLE_HOURS, this.valitseTunti)
    }

    renderMinutes() {
        return this.renderTimeUnits(AVAILABLE_MINUTES, this.valitseMinuutit)
    }

    renderTimeUnits(timeUnits, selectFunction) {
        return (
            <TimeUnitColumnGrid item>
                <TimeUnitList>
                    {timeUnits.map((timeUnit, index) => this.renderTimeUnit(timeUnit, selectFunction, index))}
                </TimeUnitList>
            </TimeUnitColumnGrid>
        )
    }

    valitseAika(e, taydenAjanMuodostusFunktio) {
        const valittuAika = e.target.textContent
        const { currentUserInput, rawValueObject } = this.props

        let aikaTaydennettyna = TimeInputUtility[taydenAjanMuodostusFunktio](currentUserInput, valittuAika)

        aikaTaydennettyna = TimeInputUtility.createMomentAndAddDateInfoIfPossible(
            aikaTaydennettyna,
            rawValueObject,
            this.props.originalDateTimeObject
        )

        this.props.onChange(aikaTaydennettyna)
    }

    valitseTunti = (e) => {
        this.valitseAika(e, 'formTimeFromGivenHours')
    }

    valitseMinuutit = (e) => {
        this.valitseAika(e, 'formTimeFromGivenMinutes')
    }

    renderTimeUnit(timeUnit, selectFunction, index) {
        return (
            <TimeUnitItem key={index} onClick={selectFunction}>
                {timeUnit}
            </TimeUnitItem>
        )
    }

    render() {
        return (
            <ContainerGrid container>
                {this.renderHours()}
                {this.renderMinutes()}
            </ContainerGrid>
        )
    }
}

TimeDropdownMenu.displayName = 'TimeDropdownMenu'

TimeDropdownMenu.propTypes = {
    currentUserInput: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    rawValueObject: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    width: PropTypes.string,
    originalRawTimeValue: PropTypes.any,
}

TimeDropdownMenu.defaultProps = {
    width: '100px',
}
