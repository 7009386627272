import { Dispatch } from 'redux'
import { RootState } from 'typesafe-actions'
import { isEqual, uniqWith } from 'lodash-es'
import ICalendarAction from '../Types/ICalendarAction'
import { EActionFunctionalityType, getActionPermissions, handleViewEngineActionThunk } from '../../view-engine'
import { IDataSourceItem } from '../../data-source-types'
import { IMenuItemProps } from '../../generic-components'
import { selectCalendarDataTemplates, selectSelectedNodeObjects } from '../State/ConfigurableCalendarSelectors'
import { ICalendarDataTemplate, IGroupData } from '../Types/ICalendarDataTemplate'

const handleNodeActionClick = (
    calendarId: string,
    dispatch: Dispatch<any>,
    items: IDataSourceItem[],
    nodeFunctionality: ICalendarAction['Functionality'],
    dataSourceId: string | null,
    additionalData?: any
) => {
    dispatch(
        handleViewEngineActionThunk({
            items,
            functionalityAction: nodeFunctionality,
            dataSourceId,
            additionalRequestData: additionalData,
        })
    )
}

type TSyncThunk<T> = (dispatch: unknown, getState: () => RootState) => T
/**
 * Though this is a thunk, we only use it to access data on the fly to avoid unnecessary rendering. The
 * alternative to this would be to use `useSelector` hook (along with the `selectSelectedNodeObjects`
 * selector), but that would also cause the component using the hook to render any time the selected
 * nodes change. To avoid that we can dispatch this thunk to access the data specifically when we
 * want the selected calendar nodes.
 */
const getCurrentlySelectedItemsThunk =
    (calendarId: string): TSyncThunk<IDataSourceItem[]> =>
    (_dispatch, getState) => {
        const nodeObjects = selectSelectedNodeObjects(getState(), calendarId)

        return nodeObjects
    }

const selectedCalendarDataTemplates =
    (calendarId: string): TSyncThunk<ICalendarDataTemplate[]> =>
    (_dispatch, getState) => {
        const templates = selectCalendarDataTemplates(getState(), calendarId)

        return templates
    }

/**
 * Utility function to get actions for calendar nodes.
 * Accesses the state so can only be used within components (or hooks).
 */

export const getAllActionObjectsForMenu = (
    dispatch: Dispatch<any>,
    calendarId: string,
    nodeActions: ICalendarAction[],
    dataSourceId: string | null
): IMenuItemProps[] => {
    const selectedNodes = dispatch(getCurrentlySelectedItemsThunk(calendarId)) as unknown as IDataSourceItem[]

    const actionItems: IMenuItemProps[] = whichActionsToDisplay(
        dispatch,
        calendarId,
        dataSourceId,
        nodeActions,
        selectedNodes
    )

    return actionItems
}

const whichActionsToDisplay = (
    dispatch: Dispatch<any>,
    calendarId: string,
    dataSourceId: string | null,
    allActions: ICalendarAction[],
    selectedNodes: IDataSourceItem[]
): IMenuItemProps[] => {
    const actionsToDisplay = allActions
        .map(({ Display, Functionality, RequiresMultipleItems }) => {
            const actionPermissions = getActionPermissions(selectedNodes, Functionality)

            const show = actionPermissions?.isDefined ?? false

            if (!show) {
                return null
            }

            let isDisabled = !actionPermissions?.isAllowed ?? false
            let isDisabledDueToMultipledItemRequirement = false
            if (RequiresMultipleItems && selectedNodes?.length < 2) {
                isDisabled = true
                isDisabledDueToMultipledItemRequirement = true
            }

            const disableReason = isDisabled
                ? actionPermissions?.disabledReason
                    ? [...actionPermissions.disabledReason]
                    : []
                : null

            if (isDisabledDueToMultipledItemRequirement) {
                disableReason.push('Toiminto vaatii yli yhden kohteen')
            }

            return {
                label: Display.Label,
                onClick: () => handleNodeActionClick(calendarId, dispatch, selectedNodes, Functionality, dataSourceId),
                icon: Display.Icon,
                isDisabled,
                tooltip: disableReason,
            }
        })
        .filter((x) => x !== null)

    return actionsToDisplay
}

export const getActionObjectsForMenu = (
    dispatch: Dispatch<any>,
    calendarId: string,
    nodeActions: ICalendarAction[],
    dataSourceId: string | null
): IMenuItemProps[] => {
    // It seems like the dispatch function is typed wrong so do typecast here to correct it
    const selectedNodes = dispatch(getCurrentlySelectedItemsThunk(calendarId)) as unknown as IDataSourceItem[]

    const primaryActions = nodeActions.filter((x) => x.IsPrimaryAction)
    const secondaryActions = nodeActions.filter((x) => !x.IsPrimaryAction)

    const primaryActionsItems: IMenuItemProps[] = whichActionsToDisplay(
        dispatch,
        calendarId,
        dataSourceId,
        primaryActions,
        selectedNodes
    )

    const secondaryActionItems: IMenuItemProps[] = whichActionsToDisplay(
        dispatch,
        calendarId,
        dataSourceId,
        secondaryActions,
        selectedNodes
    )

    if (secondaryActionItems.length > 0) {
        return [
            ...primaryActionsItems,
            {
                label: 'Toiminnot', //TODO Localization
                subMenu: secondaryActionItems,
            },
        ]
    } else {
        return primaryActionsItems
    }
}

export const getTemplateActionObjectsForMenu = (
    dispatch: Dispatch<any>,
    calendarId: string,
    dataSourceId: string | null
): IMenuItemProps[] => {
    const selectedNodes = dispatch(getCurrentlySelectedItemsThunk(calendarId)) as unknown as IDataSourceItem[]

    const templates = dispatch(selectedCalendarDataTemplates(calendarId)) as unknown as ICalendarDataTemplate[]

    if (!templates || !selectedNodes) {
        return []
    }

    const currentlySelectedGroups = selectedNodes.map((x) => x as IGroupData)

    const distinctGroups = uniqWith(currentlySelectedGroups, isEqual) as IGroupData[]

    const functionality = {
        FunctionalityType: EActionFunctionalityType.Immediate,
        DataSourceActionId:
            dataSourceId === 'TyontekijanakymaDataSource'
                ? 'Tyontekijanakyma_Action_CreateEventsFromEventTemplate'
                : 'Tarvenakyma_Action_CreateEventsFromEventTemplate',
        PermissionId: 'CREATE',
    }

    const allowedFunctionalities =
        distinctGroups && distinctGroups.length > 0
            ? templates.filter((template) => {
                  return distinctGroups.every((group) => {
                      return (
                          (!group.WorkUnitId ||
                              (template.ApplicableWorkUnitIds &&
                                  template.ApplicableWorkUnitIds.find((x) => x === group.WorkUnitId))) &&
                          (!group.JobTitleId ||
                              (template.ApplicableJobTitleIds &&
                                  template.ApplicableJobTitleIds.find((x) => x === group.JobTitleId))) &&
                          (!group.EmployeeId ||
                              (template.ApplicableEmployeeIds &&
                                  template.ApplicableEmployeeIds.find((x) => x === group.EmployeeId)))
                      )
                  })
              })
            : []

    if (allowedFunctionalities.length > 0) {
        return [
            {
                label: 'Tapahtumapohjat', //TODO Localization
                subMenu: allowedFunctionalities.map((func) => {
                    const dataForTemplate = distinctGroups.map((group) => {
                        return {
                            EmployeeId: group.EmployeeId,
                            StartDate: group.Alkamisaika ?? group.StartTime,
                        }
                    })

                    return {
                        label: func.Name,
                        onClick: () =>
                            handleNodeActionClick(
                                calendarId,

                                dispatch,
                                [
                                    {
                                        Id: func.Id,
                                        Kayttooikeudet: [{ Toiminto: 'CREATE', OnkoSallittu: true, SyyKieltoon: null }],
                                    },
                                ],
                                functionality,
                                dataSourceId,
                                {
                                    TemplateIds: [func.Id],
                                    CreateParameters: dataForTemplate,
                                }
                            ),
                    }
                }),
            },
        ]
    } else {
        return []
    }
}
