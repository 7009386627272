import EValuePickerType from '../types/EValuePickerType'
import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import IValuePickerReducerState from '../types/IValuePickerReducerState'

export const valuePickersMetadata: TValuePickerConfiguration[] = [
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Alue/Identifier',
        ValuePickerId: 'area',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'alueIdt',
        Text: {
            FetchError: 'Alueiden haku epäonnistui',
            Label: 'Kaikki alueet',
            MultipleSelected: 'aluetta',
        },
        TextSearchProps: ['Nimi'],
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [{ ValuePickerId: 'area', ParameterName: 'alueIdt' }],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Search',
        ValuePickerId: 'customer',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Asiakkaiden haku epäonnistui',
            Label: 'Asiakas',
            MultipleSelected: 'asiakasta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
]

export const valuePickerState: IValuePickerReducerState = new Map(
    valuePickersMetadata.map((valuePicker) => [
        valuePicker.ValuePickerId,
        { configuration: valuePicker, value: new Set() },
    ])
)

export default {
    valuePickersMetadata,
    valuePickerState,
}
