import * as React from 'react'
import styled from '@emotion/styled'

import TextInput, { ITextInputProps } from 'components/atoms/TextInput'

export type TStyledTextInputProps = ITextInputProps

const Input = styled(TextInput)`
    width: ${({ width }: TStyledTextInputProps) => width ?? '300px'};
`

const StyledTextInput: React.FunctionComponent<TStyledTextInputProps> = (props) => <Input {...props} />

export default StyledTextInput
