import { createReducer } from 'typesafe-actions'

import INavigationState from '../Types/INavigationState'
import { setCurrentPageAction, setNavigationSectionsAction, setNavigationSubsectionsAction } from './NavigationActions'

const initialState: INavigationState = {
    navigationSections: [],
    navigationSubsections: [],
    currentPage: '',
}

const navigationReducer = createReducer(initialState)
    .handleAction(setNavigationSectionsAction, (state, action) => {
        return {
            ...state,
            navigationSections: action.payload,
        }
    })
    .handleAction(setNavigationSubsectionsAction, (state, action) => {
        return {
            ...state,
            navigationSubsections: action.payload,
        }
    })
    .handleAction(setCurrentPageAction, (state, action) => {
        return {
            ...state,
            currentPage: action.payload,
        }
    })

export default navigationReducer
