import * as React from 'react'
import styled from '@emotion/styled'

import Icon from '../../Icon'
import { Button } from '../Button'

type TButtonPropsLimited = Omit<React.ComponentProps<typeof Button>, 'children'>
interface IMoreButtonProps extends TButtonPropsLimited {
    icon?: string
}

const StyledButton = styled(Button)`
    height: 24px;
    min-width: 25px;
    max-width: 25px;
`

const MoreButton: React.FC<IMoreButtonProps> = ({ onClick, icon = 'more_horiz', ...buttonProps }) => {
    return (
        <StyledButton onClick={onClick} variant="outlined" {...buttonProps}>
            <Icon>{icon}</Icon>
        </StyledButton>
    )
}

export default MoreButton
