import * as React from 'react'

import { success_100 } from 'constants/Styles'
import Icon from '.'

interface ICheckIconProps {
    value: boolean
    tooltip: string
}

const CheckIcon: React.FC<ICheckIconProps> = ({ value = false, tooltip = '' }) => {
    return value ? (
        <Icon color={success_100} tooltip={tooltip}>
            check_circle
        </Icon>
    ) : (
        <Icon color={success_100} tooltip={tooltip}>
            check_circle_outline
        </Icon>
    )
}

export default CheckIcon
