import makeRequest, { ERequestMethod } from '../../rest-api'
import IDeletionReasonFrom, { IDeletionReasonFromServer } from '../Types/IDeletionReason'
import { IListViewDataContainer } from '../../data-source-types'
import { getLogger } from '../../log'

const Log = getLogger('modal-deletion-confirmation.queryDeletionReasons')
export const DELETION_REASON_URL = '/Poistosyy/Identifier'

const queryDeletionReasons = async (url: string): Promise<IDeletionReasonFrom[]> => {
    const reasons = await makeRequest<IListViewDataContainer<IDeletionReasonFromServer>>({
        method: ERequestMethod.POST,
        url,
    })

    if (!reasons || !reasons.ListData) {
        Log.error('ListData was null')
        return []
    }

    return reasons.ListData.map(({ Id, Name }) => ({ id: Id.toString(), name: Name }))
}

export default queryDeletionReasons
