import moment, { Moment } from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'

type THook<T extends HTMLElement> = [React.RefObject<T>, boolean]

export const useMouseHover = <T extends HTMLElement>(): THook<T> => {
    const [hovered, setHovered] = useState(false)
    const ref = useRef<T>(null)

    const handleMouseOver = (): void => setHovered(true)
    const handleMouseOut = (): void => setHovered(false)

    useEffect(() => {
        const node = ref && ref.current

        if (node) {
            node.addEventListener('mouseover', handleMouseOver)
            node.addEventListener('mouseout', handleMouseOut)
            return () => {
                node.removeEventListener('mouseover', handleMouseOver)
                node.removeEventListener('mouseout', handleMouseOut)
            }
        }
    }, [ref])

    return [ref, hovered]
}

export const useModifierKeyListener = (): { shiftPressed: boolean; controlPressed: boolean } => {
    const [shiftPressed, setShiftPressed] = useState(false)
    const [controlPressed, setControlPressed] = useState(false)

    const keyDownListener = useCallback(
        (event: KeyboardEvent) => {
            const isControlKey = event.key === 'Meta' || event.key === 'Control'

            if (event.key === 'Shift' && !shiftPressed) {
                setShiftPressed(true)
            }
            if (isControlKey && !controlPressed) {
                setControlPressed(true)
            }
        },
        [controlPressed, shiftPressed]
    )

    const keyUpListener = useCallback((event: KeyboardEvent) => {
        const isControlKey = event.key === 'Meta' || event.key === 'Control'

        if (event.key === 'Shift') {
            setShiftPressed(false)
        }
        if (isControlKey) {
            setControlPressed(false)
        }
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', keyDownListener)
        document.addEventListener('keyup', keyUpListener)

        return () => {
            document.removeEventListener('keydown', keyDownListener)
            document.removeEventListener('keyup', keyUpListener)
        }
    }, [keyDownListener, keyUpListener])

    return { shiftPressed, controlPressed }
}

export const fillDaysBetween = (oldSelection: Moment[], newSelection: Moment): Moment[] => {
    const dates = [...oldSelection]
    const iteratedDate = moment.min(...oldSelection).clone()

    const addNewDate = (date: Moment) => {
        const d = date.clone()
        const alreadyAdded = dates.some((existingDate) => existingDate.toISOString() === d.toISOString())

        if (!alreadyAdded) {
            dates.push(d)
        }
    }

    if (oldSelection[0] > newSelection) {
        while (iteratedDate.subtract(1, 'days').diff(newSelection) > 0) {
            addNewDate(iteratedDate)
        }
    } else {
        while (iteratedDate.add(1, 'days').diff(newSelection) < 0) {
            addNewDate(iteratedDate)
        }
    }

    addNewDate(newSelection)

    return dates
}
