import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import EFilterType from '../constants/EFilterType'
import EValuePickerType from '../types/EValuePickerType'

const suunnittelujaksoValuePickers: TValuePickerConfiguration[] = [
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'PlanningPeriod_Create_Form_WorkUnits',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohteet',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Asiakas/Identifier',
        ValuePickerId: 'Suunnittelujakso_Edit_Form_Asiakkaat',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'asiakasIdt',
        Text: {
            FetchError: 'Työkohteiden haku epäonnistui',
            Label: 'Työkohteet',
            MultipleSelected: 'työkohdetta',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'Suunnittelujakso_Edit_Form_Asiakkaat',
                ParameterName: 'AsiakasIdt',
                FilterType: EFilterType.ANY,
            },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'Suunnittelujakso_Edit_Form_Tyontekijat',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'PlanningPeriod_Create_Form_WorkUnits',
                ParameterName: 'AsiakasIdt',
                FilterType: EFilterType.ANY,
            },
        ],
        ItemOptionLabelFields: ['Id', 'Nimi'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tyontekija/Identifier',
        ValuePickerId: 'PlanningPeriod_Create_Form_Employees',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'tyontekijaIdt',
        Text: {
            FetchError: 'Työntekijöiden haku epäonnistui',
            Label: 'Työntekijät',
            MultipleSelected: 'työntekijää',
        },
        ItemValueLabelFields: ['Nimi'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'PlanningPeriod_Create_Form_WorkUnits',
                ParameterName: 'WorkUnitIds',
            },
        ],
        ItemOptionLabelFields: ['DisplayName'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Pooli/Identifier',
        ValuePickerId: 'PlanningPeriod_Create_Form_Pools',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolIds',
        Text: {
            FetchError: 'Poolien haku epäonnistui',
            Label: 'Poolit',
            MultipleSelected: 'poolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['DisplayName'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [
            {
                ValuePickerId: 'Suunnittelujakso_Edit_Form_Asiakkaat',
                ParameterName: 'WorkUnitIds',
            },
        ],
        ItemOptionLabelFields: ['DisplayName'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Pooli/Identifier',
        ValuePickerId: 'Suunnittelujakso_Edit_Form_Pools',
        ValuePickerType: EValuePickerType.Dropdown,
        DataRequestParameterName: 'PoolIds',
        Text: {
            FetchError: 'Poolien haku epäonnistui',
            Label: 'Poolit',
            MultipleSelected: 'poolia',
        },
        TextSearchProps: ['Name'],
        ItemValueLabelFields: ['DisplayName'],
        UseDynamicApiSearch: true,
        Limit: 200,
        ExtraRows: 'Omit',
        DefaultValues: null,
    },
    {
        DependsOn: [],
        ValuePickerId: 'PlanningPeriod_List_DateRange',
        ValuePickerType: EValuePickerType.DateRange,
        DataRequestParameterName: 'DateTimeRange',
        DefaultValues: {
            StartFromCurrentDay: 0,
            StartFromCurrentDayUnit: null,
            EndFromCurrentDay: null,
            EndFromCurrentDayUnit: null,
        },
    },
]

export default suunnittelujaksoValuePickers
