import { IEmployeeIdentifier } from './IMessageThread'

export enum EUserGroup {
    Internal,
    External,
    Employee,
}

export default interface IMessage {
    CreatedByLogin: IEmployeeIdentifier
    Content: string
    CreatedTimestamp: string
    IsRead: boolean
    CreatedByUserGroup: EUserGroup
    IsLoading?: boolean
}
