import EListViewActionFunctionalityType from '../Types/EListViewActionFunctionalityType'
import { IConfigurableListHeaderComponent } from '../interfaces/IList'
import { PermissionableAction } from 'interfaces/IActionPermission'
import EDataSourceActionMethods from '../../data-source/Constants/EDataSourceActionMethods' // TODO Cyclic reference
import IListViewModel from '../interfaces/IListViewModel'
import { IConfigurableListState } from '../interfaces/IListState'

import {
    DEFAULT_OVERRIDE_REQUEST_PARAMETERS,
    IDataSourceConfiguration,
    IDataSourceData,
    TDataSourceId,
} from '../../data-source'
import { EViewDataStatus, IDataSourceItem, TDataSourceItemId } from '../../data-source-types'
import IDataSourceReducerState from '../../data-source/Types/IDataSourceReducerState'
import IDataSource from '../../data-source/Types/IDataSource'

const iconHeaderComponent: IConfigurableListHeaderComponent = {
    ComponentId: 'Icon',
    StaticParameters: [
        {
            Name: 'actionIcon',
            Value: true,
        },
        {
            Name: 'children',
            Value: 'home',
        },
        {
            Name: 'color',
            Value: 'rgb(109, 110, 113)',
        },
        {
            Name: 'tooltip',
            Value: 'Asiakashyväksyntä',
        },
        {
            Name: 'translate',
            Value: false,
        },
    ],
}

const listViewMetadata: IListViewModel = {
    Id: 'Testilista',
    IsSelectable: true,
    AllowColumnManagement: true,
    RowSelectable: false,
    AutoFetchData: true,
    FilterItselfDataRequestParameterName: null,
    BulkActions: [
        {
            Display: { ButtonText: 'Poista', IsPrimaryAction: true },
            Functionality: {
                FunctionalityType: EListViewActionFunctionalityType.Immediate,
                DataSourceActionId: 'DELETE',
                PermissionId: 'DELETE',
            },
        },
        {
            Display: { ButtonText: 'Luo uusi', IsPrimaryAction: true },
            Functionality: {
                FunctionalityType: EListViewActionFunctionalityType.Immediate,
                DataSourceActionId: 'CREATE',
                PermissionId: 'CREATE',
            },
        },
        {
            Display: { ButtonText: 'Muokkaa', IsPrimaryAction: true },
            Functionality: {
                FunctionalityType: EListViewActionFunctionalityType.Immediate,
                DataSourceActionId: 'UPDATE',
                PermissionId: 'UPDATE',
            },
        },
    ],
    StaticActions: [],
    RowActions: [
        {
            Display: { Icon: 'done', Tooltip: 'Poista', IsPrimaryAction: true },
            Functionality: {
                FunctionalityType: EListViewActionFunctionalityType.Immediate,
                DataSourceActionId: 'DELETE',
                PermissionId: 'DELETE',
            },
        },
        {
            Display: { Icon: 'undo', Tooltip: 'Luo uusi', IsPrimaryAction: true },
            Functionality: {
                FunctionalityType: EListViewActionFunctionalityType.Immediate,
                DataSourceActionId: 'CREATE',
                PermissionId: 'CREATE',
            },
        },
        {
            Display: { Icon: 'edit', Tooltip: 'Muokkaa', IsPrimaryAction: true },
            Functionality: {
                FunctionalityType: EListViewActionFunctionalityType.Immediate,
                DataSourceActionId: 'UPDATE',
                PermissionId: 'UPDATE',
            },
        },
    ],
    DisplayName: 'Testi Lista',
    DataSourceId: 'TestilistaDataSource',
    FilterIds: ['area', 'customer'],
    Columns: [
        {
            Id: 'Testilista_Vkp',
            Sortable: false,
            Header: 'Vkp',
            HeaderComponent: iconHeaderComponent,
            ListViewId: 'Testilista',
            ViewComponentId: 'WeekDay',
            Visible: true,
            Width: 5,
            Order: 0,
            ParameterMapping: [
                {
                    Id: 0,
                    DataSourceProperty: 'Alkamisaika',
                    DataSourcePropertyId: 'DataSourcePropertyId_EiKaytetaTesteissa',
                    ViewComponentParameter: 'paiva',
                    ViewComponentParameterId: 'ViewComponentParameterId_EiKaytetaTesteissa',
                },
            ],
            StaticParameters: null,
            SortByParameter: '',
            Tooltip: null,
        },
        {
            Id: 'Testilista_Pvm',
            Sortable: true,
            Header: 'Pvm',
            HeaderComponent: null,
            ListViewId: 'Testilista',
            ViewComponentId: 'Date',
            Visible: false,
            Width: 12,
            Order: 1,
            ParameterMapping: [
                {
                    Id: 0,
                    DataSourceProperty: 'Alkamisaika',
                    DataSourcePropertyId: 'DataSourcePropertyId_EiKaytetaTesteissa',
                    ViewComponentParameter: 'paiva',
                    ViewComponentParameterId: 'ViewComponentParameterId_EiKaytetaTesteissa',
                },
            ],
            StaticParameters: [
                {
                    Id: 0,
                    Name: 'kestoyksikko',
                    Value: 'h',
                },
            ],
            SortByParameter: '',
            Tooltip: null,
        },
        {
            Id: 'Testilista_AsiakashyvaksyntaStatus',
            Sortable: false,
            Header: 'Asiakashyvaksynta',
            HeaderComponent: null,
            ListViewId: 'Testilista',
            ViewComponentId: 'HyvaksyntaStatus',
            Visible: true,
            Width: 8,
            Order: 14,
            ParameterMapping: [
                {
                    Id: 0,
                    DataSourceProperty: 'AsiakasHyvaksynyt',
                    DataSourcePropertyId: 'DataSourcePropertyId_EiKaytetaTesteissa',
                    ViewComponentParameter: 'hyvaksynta',
                    ViewComponentParameterId: 'ViewComponentParameterId_EiKaytetaTesteissa',
                },
                {
                    Id: 0,
                    DataSourceProperty: 'TyoaikatapahtumaId',
                    DataSourcePropertyId: 'DataSourcePropertyId_EiKaytetaTesteissa',
                    ViewComponentParameter: 'tapahtumaId',
                    ViewComponentParameterId: 'ViewComponentParameterId_EiKaytetaTesteissa',
                },
                {
                    Id: 0,
                    DataSourceProperty: 'Tyoaikatapahtumatyyppi',
                    DataSourcePropertyId: 'DataSourcePropertyId_EiKaytetaTesteissa',
                    ViewComponentParameter: 'tapahtumatyyppi',
                    ViewComponentParameterId: 'ViewComponentParameterId_EiKaytetaTesteissa',
                },
            ],
            StaticParameters: null,
            SortByParameter: '',
            Tooltip: null,
        },
    ],
    AdditionalDataFetchParameters: {
        GroupBy: [
            {
                GroupByProperties: [],
                CustomFunctions: ['SingleOrCount(Asiakkaat,Asiakas.Id,Asiakas.Nimi)'],
                WindowFunctions: [
                    {
                        TargetProperty: 'Id',
                        Function: 'Count',
                        Label: 'Items',
                    },
                ],
                ReplaceListData: false,
            },
        ],
    },
    Reports: [
        {
            Id: 0,
            Name: 'test report',
            GroupByParameters: [
                {
                    Target: null,
                    GroupByProperties: [
                        {
                            Property: 'AdditionalServices',
                            DoNotGroupNulls: false,
                            Transformation: null,
                        },
                    ],
                    FixedGroups: null,
                    WindowFunctions: [],
                    CustomFunctions: [],
                    GroupByData: null,
                    OmitListData: true,
                    UseFullData: false,
                    DoNotIncludeSubRowItems: false,
                    SortGroupsBy: [],
                    SubGroupBy: [
                        {
                            Target: null,
                            GroupByProperties: [
                                {
                                    Property: 'Alkamiskellonaika',
                                    DoNotGroupNulls: false,
                                    Transformation: null,
                                },
                            ],
                            FixedGroups: null,
                            WindowFunctions: [],
                            CustomFunctions: [],
                            GroupByData: null,
                            OmitListData: true,
                            UseFullData: false,
                            DoNotIncludeSubRowItems: false,
                            SortGroupsBy: [],
                            SubGroupBy: null,
                            IncludeInParentGroupLimit: false,
                        },
                    ],
                    IncludeInParentGroupLimit: false,
                },
            ],
            IncludeProperties: [],
        },
    ],
}

export interface ITestDataModel extends IDataSourceItem {
    TestProperty?: string
}

const dataSourceMetadata: IDataSourceConfiguration = {
    Id: 'TestilistaDataSource',
    Actions: [
        {
            Id: 'DELETE',
            DataPropertyParameters: [{ Key: 'tapahtumaIdt', PropertyPath: 'Id' }],
            EndpointUrl: '/Tapahtuma',
            Method: EDataSourceActionMethods.DELETE,
            StaticParameters: {},
            DataSourceId: 'TestilistaDataSource',
        },
        {
            Id: 'CREATE',
            DataPropertyParameters: [{ Key: 'tapahtumaIdt', PropertyPath: 'Id' }],
            EndpointUrl: '/Tapahtuma',
            Method: EDataSourceActionMethods.POST,
            StaticParameters: {},
            DataSourceId: 'TestilistaDataSource',
        },
        {
            Id: 'UPDATE',
            DataPropertyParameters: [],
            EndpointUrl: '/test/url',
            Method: EDataSourceActionMethods.POST,
            StaticParameters: {},
            DataSourceId: 'TestilistaDataSource',
        },
    ],
    Url: 'Test',
    Properties: [
        {
            Id: 'TestilistaDataSource_TestProperty',
            Property: 'TestProperty',
            DataType: 'string',
        },
    ],
    DependsOn: [],
}

const actualData = [
    {
        Id: '0',
        Alkamisaika: '2018-01-01T00:00:00',
        Kayttooikeudet: [
            {
                Toiminto: PermissionableAction.DELETE,
                OnkoSallittu: true,
                SyyKieltoon: null,
            },
            {
                Toiminto: PermissionableAction.EDIT,
                OnkoSallittu: false,
                SyyKieltoon: null,
            },
        ],
    },
    {
        Id: '1',
        Alkamisaika: '2018-01-01T01:00:00',
        Kayttooikeudet: [
            {
                Toiminto: PermissionableAction.CREATE,
                OnkoSallittu: true,
                SyyKieltoon: null,
            },
        ],
    },
]

const tapahtuma = {
    Tapahtumatunniste: {
        TyoaikatapahtumanId: 13121,
        Tyoaikatapahtumatyyppi: 1,
    },
    Ruokaperintahinta: null,
    Urakkapalkkalaji: null,
    KasiteltyToteumaan: false,
    KestoMinuutit: 0,
    Tapahtumatyyppi: {
        Id: 33,
        Nimi: 'testTapahtumatyyppi',
        Varikoodi: '#ff66ff',
    },
    Asiakas: {
        Id: 67001068,
        Nimi: 'Ferretti e figli',
    },
    Tyontekija: {
        Id: 405928,
        Nimi: 'Centeno Evangelista',
    },
    Alkamisaika: '2018-03-11T00:00:00',
    Loppumisaika: '2018-03-11T23:59:00',
    Ammattinimike: { Id: 0, Nimi: 'Testaaja' },
    Tes: null,
    Lounasminuutit: 0,
    LounasId: null,
    RuokaperintaId: null,
    Lisatiedot: '',
    LisatiedotTyontekijalta: null,
    LisatiedotKuitattu: false,
    Projektinumero: null,
    Urakkapalkkamaara: null,
    Tuntipalkka: 0,
    TyontekijaHyvaksynyt: false,
    AsiakasHyvaksynyt: false,
    LounaanAlkuaika: null,
    Kayttooikeudet: [
        {
            Toiminto: PermissionableAction.EDIT,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.CREATE,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.DELETE,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.VUORO_HYVAKSY,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.PERU_VUORO_HYVAKSY,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.VUORO_LISATIETO_KUITTAUS,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.VUORO_LISATIETO_KUITTAUS_PERUUTUS,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        { Toiminto: 'GET_TRANSFER_BATCH_FILES', OnkoSallittu: true, SyyKieltoon: null },
    ],
    Id: '13121_1',
    Tyosuhdetyyppi: { TyosuhdetyyppiId: 119, Nimi: 'TestTyosuhdetyyppi' },
    Tyosuhdemuoto: { Id: 113, Nimi: 'TestTyosuhdemuoto' },
    Palkkatyyppi: null,
    Kokonaislaskutus: 0,
    Kokonaispalkka: 0,
} as ITestDataModel

const secondTapahtuma = {
    Tapahtumatunniste: {
        TyoaikatapahtumanId: 13122,
        Tyoaikatapahtumatyyppi: 2,
    },
    Ruokaperintahinta: null,
    Urakkapalkkalaji: null,
    KasiteltyToteumaan: false,
    KestoMinuutit: 0,
    Tapahtumatyyppi: {
        Id: 33,
        Nimi: 'testTapahtumatyyppi',
        Varikoodi: '#ff66ff',
    },
    Asiakas: {
        Id: 67001069,
        Nimi: 'Ferretti e figlio',
    },
    Tyontekija: {
        Id: 405929,
        Nimi: 'Centeno Evangelistas',
    },
    Alkamisaika: '2018-03-11T00:00:00',
    Loppumisaika: '2018-03-11T23:59:00',
    Ammattinimike: { Id: 0, Nimi: 'Testaaja' },
    Tes: null,
    Lounasminuutit: 0,
    LounasId: null,
    RuokaperintaId: null,
    Lisatiedot: '',
    LisatiedotTyontekijalta: null,
    LisatiedotKuitattu: false,
    Projektinumero: null,
    Urakkapalkkamaara: null,
    Tuntipalkka: 0,
    TyontekijaHyvaksynyt: false,
    AsiakasHyvaksynyt: false,
    LounaanAlkuaika: null,
    Kayttooikeudet: [
        {
            Toiminto: PermissionableAction.EDIT,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.CREATE,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.DELETE,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.VUORO_HYVAKSY,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.PERU_VUORO_HYVAKSY,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.VUORO_LISATIETO_KUITTAUS,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
        {
            Toiminto: PermissionableAction.VUORO_LISATIETO_KUITTAUS_PERUUTUS,
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
    ],
    Id: '13122_2',
    Tyosuhdetyyppi: { TyosuhdetyyppiId: 119, Nimi: 'TestTyosuhdetyyppi' },
    Tyosuhdemuoto: { Id: 113, Nimi: 'TestTyosuhdemuoto' },
    Palkkatyyppi: null,
    Kokonaislaskutus: 0,
    Kokonaispalkka: 0,
} as ITestDataModel

const normalisedTapahtumaMainData = {
    [tapahtuma.Id]: tapahtuma,
    [secondTapahtuma.Id]: secondTapahtuma,
}

const dataSourceData: IDataSourceData = new Map([
    [tapahtuma.Id, tapahtuma],
    [secondTapahtuma.Id, secondTapahtuma],
])

const getOrderedItemIds = (): TDataSourceItemId[] => [tapahtuma.Id, secondTapahtuma.Id]

const dataSource: IDataSource = {
    accumulatedData: [
        {
            Label: 'TestLabel',
            Unit: 'TestUnit',
            Value: '123',
        },
    ],
    configuration: dataSourceMetadata,
    data: dataSourceData,
    orderedItemIds: getOrderedItemIds(),
    valuePickerIds: [],
    fetchParameters: {},
    fetchFiltersParameters: {},
    groupData: [],
    isDataFetched: false,
    timestamp: null,
    sortSettings: new Map(),
    offset: 0,
    overrideFetchParameters: DEFAULT_OVERRIDE_REQUEST_PARAMETERS,
    dataStatus: EViewDataStatus.OK,
}

const getDataSource = (): IDataSource => dataSource

const dataSourceReducerState: IDataSourceReducerState = new Map([[dataSourceMetadata.Id, { ...dataSource }]])

const getListViewMetadata = (): IListViewModel => listViewMetadata

const getDataSourceMetadata = (): IDataSourceConfiguration => dataSourceMetadata

const getDataSourceReducerState = (dataSourceId?: TDataSourceId): IDataSourceReducerState =>
    dataSourceId ? new Map().set(dataSourceId, dataSource) : dataSourceReducerState

const getListActualData = (): IDataSourceItem[] => actualData

const getNormalisedTapahtumaSampleData = (): IConfigurableListState => ({
    selected: new Set(),
    configuration: listViewMetadata,
})

const getTapahtumaObject = (): ITestDataModel => tapahtuma

const getSampleTapahtumas = (): ITestDataModel[] => [tapahtuma, secondTapahtuma]

/**
 * Konfiguroitavan listan testidataa varten
 */
export default {
    dataSource,
    dataSourceData,
    getDataSource,
    getDataSourceMetadata,
    getDataSourceReducerState,
    getListActualData,
    getListViewMetadata,
    getNormalisedTapahtumaSampleData,
    getOrderedItemIds,
    getSampleTapahtumas,
    getTapahtumaObject,
    iconHeaderComponent,
    normalisedTapahtumaMainData,
}
