import { useDispatch } from 'react-redux'
import { doubleClickActionThunk } from '../../../Thunks/ConfigurableCalendarThunks'

const useDoubleClickActionForNode = (calendarId: string, nodeId: string): (() => void) => {
    const dispatch = useDispatch()

    const rightClickFunction = () => {
        dispatch(doubleClickActionThunk(calendarId, nodeId))
    }

    return rightClickFunction
}

export default useDoubleClickActionForNode
