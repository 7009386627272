import * as React from 'react'

import { IDataSourceAction } from '../../../data-source'
import { IListViewButtonAction } from '../../interfaces/TDisplayAction'
import EListViewActionFunctionalityType from '../../Types/EListViewActionFunctionalityType'
import IListViewAction from '../../interfaces/IListViewAction'
import ListActionButton from '../ListActionButton'
import ListBulkActionButton from '../ListBulkActionButton'
import TFunctionalityAction, { IListViewConfigurableListModalAction } from '../../interfaces/TFunctionalityAction'
import IListActionConfirmationDialogModalProps from '../../interfaces/IListActionConfirmationDialogModalProps'
import { IDataSourceItem } from '../../../data-source-types'

export interface IListActionButtonsProps {
    actions: IListViewAction<IListViewButtonAction, TFunctionalityAction>[]
    actionConfigurations: IDataSourceAction[]
    disabled?: boolean
    selectedItems?: IDataSourceItem[]
    onConfirmationButtonClick: (
        callbackProps: Pick<IListActionConfirmationDialogModalProps, 'actionConfiguration' | 'confirmationDialogTexts'>
    ) => void
    onImmediateButtonClick: (actionConfiguration: IDataSourceAction) => void
    onModalButtonClick: (formId: string) => void
    onCustomModalClick: (formId: string, dataSourceId: string) => void
    onListModalButtonClick: (listModalFunctionalityConfiguration: IListViewConfigurableListModalAction) => void
    onDownloadButtonClick: (actionConfiguration: IDataSourceAction) => void
}

const ListActionButtons: React.FunctionComponent<IListActionButtonsProps> = ({
    actions,
    actionConfigurations,
    disabled = false,
    selectedItems,
    onConfirmationButtonClick,
    onImmediateButtonClick,
    onModalButtonClick,
    onCustomModalClick,
    onListModalButtonClick,
    onDownloadButtonClick,
}) => (
    <React.Fragment>
        {actions.map(({ Display, Functionality }) => {
            const actionConfiguration = actionConfigurations.find(
                (conf) => conf.Id === Functionality.DataSourceActionId
            )

            const hasAccessForTheActionForAnyitems = selectedItems
                ? selectedItems.find(
                      (x) =>
                          x.Kayttooikeudet &&
                          x.Kayttooikeudet.find((y) => y.Toiminto === Functionality.PermissionId && y.OnkoSallittu)
                  )
                : true

            const actionIsDisabled = disabled || !hasAccessForTheActionForAnyitems

            switch (Functionality.FunctionalityType) {
                case EListViewActionFunctionalityType.Download: {
                    if (!actionConfiguration) {
                        return null
                    }

                    return (
                        <ListActionButton
                            buttonText={Display.ButtonText}
                            callbackProps={actionConfiguration}
                            disabled={actionIsDisabled}
                            key={Functionality.DataSourceActionId}
                            onClick={onDownloadButtonClick}
                            testId={Functionality.DataSourceActionId}
                        />
                    )
                }
                case EListViewActionFunctionalityType.Immediate: {
                    if (!actionConfiguration) {
                        return null
                    }

                    return (
                        <ListActionButton
                            buttonText={Display.ButtonText}
                            callbackProps={actionConfiguration}
                            disabled={actionIsDisabled}
                            key={Functionality.DataSourceActionId}
                            onClick={onImmediateButtonClick}
                            testId={Functionality.DataSourceActionId}
                        />
                    )
                }

                case EListViewActionFunctionalityType.CustomModal: {
                    if (!actionConfiguration) {
                        return null
                    }

                    const dataSourceId = actionConfiguration.DataSourceId
                    const handleClick = (formId: string) => {
                        onCustomModalClick(formId, dataSourceId)
                    }

                    return (
                        <ListBulkActionButton
                            disabled={actionIsDisabled}
                            formId={Functionality.FormId}
                            key={Functionality.FormId}
                            label={Display.ButtonText}
                            onClick={handleClick}
                            testId={Functionality.FormId}
                        />
                    )
                }

                case EListViewActionFunctionalityType.ConfigurableModal: {
                    // @@TODO Empty state.formId on modal close?
                    return (
                        <ListBulkActionButton
                            disabled={actionIsDisabled}
                            formId={Functionality.FormId}
                            key={Functionality.FormId}
                            label={Display.ButtonText}
                            onClick={onModalButtonClick}
                            tooltip={Display.Tooltip}
                            testId={Functionality.FormId}
                        />
                    )
                }

                case EListViewActionFunctionalityType.Confirmation: {
                    if (!actionConfiguration) {
                        return null
                    }

                    return (
                        <ListActionButton
                            buttonText={Display.ButtonText}
                            callbackProps={{ actionConfiguration, confirmationDialogTexts: { ...Functionality } }}
                            disabled={actionIsDisabled}
                            key={Functionality.DataSourceActionId}
                            onClick={onConfirmationButtonClick}
                            testId={Functionality.DataSourceActionId}
                        />
                    )
                }

                case EListViewActionFunctionalityType.ListModal: {
                    const { DataSourceActionId } = Functionality

                    return (
                        <ListActionButton
                            buttonText={Display.ButtonText}
                            callbackProps={Functionality}
                            disabled={actionIsDisabled}
                            key={DataSourceActionId}
                            onClick={onListModalButtonClick}
                            testId={Functionality.DataSourceActionId}
                        />
                    )
                }

                default:
                    return null
            }
        })}
    </React.Fragment>
)

export default ListActionButtons
