interface IDimensionRestriction {
    unit: string
    value: number
    combined: string
}

export const MIN_COLUMN_WIDTH: IDimensionRestriction = {
    unit: 'px',
    value: 112,
    combined: '112px',
}

export const GROUP_NODE_WIDTH: IDimensionRestriction = {
    unit: 'px',
    value: 200,
    combined: '200px',
}
