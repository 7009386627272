import { connect } from 'react-redux'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'
import Paper from '@material-ui/core/Paper'

import ColumnManagementModal from './components/ColumnManagementModal'
import { initializeListThunk, resetListDataThunk } from './Thunks/ConfigurableListThunks'
import {
    selectDataSourceId,
    selectIsColumnManagementAllowed,
    selectIsListAutoFetchDataEnabled,
    selectListViewFilterIds,
} from './State/ConfigurableListSelectors'
import { selectIsDataSourceDataLoading, selectIsDataSourceInitialized } from '../data-source'
import { IDataSourceItem } from '../data-source-types'
import { TListIconData } from './interfaces/IList'
import ListActionConfirmationDialog from './components/ListActionConfirmationDialog'
import ListContent from './components/ListContent'
import ListFilterListener from './components/ListFilterListener'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import RowHeaders from './components/RowHeaders'
import { BoundThunk } from '../generic-state'
import { IInitialValuePickersValues, selectAreValuePickersInitialised } from '../value-picker'
import useFilterItselfWhenSetToDoSo from './Hooks/useFilterItselfWhenSetToDoSo'
import { resetAllSelectedListItemsAction } from './State/ConfigurableListActions'

interface IOwnProps {
    additionalDataSourceDataRequestFiltersParameters?: Record<string, unknown> | null
    iconData?: TListIconData
    listId: string
    onRowSelect?: (item: IDataSourceItem) => void
    isVirtualized?: boolean
    shouldAddScrollListener?: boolean
    initialValuePickersValues?: IInitialValuePickersValues | null
    shouldEmptyDataWhenUnmounting?: boolean
    resetValuePickerValues?: boolean
}

interface IStateProps {
    columnManagementAllowed: boolean
    dataLoading: boolean
    isDataSourceInitialized: boolean
    isAutoFetchEnabled: boolean
    valuePickersAreInitialized: boolean
}

interface IDispatchProps {
    initializeList: BoundThunk<typeof initializeListThunk>
    resetAllSelectedListItems: typeof resetAllSelectedListItemsAction
    resetListData: BoundThunk<typeof resetListDataThunk>
}

export interface IConfigurableListOwnProps extends IOwnProps {}

export interface IConfigurableListProps extends IOwnProps, IStateProps, IDispatchProps {}

// Ensure that it takes enough room. Otherwise for example in dialogs it might
// cause the dialog to change its height constantly as loading indicator spins
// around
const StyledLoadingIndicatorInline = styled(LoadingIndicatorInline)`
    min-height: 65px;
    display: block;
    padding-left: 30px;
`

const Container = styled(Paper, {
    shouldForwardProp: (propName) => propName !== 'isVirtualized',
})<{ isVirtualized: boolean }>`
    overflow: ${({ isVirtualized }) => (isVirtualized ? 'hidden' : 'auto')};
    /* Padding is necessary to display each row's box shadow correctly. */
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.neutralsWhite100};
    margin: 0 8px 8px 8px;
    flex: 1 1 auto;
`

export interface IConfigurableListHandles {
    recalculateScrollPosition: () => void
}

export const ConfigurableListUnconnected = ({
    dataLoading,
    iconData,
    listId,
    initializeList,
    isAutoFetchEnabled,
    additionalDataSourceDataRequestFiltersParameters,
    isDataSourceInitialized,
    columnManagementAllowed,
    onRowSelect,
    isVirtualized = false,
    shouldAddScrollListener = false,
    initialValuePickersValues = null,
    valuePickersAreInitialized,
    resetAllSelectedListItems,
    resetListData,
    shouldEmptyDataWhenUnmounting = true,
    resetValuePickerValues = false,
}: IConfigurableListProps): JSX.Element => {
    const listContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        initializeList(
            listId,
            additionalDataSourceDataRequestFiltersParameters,
            initialValuePickersValues,
            resetValuePickerValues
        )

        // When list unmounts
        return () => {
            resetAllSelectedListItems(listId)

            if (shouldEmptyDataWhenUnmounting) {
                resetListData(listId)
            }
        }
    }, [
        resetAllSelectedListItems,
        initializeList,
        additionalDataSourceDataRequestFiltersParameters,
        listId,
        initialValuePickersValues,
        resetListData,
        shouldEmptyDataWhenUnmounting,
        resetValuePickerValues,
    ])

    useFilterItselfWhenSetToDoSo(listId)

    if (!isDataSourceInitialized) {
        return <StyledLoadingIndicatorInline isLoading size={50} />
    }

    return (
        <Container elevation={2} ref={listContainerRef} isVirtualized={isVirtualized}>
            {!isVirtualized && <RowHeaders isLoadingInitialData={dataLoading} listId={listId} />}
            <ListContent
                iconData={iconData}
                isLoadingInitialData={dataLoading}
                isVirtualized={isVirtualized}
                listId={listId}
                onRowSelect={onRowSelect}
                shouldAddScrollListener={shouldAddScrollListener}
            />
            {isAutoFetchEnabled && valuePickersAreInitialized && <ListFilterListener listId={listId} />}
            {columnManagementAllowed && <ColumnManagementModal listId={listId} />}
            <ListActionConfirmationDialog />
        </Container>
    )
}

ConfigurableListUnconnected.displayName = 'ConfigurableListUnconnectedForwardRef'

const mapStateToProps = (state: RootState, { listId }: IOwnProps): IStateProps => {
    const dataSourceId = selectDataSourceId(state, listId)
    const filterIds = selectListViewFilterIds(state, listId)
    const isDataSourceInitialized = selectIsDataSourceInitialized(state, dataSourceId)

    return {
        columnManagementAllowed: selectIsColumnManagementAllowed(state, listId),
        dataLoading: selectIsDataSourceDataLoading(state, dataSourceId),
        isDataSourceInitialized,
        isAutoFetchEnabled: selectIsListAutoFetchDataEnabled(state, listId),
        valuePickersAreInitialized: isDataSourceInitialized && selectAreValuePickersInitialised(state, filterIds),
    }
}

const mapDispatchToProps = {
    initializeList: initializeListThunk,
    resetAllSelectedListItems: resetAllSelectedListItemsAction,
    resetListData: resetListDataThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurableListUnconnected)
