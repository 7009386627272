import { FieldProps } from 'formik'

import Dropdown, { IDropdownProps, TOptionalProps } from './Dropdown'
import { ISelectAction } from './Interfaces'

type TDropdownMandatoryProps<T> = Omit<IDropdownProps<T>, keyof TOptionalProps<T>>
type TDropdownProps<T> = TDropdownMandatoryProps<T> & Partial<TOptionalProps<T>>

type TDropdownPropsLimited<T> = Omit<TDropdownProps<T>, 'selectAction' | 'values'>

interface IFormikDropdownOwnProps<OptionType> extends TDropdownPropsLimited<OptionType>, FieldProps {}

function FormikDropdown<T>({ field, form, ...props }: IFormikDropdownOwnProps<T>): JSX.Element {
    const handleBlur = () => {
        const isTouched = true
        form.setFieldTouched(field.name, isTouched, form.validateOnBlur)
    }

    const handleChange: ISelectAction<T> = (newValue) => {
        form.setFieldValue(field.name, newValue)
    }

    return <Dropdown {...props} onBlur={handleBlur} selectAction={handleChange} values={field.value} />
}

export default FormikDropdown
