import { createReducer } from 'typesafe-actions'

import { TDataSourceItemId } from '../../data-source-types'
import {
    initializeConfigurableCalendarAction,
    deselectCalendarNodeAction,
    setSelectedCalendarNodesAction,
    resetCalendarNodeSelectionsAction,
    resetNonActiveCalendarsNodeSelectionsAction,
    setCalendarInitialValuePickersValues,
    setCalendarDataTemplates,
    setCalendarGroupNodeSearchFilter,
} from './ConfigurableCalendarActions'
import IConfigurableCalendar from '../Types/IConfigurableCalendar'
import { setEmptyCellNodesAction } from './ConfigurableCalendarActions'
import IConfigurableCalendarReducerState from '../Types/IConfigurableCalendarReducerState'
import IEmptyCellNode from '../Types/IEmptyCellNode'

const INITIAL_STATE: IConfigurableCalendarReducerState = new Map()

const SINGLE_CALENDAR_INITIAL_STATE = {
    configuration: null,
    selectedNodes: new Set<TDataSourceItemId>(),
    initialValuePickersValues: null,
    groupNodeSearchFilter: '',
    emptyCellNodes: new Map<string, IEmptyCellNode>(),
    dataTemplates: [],
}

const configurableCalendarReducer = createReducer(INITIAL_STATE)
    .handleAction(initializeConfigurableCalendarAction, (state, action) => {
        const { calendarId, configuration } = action.payload

        const calendar = {
            ...SINGLE_CALENDAR_INITIAL_STATE,
            configuration,
        }

        return new Map(state).set(calendarId, calendar)
    })
    .handleAction(setEmptyCellNodesAction, (state, action) => {
        const { calendarId, nodes } = action.payload

        const calendar = state.get(calendarId)

        if (!calendar) {
            return state
        }

        const nodeMap = nodes.reduce((map, obj) => map.set(obj.Id, obj), new Map())

        const newCalendarState = {
            ...calendar,
            emptyCellNodes: nodeMap,
        }

        return new Map(state).set(calendarId, newCalendarState)
    })
    .handleAction(setSelectedCalendarNodesAction, (state, action) => {
        const { calendarId, nodeIds, resetPreviousSelection } = action.payload

        const calendar = state.get(calendarId)

        if (!calendar) {
            return state
        }

        const newCalendarState = {
            ...calendar,
            selectedNodes: resetPreviousSelection ? new Set(nodeIds) : new Set([...calendar.selectedNodes, ...nodeIds]),
        }

        return new Map(state).set(calendarId, newCalendarState)
    })
    .handleAction(setCalendarDataTemplates, (state, action) => {
        const { calendarId, templates } = action.payload

        const calendar = state.get(calendarId)

        if (!calendar) {
            return state
        }

        const newCalendarState = {
            ...calendar,
            dataTemplates: templates,
        }

        return new Map(state).set(calendarId, newCalendarState)
    })
    .handleAction(deselectCalendarNodeAction, (state, action) => {
        const { calendarId, nodeId } = action.payload

        const calendar = state.get(calendarId)

        if (!calendar) {
            return state
        }

        const selectedNodes = new Set(calendar.selectedNodes)
        selectedNodes.delete(nodeId)

        const newCalendarState = {
            ...calendar,
            selectedNodes,
        }

        return new Map(state).set(calendarId, newCalendarState)
    })
    .handleAction(resetCalendarNodeSelectionsAction, (state, action) => {
        const calendarId = action.payload

        const calendar = state.get(calendarId)

        if (!calendar) {
            return state
        }

        const newCalendarState = {
            ...calendar,
            selectedNodes: new Set<TDataSourceItemId>(),
        }

        return new Map(state).set(calendarId, newCalendarState)
    })
    .handleAction(resetNonActiveCalendarsNodeSelectionsAction, (state, action) => {
        const activeCalendarId = action.payload

        const newState = new Map(state)
        newState.forEach((calendar, calendarId, reducerState) => {
            if (calendarId === activeCalendarId) {
                return
            }

            reducerState.set(calendarId, { ...calendar, selectedNodes: new Set<TDataSourceItemId>() })
        })

        return newState
    })
    .handleAction(setCalendarInitialValuePickersValues, (state, action) => {
        const { calendarId, valuePickersValues } = action.payload

        const calendar = state.get(calendarId)

        if (!calendar) {
            return state
        }

        const newCalendarState: IConfigurableCalendar = {
            ...calendar,
            initialValuePickersValues: valuePickersValues,
        }

        return new Map(state).set(calendarId, newCalendarState)
    })
    .handleAction(setCalendarGroupNodeSearchFilter, (state, action) => {
        const { calendarId, groupNodeSearchFilter } = action.payload

        const calendar = state.get(calendarId)

        if (!calendar) {
            return state
        }

        const newCalendarState: IConfigurableCalendar = {
            ...calendar,
            groupNodeSearchFilter,
        }

        return new Map(state).set(calendarId, newCalendarState)
    })

export default configurableCalendarReducer
