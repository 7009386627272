import { FieldProps } from 'formik'
import { Moment } from 'moment'
import * as React from 'react'

import ErrorableField from '../../Form/ErrorableField'
import TimeInput, { ITimeInputProps } from './TimeInput'

interface IFormikTimeInputOwnProps extends FieldProps {}

type TFormikTimeInputProps = IFormikTimeInputOwnProps & Pick<ITimeInputProps, 'description' | 'disabled' | 'translate'>

export default class FormikTimeInput extends React.Component<TFormikTimeInputProps> {
    handleTimeSelection = (time: string | Moment): void => {
        const {
            form: { setFieldValue },
            field: { name },
        } = this.props

        setFieldValue(name, time)
    }

    render(): React.ReactNode {
        const { field, description, disabled, form, translate = true } = this.props

        return (
            <ErrorableField field={field} form={form}>
                <TimeInput
                    changeAction={this.handleTimeSelection}
                    description={description}
                    disabled={disabled}
                    rawTimeValue={field.value}
                    translate={translate}
                />
            </ErrorableField>
        )
    }
}
