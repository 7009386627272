import { WebApiMock } from '../../test'
import MockAdapter from 'axios-mock-adapter/types'
import INavigationState from '../Types/INavigationState'
import { getTestNavigationSections, getTestNavigationSubsections } from './NavigationTestData'

export default class NavigationWebApiMock extends WebApiMock {
    constructor(axiosMock?: MockAdapter) {
        super(axiosMock)
    }

    mockNavigationItemsFetchRequests(
        { navigationSections, navigationSubsections }: INavigationState = {
            navigationSections: getTestNavigationSections(),
            navigationSubsections: getTestNavigationSubsections(),
        },
        statusCode = 200
    ): void {
        this.axiosMock.onGet('/NavigointiItem/Sections').reply(statusCode, navigationSections)
        this.axiosMock.onGet('/NavigointiItem/Subsections').reply(statusCode, navigationSubsections)
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getTestHistoryForNavigationItems() {
        return {
            sections: super.getHistoryForRequest('/NavigointiItem/Sections', 'get'),
            subsections: super.getHistoryForRequest('/NavigointiItem/Subsections', 'get'),
        }
    }
}
