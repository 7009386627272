import { IPermission } from 'interfaces/IActionPermission'
import TFunctionalityAction from '../Types/Actions/TFunctionalityAction'
import { IDataSourceItem } from '../../data-source-types'

export const getActionPermission = (
    item: IDataSourceItem,
    actionFunctionality: TFunctionalityAction
): IPermission | null => {
    const actionPermission = item.Kayttooikeudet.find(
        (permission) => permission.Toiminto === actionFunctionality.PermissionId
    )

    return actionPermission ?? null
}

type TAggregatedActionPermissions = {
    isAllowed: boolean
    disabledReason: Set<string>
    isDefined: boolean
}

export const getActionPermissions = (
    items: IDataSourceItem[],
    actionFunctionality: TFunctionalityAction
): TAggregatedActionPermissions | null => {
    if (items.length === 0) {
        return null
    }

    const aggregatedPermissions = items.reduce(
        (permissions, item) => {
            const actionPermission = getActionPermission(item, actionFunctionality)

            if (actionPermission === null) {
                return permissions
            }

            const { OnkoSallittu, SyyKieltoon } = actionPermission
            const { isAllowed, disabledReason } = permissions

            return {
                isDefined: true,
                // When a single item allows the action, allow it for all at this phase
                isAllowed: isAllowed === true ? true : OnkoSallittu ?? true,
                disabledReason:
                    SyyKieltoon && !disabledReason.has(SyyKieltoon) ? disabledReason.add(SyyKieltoon) : disabledReason,
            }
        },
        { isAllowed: false, disabledReason: new Set(), isDefined: false } as TAggregatedActionPermissions
    )

    return aggregatedPermissions ?? null
}
