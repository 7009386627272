import * as React from 'react'
import styled from '@emotion/styled'

interface IImageProps {
    children: string | number
    maxWidth: string
}

const StyledImg = styled.img<{ maxWidth: string }>`
    display: inline-block;
    height: 100%;
    max-width: ${({ maxWidth }) => maxWidth};
    object-fit: contain;
    width: auto;
`

/**
 * Image component for legacy use. Assumes the images to be in the path `<backend-repository>/OpteamNet/images`.
 */
const Image: React.FC<IImageProps> = ({ children, maxWidth }) => {
    const source = `../images/${children}`

    return <StyledImg maxWidth={maxWidth} src={source} />
}

export default Image
