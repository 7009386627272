import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typesafe-actions'
import styled from '@emotion/styled'

import { closeModalAction, FormModal } from '../../../../../modal'
import { IFormViewCustomFormComponentProps } from '../../../../../form-view-types'
import { createKasittelyera, ITapahtuma, processEventsForProcessingBatch } from '../../../../../tapahtuma'
import MainContent from './MainContent'

import { makeRequestTemplate } from '../../../../../rest-api'
import {
    dataSourceOverrideFetchParametersRemoveAction,
    dataSourceOverrideFetchParametersSetAction,
    dataSourceResetDataAction,
    fetchDataSourceDataThunk,
    selectIsDataSourceInitialized,
} from '../../../../../data-source'
import {
    EVENTS_LIST_DATA_SOURCE_ID,
    TRANSACTION_PREVIEW_DATA_SOURCE_ID,
} from '../../../../Constants/PalkkahallintoConstants'
import { BoundThunk } from '../../../../../generic-state'
import {
    asyncOperationFailedWithErrorDisplayThunk,
    asyncOperationStartedAction,
    asyncOperationSucceededAction,
    asyncOperationSucceededWithSuccessDisplayThunk,
} from '../../../../../async-operation'
import { resetAllSelectedListItemsAction } from '../../../../../configurable-list'
import { VUOROT_JA_VAPAAT_LIST_ID } from '../../../../'
import Styles from 'constants/Styles'

interface IDispatchProps {
    fetchDataSourceData: BoundThunk<typeof fetchDataSourceDataThunk>
    asyncOperationStarted: typeof asyncOperationStartedAction
    asyncOperationFailedWithErrorDisplay: BoundThunk<typeof asyncOperationFailedWithErrorDisplayThunk>
    asyncOperationSucceededWithSuccessDisplay: BoundThunk<typeof asyncOperationSucceededWithSuccessDisplayThunk>
    asyncOperationSucceeded: typeof asyncOperationSucceededAction
    dataSourceResetData: typeof dataSourceResetDataAction
    closeModal: typeof closeModalAction
    dataSourceOverrideFetchParametersSet: typeof dataSourceOverrideFetchParametersSetAction
    dataSourceOverrideFetchParametersRemove: typeof dataSourceOverrideFetchParametersRemoveAction
}

interface IStateProps {
    dataSourceInitialized: boolean
}

export interface IProcessEventToTransactionFormOwnProps extends IFormViewCustomFormComponentProps<ITapahtuma> {}

export interface IProcessEventToTransactionFormProps
    extends IProcessEventToTransactionFormOwnProps,
        IDispatchProps,
        IStateProps {}

interface IListFiltersParameters extends Record<string, unknown> {
    ToteumankasittelyeraIdt: number[]
}

const classNameTitleContainer = 'pln-Process-Modal-TitleContainer'
const classNameContentContainer = 'pln-Process-Modal-ContentContainer'
const classNameTitle = 'pln-Process-Modal-Title'

const StyledFormModal = styled(FormModal)`
    .${classNameTitleContainer} {
        padding: 10px 32px;
        background-color: ${Styles.mainColor.blue};
    }

    .${classNameContentContainer} {
        padding-right: 0;
        padding-left: 0;

        /* Override MUI's padding */
        &&& {
            padding-top: 0;
        }
    }

    .${classNameTitle} {
        color: white;
    }
`

const ProcessEventToTransactionFormUnconnected = ({
    selectedItems,
    formId,
    dataSourceInitialized,
    fetchDataSourceData,
    asyncOperationStarted,
    asyncOperationSucceeded,
    asyncOperationFailedWithErrorDisplay,
    asyncOperationSucceededWithSuccessDisplay,
    dataSourceResetData,
    closeModal,
    dataSourceOverrideFetchParametersSet,
    dataSourceOverrideFetchParametersRemove,
}: IProcessEventToTransactionFormProps) => {
    const dispatch = useDispatch()

    const [listFilterParameters, setListFilterParameters] = useState<null | IListFiltersParameters>(null)
    const [kasittelyeraCreationErrored, setKasittelyeraCreationErrored] = useState(false)

    useEffect(() => {
        dataSourceResetData(TRANSACTION_PREVIEW_DATA_SOURCE_ID)
        const { cleanup } = makeRequestTemplate({
            execute: async (cancelToken) => {
                asyncOperationStarted()

                const tapahtumaIds = selectedItems.map((tapahtuma) => tapahtuma.Id)

                const kasittelyeraIdFromServer = await createKasittelyera(tapahtumaIds, cancelToken)

                if (kasittelyeraCreationErrored) {
                    setKasittelyeraCreationErrored(false)
                }

                setListFilterParameters({
                    ToteumankasittelyeraIdt: [kasittelyeraIdFromServer],
                })
            },
            handleError: (e: AxiosError) => {
                setKasittelyeraCreationErrored(true)
                asyncOperationFailedWithErrorDisplay(e)
            },
        })

        return cleanup
    }, [
        asyncOperationFailedWithErrorDisplay,
        asyncOperationStarted,
        dataSourceResetData,
        kasittelyeraCreationErrored,
        selectedItems,
    ])

    useEffect(() => {
        if (!dataSourceInitialized || !listFilterParameters) {
            return
        }

        ;(async () => {
            dataSourceOverrideFetchParametersSet(TRANSACTION_PREVIEW_DATA_SOURCE_ID, {
                parameters: {
                    Filters: listFilterParameters,
                },
                overrideEverything: true,
            })

            await fetchDataSourceData(TRANSACTION_PREVIEW_DATA_SOURCE_ID)

            asyncOperationSucceeded()
        })()
    }, [
        asyncOperationSucceeded,
        dataSourceInitialized,
        dataSourceOverrideFetchParametersSet,
        fetchDataSourceData,
        listFilterParameters,
    ])

    if (kasittelyeraCreationErrored) {
        return null
    }

    const onClose = async () => {
        dataSourceOverrideFetchParametersRemove(TRANSACTION_PREVIEW_DATA_SOURCE_ID)
    }

    const handleSubmit = () => {
        makeRequestTemplate({
            execute: async (cancelToken) => {
                asyncOperationStarted()

                const processingBatchIds = listFilterParameters?.ToteumankasittelyeraIdt as number[]
                await processEventsForProcessingBatch(processingBatchIds, cancelToken)
                await fetchDataSourceData(EVENTS_LIST_DATA_SOURCE_ID)

                asyncOperationSucceededWithSuccessDisplay(
                    'payroll-management.ProcessEventToTransactionForm.submit.successMessage'
                )
                closeModal(formId)

                dispatch(resetAllSelectedListItemsAction(VUOROT_JA_VAPAAT_LIST_ID))

                onClose()
            },
            handleError: (e: AxiosError) => {
                asyncOperationFailedWithErrorDisplay(e)
            },
        })
    }

    const formikProps = {
        initialValues: {},
        onSubmit: handleSubmit,
    }

    const disableSubmit = !listFilterParameters

    return (
        <StyledFormModal
            classNameContentContainer={classNameContentContainer}
            classNameTitle={classNameTitle}
            classNameTitleContainer={classNameTitleContainer}
            disableSubmit={disableSubmit}
            formId={formId}
            formikProps={formikProps}
            modalId={formId}
            muiDialogProps={{ maxWidth: 'xl', fullWidth: true }}
            onClose={onClose}
            open
            title="payroll-management.ProcessEventToTransactionForm.mainTitle"
        >
            <MainContent />
        </StyledFormModal>
    )
}

const mapStateToProps = (state: RootState) => {
    const dataSourceInitialized = selectIsDataSourceInitialized(state, TRANSACTION_PREVIEW_DATA_SOURCE_ID)
    return {
        dataSourceInitialized,
    }
}

const mapDispatchToProps = {
    fetchDataSourceData: fetchDataSourceDataThunk,
    asyncOperationStarted: asyncOperationStartedAction,
    asyncOperationFailedWithErrorDisplay: asyncOperationFailedWithErrorDisplayThunk,
    asyncOperationSucceededWithSuccessDisplay: asyncOperationSucceededWithSuccessDisplayThunk,
    asyncOperationSucceeded: asyncOperationSucceededAction,
    dataSourceResetData: dataSourceResetDataAction,
    closeModal: closeModalAction,
    dataSourceOverrideFetchParametersSet: dataSourceOverrideFetchParametersSetAction,
    dataSourceOverrideFetchParametersRemove: dataSourceOverrideFetchParametersRemoveAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessEventToTransactionFormUnconnected)
