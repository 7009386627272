import * as React from 'react'
import { FormikProps } from 'formik'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from '@emotion/styled'

import { Button } from '../Buttons'
import Tooltip from '../Tooltip'
import { Translation } from '../../localization'
import Typography from '@material-ui/core/Typography'
import { isEmpty, isPlainObject } from 'lodash-es'

export interface ISubmitButtonWithValidationErrorsProps {
    children?: string
    disabled: boolean
    errors: FormikProps<any>['errors'] | string[]
    form?: string
    loading?: boolean
    onClick: () => void
}

type IErrorsProps = Pick<ISubmitButtonWithValidationErrorsProps, 'errors'>
const TooltipContentList = styled.div`
    color: rgb(109, 110, 113);
`

const getErrorMessages = (errors: ISubmitButtonWithValidationErrorsProps['errors']): string[] => {
    return Object.values(errors)
        .flatMap((error) =>
            isPlainObject(error) ? getErrorMessages(error as ISubmitButtonWithValidationErrorsProps['errors']) : error
        )
        .filter((error): error is string => typeof error === 'string')
}

const getUniqueErrorsMessages = (errors: ISubmitButtonWithValidationErrorsProps['errors']) => {
    const errorMessages = getErrorMessages(errors)

    return [...new Set(errorMessages)]
}

const Errors: React.FunctionComponent<IErrorsProps> = ({ errors }) => {
    const uniqueErrorMessages = getUniqueErrorsMessages(errors)

    if (uniqueErrorMessages.length === 1) {
        const error = uniqueErrorMessages[0] as string
        return <Typography>{Translation.has(error) ? Translation.translateKey(error) : error}</Typography>
    }

    return (
        <TooltipContentList>
            <ul>
                {uniqueErrorMessages.map((error) => (
                    <li key={error as string}>
                        <Typography>
                            {Translation.has(error as string)
                                ? Translation.translateKey(error as string)
                                : (error as string)}
                        </Typography>
                    </li>
                ))}
            </ul>
        </TooltipContentList>
    )
}

const LoadingSpinner = styled(CircularProgress)<{ size: number }>`
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    position: absolute;
    left: calc(50% - ${({ size }) => size / 2}px);
    top: calc(50% - ${({ size }) => size / 2}px);
`

const SubmitButtonWithValidationErrors: React.FC<ISubmitButtonWithValidationErrorsProps> = ({
    children = 'save-button',
    disabled,
    errors,
    form,
    loading = false,
    ...buttonProps
}) => {
    const hasErrors = !isEmpty(errors)

    return (
        <Tooltip title={hasErrors ? <Errors errors={errors} /> : null}>
            <div>
                <Button
                    disabled={disabled || hasErrors}
                    {...buttonProps}
                    form={form}
                    type="submit"
                    testId="STC_MODAL_SUBMIT_BTN"
                >
                    {Translation.has(children) ? Translation.translateKey(children) : children}
                </Button>
                {loading && <LoadingSpinner size={24} />}
            </div>
        </Tooltip>
    )
}

export default SubmitButtonWithValidationErrors
