import { createMuiTheme, Theme } from '@material-ui/core/styles'
import { useTheme } from '@emotion/react'
import MepcoLightTheme from './MepcoLightTheme'

export const AppTheme: Theme = createMuiTheme({
    typography: MepcoLightTheme.typography,
    colors: MepcoLightTheme.colors,
    componentExtensions: MepcoLightTheme.componentExtensions,
    tokens: MepcoLightTheme.tokens,
    typographyExtensions: MepcoLightTheme.typographyExtensions,
    eventTemplates: MepcoLightTheme.eventTemplates,
    legacyCalendarTheme: MepcoLightTheme.legacyCalendarTheme,
    legacyComponentStyles: MepcoLightTheme.legacyComponentStyles,
    legacySupplementaryColors: MepcoLightTheme.legacySupplementaryColors,
    palette: {
        primary: {
            main: MepcoLightTheme.colors.primaryTeal120,
        },
    },
})

export type PlanierTheme = typeof MepcoLightTheme
export const useAppTheme = (): PlanierTheme => useTheme() as PlanierTheme

export type EventColorId = 'event1' | 'event2' | 'event3' | 'event4' | 'event5' | 'event6'
export const selectableColorIds: EventColorId[] = ['event1', 'event2', 'event3', 'event4', 'event5', 'event6']

export const getPrimaryEventColor = (colorId: EventColorId): string => {
    return MepcoLightTheme.eventTemplates.primary[colorId]
}

export const getSecondaryEventColor = (colorId: EventColorId): string => {
    return MepcoLightTheme.eventTemplates.secondary[colorId]
}

export const getPreviewEventColor = (colorId: EventColorId): string => {
    return MepcoLightTheme.eventTemplates.preview[colorId]
}
