import { connect } from 'react-redux'
import * as React from 'react'
import Progress from 'components/molecules/Progress'
import { RootState } from 'typesafe-actions'
import styled from '@emotion/styled'

import OverlayUnderBody from 'components/molecules/OverlayUnderBody'
import { selectIsAsyncOperationIsInProgress } from '../../../async-operation'

interface IOwnProps {
    fullScreen?: boolean
    isLoading?: boolean
}

interface IStateProps {
    isLoading?: boolean
}

interface ILoadingIndicatorGlobalProps extends IOwnProps, IStateProps {}

/**
 * Latausindikaattori-overlay.
 * Tekee harmaan overlayn, jonka keskellä on spinneri. Estää käyttöliittymän käyttämisen lataamisen aikana.
 */

const Overlay = styled.div<{ isLoading: boolean }>`
    background-color: ${({ theme }) => theme.colors.neutralsGrey11}
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
    justify-content: center;
    align-items: center;

    ${({ isLoading }) =>
        isLoading &&
        `
        display: flex;
        position: fixed;
    `}
`

/**
 * Loading indicator overlay. Creates a grey overlay in the middle of which there's a spinner.
 * Prevents using the UI while it's loading.
 */
const LoadingIndicatorGlobalUnconnected: React.FC<ILoadingIndicatorGlobalProps> = ({ isLoading = false }) => {
    return (
        <OverlayUnderBody open={isLoading} zIndex={Number.MAX_SAFE_INTEGER}>
            <Overlay isLoading={isLoading}>
                <Progress size={200} />
            </Overlay>
        </OverlayUnderBody>
    )
}

const mapStateToProps = (state: RootState, { isLoading }: IOwnProps): IStateProps => {
    const globalAsyncOperationInProgress = selectIsAsyncOperationIsInProgress(state, 'global')

    return {
        isLoading: globalAsyncOperationInProgress || isLoading,
    }
}

export default connect(mapStateToProps)(LoadingIndicatorGlobalUnconnected)
