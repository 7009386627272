import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import * as React from 'react'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'

import { haeOsiokohtaisetAsetukset } from '../../thunks/OsiokohtaisetAsetuksetThunks'
import { IOsiokohtaisetAsetuksetState } from '../../reducers/OsiokohtaisetAsetuksetReducer'
import Heading from 'components/atoms/Heading'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import OsiokohtaisetAsetuksetForm from '../organisms/OsiokohtaisetAsetuksetForm'
import { BoundThunk } from '../../../generic-state'
import { selectIsAsyncOperationIsInProgress } from '../../../async-operation'

const Container = styled.div`
    margin: 1rem;
    max-width: 70rem;
`

export interface IPathParams {
    osionId: string
}

interface IOwnProps extends RouteComponentProps<IPathParams> {}

interface IStateProps {
    osiokohtaisetAsetukset: IOsiokohtaisetAsetuksetState
    isLoading: boolean
}

interface IDispatchProps {
    haeOsiokohtaisetAsetukset: BoundThunk<typeof haeOsiokohtaisetAsetukset>
}

export interface IOsiokohtaisetAsetuksetProps extends IStateProps, IDispatchProps, IOwnProps {}

export class OsiokohtaisetAsetuksetUnconnected extends React.Component<IOsiokohtaisetAsetuksetProps> {
    static defaultProps = {
        isLoading: false,
    }

    async componentDidMount(): Promise<void> {
        await this.props.haeOsiokohtaisetAsetukset(Number(this.props.match.params.osionId))
    }

    render(): React.ReactNode {
        const { asetukset, nimi } = this.props.osiokohtaisetAsetukset

        // Kun komponentti mountataan, `this.props.isLoading` on aluksi false ja vaihtuu
        // true:ksi vasta kun `this.props.haeOsiokohtaisetAsetukset` kutsutaan.
        // Tarkastamalla jos asetuksia ei ole, voidaan estää formin turha rendaus ennen kuin
        // lataus on käynnistynyt.
        const naytaFormi = asetukset.size !== 0 && !this.props.isLoading

        const naytaOtsikko = nimi && !this.props.isLoading

        return (
            <Container>
                <LoadingIndicatorInline isLoading={this.props.isLoading} />

                {naytaOtsikko && (
                    <React.Fragment>
                        <Heading>OsiokohtaisetAsetukset.osionNimi</Heading>
                        <Heading doNotTranslate>{nimi}</Heading>
                    </React.Fragment>
                )}

                {naytaFormi && <OsiokohtaisetAsetuksetForm asetukset={asetukset} />}
            </Container>
        )
    }
}

const mapStateToProps = (state: RootState): IStateProps => {
    const { osiokohtaisetAsetukset } = state
    return {
        osiokohtaisetAsetukset,
        isLoading: selectIsAsyncOperationIsInProgress(state, 'OsiokohtaisetAsetukset'),
    }
}

const mapDispatchToProps = {
    haeOsiokohtaisetAsetukset,
}

export default connect(mapStateToProps, mapDispatchToProps)(OsiokohtaisetAsetuksetUnconnected)
