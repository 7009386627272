import * as React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import { Translate, TTranslatable } from '../../../localization'

interface IH2Props {
    label: React.ReactNode
}

interface ITranslatedH3Props {
    label: TTranslatable
}

const Header = styled(Typography)`
    margin-bottom: 10px;
`

const TranslatedH3: React.FunctionComponent<ITranslatedH3Props> = ({ label }) => {
    return <Translate translate={{ label }}>{({ label: H3Label }) => <H3 label={H3Label} />}</Translate>
}

const H3: React.FunctionComponent<IH2Props> = ({ label }) => <Header variant="h3">{label}</Header>

export { H3, TranslatedH3 }
