import { createAsyncThunk } from '@reduxjs/toolkit'
import { IEvent } from '../../event'
import { fetchEventsForEmployee, fetchEventTypeCategories } from '../Api/EmployeeEventsApi'
import moment, { Moment } from 'moment'
import { formatForDataUsage } from '../../dates'
import { IEmployeeDataSourceItem } from '../../messaging/Types/IEmployeeDataSourceItem'
import { IIdentifier } from '../../event/Types/IEvent'
import { CancelTokenSource } from 'axios'
import { createCancelToken } from '../../rest-api'
import { EActionFunctionalityType, handleViewEngineActionThunk, TFunctionalityAction } from '../../view-engine'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { fetchDataSourceDataThunk } from '../../data-source'
import { IThunkBaseAction } from '../../generic-state'
import { CancelEventAction, DeleteEventAction } from '../Utils/eventActions'
import { setSelectedEvents } from './EmployeeCalendarReducer'

let cancelTokenSource: CancelTokenSource | null = null

export const getEventsForEmployee = createAsyncThunk<IEvent[], { employee: IEmployeeDataSourceItem; month?: Moment }>(
    'employeeCalendar-getEvents',
    async ({ employee, month }, { rejectWithValue }) => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('canceled by user')
        }

        cancelTokenSource = createCancelToken()

        try {
            const period = {
                Alku: formatForDataUsage(moment(month).subtract(1, 'months').startOf('month')),
                Loppu: formatForDataUsage(moment(month).add(1, 'months').endOf('month')),
            }
            const results = await fetchEventsForEmployee([employee.Id], period, cancelTokenSource.token)
            return results.ListData
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const getEventTypeCategories = createAsyncThunk<IIdentifier[]>(
    'employeeCalendar-getTypeCategories',
    async (_, { rejectWithValue }) => {
        try {
            const result = await fetchEventTypeCategories()
            return result.ListData
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

export const initEventAction =
    (action: TFunctionalityAction, events: IEvent[], employee?: IEmployeeDataSourceItem): IThunkBaseAction =>
    async (dispatch, getState) => {
        if (action.FunctionalityType === EActionFunctionalityType.Immediate) {
            await (dispatch as ThunkDispatch<any, any, Action>)(
                fetchDataSourceDataThunk('EmployeeCalendar', {
                    dynamicRequestParameters: {
                        parameters: { Filters: { Ids: events.map(({ Id }) => Id) } },
                    },
                })
            )
        }

        const onSubmitCallback = () => {
            if (!employee) {
                return
            }

            // un-select events if they were deleted or canceled
            if (
                [CancelEventAction.DataSourceActionId, DeleteEventAction.DataSourceActionId].includes(
                    action.DataSourceActionId
                )
            ) {
                dispatch(setSelectedEvents([]))
            }

            const month = getState().employeeCalendar.selectedMonth

            dispatch(getEventsForEmployee({ employee, month }))
        }

        await dispatch(
            handleViewEngineActionThunk({
                items: events,
                functionalityAction: action,
                dataSourceId: 'EmployeeCalendar',
                onSubmitCallback,
                overrideDataSourceId: true,
            })
        )
    }
