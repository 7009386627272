import styled from '@emotion/styled'
import Dropdown from '../Dropdown/Dropdown'
import { isEmpty } from 'lodash-es'
import React, { lazy, Suspense } from 'react'
import ChipWithPopover from '../../ChipWithPopover'

type Props = {
    title: string
    options: { Id: string | number; Name: string; additionalOptions?: AdditionalOptionsProps }[]
    value: ChipValue[]
    onChange: (values: ChipValue[]) => void
    placeholder?: string
    required?: boolean
    additionalOptions?: AdditionalOptionsProps
}

export type AdditionalOptionsProps = {
    pickerType: 'Dropdown'
    defaultValue?: any
    pickerProps?: Record<any, any>
}

export type ChipValue = {
    value?: string | number
    additionalOptionsValue?: any
}

const ChipContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 20px;
`

const AdditionalOptionsContainer = styled.div`
    margin-top: 10px;
`

const AdditionalOptionsPicker = ({
    additionalOptions,
    value,
    onAdditionalOptionsChange,
}: {
    additionalOptions: AdditionalOptionsProps
    value: (string | number)[]
    onAdditionalOptionsChange: (value: (string | number)[]) => void
}): JSX.Element | null => {
    const getPickerComponent = (pickerType: string) => {
        switch (pickerType) {
            case 'Dropdown':
                return () => import('../Dropdown/Dropdown')

            default:
                return null
        }
    }

    const pickerComponent = getPickerComponent(additionalOptions?.pickerType as string)

    if (!pickerComponent) {
        return null
    }

    const Component = lazy(pickerComponent)

    return (
        <Suspense fallback={null}>
            <Component {...additionalOptions?.pickerProps} value={value} onChange={onAdditionalOptionsChange} />
        </Suspense>
    )
}

const MultiChipPicker = ({ title, options, onChange, placeholder, required, value = [] }: Props): JSX.Element => {
    const handleOnChange = (values: (string | number)[]) => {
        const changedValues: ChipValue[] = values.map((val) => {
            const option = options.find((opt) => opt.Id === val)
            const existingValue = value.find((v) => v.value === val)

            return {
                value: option?.Id,
                additionalOptionsValue:
                    existingValue?.additionalOptionsValue || option?.additionalOptions?.defaultValue,
            }
        })

        onChange(changedValues)
    }

    const handleAdditionalOptionsChange = (optionId: number | string, additionalOptionsValue: (string | number)[]) => {
        const newValues = [...value]

        const changedValue = newValues.find((v) => v.value === optionId)

        if (!changedValue) {
            return
        }

        changedValue.additionalOptionsValue = additionalOptionsValue

        onChange(newValues)
    }

    const handleDelete = (item: string | number) => {
        const newValues = value.filter((v) => item !== v.value)
        onChange(newValues)
    }

    const selectionChips = value.map((selectedOption) => {
        const option = options.find(({ Id }) => Id === selectedOption.value)

        if (!option) {
            return null
        }

        const { Id, Name, additionalOptions } = option

        return (
            <ChipWithPopover key={`${Name}-${Id}`} label={Name} onDelete={() => handleDelete(Id)}>
                {additionalOptions && (
                    <AdditionalOptionsContainer>
                        <AdditionalOptionsPicker
                            additionalOptions={additionalOptions}
                            value={selectedOption.additionalOptionsValue}
                            onAdditionalOptionsChange={(values) => handleAdditionalOptionsChange(Id, values)}
                        />
                    </AdditionalOptionsContainer>
                )}
            </ChipWithPopover>
        )
    })

    return (
        <div>
            <Dropdown
                label={title}
                value={value.map((v) => v.value) as number[]}
                options={options}
                onChange={handleOnChange}
                placeholder={placeholder}
                disabled={!options.length}
                required={required}
                autoFocusFilter
                isMultiselect
            />
            {!isEmpty(value) && <ChipContainer>{selectionChips}</ChipContainer>}
        </div>
    )
}

export default MultiChipPicker
