import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { CancelToken } from 'axios'
import { RootState } from 'typesafe-actions'

import { TagList } from '../generic-components'
import { Dropdown, DropdownWithDynamicApiSearch } from '../generic-components-dropdown'
import {
    EValuePickerType,
    IDropdownValuePickerOwnProps,
    ITagListValuePickerOwnProps,
    selectValuePickerValue,
    TValuePickerWithOptionsOwnProps,
} from '../value-picker'
import {
    fetchValuePickerWithOptionsDataThunk,
    setValuePickerWithOptionsValueThunk,
} from './Thunks/ValuePickerWithOptionsThunks'
import { BoundThunk } from '../generic-state'
import {
    selectDidStoredOptionsComponentFetchFail,
    selectIsStoredOptionsComponentFetching,
    selectStoredOptionsComponentAllOptions,
    storedOptionsInitializeAction,
    TStoredOptionsSelectedItemIds,
} from '../stored-options'

import ValueLink from './Components/ValueLink'
import { getLogger } from '../log'
import { isEqual } from 'lodash-es'

const Log = getLogger('value-picker.ValuePickerWithOptions')

interface IStateProps {
    options: any
    fetchFailed: boolean
    fetching: boolean
    selected: TStoredOptionsSelectedItemIds
}

interface IDispatchProps {
    fetchValuePickerWithOptionsData: BoundThunk<typeof fetchValuePickerWithOptionsDataThunk>
    setValuePickerWithOptionsValue: BoundThunk<typeof setValuePickerWithOptionsValueThunk>
    storedOptionsInitialize: typeof storedOptionsInitializeAction
}

type TValuePickerWithOptionsProps = IStateProps & IDispatchProps & TValuePickerWithOptionsOwnProps

export const ValuePickerWithOptionsUnconnected: React.FC<TValuePickerWithOptionsProps> = ({
    disabled,
    enableTextSearch = true,
    fetchFailed,
    fetchValuePickerWithOptionsData,
    fetching,
    itemIdField,
    multiselect,
    options,
    selected,
    textSearchProps,
    valuePickerId,
    valuePickerType,
    storedOptionsInitialize,
    useDynamicApiSearch,
    setValuePickerWithOptionsValue,
    onChange,
    useCachedValuesForOptions = false,
    required = false,
    contextId,
    ...props
}) => {
    useEffect(() => {
        storedOptionsInitialize(valuePickerId)
        fetchValuePickerWithOptionsData(valuePickerId, {
            useCachedValues: useCachedValuesForOptions,
        })
    }, [fetchValuePickerWithOptionsData, useCachedValuesForOptions, valuePickerId, storedOptionsInitialize])

    const fetchOptionsWithDynamicApiRequests = useCallback(
        async (userInput: string, cancelToken: CancelToken) => {
            await fetchValuePickerWithOptionsData(
                valuePickerId,
                { useCachedValues: false },
                { Vapaatekstihaku: userInput },
                cancelToken
            )
        },
        [fetchValuePickerWithOptionsData, valuePickerId]
    )

    const handleValueSelect = useCallback(
        (newSelected: any) => {
            if (isEqual(selected, newSelected)) {
                return
            }

            setValuePickerWithOptionsValue(valuePickerId, newSelected, contextId)

            onChange && onChange(newSelected)
        },
        [onChange, selected, setValuePickerWithOptionsValue, valuePickerId, contextId]
    )

    const valuePickerWithOptionsCommonProps = {
        disabled: disabled || fetchFailed,
        enableTextSearch,
        filteringProps: textSearchProps,
        isLoading: fetching,
        itemIdField,
        multiselect,
        translate: false,
    }

    switch (valuePickerType) {
        case EValuePickerType.DropdownV2:
        case EValuePickerType.Dropdown: {
            const {
                text,
                itemOptionLabelFields,
                itemValueLabelFields = ['Id', 'Nimi'],
                dropdownWidth,
                dropdownMenuWidth,
                singleValueLinkStart = null,
                singleValueLinkTooltip = null,
                valueFieldType,
                spaceReservedForChipSelectionCount,
                errors,
            } = props as IDropdownValuePickerOwnProps

            const dropdownProps = {
                ...valuePickerWithOptionsCommonProps,
                label: text.Label,
                defaultText: fetchFailed ? text.FetchError : props.note ?? text.Label,
                itemOptionLabelFields,
                itemValueLabelFields,
                textForMultipleSelected: text.MultipleSelected,
                selectAction: handleValueSelect,
                items: options,
                values: selected,
                required,
                valueFieldType,
                menuWidth: dropdownMenuWidth,
                width: dropdownWidth,
                spaceReservedForChipSelectionCount,
                errors,
                valuePickerId,
            }

            const dropdown = useDynamicApiSearch ? (
                <DropdownWithDynamicApiSearch fetchData={fetchOptionsWithDynamicApiRequests} {...dropdownProps} />
            ) : (
                <Dropdown {...dropdownProps} disabled={dropdownProps.disabled || fetching} />
            )

            const valueLink =
                singleValueLinkStart && singleValueLinkTooltip ? (
                    <ValueLink
                        tooltip={singleValueLinkTooltip}
                        valueLinkStart={singleValueLinkStart}
                        valuePickerValues={selected}
                    />
                ) : null

            return (
                <>
                    {dropdown}
                    {valueLink}
                </>
            )
        }
        case EValuePickerType.TagList: {
            const { mainColorField, supportColorField, itemLabelFields } = props as ITagListValuePickerOwnProps
            const tagListProps = {
                ...valuePickerWithOptionsCommonProps,
                mainColorField,
                supportColorField,
                itemLabelFields,
                tagInfo: options,
                value: selected,
                onChange: handleValueSelect,
            }
            return <TagList {...tagListProps} />
        }
        default:
            Log.error('no matching value picker type found')
            return null
    }
}

const mapStateToProps = (state: RootState, { valuePickerId }: TValuePickerWithOptionsOwnProps): IStateProps => ({
    options: selectStoredOptionsComponentAllOptions(state, valuePickerId),
    fetchFailed: selectDidStoredOptionsComponentFetchFail(state, valuePickerId),
    fetching: selectIsStoredOptionsComponentFetching(state, valuePickerId),
    selected: selectValuePickerValue(state, valuePickerId) as ReadonlySet<number>,
})

const mapDispatchToProps = {
    fetchValuePickerWithOptionsData: fetchValuePickerWithOptionsDataThunk,
    setValuePickerWithOptionsValue: setValuePickerWithOptionsValueThunk,
    storedOptionsInitialize: storedOptionsInitializeAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ValuePickerWithOptionsUnconnected)
