import { WebApiMock } from '../../test'
import MockAdapter from 'axios-mock-adapter/types'
import { AxiosRequestConfig } from 'axios'

import {
    getTestDropdownValuePickerConfiguration,
    getTestTagListValuePickerConfiguration,
    getTestValuePickerConfiguration,
    getTestDataForValuePickerWithOptions,
    getTestValuePickerDataurl,
} from './ValuePickerTestData'

export default class ValuePickerWebApiMock extends WebApiMock {
    constructor(axiosMock?: MockAdapter) {
        super(axiosMock)
    }

    mockTagListValuePickerConfigurationFetchRequest(
        configurations = [getTestTagListValuePickerConfiguration()],
        statusCode = 200
    ): void {
        this.mockValuePickerConfigurationFetchRequest(configurations, statusCode)
    }

    mockDropdownValuePickerConfigurationFetchRequest(
        configurations = [getTestDropdownValuePickerConfiguration()],
        statusCode = 200
    ): void {
        this.mockValuePickerConfigurationFetchRequest(configurations, statusCode)
    }

    mockValuePickerConfigurationFetchRequest(
        configurations = [getTestValuePickerConfiguration()],
        statusCode = 200
    ): void {
        this.axiosMock.onGet('/ViewEngine/ValuePicker').reply(statusCode, configurations)
    }

    mockValuePickerDataFetchRequest(
        valuePickerDataUrl = getTestValuePickerDataurl(),
        data = getTestDataForValuePickerWithOptions(),
        statusCode = 200
    ): void {
        this.axiosMock.onPost(valuePickerDataUrl).reply(statusCode, data)
    }

    getHistoryForValuePickerConfigurationFetchRequest(): AxiosRequestConfig | null {
        return this.getHistoryForRequest('/ViewEngine/ValuePicker', 'get')
    }
}
