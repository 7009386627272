import * as React from 'react'
import styled from '@emotion/styled'

import ErrorMessage from '../ErrorMessage'

interface IErrorableComponentProps {
    children: React.ReactNode
    error: string | null
    isSpaceAlwaysReservedForError?: boolean
}

const ErrorTextContainer = styled.div`
    min-height: 21px;
    display: flex;
    align-items: center;
`

/**
 * Component to display a validation error below a component.
 */
const ErrorableComponent: React.FC<IErrorableComponentProps> = ({
    error,
    children,
    isSpaceAlwaysReservedForError = false,
}) => {
    return (
        <React.Fragment>
            {children}
            {(isSpaceAlwaysReservedForError || error) && (
                <ErrorTextContainer>{error && <ErrorMessage>{error as string}</ErrorMessage>}</ErrorTextContainer>
            )}
        </React.Fragment>
    )
}

export default ErrorableComponent
