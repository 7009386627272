import * as React from 'react'
import { useEffect, useState } from 'react'
import TextInputV2 from '../TextInput/TextInputV2'
import moment, { Moment } from 'moment'

export interface ITimeInputProps {
    value: Moment | null
    disabled?: boolean
    onChange: (input: Moment | null) => void
    label?: string
    placeholder?: string
    errors?: string
    required?: boolean
    valueInputId?: string
}

export const validTimeInput =
    /^([0-9]|([01]?[0-9]|2[0-3])|[0-9]|([01]?[0-9]|2[0-3])(:|\.)|[0-9]|([01]?[0-9]|2[0-3])|[0-9]|([01]?[0-9]|2[0-3])(:|\.)([0-5][0-9]|[0-5]))$/

const TimeInputV2: React.FC<ITimeInputProps> = ({
    value,
    disabled,
    onChange,
    label,
    errors,
    required,
    valueInputId,
}) => {
    const [val, setValue] = useState<string>('')

    useEffect(() => {
        if (moment.isMoment(value)) {
            setValue(value.format('HH:mm'))
        }
    }, [value])

    const _onChange = (input: string): void => {
        if (input) {
            //TEST CASES
            // //VALID
            // 4: 4
            // 4: 00
            // 6: 50
            // 5: 05
            // 23: 3
            // 23: 59
            // 3.54
            // 08: 30
            // 12.
            // 2.
            // 00: 30
            // 03.39
            // 04
            // 04.2
            // 4.30
            // 2
            // 23
            // 09
            // 9:
            // 4.3
            // 12: 3
            // 8: 30
            // 8: 09

            // //INVALID
            // kd: 33
            // 93
            // 4: 6
            // 0.9
            // 00: 393
            // kjdjhd
            // 90

            if (validTimeInput.test(input)) {
                let _refinedInput = input.replace('.', ':')

                //Autoinsert prefix 0 if start is > 2 or length is 2 and already contains ":"

                if (
                    (_refinedInput.length === 1 && parseInt(_refinedInput) > 2) ||
                    (_refinedInput.length === 2 && _refinedInput.includes(':'))
                ) {
                    _refinedInput = '0' + _refinedInput
                }

                //Autoinsert ":" if it is not already in the input, and the next step is to input minutes. That is the case when user is adding a new character
                //and the already put input is either already two digits or first digit is larget than 2
                if (
                    !_refinedInput.includes(':') &&
                    (_refinedInput.length === 2 || (_refinedInput.length === 1 && parseInt(_refinedInput) > 2)) &&
                    val.length < _refinedInput.length
                ) {
                    _refinedInput = _refinedInput + ':'
                }

                setValue(_refinedInput)

                if (input.length < 5) {
                    onChange(null)
                } else {
                    const parts = _refinedInput.split(':')

                    const [hours, minutes] = parts.map((x) => parseInt(x))

                    if (hours !== undefined && hours < 24 && minutes !== undefined && minutes < 60) {
                        const newValue = moment().set('hour', hours).set('minutes', minutes).second(0)

                        onChange(newValue)
                    }
                }
            }
        } else {
            setValue(input)
            onChange(null)
        }
    }

    const onBlur = () => {
        //When user off-focuses input and the input is partial but valid, we try to autocomplete the result

        if (val.length <= 5) {
            let newValue = null

            switch (val.length) {
                case 4:
                    newValue = val + '0'
                    break

                case 3:
                    newValue = val + '00'
                    break

                case 2:
                    newValue = val + ':00'
                    break

                case 1:
                    newValue = '0' + val + ':00'
                    break

                default:
                    break
            }

            if (newValue !== null) {
                const parts = newValue.split(':')

                const [hours, minutes] = parts.map((x) => parseInt(x))

                if (hours !== undefined && hours < 24 && minutes !== undefined && minutes < 60) {
                    setValue(newValue)

                    const newMomentValue = moment().set('hour', hours).set('minutes', minutes)

                    onChange(newMomentValue)
                }
            }
        }
    }

    return (
        <TextInputV2
            errors={errors}
            onBlur={onBlur}
            value={val}
            placeholder={'HH:mm'}
            label={label}
            isRequiredField={required}
            onChange={_onChange}
            disabled={disabled}
            type="text"
            overrideStyle={{ width: '128px' }}
            valueInputId={valueInputId}
        />
    )
}

export default TimeInputV2
