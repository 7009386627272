import { TDataSourceItemId } from '../../data-source-types'
import IMessage from './IMessage'
import { IEvent } from '../../event'
import { User } from '../../user'

export enum EMessageType {
    MobileMessage = 1,
    InfoMessage = 2,
    Comment = 3,
}

export interface IMessageThreadStatusUpdatePayload {
    ThreadId: TDataSourceItemId
    MessageId?: TDataSourceItemId
    EventId?: TDataSourceItemId
    IsRead: boolean
}

export interface IEmployeeIdentifier {
    Id: number
    Name: string
    Nimi?: string
}

export default interface IMessageThread {
    Id: number
    EmployeeId?: number
    Employee: IEmployeeIdentifier
    Type: EMessageType
    Title: string
    MessageCount: number
    LatestMessage: IMessage
    CreatedTimestamp: string
    HasUnreadMessages: boolean
    IsVisibleForEmployee: boolean
    IsVisibleForExternalUser: boolean
    Event: IEvent
    ThreadStartedCreatedByLogin: User | null
}
