import PropTypes from 'prop-types'
import * as React from 'react'
import reactCss from 'reactcss'
import SvgIcon from '@material-ui/core/SvgIcon'
import styled from '@emotion/styled'

import Styles from 'constants/Styles'
import ISvgData from 'interfaces/ISvgData'

interface IProps {
    svgData: ISvgData
    color?: string
    hoverEffect: boolean
}

interface IStyledSvgIconProps extends React.ComponentProps<typeof SvgIcon> {
    hoverEffect: boolean
}

const StyledSvgIcon = styled<React.FC<IStyledSvgIconProps>>(({ hoverEffect, ...rest }) => <SvgIcon {...rest} />)`
    ${(hoverEffect) =>
        hoverEffect
            ? `:hover {
                background-color: ${Styles.mainColor.blue};
            }`
            : undefined}
`

/**
 * SVG-Ikoni.
 * Vastaanottaa svgData - propertyn välityksellä olioita jotka sisältävät
 * halutun svg-kuvan pathit ja tyylit. Oletusväri on ikonien oletusharmaa.
 * Color-propertyllä voidaan ikonin väriä vaihtaa.
 *
 * Esimerkki svgData-oliosta:
 * nappi: {
 *      viewBox: '0 -1 23 26',
 *      svgRootStyle: {
 *          width: '1.27em'
 *      },
 *      paths: [
 *          {
 *              path: 'M0,0h24v24H0V0z',
 *              style: { fill: red }
 *          },
 *          {
 *              path: 'M0,1823h4....',
 *          }
 *      ]
 * }
 * Esimerkki peruskäytöstä
 * <IconSvg svgData={svgKuvat.nappi} />
 *
 * tai jos väriä halutaan vaihtaa
 * <IconSvg
 *      color={#FFF}
 *      svgData={svgKuvat.nappi} />
 *
 */

export default class IconSvg extends React.Component<IProps> {
    static displayName = 'IconSvg'
    static defaultProps: any
    static propTypes: any

    constructor(props: IProps) {
        super(props)
    }

    private styles() {
        return reactCss({
            default: {
                icon: {
                    color: this.props.color,
                },
            },
        })
    }

    private getIconPaths() {
        return this.props.svgData.paths.map((path, i) => {
            return <path d={path.path} key={i} style={path.style} />
        })
    }

    render(): React.ReactNode {
        const {
            svgData: { svgRootStyle = {} },
            hoverEffect,
        } = this.props

        const mergedStyles = { ...this.styles().icon, ...svgRootStyle }

        return (
            <StyledSvgIcon hoverEffect={hoverEffect} style={mergedStyles} viewBox={this.props.svgData.viewBox}>
                {this.getIconPaths()}
            </StyledSvgIcon>
        )
    }
}

IconSvg.defaultProps = {
    color: Styles.mainColor.darkGrey,
    hoverEffect: false,
}

IconSvg.propTypes = {
    color: PropTypes.string,
    hoverEffect: PropTypes.bool,
    svgData: PropTypes.shape({
        paths: PropTypes.arrayOf(PropTypes.object),
        svgRootStyle: PropTypes.object,
        viewBox: PropTypes.string,
    }),
}
