import EValuePickerType from '../types/EValuePickerType'

const isValuePickerWithOptions = (valuePickerType: EValuePickerType): boolean => {
    return (
        valuePickerType === EValuePickerType.Dropdown ||
        valuePickerType === EValuePickerType.TagList ||
        valuePickerType === EValuePickerType.DropdownV2
    )
}

export default isValuePickerWithOptions
