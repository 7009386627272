import { useCallback, useState, useRef } from 'react'
import * as React from 'react'

import DayRangePickerV2 from '../DayRangePicker/DayRangePickerV2'
import { TDateStringDataFormat } from '../../../dates'
import useWeekDays from './useWeekDays'
import styled from '@emotion/styled'

type TDayRangePickerProps = React.ComponentProps<typeof DayRangePickerV2>

interface IDayRangePickerWithWeekDaysProps
    extends Pick<
        TDayRangePickerProps,
        | 'required'
        | 'onBlur'
        | 'startLabel'
        | 'endLabel'
        | 'shouldDateBeDisabled'
        | 'isStartDayErrored'
        | 'isEndDayErrored'
        | 'validationErrorStartDay'
        | 'validationErrorEndDay'
        | 'isFieldErrored'
        | 'validationError'
    > {
    value: TDateStringDataFormat[]
    onChange: (selectedDays: TDateStringDataFormat[]) => void
    errors?: string
    /**
     * Set the week days that are selected by default.
     * Uses the ISO week date system, i.e. 1 - 7 starting
     * from Monday.
     *
     * By default is Monday to Friday.
     */
    defaultSelectedWeekDays?: number[]
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

/**
 * Component to select multiple dates by week days.
 *
 * Note that unlike the DayRangePicker, which selects simply a range of dates (start and
 * end), this component actually selects a list of dates within the range the user selects.
 * I.e. the user selects the start and end dates but the component selects the dates
 * within that range by the week days currently selected.
 *
 * Note that if the component's value is changed from any other source than by
 * the component itself, the given value is reset at least for now. This is because
 * of how the component works. The start and end date are saved within the component's
 * internal state and those dates don't necessarily have to be within the value array,
 * if for example the start date is on Sunday but we have selected only Monday on the week
 * day selection. Because of that we can't properly determine the week day selections
 * from any externally given input. This shouldn't be an issue at least almost ever,
 * because usually the only time the value is changed externally, would be to reset
 * the data (for example when a form is reset) but it's still something to keep in mind.
 */
const DayRangePickerWithWeekDays: React.FC<IDayRangePickerWithWeekDaysProps> = ({
    startLabel,
    endLabel,
    value,
    onChange,
    defaultSelectedWeekDays = [1, 2, 3, 4, 5],
    required = false,
    onBlur,
    shouldDateBeDisabled,
    isEndDayErrored,
    isStartDayErrored,
    validationErrorEndDay,
    validationErrorStartDay,
    validationError,
    isFieldErrored,
    errors,
}) => {
    const [{ rangeStart, rangeEnd }, setRange] = useState<{
        rangeStart: null | TDateStringDataFormat
        rangeEnd: null | TDateStringDataFormat
    }>({ rangeStart: null, rangeEnd: null })

    const copiedValueRef = useRef<typeof value>(value)

    const handleChange: IDayRangePickerWithWeekDaysProps['onChange'] = useCallback(
        (newDates) => {
            copiedValueRef.current = newDates
            onChange(newDates)
        },
        [onChange]
    )

    const { getAllDatesWithinRangeByWeekdays, WeekDaySelectionComponent, weekDaySelectionComponentProps } = useWeekDays(
        rangeStart,
        rangeEnd,
        defaultSelectedWeekDays,
        handleChange
    )

    const handleChangeForDateRange: TDayRangePickerProps['onChange'] = ({ start, end }) => {
        setRange({ rangeStart: start, rangeEnd: end })

        const newValue = !start || !end ? [] : getAllDatesWithinRangeByWeekdays(start, end)

        copiedValueRef.current = newValue

        onChange(copiedValueRef.current)
    }

    return (
        <Container>
            <DayRangePickerV2
                endDay={rangeEnd}
                endLabel={endLabel}
                isEndDayErrored={isEndDayErrored}
                isFieldErrored={isFieldErrored}
                isStartDayErrored={isStartDayErrored}
                onBlur={onBlur}
                onChange={handleChangeForDateRange}
                required={required}
                shouldDateBeDisabled={shouldDateBeDisabled}
                startDay={rangeStart}
                startLabel={startLabel}
                validationError={validationError}
                validationErrorEndDay={validationErrorEndDay}
                validationErrorStartDay={validationErrorStartDay}
                errors={errors}
            />
            <WeekDaySelectionComponent {...weekDaySelectionComponentProps} />
        </Container>
    )
}

export default DayRangePickerWithWeekDays
