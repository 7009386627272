import * as React from 'react'

import styled from '@emotion/styled'

import { TAllValues, TNewlySelected, TSelectedValues } from '../../Interfaces'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import { Translation } from '../../../../localization'
import { PADDING } from '../../Constants'
import { ReadOnlyText, RequiredIndicator } from '../../../../generic-components'
import { useAppTheme } from '../../../../theme'
import { getTestId } from '../../../../test/automationTestUtils'

interface IValueFieldProps<T> {
    defaultText: string
    label?: string
    disabled: boolean
    isLoading: boolean
    isRequired?: boolean
    itemIdField: keyof T
    itemValueLabelFields: (keyof T)[]
    items: T[]
    onClick: (e: React.MouseEvent) => void
    selectedNewItems: TNewlySelected<T>
    selectedValueItems: TSelectedValues<T>
    textForMultipleSelected: string
    translate: boolean
    values: TAllValues<T>
    valueTextOverride?: string
    typeName: undefined | string
    multiselect: boolean
    valueItems: ReadonlyMap<T[keyof T], T>
    errors?: string
    isReadOnly?: boolean
    valuePickerId?: string
}

interface IContainerProps {
    disabled?: boolean
    errors?: string
    isReadOnly?: boolean
}

const Container = styled.button<IContainerProps>`
    border: 1px solid
        ${({ errors, theme }) =>
            errors
                ? theme.componentExtensions.inputs.borderInputError
                : theme.componentExtensions.inputs.borderInputDefault};

    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.tokens.radiusDefault};
    display: flex;
    flex-direction: column;
    padding-right: ${PADDING}px;
    padding-left: 14px;
    height: 44px;
    width: 100%;
    justify-content: center;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    transition: background-color ${({ theme }) => theme.tokens.transitionQuick};
    background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDefault};

    &:hover {
        background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDropdownHover};
    }

    &:focus {
        border-color: transparent;
        outline: 2px solid ${({ theme }) => theme.componentExtensions.inputs.borderInputFocus};
        background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDefault};
    }

    ${({ isReadOnly, theme }) =>
        isReadOnly &&
        `
            background-color: ${theme.componentExtensions.inputs.bgInputDisabled};

            &:hover {
                background-color: ${theme.componentExtensions.inputs.bgInputDisabled};
            }

            &:focus {
                outline: none;
                border-color: ${theme.componentExtensions.border.primary};
                background-color: ${theme.componentExtensions.inputs.bgInputDisabled};
            }
        `}
`

const ErrorContainer = styled.div`
    margin-top: 5px;
`

const LabelContainer = styled.div`
    margin-bottom: 5px;
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const Arrow = styled.span<{ isReadOnly?: boolean }>`
    border-style: solid;
    border-width: 5px 5px 2.5px;
    height: 0;
    width: 0;
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    margin: auto 2px;

    border-color: ${({ theme, isReadOnly }) =>
            theme.componentExtensions.icons[isReadOnly ? 'iconAction' : 'iconDisabledLighter']}
        transparent transparent;
`

interface IValueText {
    value: string
    isDefaultTextShown?: boolean
}

// ensure the loading indicator doesn't push other elements down
const StyledLoadingIndicatorInline = styled(LoadingIndicatorInline)`
    height: 0;
`

const ValueFieldForm = <T, >({
    defaultText,
    itemIdField,
    itemValueLabelFields,
    items,
    multiselect,
    selectedNewItems,
    selectedValueItems,
    textForMultipleSelected,
    translate,
    typeName,
    valueItems,
    valueTextOverride,
    label,
    isReadOnly,
    isRequired,
    disabled,
    isLoading,
    errors,
    valuePickerId,
    onClick,
}: IValueFieldProps<T>): JSX.Element => {
    const { componentExtensions } = useAppTheme()

    const getValue = (): IValueText => {
        if (valueTextOverride) {
            return {
                value: translate ? Translation.translateKey(valueTextOverride) : valueTextOverride,
                isDefaultTextShown: true,
            }
        }

        const totalCount = selectedNewItems.size + selectedValueItems.size

        if (totalCount === 0) {
            return {
                value: translate ? Translation.translateKey(defaultText) : Translation.translateKey('select'),
                isDefaultTextShown: true,
            }
        }

        if (totalCount === 1) {
            const selectedValueItemId = selectedValueItems.values().next().value
            const onlyItemId =
                selectedValueItemId !== null && selectedValueItemId !== undefined
                    ? selectedValueItemId
                    : selectedNewItems.values().next().value

            // value items contain only items in the values props, not the currently selected values contains in the state
            // so if valueItems doesn't contain the currently selected item, it's still very possible to find it from the item list
            const item = valueItems.get(onlyItemId) || items.find((listItem) => listItem[itemIdField] === onlyItemId)

            // there might be situations in which we don't have the item that is currently selected
            if (item) {
                return { value: itemValueLabelFields.map((labelField) => item[labelField]).join(' ') }
            } else {
                return {
                    value: translate ? Translation.translateKey(defaultText) : defaultText,
                    isDefaultTextShown: true,
                }
            }
        }

        if (!multiselect && typeName && totalCount > 1) {
            const translatedTypeName = Translation.translateKey(typeName)

            return {
                value: Translation.translateKey('Dropdown.Value.MultipleValuesWithTypeName', { translatedTypeName }),
            }
        }

        const multipleSelectedText = translate
            ? Translation.translateKey(textForMultipleSelected)
            : textForMultipleSelected
        return { value: `${totalCount} ${Translation.translateKey('valittua-text')} ${multipleSelectedText} ` }
    }

    const { value, isDefaultTextShown = false } = getValue()

    const labelText = label ?? defaultText

    const testId = getTestId(['VALUE_PICKER', 'DROPDOWN', valuePickerId], label)

    return (
        <>
            <LabelContainer>
                <ReadOnlyText usage="dropdown_label">{labelText}</ReadOnlyText>
                {isRequired && <RequiredIndicator />}
            </LabelContainer>
            <Container
                data-testid={testId}
                errors={errors}
                disabled={disabled}
                isReadOnly={isReadOnly}
                onClick={onClick}
            >
                <RowContainer>
                    <ReadOnlyText
                        textOverflow="ellipsis"
                        usage={isDefaultTextShown ? 'dropdown_placeholder' : 'dropdown_selection'}
                        rawColor={isReadOnly ? componentExtensions.text.primary : undefined}
                    >
                        {value}
                    </ReadOnlyText>
                    {isLoading ? (
                        <StyledLoadingIndicatorInline isLoading size={16} />
                    ) : (
                        <Arrow isReadOnly={isReadOnly} />
                    )}
                </RowContainer>
            </Container>
            <ErrorContainer>
                <ReadOnlyText usage="h6" color="error">
                    {errors || ''}
                </ReadOnlyText>
            </ErrorContainer>
        </>
    )
}

export default ValueFieldForm
