import { createReducer } from 'typesafe-actions'

import {
    alustaOsiokohtaisetAsetukset,
    asetaOsionAsetuksienArvoja,
    asetaOsionNimi,
} from '../actions/OsiokohtaisetAsetuksetActions'
import IPossibleSelectedSetting from '../Types/IPossibleSelectedSetting'
export interface IAsetusArvo {
    readonly ArvoInt?: number | null
    readonly ArvoString?: string | null
    readonly ArvoFloat?: number | null
    readonly ArvoBool?: boolean | null
    readonly ArvoDateTime?: string | null
}

export interface IAsetus extends IAsetusArvo {
    Nimi: string
}

export interface IOsionAsetus extends IAsetus {
    readonly Ryhma: string
    readonly Tietotyyppi: string
    readonly MahdollisetArvot: IPossibleSelectedSetting[] | null
    readonly NimiAsiakkaalle: string
    readonly KuvausAsiakkaalle: string | null
    readonly Arvo: string
}

export interface IOsiokohtaisetAsetuksetState {
    readonly asetukset: ReadonlyMap<string, IOsionAsetus>
    readonly nimi: string
}

export const INITIAL_STATE: IOsiokohtaisetAsetuksetState = {
    asetukset: new Map(),
    nimi: '',
}

const osiokohtaisetAsetuksetReducer = createReducer(INITIAL_STATE)
    .handleAction(alustaOsiokohtaisetAsetukset, (state, action) => {
        const asetukset = new Map(action.payload.map((asetus) => [asetus.Nimi, asetus] as [string, IOsionAsetus]))

        return {
            ...state,
            asetukset,
        }
    })
    .handleAction(asetaOsionAsetuksienArvoja, (state, action) => {
        const asetukset = new Map(state.asetukset)

        for (const { Nimi, ...arvoPropertyt } of action.payload) {
            const asetus = state.asetukset.get(Nimi)

            if (!asetus) {
                return state
            }

            const asetusUudellaArvolla = {
                ...asetus,
                ...arvoPropertyt,
            }

            asetukset.set(Nimi, asetusUudellaArvolla)
        }

        return {
            ...state,
            asetukset,
        }
    })
    .handleAction(asetaOsionNimi, (state, action) => {
        const nimi = action.payload

        return {
            ...state,
            nimi,
        }
    })

export default osiokohtaisetAsetuksetReducer
