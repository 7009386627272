import { createReducer } from 'typesafe-actions'

import IModalReducerState from '../Types/IModalReducerState'
import { closeAllModalsAction, closeModalAction, openModalAction, setModalProps } from './ModalActions'

export const MODAL_INITIAL_STATE: IModalReducerState = new Map()

const modalReducer = createReducer(MODAL_INITIAL_STATE)
    .handleAction(openModalAction, (state, action) => {
        const { closeOthers, modalId, props } = action.payload

        const newState = closeOthers ? new Map() : new Map(state)
        newState.set(modalId, { isOpen: true, props })

        return newState
    })
    .handleAction(closeModalAction, (state, action) => {
        const modalId = action.payload

        const newState = new Map(state)
        newState.delete(modalId)

        return newState
    })
    .handleAction(closeAllModalsAction, () => {
        return new Map()
    })
    .handleAction(setModalProps, (state, action) => {
        const { modalId, props } = action.payload

        const modalState = state.get(modalId)
        if (!modalState) {
            return state
        }

        const newState = new Map(state)
        newState.set(modalId, { ...modalState, props: { ...modalState.props, ...props } })

        return newState
    })

export default modalReducer
