import { FieldProps } from 'formik'
import * as React from 'react'

import TranslatedToggle, { ITranslatedToggleProps } from './TranslatedToggle'

export interface IFormikToggleProps extends ITranslatedToggleProps {
    field: FieldProps['field']
}

const FormikToggle: React.FunctionComponent<IFormikToggleProps> = ({
    label,
    field: { value, onChange, ...field },
    ...passProps
}) => {
    const labelToUse = label ? label : value ? 'toggle-paalla' : 'toggle-pois-paalta'
    return (
        <TranslatedToggle {...field} checked={Boolean(value)} onChange={onChange} {...passProps} label={labelToUse} />
    )
}

export default FormikToggle
