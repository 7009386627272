import * as React from 'react'
import MuiLink, { LinkProps } from '@material-ui/core/Link'

import URL from 'common/URL'

interface ILinkProps {
    to: string
    children: React.ReactNode
    className?: string
    openInNewTab?: boolean
    windowParams?: string
    muiLinkProps?: LinkProps
}

const Link = React.forwardRef<HTMLElement, ILinkProps>(
    ({ windowParams, className, to, children, openInNewTab = true, muiLinkProps }, ref) => {
        const handleOpenOnNewTab = (event: React.MouseEvent) => {
            URL.open(to, undefined, windowParams)
            // don't open the link in the same tab
            event.preventDefault()
        }

        return (
            <MuiLink
                {...muiLinkProps}
                className={className}
                href={to}
                onClick={openInNewTab ? handleOpenOnNewTab : undefined}
                ref={ref}
            >
                {children}
            </MuiLink>
        )
    }
)

Link.displayName = 'Link'

export default Link
