import { IDataSourceAggregatedDataRow, IDataSourceGroupData } from '../../data-source-types'
import { insertToArray } from '../../generic-utilities'
import isAggregateDataGroup from './IsAggregateDataGroup'

const mapAggregatedData = ({ Label, Value, Unit }: IDataSourceAggregatedDataRow) => ({
    Label,
    Value,
    Unit,
})

export const getAggregatedData = (groupData: IDataSourceGroupData[]): IDataSourceAggregatedDataRow[] => {
    const aggregateGroup = groupData.find((group) => isAggregateDataGroup(group))

    if (!aggregateGroup) {
        return []
    }

    const windowFunctionData = aggregateGroup.WindowFunctionData.map(mapAggregatedData)
    const customFunctionData = aggregateGroup.CustomFunctionData.map(mapAggregatedData)

    return windowFunctionData.concat(customFunctionData)
}

/**
 * Dynamically adds an aggregated data object to groupdata.
 *
 * Note that if you give the index to which the data is inserted, it currently
 * works only within the boundaries of the WindowFunctionData of the object, i.e.
 * if the aggregated data has both window and custom function data, you
 * can have the index only within the window function data. Currently there's no
 * way to order the data between the window and custom function data, so any dynamically
 * inserted data will always go before the custom function data.
 */
export const addAggregatedDataItemToGroupData = (
    groupData: IDataSourceGroupData[],
    aggregatedDataItem: IDataSourceAggregatedDataRow,
    newItemOrder?: number
): IDataSourceGroupData[] => {
    const aggregateGroupIndex = groupData.findIndex((group) => isAggregateDataGroup(group))

    const aggregateGroupNotFound = aggregateGroupIndex === -1
    if (aggregateGroupNotFound) {
        return groupData
    }

    const aggregateGroupObjectCopied = {
        ...groupData[aggregateGroupIndex],
    }

    const fullNewItemToAdd = {
        ...aggregatedDataItem,
        Property: 'DynamicallyGivenCustomFunction',
    }

    const indexToInsertObjectIn =
        newItemOrder !== undefined ? newItemOrder : aggregateGroupObjectCopied.WindowFunctionData.length - 1

    aggregateGroupObjectCopied.WindowFunctionData = insertToArray(
        aggregateGroupObjectCopied.WindowFunctionData,
        indexToInsertObjectIn,
        fullNewItemToAdd
    )

    const copiedArray = [...groupData]
    copiedArray[aggregateGroupIndex] = aggregateGroupObjectCopied

    return copiedArray
}
