import employeeCalendarTranslations from './EmployeeCalendar'
import messagingTranslations from './Messaging'
import cookieBanner from './CookieBanner'

export default {
    fil: {
        fi: 'filippiinit',
        sv: '',
        en: 'philippines',
        ru: '',
        sk: '',
        pl: '',
    },
    en: {
        fi: 'englanti',
        sv: '',
        en: 'england',
        ru: '',
        sk: '',
        pl: '',
    },
    pl: {
        fi: 'puola',
        sv: '',
        en: 'poland',
        ru: '',
        sk: '',
        pl: '',
    },
    sv: {
        fi: 'ruotsi',
        sv: '',
        en: 'sweden',
        ru: '',
        sk: '',
        pl: '',
    },
    sk: {
        fi: 'slovakia',
        sv: '',
        en: 'slovakia',
        ru: '',
        sk: '',
        pl: '',
    },
    fi: {
        fi: 'suomi',
        sv: '',
        en: 'finland',
        ru: '',
        sk: '',
        pl: '',
    },
    ru: {
        fi: 'venäjä',
        sv: '',
        en: 'russia',
        ru: '',
        sk: '',
        pl: '',
    },
    'acknowledge-all-button': {
        fi: 'Kuittaa kaikki',
        sv: '',
        en: 'Acknowledge all',
        ru: '',
        sk: '',
        pl: '',
    },
    'acknowledgement-failed': {
        fi: 'Luetuksi kuittaaminen epäonnistui.',
        sv: '',
        en: 'Acknowledging as read failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'acknowledgement-reset-succeeded': {
        fi: 'Lisätiedot asetettu kuitattavaksi.',
        sv: '',
        en: 'Additional information set to the acknowledged',
        ru: '',
        sk: '',
        pl: '',
    },
    'acknowledgement-succeeded': {
        fi: 'Luetuksi kuittaaminen onnistui.',
        sv: '',
        en: 'Setting to be acknowledged succeeded',
        ru: '',
        sk: '',
        pl: '',
    },
    actionDisabled: {
        fi: 'Toiminto estetty',
        sv: '',
        en: 'Action disabled',
        ru: '',
        sk: '',
        pl: '',
    },
    'add-button': { fi: 'Lisää', sv: '', en: 'Add', ru: '', sk: '', pl: '' },
    'add-new-button': { fi: 'Lisää uusi...', sv: 'Lägg till ny...', en: 'Add new...', ru: '', sk: '', pl: '' },
    'additional-info': {
        fi: 'Lisätiedot',
        sv: '',
        en: 'Info',
        ru: '',
        sk: '',
        pl: '',
    },
    aika: { fi: 'Aika', sv: '', en: 'Time', ru: '', sk: '', pl: '' },
    aktiivinen: { fi: 'Aktiivinen', sv: '', en: 'Active', ru: '', sk: '', pl: '' },
    alkuaika: { fi: 'Alkuaika', sv: '', en: 'Start time', ru: '', sk: '', pl: '' },
    'all-areas': { fi: 'Kaikki alueet', sv: '', en: 'All domains', ru: '', sk: '', pl: '' },
    aloitusaika: { fi: 'Aloitusaika', sv: '', en: 'Start time', ru: '', sk: '', pl: '' },
    'alueet-fetch-failed': {
        fi: 'Alueiden haku epäonnistui',
        sv: '',
        en: 'Fetching domains failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'alueet-fetch-for-filter-failed': {
        fi: 'Alueiden haku suodatinta varten epäonnistui.',
        sv: '',
        en: 'Fetching domains for the filter failed',
        ru: '',
        sk: '',
        pl: '',
    },
    ammattinimike: { fi: 'Ammattinimike', sv: '', en: 'Job title', ru: '', sk: '', pl: '' },
    'Ammattinimike.AmmattinimikeSmallCase': { fi: 'ammattinimike', sv: '', en: 'job title', ru: '', sk: '', pl: '' },
    'Ammattinimike.AmmattinimikkeetDefinite': {
        fi: 'ammattinimikettä',
        sv: '',
        en: 'job titles',
        ru: '',
        sk: '',
        pl: '',
    },
    'EventSelector.JobTitle': {
        fi: 'Ammattinimikkeen tapahtumat',
        sv: '',
        en: 'Event of job title',
        ru: '',
        sk: '',
        pl: '',
    },
    'areas-definite': { fi: 'aluetta', sv: '', en: 'domains', ru: '', sk: '', pl: '' },
    'aseta-kuitattavaksi': { fi: 'Aseta kuitattavaksi', sv: '', en: 'Set to be acknowledged', ru: '', sk: '', pl: '' },
    'asetukset-sivukohtaiset-otsikko': {
        fi: 'Osiokohtaiset asetukset',
        sv: '',
        en: 'Settings by section',
        ru: '',
        sk: '',
        pl: '',
    },
    asiakas: { fi: 'Asiakas', sv: '', en: 'Work unit', ru: '', sk: '', pl: '' },
    'Asiakas.AsiakasSmallCase': { fi: 'asiakas', sv: '', en: 'work unit', ru: '', sk: '', pl: '' },
    'Asiakas.Projektinumero.select': {
        fi: 'Projektinumero',
        sv: '',
        en: 'Project number',
        ru: '',
        sk: '',
        pl: '',
    },
    'Asiakas.Projektinumero.no-values': {
        fi: 'Asiakkaalla ei ole valittavia projektinumeroita',
        sv: '',
        en: '',
        ru: '',
        sk: '',
        pl: '',
    },
    'Asiakas.Projektinumero.ProjektinumeroSmallCase': {
        fi: 'projektinumero',
        sv: '',
        en: 'project number',
        ru: '',
        sk: '',
        pl: '',
    },
    'Asiakas.Projektinumero.projektinumerot-definite': {
        fi: 'projektinumeroa',
        sv: '',
        en: 'project numbers',
        ru: '',
        sk: '',
        pl: '',
    },
    'Asiakas.UrakkapalkkalajiKappalemaara': { fi: 'Kappalemaara', sv: '', en: 'Amount', ru: '', sk: '', pl: '' },
    'Asiakas.UrakkapalkkalajiKappalemaara.Urakkamaara': {
        fi: 'Urakkamäärä',
        sv: '',
        en: 'Urakkamäärä (???)',
        ru: '',
        sk: '',
        pl: '',
    },
    'Asiakas.UrakkapalkkalajiKappalemaara.UrakkapalkkalajiKappalemaaraDefinite': {
        fi: 'Kappalemaara',
        sv: '',
        en: 'Amount',
        ru: '',
        sk: '',
        pl: '',
    },
    'Asiakas.UrakkapalkkalajiKappalemaara.UrakkapalkkalajiKappalemaaraSmallCase': {
        fi: 'kappalemaara',
        sv: '',
        en: 'amount',
        ru: '',
        sk: '',
        pl: '',
    },
    'asiakas-hyvaksynyt-ehdotuksen': {
        fi: '{nimi} hyväksynyt ehdotuksen {date} kello {time}.',
        sv: '',
        en: '{nimi} approved {date} at {time}.',
        ru: '',
        sk: '',
        pl: '',
    },
    'asiakas-tila-Aktiivinen': { fi: 'Aktiivinen', sv: 'Aktiv', en: 'Active', ru: '', sk: '', pl: '' },
    'asiakas-tila-Passiivinen': { fi: 'PASSIIVINEN', sv: 'PASSIV', en: 'PASSIVE', ru: '', sk: '', pl: '' },
    'asiakkaalla-hyvaksymattomia-vuoroja': {
        fi: 'Hyväksymättömiä vuoroja',
        sv: '',
        en: 'Non-approved shifts',
        ru: '',
        sk: '',
        pl: '',
    },
    'asiakkaan-lisatiedot-tilaukselle': {
        fi: 'Asiakkaan lisätiedot tilaukselle',
        sv: '',
        en: "Work unit's additional information for the order",
        ru: '',
        sk: '',
        pl: '',
    },
    'EventSelector.WorkUnit': {
        fi: 'Työkohteen tapahtumat',
        sv: '',
        en: 'Events of work unit',
        ru: '',
        sk: '',
        pl: '',
    },
    'lisatiedot-toteutuneelle-tyoaikatapahtumalle': {
        fi: 'Lisätiedot toteutuneelle työaikatapahtumalle',
        sv: '',
        en: 'Additional information for the actualized event',
        ru: '',
        sk: '',
        pl: '',
    },
    asiakkaat: { fi: 'Asiakkaat', sv: '', en: 'Work units', ru: '', sk: '', pl: '' },
    'asiakkaat-definite': { fi: 'asiakasta', sv: '', en: 'work units', ru: '', sk: '', pl: '' },
    'asx-anna-palautetta': { fi: 'Anna palautetta', sv: '', en: 'Give feedback', ru: '', sk: '', pl: '' },
    'asx-ei-oikeutta-syottaa-tyontekija-lisatieto': {
        fi: 'Ei oikeutta syottaa lisätietoa työntekijälle',
        sv: '',
        en: '',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-ei-pooleja': { fi: 'Ei valittavia pooleja', sv: '', en: 'Non selectable pools', ru: '', sk: '', pl: '' },
    'asx-erikoistaitojen-valintaan-ei-oikeutta': {
        fi: 'Käyttäjällä ei ole oikeutta syöttää erikoistaitoja',
        sv: '',
        en: 'The user has no right to select skills',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-email': { fi: 'Email', sv: 'Email', en: 'Email', ru: '', sk: '', pl: '' },
    'asx-fax': { fi: 'Fax', sv: 'Fax', en: 'Fax', ru: '', sk: '', pl: '' },
    'asx-irrota-tilaus': { fi: 'Irrota tilaus', sv: '', en: 'Detach the order', ru: '', sk: '', pl: '' },
    'asx-katso-listanappi-info': {
        fi: 'Näe listanappien toimintojen selitykset',
        sv: '',
        en: 'See descriptions for the list buttons',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-katuosoite': { fi: 'Katuosoite', sv: 'Adress', en: 'Address', ru: '', sk: '', pl: '' },
    'asx-kotisivu': { fi: 'Kotisivu', sv: 'Hemsida', en: 'Homepage', ru: '', sk: '', pl: '' },
    'asx-laheta-hotline-viesti': {
        fi: 'Lähetä Hotline-viesti',
        sv: '',
        en: 'Send Hotline message',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-lahetetyt': { fi: 'Lähetetyt', sv: 'Skickade', en: 'Sent', ru: '', sk: '', pl: '' },
    'asx-lisaa-alitilauksia': { fi: 'Lisää työvuoroja', sv: '', en: 'Add shifts', ru: '', sk: '', pl: '' },
    'asx-lisaa-pooliin': { fi: 'Lisää pooliin', sv: 'Lägg till poolen', en: 'Add to pool', ru: '', sk: '', pl: '' },
    'asx-lisaa-tyontekija-pooliin': {
        fi: 'Lisää työntekijä pooliin',
        sv: 'Lägg till poolen',
        en: 'Add to pool',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-lisaa-tyontekijat-pooliin': {
        fi: 'Lisää työntekijät pooliin',
        sv: 'Lägg till poolen',
        en: 'Add to pool',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-lista-ei-naytettavia-tyontekijoita': {
        fi: 'Ei näytettäviä työntekijöitä. Valitse toinen asiakas tai muuta suodatuksia.',
        sv: '',
        en: 'No employees to display. Select another work unit or change the filters.',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-lista-ei-naytettavia-tyovuorotilauksia': {
        fi: 'Ei näytettäviä työvuorotilauksia. Valitse toinen asiakas tai muuta suodatuksia.',
        sv: '',
        en: 'No orders to display. Select another work unit or change the filters.',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-lista-ei-naytettavia-viesteja': {
        fi: 'Ei näytettäviä viestejä. Valitse toinen asiakas tai muuta suodatuksia.',
        sv: '',
        en: 'No messages to display. Select another work unit or change the filters.',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-lista-ei-naytettavia-vuoroja': {
        fi: 'Ei näytettäviä vuoroja. Valitse toinen asiakas tai muuta suodatuksia.',
        sv: '',
        en: 'No shifts to display. Select another work unit or change the filters.',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-loginoikeus-otsikko': { fi: 'Login-oikeus', sv: '', en: 'Login access', ru: '', sk: '', pl: '' },
    'asx-lounas-alkuaika': { fi: 'Lounaan alkuaika', sv: '', en: 'Lunch start time', ru: '', sk: '', pl: '' },
    'asx-muokkaa-yksikon-tietoja-alku': {
        fi: 'Muokkaa yksikön',
        sv: '',
        en: 'Modify the work unit',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-muokkaa-yksikon-tietoja-loppu': { fi: 'tietoja', sv: 'information', en: 'tietoja' },
    'asx-muokkaa-yhteyshenkilon-tietoja': {
        fi: 'Muokkaa yhteyshenkilön tietoja',
        sv: '',
        en: "Modify the contact person's information",
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-nimi': { fi: 'Nimi', sv: 'Namn', en: 'Name', ru: '', sk: '', pl: '' },
    'asx-paallikko-otsikko': { fi: 'Päällikkö', sv: '', en: 'Manager', ru: '', sk: '', pl: '' },
    'asx-peruuta-tilauksen-julkaisu': {
        fi: 'Peruuta tilauksen julkaisu',
        sv: '',
        en: 'Undo the publishing of the order',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-poista-poolista': { fi: 'Poista poolista', sv: '', en: 'Remove from pool', ru: '', sk: '', pl: '' },
    'asx-poista-tilaus': { fi: 'Poista tilaus', sv: '', en: 'Remove order', ru: '', sk: '', pl: '' },
    'asx-poista-tyontekija-poolista': {
        fi: 'Poista työntekijä poolista',
        sv: '',
        en: 'Remove from pool',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-poista-tyontekijat-poolista': {
        fi: 'Poista työntekijät poolista',
        sv: '',
        en: 'Remove from pool',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-postinumero': { fi: 'Postinumero', sv: 'Postnummer', en: 'ZIP code', ru: '', sk: '', pl: '' },
    'asx-postitoimipaikka': { fi: 'Postitoimipaikka', sv: 'Postkontor', en: 'Post office', ru: '', sk: '', pl: '' },
    'asx-puhelin': { fi: 'Puhelin', sv: 'Telefon', en: 'Phone', ru: '', sk: '', pl: '' },
    'asx-saapuneet': { fi: 'Saapuneet', sv: 'Ankomna', en: 'Received', ru: '', sk: '', pl: '' },
    'asx-sahkoposti': { fi: 'Sähköposti', sv: 'E-post', en: 'Email', ru: '', sk: '', pl: '' },
    'asx-tilauksella-ei-tyontekijoita-alku': {
        fi: 'Ei työntekijöitä, ehdota tai kiinnitä työntekijöitä tilaukseen klikkaamalla',
        sv: '',
        en: 'No employees. Suggest or attach employees to the order by clicking the',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-tilauksella-ei-tyontekijoita-loppu': { fi: '-ikonia', sv: '', en: 'icon', ru: '', sk: '', pl: '' },
    'asx-tilausehdotus-ehdotusviesti-lahetetaan-vain-sms-push-tai-hotline-viestina-ja-sms-viestiin-lisataan-varaustunnus-joka-vaikuttaa-merkkimaaraan':
        {
            fi: 'Ehdotusviesti lähetetään vain SMS- tai push-viestinä. SMS-viestiin lisätään varaustunnus, joka vaikuttaa merkkimäärään. Mobiilikäyttäjät eivät näe ylläolevaa SMS-viestiä.',
            sv: '',
            en: 'The message is sent only as an SMS or push notification. A booking ID is added to the SMS message, which affects the total amount of characters. Mobile users will not see the SMS message above.',
            ru: '',
            sk: '',
            pl: '',
        },
    'asx-tilausehdotus-terveisin': { fi: 'Terveisin', sv: 'Hälsningar', en: 'Best Regards', ru: '', sk: '', pl: '' },
    'asx-titteli-otsikko': { fi: 'Titteli', sv: 'Titteli', en: 'Title', ru: '', sk: '', pl: '' },
    'asx-toiminnot-valituille': {
        fi: 'Toiminnot valituille:',
        sv: '',
        en: 'Actions for the selected',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-tyontekija': { fi: 'Työntekijä', sv: 'Arbetstagare', en: 'Employee', ru: '', sk: '', pl: '' },
    'asx-tyontekijat': { fi: 'Työntekijät', sv: 'Arbetstagare', en: 'Employees', ru: '', sk: '', pl: '' },
    'asx-uutiset-otsikko': { fi: 'Uutiset', sv: 'Nyheter', en: 'News', ru: '', sk: '', pl: '' },
    'asx-vahvista-tyontekija': { fi: 'Vahvista työntekijä', sv: '', en: 'Confirm an employee', ru: '', sk: '', pl: '' },
    'asx-vain-pooleihin-kuulumattomat': {
        fi: 'Vain pooleihin kuulumattomat',
        sv: '',
        en: 'Only ones not belonging to any pools',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-vain-pooleihin-kuuluvat': {
        fi: 'Vain pooleihin kuuluvat',
        sv: '',
        en: 'Only ones belonging to any pools',
        ru: '',
        sk: '',
        pl: '',
    },
    'asx-yksikontiedot-otsikko': {
        fi: 'Yksikön tiedot',
        sv: '',
        en: "Work unit's information",
        ru: '',
        sk: '',
        pl: '',
    },
    'automaattinen-ehdotus-ei-ole-sallittu-asiakkaalle': {
        fi: 'Automaattinen ehdotus ei ole sallittu asiakkaalle.',
        sv: '',
        en: 'Automatic suggestion not allowed for the work unit',
        ru: '',
        sk: '',
        pl: '',
    },
    'avaa-tietoturvaseloste': {
        fi: 'Avaa tietosuojaseloste',
        sv: '',
        en: 'Open the privacy policy',
        ru: '',
        sk: '',
        pl: '',
    },
    'tietoa-evasteista': {
        fi: 'Tietoa evästeistä',
        sv: '',
        en: 'About cookies',
        ru: '',
        sk: '',
        pl: '',
    },
    'tietoa-evasteista-url': {
        fi: 'https://mepco.fi/evasteilmoitus-ohjelmistot/',
        sv: '',
        en: 'https://mepco.fi/cookie-notice-software/',
        ru: '',
        sk: '',
        pl: '',
    },
    'tietosuojaseloste-url': {
        fi: 'https://mepco.fi/tietosuojaseloste-ohjelmistot/',
        sv: '',
        en: 'https://mepco.fi/privacy-notice-software/',
        ru: '',
        sk: '',
        pl: '',
    },
    'terms-and-conditions-url': {
        fi: 'https://mepco.fi/kayttajaehdot/',
        sv: '',
        en: 'https://mepco.fi/terms-and-conditions/',
        ru: '',
        sk: '',
        pl: '',
    },
    'button-cancel': { fi: 'Peruuta', sv: 'Tillbaka', en: 'Cancel', ru: '', sk: '', pl: '' },
    'button-ok': { fi: 'OK', sv: 'OK', en: 'OK', ru: '', sk: '', pl: '' },
    'button-sulje': { fi: 'Sulje', sv: '', en: 'Close', ru: '', sk: '', pl: '' },
    'cancel-button': { fi: 'Peruuta', sv: 'Annullera', en: 'Cancel', ru: '', sk: '', pl: '' },
    'choose-areas': { fi: 'Valitse alueet', sv: '', en: 'Select domains', ru: '', sk: '', pl: '' },
    'clear-filters-button': {
        fi: 'Nollaa suodatukset',
        sv: 'Töm sökfilter',
        en: 'Clear filters',
        ru: '',
        sk: '',
        pl: '',
    },
    'close-button': { fi: 'Sulje', sv: 'Stäng', en: 'Close', ru: '', sk: '', pl: '' },
    collapse: { fi: 'Vähemmän', sv: 'Mindre', en: 'Less', ru: '', sk: '', pl: '' },
    'calendar.Node.ContextMenu.SelectorObjects.MainLabel': {
        fi: 'Valitse',
        sv: '',
        en: 'Select',
        ru: '',
        sk: '',
        pl: '',
    },
    'calendar.TooMuchDataWarning.Label': {
        fi: 'Tarkenna suodattimia. Nykyiset suodatukset tuottavat liikaa tuloksia.',
        sv: '',
        en: 'Too many results. Please apply more specific filter values',
        ru: '',
        sk: '',
        pl: '',
    },
    'calendar.Headers.LoadMoreDataButton.Text': {
        fi: 'Näytä lisää',
        sv: '',
        en: 'Show more',
        ru: '',
        sk: '',
        pl: '',
    },

    'calendar.Esto': {
        fi: 'Vapaatoive',
        sv: '',
        en: 'Day off preference',
        ru: '',
        sk: '',
        pl: '',
    },
    'calendar.Transfer': {
        fi: 'Siirtymä',
        sv: '',
        en: 'Transfer',
        ru: '',
        sk: '',
        pl: '',
    },
    'calendar.InitializationFailed': {
        fi: 'Kalenteria ei voitu alustaa.',
        sv: '',
        en: 'The calendar could not be initialized',
        ru: '',
        sk: '',
        pl: '',
    },
    'calendar.TyoaikasaantoViolation': {
        fi: 'Tapahtuma rikkoo työaikasääntöjä',
        sv: '',
        en: 'The event breaks work time rules',
        ru: '',
        sk: '',
        pl: '',
    },
    'calendar.Tyrkky': {
        fi: 'Vuorotoive',
        sv: '',
        en: 'Wish',
        ru: '',
        sk: '',
        pl: '',
    },
    'calendar.CalendarFilters.FiltersButton.Tooltip': {
        fi: 'Suodata',
        sv: '',
        en: 'Filter',
        ru: '',
        sk: '',
        pl: '',
    },
    'calendar.CalendarFilters.QuickActionsButton.Tooltip': {
        fi: 'Näytä',
        sv: '',
        en: 'Display',
        ru: '',
        sk: '',
        pl: '',
    },
    'calendar.CalendarFilters.Search.Tooltip': {
        fi: 'Etsi',
        sv: '',
        en: 'Search',
        ru: '',
        sk: '',
        pl: '',
    },
    'configurable-list-modal.SaveButton.Label.Default': {
        fi: 'Tallenna',
        sv: '',
        en: 'Save',
        ru: '',
        sk: '',
        pl: '',
    },
    'configurable-list': {
        ColumnManagementModal: {
            OpenButton: {
                fi: 'Näytä sarakkeet',
                sv: '',
                en: 'Show columns',
                ru: '',
                sk: '',
                pl: '',
            },
            EditColumns: {
                fi: 'Muokkaa sarakkeita',
                sv: '',
                en: 'Edit columns',
                ru: '',
                sk: '',
                pl: '',
            },
            resetDefaultColumnSettingsButton: {
                fi: 'Palauta oletukset',
                sv: '',
                en: 'Restore defaults',
                ru: '',
                sk: '',
                pl: '',
            },
            SaveSucceeded: {
                fi: 'Sarakkeiden tallennus onnistui',
                sv: '',
                en: 'Saving columns succeeded',
                ru: '',
                sk: '',
                pl: '',
            },
            SaveFailed: {
                fi: 'Sarakkeiden tallennus epäonnistui',
                sv: '',
                en: 'Saving columns failed',
                ru: '',
                sk: '',
                pl: '',
            },
        },
        ListExcelButton: {
            Label: {
                fi: 'Vie Excel',
                sv: '',
                en: 'Export to Excel',
                ru: '',
                sk: '',
                pl: '',
            },
        },
        FetchDataButton: {
            Label: {
                fi: 'Suodata',
                sv: '',
                en: 'Filter',
                ru: '',
                sk: '',
                pl: '',
            },
        },
        NoContentText: {
            NotFetched: {
                fi: 'Aseta suodatukset ja paina "Suodata"',
                sv: '',
                en: 'Set filters and press "Filter"',
                ru: '',
                sk: '',
                pl: '',
            },
        },
    },
    configurableList: {
        reports: {
            createNew: {
                fi: 'Luo uusi...',
                sv: '',
                en: 'Create new...',
                ru: '',
                sk: '',
                pl: '',
            },
            createReportModalTitle: {
                fi: 'Luo uusi raportti',
                sv: '',
                en: 'Create new report',
                ru: '',
                sk: '',
                pl: '',
            },
            reportName: {
                fi: 'Raportin nimi',
                sv: '',
                en: 'Report name',
                ru: '',
                sk: '',
                pl: '',
            },
            groupingFields: {
                fi: 'Ryhmittelykentät',
                sv: '',
                en: 'Grouping fields',
                ru: '',
                sk: '',
                pl: '',
            },
            propertyFields: {
                fi: 'Tietokentät',
                sv: '',
                en: 'Property fields',
                ru: '',
                sk: '',
                pl: '',
            },
            calculationFields: {
                fi: 'Laskentakentät',
                sv: '',
                en: 'Calculation fields',
                ru: '',
                sk: '',
                pl: '',
            },
            windowFunction: {
                fi: 'Laskutapa',
                sv: '',
                en: 'Window function',
                ru: '',
                sk: '',
                pl: '',
            },
            reportCreated: {
                fi: 'Raportti "{name}" luotu ',
                sv: '',
                en: 'Report "{name}" created',
                ru: '',
                sk: '',
                pl: '',
            },
            confirmRemoveReport: {
                fi: 'Haluatko varmasti poistaa raportin "{name}"?',
                sv: '',
                en: 'Are you sure you want to remove the report "{name}"?',
                ru: '',
                sk: '',
                pl: '',
            },
        },
        columnActions: {
            ascending: {
                fi: 'Nouseva',
                sv: '',
                en: 'Ascending',
                ru: '',
                sk: '',
                pl: '',
            },
            descending: {
                fi: 'Laskeva',
                sv: '',
                en: 'Descending',
                ru: '',
                sk: '',
                pl: '',
            },
            hideColumn: {
                fi: 'Piilota sarake',
                sv: '',
                en: 'Hide column',
                ru: '',
                sk: '',
                pl: '',
            },
            pinColumn: {
                fi: 'Kiinnitä sarake vasemmalle',
                sv: '',
                en: 'Pin column to left',
                ru: '',
                sk: '',
                pl: '',
            },
            unpinColumn: {
                fi: 'Poista sarakkeen kiinnitys',
                sv: '',
                en: 'Unpin column',
                ru: '',
                sk: '',
                pl: '',
            },
            unpin: {
                fi: 'Poista kiinnitys',
                sv: '',
                en: 'Unpin',
                ru: '',
                sk: '',
                pl: '',
            },
        },
        moreActions: {
            fi: 'Lisää toimintoja',
            sv: '',
            en: 'More actions',
            ru: '',
            sk: '',
            pl: '',
        },
    },
    'Common.AddNewText': {
        fi: 'Lisää uusi',
        sv: '',
        en: 'Add new',
        ru: '',
        sk: '',
        pl: '',
    },
    'Common.SomethingWrong': {
        fi: 'Jokin meni pieleen. Yritä uudestaan.',
        sv: '',
        en: 'Something went wrong',
        ru: '',
        sk: '',
        pl: '',
    },
    'ConfigurableList.ColumnManagementModal.title': {
        fi: 'Valitse näytettävät sarakkeet',
        sv: '',
        en: 'Select the columns to be displayed',
        ru: '',
        sk: '',
        pl: '',
    },
    'ConfigurableList.ListActionButtons.SelectedAmount': {
        fi: 'Valitut rivit ({amount}):',
        sv: '',
        en: 'Selected rows ({amount})',
        ru: '',
        sk: '',
        pl: '',
    },
    'confirm-button': { fi: 'Vahvista', sv: '', en: 'Confirm', ru: '', sk: '', pl: '' },
    'confirmation-overwrite-ticket-1': { fi: 'Huom.! Käyttäjä', sv: '', en: 'Note! User', ru: '', sk: '', pl: '' },
    'confirmation-overwrite-ticket-2': {
        fi: 'on muokannut tikettiä avaamisen jälkeen. Tallentaminen ylikirjoittaa ja poistaa käyttäjän',
        sv: '',
        en: "has modified the ticket after opening it. Saving it will overwrite and remove the user's",
        ru: '',
        sk: '',
        pl: '',
    },
    'confirmation-overwrite-ticket-3': {
        fi: 'tekemät muutokset. Tallennetaanko tiketti?',
        sv: '',
        en: 'changes. Are you sure you want to save the ticket?',
        ru: '',
        sk: '',
        pl: '',
    },
    'confirmation-remove-attachment': {
        fi: 'Haluatko varmasti poistaa liitteen?',
        sv: 'Är du säkert att ta bort bifogad fil?',
        en: 'Are you sure you want to remove the attachment?',
        ru: '',
        sk: '',
        pl: '',
    },
    'confirmation-remove-comment': {
        fi: 'Haluatko varmasti poistaa kommentin?',
        sv: 'Är du säkert att ta bort kommentaren?',
        en: 'Are you sure you want to remove the comment?',
        ru: '',
        sk: '',
        pl: '',
    },
    'confirmation-remove-task': {
        fi: 'Haluatko varmasti poistaa tehtävän?',
        sv: 'Är du säkert att ta bort uppgiften?',
        en: 'Are you sure you want to remove the task?',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-aseta-kotiyksikko': {
        fi: 'Aseta henkilön kotiyksikkö.',
        sv: '',
        en: 'Set the home work unit.',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-aseta-paayhteyshenkilo': {
        fi: 'Aseta henkilö yksikön pääyhteyshenkilöksi.',
        sv: '',
        en: 'Set this person as the primary contact person of the work unit.',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-aseta-passiiviseksi': {
        fi: 'Aseta passiiviseksi',
        se: '',
        en: 'Set as passive',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-asiakkaan-yhteyshenkilot-otsikko': {
        fi: 'Asiakkaan yhteyshenkilöt',
        sv: '',
        en: 'Contact people of of the work unit',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-board-ei-tiketteja': { fi: 'ei tikettejä', sv: 'ei tikettejä', en: 'no tickets', ru: '', sk: '', pl: '' },
    'crm-board-ei-uusia_palautteita': {
        fi: 'ei uusia palautteita',
        sv: '',
        en: 'no new feedback',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-board-kuittaamattomat-tiketit-title': {
        fi: 'Kuittaamatta / Työn alla',
        sv: 'Behöver aktion / bekräftelse',
        en: 'Needs action / acknowledgement',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-board-muita-odottavat-tiketit-label': {
        fi: 'Valmis (odottaa muiden kuittausta)',
        sv: 'Färdig (väntar på andra)',
        en: 'Ready (waiting for others)',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-board-uudet-tiketit-title': {
        fi: 'Uudet palautteet',
        sv: 'Nya feed-back',
        en: 'New feedback',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-board-valmiit-tiketit-label': {
        fi: 'Valmis (suljettu)',
        sv: 'Färdig (stängd)',
        en: 'Ready (closed)',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-ei-login-oikeutta': {
        fi: 'ei login-oikeutta',
        sv: '',
        en: 'No login access',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-ei-ole-kotiyksikko': {
        fi: 'Yksikkö ei ole henkilön kotiyksikkö.',
        sv: '',
        en: 'The work unit is not the home work unit of the user',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-ei-ole-paayhteyshenkilo': {
        fi: 'Henkilö ei ole yksikön pääyhteyshenkilö.',
        sv: '',
        en: "The user is not the work unit's primary contact person",
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-ei-yksikkoja': { fi: 'Ei yksikköjä.', sv: '', en: 'No work units', ru: '', sk: '', pl: '' },
    'crm-Email': { fi: 'Email', sv: 'Email', en: 'Email', ru: '', sk: '', pl: '' },
    'crm-etunimi': { fi: 'etunimi:', sv: '', en: 'first name:', ru: '', sk: '', pl: '' },
    'crm-filter-id-tai-otsikko-sisaltaa': {
        fi: 'ID tai otsikko sisältää',
        sv: 'ID eller titel innehåller',
        en: 'ID or title contains',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-filter-kayttaja': { fi: 'Katsele käyttäjänä', sv: 'Visa för', en: 'View as', ru: '', sk: '', pl: '' },
    'crm-filter-kiireellinen': {
        fi: 'Vain kiireelliset',
        sv: 'Bara bårdskande',
        en: 'Only urgent',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-filter-segmentti': { fi: 'Segmentti', sv: '', en: 'Segment', ru: '', sk: '', pl: '' },
    'crm-filter-tagi': { fi: 'Tagit', sv: 'Taggar', en: 'Tags', ru: '', sk: '', pl: '' },
    'crm-filter-tehtava-sisaltaa': {
        fi: 'Tehtävä sisältää',
        sv: 'Uppgift innehåller',
        en: 'Task contains',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-filter-tehtava-tekija': {
        fi: 'Tehtäviä käyttäjällä',
        sv: 'Uppgifter för användare',
        en: 'Tasks for user',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-filter-tehty-pvm': { fi: 'Luontipäivä', sv: 'Daterad', en: 'Created', ru: '', sk: '', pl: '' },
    'crm-filter-tehty-pvm-alkaen': {
        fi: 'Luontipäivä alkaen',
        sv: '',
        en: 'Creation date starting from',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-filter-tehty-pvm-paattyen': {
        fi: 'Luontipäivä päättyen',
        sv: '',
        en: 'Creation date until',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-filter-tikettityyppi': { fi: 'Tikettityyppi', sv: 'Typ', en: 'Type', ru: '', sk: '', pl: '' },
    'crm-gsm': { fi: 'GSM:', sv: 'GSM:', en: 'GSM:', ru: '', sk: '', pl: '' },
    'crm-kayttajatunnus': {
        fi: 'Käyttäjätunnus:',
        sv: '',
        en: 'User login ID:',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kayttooikeus': { fi: 'käyttöoikeus:', sv: '', en: 'access right:', ru: '', sk: '', pl: '' },
    'crm-kommentti-lisaa-uusi-label': {
        fi: '+ lisää kommentti',
        sv: '+ lägg till kommentar',
        en: '+ add a comment',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kopioi': { fi: 'Kopioi', sv: '', en: 'Copy', ru: '', sk: '', pl: '' },
    'crm-kopioi-yhteyshenkilo': {
        fi: 'Kopioi yhteyshenkilöasetukset toiselle yhteyshenkilölle..',
        se: '',
        en: 'Copy the contact person settings to another contact person',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kopioi-yhteyshenkilo-notification': {
        fi: 'Yhteyshenkilön tiedot kopioitu onnistuneesti',
        se: '',
        en: "Successfully copied the contact person's information",
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kopioi-yhteyshenkilon-tiedot': {
        fi: 'Kopioi yhteyshenkilon tiedot:',
        sv: ':',
        en: "Copy the contact person's information",
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kopioidaan-kayttajalta': {
        fi: 'Kopioidaan käyttäjältä',
        sv: '',
        en: 'Copying from user',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kopiointi-info': {
        fi: 'Seuraavat tiedot kopioidaan ja ylikirjoitetaan:\n - Yksiköt, joissa henkilö toimii yhteyshenkilönä \n - Oletusyhteyshenkilötiedot \n - Kotiyksikkö \n \n Huom.! Tiedot kopioidessa ylikirjoitetaan eli kohteeksi valitun henkilön nykyisiä yhteyshenkilöasetuksia ei voi kopioinnin jälkeen palauttaa.',
        sv: '',
        en: 'The following information is copied and overwritten:\n - Work units where the user is a contact person \n - The information for the default contact people \n - The home work unit \n\n Note! While copying the information is overwritten, meaning the current information of the target contact person can not be restored after the copying.',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kotiyksikko': { fi: 'Kotiyksikkö', sv: 'Kotiyksikkö', en: 'Home unit', ru: '', sk: '', pl: '' },
    'crm-kuittaaja-lisaa-uusi-label': {
        fi: '+ lisää kuittaaja',
        sv: '+ ny bekräftare',
        en: '+ new acknowledger',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kuittaus-automaattikuitattu': {
        fi: '(automaattikuitattu)',
        sv: '(automatiskt bekräftad)',
        en: '(automatically acknowledged)',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kuittaus-ei-kuitattu': {
        fi: '(ei vielä kuitattu)',
        sv: '(inte ännu bekräftad)',
        en: '(not yet acknowledged)',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kuittaus-ei-vaadi-kuittausta': {
        fi: 'Kuittauspyyntö',
        sv: 'Vänligen bekräfta',
        en: 'Acknowledgement requested',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-kuittaus-kuitattu': { fi: 'Kuitattu', sv: 'Bekräftad', en: 'Acknowledged', ru: '', sk: '', pl: '' },
    'crm-kuittaus-kuittaaja': { fi: 'Kuittaaja', sv: 'Bekräftare', en: 'Person', ru: '', sk: '', pl: '' },
    'crm-liite-lisaa-uusi-label': {
        fi: '+ lisää liitetiedosto',
        sv: '+ bifoga fil',
        en: '+ attach a file',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-linkitetty-tiketti-lisaa-uusi-label': {
        fi: '+ lisää uusi linkitys',
        sv: '+ lägg till länk',
        en: '+ add a linked ticket',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-lisaa-kokonaan-uusi-yhteyshenkilo': {
        fi: 'Lisää kokonaan uusi yhteyshenkilö',
        sv: '',
        en: 'Add a completely new contact person',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-lisaa-listasta': { fi: 'LISÄÄ LISTASTA', sv: 'LISÄÄ LISTASTA', en: 'LISÄÄ LISTASTA', ru: '', sk: '', pl: '' },
    'crm-lisaa-poisto-organisaatiopuu': {
        fi: '+ Lisää / poista organisaatiopuunäkymässä...',
        sv: '',
        en: '+ Add / remove in the organization tree view',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-lisaa-uusi': { fi: 'LISÄÄ UUSI', sv: '', en: 'ADD NEW', ru: '', sk: '', pl: '' },
    'crm-lisatieto': { fi: 'lisätieto:', sv: '', en: 'additional information:', ru: '', sk: '', pl: '' },
    'crm-listaa-tiketit': {
        fi: 'Listaa yhteyshenkilön tiketit',
        sv: '',
        en: "List the contact person's tickets",
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-login': { fi: 'login:', sv: 'login:', en: 'login:', ru: '', sk: '', pl: '' },
    'crm-luontipvm': { fi: 'Luonti-pvm', sv: '', en: 'Creation date', ru: '', sk: '', pl: '' },
    'crm-muuta': { fi: 'Muuta:', sv: '', en: 'Other::', ru: '', sk: '', pl: '' },
    'crm-nimi': { fi: 'Nimi', sv: '', en: 'Name', ru: '', sk: '', pl: '' },
    'crm-notification-ticket-open-multiple': {
        fi: 'katselevat myös tätä tikettiä.',
        sv: 'också tittar på tiketten.',
        en: 'are also viewing this ticket.',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-notification-ticket-open-single': {
        fi: 'katselee myös tätä tikettiä.',
        sv: 'också tittar på tiketten.',
        en: 'is also viewing this ticket.',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-on-kotiyksikko': {
        fi: 'Yksikkö on henkilön kotiyksikkö.',
        sv: '',
        en: 'The work unit is the home unit of the user',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-on-paayhteyshenkilo': {
        fi: 'Henkilö on yksikön pääyhteyshenkilö.',
        sv: '',
        en: 'The user is the primary contact person of the work unit',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-paallikko': { fi: 'Paallikko:', sv: ':', en: 'Manager:', ru: '', sk: '', pl: '' },
    'crm-palaute-koskee-asiakas': { fi: 'asiakasta', sv: 'en kund', en: 'an employee', ru: '', sk: '', pl: '' },
    'crm-palaute-koskee-ei-valintaa': { fi: 'Ei valintaa', sv: '', en: 'Not selected', ru: '', sk: '', pl: '' },
    'crm-palaute-koskee-tyontekija': {
        fi: 'työntekijää',
        sv: 'en anställd',
        en: 'an employee',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-palaute-koskee-valittaja': { fi: 'välittäjää', sv: 'mäklaren', en: 'the broker', ru: '', sk: '', pl: '' },
    'crm-palautekanava-contactor': { fi: 'Contactor', sv: 'Contactor', en: 'Contactor', ru: '', sk: '', pl: '' },
    'crm-palautekanava-email': { fi: 'Sähköposti', sv: 'E-post', en: 'Email', ru: '', sk: '', pl: '' },
    'crm-palautekanava-hotline': { fi: 'Hotline', sv: 'Hotline', en: 'Hotline', ru: '', sk: '', pl: '' },
    'crm-palautekanava-kasvotusten': { fi: 'Kasvotusten', sv: 'I person', en: 'In person', ru: '', sk: '', pl: '' },
    'crm-palautekanava-kysely': { fi: 'Kysely', sv: 'Förfrågan', en: 'Inquiry', ru: '', sk: '', pl: '' },
    'crm-palautekanava-puhelin': { fi: 'Puhelin', sv: 'Telefon', en: 'Phone', ru: '', sk: '', pl: '' },
    'crm-palautekanava-some': { fi: 'SoMe', sv: 'Sociala medier', en: 'Social media', ru: '', sk: '', pl: '' },
    'crm-palautesavy-negatiivinen': { fi: 'Negatiivinen', sv: 'Negativt', en: 'Negative', ru: '', sk: '', pl: '' },
    'crm-palautesavy-neutraali': { fi: 'Neutraali', sv: 'Neutralt', en: 'Neutral', ru: '', sk: '', pl: '' },
    'crm-palautesavy-positiivinen': { fi: 'Positiivinen', sv: 'Positivt', en: 'Positive', ru: '', sk: '', pl: '' },
    'crm-placeholder-gsm': {
        fi: '+358-muodossa, esim. +358400123456',
        sv: '',
        en: 'In +358 format (e.g. +358400123456)',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-poista-henkilo': {
        fi: 'Poista henkilö kokonaan',
        se: '',
        en: 'Remove the user completely',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-poista-henkilo-notification': {
        fi: 'Henkilö poistettu onnistuneesti',
        se: '',
        en: 'Successfully removed the user',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-poista-henkilo-kysymys': {
        fi: 'Haluatko varmasti poistaa yhteyshenkilön?',
        se: '',
        en: 'Are you sure you want to remove the contact person',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-puh': { fi: 'puh:', sv: ':', en: 'phone:', ru: '', sk: '', pl: '' },
    'crm-puhelin': { fi: 'Puhelin', sv: '', en: 'Phone', ru: '', sk: '', pl: '' },
    'crm-salasana': { fi: 'Salasana:', sv: '', en: 'Password:', ru: '', sk: '', pl: '' },
    'crm-sukunimi': { fi: 'sukunimi:', sv: '', en: 'surname:', ru: '', sk: '', pl: '' },
    'crm-tagi-kertakuitattava': {
        fi: 'Kertakuitattava',
        sv: '',
        en: 'To be acknowledged at one time',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tagi-kuittaajat': { fi: 'Kuittaajat', sv: 'Bekräftare', en: 'Acknowledgers', ru: '', sk: '', pl: '' },
    'crm-tagi-lisaa-uusi-placeholder': {
        fi: '+ lisää merkintä',
        sv: '+ ny tagg',
        en: '+ add a tag',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tagi-lisaa-uusi-placeholder-customer': {
        fi: '+ lisää merkintä (esim. työntekijä, tilausnumero tai asiakas jota palaute koskee)',
        sv: '',
        en: '+ add an entry (e.g. employee, order number or work unit that the feedback is related to)',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tagi-lisaa-uusi-placeholder-search': {
        fi: '+ lisää merkintä rajataksesi näytettäviä tikettejä',
        sv: '',
        en: '+ add an entry to limit the tickets shown',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tagi-lisaa-uusi-title-customer': {
        fi: 'Asiakas, työntekijä tai tilausnumero, jota palaute koskee',
        sv: '',
        en: 'Work unit, employee or order number that the feedback is related to',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tagi-nimi': { fi: 'Nimi', sv: 'Namn', en: 'Name', ru: '', sk: '', pl: '' },
    'crm-tagi-passiivinen': { fi: 'Passiivinen', sv: 'Passiv', en: 'Passive', ru: '', sk: '', pl: '' },
    'crm-tagi-siirra-kayttajalle': { fi: 'Käyttäjälle', sv: '', en: 'To user', ru: '', sk: '', pl: '' },
    'crm-tagi-siirra-kayttajalta': { fi: 'Käyttäjältä', sv: '', en: 'From user', ru: '', sk: '', pl: '' },
    'crm-tehtava-ei-tehty': {
        fi: '(ei vielä tehty)',
        sv: '(inte ännu gjort)',
        en: '(not yet done)',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tehtava-kuittaa-action': { fi: 'KUITTAA', sv: 'BEKRÄFTA', en: 'ACKNOWLEDGE', ru: '', sk: '', pl: '' },
    'crm-tehtava-lisaa-uusi-label': {
        fi: '+ lisää tehtävä',
        sv: '+ ny uppgift',
        en: '+ add a task',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tehtava-nimi': { fi: 'Kuvaus', sv: 'Beskrivning', en: 'Description', ru: '', sk: '', pl: '' },
    'crm-tehtava-tavoite-pvm': { fi: 'Tavoitepäivämäärä', sv: 'Måldatum', en: 'Target date', ru: '', sk: '', pl: '' },
    'crm-tehtava-tehty': { fi: 'Tehty', sv: 'Färdig', en: 'Marked done', ru: '', sk: '', pl: '' },
    'crm-tehtava-tekija': { fi: 'Tekijä', sv: 'Innehavare', en: 'Assignee', ru: '', sk: '', pl: '' },
    'crm-tiketit-otsikko': { fi: 'Tiketit', sv: '', en: 'Tickets', ru: '', sk: '', pl: '' },
    'crm-tiketti': { fi: 'Tiketti', sv: 'Tiketti', en: 'Tiketti', ru: '', sk: '', pl: '' },
    'crm-tiketti-asiakas-feedback': {
        fi: 'Asiakas, jota palaute koskee',
        sv: 'Kunden, som feed-back gäller',
        en: 'Customer the feedback is about',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-ei-vaadi-kuittauksia-label': {
        fi: 'Kuittauksia ei vaadita',
        sv: 'Bekräftelser inte behovs',
        en: 'Acknowledgements not required',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-heratyskello-tooltip': { fi: 'Kiireellinen', sv: 'Brådskande', en: 'Urgent', ru: '', sk: '', pl: '' },
    'crm-tiketti-id-label': { fi: '#', sv: '#', en: '#', ru: '', sk: '', pl: '' },
    'crm-tiketti-kiireellinen-label': { fi: 'Kiireellinen', sv: 'Brådskande', en: 'Urgent', ru: '', sk: '', pl: '' },
    'crm-tiketti-kloonaa': {
        fi: 'Luo uusi tiketti tämän tiketin pohjalta',
        sv: '',
        en: 'Create a new ticket based on this ticket',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-kommentit-label': { fi: 'Kommentit', sv: 'Kommentarer', en: 'Comments', ru: '', sk: '', pl: '' },
    'crm-tiketti-kuittaukset-label': {
        fi: 'Kuittaukset',
        sv: 'Bekräftelser',
        en: 'Acknowledgements',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-liite-tooltip': {
        fi: 'Sisältää liitteen',
        sv: 'Innehåller en bifogad fil',
        en: 'Contains an attachment',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-liitteet-label': {
        fi: 'Liitetiedostot',
        sv: 'Bifogade filer',
        en: 'Attachments',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-linkitetyt-tiketit-label': {
        fi: 'Linkitetyt tiketit',
        sv: 'Länkade tiketter',
        en: 'Linked tickets',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-otsikko-label': { fi: 'Otsikko', sv: 'Titel', en: 'Title', ru: '', sk: '', pl: '' },
    'crm-tiketti-palaute-annettu-pvm-label': {
        fi: 'Palaute annettu',
        sv: 'Avsänt datum',
        en: 'Sent date',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-palaute-antaja-label': {
        fi: 'Palautteen lähettäjä',
        sv: 'Avsändare',
        en: 'Sender',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-palaute-kontaktointi-label': {
        fi: 'Yhteydenottopyyntö',
        sv: 'Vänligen kontakta',
        en: 'Contact requested',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-palaute-koskee-label': {
        fi: 'Palaute koskee',
        sv: 'Feed-back gäller för',
        en: 'Feedback is about',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-palaute-teksti-label': {
        fi: 'Palautteesi',
        sv: 'Ditt feed-back',
        en: 'Your feedback',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-palaute-ulkoinen-antaja-label': {
        fi: 'Palautteen antaja ei ole järjestelmässä',
        sv: 'Avsänder finns inte i systemet',
        en: 'Sender does not exist in the system',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-palautekanava-label': {
        fi: 'Palautekanava',
        sv: 'Mottagen från',
        en: 'Received from',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-palautesavy-label': { fi: 'Palautteen sävy', sv: 'Tånen', en: 'Tone', ru: '', sk: '', pl: '' },
    'crm-tiketti-tagit-label': { fi: 'Tagit', sv: 'Taggar', en: 'Tags', ru: '', sk: '', pl: '' },
    'crm-tiketti-tahti-tooltip': { fi: 'Ei katsottu', sv: 'Osynlig', en: 'Unseen', ru: '', sk: '', pl: '' },
    'crm-tiketti-tavoite-pvm-label': {
        fi: 'Tavoitepäivämäärä',
        sv: 'Måldatum',
        en: 'Target date',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-tehtavat-label': { fi: 'Tehtävät', sv: 'Uppgifter', en: 'Tasks', ru: '', sk: '', pl: '' },
    'crm-tiketti-teksti-label': { fi: 'Tiketin kuvaus', sv: 'Beskrivning', en: 'Description', ru: '', sk: '', pl: '' },
    'crm-tiketti-teksti-placeholder-employee': {
        fi: 'Kirjoita palautteeseen myös mahdollinen toimipiste, esimies tai työvuoro, jota palaute koskee.',
        sv: '',
        en: 'Add a possible work unit, superior or shift that the feedback is about',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-tilaus-feedback': {
        fi: 'Työvuoro, jota palaute koskee',
        sv: 'Skifte, som feed-back gäller',
        en: 'Shift the feedback is about',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-uusi-label': { fi: 'Otsikko', sv: 'Ämne', en: 'Subject', ru: '', sk: '', pl: '' },
    'crm-tiketti-uusi-palaute-label': {
        fi: 'Palautteen otsikko',
        sv: 'Ämne till feed-back',
        en: 'Feedback subject',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-Tikettilistaus': {
        fi: 'Tikettilistaus:',
        sv: '',
        en: 'Ticket list:',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tikettityyppi': { fi: 'Tikettityyppi', sv: '', en: 'Type', ru: '', sk: '', pl: '' },
    'crm-tikettityyppi-kontaktointi': { fi: 'Kontaktointi', sv: '', en: 'Contact', ru: '', sk: '', pl: '' },
    'crm-tikettityyppi-palaute': { fi: 'Palaute', sv: 'Feed-back', en: 'Feedback', ru: '', sk: '', pl: '' },
    'crm-tikettityyppi-palvelupyynto': { fi: 'Palvelupyyntö', sv: '', en: 'Help request', ru: '', sk: '', pl: '' },
    'crm-tikettityyppi-palvelupyynto_sisainen': {
        fi: 'Palvelupyyntö sisäinen',
        sv: '',
        en: 'Help request internal',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tikettityyppi-placeholder-search': {
        fi: '+ rajaa tikettityypillä',
        sv: '',
        en: '+ limit by type',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tikettityyppi-tapaaminen': { fi: 'Tapaaminen', sv: '', en: 'Meeting', ru: '', sk: '', pl: '' },
    'crm-tikettityyppi-tehtava': { fi: 'Tehtävä', sv: 'Uppgift', en: 'Task', ru: '', sk: '', pl: '' },
    'crm-tikettityyppi-tilauskannan_haltuunotto': {
        fi: 'Tilauskannan haltuunotto',
        sv: '',
        en: 'Confiscation of the order base',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tilapainen-salasana': {
        fi: 'Luo tilapäinen salasana:',
        sv: '',
        en: 'Create a temporary password',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tilapainen-salasana-antaminen': {
        fi: 'Tilapäinen salasana:',
        sv: '',
        en: 'Temporary password:',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tilapainen-salasana-luotu': {
        fi: 'Tilapäinen salasana luotu onnistuneesti! Salasana on voimassa 30 minuuttia',
        sv: '',
        en: 'Temporary password created successfully! The password expires after 30 minutes',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-title-transfer-acknowledgements': {
        fi: 'Siirrä kuittaukset',
        sv: 'Flytta bekräftelse',
        en: 'Transfer acknowledgements',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-Titteli': { fi: 'Titteli:', sv: '', en: 'Title:', ru: '', sk: '', pl: '' },
    'crm-tyokalut': { fi: 'Työkalut', sv: '', en: 'Tools', ru: '', sk: '', pl: '' },
    'crm-valitse-asiakkaat-exceliin': {
        fi: 'Valitse asiakkaat joiden yhteyshenkilöiden tiedot halutaan exceliin',
        sv: '',
        en: 'Select the work units the contact people of which are to be exported to the excel',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-valitse-uusi-yhteyshenkilo-listasta': {
        fi: 'Valitse uusi yhteyshenkilö listasta',
        sv: '',
        en: 'Select a new contact person from the list',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-valitseasiakas-listasta': {
        fi: 'Valitse asiakas viereisestä listasta:',
        sv: '',
        en: 'Select a work unit from the list',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-valmistumis-pvm': { fi: 'Valmistumis-pvm', sv: '', en: 'Ready date', ru: '', sk: '', pl: '' },
    'crm-yhteyshenkilo-lisaa-notification': {
        fi: 'Yhteyshenkilö lisätty onnistuneesti!',
        sv: '',
        en: 'Contact person added successfully!',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-yhteyshenkilo-uusi': { fi: 'Uusi yhteyshenkilö', sv: '', en: 'New contact person', ru: '', sk: '', pl: '' },
    'crm-yhteyshenkilon-poisto-asiakkaalta': {
        fi: 'Haluatko varmasti poistaa yhteyshenkilön asiakkaalta?',
        sv: '',
        en: 'Are you sure you want to remove the contact person from the work unit?',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-yhteyshenkilon-yksikot': {
        fi: 'Yhteyshenkilön yksiköt',
        sv: '',
        en: 'The contact people of the work unit',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-yhteyshenkilot': { fi: 'Yhteyshenkilöt', sv: '', en: 'Contact people', ru: '', sk: '', pl: '' },
    'crm-yksikot': { fi: 'Yksiköt:', sv: ':', en: 'Work units:', ru: '', sk: '', pl: '' },
    'customer-additional-info': {
        fi: 'Lisätiedot hyväksyjältä',
        sv: '',
        en: 'Approval information',
        ru: '',
        sk: '',
        pl: '',
    },
    'data-source.ExcelDownload.Error': {
        fi: 'Tiedon lataaminen Excel-tiedostoon epäonnistui',
        sv: '',
        en: 'Downloading data to an excel file failed',
        ru: '',
        sk: '',
        pl: '',
    },
    DELETE: { fi: 'Poisto', sv: '', en: 'Delete', ru: '', sk: '', pl: '' },
    'Dropdown.Value.MultipleValuesWithTypeName': {
        fi: 'Usea {translatedTypeName}',
        sv: '',
        en: 'Multiple {translatedTypeName}',
        ru: '',
        sk: '',
        pl: '',
    },
    'ehdota-automaattisesti': {
        fi: 'Ehdota automaattisesti',
        sv: '',
        en: 'Suggest automatically',
        ru: '',
        sk: '',
        pl: '',
    },
    'ehdota-tyontekijoita-tilaukseen': {
        fi: 'Ehdota työntekijöitä tilaukseen',
        sv: '',
        en: 'Suggest employees for the order',
        ru: '',
        sk: '',
        pl: '',
    },
    ehdotettu: { fi: 'Ehdotettu', sv: '', en: 'Suggested', ru: '', sk: '', pl: '' },
    ei: { fi: 'Ei', se: 'Nej', en: 'No', ru: 'Njet', sk: '', pl: '' },
    'ei-oikeutta-syottaa-laskutusviitetta': {
        fi: 'Ei oikeutta syöttää laskutusviitettä',
        sv: '',
        en: 'No access right to enter invoicing reference',
        ru: '',
        sk: '',
        pl: '',
    },
    'ei-erikoistaitoja': { fi: 'Ei erikoistaitoja', se: '', en: 'No skills', ru: '', sk: '', pl: '' },
    'ei-lounastaukoa': { fi: 'Ei lounastaukoa', se: '', en: 'No lunch break', ru: '', sk: '', pl: '' },
    'ei-loytynyt-asiakkaita': {
        fi: 'Ei löytynyt asiakkaita, yritä toisella hakusanalla',
        se: '',
        en: 'No work units found, try with another search term',
        ru: '',
        sk: '',
        pl: '',
    },
    'ei-loytynyt-ehdotettavia-tyontekijoita': {
        fi: 'Ei löytynyt ehdotettavia työntekijöitä',
        se: '',
        en: "Didn't find any employees to suggest",
        ru: '',
        sk: '',
        pl: '',
    },
    'ei-loytynyt-tuloksia': {
        fi: 'Ei löytynyt tuloksia, yritä toisella hakusanalla',
        se: '',
        en: 'No results found, try with another search term',
        ru: '',
        sk: '',
        pl: '',
    },
    'ei-loytynyt-tyontekijoita-hakusanalla': {
        fi: 'Ei löytynyt työntekijöitä, yritä toisella hakusanalla',
        se: '',
        en: 'No employees found, try with another search term',
        ru: '',
        sk: '',
        pl: '',
    },
    'ei-loytynyt-tyontekijoita-asiakas-puuttuu': {
        fi: 'Ei löytynyt työntekijöitä valitulle asiakkaalle',
        se: '',
        en: 'No employees found for the selected work unit',
        ru: '',
        sk: '',
        pl: '',
    },
    'ei-loytynyt-vahvistettavia-tyontekijoita': {
        fi: 'Ei löytynyt vahvistettavia työntekijöitä',
        se: '',
        en: 'No confirmed employees found',
        ru: '',
        sk: '',
        pl: '',
    },
    'ei-ruokaperintaa': { fi: 'Ei ruokaperintää', se: '', en: 'No lunch benefit', ru: '', sk: '', pl: '' },
    'ei-vuoroja': { fi: 'Ei vuoroja', se: '', en: 'No shifts', ru: '', sk: '', pl: '' },
    'employee-additional-info': {
        fi: 'Lisätiedot työntekijältä',
        sv: '',
        en: 'Additional information from the employee',
        ru: '',
        sk: '',
        pl: '',
    },
    'tyontekijat-definite': { fi: 'työntekijää', sv: '', en: 'employees', ru: '', sk: '', pl: '' },
    'ehdota-vuoroa': { fi: 'Ehdota vuoroa', sv: '', en: 'Suggest the shift', ru: '', sk: '', pl: '' },
    'vahvista-vuoro': { fi: 'Vahvista vuoro', sv: '', en: 'Confirm the shift', ru: '', sk: '', pl: '' },
    'end-date-short': { fi: 'Loppu-pvm', sv: '', en: 'End date', ru: '', sk: '', pl: '' },
    'enum-Palkkaa': { fi: 'Palkkaa', sv: '', en: 'Salary', ru: '', sk: '', pl: '' },
    'enum-Poissaolo': { fi: 'Poissaolo', sv: '', en: 'Absence', ru: '', sk: '', pl: '' },
    'enum-Vuoro': { fi: 'Työvuoro', sv: '', en: 'Shift', ru: '', sk: '', pl: '' },
    erikoistaito: { fi: 'Erikoistaito', sv: '', en: 'Skill', ru: '', sk: '', pl: '' },
    'erikoistaito-ei-valittuja': {
        fi: 'Ei valittuja erikoistaitoja',
        sv: '',
        en: 'No selected skills',
        ru: '',
        sk: '',
        pl: '',
    },
    'erikoistaitoja-lyhenne': { fi: 'ET', sv: '', en: 'Skill', ru: '', sk: '', pl: '' },
    'erikoistaitolistaus-nayta': {
        fi: 'Näytä valittavat erikoistaidot',
        sv: '',
        en: 'Show skills to be selected',
        ru: '',
        sk: '',
        pl: '',
    },
    'erikoistaitolistaus-piilota': {
        fi: 'Piilota valittavat erikoistaidot',
        sv: '',
        en: 'Hide skills to be selected',
        ru: '',
        sk: '',
        pl: '',
    },
    erikoistaidot: { fi: 'Erikoistaidot', sv: '', en: 'Skills', ru: '', sk: '', pl: '' },
    erikoistaitovalinta: { fi: 'Erikoistaidot', sv: '', en: 'Skills', ru: '', sk: '', pl: '' },
    etunimi: { fi: 'Etunimi', sv: '', en: 'First name', ru: '', sk: '', pl: '' },
    Gsm: { fi: 'Gsm', sv: '', en: 'Gsm', ru: '', sk: '', pl: '' },
    sukunimi: { fi: 'Sukunimi', sv: '', en: 'Surname', ru: '', sk: '', pl: '' },
    expand: { fi: 'Enemmän', sv: 'Mera', en: 'More', ru: '', sk: '', pl: '' },
    'form-view.ErrorAlert.Label': {
        fi: 'Täytä puuttuvat tiedot',
        sv: '',
        en: 'Fill in the missing fields',
        ru: '',
        sk: '',
        pl: '',
    },
    'form-view.SaveAndContinueButton.Create': {
        fi: 'Tallenna & luo uusi',
        sv: '',
        en: 'Save & Create new',
        ru: '',
        sk: '',
        pl: '',
    },
    'form-view.FormWasContinuedAlert.Start': {
        fi: 'Tallennus onnistui. Luo uusi käyttämällä tietoja pohjana tai ',
        sv: '',
        en: 'Creating the item succeeded. Create new using the data as a template or ',
        ru: '',
        sk: '',
        pl: '',
    },
    'form-view.FormWasContinuedAlert.End': {
        fi: 'tyhjennä lomake.',
        sv: '',
        en: 'empty the form.',
        ru: '',
        sk: '',
        pl: '',
    },
    'form-view-components.FormViewHoursInput.label.default': {
        fi: 'Jakson pituus',
        sv: '',
        en: 'Lengh of the time period',
        ru: '',
        sk: '',
        pl: '',
    },
    'form-view.FormViewLoadFailed': {
        fi: 'Lomaketta ei voitu ladata.',
        sv: 'Kunde inte hämta formuläret.',
        en: 'The form could not be loaded.',
        ru: '',
        sk: '',
        pl: '',
    },
    'Form.Error.ErrorLoading': {
        fi: 'Tapahtui virhe tietoa ladattaessa. Sulje lomake ja avaa se uudestaan',
        sv: '',
        en: 'The system ran into an error while loading data. Please try to reopen the form.',
        ru: '',
        sk: '',
        pl: '',
    },
    'Form.Error.FieldRequired': {
        fi: 'Arvo vaaditaan',
        sv: '',
        en: 'Value required',
        ru: '',
        sk: '',
        pl: '',
    },
    'Form.Validation.NoChanges': {
        fi: 'Kenttien arvoissa ei muutoksia',
        sv: '',
        en: 'No changes in the values of the fields',
        ru: '',
        sk: '',
        pl: '',
    },
    'factoring-oletus': { fi: 'Factoring (oletus)', sv: '', en: 'Factoring (default)', ru: '', sk: '', pl: '' },
    'filtteroi-asiakkaita': {
        fi: 'Suodata asiakkaita tässä',
        sv: '',
        en: 'Filter work units here',
        ru: '',
        sk: '',
        pl: '',
    },
    'filtteroi-tassa': { fi: 'Suodata tässä', sv: '', en: 'Filter here', ru: '', sk: '', pl: '' },
    'filtteroi-tyontekijoita': {
        fi: 'Suodata työntekijöitä tässä',
        sv: '',
        en: 'Filter employees here',
        ru: '',
        sk: '',
        pl: '',
    },
    'filtteroi-tyontekijoita-pooleilla': {
        fi: 'Suodata työntekijöitä pooleilla',
        sv: '',
        en: 'Filter employees by pools',
        ru: '',
        sk: '',
        pl: '',
    },
    GraafisetRaportit: { fi: 'Graafiset raportit', sv: '', en: 'Graphic reports', ru: '', sk: '', pl: '' },
    'generic-components.DayRangePicker.StartLabel.DefaultValue': {
        fi: 'Alkupvm',
        sv: '',
        en: 'Start date',
        ru: '',
        sk: '',
        pl: '',
    },
    'generic-components.DayRangePicker.EndLabel.DefaultValue': {
        fi: 'Päättymispvm',
        sv: '',
        en: 'End date',
        ru: '',
        sk: '',
        pl: '',
    },
    'generic-components.DayPickerWithRangeAndWeekDays.ToggleLabel.DefaultValue': {
        fi: 'Usean päivän jakso',
        sv: '',
        en: 'Multiple days period',
        ru: '',
        sk: '',
        pl: '',
    },
    'generic-components.DayPicker.Label.DefaultValue': {
        fi: 'Syötä päivä',
        sv: '',
        en: 'Select date',
        ru: '',
        sk: '',
        pl: '',
    },
    'generic-components.Dropdown.LoadingContentText': {
        fi: 'Ladataan sisältöä. Odota hetki..',
        sv: '',
        en: 'Loading more content. Please wait..',
        ru: '',
        sk: '',
        pl: '',
    },
    'generic-components.Dropdown.NoContentFoundText': {
        fi: 'Sisältöä ei löytynyt. Voit kokeilla vaihtaa muiden valikoiden arvoja.',
        sv: '',
        en: 'No content found. You may want to try changing the other filters.',
        ru: '',
        sk: '',
        pl: '',
    },
    'generic-components.Inputs.TimeRangeInput.TimeRangeRequired': {
        fi: 'Alkuaika ja loppuaika ovat vaadittuja kenttiä.',
        sv: '',
        en: 'Start and end time are required.',
        ru: '',
        sk: '',
        pl: '',
    },
    'GenericComponents.TimeLength': { fi: 'Pituus (hh:mm)', sv: '', en: 'Duration (hh:mm)', ru: '', sk: '', pl: '' },
    hae: { fi: 'Hae', se: 'Hae', en: 'Search', ru: '', sk: '', pl: '' },
    henkilomaara: { fi: 'Henkilömäärä', sv: '', en: 'Person count', ru: '', sk: '', pl: '' },
    hyvaksy: { fi: 'Hyväksy', sv: '', en: 'Approve', ru: '', sk: '', pl: '' },
    haku: {
        fi: 'Haku',
        sv: '',
        en: 'Search',
        ru: '',
        sk: '',
        pl: '',
    },
    'hyvaksy-tyovuorotapahtuma-tooltip-disabled': {
        fi: 'Ei voida hyväksyä, koska tapahtuma on tulevaisuudessa',
        sv: '',
        en: "Can't approve because the event is in future",
        ru: '',
        sk: '',
        pl: '',
    },
    'hyvaksy-vapaa': { fi: 'Hyväksy vapaa', sv: '', en: 'Approve the vacation or sick leave', ru: '', sk: '', pl: '' },
    'hyvaksy-vapaat': {
        fi: 'Hyväksy vapaat',
        sv: '',
        en: 'Approve the vacations or sick leaves',
        ru: '',
        sk: '',
        pl: '',
    },
    'hyvaksy-vuoro': { fi: 'Hyväksy vuoro', sv: '', en: 'Approve the shift', ru: '', sk: '', pl: '' },
    'hyvaksy-vuoro-kommentti-otsikko': {
        fi: 'Työvuoroon liittyvä lisätieto',
        sv: '',
        en: 'Additional information regarding the shift',
        ru: '',
        sk: '',
        pl: '',
    },
    'hyvaksy-vuoro-tooltip': { fi: 'Hyväksy vuoro', sv: '', en: 'Approve the shift', ru: '', sk: '', pl: '' },
    'hyvaksy-vuorot': { fi: 'Hyväksy vuorot', sv: '', en: 'Aprove the shifts', ru: '', sk: '', pl: '' },
    'hyvaksy-vuorot-ja-vapaat': {
        fi: 'Hyväksy vuorot ja vapaat',
        sv: '',
        en: 'Approve the events',
        ru: '',
        sk: '',
        pl: '',
    },
    'hyvaksy-vuorot-samoilla-tiedoilla': {
        fi: 'Kaikki vuorot hyväksytään samoilla tiedoilla',
        sv: '',
        en: 'All the shifts are approved with the same information',
        ru: '',
        sk: '',
        pl: '',
    },
    'hyvaksynnan-peruminen': { fi: 'Peru hyväksyntä', sv: '', en: 'Undo the approval', ru: '', sk: '', pl: '' },
    'hyvaksynnan-peruminen-tooltip': { fi: 'Peru hyväksyntä', sv: '', en: 'Undo the approval', ru: '', sk: '', pl: '' },
    hyvaksynta: { fi: 'Hyväksyntä', sv: '', en: 'Approval', ru: '', sk: '', pl: '' },
    'hyvaksyntaa-odottavat': {
        fi: 'Hyväksyntää odottavat',
        sv: '',
        en: 'Waiting for approval',
        ru: '',
        sk: '',
        pl: '',
    },
    'hyvaksyttya-ei-voi-muokata': {
        fi: 'Hyväksytyttyä ei voi muokata',
        sv: '',
        en: "Can't modify approved event",
        ru: '',
        sk: '',
        pl: '',
    },
    hyvaksytyt: { fi: 'Hyväksytyt', sv: '', en: 'Approved', ru: '', sk: '', pl: '' },
    'information-not-given': { fi: '(ei annettu)', sv: '(inte givit)', en: '(not given)', ru: '', sk: '', pl: '' },
    'input-kokonaisluku-virheellinen-arvo': {
        fi: 'Annettu arvo ei ole kokonaisluku',
        sv: '',
        en: 'The value given can not be a whole number',
        ru: '',
        sk: '',
        pl: '',
    },
    'input-kokonaisluku-lista-placeholder': {
        fi: 'Esim. 1,23,456',
        sv: '',
        en: 'E.g. 1,23,456',
        ru: '',
        sk: '',
        pl: '',
    },
    'input-kokonaisluku-lista-virheellinen-arvo': {
        fi: 'Anna lista pilkulla (,) eroteltuja kokonaislukuja',
        sv: '',
        en: 'Give a list of whole numbers with a comma (,) as the separator',
        ru: '',
        sk: '',
        pl: '',
    },
    INSERT: { fi: 'Tallennus', sv: '', en: 'Insert', ru: '', sk: '', pl: '' },
    'input-paivamaara-virheellinen-arvo': {
        fi: 'Annettu arvo ei ole päivämäärä',
        sv: '',
        en: 'The given value is not a valid date',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-alue-placeholder': {
        fi: 'Valitse alueet joilta siirretään',
        sv: 'Välja områdena',
        en: 'Select areas to transfer from',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-ei-aluekohtainen': { fi: '(ei aluekohtainen)', sv: '', en: '(no domain specific)', ru: '', sk: '', pl: '' },
    'int-ei-siirrettavaa': { fi: '(ei siirrettävää)', sv: '', en: '(nothing to transfer)', ru: '', sk: '', pl: '' },
    'int-laskun-pvm-filter': { fi: 'Laskun päivämäärä', sv: '', en: 'Date for the invoice', ru: '', sk: '', pl: '' },
    'int-lataa-siirtotiedosto': {
        fi: 'Lataa siirtotiedosto',
        sv: '',
        en: 'Download the transfer data file',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-lataa-siirtovirhe': {
        fi: 'Lataa siirron virhetiedot',
        sv: '',
        en: 'Download the error data for the transfer',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-poista-vanhat-siirrot': {
        fi: 'Poista yli 30 pv vanhat siirrot',
        sv: 'Radera löpta överföringar',
        en: 'Remove old transfers',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirra-kaikki-uudelleen': {
        fi: 'Siirrä kaikki uudelleen',
        sv: 'Överför alla',
        en: 'Retransfer all',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirra-kellokorttitiedot': {
        fi: 'Siirrä kellokorttitiedot',
        sv: 'Överför stämpelkortsdata',
        en: 'Transfer timecard data',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirra-laskut': {
        fi: 'Siirrä laskut',
        sv: 'Överför räkningar',
        en: 'Transfer invoices',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirra-palkat': { fi: 'Siirrä palkat', sv: 'Överför lön', en: 'Transfer salaries', ru: '', sk: '', pl: '' },
    'int-siirra-siirtoera-uudelleen-tooltip': {
        fi: 'Siirrä siirtoerä uudelleen',
        sv: '',
        en: 'Retransfer the batch again',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirra-tyontekijat': {
        fi: 'Siirrä työntekijät',
        sv: 'Överför medarbetare',
        en: 'Transfer employees',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirra-vuorot-ja-vapaat': {
        fi: 'Siirrä vuorot ja vapaat',
        sv: 'Överför skifter och ledigheter',
        en: 'Transfer shifts and absences',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirra-yksikko-uudelleen-tooltip': {
        fi: 'Siirrä vain tämä asiakas uudelleen',
        sv: 'Överför bara den kunden',
        en: 'Retransfer only this customer',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirrot-otsikko': { fi: 'Siirrot', sv: '', en: 'Transfers', ru: '', sk: '', pl: '' },
    'int-siirto-aikaleima': { fi: 'Aikaleima', sv: 'Tidpunkt', en: 'Timestamp', ru: '', sk: '', pl: '' },
    'int-siirto-alue': { fi: 'Alue', sv: 'Område', en: 'Domain', ru: '', sk: '', pl: '' },
    'int-siirto-asiakkaat': { fi: 'Asiakkaat', sv: 'Kunder', en: 'Work units', ru: '', sk: '', pl: '' },
    'int-siirto-siirtaja': { fi: 'Siirtäjä', sv: 'Överförs av', en: 'Transferred by', ru: '', sk: '', pl: '' },
    'int-siirto-siirtoera': { fi: 'Siirtoerä', sv: 'Sats', en: 'Batch', ru: '', sk: '', pl: '' },
    'int-siirto-siirtotyyppi': {
        fi: 'Siirtotyyppi',
        sv: 'Överföringstyp',
        en: 'Transfer type',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirto-siirtotyyppi-Kellokorttisiirto': {
        fi: 'Kellokorttisiirto',
        sv: '',
        en: 'Timecard transfer',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirto-siirtotyyppi-Laskutussiirto': {
        fi: 'Laskutussiirto',
        sv: '',
        en: 'Invoicing transfer',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirto-siirtotyyppi-Palkkasiirto': {
        fi: 'Palkkasiirto',
        sv: '',
        en: 'Salary transfer',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirto-siirtotyyppi-Tyontekijasiirto': {
        fi: 'Työntekijöiden siirto',
        sv: '',
        en: 'Employee transfer',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirto-siirtotyyppi-VuoroVapaaSiirto': {
        fi: 'Vuorojen ja vapaiden siirto',
        sv: '',
        en: 'Transfer of shifts and vacations / sick leaves and such',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirto-tila': { fi: 'Tila', sv: 'Status', en: 'Status', ru: '', sk: '', pl: '' },
    'int-siirto-tila-Epaonnistui': { fi: 'Epäonnistui', sv: 'Avbryts', en: 'Unsuccessful', ru: '', sk: '', pl: '' },
    'int-siirto-tila-EpaonnistuiOsittain': {
        fi: 'Epäonnistui osittain',
        sv: 'Delvis avbryts',
        en: 'Partially unsuccessful',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirto-tila-Onnistui': { fi: 'Onnistui', sv: 'Succé', en: 'Successful', ru: '', sk: '', pl: '' },
    'int-siirto-tila-Uudelleensiirretty': {
        fi: 'Uudelleensiirretty',
        sv: 'Överförts',
        en: 'Retransferred',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-siirto-tila-UudelleensiirrettyOsittain': {
        fi: 'Uudelleensiirretty osittain',
        sv: 'Delvis överförts',
        en: 'Partially retransferred',
        ru: '',
        sk: '',
        pl: '',
    },
    'int-yhteyskumppani-selite': { fi: 'Selite', sv: 'Beskrivning', en: 'Description', ru: '', sk: '', pl: '' },
    'int-yhteyskumppani-tunniste': { fi: 'Tunniste', sv: 'Kod', en: 'Code', ru: '', sk: '', pl: '' },
    'int-yhteyskumppanit-otsikko': { fi: 'Yhteyskumppanit', sv: '', en: 'Partners', ru: '', sk: '', pl: '' },
    'Irrota tapahtuma tapahtumaryhmästä': {
        fi: 'Irrota tapahtuma tapahtumaryhmästä',
        sv: '',
        en: 'Detach from even group',
        ru: '',
        sk: '',
        pl: '',
    },
    julkaistu: { fi: 'julkaistu', sv: '', en: 'published', ru: '', sk: '', pl: '' },
    julkaisu: { fi: 'Julkaisu', sv: '', en: 'Publishing', ru: '', sk: '', pl: '' },
    'kaikki-ammattinimikkeet': { fi: 'Kaikki ammattinimikkeet', sv: '', en: 'All job titles', ru: '', sk: '', pl: '' },
    'kaikki-ei-poolivalintaa': {
        fi: 'Kaikki (ei poolivalintaa)',
        sv: '',
        en: 'All (no pool selection)',
        ru: '',
        sk: '',
        pl: '',
    },
    'kaikki-hyvaksyntastatukset': {
        fi: 'Kaikki hyväksymisstatukset',
        sv: '',
        en: 'All approval statuses',
        ru: '',
        sk: '',
        pl: '',
    },
    'kaikki-laskutuserat': {
        fi: 'Kaikki laskutuserät',
        sv: '',
        en: 'All billing lots',
        ru: '',
        sk: '',
        pl: '',
    },
    'kaikki-tayttoasteet': {
        fi: 'Kaikki Täyttöasteet',
        sv: '',
        en: 'All "Täyttöasteet" (???)',
        ru: '',
        sk: '',
        pl: '',
    },
    'kaikki-tyontekijat': { fi: 'Kaikki työntekijät', sv: '', en: 'All employees', ru: '', sk: '', pl: '' },
    'kaikki-vuorotyypit': { fi: 'Kaikki vuorotyypit', sv: '', en: 'All shift types', ru: '', sk: '', pl: '' },
    kalenteri: { fi: 'Kalenteri', sv: '', en: 'Calendar', ru: '', sk: '', pl: '' },
    kayttaja: { fi: 'Käyttäjä', sv: '', en: 'User', ru: '', sk: '', pl: '' },
    'KayttoOikeus.SyyKieltoonMissing': {
        fi: 'Syy kieltoon puuttuu',
        sv: '',
        en: 'The reason for removal is missing',
        ru: '',
        sk: '',
        pl: '',
    },
    kello: { fi: 'kello', sv: '', en: 'at', ru: '', sk: '', pl: '' },
    'kellonaika-muoto': { fi: '(hh:mm)', se: '', en: '(hh:mm)', ru: '', sk: '', pl: '' },
    keskenerainen: { fi: 'Keskeneräinen', se: '', en: 'Incomplete', ru: '', sk: '', pl: '' },
    kieli: { fi: 'Kieli', se: '', en: 'Language', ru: '', sk: '', pl: '' },
    klo: { fi: 'klo', sv: '', en: 'at', ru: '', sk: '', pl: '' },
    kommentit: { fi: 'Kommentit', sv: '', en: 'Comments', ru: '', sk: '', pl: '' },
    kommentti: { fi: 'Kommentti', se: '', en: 'Comment', ru: '', sk: '', pl: '' },
    'kotisivun-osoitteen-muoto-on-vaara': {
        fi: 'Kotisivun osoitteen muoto on väärä.',
        se: '',
        en: 'The format for the home page is wrong.',
        ru: '',
        sk: '',
        pl: '',
    },
    kpl: { fi: 'kpl', sv: '', en: 'pieces', ru: '', sk: '', pl: '' },
    'kuittaa-luetuksi': {
        fi: 'Kuittaa luetuksi',
        se: '',
        en: 'Acknowledge as read',
        ru: '',
        sk: '',
        pl: '',
    },
    'kuittaa-nakyman-viestit-luetuksi': {
        fi: 'Kuittaa näkymän viestit luetuksi',
        sv: '',
        en: 'Ackonwledge the messages in the view as read',
        ru: '',
        sk: '',
        pl: '',
    },
    'kuukausi-elo': { fi: 'Elokuu', sv: '', en: 'August', ru: '', sk: '', pl: '' },
    'kuukausi-heina': { fi: 'Heinäkuu', sv: '', en: 'July', ru: '', sk: '', pl: '' },
    'kuukausi-helmi': { fi: 'Helmikuu', sv: '', en: 'February', ru: '', sk: '', pl: '' },
    'kuukausi-huhti': { fi: 'Huhtikuu', sv: '', en: 'April', ru: '', sk: '', pl: '' },
    'kuukausi-joulu': { fi: 'Joulukuu', sv: '', en: 'December', ru: '', sk: '', pl: '' },
    'kuukausi-kesa': { fi: 'Kesäkuu', sv: '', en: 'June', ru: '', sk: '', pl: '' },
    'kuukausi-loka': { fi: 'Lokakuu', sv: '', en: 'October', ru: '', sk: '', pl: '' },
    'kuukausi-maalis': { fi: 'Maaliskuu', sv: '', en: 'March', ru: '', sk: '', pl: '' },
    'kuukausi-marras': { fi: 'Marraskuu', sv: '', en: 'November', ru: '', sk: '', pl: '' },
    'kuukausi-syys': { fi: 'Syyskuu', sv: '', en: 'September', ru: '', sk: '', pl: '' },
    'kuukausi-tammi': { fi: 'Tammikuu', sv: '', en: 'January', ru: '', sk: '', pl: '' },
    'kuukausi-touko': { fi: 'Toukokuu', sv: '', en: 'May', ru: '', sk: '', pl: '' },
    kayttajatunnus: {
        fi: 'Käyttäjätunnus',
        sv: '',
        en: 'Login username',
        ru: '',
        sk: '',
        pl: '',
    },
    kayttooikeus: { fi: 'Käyttöoikeus', sv: '', en: 'Access right', ru: '', sk: '', pl: '' },
    kylla: { fi: 'Kyllä', se: 'Ja', en: 'Yes', ru: '', sk: '', pl: '' },
    laheta: { fi: 'Lähetä', sv: '', en: 'Send', ru: '', sk: '', pl: '' },
    'laheta-ehdotus': { fi: 'Lähetä ehdotus', sv: '', en: 'Send suggestion', ru: '', sk: '', pl: '' },
    'laheta-vahvistus': { fi: 'Lähetä vahvistus', sv: '', en: 'Send confirmation', ru: '', sk: '', pl: '' },
    'lahetetyt-hotline-viestit': {
        fi: 'Lähetetyt Hotline-viestit',
        sv: '',
        en: 'Send Hotline messages',
        ru: '',
        sk: '',
        pl: '',
    },
    lahettaja: { fi: 'Lähettäjä', sv: '', en: 'Sender', ru: '', sk: '', pl: '' },
    'language-en': { fi: 'EN', sv: 'EN', en: 'EN', ru: '', sk: '', pl: '' },
    'language-fi': { fi: 'FI', sv: 'FI', en: 'FI', ru: '', sk: '', pl: '' },
    'language-fil': { fi: 'FIL', sv: 'FIL', en: 'FIL', ru: '', sk: '', pl: '' },
    'language-pl': { fi: 'PL', sv: 'PL', en: 'PL', ru: '', sk: '', pl: '' },
    'language-ru': { fi: 'RU', sv: 'RU', en: 'RU', ru: '', sk: '', pl: '' },
    'language-sk': { fi: 'SK', sv: 'SK', en: 'SK', ru: '', sk: '', pl: '' },
    'language-sv': { fi: 'SV', sv: 'SV', en: 'SV', ru: '', sk: '', pl: '' },
    laskutusera: { fi: 'Laskutuserä', sv: 'Faktureringsrat', en: 'Billing lot', ru: '', sk: '', pl: '' },
    laskutusviite: { fi: 'Laskutusviite', sv: '', en: 'Sender', ru: '', sk: '', pl: '' },
    laskutusosoite: { fi: 'Laskutusosoite', sv: '', en: 'Billing address', ru: '', sk: '', pl: '' },
    'laskuttavat-yritykset': { fi: 'Laskuttavat yritykset', sv: '', en: 'Invoicing companies', ru: '', sk: '', pl: '' },
    laskutusHinnat: {
        dayCategory: { fi: 'Päiväkategoria', sv: '', en: 'Day category', ru: '', sk: '', pl: '' },
        overridingPayType: {
            fi: 'Yliajava laskutuspalkkalaji',
            sv: '',
            en: 'Overriding pay type',
            ru: '',
            sk: '',
            pl: '',
        },
        startsOnPreviousDay: {
            fi: 'Alkaa edellisenä päivänä',
            sv: '',
            en: 'Starts on previous day',
            ru: '',
            sk: '',
            pl: '',
        },
        endsOnFollowingDay: {
            fi: 'Loppuu seuraavana päivänä',
            sv: '',
            en: 'Ends on following day',
            ru: '',
            sk: '',
            pl: '',
        },
    },
    'lataa-excel': { fi: 'Lataa Excel', sv: '', en: 'Download Excel', ru: '', sk: '', pl: '' },
    'lataa-kaikki': { fi: 'Lataa Kaikki', sv: '', en: 'Download All', ru: '', sk: '', pl: '' },
    'lataa-ohjeet': { fi: 'Ohjeet', sv: '', en: 'Instructions', ru: '', sk: '', pl: '' },
    'lataa-pdf': { fi: 'Lataa PDF', sv: '', en: 'Download PDF', ru: 'Download PDF', sk: '', pl: '' },
    'lataa-tiedosto': { fi: 'Lataa tiedosto', sv: '', en: 'Download file', ru: '', sk: '', pl: '' },
    'lataus-kesken': {
        fi: 'Lataus kesken, odota pieni hetki',
        sv: '',
        en: 'Download not finished, please wait a moment',
        ru: '',
        sk: '',
        pl: '',
    },
    'leave-feedback-action': {
        fi: 'Jätä palautetta',
        sv: 'Lämna feed-back',
        en: 'Leave feedback',
        ru: '',
        sk: '',
        pl: '',
    },
    'legacy.ClientPortal.TilausEhdotus.MassSelectCheckbox.AmountOfEmployeesLimited.Tooltip': {
        fi: 'Voit valita korkeintaan {amount} työntekijää, joille lähetetään ehdotus.',
        sv: '',
        en: 'You may select at most {amount} employees, to which a suggestion is sent.',
        ru: '',
        sk: '',
        pl: '',
    },
    'legacy.ClientPortal.TilausEhdotus.AmountOfEmployeesLimited.PotentialEmployeesNote': {
        fi: 'Huomioithan, että kaikki potentiaaliset työntekijät näkevät työvuoron myös ilman lähetettyä työvuoroehdotusta.',
        sv: '',
        en: 'Please note that all the potential employees can see the shift also without a shift suggestion having been sent.',
        ru: '',
        sk: '',
        pl: '',
    },
    'legacy.ClientPortal.TilausForm.SelectSmsContacts.MainLabel': {
        fi: 'Automaattinen tekstiviesti-ilmoitus tilauksen etenemisestä',
        sv: '',
        en: 'Automatic text message for the order progressing',
        ru: '',
        sk: '',
        pl: '',
    },
    'legacy.ClientPortal.TilausForm.SelectSmsContacts.Dropdown.DefaultText': {
        fi: 'Valitse vastaanottajat',
        sv: '',
        en: 'Select the receivers',
        ru: '',
        sk: '',
        pl: '',
    },
    'legacy.ClientPortal.TilausForm.SelectSmsContacts.Dropdown.textForMultipleSelected': {
        fi: 'käyttäjää',
        sv: '',
        en: 'users',
        ru: '',
        sk: '',
        pl: '',
    },
    'legacy.ClientPortal.TilausForm.AutomaticSmsForOrderProgressing': {
        fi: 'Automaattinen tekstiviesti-ilmoitus tilauksen etenemisestä',
        sv: '',
        en: 'Automatic text message for the order progressing',
        ru: '',
        sk: '',
        pl: '',
    },
    liitetiedosto: { fi: 'Liitetiedosto', sv: 'SV', en: 'SV', ru: '', sk: '', pl: '' },
    lisaa: { fi: 'Lisää', sv: '', en: 'Add', ru: '', sk: '', pl: '' },
    'lisaa-erikoistaito': { fi: 'Lisää erikoistaito', sv: '', en: 'Add skill', ru: '', sk: '', pl: '' },
    'lisaa-filttereita': { fi: 'Lisää suodatuksia', sv: '', en: 'More filters', ru: '', sk: '', pl: '' },
    'lisaa-lisatieto': { fi: 'Lisää lisätieto', sv: '', en: 'Add additional information', ru: '', sk: '', pl: '' },
    'lisaa-lisatieto-vapaaseen': {
        fi: 'Lisää lisätieto vapaaseen',
        sv: '',
        en: 'Add additional information to the event',
        ru: '',
        sk: '',
        pl: '',
    },
    'lisaa-lisatieto-vuoroon': {
        fi: 'Lisää lisätieto vuoroon',
        sv: '',
        en: 'Add additional information to the shift',
        ru: '',
        sk: '',
        pl: '',
    },
    'lisaa-uusi-tilaus': { fi: 'Lisää uusi työvuorotilaus', sv: '', en: 'Add a new order', ru: '', sk: '', pl: '' },
    'lisaa-uusi-vuoro': {
        fi: 'Syötä uusi suoritettu vuoro',
        sv: '',
        en: 'Add a new finished shift',
        ru: '',
        sk: '',
        pl: '',
    },
    lisatieto: { fi: 'Lisätieto', sv: '', en: 'Additional information', ru: '', sk: '', pl: '' },
    'lisatieto-tekijalle': {
        fi: 'Lisätieto tekijälle',
        sv: '',
        en: 'Additional information for the doer',
        ru: '',
        sk: '',
        pl: '',
    },
    'lisatieto-tyontekijalle': {
        fi: 'Lisätieto työntekijälle',
        sv: '',
        en: 'Additional information for the employee',
        ru: '',
        sk: '',
        pl: '',
    },
    lisatietoja: { fi: 'Lisätietoja', sv: '', en: 'Additional information', ru: '', sk: '', pl: '' },
    'list-downloading-data': {
        fi: 'Ladataan sisältöä, pieni hetki...',
        sv: '',
        en: 'Downloading content, please wait a moment..',
        ru: '',
        sk: '',
        pl: '',
    },
    'list-end-reached': {
        fi: 'Yhteensä {amount} riviä ladattu.',
        sv: 'Totalt {amount} rader laddade.',
        en: 'Total {amount} rows loaded.',
        ru: '',
        sk: '',
        pl: '',
    },
    'list-no-data-found': {
        fi: 'Ei löytynyt sisältöä. Kokeile vaihtaa suodatuksia',
        sv: '',
        en: 'No content found. Please try changing the filter values.',
        ru: '',
        sk: '',
        pl: '',
    },
    'loading-content': {
        fi: 'Ladataan sisältöä, pieni hetki...',
        sv: '',
        en: 'Downloading content, please wait a moment..',
        ru: '',
        sk: '',
        pl: '',
    },
    'loading-timeout': {
        fi: 'Tietojen lataaminen näyttäisi kestävän odotettua kauemmin. Voit päivittää selainikkunan tai jatkaa odottamista...',
        sv: '',
        en: 'Downloading the information seems to be lasting longer than expected. You can update the browser tab or continue trying..',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.SignUpForm.TermsAndConditions.AcceptStart': {
        fi: 'Hyväksyn',
        sv: '',
        en: 'I approve',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.SignUpForm.ValidationFail.TermsAndConditions': {
        fi: 'Sinun täytyy hyväksyä käyttöehdot, jotta voit käyttää ohjelmistoa',
        sv: '',
        en: 'You must accept the terms and conditions to be able to use the software',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.SignUpForm.ValidationFail.InvalidFormat': {
        fi: 'Salasana ei täytä vaatimuksia',
        sv: '',
        en: 'Password does not fulfill requirements',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.SignUpForm.ValidationFail.PasswordDoesNotMatch': {
        fi: 'Salasanat eivät täsmää',
        sv: '',
        en: 'Password does not match',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.SignUpForm.TermsAndConditions.AcceptEnd': {
        fi: 'käyttöehdot',
        sv: '',
        en: 'the terms and conditions',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.LoginForm.SwitchToPasswordResetLabel': {
        fi: 'Unohditko salasanasi?',
        sv: '',
        en: 'Forgot your password?',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetRequestForm.SwitchToLoginLabel': {
        fi: 'Palaa etusivulle',
        sv: '',
        en: 'Back to front page',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetRequestForm.SubmitButton.Text': {
        fi: 'Lähetä',
        sv: '',
        en: 'Send',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetRequestForm.Subtitle.Text': {
        fi: 'Salasanan vaihtolinkki lähetetään sinulle sähköpostitse.',
        sv: '',
        en: 'A link to change the password will be sent to you by email.',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetRequestForm.Alert.PasswordResetRequestSent': {
        fi: 'Ohjeet salasanan vaihtoon lähetetty sähköpostiosoitteeseesi.',
        sv: '',
        en: 'Instructions to change your password was sent to your email.',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.LoginPage.LoginAttemptFailed': {
        fi: 'Kirjautuminen ei onnistunut, tarkista tiedot.',
        sv: '',
        en: 'Login failed. Please check the information.',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.LoginPage.LoginTitle': {
        fi: 'Tervetuloa! Kirjaudu sisään jatkaaksesi.',
        sv: '',
        en: 'Welcome! Log in to continue.',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetRequestForm.Title': {
        fi: 'Anna käyttäjätunnukseksi',
        sv: '',
        en: 'Please give your username',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetForm.Title': {
        fi: 'Aseta uusi salasana',
        sv: '',
        en: 'Create a new password',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetForm.SubTitle': {
        fi: 'Salasanan tulee olla 12-32 merkkiä pitkä ja sen täytyy sisältää ainakin yksi numero tai erikoismerkki, sekä vähintään yksi iso kirjain.',
        sv: '',
        en: 'Password must be 12-32 characters long and must contain at least one number or spacial character, and at least one capital letter.',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetFormNoPassword.Title': {
        fi: 'Käyttöehdot',
        sv: '',
        en: 'Terms and conditions',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetRequestForm.Username.Label': {
        fi: 'Käyttäjätunnus',
        sv: '',
        en: 'Username',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetForm.Password1.Label': {
        fi: 'Kirjoita uusi salasana',
        sv: '',
        en: 'Type new password',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetForm.Password2.Label': {
        fi: 'Kirjoita uusi salasana uudestaan',
        sv: '',
        en: 'Type new password again',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetForm.SubmitButton.Label': {
        fi: 'Tallenna',
        sv: '',
        en: 'Save',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetForm.ValidationFail.NotMatching': {
        fi: 'Salasanat eivät täsmää.',
        sv: '',
        en: "The passwords don't match each other.",
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetForm.ValidationFail.GenericError': {
        fi: 'Jotain meni pieleen. Yritä uudestaan.',
        sv: '',
        en: 'Something went wrong. Please try again.',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.PasswordResetForm.ValidationFail.Requirements': {
        fi: 'Salasanassa tulee olla 12-32 merkkiä, vähintään yksi numero tai erikoismerkki sekä sisältää vähintään yksi iso kirjain',
        sv: '',
        en: 'The password must contain 12 to 32 characters, at least one number or special character and include at least one character with capital case.',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.LoginPage.Username.Label': {
        fi: 'Käyttäjätunnus',
        sv: '',
        en: 'Username',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.LoginPage.Password.Label': {
        fi: 'Salasana',
        sv: '',
        en: 'Password',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.LoginPage.SubmitButton.Text': {
        fi: 'Kirjaudu',
        sv: '',
        en: 'Login',
        ru: '',
        sk: '',
        pl: '',
    },
    'login.LoginPage.MicrosoftButton.Text': {
        fi: 'Kirjaudu Microsoft-tunnuksin',
        sv: '',
        en: 'Sign in with Microsoft',
        ru: '',
        sk: '',
        pl: '',
    },
    login: {
        showPassword: {
            fi: 'Näytä salasana',
            sv: '',
            en: 'Show password',
            ru: '',
            sk: '',
            pl: '',
        },
        hidePassword: {
            fi: 'Piilota salasana',
            sv: '',
            en: 'Hide password',
            ru: '',
            sk: '',
            pl: '',
        },
    },
    lopetusaika: { fi: 'Lopetusaika', sv: '', en: 'End time', ru: '', sk: '', pl: '' },
    loppuaika: { fi: 'Loppuaika', sv: '', en: 'End time', ru: '', sk: '', pl: '' },
    Lounas: { fi: 'Lounas', sv: '', en: 'Lunch', ru: '', sk: '', pl: '' },
    lounastauko: { fi: 'Lounastauko', sv: '', en: 'Lunch break', ru: '', sk: '', pl: '' },
    'Lounas.LounasSmallCase': { fi: 'lounas', sv: '', en: 'lunch', ru: '', sk: '', pl: '' },
    'Lounas.LounaatDefinite': { fi: 'lounasta', sv: '', en: 'lunches', ru: '', sk: '', pl: '' },
    'Lounas.LounasSelect.Alkuaika': { fi: 'Alkuaika', sv: '', en: 'Start time', ru: '', sk: '', pl: '' },
    'lounas-ja-perinta': { fi: 'Lounas ja perintä', sv: '', en: 'Lunch and benefit', ru: '', sk: '', pl: '' },
    'luo-uusi-laskuttava-yritys': {
        fi: 'Luo uusi laskuttava yritys',
        sv: '',
        en: 'Create new invoicing company',
        ru: '',
        sk: '',
        pl: '',
    },
    'lukematon-viesti': { fi: 'Lukematon viesti', sv: '', en: 'Unread message', ru: '', sk: '', pl: '' },
    'luo-uusi-yhteyshenkilo': {
        fi: 'Luo uusi yhteyshenkilö',
        sv: '',
        en: 'Add new contact person',
        ru: '',
        sk: '',
        pl: '',
    },
    'lukitse-tilaus': {
        fi: 'Lukitse tilaus (ei näy työntekijöille varattavana vuorona)',
        sv: '',
        en: "Lock the order (won't be shown to employees as a shift they can reserve)",
        ru: '',
        sk: '',
        pl: '',
    },
    'meta-selitys-tilauksesta-muodostui-ylityota': {
        fi: 'Tilausta ei voitu ehdottaa, koska siitä muodostuu ylityötä.',
        sv: '',
        en: "Couldn't suggest the order because there would be overtime.",
        ru: '',
        sk: '',
        pl: '',
    },
    'metaselitys-alitilauksesta-muodostuu-ylityota': {
        fi: 'Pitkää tilausta ei voi ehdottaa, koska jostakin työvuorosta muodostuu ylityötä.',
        sv: '',
        en: "Couldn't suggest the long order because some shift would have overtime.",
        ru: '',
        sk: '',
        pl: '',
    },
    'metaselitys-alitilauksesta-muodostuu-ylityota-voi-ehdottaa': {
        fi: 'Pitkän tilauksen jostakin työvuorosta muodostuu ylityötä.',
        sv: '',
        en: 'Some shift in the long order has overtime',
        ru: '',
        sk: '',
        pl: '',
    },
    'metaselitys-asiakas-ei-saa-ehdottaa-tesn-tilausta': {
        fi: 'Työehtosopimuksen tilausta ei voi ehdottaa asiakkaan extranetistä.',
        sv: '',
        en: "Can't suggest an order for a collective agreement from the client portal",
        ru: '',
        sk: '',
        pl: '',
    },
    'metaselitys-avoimen-pitkatilauksen-alitilaukset-eivat-ole-tulevaisuudessa': {
        fi: 'Pitkää tilausta ei voi ehdottaa, koska sen työvuorot eivät ole tulevaisuudessa.',
        sv: '',
        en: "Can't make suggestions for the long order because some of shifts are not in future.",
        ru: '',
        sk: '',
        pl: '',
    },
    'metaselitys-maara-aikaisen-pitkatilauksen-alitilaukset-eivat-ole-tulevaisuudessa': {
        fi: 'Pitkää tilausta ei voi ehdottaa, koska sen työvuorot eivät ole tulevaisuudessa.',
        sv: '',
        en: "Can't make suggestions for the long order because its shifts are not in future.",
        ru: '',
        sk: '',
        pl: '',
    },
    'metaselitys-tilauksen-alkuaika-on-menneisyydessa': {
        fi: 'Menneisyydessä olevaa tilausta ei voi ehdottaa.',
        sv: '',
        en: "Can't make suggestions for an order in the past.",
        ru: '',
        sk: '',
        pl: '',
    },
    'metaselitys-tilauksesta-muodostuu-ylityota': {
        fi: 'Tilausta, josta muodostuu ylityötä ei voi ehdottaa.',
        sv: '',
        en: "Can't make suggestions for an order having overtime.",
        ru: '',
        sk: '',
        pl: '',
    },
    'metaselitys-tilauksesta-muodostuu-ylityota-voi-ehdottaa': {
        fi: 'Tilauksesta muodostuu ylityötä.',
        sv: '',
        en: 'The order has overtime.',
        ru: '',
        sk: '',
        pl: '',
    },
    'metaselitys-tilaus-on-jo-taytetty': {
        fi: 'Täytettyä tilausta ei voi ehdottaa.',
        sv: '',
        en: "Can't make suggestions for a filled order.",
        ru: '',
        sk: '',
        pl: '',
    },
    'metaselitys-tilausta-ei-ole-julkaistu': {
        fi: 'Julkaisematonta tilausta ei voi ehdottaa.',
        sv: '',
        en: "Can't make suggestions for a non-published order.",
        ru: '',
        sk: '',
        pl: '',
    },
    'min-lyhyt': { fi: 'min', sv: '', en: 'min', ru: '', sk: '', pl: '' },
    'modal-components.ValuePickerModal.FilterLabel': {
        fi: 'Suodatukset',
        sv: '',
        en: 'Filters',
        ru: '',
        sk: '',
        pl: '',
    },
    'modal-components.ValuePickerModal.ConfirmButtonLabel': {
        fi: 'Suodata',
        sv: '',
        en: 'Filter',
        ru: '',
        sk: '',
        pl: '',
    },
    'modal-deletion-confirmation.ConfirmButtonLabel': {
        fi: 'Poista',
        sv: '',
        en: 'Delete',
        ru: '',
        sk: '',
        pl: '',
    },
    'modal-deletion-confirmation.TextField.Label': {
        fi: 'Lisätiedot',
        sv: '',
        en: 'Additional information',
        ru: '',
        sk: '',
        pl: '',
    },
    'modal-deletion-confirmation.DeletionReasons.ReasonsQueryFailed': {
        fi: 'Poistosyiden haku epäonnistui.',
        sv: '',
        en: 'Fetching the removal reasons failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'modal-deletion-confirmation.DeletionReasons.ReasonLabel': {
        fi: 'Poiston syy:',
        sv: '',
        en: 'Reason for deletion:',
        ru: '',
        sk: '',
        pl: '',
    },
    'muokattavat-laskuttavat-yritykset': {
        fi: 'Muokattavat laskuttavat yritykset',
        sv: '',
        en: 'Invoicing companies to be modified',
        ru: '',
        sk: '',
        pl: '',
    },
    Muokkaa: {
        fi: 'Muokkaa',
        sv: '',
        en: 'Edit',
        ru: '',
        sk: '',
        pl: '',
    },
    'muokkaa-suoritettua-vuoroa': {
        fi: 'Muokkaa suoritettua vuoroa',
        sv: '',
        en: 'Modify completed shift',
        ru: '',
        sk: '',
        pl: '',
    },
    muokkaa: { fi: 'Muokkaa', sv: '', en: 'Modify', ru: '', sk: '', pl: '' },
    'laskuttavan-yrityksen-tietojen-muokkaus': {
        fi: 'Laskuttavan yrityksen tietojen muokkaus',
        sv: '',
        en: 'Modify an invoicing company',
        ru: '',
        sk: '',
        pl: '',
    },
    Muutosloki: { fi: 'Muutosloki', sv: '', en: 'Change log', ru: '', sk: '', pl: '' },
    MyyntilaskutuksenTarkistusRaportti: {
        fi: 'Myyntilaskutuksen tarkistus',
        sv: '',
        en: 'Myyntilaskutuksen tarkistus',
        ru: '',
        sk: '',
        pl: '',
    },
    nayta: { fi: 'Näytä', sv: '', en: 'Show', ru: '', sk: '', pl: '' },
    'nayta-lisatiedot': {
        fi: 'Näytä lisätiedot',
        sv: '',
        en: 'Show the additional information',
        ru: '',
        sk: '',
        pl: '',
    },
    'nimi-on-liia-pitka': { fi: 'Nimi on liian pitkä', sv: '', en: 'Name is too long', ru: '', sk: '', pl: '' },
    'no-results-found': { fi: '(ei tuloksia)', sv: '(inga resultater)', en: '(no results)', ru: '', sk: '', pl: '' },
    'nollaa-paivamaaravalinnat': {
        fi: 'Nollaa päivämäärävalinnat',
        sv: ' ',
        en: 'Clear the date selections',
        ru: '',
        sk: '',
        pl: '',
    },
    'non-valid-moment': {
        fi: 'Aika ei oikeassa muodossa',
        sv: '',
        en: 'Time is not in the correct format',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-CrmModuleReloaded': {
        fi: 'Tiedot on päivitetty.',
        sv: 'Information har uppdateras.',
        en: 'Data has been updated.',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-Error': {
        fi: 'Toiminto epäonnistui.',
        sv: 'Misslyckades.',
        en: 'Operation failed.',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-ProgramError': {
        fi: 'Toiminto epäonnistui. Ole hyvä, ja päivitä selainikkuna F5-painikkeella.',
        sv: '',
        en: 'Action failed. Please try to refresh the browser tab by pressing F5.',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-SalasanaVaihdettu': {
        fi: 'Salasana vaihdettu!',
        sv: '',
        en: 'Password changed!',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-SiirtoEpaonnistui': {
        fi: 'Siirto epäonnistui!',
        sv: '',
        en: 'Transfer failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-SiirtoSiirretty': {
        fi: 'Siirto onnistui!',
        sv: 'Succé!',
        en: 'Transfer successful!',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-TagsSaved': { fi: 'Tagit tallennettu!', sv: 'Sparad!', en: 'Tags saved!', ru: '', sk: '', pl: '' },
    'notification-TagTransferAcknowledgements': {
        fi: 'Kuittaukset siirretty!',
        sv: 'Bekräftelse flyttad!',
        en: 'Acknowledgements transferred!',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-TaskMarkedDoneImmediate': {
        fi: 'Tehtävä merkitty tehdyksi!',
        sv: 'Uppgiften markerad färdig!',
        en: 'Task marked done!',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-TicketAcknowledgedImmediate': {
        fi: 'Tiketti kuitattu!',
        sv: 'Bekräftad!',
        en: 'Ticket acknowledged!',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-TicketAllAcknowledged': {
        fi: 'Kaikki tiketit kuitattu!',
        sv: 'Alla bekräftad!',
        en: 'All acknowledged!',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-TicketSaved': {
        fi: 'Tiketti tallennettu!',
        sv: 'Sparad!',
        en: 'Ticket saved!',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-tilaukselle-ei-ehdotettavia-tyontekijoita': {
        fi: 'Tilaukselle ei ollut ehdotettavia työntekijoita!',
        sv: '',
        en: 'No employees to suggest for the order',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-TilausehdotusLahetetty': {
        fi: 'Tilausehdotus lähetetty!',
        sv: '',
        en: 'Suggestion sent',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-TilausehdotuksetLahetetty': {
        fi: 'Tilausehdotukset lähetetty!',
        sv: '',
        en: 'Suggestions sent',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-TilausvahvistusLahetetty': {
        fi: 'Tilausvahvistus lähetetty!',
        sv: '',
        en: 'Confirmation sent',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-VanhatSiirrotPoistettu': {
        fi: 'Vanhat siirrot poistettu!',
        sv: 'Succé!',
        en: 'Old transfers removed!',
        ru: '',
        sk: '',
        pl: '',
    },
    'notification-YhteyskumppaniSaved': {
        fi: 'Yhteyskumppani tallennettu!',
        sv: 'Sparad!',
        en: 'Communication partner saved!',
        ru: '',
        sk: '',
        pl: '',
    },
    'odottaa-tyontekijan-hyväksyntää': {
        fi: 'Odottaa työntekijän hyväksyntää. Ehdotettu {date} klo {time} ({customer}).',
        sv: '',
        en: 'Waiting for the employee approval. Suggested {date} at {time} ({customer}).',
        ru: '',
        sk: '',
        pl: '',
    },
    'OsiokohtaisetAsetukset.asetuksetTallennettu': {
        fi: 'Asetukset tallennettu',
        sv: '',
        en: 'Saved the settings',
        ru: '',
        sk: '',
        pl: '',
    },
    'OsiokohtaisetAsetukset.kumoa': { fi: 'Kumoa', sv: '', en: 'Undo', ru: '', sk: '', pl: '' },
    'OsiokohtaisetAsetukset.muuttuneet.asetus': {
        fi: 'Asetus',
        sv: '',
        en: 'Setting',
        ru: '',
        sk: '',
        pl: '',
    },
    'OsiokohtaisetAsetukset.muuttuneet.vanha': {
        fi: 'Vanha',
        sv: '',
        en: 'Old',
        ru: '',
        sk: '',
        pl: '',
    },
    'OsiokohtaisetAsetukset.muuttuneet.uusi': {
        fi: 'Uusi',
        sv: '',
        en: 'New',
        ru: '',
        sk: '',
        pl: '',
    },
    'OsiokohtaisetAsetukset.ok': { fi: 'OK', sv: '', en: 'OK', ru: '', sk: '', pl: '' },
    'OsiokohtaisetAsetukset.osionNimi': { fi: 'Osio:', sv: '', en: 'Section', ru: '', sk: '', pl: '' },
    'OsiokohtaisetAsetukset.osiotaEiLoytynyt': {
        fi: 'Osiota ei löytynyt',
        sv: '',
        en: 'No section found',
        ru: '',
        sk: '',
        pl: '',
    },
    'OsiokohtaisetAsetukset.otsikko': {
        fi: 'Osiokohtaiset asetukset',
        sv: '',
        en: 'Settings by section',
        ru: '',
        sk: '',
        pl: '',
    },
    'OsiokohtaisetAsetukset.peruuta': { fi: 'Peruuta', sv: '', en: 'Undo', ru: '', sk: '', pl: '' },
    'OsiokohtaisetAsetukset.tallenna': { fi: 'Tallenna', sv: '', en: 'Save', ru: '', sk: '', pl: '' },
    'OsiokohtaisetAsetukset.tallennetaanMuutokset': {
        fi: 'Tallennetaan seuraavat muutokset',
        sv: '',
        en: 'Saving the following changes',
        ru: '',
        sk: '',
        pl: '',
    },
    'OsiokohtaisetAsetukset.virhe.enumillaEiArvoja': {
        fi: 'Asetukselle ei ole määritelty mahdollisia arvoja.',
        sv: '',
        en: 'No values defined for the setting',
        ru: '',
        sk: '',
        pl: '',
    },
    osoite: { fi: 'Osoite', sv: 'Adress', en: 'Address', ru: '', sk: '', pl: '' },
    'paivamaara-lyhyt': { fi: 'Pvm', sv: '', en: 'Date', ru: '', sk: '', pl: '' },
    'pakollisia-kenttia': {
        fi: 'Pakollisia kenttiä puuttuu',
        sv: 'Obligatoriskt fält',
        en: 'Mandatory field',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.AdditionalInfo': {
        fi: 'Lisätiedot',
        sv: '',
        en: 'Additional information',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.DeletionFailed': {
        fi: 'Tapahtuman poisto epäonnistui.',
        sv: '',
        en: 'Removing event succeeded',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.DeletionFailed.Multi': {
        fi: 'Tapahtumien poisto epäonnistui.',
        sv: '',
        en: 'removing events failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.DeletionSucceeded': {
        fi: 'Tapahtuman poisto onnistui.',
        sv: '',
        en: 'Removing event succeeded',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.DeletionSucceeded.Multi': {
        fi: 'Tapahtumien poisto onnistui.',
        sv: '',
        en: 'Removing events succeeded',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.DeleteTapahtuma': {
        fi: 'Poista tapahtuma',
        sv: '',
        en: 'Remove the event',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.Title.DeleteSeveral': {
        fi: 'Poista useita tapahtumia',
        sv: '',
        en: 'Remove multiple events',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.SubmitButtonLabel.DeleteSeveral': {
        fi: 'Poista tapahtumat',
        sv: '',
        en: 'Remove the events',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.MultiDeletionFailed': {
        fi: 'Tapahtumien poisto epäonnistui.',
        sv: '',
        en: 'Removing events failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.MultiDeletionSucceeded': {
        fi: 'Tapahtumien poisto onnistui.',
        sv: '',
        en: 'Removing events succeeded.',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.ReasonLabel': {
        fi: 'Poiston syy:',
        sv: '',
        en: 'Reason for removal:',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.TapahtumasAmount': {
        fi: 'Tapahtumat: {amount} kpl',
        sv: '',
        en: 'Events: {amount}',
        ru: '',
        sk: '',
        pl: '',
    },
    'Palkkahallinto.DeletionModal.Daterange': {
        fi: 'Aikaväli',
        sv: '',
        en: 'Date range',
        ru: '',
        sk: '',
        pl: '',
    },
    'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.siirtamattomat': {
        fi: 'Siirtämättömät',
        sv: '',
        en: 'Non-transferred',
        ru: '',
        sk: '',
        pl: '',
    },
    'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.siirtoonHyvaksytyt': {
        fi: 'Siirtoon hyväksytyt',
        sv: '',
        en: 'Approved for transfer',
        ru: '',
        sk: '',
        pl: '',
    },
    'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.siirretty': {
        fi: 'Siirretty',
        sv: '',
        en: 'Transferred',
        ru: '',
        sk: '',
        pl: '',
    },
    'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.kaikki': {
        fi: 'Kaikki',
        sv: '',
        en: 'All',
        ru: '',
        sk: '',
        pl: '',
    },
    'palkkahallinto.VuorotJaVapaat.HyvaksyntastatusTabs.Label.hyvaksymatta': {
        fi: 'Hyväksymättä',
        sv: '',
        en: 'Unapproved',
        ru: '',
        sk: '',
        pl: '',
    },
    'palkkahallinto.VuorotJaVapaat.HyvaksyntastatusTabs.Label.hyvaksytyt': {
        fi: 'Hyväksytyt',
        sv: '',
        en: 'Approved',
        ru: '',
        sk: '',
        pl: '',
    },
    'palkkahallinto.VuorotJaVapaat.HyvaksyntastatusTabs.Label.kasitellyt': {
        fi: 'Käsitellyt',
        sv: '',
        en: 'Processed',
        ru: '',
        sk: '',
        pl: '',
    },
    'palkkahallinto.VuorotJaVapaat.HyvaksyntastatusTabs.Label.kaikki': {
        fi: 'Kaikki',
        sv: '',
        en: 'All',
        ru: '',
        sk: '',
        pl: '',
    },
    'palkkahallintopage-vuorot-ja-vapaat': { fi: 'Vuorot ja vapaat', sv: '', en: 'Events', ru: '', sk: '', pl: '' },
    'palkkahallintopage-toteuma': { fi: 'Toteuma', sv: '', en: 'Payroll transaction rows', ru: '', sk: '', pl: '' },
    passiivinen: { fi: 'Passiivinen', sv: '', en: 'Passive', ru: '', sk: '', pl: '' },
    'language-change': { fi: 'Valitse kieli', sv: '', en: 'Change language', ru: '', sk: '', pl: '' },
    'language-change.ChangeFailed.Message': {
        fi: 'Kielen vaihto epäonnistui',
        en: 'Failed to change language',
        sv: '',
        ru: '',
        sk: '',
        pl: '',
    },
    'password-change': { fi: 'Salasanan vaihto', sv: '', en: 'Password change', ru: '', sk: '', pl: '' },
    'password-current': { fi: 'Nykyinen salasana', sv: '', en: 'Current password', ru: '', sk: '', pl: '' },
    'password-current-is-incorrect': {
        fi: 'Nykyinen salasana on väärä.',
        sv: '',
        en: 'Current password is incorrect.',
        ru: '',
        sk: '',
        pl: '',
    },
    'password-change.ValidationError.ValueMissing': {
        fi: 'Arvo puuttuu',
        sv: '',
        en: 'Value missing',
        ru: '',
        sk: '',
        pl: '',
    },
    'password-change.ValidationError.NewPasswordsNoMatchRequirements': {
        fi: 'Ei vastaa vaatimuksia',
        sv: '',
        en: "Doesn't match the requirements",
        ru: '',
        sk: '',
        pl: '',
    },
    'password-change.ValidationError.NewPasswordsNoMatch': {
        fi: 'Salasanat eivät täsmää keskenään',
        sv: '',
        en: "Passwords don't match with each other",
        ru: '',
        sk: '',
        pl: '',
    },
    'password-change.ChangeSucceeded.Message': {
        fi: 'Salasanan vaihtaminen onnistui',
        sv: '',
        en: 'Changing password succeeded',
        ru: '',
        sk: '',
        pl: '',
    },
    'password-change.ChangeFailed.Message': {
        fi: 'Salasanan vaihto epäonnistui. Tarkista nykyinen salasana.',
        sv: '',
        en: 'Changing password failed. Please check your current password.',
        ru: '',
        sk: '',
        pl: '',
    },
    'password-format-example': {
        fi: 'Salasanan pitää olla 12–32 merkkiä (vähintään yksi numero tai erikoismerkki ja yksi iso kirjain).',
        sv: '',
        en: 'The password must have 12 to 32 characters (at least one number or special character and one capital letter).',
        ru: '',
        sk: '',
        pl: '',
    },
    'password-new': { fi: 'Uusi salasana', sv: '', en: 'New password', ru: '', sk: '', pl: '' },
    'password-new-again': {
        fi: 'Uusi salasana uudestaan',
        sv: '',
        en: 'Re-enter new password',
        ru: '',
        sk: '',
        pl: '',
    },
    passwordSetSuccess: {
        fi: 'Salasanan luominen onnistui. Lataa seuraavaksi sovellus.',
        sv: '',
        en: 'Password created successfully. You can now download the app.',
        ru: '',
        sk: '',
        pl: '',
    },
    'piilota-filtterit': { fi: 'Piilota suodatukset', en: 'Hide filters', ru: '', sk: '', pl: '' },
    'pitkaa-tilausta-ei-voi-muokata': {
        fi: 'Pitkää tilausta ei voi muokata.',
        sv: ' ',
        en: "Can't modify the long order",
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkaatilausta-ei-voi-ehdottaa': {
        fi: 'Tilausta ei voi ehdottaa.',
        sv: '',
        en: "Order can't be suggested",
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkaatilausta-ei-voi-ehdottaa-tyontekijalle': {
        fi: 'Tilausta ei voi ehdottaa työntekijälle',
        sv: ' ',
        en: "Can't suggest the order for the employee",
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkat-tilaukset': { fi: 'Pitkät tilaukset', sv: ' lyckades', en: 'Successfully deleted', ru: '', sk: '', pl: '' },
    'pitkatilauksella-ei-alitilauksia': {
        fi: 'Pitkällä tilauksella ei työvuoroja',
        sv: '',
        en: 'No shifts for the long order',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-avoin': { fi: 'Avoin', sv: '', en: 'Open', ru: '', sk: '', pl: '' },
    'pitkatilaus-maaraaikainen': { fi: 'Määräaikainen', sv: '', en: 'Fixed-term', ru: '', sk: '', pl: '' },
    'pitkatilaus-alitilaus-maara': { fi: 'Työvuorojen määrä', sv: '', en: 'Amount of shifts', ru: '', sk: '', pl: '' },
    'pitkatilaus-automatik-ehdotus-varmistus': {
        fi: 'Haluatko ehdottaa vuoroa automaattisesti työntekijöille?',
        sv: '',
        en: 'Do you want to automatically suggest the shift for the employees?',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-julkaisu-button': { fi: 'Julkaise', sv: '', en: 'Publish', ru: '', sk: '', pl: '' },
    'pitkatilaus-julkaisu-ehdotus-ala-ehdota': {
        fi: 'Älä ehdota vuoroja',
        sv: '',
        en: "Don't suggest shifts",
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-julkaisu-ehdotus-automatik': {
        fi: 'Ehdota automaattisesti julkaisun yhteydessä',
        sv: '',
        en: 'Suggest automatically along with publishing',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-julkaisu-ehdotus-manual': {
        fi: 'Ehdota manuaalisesti julkaisun jälkeen',
        sv: '',
        en: 'Suggest manually after publishing',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-julkaisu-ehdotus-otsikko': {
        fi: 'Vuorojen ehdottaminen soveltuville työntekijöille:',
        sv: '',
        en: 'Suggesting shifts for capable employees:',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-julkaisu-otsikko': {
        fi: 'Julkaise tilaus varattavaksi',
        sv: '',
        en: 'Publish the order to be reserved',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-julkaisu-vuorot-lukittu': {
        fi: 'Vuorot lukittu (Työntekijät eivät näe vuoroja varattavissa vuoroissa)',
        sv: '',
        en: 'Shifts locked (Employees will not see them in reserved shifts)',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-lisaa-tilauksia': {
        fi: 'Lisää työvuoroja pitkään tilaukseen',
        sv: '',
        en: 'Add orders to the long order',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-lisaa-tyovuorot': { fi: 'Lisää tyovuorot', sv: '', en: 'Add orders', ru: '', sk: '', pl: '' },
    'pitkatilaus-siirry-julkaisuun': {
        fi: 'Siirry julkaisuun',
        sv: '',
        en: 'Move to publishing',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-siirry-tyovuoroihin': {
        fi: 'Siirry lisäämään työvuorot',
        sv: '',
        en: 'Move to add shifts',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-toistuvat-tilaukset': {
        fi: 'Toistuvat tilaukset',
        sv: 'Cykliskla skifter',
        en: 'Recurring orders',
        ru: '',
        sk: '',
        pl: '',
    },
    'pitkatilaus-yksittaiset-tilaukset': {
        fi: 'Yksittäiset tilaukset',
        sv: 'Enskilda skifter',
        en: 'Single orders',
        ru: '',
        sk: '',
        pl: '',
    },
    'poistettua-laskuttavaa-yritysta-ei-voi-muokata': {
        fi: 'Poistettua laskuttavaa yritystä ei voi muokata',
        sv: '',
        en: "Can't modify a removed invoicing company",
        ru: '',
        sk: '',
        pl: '',
    },
    'poisto-onnistui': { fi: 'Poisto onnistui', sv: ' lyckades', en: 'Successfully deleted', ru: '', sk: '', pl: '' },
    poolit: { fi: 'Poolit', sv: 'Pooler', en: 'Pools', ru: '', sk: '', pl: '' },
    Product: { fi: 'Tuote', sv: '', en: '', ru: '', sk: '', pl: '' },
    'Product.ProductsDefinite': { fi: 'tuotetta', sv: '', en: '', ru: '', sk: '', pl: '' },
    'Product.ProductSmallCase': { fi: 'tuote', sv: '', en: '', ru: '', sk: '', pl: '' },
    raportit: { fi: 'Raportit', sv: 'Rapporter', en: 'Reports', ru: '', sk: '', pl: '' },
    'raportit-nayttotapa': { fi: 'Näyttötapa', sv: 'Visning', en: 'Display', ru: '', sk: '', pl: '' },
    'release-notes.ReleaseNotesDialogOpener.HasUnreadMessages': {
        fi: 'Sinulla on lukemattomia julkaisutiedotteita',
        sv: '',
        en: 'You have unread release notes',
        ru: '',
        sk: '',
        pl: '',
    },
    'release-notes.ReleaseNotesDialogOpener.AllMessagesRead': {
        fi: 'Julkaisutiedotteet',
        sv: '',
        en: 'Release notes',
        ru: '',
        sk: '',
        pl: '',
    },
    'read-more-action': { fi: 'Lue lisää', sv: 'Läsa mer', en: 'Read more', ru: '', sk: '', pl: '' },
    'remove-button': { fi: 'Poista', sv: 'Radera', en: 'Remove', ru: '', sk: '', pl: '' },
    Ruokaperinta: { fi: 'Ruokaperintä', sv: '', en: 'Lunch benefit', ru: '', sk: '', pl: '' },
    'Ruokaperinta.RuokaperinnatDefinite': { fi: 'ruokaperintää', sv: '', en: 'lunch benefits', ru: '', sk: '', pl: '' },
    'Ruokaperinta.RuokaperintaSmallCase': { fi: 'ruokaperintä', sv: '', en: 'lunch benefit', ru: '', sk: '', pl: '' },
    'saapuneet-email-viestit': {
        fi: 'Saapuneet Email-viestit',
        sv: '',
        en: 'Received email messages',
        ru: '',
        sk: '',
        pl: '',
    },
    'saapuneet-hotline-viestit': {
        fi: 'Saapuneet Hotline-viestit',
        sv: '',
        en: 'Received Hotline messages',
        ru: '',
        sk: '',
        pl: '',
    },
    saapunut: { fi: 'Saapunut', sv: '', en: 'Received', ru: '', sk: '', pl: '' },
    'sahkopostiosoite-on-liian-pitka': {
        fi: 'Sähköpostiosoite on liian pitkä',
        sv: '',
        en: 'Email is too long',
        ru: '',
        sk: '',
        pl: '',
    },
    'sahkopostiosoitteen-muoto-on-vaara': {
        fi: 'Sähköpostiosoiteen muoto on väärä',
        sv: '',
        en: 'Email is in wrong format',
        ru: '',
        sk: '',
        pl: '',
    },
    'samanniminen-tagi-loytyy-jo': {
        fi: 'Samanniminen tagi löytyy jo',
        sv: '',
        en: 'Tag with the same name exists already',
        ru: '',
        sk: '',
        pl: '',
    },
    'saatava-tilinumero': { fi: 'Saatava tilinumero', sv: '', en: 'Account number', ru: '', sk: '', pl: '' },
    salasana: { fi: 'Salasana', sv: '', en: 'Password', ru: '', sk: '', pl: '' },
    'save-all-button': { fi: 'Tallenna kaikki', sv: 'Spara alla', en: 'Save all', ru: '', sk: '', pl: '' },
    'save-and-create-ticket-button': {
        fi: 'Tallenna tiketiksi',
        sv: 'Spara som ett ticket',
        en: 'Save as ticket',
        ru: '',
        sk: '',
        pl: '',
    },
    'save-and-overwrite-button': {
        fi: 'Tallenna (ylikirjoita)',
        sv: 'Spara (överskriva)',
        en: 'Save (overwrite)',
        ru: '',
        sk: '',
        pl: '',
    },
    'save-button': { fi: 'Tallenna', sv: 'Spara', en: 'Save', ru: '', sk: '', pl: '' },
    'save-button-tooltip': { fi: 'Tallenna', sv: 'Spara', en: 'Save', ru: '', sk: '', pl: '' },
    select: { fi: 'Valitse', sv: 'Välja', en: 'Select', ru: '', sk: '', pl: '' },
    write: { fi: 'Kirjoita', sv: '', en: 'Write', ru: '', sk: '', pl: '' },
    'send-feedback-button': {
        fi: 'Lähetä palaute!',
        sv: 'Skicka feed-back!',
        en: 'Send feedback!',
        ru: '',
        sk: '',
        pl: '',
    },
    'show-less-action': { fi: 'Näytä vähemmän...', sv: 'Visa mindre...', en: 'Show less...', ru: '', sk: '', pl: '' },
    'show-more-action': { fi: 'Näytä lisää...', sv: 'Visa mer...', en: 'Show more...', ru: '', sk: '', pl: '' },
    'sign-out-action': { fi: 'Kirjaudu ulos', sv: 'Logga ut', en: 'Sign out', ru: '', sk: '', pl: '' },
    'sinulla-on-lukemattomia-viesteja': {
        fi: 'Sinulla on lukemattomia viestejä.',
        sv: '',
        en: 'You have unread messages.',
        ru: '',
        sk: '',
        pl: '',
    },
    sisalto: { fi: 'Sisältö', sv: '', en: 'Content', ru: '', sk: '', pl: '' },
    'start-date-short': { fi: 'Alku-pvm', sv: '', en: 'Start date', ru: '', sk: '', pl: '' },
    status: { fi: 'Status', sv: '', en: 'Status', ru: '', sk: '', pl: '' },
    'syotetty-arvo-oli-liian-pitka': {
        fi: 'Syötetty arvo oli liian pitkä',
        sv: '',
        en: 'Value given was too long',
        ru: '',
        sk: '',
        pl: '',
    },
    tagihallinta: { fi: 'Tagihallinta', sv: 'Tagg behärskning', en: 'Tag control', ru: '', sk: '', pl: '' },
    'tagityyppi-Asiakas': { fi: 'Asiakas', sv: 'Kund', en: 'Work unit', ru: '', sk: '', pl: '' },
    'tagityyppi-Pitkatilaus': {
        fi: 'Pitkä tilaus',
        sv: 'Förlängad beställning',
        en: 'Extended order',
        ru: '',
        sk: '',
        pl: '',
    },
    'tagityyppi-Tagi': { fi: 'Tagi', sv: 'Tagg', en: 'Tag', ru: '', sk: '', pl: '' },
    'tagityyppi-Tilaus': { fi: 'Tilaus', sv: 'Beställning', en: 'Order', ru: '', sk: '', pl: '' },
    'tagityyppi-Tyontekija': { fi: 'Työntekijä', sv: 'Anställd', en: 'Employee', ru: '', sk: '', pl: '' },
    'tagityyppi-Yhteyshenkilo': {
        fi: 'Yhteyshenkilö',
        sv: 'Kontaktperson',
        en: 'Contact person',
        ru: '',
        sk: '',
        pl: '',
    },
    'tallenna-uusi-laskuttava-yritys': {
        fi: 'Tallenna uusi laskuttava yritys',
        sv: '',
        en: 'Save new invoicing company',
        ru: '',
        sk: '',
        pl: '',
    },
    'tallennus-onnistui': {
        fi: 'Tallennus onnistui',
        sv: 'Sparningen lyckades',
        en: 'Successfully saved',
        ru: '',
        sk: '',
        pl: '',
    },
    'tapahtuman-lisätiedot': {
        fi: 'Tapahtuman lisätiedot',
        sv: '',
        en: 'Additional information for the event',
        ru: '',
        sk: '',
        pl: '',
    },
    'tapahtumas-query-failed': {
        fi: 'Tapahtumien haku epäonnistui.',
        sv: '',
        en: 'Fetching events failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'payroll-management.ProcessEventToTransactionForm.subTitle.summary': {
        fi: 'Yhteenveto',
        sv: '',
        en: 'Summary',
        ru: '',
        sk: '',
        pl: '',
    },
    'payroll-management.ProcessEventToTransactionForm.submit.successMessage': {
        fi: 'Tapahtumien käsittely onnistui',
        sv: '',
        en: 'Processing events was successful',
        ru: '',
        sk: '',
        pl: '',
    },
    'payroll-management.tapahtuma.ProcessEventToTransactionForm.subTitle.preview': {
        fi: 'Toteumarivien esikatselu',
        sv: '',
        en: 'Preview for the transaction row',
        ru: '',
        sk: '',
        pl: '',
    },
    'payroll-management.ProcessEventToTransactionForm.mainTitle': {
        fi: 'Tapahtumien käsittely & muodostuvien toteumarivien esikatselu',
        sv: '',
        en: 'Processing events & previewing the transactions to be formed',
        ru: '',
        sk: '',
        pl: '',
    },
    'Päätä tapahtumasarja': {
        fi: 'Päätä tapahtumasarja',
        sv: '',
        en: 'End recurring event',
        ru: '',
        sk: '',
        pl: '',
    },
    Sopimukset: {
        fi: 'Sopimukset',
        sv: '',
        en: 'Agreements',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tapahtuma.CreationModal.CreationSucceeded': {
        fi: 'Tapahtumien luonti onnistui',
        sv: '',
        en: 'Creating events succeeded',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tapahtuma.CreationModal.NewTapahtumaText': {
        fi: 'Uusi tapahtuma',
        sv: '',
        en: 'New event',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tapahtuma.EditHeading': {
        fi: 'Muokkaa tapahtumaa',
        sv: '',
        en: 'Modify event',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tapahtuma.EditHeadingMultiple': {
        fi: 'Muokkaa tapahtumaa: {tapahtumaCount} eri tapahtumaa',
        sv: '',
        en: 'Modify event: {tapahtumaCount} different events',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tapahtuma.HyvaksyJaKasittele': {
        fi: 'Hyväksy ja käsittele automaattisesti',
        sv: '',
        en: 'Approve and process automatically',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tapahtuma.Tapahtumatype': {
        fi: 'Tapahtumatyyppi',
        sv: '',
        en: 'Event type',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tapahtuma.TapahtumaType.TapahtumatypeSmallCase': {
        fi: 'tapahtumatyyppi',
        sv: '',
        en: 'event type',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tapahtuma.TapahtumatypesDefinite': {
        fi: 'tapahtumatyyppiä',
        sv: '',
        en: 'event types',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tapahtuma.TapahtumaOver24h': {
        fi: 'Yli 24h tapahtuma',
        sv: '',
        en: 'Over 24h event',
        ru: '',
        sk: '',
        pl: '',
    },
    'tarkastele-hyvaksymattomia': {
        fi: 'Siirry tarkastelemaan hyväksymättömiä vuoroja',
        sv: '',
        en: 'Move to review unapproved shifts',
        ru: '',
        sk: '',
        pl: '',
    },
    'tarkastele-hyvaksyttyja': {
        fi: 'Siirry tarkastelemaan hyväksyttyjä vuoroja',
        sv: '',
        en: 'Move to review approved shifts',
        ru: '',
        sk: '',
        pl: '',
    },
    'tarkemmat-tiedot-vahvistuksesta-puuttuvat': {
        fi: 'Tarkemmat tiedot vahvistuksesta puuttuvat',
        sv: '',
        en: 'Additional information on the confirmation are missing',
        ru: '',
        sk: '',
        pl: '',
    },
    Tarve: { fi: 'Tarve', sv: '', en: 'Demand', ru: '', sk: '', pl: '' },
    'tee-tilaus': { fi: 'Tee tilaus', sv: '', en: 'Create an order', ru: '', sk: '', pl: '' },
    'tee-muut-valinnat-ensin': {
        fi: 'Tee muut valinnat ensin',
        sv: '',
        en: 'Make other selections first',
        ru: '',
        sk: '',
        pl: '',
    },
    tehtava: { fi: 'Tehtävä', sv: '', en: 'Task', ru: '', sk: '', pl: '' },
    'tehtavan-erikoistaidot': { fi: 'Tehtävän erikoistaidot', sv: '', en: 'File not found', ru: '', sk: '', pl: '' },
    Tes: {
        fi: 'Tes',
        sv: '',
        en: 'CLA',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tes.TesSmallCase': {
        fi: 'tes',
        sv: '',
        en: 'cla',
        ru: '',
        sk: '',
        pl: '',
    },
    'tes-definite': { fi: 'TES:a', sv: '', en: 'CLAs', ru: '', sk: '', pl: '' },
    'tiedostoa-ei-loydy': { fi: 'Tiedostoa ei löydy', sv: '', en: 'File not found', ru: '', sk: '', pl: '' },
    'tilauksen-erikoistaidot': {
        fi: 'Tilauksen erikoistaidot',
        sv: '',
        en: 'Skills required for the order',
        ru: '',
        sk: '',
        pl: '',
    },
    'tilauksen-irrottaminen-muuttaa-tilauksen-tes-eika-muuttuvalle-tyoehtosopimukselle-loydy-vastaavuutta': {
        fi: 'Tilauksen irrottaminen muuttaa tilauksen työehtosopimuksen, eikä uudella työehtosopimuksella ole vastaavaa tehtävää.',
        sv: '',
        en: "Detaching the order changes the CLA for the order and the new CLA doesn't have an equivalent task",
        ru: '',
        sk: '',
        pl: '',
    },
    'tilauksen-tyontekijat': {
        fi: 'Tilauksen työntekijät',
        sv: '',
        en: 'Employees of the order',
        ru: '',
        sk: '',
        pl: '',
    },
    tilaukset: { fi: 'Tilaukset', sv: '', en: 'Orders', ru: '', sk: '', pl: '' },
    'tilaukset-paivita-nakyma': { fi: 'Päivitä näkymä', sv: '', en: 'Update the view', ru: '', sk: '', pl: '' },
    TilauksetAsiakkaittain: {
        fi: 'Tilaukset asiakkaittain',
        sv: '',
        en: 'Orders by work units',
        ru: '',
        sk: '',
        pl: '',
    },
    'tilaus-alkaa': { fi: 'Tilaus alkaa', sv: '', en: 'Order starts', ru: '', sk: '', pl: '' },
    'tilaus-id': { fi: 'Tilaus-ID', sv: '', en: 'Order ID', ru: '', sk: '', pl: '' },
    'tilaus-lisatty': { fi: 'Tilaus lisätty', sv: '', en: 'Order added', ru: '', sk: '', pl: '' },
    'tilaus-lukittu': {
        fi: 'Tilaus lukittu: ei näy työntekijöille varattavana vuorona.',
        sv: '',
        en: "Order locked: won't be shown to employees as a shift to order.",
        ru: '',
        sk: '',
        pl: '',
    },
    'tilaus-luotu': { fi: 'Tilaus luotu', sv: '', en: 'Created order', ru: '', sk: '', pl: '' },
    'tilaus-paattyy': { fi: 'Tilaus päättyy', sv: '', en: 'Order ends', ru: '', sk: '', pl: '' },
    'tilaus-poisto-lukitus-teksti': {
        fi: 'Poistetaanko tilauksen lukko? Tämän jälkeen tilaus on näkyvillä ja varattavissa kaikille soveltuville työntekijöille.',
        sv: '',
        en: 'Unlock the order? After this the order is visible and reservable for all capable employees.',
        ru: '',
        sk: '',
        pl: '',
    },
    tilauspaivat: { fi: 'Tilauspäivät', sv: '', en: 'Order days', ru: '', sk: '', pl: '' },
    'tilausta-ei-voi-ehdottaa': {
        fi: 'Tilausta ei voi ehdottaa.',
        sv: '',
        en: "Order can't be suggested",
        ru: '',
        sk: '',
        pl: '',
    },
    'tilausta-ei-voi-ehdottaa-tyontekijalle': {
        fi: 'Tilausta ei voi ehdottaa työntekijälle.',
        sv: '',
        en: "Can't suggest the order for the employee.",
        ru: '',
        sk: '',
        pl: '',
    },
    'tilausta-ei-voi-vahvistaa-tyontekijalle': {
        fi: 'Tilausta ei voi vahvistaa työntekijälle päällekkäisen tiedon tai soveltumattomuuden vuoksi.',
        sv: '',
        en: "Can't confirm the order for the employee due to overlapping order or due to the employee not being capable.",
        ru: '',
        sk: '',
        pl: '',
    },
    'tilaustyyppi-pitkatilaus': { fi: 'Pitkä tilaus', sv: '', en: 'Long order', ru: '', sk: '', pl: '' },
    'tilaustyyppi-tyovuorotilaus': { fi: 'Työvuorotilaus', sv: '', en: 'Order', ru: '', sk: '', pl: '' },
    'toggle-paalla': { fi: 'Päällä', sv: '', en: 'On', ru: '', sk: '', pl: '' },
    'toggle-pois-paalta': { fi: 'Pois päältä', sv: '', en: 'Off', ru: '', sk: '', pl: '' },
    'toimipisteen-yleiskuvaus': {
        fi: 'Toimipisteen yleiskuvaus',
        sv: '',
        en: 'Work unit description',
        ru: '',
        sk: '',
        pl: '',
    },
    toteuma: {
        fi: 'Toteuma',
        sv: '',
        en: 'Transaction',
        ru: '',
        sk: '',
        pl: '',
    },
    'toteuma-fields.Maara.UpdateFailed': {
        fi: 'Määrän automaattinen laskenta epäonnistui',
        sv: '',
        en: 'Automatically calculating the amount failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'toteuma-fields.Hinnoittelu.DisplayToggle.Label': {
        fi: 'Muokkaa',
        sv: '',
        en: 'Modify',
        ru: '',
        sk: '',
        pl: '',
    },
    'toteuma-fields.Hinnoittelu.Maaraytyminen.Label': {
        fi: 'Määräytyminen',
        sv: '',
        en: "How it's formed",
        ru: '',
        sk: '',
        pl: '',
    },
    'toteuma-fields.Hinnoittelu.Tulos.Label': {
        fi: 'Tulos',
        sv: '',
        en: 'Result',
        ru: '',
        sk: '',
        pl: '',
    },
    'toteuma-fields.Hinnoittelu.UpdateFailed': {
        fi: 'Palkan ja laskutuksen automaattinen laskenta epäonnistui',
        sv: '',
        en: 'Automatically calculating salary and invoicing failed',
        ru: '',
        sk: '',
        pl: '',
    },
    toimipistetieto: { fi: 'Toimipistetieto', sv: '', en: 'Work unit information', ru: '', sk: '', pl: '' },
    ToteumaRaportti: { fi: 'Toteuma', sv: '', en: 'Transactions', ru: '', sk: '', pl: '' },
    'toteutunut-poissaolo': { fi: 'Toteutunut poissaolo', sv: '', en: 'Actualized absense', ru: '', sk: '', pl: '' },
    'toteutunut-vuoro': { fi: 'Toteutunut vuoro', sv: '', en: 'Actualized shift', ru: '', sk: '', pl: '' },
    'transfer-acknowledgements-button': {
        fi: 'Siirrä kuittaukset...',
        sv: 'Flytta bekräftelse...',
        en: 'Transfer acknowledgements...',
        ru: '',
        sk: '',
        pl: '',
    },
    'tt-hyvaksynta': { fi: 'TT-hyväksyntä', sv: '', en: 'Employee approval', ru: '', sk: '', pl: '' },
    'tt-lisatieto': { fi: 'TT-lisätieto', sv: '', en: 'Employee additional information', ru: '', sk: '', pl: '' },
    tunniste: { fi: 'Tunniste', sv: '', en: 'Code', ru: '', sk: '', pl: '' },
    tuntematon: { fi: '(tuntematon)', sv: '(obekänt)', en: '(unknown))', ru: '', sk: '', pl: '' },
    tuntipalkkaYliajava: { fi: 'Yliajava tuntipalkka', sv: '', en: 'Overriding hourly wage', ru: '', sk: '', pl: '' },
    tyontekija: { fi: 'Työntekijä', sv: 'Anställd', en: 'Employee', ru: '', sk: '', pl: '' },
    siirretyt: { fi: 'Siirretyt', sv: '', en: 'Processed', ru: '', sk: '', pl: '' },
    'siirretyt-vuorot': { fi: 'Siirretyt vuorot', sv: '', en: 'Processed shifts', ru: '', sk: '', pl: '' },
    'kasitellyt-vapaat': { fi: 'Käsitellyt vapaat', sv: '', en: 'Processed absenses', ru: '', sk: '', pl: '' },
    'Tyontekija.TyontekijaSmallCase': { fi: 'työntekijä', sv: '', en: 'employee', ru: '', sk: '', pl: '' },
    'tyhjenna-valinnat': { fi: 'Tyhjennä valinnat', sv: '', en: 'Clear selections', ru: '', sk: '', pl: '' },
    'tyontekija-ei-hyvaksynyt': {
        fi: 'Työntekijä ei hyväksynyt',
        sv: '',
        en: 'Not approved by the employee',
        ru: '',
        sk: '',
        pl: '',
    },
    'tyontekija-hyvaksynyt': { fi: 'Työntekijä hyväksynyt', sv: '', en: 'Employee accepted', ru: '', sk: '', pl: '' },
    'tyontekija-hyvaksynyt-ehdotuksen': {
        fi: 'Työntekijä hyväksynyt ehdotuksen {date} kello {time}.',
        sv: '',
        en: 'Employee approved the proposal {date} at {time}.atv',
        ru: '',
        sk: '',
        pl: '',
    },
    'tyontekija-hyvaksynyt-vuoron': {
        fi: 'Työntekijä hyväksynyt vuoron',
        sv: '',
        en: 'Employee has approved the shift',
        ru: '',
        sk: '',
        pl: '',
    },
    'tyontekija-id': { fi: 'Työntekijä-ID', sv: '', en: 'Employee ID', ru: '', sk: '', pl: '' },
    'tyontekija-kieltaytynyt-vuorosta': {
        fi: 'Työntekijä kieltäytynyt vuorosta',
        sv: '',
        en: 'Employee has rejected the shift',
        ru: '',
        sk: '',
        pl: '',
    },
    'tyontekija-kommentti': { fi: 'Työntekijän kommentti', sv: '', en: 'Employee comment', ru: '', sk: '', pl: '' },
    'tyontekija-yhteydenottotapa': { fi: 'Yhteydenottotapa', sv: '', en: 'Way to contact', ru: '', sk: '', pl: '' },
    'tyontekija-yhteydenottotapa-email': { fi: 'Sähköposti', sv: 'E-post', en: 'Email', ru: '', sk: '', pl: '' },
    'tyontekija-yhteydenottotapa-hotline': {
        fi: 'Hotline-viesti',
        sv: '',
        en: 'Hotline message',
        ru: '',
        sk: '',
        pl: '',
    },
    'tyontekija-yhteydenottotapa-puhelin': { fi: 'Puhelin', sv: 'Telefon', en: 'Phone', ru: '', sk: '', pl: '' },
    'tyontekija-yhteydenottotapa-pushviesti': {
        fi: 'Push-viesti',
        sv: '',
        en: 'Push notification',
        ru: '',
        sk: '',
        pl: '',
    },
    'tyontekija-yhteydenottotapa-sms': { fi: 'SMS-viesti', sv: '', en: 'SMS message', ru: '', sk: '', pl: '' },
    tyontekijaa: { fi: 'työntekijää', sv: '', en: 'employees', ru: '', sk: '', pl: '' },
    'tyontekijoille-lahetettava-sms-viesti': {
        fi: 'Työntekijöille lähetettävä SMS-viesti',
        sv: '',
        en: 'SMS message to be sent for employees',
        ru: '',
        sk: '',
        pl: '',
    },
    TyontekijoittainRaportti: { fi: 'Tyontekijöittäin', sv: '', en: 'By employees', ru: '', sk: '', pl: '' },
    tyovuoro: { fi: 'Työvuoro', sv: '', en: 'Shift', ru: '', sk: '', pl: '' },
    'tyovuoro-tilaukset': { fi: 'Työvuorotilaukset', sv: '', en: 'Orders', ru: '', sk: '', pl: '' },
    tyovuoroehdotus: { fi: 'Työvuoroehdotus', sv: '', en: 'Order suggestion', ru: '', sk: '', pl: '' },
    Tyovuorolista: { fi: 'Työvuorolista', sv: '', en: 'Shift list', ru: '', sk: '', pl: '' },
    'Tyovuorolistapaketti.createPackage': { fi: 'Luo paketti', sv: '', en: 'Create package', ru: '', sk: '', pl: '' },
    'Tyovuorolistapaketti.download': { fi: 'Lataa', sv: '', en: 'Download', ru: '', sk: '', pl: '' },
    'Tyovuorolistapaketti.help': {
        fi: 'Valitse vähintään yksi alue ja haluamasi aikaväli. Luo paketti -painike käynnistää prosessin, joka voi kestää kauankin riippuen asiakasmäärästä. Paketti sisältää työvuorolistat kaikille alueen asiakkaille, joilla on ollut tapahtumia kyseisellä aikavälillä.',
        sv: '',
        en: 'Select at least one domain and the time range you want. The Create package button begins the process that might last for a while depending on the amount of the work units. The package contains shift lists for all the work units of the domain, that have had any events in the time range specified.',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tyovuorolistapaketti.name': { fi: 'Aikaväli', sv: '', en: 'Time range', ru: '', sk: '', pl: '' },
    'Tyovuorolistapaketti.processing': { fi: 'Luodaan...', sv: '', en: 'Processing...', ru: '', sk: '', pl: '' },
    'Tyovuorolistapaketti.refresh': { fi: 'Päivitä', sv: '', en: 'Refresh', ru: '', sk: '', pl: '' },
    'Tyovuorolistapaketti.title': {
        fi: 'Työvuorolistapaketit',
        sv: '',
        en: 'Shift list packages',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tyovuorolistapaketti.toggleOn': {
        fi: 'Vapaat mukana',
        sv: '',
        en: 'Include non-shift events',
        ru: '',
        sk: '',
        pl: '',
    },
    'Tyovuorolistapaketti.toggleOff': {
        fi: 'Ei vapaita',
        sv: '',
        en: 'Exclude non-shift events',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.SwapEvents.Failed': {
        fi: 'Työntekijöiden vaihtaminen tapahtumille epäonnistui.',
        sv: '',
        en: 'Swapping the events for the employees failed.',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.ActionBar.DateRange.DefaultLabel': {
        fi: 'Päivämäärä',
        sv: '',
        en: 'Date range',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.ActionBar.NodeActionsButton.Label': {
        fi: 'Toiminnot',
        sv: '',
        en: 'Actions',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.ActionBar.Filters.ResetButton': {
        fi: 'Nollaa',
        sv: '',
        en: 'Reset',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.ActionBar.Filters.RestoreButton': {
        fi: 'Palauta',
        sv: '',
        en: 'Restore',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.ActionBar.Filters.MoreFiltersButton': {
        fi: 'Suodatukset',
        sv: '',
        en: 'Filters',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.ActionBar.NodeActionsButton.Actions.Tooltip.NoItemsSelected': {
        fi: 'Vähintään yhden tapahtuman pitää olla valittuna jommasta kummasta kalenterista että näitä toimintoja voi käyttää.',
        sv: '',
        en: 'At least one event has to be selected in either of the calendars to be able to use these functions.',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.ActionBar.NodeActionsButton.Actions.Tooltip.EmployeeCalendarSpecificAction': {
        fi: 'Toiminto liittyy työntekijäkalenteriin.',
        sv: '',
        en: 'This action is related to the employee calendar.',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.ActionBar.NodeActionsButton.Actions.Tooltip.DemandCalendarSpecificAction': {
        fi: 'Toiminto liittyy työvuorot-kalenteriin',
        sv: '',
        en: 'This action is related to the upper calendar.',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.ActionBar.DatePicker.Title': {
        fi: 'Tarkastele ajanjaksolla:',
        sv: '',
        en: 'Display by date range:',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.SearchEventType.NonAssignedOnly.DisplaySearchText': {
        fi: 'Näytä vain täyttämättömät tapahtumat',
        sv: '',
        en: 'Show only non-assigned events',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.SearchEventType.Stacked.DisplaySearchText': {
        fi: 'Näytä tapahtumat koostettuna',
        sv: '',
        en: 'Show events stacked',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.SearchEventType.NoAutomaticTransfers.DisplaySearchText': {
        fi: 'Piilota siirtymät',
        sv: '',
        en: 'Hide transfers',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.DisplayMenu.HideGroupsWithNoEvents.Label': {
        fi: 'Piilota tapahtumattomat ryhmät',
        sv: '',
        en: 'Hide groups with no events',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.SearchEventGroupType.Label': {
        fi: 'Näytä ryhmiteltynä ammattinimikkeittäin',
        sv: '',
        en: 'Display grouped by job titles',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.AddEmployee.Failed': {
        fi: 'Työntekijän lisääminen epäonnistui',
        sv: '',
        en: 'Adding employee failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.AddEmployee': {
        fi: 'Lisää työntekijä',
        sv: '',
        en: 'Add employee',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.AddEmployee.ModalTitle': {
        fi: 'Soveltuvan työntekijän haku',
        sv: '',
        en: 'Selecting an applicable employee',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.TimePeriod': {
        fi: 'Aikaväli',
        sv: '',
        en: 'Time range',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.Employees': {
        fi: 'Työntekijät',
        sv: '',
        en: 'Employees',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.EventTypes': {
        fi: 'Tapahtumatyypit',
        sv: '',
        en: 'Event types',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.WorkUnits': {
        fi: 'Asiakkaat',
        sv: '',
        en: 'Work units',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.EventsToPublish': {
        fi: 'Julkaistavat tapahtumat',
        sv: '',
        en: 'Events to be published',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.TotalEvents': {
        fi: 'Tapahtumia yhteensä',
        sv: '',
        en: 'Events in total',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Publish.Confirmation.AggregatedData.Units.pieces': {
        fi: 'kpl',
        sv: '',
        en: 'pcs',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.RefreshCalendarsData': {
        fi: 'Päivitä kalenterit',
        sv: '',
        en: 'Refresh the calendar data',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PlanningPeriodButton.OpenModalLabel': {
        fi: 'Avaa',
        sv: '',
        en: 'Open ',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PlanningPeriodButton.ModifyLabel': {
        fi: 'Muokkaa',
        sv: '',
        en: 'Modify',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PlanningPeriodButton.DefaultLabel': {
        fi: 'Työvuorolista',
        sv: '',
        en: 'Select the roster',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PlanningPeriodButton.CreateNewLabel': {
        fi: 'Luo uusi',
        sv: '',
        en: 'Create new',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PlanningPeriodButton.CreateNewFromViewLabel': {
        fi: 'Luo uusi näkymästä',
        sv: '',
        en: 'Create new from the view',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PlanningPeriodButton.RecentPlanningPeriodsLabel': {
        fi: 'Viimeisimmät',
        sv: '',
        en: 'Recent',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.CreateNewEvents': {
        fi: 'Luo uusi',
        sv: '',
        en: 'Add new',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Filter': {
        fi: 'Suodata',
        sv: '',
        en: 'Filter',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PublishEvents.ConfirmationDialog.publishNotification': {
        fi: 'Julkaistaan vain julkaisemattomat tapahtumat valituista tapahtumista',
        sv: '',
        en: 'Publishing only the events that are not yet published from the selected events',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PublishPlanning.ConfirmationDialog.noPublishableEvents': {
        fi: 'Ei yhtään julkaisematonta tapahtumaa',
        sv: '',
        en: 'No non-published events to be published',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PublishPlanningPeriod.ConfirmationDialog.publishNotification': {
        fi: 'Julkaistaan vain listan julkaisemattomat tapahtumat',
        sv: '',
        en: 'Publishing only the events that are not yet published from the roster',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PublishEvents.ConfirmationDialog.title': {
        fi: 'Vuorojen julkaisu',
        sv: '',
        en: 'Publishing shifts',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PublishPlanningPeriod.ConfirmationDialog.title': {
        fi: 'Vuorolistan julkaisu',
        sv: '',
        en: 'Publishing roster',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PublishEvents': {
        fi: 'Julkaise',
        sv: '',
        en: 'Publish',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PublishEventsFailed': {
        fi: 'Julkaiseminen epäonnistui',
        sv: '',
        en: 'Publish failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Optimize': {
        fi: 'Optimoi',
        sv: '',
        en: 'Optimize',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Optimize-Failed': {
        fi: 'Optimointi epäonnistui',
        sv: '',
        en: 'Optimization failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Optimize-Succeeded': {
        fi: 'Optimointi onnistui',
        sv: '',
        en: 'Optimization was successful',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.EditEvent': {
        fi: 'Muokkaa',
        sv: '',
        en: 'Modify',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.CopyEvent': {
        fi: 'Kopioi tarve',
        sv: '',
        en: 'Copy demand',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.SwapEvent': {
        fi: 'Vaihda',
        sv: '',
        en: 'Swap events',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.CreateEventTemplateGroup': {
        fi: 'Luo profiili',
        sv: '',
        en: 'Create profile',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.CreateEventsFromEventTemplateGroup': {
        fi: 'Luo profiilista',
        sv: '',
        en: 'Create from profile',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PublishingSucceeded': {
        fi: 'Julkaisu onnistui',
        sv: '',
        en: 'Publishing succeeded',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PublishingFailed': {
        fi: 'Julkaisu epäonnistui',
        sv: '',
        en: 'Publishing failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.Publish': {
        fi: 'Julkaise työvuorolista',
        sv: '',
        en: 'Publish roster',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.RemoveEvents': {
        fi: 'Poista',
        sv: '',
        en: 'Remove',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.TransferEvents': {
        fi: 'Siirtymät',
        sv: '',
        en: 'Transfers',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.TransferEvents.ConfirmToEvents': {
        fi: 'Luo siirtymät tapahtumista',
        sv: '',
        en: 'Create events from the transfers',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.TransferEvents.Display': {
        fi: 'Näytä siirtymät kalenterissa',
        sv: '',
        en: 'Display transfers in the calendar',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.TransferEvents.Message.ContainedOnlyNonTransferNodes': {
        fi: 'Valituissa tapahtumissa ei ollut yhtään siirtymää',
        sv: '',
        en: 'There were no transfers in the selected events',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.TransferEvents.Message.ContainedSomeNonTransferNodes': {
        fi: 'Valituissa tapahtumissa oli myös muita kuin siirtymiä. Käsitellään vain siirtymät.',
        sv: '',
        en: 'There were also non-transfers in the selected items. Only the transfers will be processed.',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.TransferEvents.Message.OperationFailed': {
        fi: 'Siirtymien vahvistaminen tapahtumiksi epäonnistui.',
        sv: '',
        en: 'Confirming transfers to events failed.',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.RemoveEvents.Failed': {
        fi: 'Tapahtumien poisto epäonnistui',
        sv: '',
        en: 'Removing events failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.RemoveEmployee': {
        fi: 'Poista työntekijä',
        sv: '',
        en: 'Remove employee',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.RemoveEmployee.Failed': {
        fi: 'Työntekijän poistaminen epäonnistui',
        sv: '',
        en: 'Removing employee failed',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.SuitableEvents.ModalTitle': {
        fi: 'Soveltuvien vuorojen haku',
        sv: '',
        en: 'Search for applicable shifts',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.SuitableEvents.SaveButton.Label': {
        fi: 'Osoita',
        sv: '',
        en: 'Assign',
        ru: '',
        sk: '',
        pl: '',
    },
    Staffing: {
        autoRefresh: {
            fi: 'Päivitä automaattisesti',
            sv: '',
            en: 'Refresh automatically',
            ru: '',
            sk: '',
            pl: '',
        },
        hideFilters: {
            fi: 'Piilota suodattimet',
            sv: '',
            en: 'Hide filters',
            ru: '',
            sk: '',
            pl: '',
        },
        showFilters: {
            fi: 'Näytä suodattimet',
            sv: '',
            en: 'Show filters',
            ru: '',
            sk: '',
            pl: '',
        },
    },
    'Staffing.SuitableEmployees.SaveButton.RequestResponse': {
        fi: 'Pyydä vastausta',
        sv: '',
        en: 'Request response',
        ru: '',
        sk: '',
        pl: '',
    },
    'Staffing.SuitableEmployees.SaveButton.SetReservable': {
        fi: 'Aseta varattavaksi',
        sv: '',
        en: 'Set reservable',
        ru: '',
        sk: '',
        pl: '',
    },
    'Staffing.SuitableEmployees.SaveButton.SetRequestable': {
        fi: 'Aseta ehdotettavaksi',
        sv: '',
        en: 'Set requestable',
        ru: '',
        sk: '',
        pl: '',
    },
    'Staffing.SuitableEmployees..Label': {
        fi: 'Tarjoa',
        sv: '',
        en: 'Assign',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.SuitableEvents.Tooltip': {
        fi: 'Hae soveltuvia vuoroja...',
        sv: '',
        en: 'Search for applicable shifts',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PlanningPeriodListModal.Title': {
        fi: 'Työvuorolistat',
        sv: '',
        en: 'Rosters',
        ru: '',
        sk: '',
        pl: '',
    },
    'workforce-scheduling.PlanningPeriodFetchFailed': {
        fi: 'Työvuorolistoja ei voitu hakea',
        sv: '',
        en: "Couldn't fetch the rosters",
        ru: '',
        sk: '',
        pl: '',
    },
    tyovuorot: { fi: 'Työvuorot', sv: '', en: 'Shifts', ru: '', sk: '', pl: '' },
    tyyppi: { fi: 'Tyyppi', sv: '', en: 'Type', ru: '', sk: '', pl: '' },
    ulkoinentunnus: { fi: 'Ulkoinentunnus', sv: '', en: 'External code', ru: '', sk: '', pl: '' },
    UPDATE: { fi: 'Päivitys', sv: '', en: 'Update', ru: '', sk: '', pl: '' },
    'uusi-viesti': { fi: 'Uusi viesti', sv: '', en: 'New message', ru: '', sk: '', pl: '' },
    'uuden-laskuttavan-yrityksen-luonti': {
        fi: 'Uuden laskuttavan yrityksen luonti',
        sv: '',
        en: 'Creating a new invoicing company',
        ru: '',
        sk: '',
        pl: '',
    },
    'vain-taytetyt': { fi: 'Vain täytetyt', sv: '', en: 'Only filled ones', ru: '', sk: '', pl: '' },
    'vain-vajaa': { fi: 'Vain vajaat', sv: '', en: 'Only non-filled ones', ru: '', sk: '', pl: '' },
    'validation-alku-ja-loppuajat-samat': {
        fi: 'Alku- ja loppuajat eivät saa olla samat.',
        sv: '',
        en: 'Start and end time can not be same',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-alkuaika-on-menneisyydessa': {
        fi: 'Alkuaika ei voi olla menneisyydessä.',
        sv: '',
        en: "Start time can't be in the past",
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-generic-error': { fi: 'Virheellinen kenttä', sv: '', en: 'Invalid value', ru: '', sk: '', pl: '' },
    'validation-has-errors': {
        fi: 'Korjaa huutomerkillä merkityt kentät.',
        sv: 'Fixa fält markerade med ett utrops tecken.',
        en: 'Review fields marked with an exclamation sign.',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-has-warnings': {
        fi: 'Syöttämiisi tietoihin liittyy huomautuksia.',
        sv: 'Meddelanden finns för given data.',
        en: 'Notices exist for given data.',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-invalid-array': {
        fi: 'Virheellinen tai tyhjä taulukko',
        sv: '',
        en: 'Invalid or empty array',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-invalid-integer': { fi: 'Numeerinen arvo.', sv: '', en: 'Numeric value.', ru: '', sk: '', pl: '' },
    'validation-invalid-international-phone-number': {
        fi: 'Anna puhelinnumero muodossa +358400123456. Puhelinnumeron tulee sisältää vähintään 8 numeroa.',
        sv: '',
        en: 'Give the phone number in the +358400123456 format. The phone number must contain at least eight numbers.',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-liian-pitka-teksti': {
        fi: 'Teksti ylittää sallitun pituusrajan.',
        sv: '',
        en: 'The text exceeds the allowed length',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-kokonaisluku-lista-virheellinen-arvo': {
        fi: 'Anna lista pilkulla (,) eroteltuja kokonaislukuja.',
        sv: '',
        en: 'Give a list of whole numbers with a comma (,) as the separator',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-loppuaika-alkuaikaa-myohempi': {
        fi: 'Loppuajan täytyy olla myöhemmin kuin alkuajan',
        sv: '',
        en: 'The end time must be after the start time',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-lounas-ei-sisally-vuoroon': {
        fi: 'Lounastauko ei sisälly vuoroon.',
        sv: '',
        en: 'Lunch break is not during the shift',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-mandatory': {
        fi: 'Pakollinen tieto puuttuu.',
        sv: 'Obligatoriskt fält.',
        en: 'Mandatory field.',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-new-password-not-the-same': {
        fi: 'Uusi salasana- ja Uusi salasana uudestaan- teksti eivät ole yhdenmukaiset.',
        sv: '',
        en: '"New password" and "New password again" fields do not match each other',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-new-password-not-valid': {
        fi: 'Salasana virheellisessä muodossa!',
        sv: '',
        en: 'Password in the wrong format!',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-pitkatilaus-pvm-not-in-the-between': {
        fi: 'Työvuoron pitää sisältyä pitkän tilauksen aikavälille.',
        sv: '',
        en: "Shift must be within the long order's range",
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-pvm-tyhja': { fi: 'Päivämäärää ei valittu!', sv: '', en: 'Date not selected!', ru: '', sk: '', pl: '' },
    'validation-pvm-virheellinen': {
        fi: 'Virheellinen päivämäärä!',
        sv: '',
        en: 'Invalid date!',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-sahkopostiosoite-sisaltaa-aakkosia': {
        fi: 'Sähköpostiosoite sisältää ääkkösiä',
        sv: '',
        en: 'Email contains scandinavian letters',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-sahkopostiosoitteen-muoto-vaara': {
        fi: 'Sähköpostiosoitteen muoto on väärä',
        sv: '',
        en: 'The email is in invalid format',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-sama-tunnus': {
        fi: 'Syötetty käyttäjänimi on jo varattu.',
        sv: '',
        en: 'Given username is already taken',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-tavoite-pvm-not-in-the-past': {
        fi: 'Tavoitepäivämäärä ei voi olla menneisyydessä.',
        sv: 'Måldatum måste vara i framtiden.',
        en: 'Target date cannot be in the past.',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-tiedostomuoto': {
        fi: 'Virheellinen tiedostomuoto.',
        sv: '',
        en: 'Invalid file format',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-tiedoston-koko': { fi: 'Liian iso tiedosto.', sv: '', en: 'File too large', ru: '', sk: '', pl: '' },
    'validation-tiketti-tagit-warning': {
        fi: 'Tiketille ei ole lisätty yhtään tagia, minkä vuoksi tiketti saatetaan merkitä valmiiksi heti tallennuksen jälkeen.',
        sv: 'Inga taggar har läggs till. Tikettin kan bli färdigmarkerad efter sparing.',
        en: 'No tags have been added. Ticket might be marked ready immediately after save.',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-tiketti-tehtavat-warning': {
        fi: 'Tiketille ei ole lisätty yhtään tehtävää, minkä vuoksi tiketti saatetaan merkitä valmiiksi heti tallennuksen jälkeen.',
        sv: 'Inga uppgifter har läggs till. Tikettin kan bli färdigmarkerad efter sparing.',
        en: 'No tasks have been added. Ticket might be marked ready immediately after save.',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-tilaus-ei-saa-olla-menneisyydessa': {
        fi: 'Tilausta ei voi tehdä menneisyyteen',
        sv: '',
        en: "Can't make the order to past",
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-tilaus-tarvitsee-alun-ja-lopun': {
        fi: 'Tilaus tarvitsee alku- ja loppupäivän',
        sv: '',
        en: 'Order needs a start and end date',
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-valitse-alue': { fi: 'Valitse alue', sv: '', en: 'Select domain', ru: '', sk: '', pl: '' },
    'validation-vuoro-ei-saa-olla-tulevaisuudessa': {
        fi: 'Vuoroa ei voi syöttää tulevaisuuteen',
        sv: '',
        en: "Order can't be created to future",
        ru: '',
        sk: '',
        pl: '',
    },
    'validation-yksi-asiakas': {
        fi: 'Yhteyshenkilöllä oltava vähintään yksi yksikkö',
        sv: '',
        en: 'Contact person must have at least one work unit',
        ru: '',
        sk: '',
        pl: '',
    },
    valitse: { fi: 'Valitse', sv: '', en: 'Select', ru: '', sk: '', pl: '' },
    'valitse-asiakas': { fi: 'Valitse asiakas', sv: '', en: 'Select work unit', ru: '', sk: '', pl: '' },
    'valitse-erikoistaito': { fi: 'Valitse erikoistaito', sv: '', en: 'Select skill', ru: '', sk: '', pl: '' },
    'valitse-kaikki': { fi: 'Valitse kaikki', sv: 'Välja alla', en: 'Select all', ru: '', sk: '', pl: '' },
    'valitse-pvm': { fi: 'Valitse päivämäärä', sv: '', en: 'Select date', ru: '', sk: '', pl: '' },
    'valitse-raportti': { fi: 'Valitse raportti', sv: '', en: 'Select report', ru: '', sk: '', pl: '' },
    'valitse-tehtava': { fi: 'Valitse tehtävä', sv: '', en: 'Select task', ru: '', sk: '', pl: '' },
    'valitse-tilauksen-kesto': {
        fi: 'Valitse tilauksen kesto',
        sv: '',
        en: 'Select duration for the order',
        ru: '',
        sk: '',
        pl: '',
    },
    'valitse-tilauksen-syy': {
        fi: 'Valitse tilauksen syy',
        sv: '',
        en: 'Select reason for the order',
        ru: '',
        sk: '',
        pl: '',
    },
    'valitse-tyontekija': { fi: 'Valitse työntekijä', sv: '', en: 'Select employee', ru: '', sk: '', pl: '' },
    'valitse-vain-yksi': { fi: 'valitse vain yksi', sv: '', en: 'Select only one', ru: '', sk: '', pl: '' },
    'valitse-vuoron-kesto': {
        fi: 'Valitse vuoron kesto',
        sv: '',
        en: 'Select the duration for the shift',
        ru: '',
        sk: '',
        pl: '',
    },
    'valitse-vuorotyypit': { fi: 'Valitse vuorotyypit', sv: '', en: 'Select the shift types', ru: '', sk: '', pl: '' },
    'valitse-vuosi': { fi: 'Valitse vuosi', sv: '', en: 'Select year', ru: '', sk: '', pl: '' },
    'valittua-text': { fi: 'valittua', sv: '', en: 'selected', ru: '', sk: '', pl: '' },
    'valitse-poolit-joiden-tyontekijat-naytetaan': {
        fi: 'Valitse poolit joiden työntekijät näytetään',
        sv: '',
        en: 'Filter by pools',
        ru: '',
        sk: '',
        pl: '',
    },
    'value-picker.ValuePickerGroup.filtersText': {
        fi: 'Suodatukset',
        sv: '',
        en: 'Filters',
        ru: '',
        sk: '',
        pl: '',
    },
    'value-picker.ValuePickerGroup.lessFiltersText': {
        fi: 'Vähemmän suodatuksia',
        sv: '',
        en: 'Less filters',
        ru: '',
        sk: '',
        pl: '',
    },
    'value-picker.ValuePickerGroup.moreFiltersText': {
        fi: 'Lisää suodatuksia',
        sv: '',
        en: 'More filters',
        ru: '',
        sk: '',
        pl: '',
    },
    vapaa: { fi: 'Vapaa', sv: '', en: 'Vapaa (???)', ru: '', sk: '', pl: '' },
    'vapaan-hyvaksyminen-epaonnistui-on-jo-hyvaksytty': {
        fi: 'Vapaa on jo hyväksytty, päivitä näkymä.',
        sv: '',
        en: 'The event has already been approved. Please refresh the view.',
        ru: '',
        sk: '',
        pl: '',
    },
    'vapaan-hyvaksynnan-peruminen-epaonnistui-on-jo-epahyvaksytty': {
        fi: 'Vapaata ei ole hyväksytty, päivitä näkymä.',
        sv: '',
        en: 'The event has not been approved yet. Please update the view.',
        ru: '',
        sk: '',
        pl: '',
    },
    'vapaan-hyvaksynnan-peruminen-epaonnistui-toiminnanohjaus-hyvaksynyt': {
        fi: 'Vapaan hyväksymistä ei voi perua, koska toiminnanohjaus on hyväksynyt sen. ',
        sv: '',
        en: "Can't undo the approval of the event because 'toiminnanohjaus' (???) has already approved it. ",
        ru: '',
        sk: '',
        pl: '',
    },
    vapaat: { fi: 'Vapaat', sv: '', en: 'Vapaat (???)', ru: '', sk: '', pl: '' },
    'vapaiden-massahyvaksynnan-ohjeteksti': {
        fi: 'Vuorojen hyväksynnässä tehdyt valinnat eivät vaikuta vapaiden hyväksymiseen!',
        sv: '',
        en: "Any selections made while approving shifts don't affect approving non-shifts!",
        ru: '',
        sk: '',
        pl: '',
    },
    VaratutTyovuorotRaportti: { fi: 'Varatut työvuorot', sv: '', en: 'Reserved shifts', ru: '', sk: '', pl: '' },
    'view-engine.ViewEngineThunks.ItemsFilteredOutMessage': {
        fi: 'Toiminto voidaan suorittaa vain osalle ({itemsIncludedCount} / {totalCount}) valituista kohteista.',
        sv: '',
        en: 'The action can be performed only on some ({itemsIncludedCount} / {totalCount}) of the selected items.',
        ru: '',
        sk: '',
        pl: '',
    },
    'vie-exceliin': { fi: 'Vie exceliin', sv: '', en: 'Export to excel', ru: '', sk: '', pl: '' },
    viesti: { fi: 'Viesti', sv: '', en: 'Message', ru: '', sk: '', pl: '' },
    'viesti-poista': { fi: 'Poista viesti', sv: 'Radera', en: 'Delete', ru: '', sk: '', pl: '' },
    'viesti-vastaa-viestiin': { fi: 'Vastaa viestiin', sv: 'Svara', en: 'Reply', ru: '', sk: '', pl: '' },
    'viesti-vastaus-otsikon-alku': { fi: 'VS: ', sv: 'RE: ', en: 'RE: ', ru: '', sk: '', pl: '' },
    'viesti-vastaus-sisallon-alku': {
        fi: 'Alkuperäinen viesti: \n >> ',
        sv: 'Ursprungligt meddelande: \n >> ',
        en: 'Original message: \n >> ',
        ru: '',
        sk: '',
        pl: '',
    },
    'viestin-otsikko': { fi: 'Viestin otsikko', sv: '', en: 'Title for the message', ru: '', sk: '', pl: '' },
    'viestin-poisto-onnistui': {
        fi: 'Viestin poisto onnistui',
        sv: '',
        en: 'Removing the message succeeded',
        ru: '',
        sk: '',
        pl: '',
    },
    viestit: { fi: 'Viestit', sv: '', en: 'Messages', ru: '', sk: '', pl: '' },
    'viestit-alkaen': { fi: 'Viestit alkaen', sv: '', en: 'Messages from', ru: '', sk: '', pl: '' },
    'viestit-paattyen': { fi: 'Viestit päättyen', sv: '', en: 'Messages due', ru: '', sk: '', pl: '' },
    'viikonpaiva-Keskiviikko': { fi: 'Keskiviikko', sv: 'Onsdag', en: 'Wednesday', ru: '', sk: '', pl: '' },
    'viikonpaiva-keskiviikko-lyhyt': { fi: 'Ke', sv: '', en: 'Wed', ru: '', sk: '', pl: '' },
    'viikonpaiva-Lauantai': { fi: 'Lauantai', sv: 'Lördag', en: 'Saturday', ru: '', sk: '', pl: '' },
    'viikonpaiva-lauantai-lyhyt': { fi: 'La', sv: '', en: 'Sat', ru: '', sk: '', pl: '' },
    'viikonpaiva-Maanantai': { fi: 'Maanantai', sv: 'Mondag', en: 'Monday', ru: '', sk: '', pl: '' },
    'viikonpaiva-maanantai-lyhyt': { fi: 'Ma', sv: '', en: 'Mon', ru: '', sk: '', pl: '' },
    'viikonpaiva-Perjantai': { fi: 'Perjantai', sv: 'Fredag', en: 'Friday', ru: '', sk: '', pl: '' },
    'viikonpaiva-perjantai-lyhyt': { fi: 'Pe', sv: '', en: 'Fri', ru: '', sk: '', pl: '' },
    'viikonpaiva-Sunnuntai': { fi: 'Sunnuntai', sv: 'Söndag', en: 'Sunday', ru: '', sk: '', pl: '' },
    'viikonpaiva-sunnuntai-lyhyt': { fi: 'Su', sv: '', en: 'Sun', ru: '', sk: '', pl: '' },
    'viikonpaiva-Tiistai': { fi: 'Tiistai', sv: 'Tisdag', en: 'Tuesday', ru: '', sk: '', pl: '' },
    'viikonpaiva-tiistai-lyhyt': { fi: 'Ti', sv: '', en: 'Tue', ru: '', sk: '', pl: '' },
    'viikonpaiva-Torstai': { fi: 'Torstai', sv: 'Torsdag', en: 'Thursday', ru: '', sk: '', pl: '' },
    'viikonpaiva-torstai-lyhyt': { fi: 'To', sv: '', en: 'Thu', ru: '', sk: '', pl: '' },
    viikonpaivat: { fi: 'Viikonpäivä(t)', sv: 'Torsdag', en: 'Thursday', ru: '', sk: '', pl: '' },
    'virheellinen-alue': { fi: 'Virheellinen alue.', sv: 'Torsdag', en: 'Thursday', ru: '', sk: '', pl: '' },
    vuoro: { fi: 'vuoro', sv: '', en: 'Shift', ru: '', sk: '', pl: '' },
    'vuoro-loppuu-seuraavan-vuorokauden-puolella': {
        fi: 'Huom.! vuoro loppuu seuraavan vuorokauden puolella!',
        sv: '',
        en: 'Note! The shift ends in the next day!',
        ru: '',
        sk: '',
        pl: '',
    },
    viikko: {
        fi: 'viikko',
        sv: '',
        en: 'week',
        ru: '',
        sk: '',
        pl: '',
    },
    viikkoa: {
        fi: 'viikkoa',
        sv: '',
        en: 'weeks',
        ru: '',
        sk: '',
        pl: '',
    },
    vuoroa: { fi: 'vuoroa', sv: '', en: 'shifts', ru: '', sk: '', pl: '' },
    'vuoroa-ei-loytynyt': { fi: 'Vuoroa ei löytynyt!', sv: '', en: 'Shift not found!', ru: '', sk: '', pl: '' },
    'vuoron-hyvaksyminen-epaonnistui-on-jo-hyvaksytty': {
        fi: 'Vuoro on jo hyväksytty, päivitä näkymä.',
        sv: '',
        en: 'Shift has been approved already, please refresh the view.',
        ru: '',
        sk: '',
        pl: '',
    },
    'vuoron-hyvaksynnan-peruminen-epaonnistui-on-jo-epahyvaksytty': {
        fi: 'Vuoroa ei ole hyväksytty, päivitä näkymä.',
        sv: '',
        en: 'Shift has not been approved yet, please refresh the view',
        ru: '',
        sk: '',
        pl: '',
    },
    'vuoron-tallennus-epaonnistui-paallekaisia-pitkia-tilauksia': {
        fi: 'Vuoroa ei voi tallentaa. Työntekijällä päällekäisiä pitkiä tilauksia.',
        sv: '',
        en: 'Shift can not be saved. The employee has overlapping long orders.',
        ru: '',
        sk: '',
        pl: '',
    },
    'vuoron-tallennus-epaonnistui-paallekaisia-vapaita': {
        fi: 'Vuoroa ei voi tallentaa. Työntekijällä päällekäisiä vapaita.',
        sv: '',
        en: 'Shift can not be saved. The employee has overlapping non-shifts',
        ru: '',
        sk: '',
        pl: '',
    },
    'vuoron-tallennus-epaonnistui-paallekaisia-vuoroja': {
        fi: 'Vuoroa ei voi tallentaa. Työntekijällä päällekäisiä vuoroja.',
        sv: '',
        en: 'Shift can not be saved. The employee has overlapping shifts.',
        ru: '',
        sk: '',
        pl: '',
    },
    'vuoron-tallennus-epaonnistui-vuorolla-on-toteumaa': {
        fi: 'Vuorolla on toteumaa.',
        sv: '',
        en: 'Shift has transactions.',
        ru: '',
        sk: '',
        pl: '',
    },
    vuorot: { fi: 'Vuorot', sv: '', en: 'Shifts', ru: '', sk: '', pl: '' },
    'vuorot-ja-vapaat': { fi: 'Vuorot ja vapaat', sv: '', en: 'Events', ru: '', sk: '', pl: '' },
    vuosi: { fi: 'Vuosi', sv: '', en: 'Year', ru: '', sk: '', pl: '' },
    yksikko: { fi: 'Yksikkö', sv: '', en: 'Work unit', ru: '', sk: '', pl: '' },
    'yksittaiset-vuorot': { fi: 'Yksittäiset vuorot', sv: '', en: 'Single shifts', ru: '', sk: '', pl: '' },
    yleiskuvaus: { fi: 'Yleiskuvaus', sv: '', en: 'Description', ru: '', sk: '', pl: '' },
    'yhteyshenkilon-tietojen-muokkaus': {
        fi: 'Yhteyshenkilön tietojen muokkaus',
        sv: '',
        en: "Modifying contact person's info",
        ru: '',
        sk: '',
        pl: '',
    },
    'yllapito-callstack': { fi: 'Kutsupino', sv: '', en: 'Call stack', ru: '', sk: '', pl: '' },
    'yllapito-callstack-on-tyhja': {
        fi: 'Kutsupino on tyhjä',
        sv: '',
        en: 'Call stack is empty!',
        ru: '',
        sk: '',
        pl: '',
    },
    'yllapito-ei-virheilmoituksia': {
        fi: 'Ei virheilmoituksia',
        sv: '',
        en: 'No error notifications',
        ru: '',
        sk: '',
        pl: '',
    },
    'yllapito-poikkeuslokiselain': {
        fi: 'Poikkeuslokiselain',
        sv: '',
        en: 'Exception log browser',
        ru: '',
        sk: '',
        pl: '',
    },
    'yllapito-poikkeuslokit': { fi: 'Poikkeuslokit', sv: '', en: 'Exception logs', ru: '', sk: '', pl: '' },
    'yllapito-sisakkaisetpoikkeukset': {
        fi: 'Sisäkkäiset poikkeukset',
        sv: '',
        en: 'Internal exceptions',
        ru: '',
        sk: '',
        pl: '',
    },
    'yllapito-tallennuskansio': { fi: 'Tallennuskansio', sv: '', en: 'Save directory', ru: '', sk: '', pl: '' },
    'yllapito-sisaltaa-sisakkaisia-poikkeuksia': {
        fi: 'Sisältää sisäkkäisiä poikkeuksia',
        sv: '',
        en: 'Contains nested exceptions',
        ru: '',
        sk: '',
        pl: '',
    },
    'yllapito-virheilmoitukset': {
        fi: 'Virheilmoitukset',
        sv: '',
        en: 'Exception notifications',
        ru: '',
        sk: '',
        pl: '',
    },
    'y-tunnus': { fi: 'Y-tunnus', sv: '', en: 'Business ID', ru: '', sk: '', pl: '' },
    shifts: { fi: 'Työvuorot', sv: '', en: 'Workshifts', ru: '', sk: '', pl: '' },
    reset: { fi: 'nollaa', sv: '', en: 'reset', ru: '', sk: '', pl: '' },
    'set-as-default': { fi: 'Tallenna oletukseksi', sv: '', en: 'Set as default', ru: '', sk: '', pl: '' },
    'load-defaults': { fi: 'Lataa oletukset', sv: '', en: 'Load defaults', ru: '', sk: '', pl: '' },
    Alue: { fi: 'Alue', sv: '', en: 'Area', ru: '', sk: '', pl: '' },
    Työkohde: { fi: 'Työkohde', sv: '', en: 'Workunit', ru: '', sk: '', pl: '' },
    Työntekijä: { fi: 'Työntekijä', sv: '', en: 'Employee', ru: '', sk: '', pl: '' },
    Pooli: { fi: 'Pooli', sv: '', en: 'Pool', ru: '', sk: '', pl: '' },
    Ammattinimike: { fi: 'Ammattinimike', sv: '', en: 'JobTitle', ru: '', sk: '', pl: '' },
    Hae: { fi: 'Hae', sv: '', en: 'Search', ru: '', sk: '', pl: '' },
    'Hae yli 250': { fi: 'Hae yli 250', sv: '', en: 'Fetch over 250', ru: '', sk: '', pl: '' },
    'Hae (ID, lisätiedot)': { fi: 'Hae (ID, lisätiedot)', sv: '', en: 'Search (ID, info)', ru: '', sk: '', pl: '' },
    'Hae (työkohteen nimi)': {
        fi: 'Hae (työkohteen nimi)',
        sv: '',
        en: 'Search (work unit name)',
        ru: '',
        sk: '',
        pl: '',
    },
    Asiakkaat: { fi: 'Asiakkaat', sv: '', en: 'Customers', ru: '', sk: '', pl: '' },
    Työntekijät: { fi: 'Työntekijät', sv: '', en: 'Employees', ru: '', sk: '', pl: '' },
    Tapahtumatyypit: { fi: 'Tapahtumatyypit', sv: '', en: 'Event types', ru: '', sk: '', pl: '' },
    TES: { fi: 'TES', sv: '', en: 'Collective agreement', ru: '', sk: '', pl: '' },
    Poolit: { fi: 'Poolit', sv: '', en: 'Pools', ru: '', sk: '', pl: '' },
    'Näytä vain julkaistut': { fi: 'Näytä vain julkaistut', sv: '', en: 'Show published only', ru: '', sk: '', pl: '' },
    'Näytä vain täytetyt': { fi: 'Näytä vain täytetyt', sv: '', en: 'Show assigned only', ru: '', sk: '', pl: '' },
    'Näytä myös vahvistetut': {
        fi: 'Näytä myös vahvistetut',
        sv: '',
        en: 'Include confirmed',
        ru: '',
        sk: '',
        pl: '',
    },
    'Näytä vain täyttämättömät': {
        fi: 'Näytä vain täyttämättömät',
        sv: '',
        en: 'Show non-assigned only',
        ru: '',
        sk: '',
        pl: '',
    },
    Työvuorot: { fi: 'Työvuorot', sv: '', en: 'Shifts', ru: '', sk: '', pl: '' },
    vko: { fi: 'vko', sv: '', en: 'week', ru: '', sk: '', pl: '' },
    'Vain pooliin kuuluvat': { fi: 'Vain pooliin kuuluvat', sv: '', en: 'Only pool members', ru: '', sk: '', pl: '' },
    'Lisää tiedosto': { fi: 'Lisää tiedosto', sv: '', en: 'Add file', ru: '', sk: '', pl: '' },
    'Ei valittua tiedostoa': { fi: 'Ei valittua tiedostoa', sv: '', en: 'No file selected', ru: '', sk: '', pl: '' },
    'Poista tiedosto': { fi: 'Poista tiedosto', sv: '', en: 'Remove file', ru: '', sk: '', pl: '' },
    'Lisää kuva': { fi: 'Lisää kuva', sv: '', en: 'Add image', ru: '', sk: '', pl: '' },
    'Piilota päivälaskurit': { fi: 'Piilota päivälaskurit', sv: '', en: 'Hide daily meters', ru: '', sk: '', pl: '' },
    Tapahtumatyyppi: { fi: 'Tapahtumatyyppi', sv: '', en: 'Event type', ru: '', sk: '', pl: '' },

    'Luo uusi': { fi: 'Luo uusi', sv: '', en: 'Create new', ru: '', sk: '', pl: '' },

    'Lisää työntekijä': { fi: 'Lisää työntekijä', sv: '', en: 'Add employee', ru: '', sk: '', pl: '' },
    Julkaise: { fi: 'Julkaise', sv: '', en: 'Publish', ru: '', sk: '', pl: '' },
    Kopioi: { fi: 'Kopioi', sv: '', en: 'Copy', ru: '', sk: '', pl: '' },

    'Luo tapahtumapohja': { fi: 'Luo tapahtumapohja', sv: '', en: 'Create event template', ru: '', sk: '', pl: '' },
    'Luo profiili': { fi: 'Luo profiili', sv: '', en: 'Create profile', ru: '', sk: '', pl: '' },

    Poista: { fi: 'Poista', sv: '', en: 'Delete', ru: '', sk: '', pl: '' },
    Vaihda: { fi: 'Vaihda', sv: '', en: 'Swap', ru: '', sk: '', pl: '' },
    'Irrota työntekijä': { fi: 'Irrota työntekijä', sv: '', en: 'Remove employee', ru: '', sk: '', pl: '' },
    'Merkitse poissaoloksi': { fi: 'Merkitse poissaoloksi', sv: '', en: 'Mark as absence', ru: '', sk: '', pl: '' },

    Valitse: { fi: 'Valitse', sv: '', en: 'Select', ru: '', sk: '', pl: '' },

    'Valitse kaikki tapahtumat': {
        fi: 'Valitse kaikki tapahtumat',
        sv: '',
        en: 'Select all events',
        ru: '',
        sk: '',
        pl: '',
    },
    'Valitse päivän tapahtumat': {
        fi: 'Valitse päivän tapahtumat',
        sv: '',
        en: 'Select events of the day',
        ru: '',
        sk: '',
        pl: '',
    },
    'Valitse työntekijän tapahtumat': {
        fi: 'Valitse työntekijän tapahtumat',
        sv: '',
        en: 'Select events of the employee',
        ru: '',
        sk: '',
        pl: '',
    },
    'Valitse työkohteen tapahtumat': {
        fi: 'Valitse työkohteen tapahtumat',
        sv: '',
        en: 'Select events of the workunit',
        ru: '',
        sk: '',
        pl: '',
    },
    'Valitse ammattinimikkeen tapahtumat': {
        fi: 'Valitse ammattinimikkeen tapahtumat',
        sv: '',
        en: 'Select events of same job title',
        ru: '',
        sk: '',
        pl: '',
    },
    'Yhdistä tapahtumat yhdessä tehtäväksi': {
        fi: 'Yhdistä tapahtumat yhdessä tehtäväksi',
        sv: '',
        en: 'Combine events to a single reserable event',
        ru: '',
        sk: '',
        pl: '',
    },

    'Työkohteen tapahtumat': { fi: 'Työkohteen tapahtumat', sv: '', en: 'Workunits events', ru: '', sk: '', pl: '' },
    'Kaikki tapahtumat': { fi: 'Kaikki tapahtumat', sv: '', en: 'All events', ru: '', sk: '', pl: '' },
    'Kaikki tiedot': { fi: 'Kaikki tiedot', sv: '', en: 'All data', ru: '', sk: '', pl: '' },
    'EventSelector.Date': { fi: 'Päivän tapahtumat', sv: '', en: 'Events of the day', ru: '', sk: '', pl: '' },
    'Ammattinimikken tapahtumat': {
        fi: 'Ammattinimikken tapahtumat',
        sv: '',
        en: 'Events of same jobtitle',
        ru: '',
        sk: '',
        pl: '',
    },

    Alueet: { fi: 'Alueet', sv: '', en: 'Areas', ru: '', sk: '', pl: '' },
    'Laskuttava yritys': { fi: 'Laskuttava yritys', sv: '', en: 'Invoicing company', ru: '', sk: '', pl: '' },
    Vastuuhenkilö: { fi: 'Vastuuhenkilö', sv: '', en: 'Person in charge', ru: '', sk: '', pl: '' },
    Vastuuhenkilöt: { fi: 'Vastuuhenkilöt', sv: '', en: 'Persons in charge', ru: '', sk: '', pl: '' },
    Erikoistaidot: { fi: 'Erikoistaidot', sv: '', en: 'Skills', ru: '', sk: '', pl: '' },
    Ammattinimikkeet: { fi: 'Ammattinimikkeet', sv: '', en: 'Jobtitles', ru: '', sk: '', pl: '' },
    Työsopimustyypit: { fi: 'Työsopimustyypit', sv: '', en: 'Contract types', ru: '', sk: '', pl: '' },
    Työkohteet: { fi: 'Työkohteet', sv: '', en: 'Workunits', ru: '', sk: '', pl: '' },
    Palkkatyypit: { fi: 'Palkkatyypit', sv: '', en: 'Paytypes', ru: '', sk: '', pl: '' },
    Viikonpäivät: { fi: 'Viikonpäivät', sv: '', en: 'Day of week', ru: '', sk: '', pl: '' },
    ValidThru: { fi: 'Voimassaolo', sv: '', en: 'Valid thru', ru: '', sk: '', pl: '' },
    DateOfExecution: { fi: 'Suorituksen päivämäärä', sv: '', en: 'Date of execution', ru: '', sk: '', pl: '' },
    Lisätiedot: { fi: 'Lisätiedot', sv: '', en: 'Additional information', ru: '', sk: '', pl: '' },
    'TES:t': { fi: 'TES:t', sv: '', en: 'Collective agreements', ru: '', sk: '', pl: '' },
    Ruokatauko: { fi: 'Ruokatauko', sv: '', en: 'Meal break', ru: '', sk: '', pl: '' },
    'Palkanmaksava yritys': { fi: 'Palkanmaksava yritys', sv: '', en: 'Payee company', ru: '', sk: '', pl: '' },
    Käsittelysääntöstatukset: {
        fi: 'Käsittelysääntöstatukset',
        sv: '',
        en: 'Processing rule statuses',
        ru: '',
        sk: '',
        pl: '',
    },
    Käsittelysääntötyyppi: { fi: 'Käsittelysääntötyyppi', sv: '', en: 'Processing rule type', ru: '', sk: '', pl: '' },
    'Toteutuneet ajat poikkeavat julkaistuista': {
        fi: 'Toteutuneet ajat poikkeavat julkaistuista',
        sv: '',
        en: 'Actual times differ from planned',
        ru: '',
        sk: '',
        pl: '',
    },
    Työaikamuodot: { fi: 'Työaikamuodot', sv: '', en: 'Form of working hours', ru: '', sk: '', pl: '' },
    Palkkalajit: { fi: 'Palkkalajit', sv: '', en: 'Salary categories', ru: '', sk: '', pl: '' },
    Käsittelytavat: { fi: 'Käsittelytavat', sv: '', en: 'Processing types', ru: '', sk: '', pl: '' },
    Siirtotyypit: { fi: 'Siirtotyypit', sv: '', en: 'Transfer types', ru: '', sk: '', pl: '' },
    Lisätietostatukset: {
        fi: 'Lisätietostatukset',
        sv: '',
        en: 'Additional information status',
        ru: '',
        sk: '',
        pl: '',
    },
    'ALV-kannat': { fi: 'ALV-kannat', sv: '', en: 'VAT-classes', ru: '', sk: '', pl: '' },
    Palkkatuotteet: { fi: 'Palkkatuotteet', sv: '', en: 'Payroll products', ru: '', sk: '', pl: '' },
    Palkkaerät: { fi: 'Palkkaerät', sv: '', en: 'Payroll batches', ru: '', sk: '', pl: '' },
    Laskuerät: { fi: 'Laskuerät', sv: '', en: 'Invoice batches', ru: '', sk: '', pl: '' },

    Toteumastatukset: { fi: 'Toteumastatukset', sv: '', en: 'Transaction status', ru: '', sk: '', pl: '' },
    Tuote: { fi: 'Tuote', sv: '', en: 'Product', ru: '', sk: '', pl: '' },
    Tilaustyyppi: { fi: 'Tilaustyyppi', sv: '', en: 'Order type', ru: '', sk: '', pl: '' },
    'Näytä myös poistetut': { fi: 'Näytä myös poistetut', sv: '', en: 'Include deleted', ru: '', sk: '', pl: '' },
    Hinnoittelukohde: { fi: 'Hinnoittelukohde', sv: '', en: 'Pricing target', ru: '', sk: '', pl: '' },
    'Tilauksen syyt': { fi: 'Tilauksen syyt', sv: '', en: 'Grounds for an order', ru: '', sk: '', pl: '' },
    'Työvuorojen hyvaksyntästatukset': {
        fi: 'Työvuorojen hyvaksyntästatukset',
        sv: '',
        en: 'Shift approval statuses',
        ru: '',
        sk: '',
        pl: '',
    },
    Työntekijäerikoistaidot: { fi: 'Työntekijäerikoistaidot', sv: '', en: 'Emplyoee skills', ru: '', sk: '', pl: '' },
    Työsopimukset: { fi: 'Työsopimukset', sv: '', en: 'Contracts', ru: '', sk: '', pl: '' },
    Jaksot: { fi: 'Jaksot', sv: '', en: 'Periods', ru: '', sk: '', pl: '' },
    Perustiedot: { fi: 'Perustiedot', sv: '', en: 'General', ru: '', sk: '', pl: '' },
    AllPools: { fi: 'Kaikki poolit', sv: '', en: 'All pools', ru: '', sk: '', pl: '' },
    AllEmployees: { fi: 'Kaikki työntekijät', sv: '', en: 'All employees', ru: '', sk: '', pl: '' },
    rule: { fi: 'Sääntö', sv: '', en: 'Rule', ru: '', sk: '', pl: '' },
    event_reservation_publish_start: {
        fi: 'Varattavissa, kun tapahtuman julkaisusta on',
        sv: '',
        en: 'Only after',
        ru: '',
        sk: '',
        pl: '',
    },
    event_reservation_publish_end: { fi: 'tuntia', sv: '', en: 'hours since event published', ru: '', sk: '', pl: '' },
    pool_selection_tooltip: {
        fi: 'Jos valitset poolin, ovat kaikki sen työntekijät oletuksena valittuina. Voit tehdä tarkemman rajauksen työntekijävalikosta.',
        sv: '',
        en: 'All employees from selected pools are selected by default. You can select specific employees from the employee selection.',
        ru: '',
        sk: '',
        pl: '',
    },
    employee_selection_tooltip: {
        fi: 'Huom.! Jos olet valinnut pooleja, valittavana on vain kyseisiin pooleihin kuuluvia työntekijöitä.',
        sv: '',
        en: 'If you have selected any pools, only employees from the selected pools are selectable.',
        ru: '',
        sk: '',
        pl: '',
    },
    event_reservation_before_start: {
        fi: 'Varattavissa, kun tapahtuman alkuun on',
        sv: '',
        en: 'Only after',
        ru: '',
        sk: '',
        pl: '',
    },
    event_reservation_before_end: {
        fi: 'tuntia',
        sv: '',
        en: 'hours before start of the event',
        ru: '',
        sk: '',
        pl: '',
    },
    add_new_rule: { fi: 'Lisää uusi sääntö', sv: '', en: 'Add new rule', ru: '', sk: '', pl: '' },
    remove: { fi: 'Poista', sv: '', en: 'Remove', ru: '', sk: '', pl: '' },
    'Pakollinen valinta': {
        fi: 'Pakollinen valinta',
        sv: '',
        en: 'Required field',
        ru: '',
        sk: '',
        pl: '',
    },
    'Peru vuoro': {
        fi: 'Peru vuoro',
        sv: '',
        en: 'Cancel event',
        ru: '',
        sk: '',
        pl: '',
    },
    price: {
        fi: 'Hinta',
        sv: '',
        en: 'Price',
        ru: '',
        sk: '',
        pl: '',
    },
    user_deleted: {
        fi: 'käyttäjä poistettu',
        sv: '',
        en: 'user deleted',
        ru: '',
        sk: '',
        pl: '',
    },
    event_group: {
        fi: 'Vuororyhmä',
        sv: '',
        en: 'Event group',
        ru: '',
        sk: '',
        pl: '',
    },
    reserving_event_group: {
        fi: 'Varaava vuororyhmä',
        sv: '',
        en: 'Reserving event group',
        ru: '',
        sk: '',
        pl: '',
    },
    selected: {
        fi: 'valittu',
        sv: '',
        en: 'selected',
        ru: '',
        sk: '',
        pl: '',
    },
    'Näytä myös käsitellyt': {
        fi: 'Näytä myös käsitellyt',
        sv: '',
        en: 'Include processed',
        ru: '',
        sk: '',
        pl: '',
    },
    'Vaadi erikoistaito': {
        fi: 'Vaadi erikoistaito',
        sv: '',
        en: 'Require skills',
        ru: '',
        sk: '',
        pl: '',
    },
    'Laajenna hakua ammattinimikkeille': {
        fi: 'Laajenna hakua ammattinimikkeille',
        sv: '',
        en: 'Expand search for job titles',
    },
    typeHere: {
        fi: 'Kirjoita',
        sv: '',
        en: 'Type here',
        ru: '',
        sk: '',
        pl: '',
    },
    Kustannuspaikat: {
        fi: 'Kustannuspaikat',
        sv: '',
        en: 'Cost codes',
        ru: '',
        sk: '',
        pl: '',
    },
    filtersSavedAsDefault: {
        fi: 'Suodattimet tallennettu oletukseksi',
        sv: '',
        en: 'Filters saved as default',
        ru: '',
        sk: '',
        pl: '',
    },
    findSuitableShifts: {
        fi: 'Etsi soveltuvia työvuoroja',
        sv: '',
        en: 'Find suitable shifts',
        ru: '',
        sk: '',
        pl: '',
    },
    'Näytä vain kuittaamattomat': {
        fi: 'Näytä vain kuittaamattomat',
        sv: '',
        en: 'Show only pending read receipts',
        ru: '',
        sk: '',
        pl: '',
    },
    'Manuaalinen tarjoaminen oletuksena': {
        fi: 'Manuaalinen tarjoaminen oletuksena',
        sv: '',
        en: 'Manual offering by default',
        ru: '',
        sk: '',
        pl: '',
    },
    removeSpecialSkill: {
        fi: 'Poista erikoistaito',
        sv: '',
        en: 'Remove special skill',
        ru: '',
        sk: '',
        pl: '',
    },
    'Näytä vain voimassa olevat': {
        fi: 'Näytä vain voimassa olevat',
        sv: '',
        en: 'Show only the currently valid contracts',
        ru: '',
        sk: '',
        pl: '',
    },
    ID: {
        fi: 'ID',
        sv: '',
        en: 'ID',
        ru: '',
        sk: '',
        pl: '',
    },
    Organization: {
        fi: 'Organisaatio',
        sv: '',
        en: 'Organization',
        ru: '',
        sk: '',
        pl: '',
    },
    reservationConstraint: {
        fi: 'Varaussääntö',
        sv: '',
        en: 'Reservation constraint',
        ru: '',
        sk: '',
        pl: '',
    },
    reservationConstraintTooltip: {
        fi: 'Varaussäännöillä määritellään, ketkä työntekijät voivat varata työvuoron itselleen. Työvuoroa koskevan varaussäännön piiriin kuuluvat työntekijät näkevät työvuoron mobiilisovelluksessaan ja pystyvät varaamaan sen itselleen',
        sv: '',
        en: '',
        ru: '',
        sk: '',
        pl: '',
    },
    requestRule: {
        fi: 'Ehdotussääntö',
        sv: '',
        en: 'Request rule',
        ru: '',
        sk: '',
        pl: '',
    },
    requestRuleTooltip: {
        fi:
            'Ehdotussäännöillä määritellään, ketkä työntekijät voivat ehdottaa itseään työvuoroon. Työvuoroa koskevan ehdotussäännön piiriin kuuluvat työntekijät pystyvät ehdottamaan itseään vuoroon.\n\n' +
            'Hallintokäyttäjän tulee hyväksyä ehdotus, ennen kuin vuoro vahvistetaan työntekijälle. Ehdotus hyväksytään työvuorojen listanäkymässä Hae työntekijää-dialogissa.',
        sv: '',
        en: '',
        ru: '',
        sk: '',
        pl: '',
    },
    ...messagingTranslations,
    ...employeeCalendarTranslations,
    ...cookieBanner,
}
