import { isNil } from 'lodash-es'

interface IFunction {
    (...args: unknown[]): unknown
}

export const isFunction = (property: IFunction | unknown): property is IFunction => typeof property === 'function'

/**
 * Taken from https://stackoverflow.com/a/32538867
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isIterable = <T>(object: any): object is Iterable<T> => {
    if (isNil(object)) {
        return false
    }

    return typeof object[Symbol.iterator] === 'function'
}
