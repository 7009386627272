import { Component } from 'react'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import Grid from '@material-ui/core/Grid'

import DateUtility from 'common/DateUtility'
import { Translation } from '../../../../localization'
import { ILoadingItemsData } from 'interfaces/actions/ILoadingAction'
import { markAdditionalInformationAcknowledgedAction } from '../action-creators/LisatietokuittausActions'

import AdditionalNotes from './AdditionalNotes'
import { Button, TranslatedButton } from '../../../../generic-components'
import MaterialPopover from '../../../../legacy/components/molecules/MaterialPopover'
import { BoundThunk } from '../../../../generic-state'
import Typography from '@material-ui/core/Typography'
import { Icon } from '../../../../generic-components'

interface IOwnProps {
    additionalInfoAcknowledged: boolean
    anchorElement: HTMLElement | null
    generalNotes: string | null
    customerNotes: string | null
    dataForLoading: ILoadingItemsData
    datetime: string
    employeeNotes: string | null
    id: string
    markAdditionalInformationAcknowledged: BoundThunk<typeof markAdditionalInformationAcknowledgedAction>
    employeeName: string
    onClose: () => void
    open: boolean
    profession: string | null
    disabled: boolean
}

const popoverPaperStyles = {
    width: '444px',
}

const HeaderContainer = styled(Grid)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralsGrey30};
    padding: 24px 8px 8px 24px;
`

const PopoverContainer = styled(Grid)`
    padding: 24px;
`

const EmployeeInfoContainer = styled(Grid)`
    padding-bottom: 16px;
`

const ConfirmButtonArea = styled.div`
    padding-left: 8px;
`

const GridRight = styled(Grid)`
    margin-top: 35px;
    align-self: flex-end;
`

/**
 * Komponentti työaikatapahtuman lisätietojen näyttämiseen käyttöliittymässä
 */
class AdditionalInfoPopover extends Component<IOwnProps> {
    static readonly displayName = 'AdditionalInfoPopover'

    handleConfirmClick = () => {
        const { additionalInfoAcknowledged, dataForLoading, id, markAdditionalInformationAcknowledged, onClose } =
            this.props

        onClose()
        markAdditionalInformationAcknowledged(!additionalInfoAcknowledged, id, dataForLoading)
    }

    render(): React.ReactNode {
        const {
            additionalInfoAcknowledged,
            anchorElement,
            customerNotes,
            datetime,
            generalNotes,
            employeeNotes,
            employeeName,
            onClose,
            open,
            profession,
            disabled,
        } = this.props

        if (!anchorElement) {
            return null
        }

        return (
            <MaterialPopover anchorElement={anchorElement} onClose={onClose} open={open} style={popoverPaperStyles}>
                <HeaderContainer container>
                    <Grid item xs={11}>
                        <Typography variant="h2">{Translation.translateKey('tapahtuman-lisätiedot')}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Icon size={24} onClick={onClose}>
                            close
                        </Icon>
                    </Grid>
                </HeaderContainer>
                <PopoverContainer container>
                    <EmployeeInfoContainer container>
                        <Grid item xs={3}>
                            <Typography variant="h5">{Translation.translateKey('tyontekija')}:</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant="bodyM">
                                {employeeName}
                                {profession ? `, ${profession}` : ', '}
                                {datetime ? `, ${DateUtility.asFullDate(datetime)}` : ''}
                            </Typography>
                        </Grid>
                    </EmployeeInfoContainer>
                    <AdditionalNotes
                        customerNotes={customerNotes}
                        employeeNotes={employeeNotes}
                        generalNotes={generalNotes}
                    />
                    <GridRight container justifyContent="flex-end" wrap="nowrap">
                        <TranslatedButton onClick={onClose} variant="text">
                            close-button
                        </TranslatedButton>
                        {disabled ? (
                            <></>
                        ) : (
                            <ConfirmButtonArea>
                                <Button onClick={this.handleConfirmClick}>
                                    {additionalInfoAcknowledged
                                        ? Translation.translateKey('aseta-kuitattavaksi')
                                        : Translation.translateKey('kuittaa-luetuksi')}
                                </Button>
                            </ConfirmButtonArea>
                        )}
                    </GridRight>
                </PopoverContainer>
            </MaterialPopover>
        )
    }
}

const mapDispatchToProps = {
    markAdditionalInformationAcknowledged: markAdditionalInformationAcknowledgedAction,
}

export default connect(null, mapDispatchToProps)(AdditionalInfoPopover)
