import { memo, MouseEvent } from 'react'
import { TDataSourceItemId } from '../../../../data-source-types'
import { IPermission } from 'interfaces/IActionPermission'
import EListViewActionFunctionalityType from '../../../Types/EListViewActionFunctionalityType'
import { Icon } from '../../../../generic-components'
import IconButton from '@material-ui/core/IconButton'
import styled from '@emotion/styled'
import { translate } from '../../../../localization'
import { isNil } from 'lodash-es'
import { getTestId } from '../../../../test'

type Props = {
    actionType: EListViewActionFunctionalityType
    callbackProps: any
    disabled: boolean
    icon: string
    itemId: TDataSourceItemId
    onClick: (actionType: EListViewActionFunctionalityType, callbackProps: any, event: any) => void
    permission?: IPermission
    tooltip: string
    tooltipLeaveDelay?: number
}

const StyledButton = styled(IconButton)`
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme.colors.primaryTeal120};

    & .MuiIconButton-label {
        height: 20px;
        align-items: flex-start;
    }

    &:disabled {
        color: ${({ theme }) => theme.componentExtensions.text.disabledLighter};
        pointer-events: auto;
    }

    &:focus {
        outline: 2px solid ${({ theme }) => theme.colors.primaryTeal60};
    }
`

const MessageBadge = styled.div<{ unread: boolean; disabled: boolean }>`
    position: absolute;
    right: 6px;
    top: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    outline: 1px solid ${({ theme }) => theme.colors.neutralsWhite100};
    pointer-events: none;
    background-color: ${({ unread, disabled, theme: { componentExtensions } }) =>
        (unread && componentExtensions.icons.iconWarning) ||
        (disabled && componentExtensions.icons.iconDisabledLighter) ||
        componentExtensions.icons.iconAction};
`

const isEnabled = (disabled: boolean, permission?: IPermission): boolean => {
    const permitted = permission ? permission.OnkoSallittu : true

    return !disabled && permitted
}

const getTooltip = (enabled: boolean, tooltip: string, permission?: IPermission): string | null => {
    const badgeInfo = permission?.AdditionalData?.BadgeInfo

    if (badgeInfo) {
        return badgeInfo
    }

    if (enabled && tooltip) {
        return tooltip
    }

    const reason = permission?.SyyKieltoon ?? null
    const reasonTooltip = reason ? `\n\n${translate('actionDisabled')}: ${reason}` : ''

    return `${tooltip}.${reasonTooltip}`
}

const RowAction = ({
    actionType,
    callbackProps,
    onClick,
    disabled,
    icon,
    permission,
    tooltip,
    tooltipLeaveDelay,
    itemId,
}: Props): JSX.Element => {
    const handleOnClick = (e: MouseEvent) => {
        // prevent a possible onClick for the list row itself from triggering
        e.stopPropagation()
        onClick(actionType, callbackProps, e)
    }

    const enabled = isEnabled(disabled, permission)
    const displayedTooltip = getTooltip(enabled, tooltip, permission)

    const showMessageBadge = !isNil(permission?.AdditionalData)

    return (
        <StyledButton onClick={handleOnClick} aria-label={displayedTooltip || ''} type="button" disabled={!enabled}>
            {showMessageBadge && (
                <MessageBadge
                    disabled={!enabled}
                    unread={permission?.AdditionalData?.BadgeWarningLevel === 'Warning'}
                />
            )}
            <Icon
                tooltip={displayedTooltip}
                leaveDelay={tooltipLeaveDelay}
                testId={getTestId(['ROW_ACTION', tooltip], itemId)}
            >
                {icon}
            </Icon>
        </StyledButton>
    )
}

export default memo(RowAction)
