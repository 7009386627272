import IDataSourceConfiguration from '../Types/IDataSourceConfiguration'

const tarvenakymaDataSourceConfiguration = (
    dataSourceMetadata: IDataSourceConfiguration
): IDataSourceConfiguration => ({
    ...dataSourceMetadata,
    DependsOn: ['TyontekijanakymaDataSource'],
})

export default tarvenakymaDataSourceConfiguration
