import EValuePickerType from '../types/EValuePickerType'
import { TValuePickerConfiguration } from '../../value-picker'

export const maintenanceViewValuePickerConfigurations: TValuePickerConfiguration[] = [
    {
        ValuePickerId: 'MaintenanceView_Tags',
        DependsOn: [],
        ItemLabelFields: ['Label'],
        EnableTextSearch: true,
        OptionsEndpointUrl: '/Tag/Search',
        ValuePickerType: EValuePickerType.TagList,
        DataRequestParameterName: 'TagIds',
        Text: {
            FetchError: 'Tagien haku epäonnistui',
        },
        TextSearchProps: [],
        UseDynamicApiSearch: false,
        Limit: null,
        ExtraRows: 'Include',
        MainColorField: 'MainColor',
        SupportColorField: 'SupportColor',
        DefaultValues: null,
    },
]
