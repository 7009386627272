import { FC } from 'react'
import styled from '@emotion/styled'

import { TranslatedTypography } from '../../generic-components'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    width: 100%;
`

const TooMuchDataWarning: FC = () => {
    return (
        <Container>
            <TranslatedTypography variant="listHeader">calendar.TooMuchDataWarning.Label</TranslatedTypography>
        </Container>
    )
}

export default TooMuchDataWarning
