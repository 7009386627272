import styled from '@emotion/styled'
import { Icon } from '../../generic-components'
import { IIdentifier } from '../../event/Types/IEvent'
import { eventTypeIndicatorProps } from './DayLogic'
import { useAppTheme } from '../../theme'

type Props = {
    eventTypeCategory: IIdentifier
}

const Indicator = styled.div<{ color: string; border?: boolean }>`
    width: 16px;
    height: 16px;
    background-color: ${({ color }) => color};
    border: ${({ theme, border }) => border && `1px solid ${theme.componentExtensions.border.secondary}`};
`

const EventCategoryIndicator = ({ eventTypeCategory }: Props): JSX.Element => {
    const theme = useAppTheme()
    const { icon, color, border } = eventTypeIndicatorProps(eventTypeCategory.Id as number, theme)

    if (icon) {
        return (
            <Icon color={color} size={16}>
                {icon}
            </Icon>
        )
    }

    return <Indicator color={color} border={border} />
}

export default EventCategoryIndicator
