// https://github.com/google/material-design-icons/blob/master/font/MaterialIcons-Regular.codepoints
export default {
    '3d_rotation': 'E84D',
    ac_unit: 'EB3B',
    access_alarm: 'E190',
    access_alarms: 'E191',
    access_time: 'E192',
    accessibility: 'E84E',
    accessible: 'E914',
    account_balance: 'E84F',
    account_balance_wallet: 'E850',
    account_box: 'E851',
    account_circle: 'E853',
    adb: 'E60E',
    add: 'E145',
    add_a_photo: 'E439',
    add_alarm: 'E193',
    add_alert: 'E003',
    add_box: 'E146',
    add_circle: 'E147',
    add_circle_outline: 'E148',
    add_location: 'E567',
    add_shopping_cart: 'E854',
    add_to_photos: 'E39D',
    add_to_queue: 'E05C',
    adjust: 'E39E',
    airline_seat_flat: 'E630',
    airline_seat_flat_angled: 'E631',
    airline_seat_individual_suite: 'E632',
    airline_seat_legroom_extra: 'E633',
    airline_seat_legroom_normal: 'E634',
    airline_seat_legroom_reduced: 'E635',
    airline_seat_recline_extra: 'E636',
    airline_seat_recline_normal: 'E637',
    airplanemode_active: 'E195',
    airplanemode_inactive: 'E194',
    airplay: 'E055',
    airport_shuttle: 'EB3C',
    alarm: 'E855',
    alarm_add: 'E856',
    alarm_off: 'E857',
    alarm_on: 'E858',
    album: 'E019',
    all_inclusive: 'EB3D',
    all_out: 'E90B',
    android: 'E859',
    announcement: 'E85A',
    apps: 'E5C3',
    archive: 'E149',
    arrow_back: 'E5C4',
    arrow_downward: 'E5DB',
    arrow_drop_down: 'E5C5',
    arrow_drop_down_circle: 'E5C6',
    arrow_drop_up: 'E5C7',
    arrow_forward: 'E5C8',
    arrow_upward: 'E5D8',
    art_track: 'E060',
    aspect_ratio: 'E85B',
    assessment: 'E85C',
    assignment: 'E85D',
    assignment_ind: 'E85E',
    assignment_late: 'E85F',
    assignment_return: 'E860',
    assignment_returned: 'E861',
    assignment_turned_in: 'E862',
    assistant: 'E39F',
    assistant_photo: 'E3A0',
    attach_file: 'E226',
    attach_money: 'E227',
    attachment: 'E2BC',
    audiotrack: 'E3A1',
    autorenew: 'E863',
    av_timer: 'E01B',
    backspace: 'E14A',
    backup: 'E864',
    balance: 'EAF6',
    battery_alert: 'E19C',
    battery_charging_full: 'E1A3',
    battery_full: 'E1A4',
    battery_std: 'E1A5',
    battery_unknown: 'E1A6',
    beach_access: 'EB3E',
    beenhere: 'E52D',
    block: 'E14B',
    bluetooth: 'E1A7',
    bluetooth_audio: 'E60F',
    bluetooth_connected: 'E1A8',
    bluetooth_disabled: 'E1A9',
    bluetooth_searching: 'E1AA',
    blur_circular: 'E3A2',
    blur_linear: 'E3A3',
    blur_off: 'E3A4',
    blur_on: 'E3A5',
    book: 'E865',
    bookmark: 'E866',
    bookmark_border: 'E867',
    border_all: 'E228',
    border_bottom: 'E229',
    border_clear: 'E22A',
    border_color: 'E22B',
    border_horizontal: 'E22C',
    border_inner: 'E22D',
    border_left: 'E22E',
    border_outer: 'E22F',
    border_right: 'E230',
    border_style: 'E231',
    border_top: 'E232',
    border_vertical: 'E233',
    branding_watermark: 'E06B',
    brightness_1: 'E3A6',
    brightness_2: 'E3A7',
    brightness_3: 'E3A8',
    brightness_4: 'E3A9',
    brightness_5: 'E3AA',
    brightness_6: 'E3AB',
    brightness_7: 'E3AC',
    brightness_auto: 'E1AB',
    brightness_high: 'E1AC',
    brightness_low: 'E1AD',
    brightness_medium: 'E1AE',
    broken_image: 'E3AD',
    brush: 'E3AE',
    bubble_chart: 'E6DD',
    bug_report: 'E868',
    build: 'E869',
    burst_mode: 'E43C',
    business: 'E0AF',
    business_center: 'EB3F',
    cached: 'E86A',
    cake: 'E7E9',
    call: 'E0B0',
    call_end: 'E0B1',
    call_made: 'E0B2',
    call_merge: 'E0B3',
    call_missed: 'E0B4',
    call_missed_outgoing: 'E0E4',
    call_received: 'E0B5',
    call_split: 'E0B6',
    call_to_action: 'E06C',
    camera: 'E3AF',
    camera_alt: 'E3B0',
    camera_enhance: 'E8FC',
    camera_front: 'E3B1',
    camera_rear: 'E3B2',
    camera_roll: 'E3B3',
    cancel: 'E5C9',
    card_giftcard: 'E8F6',
    card_membership: 'E8F7',
    card_travel: 'E8F8',
    casino: 'EB40',
    cast: 'E307',
    cast_connected: 'E308',
    center_focus_strong: 'E3B4',
    center_focus_weak: 'E3B5',
    change_history: 'E86B',
    chat: 'E0B7',
    chat_bubble: 'E0CA',
    chat_bubble_outline: 'E0CB',
    check: 'E5CA',
    check_box: 'E834',
    check_box_outline_blank: 'E835',
    check_circle: 'E86C',
    chevron_left: 'E5CB',
    chevron_right: 'E5CC',
    child_care: 'EB41',
    child_friendly: 'EB42',
    chrome_reader_mode: 'E86D',
    class: 'E86E',
    clear: 'E14C',
    clear_all: 'E0B8',
    close: 'E5CD',
    closed_caption: 'E01C',
    cloud: 'E2BD',
    cloud_circle: 'E2BE',
    cloud_done: 'E2BF',
    cloud_download: 'E2C0',
    cloud_off: 'E2C1',
    cloud_queue: 'E2C2',
    cloud_upload: 'E2C3',
    code: 'E86F',
    collections: 'E3B6',
    collections_bookmark: 'E431',
    color_lens: 'E3B7',
    colorize: 'E3B8',
    comment: 'E0B9',
    compare: 'E3B9',
    compare_arrows: 'E915',
    computer: 'E30A',
    confirmation_number: 'E638',
    contact_mail: 'E0D0',
    contact_phone: 'E0CF',
    contacts: 'E0BA',
    content_copy: 'E14D',
    content_cut: 'E14E',
    content_paste: 'E14F',
    control_point: 'E3BA',
    control_point_duplicate: 'E3BB',
    copyright: 'E90C',
    create: 'E150',
    create_new_folder: 'E2CC',
    credit_card: 'E870',
    crop: 'E3BE',
    crop_16_9: 'E3BC',
    crop_3_2: 'E3BD',
    crop_5_4: 'E3BF',
    crop_7_5: 'E3C0',
    crop_din: 'E3C1',
    crop_free: 'E3C2',
    crop_landscape: 'E3C3',
    crop_original: 'E3C4',
    crop_portrait: 'E3C5',
    crop_rotate: 'E437',
    crop_square: 'E3C6',
    dashboard: 'E871',
    data_usage: 'E1AF',
    date_range: 'E916',
    dehaze: 'E3C7',
    delete: 'E872',
    delete_forever: 'E92B',
    delete_sweep: 'E16C',
    description: 'E873',
    desktop_mac: 'E30B',
    desktop_windows: 'E30C',
    details: 'E3C8',
    developer_board: 'E30D',
    developer_mode: 'E1B0',
    device_hub: 'E335',
    devices: 'E1B1',
    devices_other: 'E337',
    dialer_sip: 'E0BB',
    dialpad: 'E0BC',
    directions: 'E52E',
    directions_bike: 'E52F',
    directions_boat: 'E532',
    directions_bus: 'E530',
    directions_car: 'E531',
    directions_railway: 'E534',
    directions_run: 'E566',
    directions_subway: 'E533',
    directions_transit: 'E535',
    directions_walk: 'E536',
    disc_full: 'E610',
    dns: 'E875',
    do_not_disturb: 'E612',
    do_not_disturb_alt: 'E611',
    do_not_disturb_off: 'E643',
    do_not_disturb_on: 'E644',
    dock: 'E30E',
    domain: 'E7EE',
    done: 'E876',
    done_all: 'E877',
    donut_large: 'E917',
    donut_small: 'E918',
    drafts: 'E151',
    drag_handle: 'E25D',
    drive_eta: 'E613',
    dvr: 'E1B2',
    edit: 'E3C9',
    edit_location: 'E568',
    eject: 'E8FB',
    email: 'E0BE',
    enhanced_encryption: 'E63F',
    equalizer: 'E01D',
    error: 'E000',
    error_outline: 'E001',
    euro_symbol: 'E926',
    ev_station: 'E56D',
    event: 'E878',
    event_available: 'E614',
    event_busy: 'E615',
    event_note: 'E616',
    event_seat: 'E903',
    exit_to_app: 'E879',
    expand_less: 'E5CE',
    expand_more: 'E5CF',
    explicit: 'E01E',
    explore: 'E87A',
    exposure: 'E3CA',
    exposure_neg_1: 'E3CB',
    exposure_neg_2: 'E3CC',
    exposure_plus_1: 'E3CD',
    exposure_plus_2: 'E3CE',
    exposure_zero: 'E3CF',
    extension: 'E87B',
    face: 'E87C',
    fast_forward: 'E01F',
    fast_rewind: 'E020',
    favorite: 'E87D',
    favorite_border: 'E87E',
    featured_play_list: 'E06D',
    featured_video: 'E06E',
    feedback: 'E87F',
    fiber_dvr: 'E05D',
    fiber_manual_record: 'E061',
    fiber_new: 'E05E',
    fiber_pin: 'E06A',
    fiber_smart_record: 'E062',
    file_download: 'E2C4',
    file_upload: 'E2C6',
    filter: 'E3D3',
    filter_1: 'E3D0',
    filter_2: 'E3D1',
    filter_3: 'E3D2',
    filter_4: 'E3D4',
    filter_5: 'E3D5',
    filter_6: 'E3D6',
    filter_7: 'E3D7',
    filter_8: 'E3D8',
    filter_9: 'E3D9',
    filter_9_plus: 'E3DA',
    filter_b_and_w: 'E3DB',
    filter_center_focus: 'E3DC',
    filter_drama: 'E3DD',
    filter_frames: 'E3DE',
    filter_hdr: 'E3DF',
    filter_list: 'E152',
    filter_none: 'E3E0',
    filter_tilt_shift: 'E3E2',
    filter_vintage: 'E3E3',
    find_in_page: 'E880',
    find_replace: 'E881',
    fingerprint: 'E90D',
    first_page: 'E5DC',
    fitness_center: 'EB43',
    flag: 'E153',
    flare: 'E3E4',
    flash_auto: 'E3E5',
    flash_off: 'E3E6',
    flash_on: 'E3E7',
    flight: 'E539',
    flight_land: 'E904',
    flight_takeoff: 'E905',
    flip: 'E3E8',
    flip_to_back: 'E882',
    flip_to_front: 'E883',
    folder: 'E2C7',
    folder_open: 'E2C8',
    folder_shared: 'E2C9',
    folder_special: 'E617',
    font_download: 'E167',
    format_align_center: 'E234',
    format_align_justify: 'E235',
    format_align_left: 'E236',
    format_align_right: 'E237',
    format_bold: 'E238',
    format_clear: 'E239',
    format_color_fill: 'E23A',
    format_color_reset: 'E23B',
    format_color_text: 'E23C',
    format_indent_decrease: 'E23D',
    format_indent_increase: 'E23E',
    format_italic: 'E23F',
    format_line_spacing: 'E240',
    format_list_bulleted: 'E241',
    format_list_numbered: 'E242',
    format_paint: 'E243',
    format_quote: 'E244',
    format_shapes: 'E25E',
    format_size: 'E245',
    format_strikethrough: 'E246',
    format_textdirection_l_to_r: 'E247',
    format_textdirection_r_to_l: 'E248',
    format_underlined: 'E249',
    forum: 'E0BF',
    forward: 'E154',
    forward_10: 'E056',
    forward_30: 'E057',
    forward_5: 'E058',
    free_breakfast: 'EB44',
    fullscreen: 'E5D0',
    fullscreen_exit: 'E5D1',
    functions: 'E24A',
    g_translate: 'E927',
    gamepad: 'E30F',
    games: 'E021',
    gavel: 'E90E',
    gesture: 'E155',
    get_app: 'E884',
    gif: 'E908',
    golf_course: 'EB45',
    gps_fixed: 'E1B3',
    gps_not_fixed: 'E1B4',
    gps_off: 'E1B5',
    grade: 'E885',
    gradient: 'E3E9',
    grain: 'E3EA',
    graphic_eq: 'E1B8',
    grid_off: 'E3EB',
    grid_on: 'E3EC',
    group: 'E7EF',
    group_add: 'E7F0',
    group_work: 'E886',
    hd: 'E052',
    hdr_off: 'E3ED',
    hdr_on: 'E3EE',
    hdr_strong: 'E3F1',
    hdr_weak: 'E3F2',
    headset: 'E310',
    headset_mic: 'E311',
    healing: 'E3F3',
    hearing: 'E023',
    help: 'E887',
    help_outline: 'E8FD',
    high_quality: 'E024',
    highlight: 'E25F',
    highlight_off: 'E888',
    history: 'E889',
    home: 'E88A',
    hot_tub: 'EB46',
    hotel: 'E53A',
    hourglass_empty: 'E88B',
    hourglass_full: 'E88C',
    http: 'E902',
    https: 'E88D',
    image: 'E3F4',
    image_aspect_ratio: 'E3F5',
    import_contacts: 'E0E0',
    import_export: 'E0C3',
    important_devices: 'E912',
    inbox: 'E156',
    indeterminate_check_box: 'E909',
    info: 'E88E',
    info_outline: 'E88F',
    input: 'E890',
    insert_chart: 'E24B',
    insert_comment: 'E24C',
    insert_drive_file: 'E24D',
    insert_emoticon: 'E24E',
    insert_invitation: 'E24F',
    insert_link: 'E250',
    insert_photo: 'E251',
    invert_colors: 'E891',
    invert_colors_off: 'E0C4',
    iso: 'E3F6',
    keyboard: 'E312',
    keyboard_arrow_down: 'E313',
    keyboard_arrow_left: 'E314',
    keyboard_arrow_right: 'E315',
    keyboard_arrow_up: 'E316',
    keyboard_backspace: 'E317',
    keyboard_capslock: 'E318',
    keyboard_hide: 'E31A',
    keyboard_return: 'E31B',
    keyboard_tab: 'E31C',
    keyboard_voice: 'E31D',
    kitchen: 'EB47',
    label: 'E892',
    label_outline: 'E893',
    landscape: 'E3F7',
    language: 'E894',
    laptop: 'E31E',
    laptop_chromebook: 'E31F',
    laptop_mac: 'E320',
    laptop_windows: 'E321',
    last_page: 'E5DD',
    launch: 'E895',
    layers: 'E53B',
    layers_clear: 'E53C',
    leak_add: 'E3F8',
    leak_remove: 'E3F9',
    lens: 'E3FA',
    library_add: 'E02E',
    library_books: 'E02F',
    library_music: 'E030',
    lightbulb_outline: 'E90F',
    line_style: 'E919',
    line_weight: 'E91A',
    linear_scale: 'E260',
    link: 'E157',
    linked_camera: 'E438',
    list: 'E896',
    live_help: 'E0C6',
    live_tv: 'E639',
    local_activity: 'E53F',
    local_airport: 'E53D',
    local_atm: 'E53E',
    local_bar: 'E540',
    local_cafe: 'E541',
    local_car_wash: 'E542',
    local_convenience_store: 'E543',
    local_dining: 'E556',
    local_drink: 'E544',
    local_florist: 'E545',
    local_gas_station: 'E546',
    local_grocery_store: 'E547',
    local_hospital: 'E548',
    local_hotel: 'E549',
    local_laundry_service: 'E54A',
    local_library: 'E54B',
    local_mall: 'E54C',
    local_movies: 'E54D',
    local_offer: 'E54E',
    local_parking: 'E54F',
    local_pharmacy: 'E550',
    local_phone: 'E551',
    local_pizza: 'E552',
    local_play: 'E553',
    local_post_office: 'E554',
    local_printshop: 'E555',
    local_see: 'E557',
    local_shipping: 'E558',
    local_taxi: 'E559',
    location_city: 'E7F1',
    location_disabled: 'E1B6',
    location_off: 'E0C7',
    location_on: 'E0C8',
    location_searching: 'E1B7',
    lock: 'E897',
    lock_open: 'E898',
    lock_outline: 'E899',
    looks: 'E3FC',
    looks_3: 'E3FB',
    looks_4: 'E3FD',
    looks_5: 'E3FE',
    looks_6: 'E3FF',
    looks_one: 'E400',
    looks_two: 'E401',
    loop: 'E028',
    loupe: 'E402',
    low_priority: 'E16D',
    loyalty: 'E89A',
    mail: 'E158',
    mail_outline: 'E0E1',
    map: 'E55B',
    markunread: 'E159',
    markunread_mailbox: 'E89B',
    memory: 'E322',
    menu: 'E5D2',
    merge_type: 'E252',
    message: 'E0C9',
    mic: 'E029',
    mic_none: 'E02A',
    mic_off: 'E02B',
    mms: 'E618',
    mode_comment: 'E253',
    mode_edit: 'E254',
    monetization_on: 'E263',
    money_off: 'E25C',
    monochrome_photos: 'E403',
    mood: 'E7F2',
    mood_bad: 'E7F3',
    more: 'E619',
    more_horiz: 'E5D3',
    more_vert: 'E5D4',
    motorcycle: 'E91B',
    mouse: 'E323',
    move_to_inbox: 'E168',
    movie: 'E02C',
    movie_creation: 'E404',
    movie_filter: 'E43A',
    multiline_chart: 'E6DF',
    music_note: 'E405',
    music_video: 'E063',
    my_location: 'E55C',
    nature: 'E406',
    nature_people: 'E407',
    navigate_before: 'E408',
    navigate_next: 'E409',
    navigation: 'E55D',
    near_me: 'E569',
    network_cell: 'E1B9',
    network_check: 'E640',
    network_locked: 'E61A',
    network_wifi: 'E1BA',
    new_releases: 'E031',
    next_week: 'E16A',
    nfc: 'E1BB',
    no_encryption: 'E641',
    no_sim: 'E0CC',
    not_interested: 'E033',
    note: 'E06F',
    note_add: 'E89C',
    notifications: 'E7F4',
    notifications_active: 'E7F7',
    notifications_none: 'E7F5',
    notifications_off: 'E7F6',
    notifications_paused: 'E7F8',
    offline_pin: 'E90A',
    ondemand_video: 'E63A',
    opacity: 'E91C',
    open_in_browser: 'E89D',
    open_in_new: 'E89E',
    open_with: 'E89F',
    pages: 'E7F9',
    pageview: 'E8A0',
    palette: 'E40A',
    pan_tool: 'E925',
    panorama: 'E40B',
    panorama_fish_eye: 'E40C',
    panorama_horizontal: 'E40D',
    panorama_vertical: 'E40E',
    panorama_wide_angle: 'E40F',
    party_mode: 'E7FA',
    pause: 'E034',
    pause_circle_filled: 'E035',
    pause_circle_outline: 'E036',
    payment: 'E8A1',
    people: 'E7FB',
    people_outline: 'E7FC',
    perm_camera_mic: 'E8A2',
    perm_contact_calendar: 'E8A3',
    perm_data_setting: 'E8A4',
    perm_device_information: 'E8A5',
    perm_identity: 'E8A6',
    perm_media: 'E8A7',
    perm_phone_msg: 'E8A8',
    perm_scan_wifi: 'E8A9',
    person: 'E7FD',
    person_add: 'E7FE',
    person_outline: 'E7FF',
    person_pin: 'E55A',
    person_pin_circle: 'E56A',
    personal_video: 'E63B',
    pets: 'E91D',
    phone: 'E0CD',
    phone_android: 'E324',
    phone_bluetooth_speaker: 'E61B',
    phone_forwarded: 'E61C',
    phone_in_talk: 'E61D',
    phone_iphone: 'E325',
    phone_locked: 'E61E',
    phone_missed: 'E61F',
    phone_paused: 'E620',
    phonelink: 'E326',
    phonelink_erase: 'E0DB',
    phonelink_lock: 'E0DC',
    phonelink_off: 'E327',
    phonelink_ring: 'E0DD',
    phonelink_setup: 'E0DE',
    photo: 'E410',
    photo_album: 'E411',
    photo_camera: 'E412',
    photo_filter: 'E43B',
    photo_library: 'E413',
    photo_size_select_actual: 'E432',
    photo_size_select_large: 'E433',
    photo_size_select_small: 'E434',
    picture_as_pdf: 'E415',
    picture_in_picture: 'E8AA',
    picture_in_picture_alt: 'E911',
    pie_chart: 'E6C4',
    pie_chart_outlined: 'E6C5',
    pin_drop: 'E55E',
    place: 'E55F',
    play_arrow: 'E037',
    play_circle_filled: 'E038',
    play_circle_outline: 'E039',
    play_for_work: 'E906',
    playlist_add: 'E03B',
    playlist_add_check: 'E065',
    playlist_play: 'E05F',
    plus_one: 'E800',
    poll: 'E801',
    polymer: 'E8AB',
    pool: 'EB48',
    portable_wifi_off: 'E0CE',
    portrait: 'E416',
    power: 'E63C',
    power_input: 'E336',
    power_settings_new: 'E8AC',
    pregnant_woman: 'E91E',
    present_to_all: 'E0DF',
    print: 'E8AD',
    priority_high: 'E645',
    public: 'E80B',
    publish: 'E255',
    query_builder: 'E8AE',
    question_answer: 'E8AF',
    queue: 'E03C',
    queue_music: 'E03D',
    queue_play_next: 'E066',
    radio: 'E03E',
    radio_button_checked: 'E837',
    radio_button_unchecked: 'E836',
    rate_review: 'E560',
    receipt: 'E8B0',
    recent_actors: 'E03F',
    record_voice_over: 'E91F',
    redeem: 'E8B1',
    redo: 'E15A',
    refresh: 'E5D5',
    remove: 'E15B',
    remove_circle: 'E15C',
    remove_circle_outline: 'E15D',
    remove_from_queue: 'E067',
    remove_red_eye: 'E417',
    remove_shopping_cart: 'E928',
    reorder: 'E8FE',
    repeat: 'E040',
    repeat_one: 'E041',
    replay: 'E042',
    replay_10: 'E059',
    replay_30: 'E05A',
    replay_5: 'E05B',
    reply: 'E15E',
    reply_all: 'E15F',
    report: 'E160',
    report_problem: 'E8B2',
    restaurant: 'E56C',
    restaurant_menu: 'E561',
    restore: 'E8B3',
    restore_page: 'E929',
    ring_volume: 'E0D1',
    room: 'E8B4',
    room_service: 'EB49',
    rotate_90_degrees_ccw: 'E418',
    rotate_left: 'E419',
    rotate_right: 'E41A',
    rounded_corner: 'E920',
    router: 'E328',
    rowing: 'E921',
    rss_feed: 'E0E5',
    rv_hookup: 'E642',
    satellite: 'E562',
    save: 'E161',
    scanner: 'E329',
    schedule: 'E8B5',
    school: 'E80C',
    screen_lock_landscape: 'E1BE',
    screen_lock_portrait: 'E1BF',
    screen_lock_rotation: 'E1C0',
    screen_rotation: 'E1C1',
    screen_share: 'E0E2',
    sd_card: 'E623',
    sd_storage: 'E1C2',
    search: 'E8B6',
    security: 'E32A',
    select_all: 'E162',
    send: 'E163',
    sentiment_dissatisfied: 'E811',
    sentiment_neutral: 'E812',
    sentiment_satisfied: 'E813',
    sentiment_very_dissatisfied: 'E814',
    sentiment_very_satisfied: 'E815',
    settings: 'E8B8',
    settings_applications: 'E8B9',
    settings_backup_restore: 'E8BA',
    settings_bluetooth: 'E8BB',
    settings_brightness: 'E8BD',
    settings_cell: 'E8BC',
    settings_ethernet: 'E8BE',
    settings_input_antenna: 'E8BF',
    settings_input_component: 'E8C0',
    settings_input_composite: 'E8C1',
    settings_input_hdmi: 'E8C2',
    settings_input_svideo: 'E8C3',
    settings_overscan: 'E8C4',
    settings_phone: 'E8C5',
    settings_power: 'E8C6',
    settings_remote: 'E8C7',
    settings_system_daydream: 'E1C3',
    settings_voice: 'E8C8',
    share: 'E80D',
    shop: 'E8C9',
    shop_two: 'E8CA',
    shopping_basket: 'E8CB',
    shopping_cart: 'E8CC',
    short_text: 'E261',
    show_chart: 'E6E1',
    shuffle: 'E043',
    signal_cellular_4_bar: 'E1C8',
    signal_cellular_connected_no_internet_4_bar: 'E1CD',
    signal_cellular_no_sim: 'E1CE',
    signal_cellular_null: 'E1CF',
    signal_cellular_off: 'E1D0',
    signal_wifi_4_bar: 'E1D8',
    signal_wifi_4_bar_lock: 'E1D9',
    signal_wifi_off: 'E1DA',
    sim_card: 'E32B',
    sim_card_alert: 'E624',
    skip_next: 'E044',
    skip_previous: 'E045',
    slideshow: 'E41B',
    slow_motion_video: 'E068',
    smartphone: 'E32C',
    smoke_free: 'EB4A',
    smoking_rooms: 'EB4B',
    sms: 'E625',
    sms_failed: 'E626',
    snooze: 'E046',
    sort: 'E164',
    sort_by_alpha: 'E053',
    spa: 'EB4C',
    space_bar: 'E256',
    speaker: 'E32D',
    speaker_group: 'E32E',
    speaker_notes: 'E8CD',
    speaker_notes_off: 'E92A',
    speaker_phone: 'E0D2',
    spellcheck: 'E8CE',
    star: 'E838',
    star_border: 'E83A',
    star_half: 'E839',
    stars: 'E8D0',
    stay_current_landscape: 'E0D3',
    stay_current_portrait: 'E0D4',
    stay_primary_landscape: 'E0D5',
    stay_primary_portrait: 'E0D6',
    stop: 'E047',
    stop_screen_share: 'E0E3',
    storage: 'E1DB',
    store: 'E8D1',
    store_mall_directory: 'E563',
    straighten: 'E41C',
    streetview: 'E56E',
    strikethrough_s: 'E257',
    style: 'E41D',
    subdirectory_arrow_left: 'E5D9',
    subdirectory_arrow_right: 'E5DA',
    subject: 'E8D2',
    subscriptions: 'E064',
    subtitles: 'E048',
    subway: 'E56F',
    supervisor_account: 'E8D3',
    surround_sound: 'E049',
    swap_calls: 'E0D7',
    swap_horiz: 'E8D4',
    swap_vert: 'E8D5',
    swap_vertical_circle: 'E8D6',
    switch_camera: 'E41E',
    switch_video: 'E41F',
    sync: 'E627',
    sync_disabled: 'E628',
    sync_problem: 'E629',
    system_update: 'E62A',
    system_update_alt: 'E8D7',
    tab: 'E8D8',
    tab_unselected: 'E8D9',
    tablet: 'E32F',
    tablet_android: 'E330',
    tablet_mac: 'E331',
    tag_faces: 'E420',
    tap_and_play: 'E62B',
    terrain: 'E564',
    text_fields: 'E262',
    text_format: 'E165',
    textsms: 'E0D8',
    texture: 'E421',
    theaters: 'E8DA',
    thumb_down: 'E8DB',
    thumb_up: 'E8DC',
    thumbs_up_down: 'E8DD',
    time_to_leave: 'E62C',
    timelapse: 'E422',
    timeline: 'E922',
    timer: 'E425',
    timer_10: 'E423',
    timer_3: 'E424',
    timer_off: 'E426',
    title: 'E264',
    toc: 'E8DE',
    today: 'E8DF',
    toll: 'E8E0',
    tonality: 'E427',
    touch_app: 'E913',
    toys: 'E332',
    track_changes: 'E8E1',
    traffic: 'E565',
    train: 'E570',
    tram: 'E571',
    transfer_within_a_station: 'E572',
    transform: 'E428',
    translate: 'E8E2',
    trending_down: 'E8E3',
    trending_flat: 'E8E4',
    trending_up: 'E8E5',
    tune: 'E429',
    turned_in: 'E8E6',
    turned_in_not: 'E8E7',
    tv: 'E333',
    unarchive: 'E169',
    undo: 'E166',
    unfold_less: 'E5D6',
    unfold_more: 'E5D7',
    update: 'E923',
    usb: 'E1E0',
    verified_user: 'E8E8',
    vertical_align_bottom: 'E258',
    vertical_align_center: 'E259',
    vertical_align_top: 'E25A',
    vibration: 'E62D',
    video_call: 'E070',
    video_label: 'E071',
    video_library: 'E04A',
    videocam: 'E04B',
    videocam_off: 'E04C',
    videogame_asset: 'E338',
    view_agenda: 'E8E9',
    view_array: 'E8EA',
    view_carousel: 'E8EB',
    view_column: 'E8EC',
    view_comfy: 'E42A',
    view_compact: 'E42B',
    view_day: 'E8ED',
    view_headline: 'E8EE',
    view_list: 'E8EF',
    view_module: 'E8F0',
    view_quilt: 'E8F1',
    view_stream: 'E8F2',
    view_week: 'E8F3',
    vignette: 'E435',
    visibility: 'E8F4',
    visibility_off: 'E8F5',
    voice_chat: 'E62E',
    voicemail: 'E0D9',
    volume_down: 'E04D',
    volume_mute: 'E04E',
    volume_off: 'E04F',
    volume_up: 'E050',
    vpn_key: 'E0DA',
    vpn_lock: 'E62F',
    wallpaper: 'E1BC',
    warning: 'E002',
    watch: 'E334',
    watch_later: 'E924',
    wb_auto: 'E42C',
    wb_cloudy: 'E42D',
    wb_incandescent: 'E42E',
    wb_iridescent: 'E436',
    wb_sunny: 'E430',
    wc: 'E63D',
    web: 'E051',
    web_asset: 'E069',
    weekend: 'E16B',
    whatshot: 'E80E',
    widgets: 'E1BD',
    wifi: 'E63E',
    wifi_lock: 'E1E1',
    wifi_tethering: 'E1E2',
    work: 'E8F9',
    wrap_text: 'E25B',
    youtube_searched_for: 'E8FA',
    zoom_in: 'E8FF',
    zoom_out: 'E900',
    zoom_out_map: 'E56B',
}
