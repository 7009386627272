import styled from '@emotion/styled'
import { JSX, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { getJSONItemFromLocalStorage, setJSONItemToLocalStorage } from '../generic-utilities/StorageUtils'
import { clamp } from 'lodash-es'

type Props = {
    dialog: HTMLDivElement | null
    onPositionChange: ({ top, left }: { top: number; left: number }) => void
    storageId?: string
}

const DragArea = styled.div`
    cursor: move;
    width: 100%;
    height: 70px;
    position: absolute;
`

const MovableDialog = ({ dialog, storageId, onPositionChange, children }: PropsWithChildren<Props>): JSX.Element => {
    const dragAreaRef = useRef<HTMLDivElement | null>(null)

    const [dragging, setDragging] = useState<boolean>(false)
    const [startPos, setStartPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 })

    const repositionDialog = useCallback(
        (initialX: number, initialY: number) => {
            if (!dialog) {
                return
            }

            const { width, height } = dialog.getBoundingClientRect()

            const boundaries = {
                top: 0,
                left: 0,
                right: window.innerWidth - width,
                bottom: window.innerHeight - height,
            }

            const newX = clamp(initialX, boundaries.left, boundaries.right)
            const newY = clamp(initialY, boundaries.top, boundaries.bottom)

            onPositionChange({ left: newX, top: newY })
        },
        [dialog, onPositionChange]
    )

    const onDragStart = useCallback(
        (event: PointerEvent) => {
            setDragging(true)

            if (!dialog) {
                return
            }

            const { clientX, clientY } = event
            const { left, top } = dialog.getBoundingClientRect()

            setStartPos({ x: clientX - left, y: clientY - top })
        },
        [dialog]
    )

    const onDragEnd = useCallback(() => {
        if (!dialog || !storageId || !dragging) {
            return
        }

        setDragging(false)

        const { left, top } = dialog.getBoundingClientRect()

        const currentPositions =
            getJSONItemFromLocalStorage<Record<string, { top: number; left: number }>>('dialogPosition')

        setJSONItemToLocalStorage('dialogPosition', { ...currentPositions, [storageId]: { top, left } })
    }, [dialog, dragging, storageId])

    const onMouseMove = useCallback(
        (event: PointerEvent) => {
            if (!dragging || !dialog) {
                return
            }

            const { clientX, clientY } = event

            const newX = clientX - startPos.x
            const newY = clientY - startPos.y

            repositionDialog(newX, newY)
        },
        [dialog, dragging, repositionDialog, startPos.x, startPos.y]
    )

    // reposition dialog back into bounds if window size has changed
    useEffect(() => {
        if (!dialog) {
            return
        }

        const { left, top } = dialog.getBoundingClientRect()

        repositionDialog(left, top)
    }, [dialog, repositionDialog])

    useEffect(() => {
        const element = dragAreaRef.current

        if (!element) {
            return
        }

        const onSelect = (event: PointerEvent) => {
            if (dragging) {
                event.preventDefault()
            }
        }

        element.addEventListener('mousedown', onDragStart)

        document.addEventListener('mouseup', onDragEnd)
        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('selectstart', onSelect)

        return () => {
            element.removeEventListener('mousedown', onDragStart)

            document.removeEventListener('mouseup', onDragEnd)
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('selectstart', onSelect)
        }
    }, [dragging, onDragEnd, onDragStart, onMouseMove])

    return (
        <>
            <DragArea ref={dragAreaRef} />
            {children}
        </>
    )
}

export default MovableDialog
