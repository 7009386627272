import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { useValuePickerValuesChanged } from '../../value-picker'
import { fetchDataSourceDataThunk } from '../Thunks/DataSourceThunks'
import { selectAreDataSourcesInitialized } from '../State/DataSourceSelectors'

const useDataFetchAtValuePickerValueChange = (dataSourceIds: string | string[], valuePickerIds: string[]): void => {
    const dispatch = useDispatch()
    const valuePickersValuesChanged = useValuePickerValuesChanged(valuePickerIds)

    const dataSourceInArray = Array.isArray(dataSourceIds) ? dataSourceIds : [dataSourceIds]

    const dataSourcesInitialized = useSelector<RootState>((state) =>
        selectAreDataSourcesInitialized(state, dataSourceInArray)
    )

    useEffect(() => {
        if (!valuePickersValuesChanged || !dataSourcesInitialized) {
            return
        }

        dataSourceInArray.forEach((dataSourceId) => {
            dispatch(fetchDataSourceDataThunk(dataSourceId))
        })
    })
}

export default useDataFetchAtValuePickerValueChange
