import * as React from 'react'

import styled from '@emotion/styled'

import { Translation } from '../../localization'
import { AlertBox } from '../../generic-components'
import { alert_10, alert_100 } from 'constants/Styles'

const ErrorAlert = styled(AlertBox)`
    && {
        position: absolute;
        bottom: 82px;
        left: 0;
        width: 100%;
        background-color: ${alert_10};
        color: ${alert_100};
        border-radius: 5px;
        border-top: 5px solid ${alert_100};
        height: 60px;
        font-size: 14px;
        font-weight: 500;
    }
`

const Placeholder = styled.div`
    height: 60px;
    width: 100px;
`

interface IOwnProps {
    ShowError: boolean
}

const FormErrorAlert: React.FC<IOwnProps> = ({ ShowError }) => {
    return ShowError ? (
        <ErrorAlert severity="error">{Translation.translateKey('form-view.ErrorAlert.Label')}</ErrorAlert>
    ) : (
        <Placeholder />
    )
}

export default FormErrorAlert
