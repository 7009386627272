// Legacy komponenttien teemat joille ei uudistetuissa brändi designeissa löydy vastinkappaletta
import { legacyCalendarTheme, LegacyCalendarThemeOptions } from './LegacyCalendarTheme'
import { legacyComponentStyles, LegacyComponentStylesOptions } from './LegacyComponentStyles'
import { legacySupplementaryColors, LegacySupplementaryColorsOptions } from './LegacySupplementaryColors'

type TextTransform = 'uppercase' | 'lowercase' | 'capitalize' | 'none'

// Mepco Light Theme
const colors = {
    neutralsGrey11: '#e8e8e8',
    neutralsGrey30: '#bfbfbf',
    neutralsGrey60: '#7f7f7f',
    neutralsGrey65: '#747474',
    neutralsGrey100: '#2a2a2a',
    neutralsGrey140: '#191919',
    neutralsWhite100: '#ffffff',
    pacificCyan30: '#b7e8f2',
    pacificCyan100: '#0eb1d2',
    pacificCyan120: '#086a7e',
    primaryTeal11: '#e3f0f1',
    primaryTeal30: '#b3d7d8',
    primaryTeal60: '#68aeb0',
    primaryTeal100: '#03787c',
    primaryTeal120: '#026063',
    primaryTeal140: '#02484a',
    primaryCyan11: '#EFFAF9',
    primaryCyan30: '#d4f0ef',
    primaryCyan60: '#a9e1df',
    primaryCyan100: '#70cdc9',
    primaryCyan120: '#5aa4a1',
    primaryChampion11: '#f8f6f6',
    primaryChampion30: '#ede6e6',
    primaryChampion60: '#dbcccd',
    primaryChampion100: '#c3aaac',
    secondaryPink30: '#fddce0',
    secondaryPink60: '#fbb9c1',
    secondaryPink100: '#f98a97',
    semanticWarning11: '#f9e8e8',
    secondaryYellow11: '#fff9e3',
    secondaryYellow30: '#ffefb4',
    secondaryYellow60: '#ffe069',
    secondaryYellow100: '#ffcb05',
    secondaryYellow120: '#cca204',
    semanticSuccess11: '#e3f0ee',
    semanticSuccess60: '#66afa0',
    semanticSuccess100: '#007a60',
    semanticSuccess120: '#00624d',
    semanticSuccess140: '#00493a',
    semanticWarning30: '#eec0c0',
    semanticWarning60: '#dd8080',
    semanticWarning100: '#c72c2c',
    semanticWarning120: '#9f2323',
    semanticWarning140: '#771a1a',
}

const eventTemplates = {
    primary: {
        event1: '#3e3474',
        event2: '#506a99',
        event3: '#906800',
        event4: '#983c52',
        event5: '#007a60',
        event6: '#c72c2c',
    },
    secondary: {
        event1: '#eeecf8',
        event2: '#f2f6ff',
        event3: '#fff9e3',
        event4: '#fef2f4',
        event5: '#effaf9',
        event6: '#f8f6f6',
    },
    preview: {
        event1: '#6856c2',
        event2: '#506a99',
        event3: '#cca204',
        event4: '#be4b66',
        event5: '#66afa0',
        event6: '#c72c2c',
    },
}

const componentExtensions = {
    global: {
        bgPrimary: '#ffffff',
        action: '#026063',
        error: '#c72c2c',
        success: '#007a60',
    },
    cards: {
        bgCard: '#f8f6f6',
        bgCardDark: '#dbcccd',
    },
    text: {
        primary: '#2a2a2a',
        primaryAlt: '#ffffff',
        disabledDarker: '#7f7f7f',
        disabledLighter: '#bfbfbf',
        action: '#026063',
    },
    border: {
        primary: '#7f7f7f',
        secondary: '#bfbfbf',
    },
    buttons: {
        bgButtonPrimaryDefault: '#026063',
        bgButtonPrimaryHover: '#a9e1df',
        bgButtonPrimaryDisabled: '#bfbfbf',
        bgButtonPrimaryActive: '#b3d7d8',
        bgButtonDestructiveHover: '#c72c2c',
        bgButtonDestructiveDefault: '#ffcb05',
        borderButtonSecondaryDisabled: '#bfbfbf',
        bgButtonDestructiveActive: '#eec0c0',
        borderButtonSecdestructiveDefault: '#c72c2c',
        textButtonPrimaryActive: '#026063',
        textButtonSecondaryDisabled: '#bfbfbf',
        textButtonDestructiveDefault: '#2a2a2a',
        textButtonDestructiveHover: '#ffffff',
        textButtonDestructiveActive: '#c72c2c',
        textButtonSecdestructiveDefault: '#c72c2c',
        borderButtonSecondaryDefault: '#026063',
        bgButtonSecondaryHover: '#a9e1df',
        bgButtonSecondaryActive: '#b3d7d8',
        bgButtonTertiaryHover: '#ffffff',
        bgButtonTertiaryActive: '#b3d7d8',
        bgButtonDestructiveDisabled: '#bfbfbf',
        borderButtonSecdestructiveDisabled: '#bfbfbf',
        bgButtonSecdestructiveActive: '#eec0c0',
        borderButtonFocus: '#026063',
        bgButtonSecdestructiveHover: '#c72c2c',
        textButtonPrimaryDefault: '#ffffff',
        textButtonPrimaryHover: '#026063',
        textButtonPrimaryDisabled: '#ffffff',
        textButtonSecondaryDefault: '#026063',
        textButtonSecondaryHover: '#026063',
        textButtonSecondaryActive: '#026063',
        textButtonTertiaryDefault: '#026063',
        textButtonTertiaryHover: '#026063',
        textButtonTertiaryDisabled: '#bfbfbf',
        textButtonTertiaryActive: '#026063',
        textButtonDestructiveDisabled: '#ffffff',
        textButtonSecdestructiveHover: '#ffffff',
        textButtonSecdestructiveDisabled: '#bfbfbf',
        textButtonSecdestructiveActive: '#c72c2c',
        bgButtonTertdestructiveHover: '#c72c2c',
        bgButtonTertdestructiveActive: '#eec0c0',
        textButtonTertdestructiveDefault: '#c72c2c',
        textButtonTertdestructiveHover: '#ffffff',
        textButtonTertdestructiveDisabled: '#bfbfbf',
        textButtonTertdestructiveActive: '#c72c2c',
    },
    icons: {
        iconAction: '#026063',
        iconAlt: '#ffffff',
        iconWarning: '#c72c2c',
        iconDisabledDarker: '#7f7f7f',
        iconDisabledLighter: '#bfbfbf',
        iconSuccess: '#007a60',
        iconNeutral: '#2a2a2a',
        iconIllustrative: '#c3aaac',
    },
    inputs: {
        bgInputDefault: '#ffffff',
        borderInputDefault: '#7f7f7f',
        textInputDefault: '#2a2a2a',
        textInputHelperDefault: '#2a2a2a',
        borderInputFocus: '#026063',
        iconInputDefault: '#026063',
        borderInputError: '#c72c2c',
        iconInputError: '#c72c2c',
        colorTextInputHelperError: '#c72c2c',
        bgInputDisabled: '#f8f6f6',
        borderInputDisabled: '#bfbfbf',
        iconInputDisabled: '#7f7f7f',
        textInputDisabled: '#7f7f7f',
        textInputHelperDisabled: '#7f7f7f',
        bgInputDropdownHover: '#ede6e6',
        textInputRequired: '#c72c2c',
    },
}

const tokens = {
    radiusDefault: '8px',
    radiusSmall: '4px',
    radiusButtonXs: '12px',
    radiusButtonS: '16px',
    radiusButtonM: '22px',
    radiusButtonL: '26px',
    radiusButtonXl: '31px',
    buttonMinWidthXs: 48,
    buttonMinWidthS: 64,
    buttonMinWidthM: 88,
    buttonMinWidthL: 104,
    buttonMinWidthXl: 124,
    transitionQuick: '250ms ease-in-out',
}

const typography = {
    dialogHeader: {
        color: '#2A2A2A',
        fontFamily: 'Fabrik, arial',
        fontSize: '18px',
        fontWeight: 700,
        letterSpacing: 0,
        lineHeight: '23px',
    },
    listHeader: {
        fontFamily: 'Inter, arial',
        fontWeight: 600,
        color: '#2A2A2A',
        letterSpacing: 0,
        lineHeight: '18px',
        fontSize: '14px',
    },
    listElement: {
        fontFamily: 'Inter, arial',
        fontWeight: 'normal',
        color: '#2A2A2A',
        letterSpacing: 0,
        lineHeight: '18px',
        fontSize: '14px',
    },
    h1: {
        fontFamily: 'Fabrik, arial',
        fontSize: '24px',
        lineHeight: '32px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        color: '#2A2A2A',
    },
    h2: {
        fontFamily: 'Fabrik, arial',
        fontSize: '22px',
        lineHeight: '22px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        color: '#2A2A2A',
    },
    h3: {
        fontFamily: 'Fabrik, arial',
        fontSize: '20px',
        lineHeight: '26px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        color: '#2A2A2A',
    },
    h4: {
        fontFamily: 'Fabrik, arial',
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        color: '#2A2A2A',
    },
    h5: {
        fontFamily: 'Inter, arial',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 600,
        fontStyle: 'normal',
        color: '#2A2A2A',
    },
    h6: {
        fontFamily: 'Inter, arial',
        fontSize: '12px',
        lineHeight: '20px',
        fontWeight: 'normal',
        color: '#2A2A2A',
    },
    subtitle1: {
        fontFamily: 'Inter, arial',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        color: '#2A2A2A',
    },
    subtitle2: {
        fontFamily: 'Inter, arial',
        fontSize: '15px',
        lineHeight: '15px',
        fontWeight: 700,
        fontStyle: 'italic',
        color: '#2A2A2A',
    },
    bodyM: {
        fontFamily: 'Inter, arial',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        color: '#2A2A2A',
    },
    bodyS: {
        fontFamily: 'Inter, arial',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
        color: '#2A2A2A',
    },
    bodyXS: {
        fontFamily: 'Inter, arial',
        fontSize: '12px',
        lineHeight: '18px',
        fontWeight: 400,
        color: '#2A2A2A',
    },
    caption: {
        fontFamily: 'Inter, arial',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        color: '#2A2A2A',
    },
    button: {
        fontFamily: 'Inter, arial',
        fontSize: '16px',
        color: '#2A2A2A',
        lineHeight: '21px',
        letterSpacing: 0,
        textTransform: 'none' as TextTransform,
    },
}

const typographyExtensions = {
    dropdown_label: {
        fontFamily: 'Inter, arial',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#2A2A2A',
    },
    dropdown_selection: {
        fontFamily: 'Inter, arial',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#2a2a2a',
    },
    dropdown_placeholder: {
        fontFamily: 'Inter, arial',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        color: '#7f7f7f',
    },
    list_header: {
        fontFamily: 'Inter, arial',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#2A2A2A',
    },
    list_data: {
        fontFamily: 'Inter, arial',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#2A2A2A',
    },
    list_link: {
        fontFamily: 'Inter, arial',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#026063',
    },
    event_block: {
        fontFamily: 'Inter, arial',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '10px',
        lineHeight: '13px',
        color: '#026063',
    },
    h7: {
        fontFamily: 'Fabrik, arial',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#2A2A2A',
    },
    h8: {
        fontFamily: 'Fabrik, arial',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '22px',
        color: '#2A2A2A',
    },
    legacyListElement: {
        fontFamily: 'Inter, arial',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#2A2A2A',
    },
    smallbutton: {
        fontFamily: 'Inter, arial',
        fontSize: '14px',
        color: '#2A2A2A',
        lineHeight: '22px',
        textTransform: 'uppercase',
    },
    linkM: {
        fontFamily: 'Inter, arial',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 400,
        color: componentExtensions.text.action,
        textDecoration: 'underline',
    },
    list_element_link: {
        fontFamily: 'Open Sans',
        textDecoration: 'underline',
        color: '#026063',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
    },
}

const MepcoLightTheme = {
    typography,
    typographyExtensions,
    colors,
    componentExtensions,
    tokens,
    eventTemplates,
    legacyCalendarTheme,
    legacyComponentStyles,
    legacySupplementaryColors,
}

export type ColorOptions = typeof colors
export type ComponentExtensionsOptions = typeof componentExtensions
export type TokenOptions = typeof tokens
export type TypographyOptions = typeof typography
export type TypographyExtensionsOptions = typeof typographyExtensions
export type EventTemplatesOptions = typeof eventTemplates

type LightTheme = {
    typography: TypographyOptions
    typographyExtensions: TypographyExtensionsOptions
    colors: ColorOptions
    componentExtensions: ComponentExtensionsOptions
    tokens: TokenOptions
    eventTemplates: EventTemplatesOptions
    legacyCalendarTheme: LegacyCalendarThemeOptions
    legacyComponentStyles: LegacyComponentStylesOptions
    legacySupplementaryColors: LegacySupplementaryColorsOptions
}

export default MepcoLightTheme as LightTheme
