import { FC } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import { Tooltip } from '../../generic-components'

interface IGroupCountersProps {
    counters: string[] | null
}

const StyledTypography = styled(Typography)`
    min-width: 30px;
`

/**
 * TEMPORARY solution to make counters for the groups work for now
 */
const GroupCounters: FC<IGroupCountersProps> = ({ counters }) => {
    if (!counters || counters.length === 0) {
        return null
    }

    const countersLinedUp = counters.join('<br />')

    return (
        <Tooltip childWrapperElement={null} title={countersLinedUp}>
            <StyledTypography display="block" noWrap variant="caption">
                {counters[0]}
            </StyledTypography>
        </Tooltip>
    )
}

export default GroupCounters
