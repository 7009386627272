import INavigationSection from '../Types/INavigationSection'
import INavigationSubsection from '../Types/INavigationSubsection'

import INavigationReducerState from '../Types/INavigationReducerState'
import navigationReducer from '../State/NavigationReducer'

export const getTestNavigationSubsections = (): INavigationSubsection[] => [
    {
        Id: 1,
        Name: 'NavigationSubsection1',
        Translation: 'NavigationSubsection1Translated',
        Url: 'NavigationSubsection1Url',
    },
    {
        Id: 2,
        Name: 'NavigationSubsection2',
        Translation: 'NavigationSubsection2Translated',
        Url: 'NavigationSubsection2Url',
    },
    {
        Id: 3,
        Name: 'NavigationSubsection3',
        Translation: 'NavigationSubsection3Translated',
        Url: 'NavigationSubsection3Url',
    },
    {
        Id: 4,
        Name: 'NavigationSubsection4',
        Translation: 'NavigationSubsection4Translated',
        Url: 'NavigationSubsection4Url',
    },

    {
        Id: 5,
        Name: 'NavigationSubsection5',
        Translation: 'NavigationSubsection5Translated',
        Url: 'NavigationSubsection5Url',
    },
]

const subsectionIds1 = getTestNavigationSubsections()
    .slice(0, 2)
    .map(({ Id }) => Id)
const subsectionIds2 = getTestNavigationSubsections()
    .slice(2, 4)
    .map(({ Id }) => Id)
const subsectionIds3 = getTestNavigationSubsections()
    .slice(4, 5)
    .map(({ Id }) => Id)

export const getTestNavigationSections = (): INavigationSection[] => [
    {
        Id: 1,
        Name: 'NavigationSection1',
        Translation: 'NavigationSection1Translated',
        Subsections: subsectionIds1,
        Icon: 'date_range',
    },
    {
        Id: 2,
        Name: 'NavigationSection2',
        Translation: 'NavigationSection2Translated',
        Subsections: subsectionIds2,
        Icon: 'domain',
    },
    {
        Id: 3,
        Name: 'NavigationSection3',
        Translation: 'NavigationSection3Translated',
        Subsections: subsectionIds3,
        Icon: null,
    },
]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTestNavigationReducerMap = () => ({
    navigationItems: navigationReducer,
})

export const getTestNavigationStoreState = (
    sections: INavigationSection[] = getTestNavigationSections(),
    subsections: INavigationSubsection[] = getTestNavigationSubsections()
): INavigationReducerState => ({
    navigationItems: {
        navigationSections: sections,
        navigationSubsections: subsections,
        currentPage: 'Test',
    },
})
