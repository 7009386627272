import * as React from 'react'

import IValuePickerRegistryComponents from './types/IValuePickerRegistryComponents'

interface IValuePickerComponentRegistry {
    components: null | IValuePickerRegistryComponents
}

const emptyRegistry = {
    components: null,
}

const ValuePickerComponentRegistry = React.createContext<IValuePickerComponentRegistry>(emptyRegistry)

export default ValuePickerComponentRegistry

interface IValuePickerComponentRegistryProviderProps {
    readonly components: IValuePickerRegistryComponents
}

export const ValuePickerComponentRegistryProvider: React.FunctionComponent<IValuePickerComponentRegistryProviderProps> = ({
    children,
    components,
}) => {
    const registry = { components }

    return <ValuePickerComponentRegistry.Provider value={registry}>{children}</ValuePickerComponentRegistry.Provider>
}
