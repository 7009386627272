import * as React from 'react'

import { Icon } from '../../generic-components'

interface IHyvaksyntaStatusProps {
    hyvaksynta: boolean
}

const HyvaksyntaStatus: React.FunctionComponent<IHyvaksyntaStatusProps> = ({ hyvaksynta: hyvaksytty }) => {
    if (!hyvaksytty) {
        return null
    }

    return (
        <Icon color="#8dbd33" testId="acceptanceStatusIcon">
            check_circle
        </Icon>
    )
}

export default HyvaksyntaStatus
