const allValuePickersFitTheFirstRow = (valuePickerElements: Element[]): boolean => {
    const firstElementBottomPosition = valuePickerElements[0].getBoundingClientRect().bottom.toFixed(0)

    const valuePickersWithDifferentBottomPositionThanFirstElement = valuePickerElements.filter((valuePickerElement) => {
        const isInDifferentBottomPositionThanFirstElement =
            valuePickerElement.getBoundingClientRect().bottom.toFixed(0) !== firstElementBottomPosition
        return isInDifferentBottomPositionThanFirstElement
    })

    const allValuePickersFitFirstRowNow = valuePickersWithDifferentBottomPositionThanFirstElement.length === 0

    return allValuePickersFitFirstRowNow
}

export default allValuePickersFitTheFirstRow
