import * as React from 'react'
import MuiButton, { ButtonProps } from '@material-ui/core/Button'
import { getTestId } from '../../../test/automationTestUtils'

import Icon from '../../Icon'

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode
    muiButtonProps?: ButtonProps
    disabled?: boolean
    className?: string
    onClick?: (event?: React.MouseEvent) => void | Promise<void>
    variant?: ButtonProps['variant']
    form?: string
    startIcon?: React.ReactNode
    endIcon?: React.ReactNode
    testId?: string | undefined
}

const Button: React.FC<IButtonProps> = ({
    onClick,
    className,
    disabled = false,
    children,
    muiButtonProps,
    type = 'button',
    form,
    startIcon,
    endIcon,
    testId,
}) => {
    const startIconToUse = typeof startIcon === 'string' ? <Icon>{startIcon}</Icon> : startIcon
    const endIconTouse = typeof endIcon === 'string' ? <Icon>{endIcon}</Icon> : endIcon

    const testIdToUse = testId ?? getTestId(['BUTTON'], typeof children === 'string' ? children : undefined)

    return (
        <MuiButton
            className={className}
            disabled={disabled}
            endIcon={endIconTouse}
            form={form}
            {...muiButtonProps}
            onClick={onClick}
            startIcon={startIconToUse}
            type={type}
            data-testid={testIdToUse}
        >
            {children}
        </MuiButton>
    )
}

export default Button
