import { createReducer } from 'typesafe-actions'

import { initializeApplicationAction } from 'action-creators'
import IOhjeinfo from 'interfaces/IOhjeinfo'

const initialState: IOhjeinfo[] = []
const ohjeinfoReducer = createReducer(initialState).handleAction(initializeApplicationAction, (state, action) => {
    return action.payload.ohjeet
})

export default ohjeinfoReducer
