/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types'
import { Component } from 'react'
import ReactDOM from 'react-dom'

import Styles from 'constants/Styles'

export interface IAbstractActionProps {
    clickAction?: any
    actionArgument?: any
    target?: string
    invertColors?: boolean
}

/**
 * Action-elementtien kantaluokka.
 * Tarjoaa actionin dispatchauksen tai kutsun. Jos changeAction on flux-action,
 * sille kutsutaan dispatch-metodia. Jos se on funktio, sitä kutsutaan suoraan.
 *
 * Perittäessä luokka anna luokalle propsimäärittelyt interfacena, joka "perii" IAbstractActionProps-interfacelta,
 * eli se sisältää sekä tämän parent-komponentin propsit että lapsiluokassa määritellyt propsit.
 *
 * IAbstractActionProps täytyy siis importata erikseen, esim.:
 *
 *     import AbstractAction, { IAbstractActionProps } from 'components/atoms/AbstractAction';
 *
 * ..Ja sen jälkeen lapsiluokassa määritellä propsit näin:
 *
 *     interface IProps extends IAbstractActionProps {
 *         jokuLapsiluokanPropsi: string;
 *     }
 *
 * ..Ja sitten antaa propsit eteenpäin tälle parent-komponentille:
 *
 *     export default class JokuLapsiLuokka extends AbstractAction<IProps> {
 *         // luokan koodi tässä
 *     }
 *
 * @note Tällä luokalla ei voi olla omaa render()-metodia. Jos on, React hajoaa.
 */
export default class AbstractActionComponent<Props extends IAbstractActionProps> extends Component<Props> {
    static readonly defaultProps = {
        invertColors: false,
    }

    static readonly propTypes = {
        actionArgument: PropTypes.object,
        clickAction: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        invertColors: PropTypes.bool,
        target: PropTypes.node,
    }

    constructor(props: any) {
        super(props)

        this._onClicked = this._onClicked.bind(this)
        this._openPopup = this._openPopup.bind(this)
        this._highlightWithColor = this._highlightWithColor.bind(this)
        this._removeHighlightWithColor = this._removeHighlightWithColor.bind(this)
        this._highlightWithUnderline = this._highlightWithUnderline.bind(this)
        this._removeHighlightWithUnderline = this._removeHighlightWithUnderline.bind(this)
        this._removeBackgroundHighlight = this._removeBackgroundHighlight.bind(this)
        this._highlightBackground = this._highlightBackground.bind(this)
    }

    /**
     * Ylikirjoita tämä metodi aliluokassa.
     */
    protected styles(): any {
        return {}
    }

    /**
     * Kutsu tätä metodia lapsiluokan komponentin klikkauksen käsittelijässä.
     * Dispatchauksen argumenttina actionArgument.
     *
     * Esimerkiksi:
     * <input type='button' onClick={ this._onClicked } />
     * @protected
     */
    _onClicked(event: any): void {
        const { clickAction, actionArgument } = this.props

        if (!clickAction) {
            return
        }

        if (typeof clickAction === 'function') {
            clickAction(event)
        } else {
            clickAction.dispatch(actionArgument)
        }

        event.stopPropagation()
        event.preventDefault()
    }

    /**
     * Jaettu apumetodi popuppien avaamiseen.
     * @protected
     */
    _openPopup(event: any): void {
        const specs =
            'width=1150, height=800, status=no, resizable=yes, scrollbars=yes, toolbar=no, location=no, menubar=no'
        const windowName = undefined

        window.open(this.props.target, windowName, specs)
        event.preventDefault()
    }

    /**
     * Jaettu apumetodi hoveroinnin indikoimiseen väriä vaihtamalla.
     * Lisää haluttuun nodeen:
     *     onMouseOver={ this._highlightWithColor }
     * @protected
     */
    _highlightWithColor(): void {
        const newColor = this.props.invertColors ? Styles.supplementaryColor.lightBlue : Styles.mainColor.blue
        // eslint-disable-next-line react/no-find-dom-node
        ;(ReactDOM.findDOMNode(this) as any).style.color = newColor
    }

    /**
     * Jaettu apumetodi hoveroinnin indikoimiseen väriä vaihtamalla.
     * Lisää haluttuun nodeen:
     *     onMouseOut={ this._removeHighlightWithColor }
     * @protected
     */
    _removeHighlightWithColor(): void {
        const newColor = this.props.invertColors ? Styles.mainColor.white : this.styles().atom.color
        // eslint-disable-next-line react/no-find-dom-node
        ;(ReactDOM.findDOMNode(this) as any).style.color = newColor
    }

    /**
     * Jaettu apumetodi hoveroinnin indikoimiseen alleviivaamalla.
     * Lisää haluttuun nodeen:
     *     onMouseOver={ this._highlightWithUnderline }
     * @protected
     */
    _highlightWithUnderline(): void {
        // eslint-disable-next-line react/no-find-dom-node
        ;(ReactDOM.findDOMNode(this) as any).style.textDecoration = 'underline'
    }

    /**
     * Jaettu apumetodi hoveroinnin indikoimiseen alleviivaamalla.
     * Lisää haluttuun nodeen:
     *     onMouseOut={ this._removeHighlightWithUnderline }
     * @protected
     */
    _removeHighlightWithUnderline(): void {
        // eslint-disable-next-line react/no-find-dom-node
        ;(ReactDOM.findDOMNode(this) as any).style.textDecoration = 'none'
    }

    /**
     * Jaettu apumetodi hoveroinnin indikoimiseen taustavärieä vaihtamalla.
     * Lisää haluttuun nodeen:
     *     onMouseOut={ this._highlightBackground.bind(this, color) }
     * @protected
     */
    _highlightBackground(color: any): void {
        // eslint-disable-next-line react/no-find-dom-node
        ;(ReactDOM.findDOMNode(this) as any).style.background = color || Styles.mainColor.lightGrey
    }

    /**
     * Jaettu apumetodi hoveroinnin indikoimiseen taustavärieä vaihtamalla.
     * Lisää haluttuun nodeen:
     *     onMouseOut={ this._removeBackgroundHighlight.bind(this, color) }
     * @protected
     */
    _removeBackgroundHighlight(color: any): void {
        // eslint-disable-next-line react/no-find-dom-node
        ;(ReactDOM.findDOMNode(this) as any).style.background = color || Styles.mainColor.white
    }
}
