import { FC } from 'react'
import styled from '@emotion/styled'
import { ReadOnlyText } from '../../../generic-components'
import { Tab } from './FormView'
import { useAppTheme } from '../../../theme/'
import { getTestId } from '../../../test'

export interface ITabsProps {
    tabs: Tab[]
    onSelectTab: (tab: string) => void
    selectedTab: string | null
    userHasTriedSubmit: boolean
}

export interface ITabItemProps {
    selected?: boolean
    text: string
    onClick: () => void
    hasRequiredFields: boolean
    hasValidationError: boolean
    userHasTriedSubmit: boolean
}

const TabsContainer = styled.div`
    width: 200px;
    flex-direction: column;
    display: flex;
    border-right: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
`

const StyledTabItem = styled.button<{ selected: boolean }>`
    height: 48px;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    justify-content: center;
    text-align: center;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color ${({ theme }) => theme.tokens.transitionQuick};
    border-bottom: ${({ selected, theme }) =>
        selected
            ? `2px solid ${theme.colors.primaryTeal120}`
            : `1px solid ${theme.componentExtensions.border.secondary}`};

    background-color: ${({ selected, theme }) =>
        selected ? theme.colors.primaryCyan11 : theme.colors.neutralsWhite100};

    span {
        margin-top: ${({ selected }) => selected && '1px'};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.neutralsGrey11};
    }
`

const TabItem: FC<ITabItemProps> = ({
    selected,
    text,
    onClick,
    hasRequiredFields,
    hasValidationError,
    userHasTriedSubmit,
}) => {
    const title = text + (hasRequiredFields ? ' *' : '')
    const theme = useAppTheme()

    const testId = getTestId(['Stc_Form', 'SubTab'], text)

    return (
        <StyledTabItem selected={!!selected} onClick={onClick}>
            <div data-testid={testId}>
                <ReadOnlyText
                    usage="button"
                    rawColor={
                        selected
                            ? theme.colors.primaryTeal120
                            : hasValidationError && userHasTriedSubmit
                            ? theme.componentExtensions.global.error
                            : theme.componentExtensions.text.disabledDarker
                    }
                >
                    {title}
                </ReadOnlyText>
            </div>
        </StyledTabItem>
    )
}

const Tabs: FC<ITabsProps> = ({ onSelectTab, tabs, selectedTab, userHasTriedSubmit }) => {
    return (
        <TabsContainer>
            {tabs.map((tab, index) => {
                const handleOnSelectTab = () => {
                    onSelectTab(tab.name)
                }
                const selected = tab.name === selectedTab ? true : index === 0 && selectedTab === null
                return (
                    <TabItem
                        key={tab.name}
                        selected={selected}
                        text={tab.name}
                        onClick={handleOnSelectTab}
                        hasRequiredFields={tab.hasRequiredFields}
                        hasValidationError={tab.hasValidationErrors}
                        userHasTriedSubmit={userHasTriedSubmit}
                    />
                )
            })}
        </TabsContainer>
    )
}

export default Tabs
