import * as React from 'react'
import styled from '@emotion/styled'

import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import Styles from 'constants/Styles'
import { useScreenDimensions } from './FormView/utils'

const FORM_WIDTH = 964

const LoadingContainer = styled.div<{ height: number }>`
    width: ${FORM_WIDTH}px;
    height: calc(${({ height }) => height}px - 120px - 82px - 70px);
    background-color: ${Styles.mainColor.white};
    display: flex;
    justify-content: center;
    align-items: center;
`

const FormViewLoadingIndicator: React.FunctionComponent = () => {
    const { height } = useScreenDimensions()
    return (
        <LoadingContainer height={height}>
            <LoadingIndicatorInline disableShrink isLoading paddingLeft="0" size="50px" />
        </LoadingContainer>
    )
}

export default FormViewLoadingIndicator
