import moment, { Moment } from 'moment'
import { IEvent } from '../../event'
import { RootState } from 'typesafe-actions'
import { IEventGroupsByDays } from '../Types/tyles'
import { EEventType, IIdentifier } from '../../event/Types/IEvent'
import { difference, isEmpty, sortBy } from 'lodash-es'
import createCachedSelector from 're-reselect'

export const selectIsLoadingEvents = (state: RootState): boolean => {
    return state.employeeCalendar.isLoadingEvents
}

export const selectEventsForDay = (state: RootState, day: Moment, includeTransfer: boolean): IEvent[] => {
    const events = state.employeeCalendar.events.filter(
        (event) =>
            moment(event.Alkamisaika).isSame(day, 'date') &&
            (includeTransfer || event.EventType?.Id !== EEventType.Transfer)
    )

    return events
}

export const selectHoveredDaysEvents = (state: RootState): IEvent[] => {
    const events = state.employeeCalendar.events.filter((event) =>
        moment(event.Alkamisaika).isSame(state.employeeCalendar.hoveredDay, 'date')
    )

    return events
}

export const selectSelectedDays = (state: RootState): Moment[] => {
    return state.employeeCalendar.selectedDays
}

export const selectSelectedDaysEvents = (state: RootState): IEvent[] => {
    const { selectedDays } = state.employeeCalendar

    if (!selectedDays) {
        return []
    }

    return selectedDays.flatMap((selectedDay) => {
        return selectEventsForDay(state, selectedDay, false)
    })
}

export const selectSelectedDaysEventsGroupedByDays = (state: RootState): IEventGroupsByDays[] => {
    return sortBy(
        state.employeeCalendar.selectedDays.map((selectedDay) => {
            return {
                day: selectedDay.clone(),
                events: selectEventsForDay(state, selectedDay, false),
            }
        }),
        'day'
    )
}

export const selectEventTypeCategories = (state: RootState): IIdentifier[] => {
    return state.employeeCalendar.eventTypeCategories
}

export const selectSelectedMonth = (state: RootState): Moment | undefined => state.employeeCalendar.selectedMonth

export const selectCalendarEvents = (state: RootState): IEvent[] => state.employeeCalendar.events

export const selectSelectedEvents = (state: RootState): IEvent[] => state.employeeCalendar.selectedEvents

export const selectSelectedEvent = (state: RootState, id: string): IEvent | undefined =>
    state.employeeCalendar.selectedEvents?.find(({ Id }) => Id === id)

export const selectEventsOfType = (state: RootState, eventTypeCategory: IIdentifier): Moment[] => {
    return state.employeeCalendar.events
        .map(({ EventTypeCategory, StartTime }) => {
            return {
                eventTypeId: EventTypeCategory.Id,
                startTime: moment(StartTime).startOf('day'),
            }
        })
        .filter(({ eventTypeId, startTime }) => {
            return (
                eventTypeId === eventTypeCategory.Id && startTime.isSame(state.employeeCalendar.selectedMonth, 'month')
            )
        })
        .map(({ startTime }) => startTime)
}

export const selectAreAllEventsSelectedByType = createCachedSelector(
    selectEventsOfType,
    selectSelectedDays,
    (eventsOfType, selectedDays): boolean => {
        if (isEmpty(eventsOfType)) {
            return false
        }

        const alreadySelectedEvents = eventsOfType.filter((event) => selectedDays.some((day) => day.isSame(event)))

        const unselectedEvents = difference(eventsOfType, alreadySelectedEvents)

        return isEmpty(unselectedEvents)
    }
)((state, { Id, Name }) => `${Id}-${Name}`)
