import styled from '@emotion/styled'
import IconButton from '../../generic-components/Buttons/IconButton/IconButton'

type Props = {
    icon: string
    tooltip: string
    onClick: () => void
    disabled?: boolean
    testId?: string
}

const Button = styled(IconButton)`
    color: ${({ theme }) => theme.componentExtensions.icons.iconAction};

    &:disabled {
        color: ${({ theme }) => theme.componentExtensions.icons.iconDisabledLighter};
        pointer-events: auto;
    }
`

const ActionButton = ({ icon, tooltip, disabled, testId, onClick }: Props): JSX.Element => {
    return (
        <Button
            icon={icon}
            size="medium"
            tooltip={tooltip}
            onClick={onClick}
            disabled={disabled}
            testId={testId}
            outline
        />
    )
}

export default ActionButton
