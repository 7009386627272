import * as React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import { NonInitialNodesContainer } from './HeaderNodesContainer'
import CloseButton from './CloseButton'
import { BoundThunk } from '../../../../../generic-state'
import { resetToCalendarInitialStartDateThunk } from '../../../../Thunks/ConfigurableCalendarThunks'

interface IOwnProps {
    children: JSX.Element[]
    totalHeaderNodesCount: number
}

interface IDispatchProps {
    resetToCalendarInitialStartDate: BoundThunk<typeof resetToCalendarInitialStartDateThunk>
}

interface INodesBeforeInitialNodesProps extends IOwnProps, IDispatchProps {}

const PositionedCloseButton = styled(CloseButton)`
    position: absolute;
    right: 0;
`

const NodesBeforeInitialNodes: React.FC<INodesBeforeInitialNodesProps> = ({
    children,
    resetToCalendarInitialStartDate,
    totalHeaderNodesCount,
}) => {
    return (
        <NonInitialNodesContainer
            childHeadersCount={children.length}
            hasContent={children.length > 0}
            totalHeaderNodesCount={totalHeaderNodesCount}
        >
            {children}
            {children.length > 0 && <PositionedCloseButton onClick={() => resetToCalendarInitialStartDate()} />}
        </NonInitialNodesContainer>
    )
}

const mapStateToProps = null
const mapDispatchToProps = {
    resetToCalendarInitialStartDate: resetToCalendarInitialStartDateThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(NodesBeforeInitialNodes)
