/**
 * Tänne tiedostoon voi määrittää sekalaisia constantteja joita
 * voi käyttää parametreina metodeille jos parametrit ovat sellaisia että niitä
 * tuntuisi muuten turhalta nimetä (esim. joku undefined-arvo tai boolean kertomaan
 * että nyt ladataan) tai jos ne toistuvat useammassa paikassa.
 */

export const DATA_UNDEFINED = undefined
export const PARAMETERS_UNDEFINED = undefined
export const RESOURCE_ID_EMPTY = ''
export const PARENT_RESOURCE_ID_EMPTY = ''
export const SUB_RESOURCE_ID_EMPTY = ''
export const LOADING_START = true
export const LOADING_STOP = false
export const CHANGE_TRUE = true
export const CHANGE_FALSE = false
export const KEEP_EXPANDED = true
export const OPEN_FALSE = false
export const OPEN_TRUE = true
export const STRICT_MODE = true
export const DELAY_FALSE = false
