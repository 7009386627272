import * as React from 'react'
import Link from './Link'

import URL from 'common/URL'

type TLinkProps = React.ComponentProps<typeof Link>

interface ISystemInternalLinkProps extends TLinkProps {}

/**
 * Links to any given page within Planier.
 * Do not use as a link between the front end pages using only the react router's path (i.e.
 * the routes defined in Routes.tsx file), but the link given needs to contain the
 * whole URL except for the its base (protocol, hostname and such), e.g.
 * /Client/index.html#/payrolladministration/views
 */
const SystemInternalLink = React.forwardRef<HTMLElement, ISystemInternalLinkProps>(
    ({ windowParams, className, to, children, openInNewTab = true, muiLinkProps }, ref) => {
        const linkWithBaseUrl = URL.oldClient(to)

        return (
            <Link
                className={className}
                muiLinkProps={muiLinkProps}
                openInNewTab={openInNewTab}
                ref={ref}
                to={linkWithBaseUrl}
                windowParams={windowParams}
            >
                {children}
            </Link>
        )
    }
)

SystemInternalLink.displayName = 'SystemInternalLink'

export default SystemInternalLink
