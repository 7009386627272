import { ProcessEventToTransactionForm } from '../../payroll-management'
import { CommentingForm, MessagingForm } from '../../messaging'
import { EmployeeCalendarForm } from '../../employee-calendar'
import {
    DeleteEvents,
    PublishEvents,
    PublishEventsConfirmationDialog,
    WorkforceSchedulingDeletionConfirmationDialog,
} from '../../workforce-scheduling'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFormFieldComponents = () => ({})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCustomModalComponents = () => ({
    ProcessEventToTransactionForm,
    WorkforceSchedulingDeletionConfirmationDialog,
    PublishEventsConfirmationDialog,
    PublishEvents,
    DeleteEvents,
    MessagingForm,
    CommentingForm,
    EmployeeCalendarForm,
})
