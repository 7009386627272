import * as React from 'react'
import styled from '@emotion/styled'
import moment from 'moment'

import { BaseButton } from '../../Buttons'

const WeekdaySelectionContainer = styled.div`
    display: flex;
`

interface IWeekDayProps {
    selected?: boolean
    children: string
    onClick: (isoDayIndex: number) => void
    isoDayIndex: number
}

const WeekDayButton = styled<React.FC<{ selected: boolean; children: string; onClick: () => void }>>(
    // There seems to be a typing error in the button or in TypeScript itself
    // causing it to not accept the onClick for example, unless we type it above as optional
    BaseButton as any,
    {
        shouldForwardProp: (prop) => prop !== 'selected',
    }
)`
    background-color: ${({ theme }) => theme.componentExtensions.global.bgPrimary};
    color: ${({ theme }) => theme.componentExtensions.buttons.textButtonDestructiveDefault};
    outline: ${({ theme }) => theme.componentExtensions.buttons.textButtonDestructiveDefault} solid 1px;
    text-transform: uppercase;

    &:hover {
        background-color: ${({ theme }) => theme.componentExtensions.buttons.bgButtonPrimaryHover};
        outline-color: transparent;
    }

    transition: ${({ theme }) => theme.tokens.transitionQuick};

    ${({ selected, theme }) =>
        selected &&
        `
        background-color: ${theme.componentExtensions.buttons.bgButtonPrimaryDefault};
        color: ${theme.componentExtensions.global.bgPrimary};
        outline-color: transparent;

        &:hover {
            background-color: ${theme.componentExtensions.buttons.bgButtonPrimaryHover};
            color: ${theme.componentExtensions.buttons.textButtonDestructiveDefault};
            outline-color: transparent;
        }
    `}

    font-size: 12px;
    border-radius: 50%;
    padding: 4px;
    min-width: 40px;
    min-height: 40px;
    margin: 2px;
`

const WeekDay: React.FC<IWeekDayProps> = ({ selected = false, children, onClick, isoDayIndex }) => {
    const handleClick = () => {
        onClick(isoDayIndex)
    }

    return (
        <WeekDayButton onClick={handleClick} selected={selected}>
            {children}
        </WeekDayButton>
    )
}

interface IWeekDaySelection {
    onChange: (isoDayIndex: number) => void
    selectedWeekDays: ReadonlySet<number>
}

const getWeekDayNamesShort = () => {
    const weekDayNamesShort = moment.weekdaysMin()
    // order to ISO
    weekDayNamesShort.push(weekDayNamesShort.shift() as string)
    return weekDayNamesShort
}

const weekdaysIso = [1, 2, 3, 4, 5, 6, 7]

const WeekDaySelection: React.FC<IWeekDaySelection> = ({ selectedWeekDays, onChange }) => {
    const weekDayNamesShort = getWeekDayNamesShort()

    return (
        <WeekdaySelectionContainer>
            {weekdaysIso.map((weekDayIsoIndex, index) => (
                <WeekDay
                    isoDayIndex={weekDayIsoIndex}
                    key={weekDayIsoIndex}
                    onClick={onChange}
                    selected={selectedWeekDays.has(weekDayIsoIndex)}
                >
                    {weekDayNamesShort[index]}
                </WeekDay>
            ))}
        </WeekdaySelectionContainer>
    )
}

export default WeekDaySelection
