import * as React from 'react'

import { setJwt } from '../State/JwtState'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'

//Use only for loggin in to demo page
const IntermidiatePageForJwtLogin: React.FC<{ match: { params: { jwt: string } } }> = (props) => {
    const history = useHistory()

    useEffect(() => {
        const jwt = props?.match?.params?.jwt ?? ''

        if (jwt && jwt !== '') {
            setJwt(jwt)

            history.push('/wfs/1')
        }
    }, [props?.match?.params?.jwt, history])

    return <div />
}

export default IntermidiatePageForJwtLogin
