import { RootState } from 'typesafe-actions'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'

import { TDataSourceItemId } from '../../../data-source-types'
import {
    PLANNING_PERIODS_VALUE_PICKER_ID,
    TARVENAKYMA_CALENDAR_ID,
    TARVENAKYMA_DATA_SOURCE_ID,
    TYONTEKIJANAKYMA_CALENDAR_ID,
    TYONTEKIJANAKYMA_DATA_SOURCE_ID,
} from '../../Constants/WorkforceSchedulingConstants'
import {
    DEMAND_VIEW_EVENT_GROUP_TYPE_VALUE_PICKER_ID,
    VALUE_PICKER_EMPLOYEES,
    VALUE_PICKER_PLANNING_PERIODS_RECENT,
    VALUE_PICKER_POOL,
    VALUE_PICKER_WORK_UNIT,
} from '../../Constants/WorkforceSchedulingValuePickerConstants'
import {
    IPublicHoliday,
    selectCalendarInitialValuePickersValues,
    selectCalendarValuePickerIds,
    selectHaveDatesChanged,
    selectSelectedCalendarNodes,
} from '../../../calendar'
import {
    selectValuePickersValues,
    selectValuePickerValue,
    selectValuePickerValuesForQueryFromValuePickerIds,
} from '../../../value-picker'
import {
    selectStoredOptionsComponentAllOptions,
    selectStoredOptionsOptionByPropertyMatchingValue,
} from '../../../stored-options'
import ISuunnittelujakso from '../../Types/ISuunnittelujakso'
import { EEventGroupType } from '../../../event'
import { isEqual } from 'lodash-es'

export const selectActiveCalendarId = (state: RootState): string | null => {
    if (selectSelectedCalendarNodes(state, TARVENAKYMA_CALENDAR_ID).size > 0) {
        return TARVENAKYMA_CALENDAR_ID
    }

    if (selectSelectedCalendarNodes(state, TYONTEKIJANAKYMA_CALENDAR_ID).size > 0) {
        return TYONTEKIJANAKYMA_CALENDAR_ID
    }

    return null
}

export const selectActiveCalendarDataSourceId = (state: RootState): string | null => {
    const activeCalendarId = selectActiveCalendarId(state)

    if (!activeCalendarId) {
        return null
    }

    return activeCalendarId === TARVENAKYMA_CALENDAR_ID ? TARVENAKYMA_DATA_SOURCE_ID : TYONTEKIJANAKYMA_DATA_SOURCE_ID
}

export const selectSelectedNodesForActiveCalendar = (state: RootState): ReadonlySet<TDataSourceItemId> => {
    const demandViewSelectedNodes = selectSelectedCalendarNodes(state, TARVENAKYMA_CALENDAR_ID)
    if (demandViewSelectedNodes.size > 0) {
        return demandViewSelectedNodes
    }

    const employeeViewSelectedNodes = selectSelectedCalendarNodes(state, TYONTEKIJANAKYMA_CALENDAR_ID)
    if (employeeViewSelectedNodes.size > 0) {
        return employeeViewSelectedNodes
    }

    return new Set()
}

export const selectPlanningPeriod = (state: RootState): ISuunnittelujakso | null => {
    const selectedPlanningPeriodsSet = selectValuePickerValue(state, PLANNING_PERIODS_VALUE_PICKER_ID)

    if (!(selectedPlanningPeriodsSet instanceof Set) || selectedPlanningPeriodsSet.size === 0) {
        return null
    }

    const planningPeriod = selectStoredOptionsOptionByPropertyMatchingValue<ISuunnittelujakso>(
        state,
        PLANNING_PERIODS_VALUE_PICKER_ID,
        'ActiveVersionId',
        [...selectedPlanningPeriodsSet][0]
    )

    return planningPeriod ?? null
}

export const selectRecentPlanningPeriods = (state: RootState): ISuunnittelujakso[] => {
    const recentPlanningPeriods = selectStoredOptionsComponentAllOptions<ISuunnittelujakso>(
        state,
        VALUE_PICKER_PLANNING_PERIODS_RECENT
    )

    return recentPlanningPeriods
}

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export const selectPlanningPeriodAsQueryParameter = createDeepEqualSelector(
    (state: RootState) => selectValuePickerValuesForQueryFromValuePickerIds(state, [PLANNING_PERIODS_VALUE_PICKER_ID]),
    (planningPeriodQueryParameter) => planningPeriodQueryParameter
)

export const selectAreGroupsGroupedForDemandView = (state: RootState): boolean => {
    const groupType = selectValuePickerValue(state, DEMAND_VIEW_EVENT_GROUP_TYPE_VALUE_PICKER_ID)

    if (groupType === undefined || groupType === null) {
        return false
    }

    return groupType !== EEventGroupType.Default
}

export const selectPlanningPeriodDatesAsQueryParameter = createDeepEqualSelector(
    // TODO: change the 'CalendarInitialDates' to something more dynamic
    (state: RootState) => selectValuePickerValuesForQueryFromValuePickerIds(state, ['CalendarInitialDates']),
    (datesParameter) => datesParameter
)

export const selectPlanningPeriodEmployeeIds = (state: RootState): number[] | null => {
    const selectedPlanningPeriod = selectPlanningPeriod(state)

    return selectedPlanningPeriod?.TyontekijaLista ?? null
}

export const selectPlanningPeriodWorkUnitIds = (state: RootState): number[] | null => {
    const selectedPlanningPeriod = selectPlanningPeriod(state)

    return selectedPlanningPeriod?.AsiakasLista ?? null
}

export const selectPlanningPeriodPoolIds = (state: RootState): number[] | null => {
    const selectedPlanningPeriod = selectPlanningPeriod(state)

    return selectedPlanningPeriod?.PooliLista ?? null
}

export const selectDemandCalendarValuePickersWithValues = (state: RootState): Record<string, unknown> => {
    const valuePickerIds = selectCalendarValuePickerIds(state, TARVENAKYMA_CALENDAR_ID)

    const valuePickersWithValues = selectValuePickersValues(state, valuePickerIds)

    return valuePickersWithValues
}

export const selectEmployeeCalendarValuePickersWithValues = (state: RootState): Record<string, unknown> => {
    const valuePickerIds = selectCalendarValuePickerIds(state, TYONTEKIJANAKYMA_CALENDAR_ID)

    const valuePickersWithValues = selectValuePickersValues(state, valuePickerIds)

    return valuePickersWithValues
}

// Checks whether there are any changes to any filters. The filters are restorable to
// their initial values if there are any changes.
// Not completely sure if this should check only filters relevant to planning period
// (atm employees, work units and pools + the date range), but currently it checks for
// all the filters.
export const selectAreFiltersRestorable = (state: RootState): boolean => {
    const planningPeriod = selectPlanningPeriod(state)

    if (!planningPeriod) {
        return false
    }

    const haveDatesChanged = selectHaveDatesChanged(state)

    if (haveDatesChanged) {
        return true
    }

    const initialValuePickersValuesForDemandCalendar = selectCalendarInitialValuePickersValues(
        state,
        TARVENAKYMA_CALENDAR_ID
    )
    const initialValuePickersValuesForEmployeeCalendar = selectCalendarInitialValuePickersValues(
        state,
        TYONTEKIJANAKYMA_CALENDAR_ID
    )

    if (!initialValuePickersValuesForDemandCalendar || !initialValuePickersValuesForEmployeeCalendar) {
        return false
    }

    if (
        initialValuePickersValuesForDemandCalendar.size === 0 ||
        initialValuePickersValuesForEmployeeCalendar.size === 0
    ) {
        return false
    }

    const currentValuePickersValuesForDemandCalendar = selectValuePickersValues(state, [
        ...initialValuePickersValuesForDemandCalendar.keys(),
    ])

    const haveDemandFiltersChanged = Object.keys(currentValuePickersValuesForDemandCalendar).some(
        (key) =>
            !isEqual(
                currentValuePickersValuesForDemandCalendar[key],
                initialValuePickersValuesForDemandCalendar.get(key)
            )
    )

    if (haveDemandFiltersChanged) {
        return true
    }

    const currentValuePickersValuesForEmployeeCalendar = selectValuePickersValues(state, [
        ...initialValuePickersValuesForEmployeeCalendar.keys(),
    ])

    const haveEmployeeFiltersChanged = Object.keys(currentValuePickersValuesForEmployeeCalendar).some(
        (key) =>
            !isEqual(
                currentValuePickersValuesForEmployeeCalendar[key],
                initialValuePickersValuesForEmployeeCalendar.get(key)
            )
    )

    if (haveEmployeeFiltersChanged) {
        return true
    }

    return false
}

export const selectCurrentlySelectedEmployees = (state: RootState): ReadonlySet<number> => {
    return selectValuePickerValue(state, VALUE_PICKER_EMPLOYEES) as ReadonlySet<number>
}

export const selectCurrentlySelectedWorkUnits = (state: RootState): ReadonlySet<number> => {
    return selectValuePickerValue(state, VALUE_PICKER_WORK_UNIT) as ReadonlySet<number>
}

export const selectCurrentlySelectedPools = (state: RootState): ReadonlySet<number> => {
    return selectValuePickerValue(state, VALUE_PICKER_POOL) as ReadonlySet<number>
}

export const selectDemandViewValuePickerIds = (state: RootState): string[] => {
    return selectCalendarValuePickerIds(state, TARVENAKYMA_CALENDAR_ID)
}

export const selectEmployeesViewValuePickerIds = (state: RootState): string[] => {
    return selectCalendarValuePickerIds(state, TYONTEKIJANAKYMA_CALENDAR_ID)
}

const hiddenValuePickers = new Set([PLANNING_PERIODS_VALUE_PICKER_ID])
export const selectCombinedFilterIds = createSelector(
    selectDemandViewValuePickerIds,
    selectEmployeesViewValuePickerIds,
    (demandViewValuePickerIds, employeeViewValuePickerIds) => {
        const uniqueValuePickerIds = [...new Set([...employeeViewValuePickerIds, ...demandViewValuePickerIds])].filter(
            (id) => !hiddenValuePickers.has(id)
        )

        return uniqueValuePickerIds
    }
)

export const selectPublicHolidays = (state: RootState): IPublicHoliday[] => {
    return state.workforceScheduling.publicHolidays
}
