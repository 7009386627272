import { useEffect, useState } from 'react'
import TextInputV2 from '../TextInput/TextInputV2'

export interface INumberInputProps {
    value: number | null
    disabled?: boolean
    onChange?: (input: number | null) => void
    fireChangeOnBlur?: boolean
    label?: string
    placeholder?: string
    errors?: string
    width?: string
    heigth?: string
    isRequiredField?: boolean
    unit?: string
    valueInputId?: string
}

export const NumberInput: React.FC<INumberInputProps> = ({
    label,
    value,
    fireChangeOnBlur,
    onChange,
    errors,
    unit,
    isRequiredField,
    disabled,
    width,
    heigth,
    valueInputId,
}) => {
    const [val, setValue] = useState<string>('')

    useEffect(() => {
        if (typeof value === 'number') {
            setValue(value.toString())
        }
        if (value === undefined) {
            setValue('')
        }
    }, [value])

    const createNumberValueFromInput = (input: string) => {
        const newValue: number | null = input !== '' ? +input : null
        return newValue
    }
    const handleChange = (newVal: string) => {
        const testval = newVal.replace(',', '.')

        const numberTestWithPeriod = /^-?\d*\.?\d*$/

        if (numberTestWithPeriod.test(testval)) {
            setValue(testval)
        }

        const newValue: number | null = createNumberValueFromInput(testval)

        if (!fireChangeOnBlur && (newValue === null || !isNaN(newValue))) {
            onChange && onChange(newValue)
        }
    }

    const changeValueOnBlur = () => {
        const newValue: number | null = createNumberValueFromInput(val)

        if (newValue === null || !isNaN(newValue)) {
            onChange && onChange(newValue)
        }
    }

    const onBlur = fireChangeOnBlur ? changeValueOnBlur : undefined

    return (
        <TextInputV2
            onBlur={onBlur}
            disabled={disabled}
            label={label}
            isRequiredField={isRequiredField}
            errors={errors}
            placeholder={label}
            overrideStyle={{ width: width ?? '128px', height: heigth }}
            endAdorment={unit}
            onChange={handleChange}
            value={val}
            valueInputId={valueInputId}
        />
    )
}
