import { useState } from 'react'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { StyleRules } from '@material-ui/styles'
import { RootState } from 'typesafe-actions'

import Styles from 'constants/Styles'
import { AggregatedDataRow, TDataSourceId } from '../../../data-source'
import { Expandable } from '../../../generic-components'
import ConfigurableCalendar from '../ConfigurableCalendar'
import { selectCalendarTitle, selectCalendarDataSourceId } from '../../State/ConfigurableCalendarSelectors'
import ICalendarQuickActions from '../../Types/ICalendarQuickActions'
import IPublicHoliday from '../../Types/IPublicHoliday'
import { Translation } from '../../../localization'
import { useScrollbarWidth } from '../../../generic-utilities'

type TConfigurableCalendarProps = React.ComponentProps<typeof ConfigurableCalendar>

interface IStateProps {
    dataSourceId: TDataSourceId | null
    title: string | null
}

interface IOwnProps
    extends Pick<
        TConfigurableCalendarProps,
        'isDataFetchedInInitialization' | 'areGroupsGrouped' | 'areCalendarFiltersToBeDisplayed'
    > {
    calendarId: string
    dependentValue?: unknown
    hiddenValuePickerIds?: string[]
    quickActions?: ICalendarQuickActions | null
    className?: string
    publicHolidays: IPublicHoliday[]
    onExpandToggle?: (calendarId: string) => void
    highlightExpandedDates: boolean
}

export interface IExpandableCalendarProps extends IStateProps, IOwnProps {}

const heightOfCalendarHeader = 48

const ExpandableContainer = styled.div<{
    calendarExpandedStatus: boolean
}>`
    box-shadow: 0 2px 4px 0 ${Styles.calendar.expandableCalendarBoxShadow};
    margin: 6px 8px;

    display: flex;
    flex-direction: column;
    max-height: 100%;

    ${({ calendarExpandedStatus }) =>
        !calendarExpandedStatus &&
        `
        max-height: ${heightOfCalendarHeader}px;
    `}

    & .MuiCollapse-wrapper {
        height: 100%;
    }

    & .MuiAccordion-region {
        height: 100%;
    }

    & .MuiAccordion-root {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
`

const TitleRow = styled.div`
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
`

const Title = styled(Typography)`
    text-transform: uppercase;
    margin-right: 48px;
`

const expansionPanelDetailsStyles: StyleRules = {
    root: {
        padding: 0,
        height: '100%',
    },
}

const expansionPanelSummaryStyles: StyleRules = {
    root: {
        padding: '0 12px',
        height: '48px',

        '&$expanded': {
            minHeight: '48px',
        },
    },
    content: {
        width: 'inherit',
        margin: 0,
        '&$expanded': {
            margin: 0,
        },
    },
    expanded: {
        marginTop: 0,
        marginBottom: 0,
    },
    expandIcon: {
        right: 0,
    },
}

const titleContainerClassName = 'pln-ExpandableCalendar-TitleContainer'

const StyledExpandable = styled(Expandable)`
    .${titleContainerClassName} {
        width: 100%;
    }
`

export const ExpandableCalendarUnconnected: React.FunctionComponent<IExpandableCalendarProps> = ({
    calendarId,
    dataSourceId,
    dependentValue,
    hiddenValuePickerIds,
    title,
    quickActions,
    className,
    onExpandToggle,
    isDataFetchedInInitialization,
    areCalendarFiltersToBeDisplayed,
    areGroupsGrouped,
    publicHolidays,
    highlightExpandedDates,
}) => {
    const {
        scrollbarWidth: horizontalScrollbarHeight,
        setRefForElement: setRefForElementForHorizontalScrollbar,
        recalculateScrollbarWidth,
    } = useScrollbarWidth('horizontal')

    const [calendarExpandedStatus, setCalendarExpandedStatus] = useState(true)

    const handleCalendarExpandedStatusChange = (event: React.ChangeEvent<unknown>, expanded: boolean) => {
        onExpandToggle && onExpandToggle(calendarId)
        setCalendarExpandedStatus(expanded)
    }

    const titleRow = (
        <TitleRow>
            <Title variant="dialogHeader">{Translation.translateKey(title || '')}</Title>
            {dataSourceId && <AggregatedDataRow dataSourceId={dataSourceId} />}
        </TitleRow>
    )

    return (
        <ExpandableContainer calendarExpandedStatus={calendarExpandedStatus} className={className}>
            <StyledExpandable
                classNameLabelContainer={titleContainerClassName}
                expansionPanelDetailsStyles={expansionPanelDetailsStyles}
                expansionPanelSummaryStyles={expansionPanelSummaryStyles}
                label={titleRow}
                muiExpansionPanelProps={{
                    defaultExpanded: true,
                    square: true,
                    onChange: handleCalendarExpandedStatusChange,
                    // Force the calendar to check the scrollbar width when expanded.
                    // Otherwise there can be misalignment issues, because when not expanded, the width is
                    // is calculated to be zero for the scrollbar.
                    TransitionProps: { onEntered: recalculateScrollbarWidth },
                }}
            >
                <ConfigurableCalendar
                    publicHolidays={publicHolidays}
                    areCalendarFiltersToBeDisplayed={areCalendarFiltersToBeDisplayed}
                    areGroupsGrouped={areGroupsGrouped}
                    calendarId={calendarId}
                    dependentValue={dependentValue}
                    hiddenValuePickerIds={hiddenValuePickerIds}
                    highlightDatesOutsideInitialDates={highlightExpandedDates}
                    horizontalScrollbarHeight={horizontalScrollbarHeight}
                    isDataFetchedInInitialization={isDataFetchedInInitialization}
                    quickActions={quickActions}
                    setRefForElementForHorizontalScrollbar={setRefForElementForHorizontalScrollbar}
                />
            </StyledExpandable>
        </ExpandableContainer>
    )
}

const mapStateToProps = (state: RootState, { calendarId }: IOwnProps): IStateProps => ({
    dataSourceId: selectCalendarDataSourceId(state, calendarId),
    title: selectCalendarTitle(state, calendarId),
})

export default connect(mapStateToProps)(ExpandableCalendarUnconnected)
