import { RefObject, useCallback, useEffect, useState } from 'react'

import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import { useOnWindowResize } from '../../generic-utilities'
import checkDoAllValuePickersFitFirstRow from '../Utilities/checkDoAllValuePickersFitFirstRow'

/**
 * Runs check whether or not all the given value pickers fit the first row.
 * We do this basically by checking if all the value picker elements have
 * the same position in its bottom as the first element has.
 *
 * Is also responsive, so whenever window is resized, we run the same checks
 * again.
 */
const useAllValuePickersFitFirstRow = (
    valuePickerConfigurations: TValuePickerConfiguration[],
    valuePickerContainerRef: RefObject<HTMLDivElement>
): boolean => {
    const [allValuePickersFitFirstRow, setAllValuePickersFitFirstRow] = useState(true)

    const setDoAllValuePickersFitFirstRowInGroup = useCallback(() => {
        if (valuePickerConfigurations.length === 0 || valuePickerContainerRef.current === null) {
            return
        }

        const valuePickerElements = valuePickerContainerRef.current.children

        if (valuePickerElements.length === 0) {
            return
        }

        const allValuePickersFitFirstRowNow = checkDoAllValuePickersFitFirstRow(Array.from(valuePickerElements))

        if (allValuePickersFitFirstRowNow !== allValuePickersFitFirstRow) {
            setAllValuePickersFitFirstRow(allValuePickersFitFirstRowNow)
        }
    }, [allValuePickersFitFirstRow, valuePickerConfigurations.length, valuePickerContainerRef])

    useEffect(() => {
        setDoAllValuePickersFitFirstRowInGroup()
    }, [setDoAllValuePickersFitFirstRowInGroup])

    useOnWindowResize(setDoAllValuePickersFitFirstRowInGroup)

    return allValuePickersFitFirstRow
}

export default useAllValuePickersFitFirstRow
