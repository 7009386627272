import { Moment } from 'moment'
import PropTypes from 'prop-types'
import * as React from 'react'
import reactCss from 'reactcss'
import { isEqual } from 'lodash-es'

import DateUtility from 'common/DateUtility'
import Styles from 'constants/Styles'

interface IOwnProps {
    children: Moment | string
}

/**
 * Komponentti aikaleiman (päivämäärän ja kellonajan) esittämiseen tekstinä.
 * Jos aikaleimaa ei ole annettu, näyttää (ei annettu)-tekstin.
 */
export default class DateTime extends React.Component<IOwnProps> {
    static readonly displayName = 'DateTime'
    static propTypes: any

    shouldComponentUpdate(nextProps: IOwnProps): boolean {
        return !isEqual(this.props, nextProps)
    }

    private styles(): any {
        return reactCss({
            default: {
                atom: {
                    fontFamily: Styles.font.text.family,
                    fontSize: Styles.font.small.size,
                    margin: '0px',
                    width: '100%',
                    verticalAlign: 'middle',
                    color: Styles.mainColor.darkGrey,
                },
            },
        })
    }

    render(): React.ReactNode {
        return <span style={this.styles().atom}>{DateUtility.asFullDateTime(this.props.children)}</span>
    }
}

DateTime.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}
