import PropTypes from 'prop-types'

import { Checkbox } from '../../../generic-components'
import AbstractInput from 'components/atoms/AbstractInput'

/**
 * @deprecated
 *
 * Use Checkbox in generic-components package instead of this.
 */
export default class CheckBox extends AbstractInput {
    render() {
        const { value, disabled } = this.props

        return <Checkbox disabled={disabled} onClick={this._onChecked} value={value} />
    }
}

CheckBox.defaultProps = {
    disabled: false,
    value: false,
}

CheckBox.displayName = 'CheckBox'

CheckBox.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.bool,
}
