import { Fragment, useContext } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import FormViewCustomModal from './Components/FormViewCustomModal'
import FormViewModal from '../FormViewModal'
import FormViewComponentRegistry from '../../FormViewComponentRegistry'
import { selectOpenFormViewModalIds } from '../../State/FormViewSelectors'

interface IStateProps {
    openModalIds: string[]
}

export interface ICustomModalHandlerProps extends IStateProps {}

const FormViewModalsManagerUnconnected = ({ openModalIds }: ICustomModalHandlerProps) => {
    const { getCustomModalComponent, hasCustomModalComponent } = useContext(FormViewComponentRegistry)

    const modals = openModalIds.map((modalId) => {
        if (hasCustomModalComponent(modalId)) {
            const customModal = getCustomModalComponent(modalId)

            if (!customModal) {
                return null
            }

            return <FormViewCustomModal FormComponent={customModal} formId={modalId} key={modalId} />
        } else {
            return <FormViewModal formId={modalId} key={modalId} />
        }
    })

    return <Fragment>{modals}</Fragment>
}

const mapStateToProps = (state: RootState): IStateProps => ({
    openModalIds: selectOpenFormViewModalIds(state),
})

export default connect(mapStateToProps)(FormViewModalsManagerUnconnected)
