import { GLOBAL_SETTING_KEYS } from './Constants/SettingsGlobal'

type TSettings = Map<string, string | number | boolean>

const GlobalSettings: TSettings = new Map()

const setGlobalSettings = (settings: TSettings): void => {
    settings.forEach((value, key) => {
        GlobalSettings.set(key, value)
    })
}

export { GLOBAL_SETTING_KEYS, setGlobalSettings }
