import keyMirror from 'keymirror'
import DateUtility from 'common/DateUtility'

const DEFAULT_HOURS_TO_SET = '00'
const DEFAULT_HOUR_DIGIT_TO_SET = '0'

const _parseHoursFromGivenInput = (input) => {
    return input.substring(0, 2)
}

const _addDefaultValuesToHourAsNeededWithColon = (hours) => {
    if (hours.length === 2) {
        return hours
    } else if (hours.length === 1) {
        return hours + DEFAULT_HOUR_DIGIT_TO_SET
    }

    return DEFAULT_HOURS_TO_SET
}

const INDEX_BEFORE_COLON_AFTER_ADDING_NUMBER_BEFORE_IT = 3

export const INPUT_TARKOITUS = keyMirror({
    NORMAALI_LISAYS: null,
    TAYSI_POISTO: null,
    LUVUN_POISTAMINEN: null,
    NORMAALI_TUNNIN_LISAYS: null,
    LUVUN_LISAYS_JUURI_ENNEN_KAKSOISPISTETTA: null,
    LUVUN_LISAYS_AIVAN_LOPPUUN: null,
    LUVUN_YLIKIRJOITUS: null,
})

export default class TimeInputUtility {
    static getCurrentCaretPositionInText(elementId) {
        return document.getElementById(elementId).selectionStart
    }

    static createMomentAndAddDateInfoIfPossible(newValue, dateObjectProp, originalDateTimeObject) {
        if (!DateUtility.isMomentAsTime(newValue)) {
            return newValue
        }

        let newValueMoment = DateUtility.asMomentFromTime(newValue)

        if (originalDateTimeObject && DateUtility.isValidMoment(originalDateTimeObject)) {
            newValueMoment
                .year(originalDateTimeObject.year())
                .month(originalDateTimeObject.month())
                .date(originalDateTimeObject.date())
        }

        if (!DateUtility.isValidMoment(dateObjectProp)) {
            return newValueMoment
        }

        newValueMoment = DateUtility.nollaaSekunnit(newValueMoment)
        return DateUtility.addTimeToDate(dateObjectProp, newValueMoment)
    }

    static selvitaInputTarkoitus(currentValue, caretPosition, newValue) {
        if (newValue.length === 0) {
            return INPUT_TARKOITUS.TAYSI_POISTO
        }

        if (newValue.length < 2) {
            return INPUT_TARKOITUS.NORMAALI_LISAYS
        }

        if (newValue.length < currentValue.length) {
            return INPUT_TARKOITUS.LUVUN_POISTAMINEN
        }

        if (newValue.length === 2) {
            return INPUT_TARKOITUS.NORMAALI_TUNNIN_LISAYS
        }

        if (caretPosition === INDEX_BEFORE_COLON_AFTER_ADDING_NUMBER_BEFORE_IT) {
            return INPUT_TARKOITUS.LUVUN_LISAYS_JUURI_ENNEN_KAKSOISPISTETTA
        }

        const yliMaksimiMaaran = newValue.length > 5

        if (yliMaksimiMaaran && caretPosition === newValue.length) {
            return INPUT_TARKOITUS.LUVUN_LISAYS_AIVAN_LOPPUUN
        }

        const kolmeKirjaintaEnnenKaksoispistetta = /[0-9]{3}:/.test(newValue)
        if (yliMaksimiMaaran || kolmeKirjaintaEnnenKaksoispistetta) {
            return INPUT_TARKOITUS.LUVUN_YLIKIRJOITUS
        }

        return INPUT_TARKOITUS.NORMAALI_LISAYS
    }

    static formTimeFromGivenMinutes(currentInput, chosenMinutes) {
        let parsedHours = _parseHoursFromGivenInput(currentInput)
        parsedHours = _addDefaultValuesToHourAsNeededWithColon(parsedHours)

        const timeValue = parsedHours + ':' + chosenMinutes

        return timeValue
    }

    static formTimeFromGivenHours(currentInput, chosenHours) {
        const parsedHours = currentInput.substring(3)

        const timeValue = chosenHours + ':' + parsedHours

        return timeValue
    }

    static convertTimeValueToString(timeValue) {
        return timeValue < 10 ? '0' + timeValue : '' + timeValue
    }
}

function _getRangeOfNumbers(exlusionaryLimit) {
    return [...Array(exlusionaryLimit).keys()]
}

function _convertTimeNumbersToStrings(timeValues = []) {
    return timeValues.map((timeValue) => TimeInputUtility.convertTimeValueToString(timeValue))
}

function _getAvailableHours() {
    const hoursAsNumbers = _getRangeOfNumbers(24)
    const hoursWithZeroAddedInEnd = hoursAsNumbers.concat(0)

    return _convertTimeNumbersToStrings(hoursWithZeroAddedInEnd)
}

function _getAvailableMinutes() {
    const minutesAsNumbers = _getRangeOfNumbers(60)
    const minutesWithZeroAddedInEnd = minutesAsNumbers.concat(0)

    return _convertTimeNumbersToStrings(minutesWithZeroAddedInEnd)
}

export const AVAILABLE_HOURS = _getAvailableHours()
export const AVAILABLE_MINUTES = _getAvailableMinutes()
