import * as React from 'react'
import ReactMarkdown from 'markdown-to-jsx'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const ListItem: React.FC = (props: TypographyProps) => (
    <li>
        <Typography component="span" {...props} />
    </li>
)

/*
Taken in slightly edited form from
https://github.com/mui-org/material-ui/blob/master/docs/src/pages/getting-started/templates/blog/Markdown.js
*/
const defaultOptionsForStylingMarkdown = {
    overrides: {
        h1: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h5',
            },
        },
        h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
        h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
        h4: {
            component: Typography,
            props: { gutterBottom: true, variant: 'caption', paragraph: true },
        },
        p: { component: Typography, props: { paragraph: true }, variant: 'bodyS' },
        a: { component: Link },
        li: ListItem,
    },
    forceBlock: true,
}

type TReactMarkdownProps = React.ComponentProps<typeof ReactMarkdown>
interface IMarkDownProps extends TReactMarkdownProps {}

/**
 * Markdown component to render a markdown string as formatted text.
 * Uses MUI's Typography and other components to render the text.
 *
 * If options are given, merges those with the default options defined
 * above. Otherwise simply uses the default options.
 */
const MarkDown: React.FC<IMarkDownProps> = ({ children, options }) => {
    const optionsToUse = options ? { ...defaultOptionsForStylingMarkdown, options } : defaultOptionsForStylingMarkdown
    return <ReactMarkdown options={optionsToUse}>{children}</ReactMarkdown>
}

export default MarkDown
