import { FC } from 'react'

import { success_100, alert_100, dark_100 } from 'constants/Styles'
import Icon from './Icon'

enum StatusIconState {
    Alert = 1,
    CautionNoEmployee = 2,
    Caution = 3,
    Published = 4,
    Booked = 5,
    NonAssignedPublishedPast = 6,
}
interface IStatusIconProps {
    tooltip: string
    status: number
}

const StatusIcon: FC<IStatusIconProps> = ({ status }) => {
    // const { tooltip, icon, color } = getStatusFromItem(item)
    let color = ''
    let tooltip = ''
    let icon = ''

    switch (status) {
        case StatusIconState.Alert:
            color = alert_100
            tooltip = 'Vuoro on julkaistu, ei työntekijää, vuoron alkuaika lähestyy'
            icon = 'error_outline'
            break
        case StatusIconState.CautionNoEmployee:
            color = dark_100
            tooltip = 'Vuoroa ei ole julkaistu, ei työntekijää'
            icon = 'warning_amber'
            break
        case StatusIconState.Caution:
            color = dark_100
            tooltip = 'Vuoroa ei ole julkaistu, mutta vuorossa on työntekijä'
            icon = 'warning'
            break
        case StatusIconState.Published:
            color = success_100
            tooltip = 'Vuoro julkaistu, vuorossa ei työntekijää'
            icon = 'check_circle_outline'
            break
        case StatusIconState.Booked:
            color = success_100
            tooltip = 'Vuoro julkaistu, vuorossa on työntekijä'
            icon = 'check_circle'
            break
        case StatusIconState.NonAssignedPublishedPast:
            color = alert_100
            tooltip = 'Vuoro on julkaistu, vuorossa ei työntekijää, vuoron alkuaika menneisyydessä'
            icon = 'error'
            break
        default:
            color = ''
            tooltip = ''
            icon = ''
            break
    }

    return (
        <Icon color={color} tooltip={tooltip ?? ''} size={22}>
            {icon ?? 'check_circle'}
        </Icon>
    )
}

export default StatusIcon
