import * as React from 'react'

import { AggregatedDataUnconnected } from './AggregatedData'
import { IDataSourceGroupData } from '../../data-source-types'
import { getAggregatedData } from '../Utilities/GroupDataUtilities'
import renderDataAsVerticalSummary from './renderFunctions/renderDataAsVerticalSummary'

interface IOwnProps {
    groupData: IDataSourceGroupData[]
}

export interface IAggregatedDataProps extends IOwnProps {}

/**
 * Renders aggregated data like `AggregatedDataSummary` does, but takes the group data
 * as a direct property, instead of taking the data from the store by a data source Id.
 */
const AggregatedDataSummaryFromGroupData: React.FC<IAggregatedDataProps> = ({ groupData }) => {
    const aggregatedData = getAggregatedData(groupData)

    return (
        <AggregatedDataUnconnected
            aggregatedData={aggregatedData}
            dataSourceId={'DoesNotMatterhere'}
            oldAccumulatedData={[]}
            renderData={renderDataAsVerticalSummary}
        />
    )
}

export default AggregatedDataSummaryFromGroupData
