import { createAction } from 'typesafe-actions'

import { TDataSourceItemId } from '../../data-source-types'
import IConfigurableCalendarConfiguration from '../Types/IConfigurableCalendarConfiguration'

import IEmptyCellNode from '../Types/IEmptyCellNode'
import { ICalendarDataTemplate } from '../Types/ICalendarDataTemplate'

export const initializeConfigurableCalendarAction = createAction(
    'configurable-calendar.Initialized',
    (calendarId: string, configuration: IConfigurableCalendarConfiguration) => ({
        calendarId,
        configuration,
    })
)()

export const setCalendarDataTemplates = createAction(
    'configurable-calendar.Set-Data-Templates',
    (calendarId: string, templates: ICalendarDataTemplate[]) => ({
        calendarId,
        templates,
    })
)()

export const setEmptyCellNodesAction = createAction(
    'configurable-calendar.Set-Empty-Cell-Nodes',
    (calendarId: string, nodes: IEmptyCellNode[]) => ({
        calendarId,
        nodes,
    })
)()

export const deselectCalendarNodeAction = createAction(
    'configurable-calendar.DeselectNode',
    (calendarId: string, nodeId: TDataSourceItemId) => ({
        calendarId,
        nodeId,
    })
)()

type TCalendarId = string
export const resetCalendarNodeSelectionsAction = createAction('configurable-calendar.ResetNodeSelection')<TCalendarId>()

type TActiveCalendarId = string
export const resetNonActiveCalendarsNodeSelectionsAction = createAction(
    'configurable-calendar.ResetNonActiveCalendarsNodeSelection'
)<TActiveCalendarId>()

export const setSelectedCalendarNodesAction = createAction(
    'configurable-calendar.SelectNode',
    (calendarId: string, nodeIds: TDataSourceItemId[], resetPreviousSelection = true) => ({
        calendarId,
        nodeIds,
        resetPreviousSelection,
    })
)()

export const setCalendarInitialValuePickersValues = createAction(
    'configurable-calendar.setCalendarInitialValuePickersValues',
    (calendarId: string, valuePickersValues: ReadonlyMap<string, unknown>) => ({
        calendarId,
        valuePickersValues,
    })
)()

export const setCalendarGroupNodeSearchFilter = createAction(
    'configurable-calendar.setCalendarGroupNodeSearchFilter',
    (calendarId: string, groupNodeSearchFilter: string) => ({
        calendarId,
        groupNodeSearchFilter,
    })
)()
