import { RootState } from 'typesafe-actions'
import { INITIAL_STATE } from './AsetusReducer'

export const selectAsetukset = (state: RootState): typeof INITIAL_STATE => {
    return state.asetukset
}

export const selectIsUserInstructionsEnabled = (state: RootState): boolean => {
    return !!selectAsetukset(state).get('ActiveInstructionsEnabled') ?? false
}
