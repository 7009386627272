import { createReducer } from 'typesafe-actions'

import {
    asyncOperationStartedAction,
    asyncOperationSucceededAction,
    asyncOperationFailedAction,
} from './AsyncOperationActions'
import IAsyncOperationReducerState from '../Types/IAsyncOperationReducerState'

const initialState: IAsyncOperationReducerState = new Map()

export const asyncOperationReducer = createReducer(initialState)
    .handleAction(asyncOperationStartedAction, (state, action) => {
        const copiedState = new Map(state)

        const operationState = {
            ...action.payload,
            error: null,
            ready: false,
            isInProgress: true,
        }

        copiedState.set(operationState.id, operationState)

        return copiedState
    })
    .handleAction(asyncOperationSucceededAction, (state, action) => {
        const copiedState = new Map(state)

        const operationState = {
            ...action.payload,
            error: null,
            ready: true,
            isInProgress: false,
            cancelTokenSource: undefined,
        }

        copiedState.set(operationState.id, operationState)

        return copiedState
    })
    .handleAction(asyncOperationFailedAction, (state, action) => {
        const copiedState = new Map(state)

        const operationState = {
            ...action.payload,
            ready: true,
            isInProgress: false,
            cancelTokenSource: undefined,
        }

        copiedState.set(operationState.id, operationState)

        return copiedState
    })

export default asyncOperationReducer
