import MaterialUICheckbox from '@material-ui/core/Checkbox'
import * as React from 'react'

import styled from '@emotion/styled'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'

import Icon from '../../Icon'
import ReadOnlyText from '../../ReadOnlyText'

export interface ICheckboxProps {
    id?: string
    className?: string
    value?: boolean | null
    disabled?: boolean
    label?: string
    noPadding?: boolean
    onClick: (newChecked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void
    indeterminate?: boolean
    variant?: 'small' | 'large'
}

type CheckboxIconProps = {
    disabled: boolean | undefined
    variant?: 'small' | 'large'
}

const StyledCheckbox = styled(MaterialUICheckbox, { shouldForwardProp: (propName) => propName !== 'noPadding' })<{
    noPadding?: boolean
}>`
    &:hover {
        background-color: ${({ theme }) => theme.componentExtensions.inputs.textInputHelperDefault}15;
    }

    padding: ${({ noPadding }) => (!noPadding ? '4px' : '0')};
`

const StyledIcon = styled(Icon)<CheckboxIconProps>`
    && {
        font-size: ${({ variant }) => (variant === 'large' ? '24px' : '20px')};

        ${({ disabled, theme }) =>
            disabled &&
            `
            color: ${theme.componentExtensions.icons.iconDisabledLighter};
            background-color: transparent;
        `}
    }
`

const UncheckedStyledIcon = styled(StyledIcon)<CheckboxIconProps>`
    ${({ disabled, theme }) =>
        !disabled &&
        `
        color: ${theme.componentExtensions.inputs.borderInputDefault};
    `}
`

const IndeterminateStyledIcon = styled(IndeterminateCheckBoxOutlinedIcon)<CheckboxIconProps>`
    font-size: 16px;
    color: #a1b4c0;

    ${({ disabled, theme }) =>
        disabled &&
        `
        color: ${theme.componentExtensions.icons.iconDisabledDarker};
    `}
`

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const CheckedIcon = ({ disabled, variant }: CheckboxIconProps): JSX.Element => {
    return (
        <StyledIcon disabled={disabled} variant={variant}>
            check_box
        </StyledIcon>
    )
}

const UncheckedIcon = ({ disabled, variant }: CheckboxIconProps): JSX.Element => {
    return (
        <UncheckedStyledIcon disabled={disabled} variant={variant}>
            check_box_outline_blank
        </UncheckedStyledIcon>
    )
}

const IndeterminedIcon = ({ disabled, variant }: CheckboxIconProps): JSX.Element => {
    // interminate_check_box_outlined doesn't work so we'll just use the Material UI icon here directly.
    return <IndeterminateStyledIcon disabled={disabled} variant={variant} />
}

const Checkbox = ({
    id,
    className,
    value = false,
    disabled = false,
    indeterminate = false,
    noPadding = false,
    variant = 'small',
    onClick,
    label,
    ...props
}: ICheckboxProps): JSX.Element => {
    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, newChecked: boolean) => {
        onClick(newChecked, event)
    }

    const handleClick = (event: React.MouseEvent) => {
        // This is to make sure if an element containing the checkbox has onClick handler,
        // we don't trigger that separately from the onChange handler.
        event.stopPropagation()
    }

    const checkbox = (
        <StyledCheckbox
            {...props}
            id={id}
            className={className}
            checked={Boolean(value)}
            color="primary"
            icon={<UncheckedIcon disabled={disabled} variant={variant} />}
            checkedIcon={<CheckedIcon disabled={disabled} variant={variant} />}
            indeterminateIcon={<IndeterminedIcon disabled={disabled} variant={variant} />}
            indeterminate={value === null || indeterminate}
            disabled={disabled}
            noPadding={noPadding}
            onChange={handleCheck}
            onClick={handleClick}
        />
    )

    // separate return for non-labeled checkbox to avoid unnecessary extra DOM depth
    if (!label) {
        return checkbox
    }

    return (
        <CheckboxContainer>
            {checkbox}

            <label htmlFor={id}>
                <ReadOnlyText usage="bodyS">{label}</ReadOnlyText>
            </label>
        </CheckboxContainer>
    )
}

export default Checkbox
