export default {
    1: 'fil',
    2: 'en',
    3: 'pl',
    4: 'sv',
    5: 'sk',
    6: 'fi',
    7: 'ru',
}

export const kieliToId = {
    fil: 1,
    en: 2,
    pl: 3,
    sv: 4,
    sk: 5,
    fi: 6,
    ru: 7,
}
