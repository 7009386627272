import { getValueAsArray } from '../../../generic-utilities'
import makeRequest, { ERequestMethod } from '../../../rest-api'
import { IThunkBaseAction } from '../../../generic-state'
import { Dispatch } from 'react'
import { IEventTemplate } from '../Types/types'
import {
    editClosed,
    eventTemplatesFetched,
    selectedCellsLoadingStarted,
    selectedCellsLoadingEnded,
    holidaysFetched,
} from './CalendarState'
import { CALENDAR_DATASOURCE_ID, EMPLOYEE_DATASOURCE_ID, WORKUNIT_DATASOURCE_ID } from '../Constants'
import { fetchDataSourceDataThunk, initializeDataSourceThunk } from '../../../data-source'
import moment from 'moment'
import { CalendarConfiguration, DatePickerId } from '../temp/temp'

export const fetchEventTemplatesThunk =
    (employeeIdValuePickerValue: unknown, workunitIdsValuePickerValue: unknown) =>
    async (dispatch: Dispatch<any>): Promise<void> => {
        const url = '/v2/Tapahtuma/EventTemplate/Search'

        const employeeIds = getValueAsArray(employeeIdValuePickerValue) as number[] | null | undefined
        const workunitIds = getValueAsArray(workunitIdsValuePickerValue) as number[] | null | undefined

        const filters = {
            Filters: {
                EmployeeIds: employeeIds && employeeIds.length === 0 ? null : employeeIds,
                WorkUnitIds: workunitIds && workunitIds?.length === 0 ? null : workunitIds,
                CheckSuitability: true,
            },
        }

        const response = await makeRequest<{ ListData: IEventTemplate[] }>({
            url: url,
            method: ERequestMethod.POST,
            data: filters,
        })

        if (response) {
            dispatch(eventTemplatesFetched(response.ListData))
        }
    }

export const initCalendarContentThunk = (): IThunkBaseAction => async (dispatch) => {
    const initialSizeX = 14
    const initialStartDate = moment().startOf('isoWeek')
    const initialEndDate = initialStartDate.clone().add(initialSizeX - 1, 'days')

    const initialDatepickerValue = { startDate: initialStartDate, endDate: initialEndDate }
    const initialValuePickerValues = new Map<string, unknown>()
    initialValuePickerValues.set(DatePickerId, initialDatepickerValue)

    dispatch(
        initializeDataSourceThunk(EMPLOYEE_DATASOURCE_ID, {
            fetchData: false,
            valuePickerIds: [],
            initialValuePickersValues: initialValuePickerValues,
            additionalDataSourceDataRequestParameters: {},
        })
    )

    dispatch(
        initializeDataSourceThunk(WORKUNIT_DATASOURCE_ID, {
            fetchData: false,
            valuePickerIds: [],
            initialValuePickersValues: initialValuePickerValues,
            additionalDataSourceDataRequestParameters: {},
        })
    )

    dispatch(
        initializeDataSourceThunk(CALENDAR_DATASOURCE_ID, {
            fetchData: true,
            valuePickerIds: CalendarConfiguration.valuePickerIds,
            initialValuePickersValues: initialValuePickerValues,
            additionalDataSourceDataRequestParameters: {
                UseCustomGroupBy: true,
                ExtraRows: 'Include',
                Filters: {
                    TapahtumaluokkaIdt: [1, 3, 6, 7, 8, 9, 10, 11, 12],
                    ShowDailyMeters: true,
                },
            },
        })
    )
}

export const initHolidaysThunk = (): IThunkBaseAction => async (dispatch) => {
    const holidays = await makeRequest<{ Date: string; Name: string }[]>({
        url: 'common/PublicHolidays',
        method: ERequestMethod.GET,
    })

    dispatch(holidaysFetched(holidays))
}

const regexToMatch = /^([0-1]?[0-9]|2[0-3])(:|.)[0-5][0-9]–([0-1]?[0-9]|2[0-3])(.|:)[0-5][0-9]$/

const autocompleteTimeStringWithZeroes = (input: string) => {
    switch (input.length) {
        case 5:
            return input

        case 4:
            //both cases 5:00 and 12:1
            if (input.split(':')[0].length < 2) {
                return '0' + input
            } else {
                return input + '0'
            }

        case 3:
            //both cases 5:0 and 05:
            if (input.endsWith(':')) {
                return input + '00'
            } else {
                return '0' + input + '0'
            }

        case 2:
            return input + ':00'

        case 1:
            return '0' + input + ':00'

        default:
            return input
    }
}

export const editCompletedThunk = (): IThunkBaseAction => async (dispatch, getState) => {
    const state = getState()

    if (!state.calendarv2.isEventTimeEdited) {
        return
    }

    await dispatch(selectedCellsLoadingStarted)

    try {
        let timePairString = state.calendarv2.editedTimeAsText

        const tempTimePairs = timePairString.split('–')

        const autocompletedPairs = tempTimePairs.map(autocompleteTimeStringWithZeroes)

        timePairString = tempTimePairs.length === 2 ? autocompletedPairs[0] + '–' + autocompletedPairs[1] : ''

        const isCompletedInput = regexToMatch.test(timePairString)

        if (isCompletedInput) {
            const timePairs = timePairString.split('–')

            const startTimeString = timePairs[0]

            const endTimeString = timePairs[1]

            const startTimeParts = startTimeString.split(':')

            const endTimeTimeParts = endTimeString.split(':')

            const [startTimeHours, startTimeMinutes] = startTimeParts.map((x) => parseInt(x))

            const [endTimeHours, endTimeMinutes] = endTimeTimeParts.map((x) => parseInt(x))

            const selectedCells = state.calendarv2.selectedCells

            const selectedDays = selectedCells.map((cell) => state.calendarv2.startDay.clone().add(cell.x, 'day'))

            const selectedIds = selectedCells.map((cell) => {
                return { cell: cell, id: state.calendarv2.idMap[cell.y][cell.x] }
            })

            const firstDay = selectedDays[0]

            if (
                startTimeHours !== undefined &&
                startTimeHours < 24 &&
                startTimeMinutes !== undefined &&
                startTimeMinutes < 60 &&
                endTimeHours !== undefined &&
                endTimeHours < 24 &&
                endTimeMinutes !== undefined &&
                endTimeMinutes < 60
            ) {
                const startTime = firstDay.clone().set('hour', startTimeHours).set('minutes', startTimeMinutes)

                const endTime = firstDay.clone().set('hour', endTimeHours).set('minutes', endTimeMinutes)

                const payloadForEmptyIds = selectedIds
                    .filter((selected) => selected.id === 'null')
                    .map((selected) => {
                        const groupData = state.calendarv2.groups[selected.cell.y]
                        const day = state.calendarv2.startDay.clone().add(selected.cell.x, 'day')

                        return {
                            Data: {
                                Tapahtumatyyppi: -1,
                                AsiakasId: groupData.groupProperties.workunitId,
                                AmmattinimikeId: groupData.groupProperties.jobTitleId,
                                Paivat: [day],
                                Alkamiskellonaika: startTime,
                                Loppumiskellonaika: endTime,
                                TyontekijaIdt: groupData.groupProperties.employeeId
                                    ? [groupData.groupProperties.employeeId]
                                    : [],
                            },
                        }
                    })

                const editedIds = selectedIds.filter((selected) => selected.id !== 'null')

                if (payloadForEmptyIds.some((x) => x)) {
                    await makeRequest({
                        url: 'v2/Tapahtuma',
                        method: ERequestMethod.POST,
                        data: {
                            Items: payloadForEmptyIds,
                        },
                    })
                }

                if (editedIds.some((x) => x)) {
                    await makeRequest({
                        url: 'v2/Tapahtuma',
                        method: ERequestMethod.PATCH,
                        data: {
                            Ids: editedIds.map((x) => x.id),
                            Data: {
                                Alkamiskellonaika: startTime,
                                Loppumiskellonaika: endTime,
                            },
                        },
                    })
                }

                dispatch(fetchDataSourceDataThunk(CALENDAR_DATASOURCE_ID))
                dispatch(editClosed)
            }
        } else {
            dispatch(editClosed)
        }
    } catch {
        //
    } finally {
        dispatch(selectedCellsLoadingEnded)
    }
}
