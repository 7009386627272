import { useEffect } from 'react'
import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import ConfigurableList, { selectSelectedItemIds, ConfigurableListPageTemplate } from '../../../configurable-list'
import { IConfigurableListValuePickerOwnProps, setValuePickerValueAction } from '../../../value-picker'
import { TDataSourceItemId } from '../../../data-source-types'

interface IStateProps {
    selectedItems: ReadonlySet<TDataSourceItemId>
}

interface IDispatchProps {
    setValuePickerValue: typeof setValuePickerValueAction
}

interface IProps extends IConfigurableListValuePickerOwnProps, IStateProps, IDispatchProps {}

const ConfigurableListValuePickerUnconnected: React.FunctionComponent<IProps> = ({
    listId,
    selectedItems,
    valuePickerId,
    setValuePickerValue,
    usePageTemplate,
}) => {
    useEffect(() => {
        setValuePickerValue(selectedItems, valuePickerId)
    }, [setValuePickerValue, valuePickerId, selectedItems])

    if (usePageTemplate) {
        return <ConfigurableListPageTemplate isValuePickerGroupFullWidth={false} listId={listId} />
    } else {
        return <ConfigurableList listId={listId} />
    }
}

const mapStateToProps = (state: RootState, { listId }: IConfigurableListValuePickerOwnProps) => ({
    selectedItems: selectSelectedItemIds(state, listId),
})

const mapDispatchToProps = {
    setValuePickerValue: setValuePickerValueAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurableListValuePickerUnconnected)
