import { FC } from 'react'
import styled from '@emotion/styled'
import { SubmitButtonWithValidationErrors, TranslatedButton } from '../../../generic-components'
import { Translation } from '../../../localization'

interface IFooterProps {
    HasSaveAndContinue?: boolean
    disableSubmit: boolean
    submit: () => void
    submitAndContinue: () => void
    displayedErrors: Record<string, string>
    renderCancel?: boolean
    formId?: string
    loading?: boolean
    onClose?: () => void
}

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    justify-self: flex-end;
    height: 82px;
    max-height: 82px;
    padding-right: 32px;
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};

    > * {
        margin-left: 8px;
    }
`

const Footer: FC<IFooterProps> = ({
    HasSaveAndContinue,
    submit,
    submitAndContinue,
    disableSubmit,
    displayedErrors,
    renderCancel,
    formId,
    loading,
    onClose,
}) => {
    return (
        <ButtonContainer>
            {renderCancel ? (
                <div data-testid="Stc_Form_CancelBtn">
                    <TranslatedButton onClick={onClose} variant="text">
                        button-cancel
                    </TranslatedButton>
                </div>
            ) : null}
            {!!HasSaveAndContinue && (
                <div data-testid="Stc_Form_SaveAndContinueBtn">
                    <SubmitButtonWithValidationErrors
                        disabled={disableSubmit}
                        errors={displayedErrors}
                        form={formId}
                        loading={loading}
                        onClick={submitAndContinue}
                    >
                        {Translation.translateKey('form-view.SaveAndContinueButton.Create')}
                    </SubmitButtonWithValidationErrors>
                </div>
            )}
            <div data-testid="Stc_Form_SubmitBtn">
                <SubmitButtonWithValidationErrors
                    disabled={disableSubmit}
                    errors={displayedErrors}
                    form={formId}
                    loading={loading}
                    onClick={submit}
                />
            </div>
        </ButtonContainer>
    )
}

export default Footer
