import * as React from 'react'

import { Field } from 'formik'
import styled from '@emotion/styled'
import { Button, FormikCheckbox, Icon, ReadOnlyText } from '../../../../generic-components'
import { ListItem, ListItemSecondaryAction } from '@mui/material'
import { IListViewColumnUserSettingsObject } from '../../../interfaces/IListViewUserSettingsForColumns'
import IconButton from '@material-ui/core/IconButton'
import { useAppTheme } from '../../../../theme'

type Props = {
    columnSettings: IListViewColumnUserSettingsObject
    onPinned: (columnId: string) => void
    lastRow?: boolean
    orderable?: boolean
    isPinned?: boolean
}

const RowContainer = styled(ListItem, { shouldForwardProp: (propName) => propName !== 'isPinned' })<{
    isPinned?: boolean
}>`
    display: flex;
    align-items: center;
    height: 48px;
    border-radius: 8px;
    margin-bottom: 4px;
    background-color: ${({ theme, isPinned }) =>
        !isPinned ? theme.componentExtensions.cards.bgCard : theme.colors.primaryChampion30};
`

const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const DragHandle = styled.div`
    display: flex;
    align-items: center;
    cursor: move;
`

const ColumnSettingsRow = ({ columnSettings, orderable, isPinned, onPinned }: Props): JSX.Element => {
    const { ListViewColumnId, Header } = columnSettings

    const { componentExtensions, colors } = useAppTheme()

    const handlePin = () => {
        onPinned(ListViewColumnId)
    }

    const pinColumnButton = !isPinned ? (
        <IconButton onClick={handlePin}>
            <Icon color={componentExtensions.icons.iconAction}>push_pin</Icon>
        </IconButton>
    ) : (
        <Button startIcon="clear" variant="text" onClick={handlePin}>
            <ReadOnlyText rawColor={colors.primaryTeal120} usage="bodyS" translate>
                configurableList.columnActions.unpin
            </ReadOnlyText>
        </Button>
    )

    return (
        <RowContainer isPinned={isPinned}>
            <Field
                component={FormikCheckbox}
                name={`${ListViewColumnId}.Visible`}
                id={ListViewColumnId}
                label={Header}
                variant="large"
            />

            <ListItemSecondaryAction>
                <ActionsContainer>
                    {pinColumnButton}

                    {orderable && (
                        <DragHandle className="drag-handle">
                            <Icon color={componentExtensions.icons.iconAction}>drag_indicator</Icon>
                        </DragHandle>
                    )}
                </ActionsContainer>
            </ListItemSecondaryAction>
        </RowContainer>
    )
}

export default ColumnSettingsRow
