import jwtDecode from 'jwt-decode'

interface IJwtExtractedData {
    exp: number
}

export const parseJwt = (token: string): IJwtExtractedData | null => {
    try {
        return jwtDecode<IJwtExtractedData>(token)
    } catch (e) {
        return null
    }
}
