import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { usePrevious } from '../../generic-utilities'
import { selectValuePickersValues } from '../State/ValuePickerSelectors'
import { isEqual } from 'lodash-es'

type TValuePickersValues = ReturnType<typeof selectValuePickersValues>

const valuePickersValueChanged = (
    previousValues: TValuePickersValues,
    currentValues: TValuePickersValues,
    valuePickerIds: string[]
) => {
    const anyValueHasChanged = valuePickerIds.some((id) => {
        const previousValue = previousValues[id]
        const newValue = currentValues[id]

        return !isEqual(previousValue, newValue)
    })

    return anyValueHasChanged
}

const useValuePickerValuesChanged = (valuePickerIds: string[]): boolean => {
    const valuePickersValues = useSelector<RootState, TValuePickersValues>((state) =>
        selectValuePickersValues(state, valuePickerIds)
    )

    const previousValuePickerValues = usePrevious(valuePickersValues)

    // first time this is run, the previous values is undefined and value hasn't changed yet
    if (previousValuePickerValues === undefined) {
        return false
    }

    const valuesChanged = valuePickersValueChanged(previousValuePickerValues, valuePickersValues, valuePickerIds)

    return valuesChanged
}

export default useValuePickerValuesChanged
