import * as React from 'react'
import Typography, { TypographyProps } from '@material-ui/core/Typography'

import { Translate, TTranslatable } from '../localization'

export interface ITranslatedTypographyProps extends TypographyProps {
    children: TTranslatable
}

const TranslatedTypography: React.FunctionComponent<ITranslatedTypographyProps> = ({ children, ...rest }) => (
    <Translate translate={{ children }}>
        {({ children: translatedChildren }) => <Typography {...rest}>{translatedChildren}</Typography>}
    </Translate>
)

export default TranslatedTypography
