import makeRequest, {
    ERequestMethod,
    makeRequestTemplate,
    IRequestAdditionalConfig,
    createCancelToken,
    makeAxiosRequest,
} from './RestApi'
import isServerError from './Utilities/isServerError'

export {
    makeAxiosRequest,
    createCancelToken,
    ERequestMethod,
    IRequestAdditionalConfig,
    isServerError,
    makeRequestTemplate,
}

export default makeRequest
