import * as React from 'react'
import Typography from '@material-ui/core/Typography'

import { TranslatedTypography } from '../../generic-components'
import ITyovuorolistapaketti from '../types/ITyovuorolistapaketti'
import DownloadActionWrapper from './DownloadActionWrapper'
import DateTime from 'components/atoms/DateTime'

export interface IColumnProps {
    paketti: ITyovuorolistapaketti
}

export const AlueColumn: React.FunctionComponent<IColumnProps> = ({ paketti }) => {
    if (!paketti.AlueId) {
        return <TranslatedTypography variant="bodyXS">int-ei-aluekohtainen</TranslatedTypography>
    }
    return <Typography variant="bodyXS">{paketti.AlueId}</Typography>
}

export const ActionColumn: React.FunctionComponent<IColumnProps> = ({ paketti }) => {
    if (paketti.TiedostoId) {
        return <DownloadActionWrapper id={paketti.TiedostoId} />
    }
    return <TranslatedTypography variant="bodyXS">Tyovuorolistapaketti.processing</TranslatedTypography>
}

export const NameColumn: React.FunctionComponent<IColumnProps> = ({ paketti }) => {
    if (!paketti.Nimi) {
        return <TranslatedTypography variant="bodyXS">int-ei-aluekohtainen</TranslatedTypography>
    }
    return <Typography variant="bodyXS">{paketti.Nimi}</Typography>
}

export const TimestampColumn: React.FunctionComponent<IColumnProps> = ({ paketti }) => {
    return <DateTime>{paketti.Aikaleima}</DateTime>
}
