import * as React from 'react'
import Alert, { AlertProps } from '@material-ui/core/Alert'
import styled from '@emotion/styled'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Styles, { alert_10 } from 'constants/Styles'

export interface IAlertBoxProps {
    children: React.ReactNode
    muiAlertProps?: AlertProps
    className?: string
    severity?: AlertProps['severity']
}

const icon = { error: <WarningIcon />, info: <InfoIcon />, success: <CheckCircleIcon /> }

const StyledAlert = styled(Alert)`
    text-align: left;
    align-items: center;

    &.MuiAlert-root {
        min-height: 60px;
    }
    &.MuiAlert-filledInfo {
        background-color: ${Styles.supplementaryColor.turquoise};
    }

    &.MuiAlert-filledError {
        background-color: ${alert_10};
    }

    &.MuiAlert-filledSuccess {
        background-color: ${Styles.supplementaryColor.seaGreen};
    }

    & .MuiAlert-icon {
        color: ${({ severity, theme }) => {
            if (severity === 'error' || severity === 'warning') {
                return theme.componentExtensions.icons.iconWarning
            }
            if (severity === 'info') {
                return Styles.supplementaryColor.turquoise
            }
            return Styles.supplementaryColor.seaGreen
        }};

        padding: 8px;
    }
    & .MuiAlert-message {
        line-height: 20px;
        letter-spacing: 0.23px;
        color: ${Styles.planierColor.gray.darkText};
        font-weight: 700;
    }
`

export const AlertBox: React.FC<IAlertBoxProps> = ({ className, children, severity, muiAlertProps }) => (
    <StyledAlert className={className} iconMapping={icon} severity={severity} variant="filled" {...muiAlertProps}>
        {children}
    </StyledAlert>
)

export default AlertBox
