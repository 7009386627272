import { useContext } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'

import Asetukset from 'constants/Asetukset'
import Image from 'components/atoms/Image'
import { LOGO_MAX_WIDTH } from '../Constants/LogoConstants'
import AsetuksetContext from 'context/AsetuksetContext'

const LogoContainer = styled.div`
    max-width: ${LOGO_MAX_WIDTH}px;
    height: 44px;
    padding: 4px 0 4px 20px;
`

const Logo: React.FC = () => {
    const settingsContext = useContext(AsetuksetContext)

    const logo = settingsContext.get(Asetukset.LoginsivuLogo)

    return logo === '' ? null : (
        <LogoContainer>
            <Image maxWidth={`${LOGO_MAX_WIDTH - 20}px`}>{logo as string}</Image>
        </LogoContainer>
    )
}

export default Logo
