import { useState, useEffect, useRef, useCallback } from 'react'
import * as React from 'react'
import useOnWindowResize from './useOnWindowResize'

interface IUseMoveItemFromArrowReturn {
    containerRef: React.RefObject<HTMLDivElement>
    handleForwardButtonClick: (event: React.SyntheticEvent) => void
    handleBackButtonClick: (event: React.SyntheticEvent) => void
    isBackElementEnabled: boolean
    isForwardElementEnabled: boolean
    areMoveButtonsNeededToBeDisplayed: boolean
}

type TMoveDirection = 'right' | 'left'

/**
 * Returns functions related to moving a component sideways.
 * Set the containerRef to the element containing all the elements (excluding
 * the buttons to move sideways from when the user clicks them) that you want
 * to move horizontally.
 *
 * You can control whether or not you want to move sideways only one item at a time,
 * or by as much as fits the container by giving the parameter. By default we move
 * by as much as fits the container.
 */
const useMoveHorizontallyThroughElements = (
    moveStrategy: 'byElement' | 'byContainer' = 'byContainer'
): IUseMoveItemFromArrowReturn => {
    const containerRef = useRef<HTMLDivElement>(null)

    const [isBackElementEnabled, setIsBackElementEnabled] = useState(false)
    const [isForwardElementEnabled, setIsForwardIconEnabled] = useState(true)

    const [areMoveButtonsNeededToBeDisplayed, setareMoveButtonsNeededToBeDisplayed] = useState(false)

    const getAreMoveButtonsNeeded = useCallback(() => {
        if (containerRef.current === null) {
            return false
        }

        const areMoveButtonsNeeded = containerRef.current.scrollWidth > containerRef.current.clientWidth

        return areMoveButtonsNeeded
    }, [])

    const updateVisibilityForMoveButtonsIfNeeded = useCallback(() => {
        const areMoveButtonsNeeded = getAreMoveButtonsNeeded()

        if (areMoveButtonsNeeded !== areMoveButtonsNeededToBeDisplayed) {
            setareMoveButtonsNeededToBeDisplayed(areMoveButtonsNeeded)
        }
    }, [areMoveButtonsNeededToBeDisplayed, getAreMoveButtonsNeeded])

    useOnWindowResize(() => {
        updateVisibilityForMoveButtonsIfNeeded()
    })

    useEffect(() => {
        const areMoveButtonsNeeded = getAreMoveButtonsNeeded()

        if (areMoveButtonsNeeded) {
            setareMoveButtonsNeededToBeDisplayed(true)
            setIsForwardIconEnabled(true)
        }
    }, [getAreMoveButtonsNeeded])

    const getAmountToMoveByForMovingByContainer = (element: Element, direction: TMoveDirection) => {
        let amountToMoveBy = 0

        if (!containerRef.current) {
            return 0
        }

        const dataItems = containerRef.current.children

        const wouldMoveTooMuch = (el: Element) => {
            if (!containerRef.current) {
                return true
            }
            return amountToMoveBy + el.clientWidth > containerRef.current.clientWidth
        }

        if (direction === 'right') {
            // When moving to right, calculate the amount to move by from the items on the right.
            // A simpler way to do this would be to simply move by the amount of the container, but
            // that wouldn't necessarily be quite as smooth. If an iten on the edge of the container
            // is displayed only partially at first, then the user would never see it fully. By counting
            // the amount to move by by looking at the size of the elements fitting the container fully,
            // we ensure that the user will see all of the items fully when they move.
            for (let i = dataItems.length - 1; i >= 0; i--) {
                const el = dataItems[i]

                if (wouldMoveTooMuch(el)) {
                    break
                }

                amountToMoveBy += el.clientWidth
            }
        } else {
            for (let i = 0; i < dataItems.length; i++) {
                const el = dataItems[i]

                if (wouldMoveTooMuch(el)) {
                    break
                }

                amountToMoveBy += el.clientWidth
            }
        }

        return amountToMoveBy
    }

    const getAmountToMoveBy = (element: Element, direction: 'left' | 'right') => {
        if (!containerRef.current) {
            return 0
        }

        if (moveStrategy === 'byElement') {
            return element.clientWidth
        }

        const amountToMoveBy = getAmountToMoveByForMovingByContainer(element, direction)

        return amountToMoveBy
    }

    const handleBackButtonClick: IUseMoveItemFromArrowReturn['handleBackButtonClick'] = (event) => {
        if (containerRef.current === null) {
            return
        }

        event.stopPropagation()

        setIsForwardIconEnabled(true)

        const dataItems = containerRef.current.children

        const containerLeftStartPosition = containerRef.current.getBoundingClientRect().left

        for (let i = dataItems.length - 1; i >= 0; i--) {
            const el = dataItems[i]
            const isElementOffScreenOnLeft = el.getBoundingClientRect().left > containerLeftStartPosition

            if (isElementOffScreenOnLeft) {
                continue
            }

            containerRef.current.scrollLeft -= getAmountToMoveBy(el, 'left')

            if (i === 0) {
                setIsBackElementEnabled(false)
            }
            break
        }
    }

    const handleForwardButtonClick: IUseMoveItemFromArrowReturn['handleForwardButtonClick'] = (event) => {
        if (containerRef.current === null) {
            return
        }

        event.stopPropagation()

        setIsBackElementEnabled(true)

        const dataItems = containerRef.current.children

        const containerLeftStartPosition = containerRef.current.getBoundingClientRect().left

        for (let i = 0; i < dataItems.length; i++) {
            const el = dataItems[i]
            const isElementOffScreenOnRight =
                el.getBoundingClientRect().right - containerLeftStartPosition + 1 < containerRef.current.clientWidth

            if (isElementOffScreenOnRight) {
                continue
            }

            containerRef.current.scrollLeft += getAmountToMoveBy(el, 'right')

            if (i === dataItems.length - 1) {
                setIsForwardIconEnabled(false)
            }
            break
        }
    }

    return {
        containerRef,
        handleForwardButtonClick,
        handleBackButtonClick,
        isBackElementEnabled,
        isForwardElementEnabled,
        areMoveButtonsNeededToBeDisplayed,
    }
}

export default useMoveHorizontallyThroughElements
