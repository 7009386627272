import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import Table from 'components/molecules/Table'
import { ITyovuorolistapaketitState } from '../reducers/TyovuorolistapakettiReducer'
import {
    getAllTyovuorolistapaketti,
    getTyovuorolistapaketti,
    initialiseTyovuorolistapakettiValuePickers,
    newTyovuorolistapaketti,
} from '../actions/TyovuorolistapakettiThunks'
import ITyovuorolistapaketti from '../types/ITyovuorolistapaketti'
import { IDateRangeNullable, selectValuePickerValue, TNullableDate, ValuePickerGroup } from '../../value-picker'
import { PageContainer } from '../../styling'
import TyovuorolistapakettiButtons from './Buttons'
import { ActionColumn, AlueColumn, NameColumn, TimestampColumn } from './Columns'
import { tyovuorolistapakettiValuePickerIds } from '../webApi/TyovuorolistapakettiConfiguration'
import TranslatedToggle from 'components/atoms/Toggle/TranslatedToggle'
import { BoundThunk } from '../../generic-state'
import { selectIsAsyncOperationIsInProgress } from '../../async-operation'
import { orderBy } from 'lodash-es'

interface IStateProps {
    tyovuorolistapaketit: ITyovuorolistapaketitState
    isLoading: boolean
    dateRange?: {
        start: TNullableDate
        end: TNullableDate
    }
    selectedAreas: ReadonlySet<number>
}

interface IDispatchProps {
    getAllTyovuorolistapaketti: BoundThunk<typeof getAllTyovuorolistapaketti>
    newTyovuorolistapaketti: BoundThunk<typeof newTyovuorolistapaketti>
    getTyovuorolistapaketti: BoundThunk<typeof getTyovuorolistapaketti>
    initialiseValuePickers: BoundThunk<typeof initialiseTyovuorolistapakettiValuePickers>
}

interface IState {
    isChecked: boolean
}

export interface ITyovuorolistapaketitProps extends IStateProps, IDispatchProps {}

const ContainerGrid = styled(Grid)`
    margin: 20px 20px 15px 15px;
`

const Cell = styled.div`
    height: 100%;
    width: 100%;
`

export class TyovuorolistapakettiUnconnected extends React.Component<ITyovuorolistapaketitProps, IState> {
    static defaultProps = { isLoading: false, isChecked: false }

    state = { isChecked: false }

    async componentDidMount(): Promise<void> {
        await this.refresh()
        this.props.initialiseValuePickers()
    }

    createPackage = async (): Promise<void> => {
        await this.props.newTyovuorolistapaketti(this.state.isChecked)
    }

    refresh = async (): Promise<void> => {
        await this.props.getAllTyovuorolistapaketti()
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    renderColumn(paketit: ITyovuorolistapaketti[], Component: any, name: string): JSX.Element[] {
        return paketit.map((paketti) => (
            <Cell key={`${name}${paketti.Id}`}>
                <Component paketti={paketti} />
            </Cell>
        ))
    }

    isDisabled(): boolean {
        const { isLoading, selectedAreas, dateRange } = this.props
        return (
            isLoading ||
            !selectedAreas ||
            selectedAreas.size === 0 ||
            !dateRange ||
            !dateRange.end ||
            !dateRange.start ||
            dateRange.start > dateRange.end
        )
    }

    handleChange = (_event: React.SyntheticEvent, value: boolean): void => {
        this.setState({ isChecked: value })
    }

    render(): React.ReactNode {
        const { tyovuorolistapaketit } = this.props.tyovuorolistapaketit
        const sortedTyovuorolistapaketit = orderBy(tyovuorolistapaketit, 'ID', 'desc')
        const columnHeaders = ['int-siirto-alue', 'Tyovuorolistapaketti.name', 'int-siirto-aikaleima', '']
        const columns = [
            this.renderColumn(sortedTyovuorolistapaketit, AlueColumn, 'alue'),
            this.renderColumn(sortedTyovuorolistapaketit, NameColumn, 'nimi'),
            this.renderColumn(sortedTyovuorolistapaketit, TimestampColumn, 'aikaleima'),
            this.renderColumn(sortedTyovuorolistapaketit, ActionColumn, 'actions'),
        ]
        return (
            <PageContainer>
                <ContainerGrid alignItems="flex-end" container spacing={1}>
                    <Grid item>
                        <ValuePickerGroup valuePickerIds={tyovuorolistapakettiValuePickerIds} />
                    </Grid>
                    <TranslatedToggle
                        checked={this.state.isChecked}
                        label={
                            this.state.isChecked ? 'Tyovuorolistapaketti.toggleOn' : 'Tyovuorolistapaketti.toggleOff'
                        }
                        onChange={this.handleChange}
                        switchProps={{ color: 'primary' }}
                    />

                    <TyovuorolistapakettiButtons
                        createPackage={this.createPackage}
                        disabled={this.isDisabled()}
                        isLoading={this.props.isLoading}
                        refresh={this.refresh}
                    />

                    <Table columnHeaders={columnHeaders} withBorders>
                        {columns}
                    </Table>
                </ContainerGrid>
            </PageContainer>
        )
    }
}

const mapStateToProps = (state: RootState): IStateProps => {
    const { tyovuorolistapaketit } = state

    return {
        tyovuorolistapaketit,
        isLoading: selectIsAsyncOperationIsInProgress(state, 'Tyovuorolistapaketti'),
        dateRange: selectValuePickerValue(state, 'Tyovuorolistapaketti.valuePicker.dateRange') as IDateRangeNullable,
        selectedAreas: selectValuePickerValue(state, 'Tyovuorolistapaketti.valuePicker.area') as ReadonlySet<number>,
    }
}

const mapDispatchToProps = {
    getAllTyovuorolistapaketti,
    newTyovuorolistapaketti,
    getTyovuorolistapaketti,
    initialiseValuePickers: initialiseTyovuorolistapakettiValuePickers,
}

export default connect(mapStateToProps, mapDispatchToProps)(TyovuorolistapakettiUnconnected)
