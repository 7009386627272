import { IThunkBaseAction } from '../../generic-state'
import { closeModalAction } from '../../modal'
import { resetUserCustomSettingsForColumns, saveColumnUserSettingsToServer } from '../webApi/ConfigurableListApi'
import { displayErrorToaster, displaySuccessToaster } from '../../notifications'
import { getObjectDiff } from '../../generic-utilities'
import IListViewUserSettingsForColumns from '../interfaces/IListViewUserSettingsForColumns'
import {
    asyncOperationFailedWithErrorDisplayThunk,
    asyncOperationStartedAction,
    asyncOperationSucceededAction,
} from '../../async-operation'
import { fetchListViewMetaDataThunk } from './ConfigurableListThunks'
import {
    hideColumnAction,
    resetColumnWidthsAction,
    saveUserSettingsForColumnsAction,
    setPinnedColumnAction,
} from '../State/ConfigurableListActions'
import EConfigurableListModal from '../Types/EConfigurableListModal'
import { compact, isEmpty, keyBy, map, mapValues, pickBy } from 'lodash-es'
import { resetColumnSettings, saveColumnOrder, savePinnedColumns } from '../Utilities/StorageUtils'

interface ISaveUserSettingsForColumns {
    (
        listId: string,
        settingsForColumns: IListViewUserSettingsForColumns,
        initialValues: IListViewUserSettingsForColumns
    ): IThunkBaseAction
}

export const saveSettingsForColumnsThunk: ISaveUserSettingsForColumns =
    (listId, settingsForColumns, initialValues) => async (dispatch) => {
        try {
            dispatch(closeModalAction(`${EConfigurableListModal.ColumnManagement}-${listId}`))

            dispatch(saveUserSettingsForColumnsAction(listId, settingsForColumns))

            const anyColumnHasValue = (value: string) => !isEmpty(compact(map(settingsForColumns, value)))

            if (anyColumnHasValue('CustomOrder')) {
                saveColumnOrder(listId, mapValues(settingsForColumns, 'CustomOrder'))
            }

            savePinnedColumns(listId, Object.keys(pickBy(mapValues(settingsForColumns, 'Pinned'))))

            const changedValueObjects: Partial<IListViewUserSettingsForColumns> = getObjectDiff(
                initialValues,
                settingsForColumns,
                ['CustomOrder', 'Pinned']
            )

            const changedValuesWithTheId = Object.keys(changedValueObjects).map((columnId) => {
                return {
                    ...changedValueObjects[columnId],
                    ListViewColumnId: columnId,
                }
            })

            await saveColumnUserSettingsToServer(listId, changedValuesWithTheId)

            dispatch(displaySuccessToaster('configurable-list.ColumnManagementModal.SaveSucceeded'))
        } catch (e) {
            dispatch(displayErrorToaster('configurable-list.ColumnManagementModal.SaveFailed'))
        }
    }

interface IResetUserCustomSettingsThunk {
    (listId: string): IThunkBaseAction
}

export const resetUserCustomSettingsForColumnsThunk: IResetUserCustomSettingsThunk =
    (listId: string) => async (dispatch) => {
        dispatch(asyncOperationStartedAction())

        try {
            await resetUserCustomSettingsForColumns(listId)

            resetColumnSettings(listId)
            dispatch(resetColumnWidthsAction(listId))

            await dispatch(fetchListViewMetaDataThunk(listId))

            dispatch(asyncOperationSucceededAction())
            dispatch(closeModalAction(`${EConfigurableListModal.ColumnManagement}-${listId}`))
        } catch (e) {
            await dispatch(asyncOperationFailedWithErrorDisplayThunk(e))
        }
    }

export const hideColumnThunk =
    (columnId: string, listId: string): IThunkBaseAction =>
    async (dispatch) => {
        dispatch(hideColumnAction(columnId, listId))

        await saveColumnUserSettingsToServer(listId, [{ ListViewColumnId: columnId, Visible: false }])
    }

export const pinColumnThunk =
    (columnId: string, listId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        dispatch(setPinnedColumnAction(columnId, listId))

        const state = getState().listData
        const listState = state[listId]

        if (!listState?.configuration) {
            return
        }

        const keyedColumns = keyBy(listState.configuration.Columns, 'Id')

        savePinnedColumns(listId, Object.keys(pickBy(keyedColumns, 'Pinned')))
        saveColumnOrder(listId, mapValues(keyedColumns, 'Order'))
    }
