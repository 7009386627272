import * as React from 'react'
import moment from 'moment'

import { ListElementText } from '../../configurable-list-components'

export interface IDurationInHoursProps {
    duration: number
    unit: 'hours' | 'minutes'
}

const getTwoDigitNumber = (number: number): string => `${number > 9 ? '' : '0'}${number}`

/**
 * Displays duration as H:mm
 */
const DurationInHours: React.FC<IDurationInHoursProps> = ({ duration, unit = 'minutes' }) => {
    // moment's duration doesn't directly support formatting to the correct format so we need to it do it
    // half manually here. The Math.floor is used because asHours for example can return a decimal.
    // moment's hours() method would work otherwise without the Math.floor but it only returns values
    // from 0 to 23.
    const momentDuration = moment.duration(duration, unit)
    const hours = Math.floor(momentDuration.asHours())
    const mins = Math.floor(momentDuration.asMinutes()) - hours * 60
    const minutesWithTwoDigits = getTwoDigitNumber(mins)

    return <ListElementText displayValue={`${hours}:${minutesWithTwoDigits}`} />
}

export default DurationInHours
