import AppBar, { AppBarProps } from '@material-ui/core/AppBar'
import { useContext } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'

import { Logo, LOGO_MAX_WIDTH } from '../../../../logo'
import MainDrawer from './MainDrawer'
import ApplicationBarTitle from './ApplicationBarTitle'
import MainMenu from 'components/main/molecules/MainMenu'
import { MainNavigationLegacy } from '../../../../navigation'
import Styles from 'constants/Styles'
import TyylitContext from 'context/TyylitContext'

interface IOwnProps {
    navItemsFitHorizontally: boolean
    pageTitle: string
    pageDescription: string | null
}

interface IApplicationBarProps extends IOwnProps {}

const StyledAppBar = styled<React.FC<AppBarProps & { backgroundColor: string | undefined }>>(
    ({ backgroundColor, ...rest }) => <AppBar {...rest} />
)`
    background-color: ${({ backgroundColor }) => backgroundColor};
    padding: 0px;
    min-height: ${Styles.layout.mainNavigationHeight};
    box-shadow: none;
    display: flex;
    height: ${Styles.layout.mainNavigationHeight};
    position: relative;
`

const AppBarContentContainer = styled.div`
    display: flex;
    height: 100%;
`

const NavigationItemsContainer = styled.div`
    display: flex;
    flex-grow: 1;
`

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    max-width: ${LOGO_MAX_WIDTH}px;
`

const ApplicationBarLegacy: React.FC<IApplicationBarProps> = ({
    navItemsFitHorizontally,
    pageTitle,
    pageDescription,
}) => {
    const stylesContext = useContext(TyylitContext)

    return (
        <div>
            <StyledAppBar backgroundColor={stylesContext.get('header.navigation.backgroundColor')}>
                <AppBarContentContainer>
                    <MainDrawer />

                    <LogoContainer>
                        <Logo />
                    </LogoContainer>

                    <ApplicationBarTitle
                        navItemsFitHorizontally={navItemsFitHorizontally}
                        pageDescription={pageDescription}
                        pageTitle={pageTitle}
                    />

                    <NavigationItemsContainer>
                        {navItemsFitHorizontally && (
                            <MainNavigationLegacy navItemsInDrawer={!navItemsFitHorizontally} />
                        )}
                    </NavigationItemsContainer>

                    <MainMenu navItemsInDrawer={!navItemsFitHorizontally} />
                </AppBarContentContainer>
            </StyledAppBar>
        </div>
    )
}

export default ApplicationBarLegacy
