import { ILoadingItemsData } from 'interfaces/actions/ILoadingAction'
import { displaySuccessToaster, displayErrorToaster } from '../../../../notifications'
import { Translation } from '../../../../localization'
import { IThunkBaseAction } from '../../../../generic-state'
import { setAdditionalInfoAcknowledgementStatus } from '../../../WebApi/QueryTapahtumas'
import { toggleRowItemSelectionAction, fetchListDataThunk } from '../../../../configurable-list'
import {
    asyncOperationFailedAction,
    asyncOperationSucceededAction,
    asyncOperationStartedAction,
} from '../../../../async-operation'

export interface IMarkAdditionalInformationAcknowledged {
    (acknowledge: boolean, id: string, dataForLoading: ILoadingItemsData): IThunkBaseAction
}

export const markAdditionalInformationAcknowledgedAction: IMarkAdditionalInformationAcknowledged =
    (acknowledge, tapahtumaId, { loadingFieldName, loadingItems }) =>
    async (dispatch) => {
        // Halt user interaction and display a loading spinner for this row.
        dispatch(asyncOperationStartedAction(loadingFieldName, loadingItems))

        try {
            await setAdditionalInfoAcknowledgementStatus(acknowledge, [tapahtumaId])

            await dispatch(fetchListDataThunk('VuorotJaVapaatList', { resetSelected: false }))

            dispatch(toggleRowItemSelectionAction(false, tapahtumaId, 'VuorotJaVapaatList'))

            dispatch(
                displaySuccessToaster(
                    Translation.translateKey(
                        acknowledge ? 'acknowledgement-succeeded' : 'acknowledgement-reset-succeeded'
                    )
                )
            )
            dispatch(asyncOperationSucceededAction(loadingFieldName))
        } catch (e) {
            dispatch(displayErrorToaster(Translation.translateKey('acknowledgement-failed')))
            dispatch(asyncOperationFailedAction(e, loadingFieldName))
        }
    }
