import * as React from 'react'

import TabsOnNavBar from './TabsOnNavBar'
import TabsInDrawer from './TabsInDrawer'

interface IOwnProps {
    navItemsInDrawer?: boolean
    closeDrawerCallback?: () => void
}

interface IMainNavigationLegacyProps extends IOwnProps {}

const MainNavigationLegacy: React.FC<IMainNavigationLegacyProps> = ({
    closeDrawerCallback,
    navItemsInDrawer = false,
}) => {
    return navItemsInDrawer ? <TabsInDrawer closeDrawerCallback={closeDrawerCallback} /> : <TabsOnNavBar />
}

export default MainNavigationLegacy
