import * as React from 'react'

import { Translation } from '../../localization'
import { ListElementText } from '../../configurable-list-components'

interface ILunchTimeTakenProps {
    lounasKesto: number | null
}

const translatedUnitText = Translation.translateKey('min-lyhyt')

const LunchTimeTaken: React.FC<ILunchTimeTakenProps> = ({ lounasKesto }) => {
    return lounasKesto ? (
        <ListElementText displayValue={`${lounasKesto} ${translatedUnitText}`} />
    ) : (
        <React.Fragment>-</React.Fragment>
    )
}

export default LunchTimeTaken
