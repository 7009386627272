import styled from '@emotion/styled'

import { UserBadge } from '../../generic-components'
import ListElementText from './ListElementText'
import { User } from '../../user'

type Props = {
    user: User | null
}

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`

const UserNameWithBadge = ({ user }: Props): JSX.Element | null => {
    if (!user) {
        return null
    }

    return (
        <Container>
            <UserBadge user={user} />
            <ListElementText displayValue={user.Name} />
        </Container>
    )
}

export default UserNameWithBadge
