/**
 * Kuvaa olion, jolla on käyttöoikeuksia
 */
export interface IPermissionableObject {
    Kayttooikeudet: IPermission[]
}

/**
 * Kuvaa yksittäisen käyttöoikeuden
 */
export interface IPermission {
    Toiminto: string
    OnkoSallittu: boolean
    SyyKieltoon: null | string
    AdditionalData?: { BadgeWarningLevel: 'Normal' | 'Warning'; BadgeInfo: string } | null
}

// TODO: tämä on enum, mutta onko järkeä siirtää constants-kansioon?
// TODO: tästä nyt tulee vähän yleisluontoinen (kaikki ohjelmiston luvalliset actionit)..
// ..mutta ei tähän taida keksiä parempaakaan tapaa? Useammaksi enumiksi jakaminen voisi
// myös mennä vähän hankalaksi
/**
 * Määrittää yksittäisen luvallisen actionin
 */
// eslint-disable-next-line no-shadow
export enum PermissionableAction {
    DELETE = 'DELETE',
    EDIT = 'UPDATE',
    CREATE = 'CREATE',
    VUORO_HYVAKSY = 'VUORO_HYVAKSY',
    PERU_VUORO_HYVAKSY = 'PERU_VUORO_HYVAKSY',
    VUORO_LISATIETO_KUITTAUS = 'VUORO_LISATIETO_KUITTAUS',
    VUORO_LISATIETO_KUITTAUS_PERUUTUS = 'VUORO_LISATIETO_KUITTAUS_PERUUTUS',
    KASITTELE = 'KASITTELE',
    VUORO_HYVAKSY_TOGGLE = 'VUORO_HYVAKSY_TOGGLE',
    UNDO_PROCESSING = 'UNDO_PROCESSING',
    PROCESS_WITHOUT_TRANSACTION_ROWS = 'PROCESS_WITHOUT_TRANSACTION_ROWS',
    DELETE_AND_UNDO_PROCESSING = 'DELETE_AND_UNDO_PROCESSING',
    DELETE_AND_DELETE_EVENT = 'DELETE_AND_DELETE_EVENT',
}
