import { createAction } from 'typesafe-actions'

import IStoredOptionsOptionIdentifierObject from '../Types/IStoredOptionsOptionIdentifierObject'
type StoredOptionsComponentId = string

export const storedOptionsInitializeAction = createAction('stored-options.Initialize')<StoredOptionsComponentId>()
export const storedOptionsDataFetchedAction = createAction('stored-options.DataFetched')<
    IStoredOptionsOptionIdentifierObject[],
    StoredOptionsComponentId
>()

export const storedOptionsSetDataAction = createAction('stored-options.SetData')<
    IStoredOptionsOptionIdentifierObject[],
    StoredOptionsComponentId
>()

export const storedOptionsDataFetchFailedAction = createAction('stored-options.DataFetchFailed')<
    StoredOptionsComponentId
>()

export const storedOptionsIsFetchingDataAction = createAction('stored-options.FetchingFilterData')<
    StoredOptionsComponentId
>()

export const storedOptionsResetOptionsForComponentsAction = createAction('stored-options.ResetOptionsForValuePickers')<
    StoredOptionsComponentId[]
>()

// TODO: We probably shouldn't have any loading related data in the stored options,
// but have it in the async-operation part of the state.
export const storedOptionsFinishedLoadingForComponentAction = createAction(
    'stored-options.FinishedLoadingForComponent'
)<StoredOptionsComponentId>()
