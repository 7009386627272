import styled from '@emotion/styled'
import { IIdentifier } from '../../event/Types/IEvent'
import { ReadOnlyText } from '../../generic-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectEventsByType } from '../State/EmployeeCalendarReducer'
import EventCategoryIndicator from './EventCategoryIndicator'
import { selectAreAllEventsSelectedByType } from '../State/EmployeeCalendarSelectors'
import { RootState } from 'typesafe-actions'
import { memo } from 'react'

type Props = {
    eventTypeCategory: IIdentifier
}

const Container = styled.button<{ isActive?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 8px 2px 12px;
    gap: 16px;
    outline-offset: 2px;
    outline: ${({ isActive, theme }) => isActive && `2px solid ${theme.componentExtensions.buttons.borderButtonFocus}`};
    border: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
    border-radius: ${({ theme }) => theme.tokens.radiusSmall};
    transition: background-color ${({ theme }) => theme.tokens.transitionQuick};

    &:hover {
        background-color: ${({ theme }) => theme.componentExtensions.buttons.bgButtonSecondaryHover};
    }
`

const EventTypeCategoryLegend = ({ eventTypeCategory }: Props): JSX.Element => {
    const dispatch = useDispatch()

    const isActive = useSelector((state: RootState) => selectAreAllEventsSelectedByType(state, eventTypeCategory))

    const handleClick = () => {
        dispatch(selectEventsByType(eventTypeCategory))
    }

    return (
        <Container data-testid="event-type-category-legend" isActive={isActive} onClick={handleClick}>
            <EventCategoryIndicator eventTypeCategory={eventTypeCategory} />
            <ReadOnlyText usage="bodyS">{eventTypeCategory.Name}</ReadOnlyText>
        </Container>
    )
}

export default memo(EventTypeCategoryLegend)
