import axios from 'axios'

export const getRefreshToken = async (): Promise<string | null> => {
    try {
        // Use axios directly here instead of rest-api module to avoid circular dependencies
        const { data: jwt } = await axios.post<string>('/refresh-token')
        return jwt
    } catch (e) {
        return null
    }
}
