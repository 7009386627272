import moment, { Moment, MomentInput, unitOfTime } from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sk'
import 'moment/locale/sv'
import 'moment/locale/pl'
import 'moment/locale/ru'

import { STRICT_MODE } from 'constants/Params'
import { Translation } from '../../localization'
import _Log from 'common/Log'

const Log = new _Log('DateUtility')

export type TLocale = 'fi' | 'en' | 'sk' | 'sv' | 'pl' | 'ru'

const SERVER_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
const STRICT_FORMAT = true

/**
 * Päivämäärien ja aikojen käsittelyyn tarkoitetut apufunktiot.
 *
 * @deprecated Use `dates` package instead.
 */
export default class DateUtility {
    /**
     * Palauttaa päivämäärän muodossa '<viikonpäivä> <päivä>.<kuukausi>.'.
     * Esimerkiksi 01.05.2015 -> 'pe 01.05.'.
     *
     * @param date Aika/päivämäärä.
     */
    static asDayDateMonth(date: MomentInput): string {
        return moment(date).format('dd DD.MM.')
    }

    static isMoment(value: MomentInput): boolean {
        return moment.isMoment(value)
    }

    static isMomentAsTime(value: MomentInput | null): boolean {
        return moment(value as MomentInput, 'HH:mm', STRICT_MODE).isValid()
    }

    static isValidMoment(value: MomentInput): boolean {
        return DateUtility.isMoment(value) && moment(value).isValid()
    }

    /**
     * @param value Tarkistettava pvm.
     */
    static isDate(value: MomentInput): boolean {
        return moment(value, 'DD.MM.YYYY', STRICT_MODE).isValid()
    }

    /**
     * Palauttaa päivämäärän muodossa '<päivä>.<kuukausi>.<vuosi>'. Esimerkiksi '1.5.2015'.
     *
     * @param date Aika/päivämäärä.
     */
    static asFullDate(date: MomentInput | null | undefined): string {
        if (date === null || date === undefined) {
            return Translation.translateKey('information-not-given')
        }

        return moment(date, SERVER_DATE_FORMAT, STRICT_FORMAT).format('D.M.YYYY')
    }

    /**
     * Palauttaa päivämäärävälin mahdollisimman lyhyessä muodossa. Mikäli alku- ja loppupäivämäärät ovat samat, palautetaan yksi päivämäärä.
     *
     * @param date Aika/päivämäärä.
     */
    static asDateRange(date1: MomentInput | null | undefined, date2: MomentInput | null | undefined): string {
        if (date1 === null || date2 === undefined || date2 === null || date2 === undefined) {
            return Translation.translateKey('information-not-given')
        }

        const date1Moment = moment(date1, SERVER_DATE_FORMAT, STRICT_FORMAT)
        const date2Moment = moment(date2, SERVER_DATE_FORMAT, STRICT_FORMAT)

        let returnValue = ''
        if (date1Moment.clone().add(1, 'days') <= date2Moment) {
            let formatForDate1 = 'D.M.'
            if (date1Moment.year() !== date2Moment.year()) {
                formatForDate1 += 'YYYY'
            }

            returnValue = date1Moment.format(formatForDate1) + '–' + date2Moment.format('D.M.YYYY')
        } else {
            returnValue = date1Moment.format('D.M.YYYY')
        }

        return returnValue
    }

    /**
     * Palauttaa päivämäärän muodossa '<päivämäärän lyhennelmä> <päivä>.<kuukausi>.<vuosi>.
     * Esim. 'pe 5.6.2018'
     */
    static asFullDateAndDayAbbreviation(date: MomentInput | null | undefined): string {
        if (date === null || date === undefined) {
            return Translation.translateKey('information-not-given')
        }

        return moment(date, SERVER_DATE_FORMAT, STRICT_FORMAT).format('dd D.M.YYYY')
    }

    /**
     * Palauttaa päivämäärän ja ajan muodossa '<päivämäärän lyhennelmä> <päivä>.<kuukausi>.<vuosi> <tunti>:<minuutti>.
     * Esim. 'pe 5.6.2018 13:50'
     */
    static asFullDateTimeWithDayAbbreviation(date: MomentInput | null | undefined): string {
        if (date === null || date === undefined) {
            Log.warn('No date object given for asFullDateTimeWithDayAbbreviation')
            return Translation.translateKey('information-not-given')
        }

        return moment(date, SERVER_DATE_FORMAT, STRICT_FORMAT).format('dd D.M.YYYY HH:mm')
    }

    /**
     * Palauttaa päivämäärän ja kellonajan muodossa '<päivä>.<kuukausi>.<vuosi> <tunti>:<minuutti>'.
     * Esimerkiksi '1.5.2015 8:15'.
     *
     * @param date Aika/päivämäärä.
     */
    static asFullDateTime(date: MomentInput | null): string {
        if (date === null) {
            return Translation.translateKey('information-not-given')
        }

        return moment(date).format('D.M.YYYY H:mm')
    }

    static isSameDate(dateTime: MomentInput, anotherDateTime: MomentInput): boolean {
        return moment(dateTime).startOf('day').isSame(moment(anotherDateTime).startOf('day'))
    }

    static isSame(dateTime: MomentInput, anotherDateTime: MomentInput): boolean {
        return moment(dateTime).isSame(moment(anotherDateTime))
    }

    /**
     * Palauttaa päivämäärän ja kellonajan muodossa '<vuosi>.<kuukausi>.<päivä>T<tunti>:<minuutti>'.
     * Esimerkiksi '2015-05-01T08:15:00'.
     *
     * @param momentObject Aika/päivämäärä.
     */
    static asLocalDateTimeString(momentObject: MomentInput | null | undefined): string | null {
        if (momentObject !== null && momentObject !== undefined) {
            return moment(momentObject).format('YYYY-MM-DDTHH:mm:ss')
        } else {
            return null
        }
    }

    /**
     * Palauttaa päivämäärän ja kellonajan muodossa '<vuosi>.<kuukausi>.<päivä>T<tunti>:<minuutti>'.
     * Esimerkiksi '2015-05-01T08:15:00'.
     *
     * @param momentObject Aika/päivämäärä niin että ajan sekunnit on nollattu.
     */
    static asLocalDateTimeStringWithSecondsZeroed(momentObject: MomentInput | null | undefined): string | null {
        if (momentObject === null || momentObject === undefined) {
            return null
        }

        const momentSekunnitNollattuna: Moment = DateUtility.nollaaSekunnit(momentObject) as Moment

        return momentSekunnitNollattuna.format('YYYY-MM-DDTHH:mm:ss')
    }

    /**
     * http://momentjs.com/docs/#/manipulating/start-of/
     * Käytetään esimerkiksi timepickeristä ulostulevan aikaolion sekuntien ja millisekuntien nollaamiseen
     * Esim: DateUtility.startOf(time, 'minute')
     *
     * HUOM! Jos päivämäärää ei ole annettu, palauttaa oletuksena kuluvan päivän!
     */
    static startOf(momentObject: MomentInput, timeSpec: unitOfTime.StartOf): Moment {
        return moment(momentObject).startOf(timeSpec)
    }

    /**
     * http://momentjs.com/docs/#/manipulating/end-of/
     * Käytetään esimerkiksi timepickeristä ulostulevan aikaolion arvojen asettamiseen
     *   viimeiseksi mahdolliseksi arvoksi.
     * Esim: DateUtility.startOf(time, 'minute')
     */
    static endOf(momentObject: MomentInput, timeSpec: unitOfTime.StartOf): Moment {
        return moment(momentObject).endOf(timeSpec)
    }

    /**
     * Palauttaa unix-aikaleiman sekunneissa annetun momentin perusteella
     *
     * @param date Aika/päivämäärä.
     */
    static asTicks(date: MomentInput): number {
        return Number.parseInt(moment(date).format('X'))
    }

    /**
     * Palauttaa ajan muodossa '<tunnit ilman etunollaa>:<minuutit>.'. Esimerkiksi 09:45:00 -> '9:45'.
     *
     * @param time Aika/päivämäärä.
     */
    static asHourMinutes(time: MomentInput): string {
        return moment(time, SERVER_DATE_FORMAT, STRICT_FORMAT).format('H:mm')
    }

    /**
     * Palauttaa ajan muodossa '<tunnit etunollan kanssa>:<minuutit>.'. Esimerkiksi 09:45:00 -> '09:45'.
     *
     * @param time Aika/päivämäärä.
     */
    static asFullHoursMinutes(time: MomentInput): string {
        return moment(time).format('HH:mm')
    }

    /**
     * Palauttaa ajan momenttina.
     *
     * @param date Aika/päivämäärä merkkijonona.
     */
    static asMoment(date: MomentInput | null | undefined): Moment | null {
        if (date === null || date === undefined) {
            return null
        } else {
            return moment(date)
        }
    }

    static asMomentFromTime(time: MomentInput): Moment {
        return moment(time, 'HH:mm', STRICT_MODE)
    }

    /**
     * Palauttaa viikonpäivät valitussa localessa taulukkona.
     * Esim. "ma", "ti" jne.
     */
    static localWeekdays(): string[] {
        return moment.weekdaysShort()
    }

    /**
     * Palauttaa true, mikäli "after" on ennen "before":a. Muuten palauttaa false.
     *
     * @param after  moment, Date tai mikä tahansa tyyppi jonka voi Momentiksi muuntaa
     * @param before moment, Date tai mikä tahansa tyyppi jonka voi Momentiksi muuntaa
     */
    static isAfter(after: MomentInput, before: MomentInput): boolean {
        return moment(after).isAfter(moment(before))
    }

    /**
     * Palauttaa true, mikäli "before" on ennen "after":ia, Muuten palauttaa false.
     *
     * @param after  moment, Date tai mikä tahansa tyyppi jonka voi Momentiksi muuntaa
     * @param before moment, Date tai mikä tahansa tyyppi jonka voi Momentiksi muuntaa
     */
    static isBefore(before: MomentInput, after: MomentInput): boolean {
        if (!before || !after) {
            Log.warn('At least one of the given times was null or undefined')
            return false
        }

        return moment(before).isBefore(moment(after))
    }

    /**
     * Palauttaa true, mikäli "time" on ennen nykyhetkeä.
     *
     * @param time
     */
    static isBeforeCurrentTime(time: MomentInput): boolean {
        if (!time) {
            Log.warn('The given time was null or undefined')
            return false
        }

        return moment(time).isBefore(moment())
    }

    /**
     * Palauttaa true, mikäli "checkdate" on "begindate":n ja "enddate":n välissä, Muuten palauttaa false.
     * incluseRangeDates-parametrilla sallitaan "checkdate":n olevan myös täsmälleen sama aika-arvo kuin
     * tarkistusvälin "begindate"/"enddate".
     *
     * @param checkdate  moment, Date tai mikä tahansa tyyppi jonka voi Momentiksi muuntaa
     * @param begindate moment, Date tai mikä tahansa tyyppi jonka voi Momentiksi muuntaa
     * @param enddate moment, Date tai mikä tahansa tyyppi jonka voi Momentiksi muuntaa
     * @param incluseRangeDates boolean, "checkdate" saa olla sama kuin tarkistusvälin alku tai loppu moment-olio.
     */
    static isBetween(
        checkdate: MomentInput,
        begindate: MomentInput,
        enddate: MomentInput,
        incluseRangeDates: boolean
    ): boolean {
        const incluseOption = incluseRangeDates ? '[]' : '()'
        const granularityOption = undefined

        return moment(checkdate).isBetween(moment(begindate), moment(enddate), granularityOption, incluseOption)
    }

    /**
     * Palauttaa nykyisen ajanhetken moment-oliona.
     */
    static now(): Moment {
        return moment()
    }

    /**
     * Palauttaa nykyisen päivän moment-oliona, jonka ajankohta on (edeltävä) keskiyö.
     */
    static thisDayFromStart(): Moment {
        return DateUtility.startOf(DateUtility.now(), 'date')
    }

    /**
     * Palauttaa nykyisen päivän moment-oliona, jonka ajankohta on juuri ennen
     * (tulevaa) keskiyötä
     */
    static thisDayFromEnd(): Moment {
        return DateUtility.endOf(DateUtility.now(), 'date')
    }

    /**
     * Asettaa päivämäärien muotoilun annetun localen muotoiseksi.
     * @param locale Localen kaksikirjaiminen tunnus, esim. fi.
     */
    static setLocale(locale: TLocale): void {
        moment.locale(locale)
    }

    /**
     * @param date Ajanhetki moment-oliona.
     */
    static timeFromNow(date: MomentInput | null): Moment | string {
        if (date === null) {
            return Translation.translateKey('information-not-given')
        }

        return moment(date).fromNow()
    }

    /**
     * Lisää annettuun dateTimeen halutun määrän päiviä
     *
     * @param  dateTime momentti johon lisätään minuutit
     * @param minutes lisättävien minuuttien määrä
     */
    static addMinutes(dateTime: MomentInput, minutes: number): Moment {
        const dateTimeMoment = moment(dateTime)

        return dateTimeMoment.add(minutes, 'minutes')
    }

    /**
     * Lisää annettuun dateTimeen halutun määrän päiviä
     *
     * @param  dateTime momentti johon lisätään päivät
     * @param minutes lisättävien päivien määrä
     */
    static addDays(dateTime: MomentInput, days: number): Moment {
        const dateTimeMoment = moment(dateTime)

        return dateTimeMoment.add(days, 'days')
    }

    /**
     * Vähentää annetusta momentista halutun määrän minuutteja
     *
     * @param dateTime momentti johon lisätään päivät
     * @param minutes lisättävien päivien määrä
     */
    static subtractMinutes(dateTime: MomentInput, minutes: number): Moment {
        const dateTimeMoment = moment(dateTime)

        return dateTimeMoment.subtract(minutes, 'minutes')
    }

    /**
     * Palauttaa muuttujan paramterin date, niin että siihen on asetettu parametrin time tunnit ja minuutit.
     * Huom. nollaa sekunnit.
     *
     * @param date päivämäärä
     * @param time kellonaika
     */
    static addTimeToDate(date: MomentInput, time: MomentInput): Moment {
        const momentDate = moment(date)
        const momentTime = moment(time)

        return momentDate.hours(momentTime.hour()).minute(momentTime.minute()).second(0)
    }

    /**
     * Palauttaa loppuajan, johon on lisätty yksi päivä mikäli alkuaika on loppuajan jälkeen
     * Muussa tapauksessa palauttaa loppuajan
     *
     * @param start alkuaika
     * @param end loppuaika
     */
    static addDayToEndIfNecessary(start: MomentInput, end: MomentInput): Moment {
        const momentStart = moment(start)
        const momentEnd = moment(end)

        return momentStart.isAfter(momentEnd) ? this.addDays(end, 1) : momentEnd
    }

    /**
     * Palauttaa ajanhetken n kuukautta taaksepäin kuluvasta hetkestä
     *
     * @param value kuukausien määrä.
     */
    static subtractMonths(value: number): Moment {
        return moment().subtract(value, 'month')
    }

    /**
     * Palauttaa ajanhetken n kuukautta eteenpäin kuluvasta hetkestä
     *
     * @param value kuukausien määrä.
     */
    static addMonths(value: number): Moment {
        return moment().add(value, 'month')
    }

    /**
     * Palauttaa ajanhetken n vuotta taaksepäin kuluvasta hetkestä.
     *
     * @param value vuosien määrä
     */
    static subtractYears(value: number): Moment {
        return moment().subtract(value, 'year')
    }

    /**
     * Palauttaa ajanhetken n vuotta eteenpäin kuluvasta hetkestä.
     *
     * @param value vuosien määrä
     */
    static addYears(value: number): Moment {
        return moment().add(value, 'year')
    }

    /**
     * Palauttaa ajanhetken jolle on asetettu annetut tunnit
     *
     * @param date aika jolle tunnit asetetaan
     * @param hours asetettavat tunnit
     */
    static setHours(date: MomentInput, hours: number): Moment {
        return moment(date).hour(hours)
    }

    /**
     * Palauttaa ajanhetken jolle on asetettu annetut tunnit
     *
     * @param date aika jolle minuutit asetetaan
     * @param minutes asetettavat minuutit
     */
    static setMinutes(date: MomentInput, minutes: number): Moment {
        return moment(date).minute(minutes)
    }

    /*
     * Palauttaa kahden ajankohdan eron millisekunteina. Huom. että ei
     * palauta itseisarvoa, vaan esim. jos ensimmäisenä annettu aika on myöhempi,
     * palauttaa negatiivisen arvon.
     */
    static difference(firstTime: MomentInput | undefined, secondTime: MomentInput | undefined): number {
        if (firstTime === undefined || secondTime === undefined) {
            throw new Error('Both parameters must have value')
        }
        return moment(firstTime).diff(secondTime)
    }

    /**
     * Palauttaa keskivälin kahdelta ajankohdalta.
     */
    static getMiddleValueOfTwoMoments(firstValue: Moment, secondValue: Moment): Moment {
        const firstInUnixTime = firstValue.unix()
        const secondInUnixTime = secondValue.unix()

        const middleTimeAsMoment = moment.unix((secondInUnixTime + firstInUnixTime) / 2)
        return middleTimeAsMoment
    }

    /**
     * Palauttaa joukon pienimmän päivämäärän
     */
    static getSmallestDate(dates: MomentInput[] | MomentInput): Moment | null {
        if (!Array.isArray(dates)) {
            return moment(dates)
        }

        if (dates.length === 0) {
            return null
        }

        return moment.min(...dates)
    }

    /**
     * Returns the "biggest" date of the given parameters.
     */
    static getLastDate(dates: MomentInput[] | MomentInput): Moment | null {
        if (!Array.isArray(dates)) {
            return moment(dates)
        }

        if (dates.length === 0) {
            return null
        }

        return moment.max(...dates)
    }

    /**
     * Palauttaa momentin niin, että sen sekunnit on nollattu.
     */
    static nollaaSekunnit(dateTime: MomentInput): Moment | null {
        if (!dateTime) {
            Log.warn('Tried to zero seconds on falsy dateTime')
            return null
        }

        return moment(dateTime).seconds(0)
    }

    /**
     * Palauttaa momentin viikonpäivän sen lyhyessä muodossa, esim. 'ma' ja 'ti'
     * @param datetime
     */
    static weekdayAbbreviation(dateTime: MomentInput | null): string | null {
        if (!dateTime) {
            Log.warn('Tried to format falsy value')
            return null
        }
        return moment(dateTime, SERVER_DATE_FORMAT, STRICT_FORMAT).format('dd')
    }

    static setDateToCurrent(dateTime: MomentInput): Moment {
        const now = moment()

        return moment(dateTime).year(now.year()).month(now.month()).date(now.date())
    }

    static daysAreDifferent(dateTime1: MomentInput | null, dateTime2: MomentInput | null): boolean {
        if ((dateTime1 && !dateTime2) || (!dateTime1 && dateTime2)) {
            return true
        }

        const dateTime1AsMoment = moment(dateTime1 as MomentInput)
        const dateTime2AsMoment = moment(dateTime2 as MomentInput)

        const isDifferentDay =
            dateTime1AsMoment.year() !== dateTime2AsMoment.year() ||
            dateTime1AsMoment.month() !== dateTime2AsMoment.month() ||
            dateTime1AsMoment.date() !== dateTime2AsMoment.date()

        return isDifferentDay
    }
}
