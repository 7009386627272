import { useRef, useCallback, useEffect } from 'react'

import TElementWithCustomProperties from './Types/TElementWithCustomProperties'
import TGroupsToRegisterForSyncedScrolling from './Types/TGroupsToRegisterForSyncedScrolling'
import { registerElementsForSyncedScrollGroup, resetGroups } from './syncscroll'

type TSetRefFunc = (element: Element | null) => void

const useSyncedScrolling = (groupObjects: TGroupsToRegisterForSyncedScrolling): TSetRefFunc => {
    const refForElement = useRef<Element | null>(null)

    const setRefFunc = useCallback<TSetRefFunc>(
        (element) => {
            if (!element || refForElement.current) {
                return
            }

            refForElement.current = element

            groupObjects.forEach(({ groupId, directions }) => {
                registerElementsForSyncedScrollGroup({
                    groupId,
                    directions,
                    elements: [element as TElementWithCustomProperties],
                })
            })
        },
        [groupObjects]
    )

    useEffect(() => {
        // cleanup when unmounting
        return () => {
            resetGroups(groupObjects.map(({ groupId }) => groupId))
        }
    }, [groupObjects])

    return setRefFunc
}

export default useSyncedScrolling
