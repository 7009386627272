import { useState } from 'react'

import { getLogger } from '../../log'
import { useInterval } from '../../generic-utilities'

const Log = getLogger('release-notes.ReleaseNotesUtils')

const idSeparator = '----'
const releaseNotesInLocalStorage = 'pln-ReleaseNotesSeen'

const saveSeenReleaseNotes = () => {
    if (!window.rnw || !window.rnw.releases) {
        Log.error('releasenotes not initialized')
        return
    }

    window.localStorage.setItem(
        releaseNotesInLocalStorage,
        window.rnw.releases.map((release) => release.id).join(idSeparator)
    )
}

const getLastSeenReleaseNotes = (): string[] => {
    const releases = window.localStorage.getItem(releaseNotesInLocalStorage)
    if (!releases) {
        return []
    }

    return releases.split(idSeparator)
}

export const getUnseenReleaseNotesCount = (): number | null => {
    if (!window.rnw || !window.rnw.releases) {
        return null
    }

    const lastSeenReleaseNotes = new Set(getLastSeenReleaseNotes())

    const currentReleaseNotes = window.rnw.releases

    return currentReleaseNotes.filter((release) => !lastSeenReleaseNotes.has(release.id)).length
}

interface IUseUnseenReleaseNotesCountReturn {
    count: number | null
    displayReleaseNotes: () => void
}

export const useUnseenReleaseNotesCount = (): IUseUnseenReleaseNotesCountReturn => {
    const [unseenReleaseNotesCount, setUnseenReleaseNotesCount] = useState<number | null>(null)

    // The releasenotes.io's rnw object gets its `releases` property when its request
    // for that is ready. That can be sometimes after we have re-rendered the icon
    // to open the release notes dialog for the last time. Because of that (and since
    // we don't control that request ourselves) we simply set an interval to check
    // every second whether or not the rnw has initialized fully.
    useInterval(
        () => {
            const newUnseenReleaseNotesCount = getUnseenReleaseNotesCount()

            if (unseenReleaseNotesCount !== newUnseenReleaseNotesCount) {
                setUnseenReleaseNotesCount(newUnseenReleaseNotesCount)
            }
        },
        1000,
        Boolean(window.rnw?.releases && unseenReleaseNotesCount !== null)
    )

    const displayReleaseNotes = (): void => {
        if (!window.rnw) {
            Log.error('releasenotes not initialized')
            return
        }

        window.rnw('show')

        saveSeenReleaseNotes()

        const newUnseenReleaseNotesCount = getUnseenReleaseNotesCount()

        if (unseenReleaseNotesCount !== newUnseenReleaseNotesCount) {
            setUnseenReleaseNotesCount(newUnseenReleaseNotesCount)
        }
    }

    return {
        count: unseenReleaseNotesCount,
        displayReleaseNotes,
    }
}
