import GetAppIcon from '@material-ui/icons/GetApp'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MuiMenuItem from '@material-ui/core/MenuItem'
import { Component } from 'react'
import FileSaver from 'file-saver'

import makeRequest, { ERequestMethod } from '../../../../rest-api'

const downloadFile = async (instructionId, fileName) => {
    const data = await makeRequest({
        method: ERequestMethod.GET,
        url: `/Tiedosto/download/ohje/${instructionId}`,
    })

    FileSaver.saveAs(data, fileName)
}

export default class OhjeMenuItem extends Component {
    constructor(props) {
        super(props)

        this._lataaOhje = this._lataaOhje.bind(this)
    }

    _lataaOhje() {
        const { ohjeId, ohjeenTiedostonimi, link } = this.props
        if (link) {
            window.open(link, '_blank')
        } else {
            downloadFile(ohjeId, ohjeenTiedostonimi)
        }
    }

    render() {
        return (
            <MuiMenuItem onClick={this._lataaOhje}>
                <ListItemIcon>{this.props.link ? <OpenInNewIcon /> : <GetAppIcon />}</ListItemIcon>
                {this.props.ohjeenTiedostonimi}
            </MuiMenuItem>
        )
    }
}
