import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel'
import Radio, { RadioProps } from '@material-ui/core/Radio'
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup'
import * as React from 'react'

interface IRadioButtonProps extends Partial<FormControlLabelProps> {
    label: FormControlLabelProps['label']
    radioProps: RadioProps
}

export interface IStyledRadioButtonGroupProps extends RadioGroupProps {
    radioButtonsProps?: IRadioButtonProps[]
}

const StyledRadioButtonGroup: React.FunctionComponent<IStyledRadioButtonGroupProps> = ({
    radioButtonsProps = [],
    ...passProps
}) => (
    <RadioGroup {...passProps}>
        {radioButtonsProps.map(({ radioProps, ...formControlLabelProps }, index) => (
            <FormControlLabel {...formControlLabelProps} control={<Radio {...radioProps} />} key={index} />
        ))}
    </RadioGroup>
)

export default StyledRadioButtonGroup
