import { Dispatch } from 'redux'
import { RootState } from 'typesafe-actions'
import {
    asyncOperationFailedAction,
    asyncOperationFailedWithErrorDisplayThunk,
    asyncOperationStartedAction,
    asyncOperationSucceededAction,
    selectAsyncOperationCancellationToken,
} from '../../async-operation'
import axios from 'axios'

import { createCancelToken, IRequestAdditionalConfig } from '../../rest-api'
import { displayErrorToaster, displaySuccessToaster } from '../../notifications'
import { errorOnPromiseFailed } from 'action-creators/ErrorActions'
import { IThunkBaseAction } from '../../generic-state'
import {
    EViewDataStatus,
    IDataSourceAccumulatedData,
    IDataSourceDataRequestGroupBy,
    IDataSourceDataRequestParameters,
    IDataSourceGroupData,
    IDataSourceItem,
    IDataSourceSortParameter,
    IListViewDataContainer,
    TDataSourceItemId,
} from '../../data-source-types'
import { fetchValuePickerConfigurationsThunk, IInitialValuePickersValues } from '../../value-picker'
import {
    downloadDataSourceData,
    queryAndDownloadDataSourceData,
    queryDataSourceAction,
    queryDataSourceCreateAction,
    queryDataSourceData,
    queryDataSourceDeleteAction,
    queryDataSourceMetadata,
    queryDataSourceUpdateAction,
} from '../WebApi/DataSourceWebApi'
import {
    selectDataFetchURL,
    selectDataSourceActionById,
    selectDataSourceConfiguration,
    selectDataSourceDependentDataSourceIds,
    selectDataSourceItemsByIds,
    selectIsDataSourceDataFetched,
    selectLoadingFieldName,
} from '../State/DataSourceSelectors'
import { TDataSourceId } from '../Types/IDataSource'
import {
    dataSourceReplaceItemAction,
    dataSourceSetDataAction,
    dataSourceSetFetchFiltersParametersAction,
    dataSourceSetFetchParametersAction,
    dataSourceSetOffsetAction,
    dataSourceSetSortSettingsAction,
    dataSourceSetValuePickerIdsAction,
    initialiseDataSourceAction,
} from '../State/DataSourceActions'
import IDataSourceAction from '../Types/IDataSourceAction'
import EDataSourceActionCrudType from '../Constants/EDataSourceActionCrudType'
import createDataItemActionRequestBody from '../Utilities/CreateDataItemActionRequestBody'
import IDataSourceConfiguration from '../Types/IDataSourceConfiguration'
import parseDataSourceData from '../Utilities/ParseDataSourceData'
import IDataSourceData from '../Types/IDataSourceData'
import { getDataSourceExcelOperationId } from '../Utilities/AsyncOperationIds'
import getDataFetchParameters from '../Utilities/getDataFetchParameters'
import { DEFAULT_OVERRIDE_REQUEST_PARAMETERS } from '../Constants/DataSourceQueryConstants'
import IFetchDataSourceDataOptions from '../Types/IFetchDataSourceDataOptions'
import { getLogger } from '../../log'

const Log = getLogger('data-source.DataSourceThunks')

interface IInitializeDataSourceParameters {
    valuePickerIds?: string[]
    fetchData?: boolean
    additionalDataSourceDataRequestFiltersParameters?: Record<string, unknown> | null
    additionalDataSourceDataRequestParameters?: null | Partial<IDataSourceDataRequestParameters>
    initialValuePickersValues?: null | IInitialValuePickersValues
    reInitialize?: boolean
}

interface IDataItemActionExecutionRequestParameters {
    actionParameters?: Record<string, unknown>
    additionalRequestProps?: Record<string, unknown>
    refetchAll?: boolean
}

const displayLoadingSpinner = (dispatch: Dispatch, loadingFieldNameForActualData?: string) => {
    dispatch(asyncOperationStartedAction(loadingFieldNameForActualData ?? 'global'))
}

const hideLoadingSpinner = (dispatch: Dispatch, loadingFieldNameForActualData?: string) => {
    dispatch(asyncOperationSucceededAction(loadingFieldNameForActualData ?? 'global'))
}

const dataSourceDataFetchedThunk =
    (
        dataSourceId: TDataSourceId,
        data: IDataSourceData | null,
        orderedItemIds: TDataSourceItemId[] | null,
        accumulatedData: IDataSourceAccumulatedData[],
        groupData: IDataSourceGroupData[] | null,
        dataStatus: EViewDataStatus
    ): IThunkBaseAction =>
    async (dispatch) => {
        dispatch(
            dataSourceSetDataAction({ dataSourceId, data, orderedItemIds, accumulatedData, groupData, dataStatus })
        )
    }

export const replaceDataSourceItemThunk =
    (dataSourceId: TDataSourceId, item: IDataSourceItem, previousId: TDataSourceItemId): IThunkBaseAction =>
    async (dispatch) => {
        dispatch(dataSourceReplaceItemAction(dataSourceId, item, previousId))
    }

const getDataSourceConfigurationThunk =
    (dataSourceId: string): IThunkBaseAction<IDataSourceConfiguration> =>
    async (dispatch, getState): Promise<IDataSourceConfiguration> => {
        const metadata = selectDataSourceConfiguration(getState(), dataSourceId)

        if (metadata) {
            return metadata
        }

        const queriedMetadata = await queryDataSourceMetadata(dataSourceId)

        dispatch(initialiseDataSourceAction(queriedMetadata))

        return queriedMetadata
    }

// TODO: when a component using data source is dependent on another, we should still probably be
// able to get the dependee's additionalFetchParameters to the requests fetching the data source data
// for the dependent data sources. Currently that is not possible.
const fetchDataForDependentDataSourcesThunk =
    (dataSourceId: TDataSourceId, originatingDataSourceId?: TDataSourceId): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const dependentDataSourceIds = selectDataSourceDependentDataSourceIds(state, dataSourceId)

        dependentDataSourceIds.forEach((id) => {
            if (id === originatingDataSourceId) {
                return
            }

            dispatch(
                fetchDataSourceDataThunk(id, {
                    originatingDataSourceId: originatingDataSourceId || dataSourceId,
                    fetchDependentDataSourceData: true,
                })
            )
        })
    }

export const fetchDataSourceDataExportedToExcelThunk =
    (
        dataSourceId: TDataSourceId,
        columns: string[],
        GroupByProperties: IDataSourceDataRequestGroupBy[],
        useGroupedData?: boolean
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        const asyncOperationId = getDataSourceExcelOperationId(dataSourceId)
        dispatch(asyncOperationStartedAction(asyncOperationId))

        try {
            const requestParameters = getDataFetchParameters(getState(), dataSourceId)
            const requestParametersWithFetchAllInsteadOfTruncate: typeof requestParameters = {
                ...requestParameters,
                ExtraRows: 'Include',
                GroupBy: GroupByProperties ? GroupByProperties : [],
            }

            await downloadDataSourceData(
                dataSourceId,
                requestParametersWithFetchAllInsteadOfTruncate,
                columns,
                useGroupedData
            )
            dispatch(asyncOperationSucceededAction(asyncOperationId))
        } catch (error) {
            dispatch(
                asyncOperationFailedWithErrorDisplayThunk(error, asyncOperationId, 'data-source.ExcelDownload.Error')
            )
        }
    }

export const fetchDataToExcel =
    (
        dataSourceId: TDataSourceId,
        datafetchURl: string,
        showLoadingSpinner = true,
        dynamicRequestParameters = DEFAULT_OVERRIDE_REQUEST_PARAMETERS
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        dispatch(asyncOperationStartedAction(dataSourceId, null, undefined))

        if (showLoadingSpinner) {
            displayLoadingSpinner(dispatch)
        }

        const state = getState()

        const itemIds = undefined

        const requestParameters = getDataFetchParameters(state, dataSourceId, itemIds, dynamicRequestParameters)

        try {
            await queryAndDownloadDataSourceData(datafetchURl, requestParameters)
        } catch (error) {
            dispatch(errorOnPromiseFailed(error))
            dispatch(asyncOperationFailedAction(error, dataSourceId))
        } finally {
            hideLoadingSpinner(dispatch)
        }
    }

export const fetchDataSourceDataThunk =
    (
        dataSourceId: TDataSourceId,
        {
            originatingDataSourceId,
            cancelToken,
            fetchDependentDataSourceData = false,
            resetOffset = false,
            showLoadingSpinner = true,
            dynamicRequestParameters = DEFAULT_OVERRIDE_REQUEST_PARAMETERS,
            saveDataToStore = true,
            datafetchURl = null,
        }: IFetchDataSourceDataOptions = {}
    ): IThunkBaseAction<IListViewDataContainer<IDataSourceItem> | null> =>
    async (dispatch, getState) => {
        if (resetOffset) {
            dispatch(dataSourceSetOffsetAction(dataSourceId, 0))
        }

        const _ExistingCancelTokenSource = selectAsyncOperationCancellationToken(getState(), dataSourceId)

        if (_ExistingCancelTokenSource) {
            _ExistingCancelTokenSource.cancel()
        }

        const currentCancelTokenSource = createCancelToken()

        dispatch(asyncOperationStartedAction(dataSourceId, null, currentCancelTokenSource))

        const state = getState()
        const fetchURL = datafetchURl ?? selectDataFetchURL(state, dataSourceId)

        if (showLoadingSpinner) {
            displayLoadingSpinner(dispatch)
        }

        let result: null | IListViewDataContainer<IDataSourceItem> = null

        try {
            if (fetchDependentDataSourceData) {
                dispatch(fetchDataForDependentDataSourcesThunk(dataSourceId, originatingDataSourceId))
            }

            const itemIds = undefined
            const requestParameters = getDataFetchParameters(state, dataSourceId, itemIds, dynamicRequestParameters)

            result = await queryDataSourceData(
                fetchURL,
                requestParameters,
                cancelToken || currentCancelTokenSource.token
            )

            const { ListData, AccumulatedData, GroupData, Status } = result

            const { orderedItemIds, data } = parseDataSourceData(ListData)

            if (saveDataToStore) {
                dispatch(
                    dataSourceDataFetchedThunk(dataSourceId, data, orderedItemIds, AccumulatedData, GroupData, Status)
                )
            }

            dispatch(asyncOperationSucceededAction(dataSourceId))
        } catch (error) {
            if (!axios.isCancel(error)) {
                dispatch(errorOnPromiseFailed(error))
                dispatch(asyncOperationFailedAction(error, dataSourceId))
            }
        }

        hideLoadingSpinner(dispatch)

        return result
    }

const fetchDataSourceSingleItemThunk =
    (
        dataSourceId: TDataSourceId,
        itemId: TDataSourceItemId,
        { fetchDependentDataSourceData = false, refetchAll = false }: IFetchDataSourceDataOptions = {}
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        const storeState = getState()
        const fetchURL = selectDataFetchURL(storeState, dataSourceId)

        const loadingFieldName = selectLoadingFieldName(dataSourceId)

        dispatch(asyncOperationStartedAction(loadingFieldName, { itemId }))

        try {
            if (fetchDependentDataSourceData) {
                dispatch(fetchDataForDependentDataSourcesThunk(dataSourceId))
            }

            const requestParameters = getDataFetchParameters(storeState, dataSourceId, [itemId], {
                parameters: { Offset: 0 },
            })

            // we need to get the groupdata when we update event/shift, WHY: for WFS, editing single item may lead to change
            //(eg. in case of work unit change) the group for the item so we need to fetch all the data.
            if (
                refetchAll ||
                dataSourceId === 'TarvenakymaDataSource' ||
                dataSourceId === 'TyontekijanakymaDataSource'
            ) {
                dispatch(fetchDataSourceDataThunk(dataSourceId))
            } else {
                const { Status, ListData: dataSourceData } = await queryDataSourceData(fetchURL, requestParameters)

                const { data } = parseDataSourceData(dataSourceData)

                if (!data) {
                    if (Status !== EViewDataStatus.OK) {
                        Log.error('ListData was null even though status was ok')
                        // Normally we update the status to the store, but since this search is only for a single item
                        // (and without some error happening we shouldn't get other status than OK) we don't do that here.
                    }
                    return
                }
                const item = data.get(itemId)

                if (item) {
                    dispatch(replaceDataSourceItemThunk(dataSourceId, item, itemId))
                } else {
                    // If an item can't be found with the given id, refetch all data for the given data source.
                    dispatch(fetchDataSourceDataThunk(dataSourceId))
                }
            }

            dispatch(asyncOperationSucceededAction(loadingFieldName))
        } catch (error) {
            dispatch(asyncOperationFailedWithErrorDisplayThunk(error, loadingFieldName))
        }
    }

const executeActionRequest = async <T = void>(
    action: IDataSourceAction,
    itemIds: TDataSourceItemId[],
    data: Record<string, unknown>,
    requestOptions?: IRequestAdditionalConfig
): Promise<T> => {
    if (action.CrudType === EDataSourceActionCrudType.Create) {
        return await queryDataSourceCreateAction(action, data, requestOptions)
    } else if (action.CrudType === EDataSourceActionCrudType.Update) {
        return await queryDataSourceUpdateAction(action, itemIds, data, requestOptions)
    } else if (action.CrudType === EDataSourceActionCrudType.Delete) {
        return await queryDataSourceDeleteAction(action, itemIds, requestOptions)
    } else {
        return await queryDataSourceAction(action, data, requestOptions)
    }
}
type TRefetchAll = (refetchAll?: boolean) => Promise<unknown>

type TDataSourceExecuteActionTemplateThunkFunctions = {
    before: () => void
    refetchData: TRefetchAll
    after: () => void
}

type TDataSourceExecuteActionTemplateThunkOptions = {
    actionRequestOptions?: IRequestAdditionalConfig
}

const dataSourceExecuteActionTemplateThunk =
    <T = void>(
        dataSourceId: TDataSourceId,
        itemIds: TDataSourceItemId[],
        actionId: string,
        { actionParameters = {}, additionalRequestProps = {} }: IDataItemActionExecutionRequestParameters = {},
        {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            before = () => {},
            // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
            refetchData = async (refetchAll) => {},
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            after = () => {},
        }: TDataSourceExecuteActionTemplateThunkFunctions,
        { actionRequestOptions }: TDataSourceExecuteActionTemplateThunkOptions = {}
    ): IThunkBaseAction<T> =>
    async (dispatch, getState) => {
        before()

        const state = getState()
        const actionConfiguration = selectDataSourceActionById(state, dataSourceId, actionId)

        try {
            if (!actionConfiguration) {
                throw new Error(`No action '${actionId}' defined in data source '${dataSourceId}'`)
            }

            const dataItems = selectDataSourceItemsByIds(state, dataSourceId, itemIds)
            const actionRequestBody = {
                ...createDataItemActionRequestBody(dataItems, actionConfiguration.DataPropertyParameters ?? null),
                ...actionConfiguration.StaticParameters,
                ...actionParameters,
                ...additionalRequestProps,
            }

            const result = await executeActionRequest<T>(
                actionConfiguration,
                itemIds,
                actionRequestBody,
                actionRequestOptions
            )

            await refetchData(actionConfiguration.RefetchAll)

            if (actionConfiguration.SuccessMessage) {
                dispatch(displaySuccessToaster(actionConfiguration.SuccessMessage))
            }

            return result
        } finally {
            // no error catch here because we let the caller handle the error
            after()
        }
    }

export const executeDataItemActionThunk =
    (
        dataSourceId: TDataSourceId,
        itemIds: TDataSourceItemId[],
        actionId: string,
        actionRequestParameters: IDataItemActionExecutionRequestParameters = {}
    ): IThunkBaseAction =>
    async (dispatch) =>
        await dispatch(
            dataSourceExecuteActionTemplateThunk(dataSourceId, itemIds, actionId, actionRequestParameters, {
                before: () => displayLoadingSpinner(dispatch),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                refetchData: async (refetchAll?: boolean) =>
                    await dispatch(
                        fetchDataSourceDataThunk(dataSourceId, {
                            fetchDependentDataSourceData: true,
                        })
                    ),
                after: () => hideLoadingSpinner(dispatch),
            })
        )

export const executeSingleDataItemActionThunk =
    (
        dataSourceId: TDataSourceId,
        itemId: TDataSourceItemId,
        actionId: string,
        actionRequestParameters: IDataItemActionExecutionRequestParameters = {},
        skipRefetchAll?: boolean
    ): IThunkBaseAction =>
    async (dispatch) => {
        const loadingFieldName = selectLoadingFieldName(dataSourceId)

        await dispatch(
            dataSourceExecuteActionTemplateThunk(dataSourceId, [itemId], actionId, actionRequestParameters, {
                before: () => dispatch(asyncOperationStartedAction(loadingFieldName, { itemId })),
                refetchData: async (refetchAll?: boolean) => {
                    return await dispatch(
                        fetchDataSourceSingleItemThunk(dataSourceId, itemId, {
                            fetchDependentDataSourceData: true,
                            refetchAll: refetchAll && !skipRefetchAll,
                        })
                    )
                },
                after: () => dispatch(asyncOperationSucceededAction(loadingFieldName)),
            })
        )
    }

export const downloadDataToFileThunk =
    (dataSourceId: TDataSourceId, itemIds: TDataSourceItemId[], actionId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        try {
            const state = getState()
            const actionConfiguration = selectDataSourceActionById(state, dataSourceId, actionId)

            if (!actionConfiguration) {
                throw new Error(`No action '${actionId}' defined in data source '${dataSourceId}'`)
            }

            const dataItems = selectDataSourceItemsByIds(state, dataSourceId, itemIds)
            const actionRequestBody = {
                ...createDataItemActionRequestBody(dataItems, actionConfiguration.DataPropertyParameters ?? null),
                ...actionConfiguration.StaticParameters,
            }

            await queryAndDownloadDataSourceData(actionConfiguration.EndpointUrl ?? '', actionRequestBody)
        } catch (e) {
            dispatch(displayErrorToaster(e))
        }
    }

const registerValuePickersThunk =
    (
        dataSourceId: TDataSourceId,
        valuePickerIds: string[],
        initialValuePickersValues: IInitialValuePickersValues | null = null
    ): IThunkBaseAction =>
    async (dispatch) => {
        await dispatch(fetchValuePickerConfigurationsThunk(valuePickerIds, initialValuePickersValues, false))

        dispatch(dataSourceSetValuePickerIdsAction(dataSourceId, valuePickerIds))
    }

export const initializeDataSourceThunk =
    (
        dataSourceId: TDataSourceId,
        {
            valuePickerIds,
            fetchData = true,
            additionalDataSourceDataRequestFiltersParameters,
            additionalDataSourceDataRequestParameters,
            initialValuePickersValues = null,
        }: IInitializeDataSourceParameters = {}
    ): IThunkBaseAction =>
    async (dispatch) => {
        await dispatch(getDataSourceConfigurationThunk(dataSourceId))

        if (additionalDataSourceDataRequestFiltersParameters) {
            dispatch(
                dataSourceSetFetchFiltersParametersAction(
                    dataSourceId,
                    additionalDataSourceDataRequestFiltersParameters
                )
            )
        }

        if (additionalDataSourceDataRequestParameters) {
            dispatch(dataSourceSetFetchParametersAction(dataSourceId, additionalDataSourceDataRequestParameters))
        }

        if (Array.isArray(valuePickerIds)) {
            await dispatch(registerValuePickersThunk(dataSourceId, valuePickerIds, initialValuePickersValues))
        }

        if (fetchData) {
            await dispatch(fetchDataSourceDataThunk(dataSourceId, { resetOffset: true }))
        }
    }

export const setSortSettingsAndFetchDataIfAvailableThunk =
    (
        state: RootState,
        dataSourceId: TDataSourceId,
        sortType: IDataSourceSortParameter['Order'] | undefined,
        sortByParameter: IDataSourceSortParameter['Property']
    ): IThunkBaseAction =>
    async (dispatch) => {
        dispatch(dataSourceSetSortSettingsAction(dataSourceId, sortType, sortByParameter))

        const dataHasAlreadyBeenFetchedAtLeastOnce = selectIsDataSourceDataFetched(state, dataSourceId)

        if (dataHasAlreadyBeenFetchedAtLeastOnce) {
            await dispatch(fetchDataSourceDataThunk(dataSourceId))
        }
    }
