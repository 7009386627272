const inter = 'Inter, arial'

export const legacyComponentStyles = {
    tooltip: {
        boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
    },
    layer: {
        background: 1,
        flowContent: 10,
        staticContent: 100,
        overlay: 1000,
        popup: 1300,
        dropdownMenu: 10000,
        tooltip: Number.MAX_SAFE_INTEGER - 1,
        loadingIndicator: Number.MAX_SAFE_INTEGER,
    },
    layout: {
        buttonBorderRadius: '3px',
        defaultMargin: '8px',
        defaultPadding: '8px',
        drawerWidth: '18.8rem',
        dropdownMenuMinHeight: '300px',
        smallPadding: '4px',
        pageDefaultLeftPadding: '1.250rem',
        filterAndListMinWidth: '69rem',
        paddingLeftForFilters: '1.3rem',
        wideTextAreaMaxWidth: '85.7rem',
        listIconWidth: '3.286',
        listButtonInfoIconSize: '1.5rem',
        listButtonInfoPicWidth: '240px',
        listRowAndHeaderHeight: '3.57rem',
        listRowHeight: '3.57rem',
        listRowHeightWithinForm: '5rem',
        listSubHeadingContainerHeight: '1.5rem',
        mainNavigationHeight: '4.571rem',
        pageContainer: {
            display: 'inherit',
            flexDirection: 'inherit',
            width: 'inherit',
            height: 'inherit',
        },
        expansionArrowBlock: {
            width: '1.500rem',
            margin: '0 0 0 0.714rem',
            bottom: '-0.3rem',
            cursor: 'pointer',
        },
        navigationIcon: {
            maxWidth: '8.214rem',
        },
        titleContainer: {
            paddingLeft: '1.25rem',
            marginTop: '1rem',
            position: 'absolute',
        },
        listTitleContainer: {
            marginBottom: '1.275rem',
        },
        listTitleMobileContainerMobile: {
            marginBottom: '1.275rem',
            paddingLeft: '0.25rem',
        },
        formTitleContainer: {
            paddingBottom: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
        },
        buttonContainer: {
            marginLeft: '-0.2rem',
            paddingBottom: '1.4rem',
            paddingLeft: '1.250rem',
            flexShrink: 0,
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: '2.143rem 2.143rem 1.714rem',
        },
        formContainerMobile: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: '0.7rem 0.7rem 1.714rem',
        },
        formContent: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            height: '100%',
            transform: 'translate3d(0, 0, 0)', // korjaa Applen laitteilla tapahtuvaa tekstien poistumista skrollatessa
        },
        formContentWithoutScrolling: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            transform: 'translate3d(0, 0, 0)',
        },
        formContentMobile: {
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            transform: 'translate3d(0, 0, 0)',
        },
        formButtons: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: 'auto',
            padding: '0.286rem 0rem 0.286rem 0.286rem',
            marginLeft: '0.286rem',
            flexShrink: 0,
            transform: 'translate3d(0, 0, 0)', // korjaa Applen laitteilla tapahtuvaa tekstien poistumista skrollatessa
        },
        filter: {
            display: 'inline-block',
            paddingRight: '8px',
            minWidth: '17.7rem',
            marginBottom: '8px',
        },
        dateRangeFilterContainer: {
            display: 'inline-block',
            paddingRight: '8px',
            minWidth: '22rem',
            marginBottom: '8px',
        },
        filterContainer: {
            display: 'inherit',
            alignItems: 'inherit',
            flexDirection: 'inherit',
        },
        tab: {
            marginSides: '1.429rem',
            width: '8.500rem',
            fontFamily: inter,
            fontSize: '1rem',
        },
        listContainer: {
            display: 'flex',
            width: '100%',
            height: '100%',
        },
        listPlaceholderContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            minHeight: '3.5rem',
        },
        placeholderText: {
            color: 'rgba(109, 110, 113, 0.5)',
            fontFamily: inter,
            fontSize: '1.071rem',
            fontStyle: 'italic',
        },
    },
    transition: {
        quick: '0.16s ease-in-out',
    },
}

export type LegacyComponentStylesOptions = typeof legacyComponentStyles
