import * as React from 'react'

import MuiMenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Icon from '../Icon'
import styled from '@emotion/styled'
import SubMenu from './SubMenu'
import Checkbox from '../Inputs/Checkbox'
import { IMenuItemOwnProps } from './Types/IMenuItemProps'
import { primary_100, primary_120 } from 'constants/Styles'
import IconButton from '@material-ui/core/IconButton'

const StyledMenuItem = styled(MuiMenuItem)`
    width: 100%;

    &.MuiMenuItem-root {
        height: 40px;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        justify-content: flex-start;
        align-items: center;

        &:hover {
            background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDropdownHover};
        }
    }
    & .MuiListItemIcon-root {
        min-width: 40px;
    }
`

const MenuItemLabel = styled.span`
    margin-right: 10px;
`

const StyledButton = styled(IconButton)`
    margin-left: auto;
    color: ${primary_100};

    &:hover {
        color: ${primary_120};
    }
`

const SecondaryActionButton = ({
    secondaryAction,
}: Required<Pick<IMenuItemOwnProps, 'secondaryAction'>>): JSX.Element => {
    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation()
        secondaryAction.onClick()
    }

    return (
        <StyledButton size="small">
            <Icon onClick={handleClick}>{secondaryAction.icon}</Icon>
        </StyledButton>
    )
}

const MenuItem = ({
    isSpaceToBeReservedForIcon = false,
    icon,
    label,
    onClick,
    onCloseMenu,
    subMenu,
    isDisabled = false,
    isCheckboxRendered = false,
    isSelected,
    autoFocus,
    secondaryAction,
    divider,
}: IMenuItemOwnProps): JSX.Element => {
    const handleClick = (event: React.SyntheticEvent) => {
        onClick && onClick(event)

        if (!isCheckboxRendered && !subMenu) {
            onCloseMenu && onCloseMenu()
        }
    }

    if (subMenu) {
        return (
            <SubMenu
                closeMenu={onCloseMenu ?? undefined}
                icon={icon}
                isSpaceToBeReservedForIcon={isSpaceToBeReservedForIcon}
                label={label}
                subMenu={subMenu}
            />
        )
    }

    const onClickHandler = (event: React.SyntheticEvent<Element, Event>) => {
        event.preventDefault()
        event.stopPropagation()

        const handler = !onClick || isDisabled ? undefined : handleClick

        if (handler) {
            handler(event)
        }
    }

    return (
        <StyledMenuItem
            disabled={isDisabled}
            onClick={onClickHandler}
            autoFocus={autoFocus}
            divider={divider}
            data-testid="menu-item"
        >
            {(isSpaceToBeReservedForIcon || icon) && (
                <ListItemIcon>{icon && <Icon colorTheme="primary">{icon}</Icon>}</ListItemIcon>
            )}
            {isCheckboxRendered && <Checkbox onClick={(_newChecked, event) => handleClick(event)} value={isSelected} />}
            <MenuItemLabel>{label}</MenuItemLabel>
            {secondaryAction && <SecondaryActionButton secondaryAction={secondaryAction} />}
        </StyledMenuItem>
    )
}

export default MenuItem
