import * as React from 'react'
import styled from '@emotion/styled'

import { IOsionAsetus } from '../../reducers/OsiokohtaisetAsetuksetReducer'
import Hr from 'components/atoms/Hr'
import SubHeading from 'components/atoms/SubHeading'
import OsiokohtaisetAsetuksetAsetusRivi from './OsiokohtaisetAsetuksetAsetusRivi'

export interface IOsiokohtaisetAsetuksetRyhmaProps {
    asetukset: IOsionAsetus[]
    ryhmanNimi: string
}

const RyhmaHeading = styled.div`
    margin: 1rem 0;
`

export default class OsiokohtaisetAsetuksetRyhma extends React.Component<IOsiokohtaisetAsetuksetRyhmaProps> {
    render(): React.ReactNode {
        return (
            <div>
                <Hr />

                <RyhmaHeading>
                    <SubHeading doNotTranslate>{this.props.ryhmanNimi}</SubHeading>
                </RyhmaHeading>

                {this.props.asetukset.map((asetus) => (
                    <OsiokohtaisetAsetuksetAsetusRivi asetus={asetus} key={asetus.Nimi} />
                ))}
            </div>
        )
    }
}
