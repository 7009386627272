import * as React from 'react'
import styled from '@emotion/styled'

import { AnimatedHoverButton } from '../../../../generic-components'
import { Translation } from '../../../../localization'
import { IMainAxisHeaderNode } from '../../../Types/IMainAxisHeader'
import Styles from 'constants/Styles'

const PositionedAnimatedHoverButton = styled(AnimatedHoverButton)`
    position: absolute;
    top: 5px;
    max-width: 25px;
    min-width: 25px;
`

const LeftAnimatedHoverButton = styled(PositionedAnimatedHoverButton)`
    margin-left: 2px;
`

const RightAnimatedHoverButton = styled(PositionedAnimatedHoverButton)`
    right: 4px;
`

interface ILoadMoreCalendarDataProps {
    onClick: () => void
    relatedExtraNodes: IMainAxisHeaderNode[]
}

const RelativeContainer = styled.div`
    position: relative;
`

const text = Translation.translateKey('calendar.Headers.LoadMoreDataButton.Text')

const getBackgroundColor = (relatedExtraNodes: IMainAxisHeaderNode[]): string | undefined => {
    return relatedExtraNodes.length > 0 ? Styles.calendar.greyFillerWithoutOpacity : undefined
}

const LoadMoreCalendarDataButtonRight: React.FC<ILoadMoreCalendarDataProps> = ({ relatedExtraNodes, onClick }) => {
    return (
        <RelativeContainer>
            <RightAnimatedHoverButton
                backgroundColor={getBackgroundColor(relatedExtraNodes)}
                icon="chevron_right"
                onClick={onClick}
            >
                {text}
            </RightAnimatedHoverButton>
        </RelativeContainer>
    )
}

const LoadMoreCalendarDataButtonLeft: React.FC<ILoadMoreCalendarDataProps> = ({ relatedExtraNodes, onClick }) => {
    return (
        <RelativeContainer>
            <LeftAnimatedHoverButton
                backgroundColor={getBackgroundColor(relatedExtraNodes)}
                icon="chevron_left"
                onClick={onClick}
            >
                {text}
            </LeftAnimatedHoverButton>
        </RelativeContainer>
    )
}

export { LoadMoreCalendarDataButtonLeft, LoadMoreCalendarDataButtonRight }
