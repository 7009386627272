import { createAction } from 'typesafe-actions'
import { Translation } from '../../localization'
import _Log from 'common/Log'
const Log = new _Log('ErrorActions')

const NOTIFICATION_ERROR = Translation.translateKey('notification-Error')

/**
 * Action creator, jota voit käyttää suoraan muista actioneista promisejen
 * feilatessa.
 *
 * @param error Catchatty virheolio
 *
 * @deprecated Use either asyncOperationFailedWithErrorDisplayThunk in AsyncOperationModule
 * or directly displayErrorToaster in Notifications module.
 */

export const errorOnPromiseFailed = createAction('Error', (error: any) => {
    Log.error(error)

    let translatedMessage = NOTIFICATION_ERROR

    let errorMessage = error

    if (error && error.response && error.response.data) {
        errorMessage = error.response.data.Message ? error.response.data.Message : error.response
    }

    if (typeof errorMessage === 'string') {
        translatedMessage += ` ${Translation.has(errorMessage) ? Translation.translateKey(errorMessage) : errorMessage}`
    }

    return {
        message: translatedMessage,
    }
})()
