import * as React from 'react'
import styled from '@emotion/styled'
import { CircularProgressProps } from '@material-ui/core/CircularProgress'

import Progress from 'components/molecules/Progress'

interface ILoadingIndicatorInlineProps extends CircularProgressProps {
    isLoading?: boolean
    paddingLeft?: string
    className?: string
}

const Container = styled.span`
    padding-left: ${({ paddingLeft }: { paddingLeft: string }) => paddingLeft};
`

/**
 * Latausindikaattori joka tulee samalle riville kuin sen vieressä oleva sisältö.
 * Sopii hyvin esim. otsikon viereen.
 */
const LoadingIndicatorInline: React.FunctionComponent<ILoadingIndicatorInlineProps> = ({
    isLoading = false,
    paddingLeft = '1rem',
    className,
    ...circularProgressProps
}) => {
    if (!isLoading) {
        return null
    }

    return (
        <Container className={`${className} ctr-loading-indicator-inline`} paddingLeft={paddingLeft}>
            <Progress {...circularProgressProps} />
        </Container>
    )
}

export default LoadingIndicatorInline
