import { JSX, Suspense } from 'react'
import { ValuePickerExtra } from './types'
import { valuePickerExtrasMapping } from './index'

const ExtraComponent = ({ Component, Props }: ValuePickerExtra): JSX.Element | null => {
    const ComponentToUse = valuePickerExtrasMapping[Component]

    if (!ComponentToUse) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <ComponentToUse {...Props} />
        </Suspense>
    )
}

export default ExtraComponent
