import moment, { DurationInputArg2, Moment } from 'moment'
import { first, get, range } from 'lodash-es'

import { getNodeComponent, ICalendarNodeFunctionComponent } from './CalendarNodeComponents'
import { IDataSourcePropertyModel } from '../..//data-source'
import { IDataSourceItem } from '../../data-source-types'
import DateConstants from 'constants/DateConstants'
import ECalendarDisplayMode from '../Constants/ECalendarDisplayMode'
import ECrossAxisGranularityType from '../Constants/ECrossAxisGranularityType'
import ICalendarCrossAxis from '../Types/ICalendarCrossAxis'
import ICalendarGranularity from '../Types/ICalendarGranularity'
import ICalendarMainData from '../Types/ICalendarMainData'
import ICalendarMainDataNode from '../Types/ICalendarMainDataNode'
import ICalendarNodeType from '../Types/ICalendarNodeType'
import ICalendarDataSourceGroupData from '../Types/ICalendarDataSourceGroupData'
import IMainAxisHeader, { INodesPositioned, TNodePositionRelativeToOtherNodes } from '../Types/IMainAxisHeader'
import ICalendarMainDataGroupData from '../Types/ICalendarMainDataGroupData'
import IPublicHoliday from '../Types/IPublicHoliday'
import isAggregateDataGroup from '../../data-source/Utilities/IsAggregateDataGroup'
import { getLogger } from '../../log'
import Styles from 'constants/Styles'
import { round } from '../../generic-utilities'

const Log = getLogger('calendar.CalendarUtilities')

const STRICT_DATE_PARSE = true
const HOURS_PER_DAY = 24

export const selectNodeType = (
    item: IDataSourceItem,
    dataSourceProperties: IDataSourcePropertyModel[],
    nodeTypes: ICalendarNodeType[]
): ICalendarNodeType | undefined =>
    nodeTypes.find(({ TypeDetermination }) => {
        const dataSourceProperty = dataSourceProperties.find(
            (property) => property.Id === TypeDetermination.DataSourcePropertyId
        )
        if (!dataSourceProperty) {
            return
        }

        const propertyValue = get(item, dataSourceProperty.Property)

        return TypeDetermination.MatchingValues.includes(propertyValue)
    })

export const parseDatetimeFromBackend = (dateTime: string | Moment): Moment =>
    moment(dateTime, DateConstants.BACKEND_DATE_FORMAT, STRICT_DATE_PARSE)

export const getGranularTimeDifference = (
    rangeEndDate: Moment,
    rangeStartDate: Moment,
    granularity: ICalendarGranularity
): number => {
    const granularityValue = granularity.Value > 0 ? Number(granularity.Value) : 1

    return rangeEndDate.diff(rangeStartDate, granularity.Unit as DurationInputArg2) / granularityValue
}

const calculateTimeCalendarNodeIndent = (
    calendarStart: Moment,
    calendarEnd: Moment,
    startValue: string | Moment,
    granularity: ICalendarGranularity,
    displayMode: ECalendarDisplayMode
): number | null => {
    const itemStartMoment = parseDatetimeFromBackend(startValue)
    const calendarStartMoment = parseDatetimeFromBackend(calendarStart).startOf('day')
    const calendarEndMoment = parseDatetimeFromBackend(calendarEnd).endOf('day')

    if (displayMode === ECalendarDisplayMode.FullDay) {
        // moment directly mutates objects
        itemStartMoment.startOf('day')
    }

    if (!itemStartMoment.isValid() || !calendarStartMoment.isValid() || !calendarEndMoment.isValid()) {
        return null
    }

    if (calendarEndMoment.isSameOrBefore(calendarStartMoment)) {
        return null
    }

    itemStartMoment.startOf('day')

    const hoursCalendar = calendarEndMoment.diff(calendarStartMoment, 'hours')

    const daysCalendar = Math.round(hoursCalendar / HOURS_PER_DAY)

    const diffFromCalendarStart = itemStartMoment.diff(calendarStartMoment, 'days')

    const percentageIndent = round((diffFromCalendarStart / daysCalendar) * 100, 5)

    return percentageIndent
}

export const calculateNodePercentageIndent = (
    calendarStart: Moment,
    calendarEnd: Moment,
    startValue: string | Moment,
    granularity: ICalendarGranularity,
    displayMode: ECalendarDisplayMode
): number => {
    switch (granularity.Type) {
        case ECrossAxisGranularityType.TIME: {
            const indent = calculateTimeCalendarNodeIndent(
                calendarStart,
                calendarEnd,
                startValue,
                granularity,
                displayMode
            )

            return typeof indent === 'number' ? indent : 0
        }

        default:
            return 0
    }
}

export const calculateNodePercentageLength = (
    calendarStart: Moment,
    calendarEnd: Moment,
    startValue: string | Moment,
    endValue: string | Moment,
    granularity: ICalendarGranularity,
    displayMode: ECalendarDisplayMode
): number => {
    switch (granularity.Type) {
        case ECrossAxisGranularityType.TIME: {
            // The calendar is at the time being always constrcuted with full days in the cross axis.
            const calendarStartMoment = parseDatetimeFromBackend(calendarStart).startOf('day')
            const calendarEndMoment = parseDatetimeFromBackend(calendarEnd).endOf('day')
            const itemStartMoment = parseDatetimeFromBackend(startValue)
            const itemEndMoment = parseDatetimeFromBackend(endValue)

            // The utilized end datetime varies depending on displayMode.
            let calculatedEndMoment = itemEndMoment.clone()

            if (
                !calendarStartMoment.isValid() ||
                !calendarEndMoment.isValid() ||
                !itemStartMoment.isValid() ||
                !itemEndMoment.isValid()
            ) {
                return 0
            }

            if (displayMode === ECalendarDisplayMode.FullDay) {
                // The below comparison needs to be done before manipulating any of the parameters.
                const hourSpan = itemEndMoment.diff(itemStartMoment, 'hours')
                let daySpan = itemEndMoment.diff(itemStartMoment, 'd')

                // for exact 24 hrs we need one day shift and NOT two day shift
                if (hourSpan !== 0 && hourSpan % HOURS_PER_DAY === 0) {
                    daySpan = daySpan - 1
                }

                calculatedEndMoment = itemStartMoment.clone().endOf('day').add(daySpan, 'days')
                itemStartMoment.startOf('day')

                const hoursCalendar = calendarEndMoment.diff(calendarStartMoment, 'hours')

                const daysCalendar = Math.round(hoursCalendar / HOURS_PER_DAY)

                const percentageLength = ((daySpan + 1) / daysCalendar) * 100

                return percentageLength
            }

            const sizeOfCalendar = getGranularTimeDifference(calendarEndMoment, calendarStartMoment, granularity)
            const sizeOfNode = getGranularTimeDifference(calculatedEndMoment, itemStartMoment, granularity)

            const percentageLength = round((sizeOfNode / sizeOfCalendar) * 100, 5)

            return percentageLength
        }

        default:
            return 0
    }
}

// TODO: currently we get the grouped by data for a group direclty from the group data text.
// The group data is separated by a dash.
// However, the group by data also comes in separate properties within the GroupData objects' Data
// object. We should add a configuration to the calendar to get those properties and use those here
// instead.
const getGroupedByData = (
    group: ICalendarDataSourceGroupData,
    previousGroupedGroupMainTitle: string | null
): ICalendarMainDataGroupData['props']['groupedByData'] => {
    const groupSeparator = ' - '
    const indexForGroupSecondaryTitleStart = group.Data.Text.lastIndexOf(groupSeparator)

    if (indexForGroupSecondaryTitleStart === -1) {
        Log.error('no group separator found')
        return null
    }

    const groupMainTitle = group.Data.Text.substring(0, indexForGroupSecondaryTitleStart)
    const groupSecondaryTitle = group.Data.Text.substring(indexForGroupSecondaryTitleStart + groupSeparator.length)
    const groupColor = Styles.planierColor.blueTurquoise.turquoisePrimary

    // We display the main title (e.g. work unit's name) only for the first sub group (e.g. job title) of the
    // main group
    const groupMainTitleToUse: string | null = previousGroupedGroupMainTitle === groupMainTitle ? null : groupMainTitle

    return {
        groupMainTitle: groupMainTitleToUse,
        groupSecondaryTitle,
        groupColor,
    }
}

const constructGroupDataProps = (
    crossAxis: ICalendarCrossAxis,
    group: ICalendarDataSourceGroupData,
    groupDataIndex: number,
    groupedByData: ICalendarMainDataGroupData['props']['groupedByData']
) => {
    const props: ICalendarMainDataGroupData['props'] = {
        // Every other group will have a shaded background.
        backgroundShading: groupDataIndex % 2 === 1,
        groupedByData,
    }

    const { Data } = group

    for (const { ComponentProperty, GroupDataPropertyPath } of crossAxis.PropertyMapping) {
        props[ComponentProperty] = get(Data, GroupDataPropertyPath)
    }

    const groupValue = first(group.GroupByProperties)
    if (groupValue) {
        props.value = groupValue.Value
    }

    return props
}

const constructNodeProps = (
    item: IDataSourceItem,
    dataSourceProperties: IDataSourcePropertyModel[],
    { PropertyMapping }: ICalendarNodeType
) => {
    const props: { [key: string]: unknown } = {}

    for (const { ComponentProperty, DataSourcePropertyId } of PropertyMapping) {
        const dataSourceProperty = dataSourceProperties.find((property) => property.Id === DataSourcePropertyId)

        if (!dataSourceProperty) {
            continue
        }

        props[ComponentProperty] = get(item, dataSourceProperty.Property)
    }

    props.item = item // TODO: the props could be typed to include the item

    return props
}

const constructEmptyDayNode = (
    calendarStart: Moment,
    calendarEnd: Moment,
    granularity: ICalendarGranularity,
    displayMode: ECalendarDisplayMode,
    dateStart: Moment,
    dateEnd: Moment,
    groupData: any,
    rowIndex: number,
    columnIndex: number
): ICalendarMainDataNode => {
    const props = groupData

    const indent = calculateNodePercentageIndent(calendarStart, calendarEnd, dateStart, granularity, displayMode)

    const length = calculateNodePercentageLength(
        calendarStart,
        calendarEnd,
        dateStart,
        dateEnd,
        granularity,
        displayMode
    )

    const component = getNodeComponent('EmptyCalendarNode')

    const node: ICalendarMainDataNode = {
        component,
        id: `empty_${rowIndex}_${columnIndex}`,
        props,
        indent,
        length,
        infoText: null,
        isDisabled: false,
        date: dateStart,
        isEmptyCell: true,
    }

    return node
}

/**
 * Forms a node object rendered in the main calendar content.
 */
const constructMainDataNode = (
    item: IDataSourceItem,
    nodeTypes: ICalendarNodeType[],
    calendarStart: Moment,
    calendarEnd: Moment,
    granularity: ICalendarGranularity,
    displayMode: ECalendarDisplayMode,
    dataSourceProperties: IDataSourcePropertyModel[]
): ICalendarMainDataNode | undefined => {
    const nodeType = selectNodeType(item, dataSourceProperties, nodeTypes)
    if (!nodeType) {
        return
    }

    const props = constructNodeProps(item, dataSourceProperties, nodeType)

    const infoText = get(item, nodeType.InfoTextProperty, null) as string | null
    const startValue = get(item, nodeType.StartProperty, null) as string
    const endValue = get(item, nodeType.EndProperty, null) as string

    const colorId = get(item, 'ColorId', null) as string | null

    const itemDateMoment = parseDatetimeFromBackend(startValue).startOf('day')

    const indent = calculateNodePercentageIndent(calendarStart, calendarEnd, startValue, granularity, displayMode)
    const length = calculateNodePercentageLength(
        calendarStart,
        calendarEnd,
        startValue,
        endValue,
        granularity,
        displayMode
    )
    const component = getNodeComponent(nodeType.Component)

    const { Id: id, Disabled } = item

    const node: ICalendarMainDataNode = {
        component,
        id,
        props,
        indent,
        length,
        infoText,
        colorId: colorId,
        isDisabled: Boolean(Disabled),
        date: itemDateMoment,
    }

    return node
}

const enumerateDaysBetweenDates = (startDate: Moment, endDate: Moment) => {
    const current = startDate.clone(),
        dates = []

    while (current.isSameOrBefore(endDate)) {
        const val = current.clone()

        dates.push(val)
        current.add(1, 'days')
    }
    return dates
}

export const cloneEmptyNode = (
    node: ICalendarMainDataNode,
    rowIndex: number,
    columnIndex: number
): ICalendarMainDataNode => {
    return {
        component: node.component,
        id: `empty_${rowIndex}_${columnIndex}`,
        indent: node.indent,
        length: node.length,
        infoText: node.infoText,
        props: node.props,
        isDisabled: node.isDisabled,
        date: node.date,
        isEmptyCell: true,
    }
}

export const adjustOverlappingNodeGroups = (
    nodes: ICalendarMainDataNode[][],
    emptyDayNodes: ICalendarMainDataNode[],
    globalRowIndexStart: number
): ICalendarMainDataNode[][] => {
    const numberOfNodesPerGroup = emptyDayNodes.length

    //To prevent floating point shit
    const allowedOverlapIndent = 0.01

    return nodes.map((nodeGroup, index) => {
        const groupsNodes: ICalendarMainDataNode[] = []

        let dayindex = 0
        let nodeGroupIndex = 0
        let lastNodeGroupRight = 0

        const rowIndex = globalRowIndexStart + index

        while (dayindex < numberOfNodesPerGroup) {
            const nextGroupNode = nodeGroup.length > nodeGroupIndex ? nodeGroup[nodeGroupIndex] : undefined

            const emptyDayNode = emptyDayNodes[dayindex]

            if (
                nextGroupNode &&
                nextGroupNode.indent + allowedOverlapIndent < emptyDayNode.indent + emptyDayNode.length
            ) {
                groupsNodes.push(nextGroupNode)
                nodeGroupIndex = nodeGroupIndex + 1
                lastNodeGroupRight = nextGroupNode.indent + nextGroupNode.length
            } else if (emptyDayNode.indent > lastNodeGroupRight - allowedOverlapIndent) {
                groupsNodes.push(cloneEmptyNode(emptyDayNode, rowIndex, dayindex))
            }

            dayindex = dayindex + 1
        }

        return groupsNodes
    })
}

/**
 * Creates an array of arrays of nodes without overlaps.
 */
export const constructNonOverlappingNodeGroups = (nodes: ICalendarMainDataNode[]): ICalendarMainDataNode[][] => {
    // Copy the array before sorting in order not to mutate arguments.
    const sortedNodes = [...nodes].sort((node, nextNode) => node.indent - nextNode.indent)

    const itemGroups: ICalendarMainDataNode[][] = []

    let currentItemGroupIndex = 0

    sortedNodes.forEach((currentNode, index) => {
        if (!Array.isArray(itemGroups[currentItemGroupIndex])) {
            itemGroups.push([])
        }

        const currentItemGroup = itemGroups[currentItemGroupIndex]
        const isFirstNode = index === 0

        if (isFirstNode) {
            currentItemGroup.push(currentNode)
            return
        }

        const currentNodeStart = currentNode.indent

        const acceptableDiff = 0.01

        // Find the first item group where the current node can be placed
        for (const itemGroup of itemGroups) {
            const lastNodeOfGroup = itemGroup[itemGroup.length - 1]
            const lastNodeEnd = lastNodeOfGroup.indent + lastNodeOfGroup.length

            const deltaEndStart = lastNodeEnd - currentNodeStart
            if (deltaEndStart - acceptableDiff < 0) {
                itemGroup.push(currentNode)
                return
            }
        }

        // When the current node fits no existing item group, create
        // a new group.
        itemGroups.push([currentNode])
        currentItemGroupIndex++
    })

    return itemGroups
}

const constructGroupDataObject = (
    groupDataIndex: number,
    nodeGroupIndex: number,
    component: ICalendarNodeFunctionComponent,
    props: ICalendarMainDataGroupData['props'],
    nodes: ICalendarMainDataNode[],
    groupObject: ICalendarDataSourceGroupData,
    isGrouped: boolean
) => {
    const groupedByData = isGrouped ? groupObject.Data.Text.split(' - ') : null

    return {
        groupData: {
            id: `${groupDataIndex}_${nodeGroupIndex}`,
            component,
            props,
        },
        groupedByData,
        nodes,
    }
}

type TGroupedByData = ICalendarMainDataGroupData['props']['groupedByData']

const getIsGroupMainTitleDifferentThanPrevious = (
    groupedByDataForGroup: TGroupedByData,
    previousGroupGroupedByMainTitle: string | null
): groupedByDataForGroup is Exclude<TGroupedByData, null> => {
    if (!groupedByDataForGroup) {
        return false
    }

    if (!previousGroupGroupedByMainTitle) {
        return true
    }

    return Boolean(
        groupedByDataForGroup?.groupMainTitle &&
            groupedByDataForGroup?.groupMainTitle !== previousGroupGroupedByMainTitle
    )
}

export const constructMainAxisData = (
    dataSourceItems: IDataSourceItem[],
    dataSourceProperties: IDataSourcePropertyModel[],
    groupData: ICalendarDataSourceGroupData[],
    crossAxis: ICalendarCrossAxis,
    nodeTypes: ICalendarNodeType[],
    granularity: ICalendarGranularity,
    displayMode: ECalendarDisplayMode,
    calendarStart: Moment,
    calendarEnd: Moment,
    isGrouped: boolean,
    groupDataNodeSearchFilter: string
): ICalendarMainData[] => {
    const groupComponent = getNodeComponent(crossAxis.Component)

    const mainAxisData: ICalendarMainData[] = []

    let previousGroupedGroupMainTitle: string | null = null

    let globalRowIndex = 0

    groupData.forEach((group, groupDataIndex) => {
        if (isAggregateDataGroup(group)) {
            return
        }

        // TODO: Once we have proper configuration for the grouped by data for group nodes,
        // We should also also change this implementation. Currently as the group data text
        // includes both the grouped by data and the normal group name, it works regardless of
        // whether the groups are grouped. See comment above getGroupedByData for further info
        // on the grouping the group nodes.
        if (groupDataNodeSearchFilter && !group.Data.Text.toLowerCase().includes(groupDataNodeSearchFilter)) {
            return
        }

        const groupedByDataForGroup = isGrouped ? getGroupedByData(group, previousGroupedGroupMainTitle) : null

        if (getIsGroupMainTitleDifferentThanPrevious(groupedByDataForGroup, previousGroupedGroupMainTitle)) {
            previousGroupedGroupMainTitle = groupedByDataForGroup.groupMainTitle
        }

        const allGroupItems = dataSourceItems.filter((item) => group.DataItemIds?.includes(item.Id))
        let nodes = allGroupItems
            .map((item) =>
                constructMainDataNode(
                    item,
                    nodeTypes,
                    calendarStart,
                    calendarEnd,
                    granularity,
                    displayMode,
                    dataSourceProperties
                )
            )
            .filter((node): node is ICalendarMainDataNode => typeof node !== 'undefined')

        const daysBetweenStartAndEnd = enumerateDaysBetweenDates(calendarStart, calendarEnd)

        const omitEmptyNodes = groupData.length > 35

        const emptyDayNodes = daysBetweenStartAndEnd.map((day, index) =>
            constructEmptyDayNode(
                calendarStart,
                calendarEnd,
                granularity,
                displayMode,
                day,
                day.clone().add(1, 'hours'),
                group.Data,
                globalRowIndex,
                index
            )
        )

        const emptyDays = emptyDayNodes.filter((x) => {
            const testValue = nodes.find((y) => {
                return x.indent < y.indent + y.length && y.indent < x.indent + x.length
            })
            if (omitEmptyNodes) {
                return false
            }
            return testValue === undefined
        })

        nodes = nodes.concat(emptyDays)

        if (nodes.length === 0) {
            const props = constructGroupDataProps(crossAxis, group, groupDataIndex, groupedByDataForGroup)
            const nodeGroupIndex = 0

            return mainAxisData.push(
                constructGroupDataObject(groupDataIndex, nodeGroupIndex, groupComponent, props, nodes, group, isGrouped)
            )
        }

        // Perform an overlap check on nodes instead of data source items, because
        // nodes might overlap even though items wouldn't overlap.
        const tempnonOverlappingNodeGroups = constructNonOverlappingNodeGroups(nodes)

        const nonOverlappingNodeGroups = omitEmptyNodes
            ? tempnonOverlappingNodeGroups
            : adjustOverlappingNodeGroups(tempnonOverlappingNodeGroups, emptyDayNodes, globalRowIndex)

        globalRowIndex = globalRowIndex + tempnonOverlappingNodeGroups.length

        nonOverlappingNodeGroups.forEach((nodeGroup, nodeGroupIndex) => {
            const component = nodeGroupIndex === 0 ? groupComponent : getNodeComponent('BlankGroupNode')
            const props = constructGroupDataProps(crossAxis, group, groupDataIndex, groupedByDataForGroup)

            mainAxisData.push(
                constructGroupDataObject(groupDataIndex, nodeGroupIndex, component, props, nodeGroup, group, isGrouped)
            )
        })
    })

    return mainAxisData
}

const getNodePositionRelativeToInitialNodes = (
    date: Moment,
    initialCalendarStart: Moment,
    initialCalendarEnd: Moment
): TNodePositionRelativeToOtherNodes => {
    if (date.isAfter(initialCalendarEnd, 'day')) {
        return 'afterInitialNodes'
    }

    if (date.isBefore(initialCalendarStart, 'day')) {
        return 'beforeInitialNodes'
    }

    return 'initialNodes'
}

// NOTE: The header grouping is currently set to always be days. This will change in the future.
export const constructMainAxisHeaders = (
    calendarStart: Moment,
    calendarEnd: Moment,
    initialCalendarStart: Moment,
    initialCalendarEnd: Moment,
    publicHolidays: IPublicHoliday[]
): IMainAxisHeader[] => {
    const startDate = parseDatetimeFromBackend(calendarStart).startOf('day')
    const endDate = parseDatetimeFromBackend(calendarEnd).startOf('day')

    if (!startDate.isValid() || !endDate.isValid()) {
        return []
    }

    const diffInDays = endDate.diff(startDate, 'days')

    const emptyPositionedNodes: INodesPositioned = {
        beforeInitialNodes: [],
        initialNodes: [],
        afterInitialNodes: [],
    }

    const nodesPositioned = range(diffInDays + 1).reduce((positionedNodes, diffFromStart) => {
        const activeDate = startDate.clone().add(diffFromStart, 'days')

        const positionRelativeToInitialNodes = getNodePositionRelativeToInitialNodes(
            activeDate,
            initialCalendarStart,
            initialCalendarEnd
        )

        const publicHoliday = publicHolidays.find((x) => x.Date === activeDate.format('YYYY-MM-DD'))?.Name

        positionedNodes[positionRelativeToInitialNodes].push({
            value: activeDate.format('dd D.M.'),
            publicHoliday: publicHoliday,
            weekNumber: activeDate.isoWeekday() === 1 ? activeDate.isoWeek() : undefined,
        })

        return positionedNodes
    }, emptyPositionedNodes)

    return [
        {
            component: getNodeComponent('DateHeaderNode'),
            level: 0,
            nodes: nodesPositioned,
            width: round((1 / (diffInDays + 1)) * 100, 5),
        },
    ]
}
