/**
 * Tarjoaa metodeja fonttikoon käsittelemiseen.
 */
export default class FontSizeUtility {
    static getFontSize() {
        const HTML = document.getElementsByTagName('html')[0]
        const HTMLFontSize = window.getComputedStyle(HTML, null).getPropertyValue('font-size')

        return HTMLFontSize
    }

    static getFontSizeAsNumber() {
        return parseFloat(this.getFontSize())
    }

    /**
     * Muuttaa rem-yksiköllisen luvun pikseleiksi.
     * Laskenta tehdään html-elementin fonttikoon avulla, koska rem on aina suhteellinen siihen.
     * Käytännössä se siis saadaan yksinkertaisesti kertomalla rem pikseleissä olevalla fonttikoolla.
     * Jos metodia tarvitsee toistaa paljon, voit antaa fonttikoon suoraan parametrina, mutta
     * oletuksena se otetaan getFontSize-metodia käyttämällä. Jos annat fonttikoon parametrina,
     * anna se suoraan lukuna. Metodi getFontSize palauttaa fonttikoon 'px'-päätteellä.
     */
    static transformRemToPixels(rem, htmlElementFontSize) {
        const fontSize = htmlElementFontSize || parseFloat(FontSizeUtility.getFontSize())

        const remInPixels = rem * fontSize
        return remInPixels
    }
}
