import { useState } from 'react'
import { get, set, uniq } from 'lodash-es'

import { FormFieldState } from '../../Types/V2/FormFieldStateEnum'
import { validateIfValuePickerValueIsSet } from '../../../value-picker'
import IFormViewFieldConfigurationV2 from '../../Types/V2/IFormViewFieldConfigurationV2'
import IFormViewConfigurationV2 from '../../../form-view/Types/V2/IFormViewConfigurationV2'

export const validate = (
    configs: Record<string, FormFieldState>,
    values: Record<string, any>,
    submitOnlyChangedValues: boolean,
    touchedFields: string[]
): Record<string, any> => {
    const errors: Record<string, any> = {}

    for (const key in configs) {
        if (submitOnlyChangedValues && !touchedFields.find((x) => x === key)) {
            continue
        }

        const config = configs[key]

        const value = values[key]

        if (config === FormFieldState.Required) {
            const validationError = validateIfValuePickerValueIsSet(value)

            if (validationError) {
                set(errors, key, validationError)
            }
        }
    }

    return errors
}

export function formHasMultipleFieldsInOneRow(form: IFormViewConfigurationV2 | null): boolean {
    if (!form) {
        return false
    }
    let hasDuplicates = false
    try {
        form.Tabs.forEach((tab) =>
            tab.SubTabs.forEach((subTab) => {
                const rowOrders: number[] = []
                subTab.Fields.forEach((field) => {
                    if (rowOrders.includes(field.RowOrder)) {
                        hasDuplicates = true
                    }
                    rowOrders.push(field.RowOrder)
                })
            })
        )
    } catch (e) {
        return false
    }

    return hasDuplicates
}

export const modifiedFieldStates = (
    configurations: IFormViewFieldConfigurationV2[],
    sideEffects: { key: string; value: string }[],
    formItems?: any[] | null
): Record<string, FormFieldState> => {
    const fieldStates: Record<string, FormFieldState> = {}

    const readOnlyFields = uniq(formItems?.flatMap(({ MetaData }) => MetaData?.ReadOnlyFields))

    configurations.forEach((conf) => {
        const sideEffectToApply = conf.Selectors.flatMap((selector) => sideEffects.filter((x) => x.key === selector))
            .filter(Boolean)
            .map((x) => x?.value) //Filter null values

        set(fieldStates, conf.Id, conf.InitialState)

        const isReadOnly = readOnlyFields.some(
            (fieldName) => conf.DataSourcePropertyMapping[0]?.DataSourceProperty === fieldName
        )

        if (isReadOnly) {
            set(fieldStates, conf.Id, FormFieldState.ReadOnly)
        }

        sideEffectToApply
            .sort((first, second) => {
                const rowOrder1 = getEffectOrder(first)
                const rowOrder2 = getEffectOrder(second)
                return rowOrder1 - rowOrder2
            })
            .forEach((effect) => {
                switch (effect?.toLowerCase()) {
                    case 'hide':
                        set(fieldStates, conf.Id, FormFieldState.Hidden)
                        break
                    case 'show':
                        set(fieldStates, conf.Id, FormFieldState.Visible)
                        break
                    case 'require':
                        set(fieldStates, conf.Id, FormFieldState.Required)
                        break
                    case 'disable':
                        set(fieldStates, conf.Id, FormFieldState.Disabled)
                        break
                    default:
                        break
                }

                // override any other state if field is ReadOnly, but only if the field is visible
                if (isReadOnly && effect !== 'hide') {
                    set(fieldStates, conf.Id, FormFieldState.ReadOnly)
                }
            })
    })

    return fieldStates
}

export const useScreenDimensions = (): { height: number; width: number } => {
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)

    useState(() => {
        const listener = () => {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }

        window.addEventListener('resize', listener)

        return () => {
            window.removeEventListener('resize', listener)
        }
    })

    return {
        height,
        width,
    }
}

interface ITabConfig {
    Name: string | null
    SubTabs: {
        Name: string | null
        Fields: IFormViewFieldConfigurationV2[]
    }[]
}

export const checkIfTabHasRequiredFields = (tab: ITabConfig, fieldStates: Record<string, FormFieldState>): boolean => {
    let hasRequiredField = false
    tab.SubTabs.forEach((subtab) => {
        if (
            subtab.Fields.findIndex((field) => {
                return get(fieldStates, field.Id) === FormFieldState.Required
            }) > -1
        ) {
            hasRequiredField = true
        }
    })
    return hasRequiredField
}

export const checkIfTabFieldsHasValidationErrors = (
    tab: ITabConfig,
    validationErrors: Record<string, any>
): boolean => {
    let hasValidationErrors = false
    tab.SubTabs.forEach((subtab) => {
        if (
            subtab.Fields.findIndex((field) => {
                return get(validationErrors, field.Id)
            }) > -1
        ) {
            return (hasValidationErrors = true)
        }
    })
    return hasValidationErrors
}
function getEffectOrder(effect: string) {
    let rowOrder = 0
    switch (effect?.toLowerCase()) {
        case 'hide':
            rowOrder = 2
            break
        case 'show':
            rowOrder = 1
            break
        case 'require':
            rowOrder = 3
            break
        case 'disable':
            rowOrder = 4
            break
        default:
            rowOrder = 0
            break
    }
    return rowOrder
}
