import * as React from 'react'
import styled from '@emotion/styled'

import Styles from 'constants/Styles'
import BasicCalendarNode from './BasicCalendarNode'
import { Translation } from '../../localization'
import Typography from '@material-ui/core/Typography'
import { IEvent } from '../../event'
import { useAppTheme } from '../../theme/'

export interface ITransferNodeProps {
    tyoaikasaantorikkeet: string[] | null
    published: boolean
    isTemporary: boolean
    travelInformationText: string
    item: IEvent
}

const mainColor = '#474b6a'

// TODO: this should be generalized into BasicCalendarNode somehow too
const StyledText = styled(Typography)`
    text-transform: lowercase;
    line-height: 12px;
    color: ${mainColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const TransferNode: React.FunctionComponent<ITransferNodeProps> = ({
    tyoaikasaantorikkeet,
    isTemporary,
    travelInformationText,
    item,
}) => {
    const { colors } = useAppTheme()

    return (
        <BasicCalendarNode
            backgroundColor={isTemporary ? Styles.mainColor.white : colors.primaryCyan11}
            borderColor={isTemporary ? Styles.calendar.transferTemporaryBorder : mainColor}
            extraTextNode={<StyledText variant="caption">{travelInformationText}</StyledText>}
            hoverColor={colors.neutralsGrey11}
            icon="commute"
            isBottomRightSignifierDisplayed={item.IsOnPlanningPeriod}
            isCoveredOnLeft={false}
            mainColor={mainColor}
            upperText={Translation.translateKey('calendar.Transfer')}
            warnings={tyoaikasaantorikkeet}
        />
    )
}

export default TransferNode
