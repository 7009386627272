import styled from '@emotion/styled'
import { Icon, ReadOnlyText } from '../'
import { Variant } from '../ReadOnlyText'
import { useAppTheme } from '../../theme/'

type Props = {
    summaryLabels: ISummaryLabel[]
}

export interface ISummaryLabel {
    Icon: string
    Text: string
}

const SummaryLabelsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 12px 22px 0 12px;
`

const SummaryLabelWrapper = styled.div<{ noPadding?: boolean; maxWidth?: number }>`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding: ${({ noPadding }) => (noPadding ? '0' : ' 12px 22px 0 12px')};
    gap: 8px;

    .MuiTypography-root {
        max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
    }
`

export const SummaryLabel = ({
    summaryLabel,
    noPadding = false,
    fontUsage,
    ellipsis,
    maxWidth,
    className,
}: {
    summaryLabel: ISummaryLabel
    noPadding?: boolean
    fontUsage?: Variant
    ellipsis?: boolean
    maxWidth?: number
    className?: string
}): JSX.Element => {
    const { colors } = useAppTheme()

    return (
        <SummaryLabelWrapper noPadding={noPadding} maxWidth={maxWidth} className={className}>
            <Icon color={colors.neutralsGrey100}>{summaryLabel.Icon}</Icon>
            <ReadOnlyText textOverflow={ellipsis ? 'ellipsis' : undefined} usage={fontUsage ?? 'h5'}>
                {summaryLabel.Text}
            </ReadOnlyText>
        </SummaryLabelWrapper>
    )
}

export const SummaryLabels = ({ summaryLabels }: Props): JSX.Element => {
    return (
        <SummaryLabelsContainer>
            {summaryLabels.map((summaryLabel, index) => (
                <SummaryLabel key={index} summaryLabel={summaryLabel} />
            ))}
        </SummaryLabelsContainer>
    )
}
