import * as React from 'react'
import { PropsWithChildren } from 'react'
import Typography from '@material-ui/core/Typography'
import { Translation } from '../localization'

import Styles, { Color, getColorCode, primary_120, TextTransform } from 'constants/Styles'

import styled from '@emotion/styled'
import { TooltipWithEllipsis } from './Tooltip'
import { PlanierTheme, useAppTheme } from '../theme/'

type MaterialVariant = 'h1' | 'h2' | 'h4' | 'h5' | 'h6' | 'button' | 'bodyS' | 'bodyXS' | 'bodyM'

export type Variant =
    | MaterialVariant
    | 'dropdown_label'
    | 'dropdown_selection'
    | 'dropdown_placeholder'
    | 'list_header'
    | 'list_data'
    | 'list_link'
    | 'event_block'
    | 'h7'
    | 'h8'
    | 'legacy_list_element'
    | 'H1'
    | 'smallButton'
    | 'linkM'
    | 'list_element_link'

interface ITextProps {
    fontFamily?: string
    fontSize?: string
    fontWeight?: number | 'bold' | 'italic' | 'normal' | string
    fontStyle?: string
    lineHeight?: string
    color?: string
    textOverflow?: 'hidden' | 'ellipsis' | undefined
    textTransform?: TextTransform | undefined
    textDecoration?: string
    whiteSpace?: 'no-wrap' | undefined
    overflow?: 'hidden' | undefined
}

export interface IReadOnlyTextProps {
    usage: Variant
    textOverflow?: 'hidden' | 'ellipsis' | 'pre-line'
    color?: Color
    rawColor?: string
    fontSize?: string
    fontStyle?: string
    fontFamily?: string
    textDecoration?: string
    fontWeight?: number | 'bold' | 'italic' | 'normal' | string
    lineHeight?: string
    isLink?: boolean
    linkOnClick?: () => void
    translate?: boolean
    tooltipEnabled?: boolean
    className?: string
    testId?: string
}

interface IExtendedTextProps extends ITextProps {
    isLink: boolean
}

const StyledTypography = styled(Typography, {
    shouldForwardProp: (propName) => propName !== 'isLink' && propName !== 'textTransform',
})<IExtendedTextProps>`
    color: ${(props) => props.color};
    font-family: ${(props) => props.fontFamily};
    font-weight: ${(props) => props.fontWeight};
    font-style: ${(props) => props.fontStyle};
    line-height: ${(props) => props.lineHeight};
    text-overflow: ${(props) => props.textOverflow};
    text-decoration: ${(props) => props.textDecoration};
    white-space: ${(props) => props.whiteSpace};
    overflow: ${(props) => props.overflow};
    text-transform: ${({ textTransform }) => textTransform};

    ${(props) =>
        props.isLink
            ? `:hover {
        color: ${primary_120};
        cursor: pointer;
        text-decoration: underline;
    }`
            : ''}
`

const getOverflowStyle = (style: 'ellipsis' | 'hidden' | 'pre-line' | undefined) => {
    if (style === 'ellipsis') {
        return {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        }
    } else if (style === 'hidden') {
        return {
            textOverflow: 'hidden',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        }
    } else if (style === 'pre-line') {
        return {
            whiteSpace: 'pre-line',
        }
    }

    return undefined
}

const getExtraStyles = (variant: Variant, { typographyExtensions }: PlanierTheme): ITextProps | undefined => {
    switch (variant) {
        case 'dropdown_label':
            return typographyExtensions.dropdown_label

        case 'dropdown_selection':
            return typographyExtensions.dropdown_selection

        case 'dropdown_placeholder':
            return typographyExtensions.dropdown_placeholder

        case 'list_header':
            return typographyExtensions.list_header

        case 'list_data':
            return typographyExtensions.list_data

        case 'list_link':
            return typographyExtensions.list_link

        case 'event_block':
            return typographyExtensions.event_block

        case 'h7':
            return typographyExtensions.h7

        case 'h8':
            return typographyExtensions.h8

        case 'H1':
            return Styles.typographyExtensions.H1

        case 'legacy_list_element':
            return typographyExtensions.legacyListElement

        case 'smallButton':
            return Styles.typographyExtensions.smallbutton

        case 'linkM':
            return typographyExtensions.linkM

        case 'list_element_link':
            return typographyExtensions.list_element_link

        default:
            return undefined
    }
}

const ReadOnlyText: React.FunctionComponent<PropsWithChildren<IReadOnlyTextProps>> = ({
    children,
    usage,
    color,
    rawColor,
    fontWeight,
    fontSize,
    fontStyle,
    fontFamily,
    textDecoration,
    textOverflow,
    linkOnClick,
    isLink,
    translate,
    tooltipEnabled,
    lineHeight,
    className,
    testId,
}) => {
    const theme = useAppTheme()

    const text = translate ? Translation.translateKey(children as string) : children

    const _extraStyles: ITextProps | undefined = getExtraStyles(usage, theme)

    const overflowStyle = getOverflowStyle(textOverflow)

    const _color = rawColor ?? getColorCode(color)

    const extraStyles = {
        ..._extraStyles,
        color: _color ?? _extraStyles?.color,
        fontWeight: fontWeight ? fontWeight : _extraStyles?.fontWeight,
        fontSize: fontSize ? fontSize : _extraStyles?.fontSize,
        lineHeight: lineHeight ? lineHeight : _extraStyles?.lineHeight,
        fontStyle: fontStyle ? fontStyle : _extraStyles?.fontStyle,
        fontFamily: fontFamily ? fontFamily : _extraStyles?.fontFamily,
        textDecoration: textDecoration ? textDecoration : _extraStyles?.textDecoration,
        textOverflow,
        ...overflowStyle,
    }

    const _onClickHandler = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        if (linkOnClick) {
            event.stopPropagation()
            linkOnClick()
        }
    }

    return (
        <TooltipWithEllipsis title={tooltipEnabled ? text : undefined} style={{ ...overflowStyle }}>
            {(ref) => (
                <StyledTypography
                    isLink={isLink ?? false}
                    onClick={_onClickHandler}
                    id="ReadOnlyText"
                    variant={usage}
                    ref={ref}
                    className={className}
                    data-testid={testId}
                    {...extraStyles}
                >
                    {text}
                </StyledTypography>
            )}
        </TooltipWithEllipsis>
    )
}

export default ReadOnlyText
