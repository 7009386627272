import { useState } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'
import PickersDay from '@material-ui/lab/PickersDay'

import DayPickerV2 from '../DayPicker/DayPickerV2'
import { GENERAL_DATA_FORMAT, isBefore, isWithinRange, TDateStringDataFormat } from '../../../dates'
import moment from 'moment'
import { getTestId } from '../../../test'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 77px;
`

const Divider = styled.div`
    width: 10px;
`

const DayBetweenRange = styled(PickersDay)`
    opacity: 50%;
`

type TDayPickerProps = React.ComponentProps<typeof DayPickerV2>

interface IOnChangeParameters {
    start: TDateStringDataFormat | null
    end: TDateStringDataFormat | null
}

interface IDayRangePickerProps
    extends Pick<TDayPickerProps, 'shouldDateBeDisabled' | 'required' | 'onBlur' | 'validationError'> {
    onChange: (startAndEnd: IOnChangeParameters) => void
    startLabel?: string
    endLabel?: string
    endDay: TDateStringDataFormat | null
    startDay: TDateStringDataFormat | null
    isStartDayErrored?: boolean
    isEndDayErrored?: boolean
    validationErrorStartDay?: string | null
    validationErrorEndDay?: string | null
    isFieldErrored?: boolean
    errors?: any
    valuePickerId?: string
}

const DayRangePicker: React.FC<IDayRangePickerProps> = ({
    endDay,
    startDay,
    onChange,
    required,
    startLabel = 'generic-components.DayRangePicker.StartLabel.DefaultValue',
    endLabel = 'generic-components.DayRangePicker.EndLabel.DefaultValue',
    shouldDateBeDisabled,
    onBlur,
    isStartDayErrored: isStartDayErroredProp = false,
    isEndDayErrored: isEndDayErroredProp = false,
    validationErrorStartDay = null,
    validationErrorEndDay = null,
    isFieldErrored = false,
    validationError,
    errors,
    valuePickerId,
}) => {
    const [isStartOpen, setIsStartOpen] = useState(false)
    const [isEndOpen, setIsEndOpen] = useState(false)

    const handleStartDayValueChange = (newSelectedDays: TDateStringDataFormat[]) => {
        const start = newSelectedDays.length === 0 ? null : newSelectedDays[0]

        onChange({ start: start, end: endDay })
    }

    const handleEndDayValueChange = (newSelectedDays: TDateStringDataFormat[]) => {
        const end = newSelectedDays.length === 0 ? null : newSelectedDays[0]

        onChange({ start: startDay, end: end })
    }

    const handleStartOpen = () => {
        if (isEndOpen) {
            setIsEndOpen(false)
        }
        setIsStartOpen(true)
    }

    const handleStartClose = () => {
        setIsStartOpen(false)
    }

    const handleEndOpen = () => {
        if (isStartOpen) {
            setIsStartOpen(false)
        }
        setIsEndOpen(true)
    }

    const handleEndClose = () => {
        setIsEndOpen(false)
    }

    const isDayDisplayedAsSelectedForStartDayPicker = (day: string) => {
        return day === endDay
    }

    const isDayDisplayedAsSelectedForEndDayPicker = (day: string) => {
        return day === startDay
    }

    const handleRenderingForDatesBetweenDateRange: TDayPickerProps['renderDay'] = (
        day,
        selectedDates,
        props,
        defaultDayRenderer
    ) => {
        const isStartOrEndDay = day === startDay || day === endDay

        if (isStartOrEndDay || startDay === null || endDay === null) {
            return defaultDayRenderer(day, selectedDates, { ...props, selected: isStartOrEndDay })
        }

        if (isWithinRange(day, startDay, endDay)) {
            return <DayBetweenRange {...props} selected />
        }

        return defaultDayRenderer(day, selectedDates, props)
    }

    const handleOnBlurStartDatePicker = () => {
        if (endDay && startDay && isBefore(endDay, startDay)) {
            handleEndDayValueChange([moment(startDay).add(14, 'days').format(GENERAL_DATA_FORMAT)])
        }
        onBlur
    }

    const isStartDayErrored = isStartDayErroredProp || (isFieldErrored && startDay === null)
    const isEndDayErrored = isEndDayErroredProp || (isFieldErrored && endDay === null)

    const testId = getTestId(['VALUE_PICKER'], valuePickerId)

    return (
        <Container data-testid={testId}>
            <InputContainer>
                <DayPickerV2
                    isDayDisplayedAsSelectedFunc={isDayDisplayedAsSelectedForStartDayPicker}
                    isFieldErrored={isStartDayErrored}
                    isOpen={isStartOpen}
                    label={startLabel}
                    multiselect={false}
                    onBlur={handleOnBlurStartDatePicker}
                    onChange={handleStartDayValueChange}
                    onClose={handleStartClose}
                    onOpen={handleStartOpen}
                    renderDay={handleRenderingForDatesBetweenDateRange}
                    required={required}
                    shouldDateBeDisabled={shouldDateBeDisabled}
                    validationError={validationErrorStartDay ?? validationError}
                    value={startDay ? [startDay] : []}
                    errors={errors}
                />
                <Divider />
                <DayPickerV2
                    isDayDisplayedAsSelectedFunc={isDayDisplayedAsSelectedForEndDayPicker}
                    isFieldErrored={isEndDayErrored}
                    isOpen={isEndOpen}
                    label={endLabel}
                    multiselect={false}
                    onBlur={onBlur}
                    onChange={handleEndDayValueChange}
                    onClose={handleEndClose}
                    onOpen={handleEndOpen}
                    renderDay={handleRenderingForDatesBetweenDateRange}
                    required={required}
                    shouldDateBeDisabled={shouldDateBeDisabled}
                    validationError={validationErrorEndDay ?? validationError}
                    value={endDay ? [endDay] : []}
                    errors={errors}
                />
            </InputContainer>
        </Container>
    )
}

export default DayRangePicker
