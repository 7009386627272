import OsiokohtaisetAsetukset from './components/pages/OsiokohtaisetAsetukset'
import osiokohtaisetAsetuksetReducer, { IOsiokohtaisetAsetuksetState } from './reducers/OsiokohtaisetAsetuksetReducer'
import IOsiokohtaisetAsetuksetReducerCombined from './Types/IOsiokohtaisetAsetuksetReducerCombined'

export default OsiokohtaisetAsetukset

export { IOsiokohtaisetAsetuksetReducerCombined, IOsiokohtaisetAsetuksetState, osiokohtaisetAsetuksetReducer }

import * as actions from './actions/OsiokohtaisetAsetuksetActions'
export { actions }
