import * as React from 'react'

import { PageContainer } from '../../../../styling'
import { ConfigurableListPageTemplate } from '../../../../configurable-list'
import ToteumaSiirtoStatusTabs from './ToteumaSiirtoStatusTabs'

import styled from '@emotion/styled'

const TOTEUMAT_LIST_ID = 'ToteumaList'

const ViewContainer = styled(PageContainer)`
    margin-top: -0.6rem;
`

const ToteumatPage: React.FunctionComponent = () => (
    <ViewContainer>
        <ToteumaSiirtoStatusTabs listId={TOTEUMAT_LIST_ID} />

        <ConfigurableListPageTemplate listId={TOTEUMAT_LIST_ID} />
    </ViewContainer>
)

export default ToteumatPage
