import { createAction, createDraftSafeSelector, createReducer, createSelector } from '@reduxjs/toolkit'
import { RootState } from 'typesafe-actions'
import moment, { Moment } from 'moment'
import {
    ICalendarConfiguration,
    ICalendarNodeContent,
    ICalendarState,
    IEventModel,
    IEventTemplate,
    IGroupData,
} from '../Types/types'

import { CopyAction, CutAction } from '../temp/temp'

import { CALENDAR_DATASOURCE_ID } from '../Constants'
import { formatDataSourceResponseToCalendarContent } from '../Utils/CalendarUtils'
import { AnyAction } from 'redux'
import { IThunkBaseAction } from '../../../generic-state'
import { handleViewEngineActionThunk, TFunctionalityAction } from '../../../view-engine'
import { validTimeInput } from '../../../generic-components'
import { IDataSourceGroupData, IDataSourceWindowFunctionData } from '../../../data-source-types'
import { get, isEqual, uniqWith } from 'lodash-es'

//TODO-R separate interface, reducer, actions and selectors to separate files
const calendarInitialState: ICalendarState = {
    holidays: [],
    areSelectedCellsLoading: false,
    isEditOpen: false,
    IsEditedTextValid: false,
    isEventTimeEdited: false,
    editedTimeAsText: '',
    isCreateOpen: false,
    isContentInitialized: false,
    showDayCalculations: false,
    idMap: [],
    items: [],
    groups: [],
    totalGroup: [],
    metrics: {
        overallMetrics: [],
        workUnitMetrics: [],
    },
    startDay: moment(),
    nextStartDay: moment(),
    clipBoardAction: undefined,
    endDay: moment(),
    nextEndDay: moment(),
    eventTemplates: [],
    sizeY: 0,
    sizeX: 0,
    nextSizeX: 0,
    isStructureInitialized: false,
    cursorIndexX: null,
    cursorIndexY: null,
    clipBoardContent: [],
    selectedCells: [],
    pendingForActionContent: [],
    pendingAction: null,
    calendarConfiguration: {
        valuePickerIds: [],
        groupingConfiguration: {
            type: 'WorkUnit',
            displayType: 'MetricsOnly',
        },
    },
    isTooMuchDataToRender: false,
}

export const structureInitialized = createAction<{
    sizeX: number
    startDay: Moment
    endDay: Moment
    configuration: ICalendarConfiguration
}>('structureInitialized')

export const contentSelectorApplied = createAction<{
    selectorProperty: string | null
    propertyTransformation: string | undefined
}>('contentSelectorApplied')

const autoFillTimeInput = (text: string, isAddingChar: boolean): { isOk: boolean; newText: string } => {
    let output = text

    if (validTimeInput.test(output) || output === '') {
        //Autoinsert prefix 0 if start is > 2 or length is 2 and already contains ":"

        if (
            (isAddingChar && output.length === 1 && parseInt(output) > 2) ||
            (output.length === 2 && output.includes(':'))
        ) {
            output = '0' + output
        }

        //Autoinsert ":" if it is not already in the input, and the next step is to input minutes. That is the case when user is adding a new character
        //and the already put input is either already two digits or first digit is larget than 2
        if (
            isAddingChar &&
            !output.includes(':') &&
            (output.length === 2 || (output.length === 1 && parseInt(output) > 2))
        ) {
            output = output + ':'
        }
    } else {
        return { isOk: false, newText: output }
    }

    return {
        isOk: true,
        newText: output,
    }
}

const timeDisplayFormat = 'H.mm'
const DEFAULT_TIME_INPUT = '0.00–0.00'

const formatEventTime = (data: IEventModel) => {
    if (data.StartTime.isValid()) {
        return `${data.StartTime.format(timeDisplayFormat)}–${data.EndTime.format(timeDisplayFormat)}`
    } else {
        return ''
    }
}

export const holidaysFetched = createAction<{ Date: string; Name: string }[]>('holidaysFetched')

export const eventTimeInLineEdited = createAction<string>('eventTimeInLineEdited')

export const setShowDayCalculations = createAction<boolean>('setShowDayCalculations')

export const eventTemplatesFetched = createAction<IEventTemplate[]>('eventTemplatesFetched')

export const dateRangeChanged = createAction<{ startDate: Moment; endDate: Moment }>('dateRangeChanged')

export const editOpened = createAction('editOpened')

export const editClosed = createAction('editClosed')

export const createOpened = createAction('createOpened')

export const createClosed = createAction('createClosed')

export const cursorMovedUp = createAction('cursorMovedUp')

export const cursorMovedDown = createAction('cursorMovedDown')

export const cursorMovedLeft = createAction('cursorMovedLeft')

export const cursorMovedRight = createAction('cursorMovedRight')

export const selectionCleared = createAction('selectionCleared')

export const selectedCellsLoadingStarted = createAction('inlineCellLoadingStarted')

export const selectedCellsLoadingEnded = createAction('inlineCellLoadingEnded')

export const inlineCellEditingEnded = createAction('inlineCellEditingEnded')

export const selectedCellsSetToClipboardForCut = createAction('selectedCellsSetToClipboardForCut')

export const selectedCellsSetToClipboardForCopy = createAction('selectedCellsSetToClipboardForCopy')

export const nodeDeSelected = createAction<{
    cursorIndexX: number
    cursorIndexY: number
    contentId: string | null
    retainOthers: boolean
}>('nodeDeSelected')

export const nodeSelected = createAction<{
    cursorIndexX: number
    cursorIndexY: number
    contentId: string | null
    retainOldSelection: boolean
}>('nodeSelected')

interface IState {
    calendarv2: ICalendarState
}

const selectSelf = (state: IState) => state.calendarv2

export const selectCalendarWeeksVisible = createSelector(selectSelf, (state) => state.sizeX / 7)
export const selectShowDayCalculations = createSelector(selectSelf, (state) => state.showDayCalculations)

const eventsSelector = (state: ICalendarState) => state.items

const isSelectedMapSelector = (state: ICalendarState) => {
    const isSelectedMap: boolean[][] = []

    for (let i = 0; i < state.sizeX; i++) {
        const newRow: boolean[] = []
        isSelectedMap.push(newRow)
        for (let j = 0; j < state.sizeY; j++) {
            newRow.push(false)
        }
    }

    state.selectedCells.forEach((x) => {
        isSelectedMap[x.x][x.y] = true
    })

    return isSelectedMap
}

export const selectIsSelectedMap = createSelector(selectSelf, isSelectedMapSelector)

const selectEvents = createDraftSafeSelector(selectSelf, eventsSelector)

const itemMapSelector = (listContent: ICalendarNodeContent[]) => {
    const map = new Map<string, ICalendarNodeContent>()

    listContent.forEach((x) => {
        map.set(x.id, x)
    })

    return map
}

export const executeClipboardActionThunk = (): IThunkBaseAction => async (dispatch, getState) => {
    const state = getState()

    if (state.calendarv2.clipBoardAction === 'cut') {
        dispatch(executeActionWithClipboardItemsAndSelectedCellsThunk(CutAction.Functionality))
    } else if (state.calendarv2.clipBoardAction === 'copy') {
        dispatch(executeActionWithClipboardItemsAndSelectedCellsThunk(CopyAction.Functionality))
    }
}

export const executeActionWithClipboardItemsAndSelectedCellsThunk =
    (action: TFunctionalityAction): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        if (state.calendarv2.clipBoardContent.length === 0) {
            return
        }

        const selectedCells = state.calendarv2.selectedCells

        const nodeIdsAsArray = state.calendarv2.clipBoardContent

        const itemMap = selectItemMap(state)

        const selectedNodeObjects = nodeIdsAsArray
            .map((id) => {
                return itemMap.get(id)?.data
            })
            .filter((x) => x) as IEventModel[]

        if (selectedCells.length !== 1) {
            return
        }

        const cell = selectedCells[0]

        const seletedCellGroup = state.calendarv2.groups[cell.y]

        const OverridingStartDate = state.calendarv2.startDay.clone().add(cell.x, 'days')

        const OverridingEmployeeId = seletedCellGroup.groupProperties.employeeId
        const OverridingWorkunitId = seletedCellGroup.groupProperties.workunitId
        const OverridingJobtitleId = seletedCellGroup.groupProperties.jobTitleId

        dispatch(
            handleViewEngineActionThunk({
                items: selectedNodeObjects,
                functionalityAction: action,
                dataSourceId: CALENDAR_DATASOURCE_ID,
                additionalRequestData: {
                    OverridingStartDate: OverridingStartDate,
                    OverridingEmployeeId: OverridingEmployeeId,
                    OverridingWorkunitId: OverridingWorkunitId,
                    OverridingJobtitleId: OverridingJobtitleId,
                    RetainAssignment: false,
                },
            })
        )
    }

export const selectItemMap = createDraftSafeSelector(selectEvents, itemMapSelector)

const clipboardContentSelector = (state: ICalendarState) => {
    const clipboardContentset = new Set<string>()

    state.clipBoardContent.forEach((x) => clipboardContentset.add(x))

    return clipboardContentset
}

export const selectClipboardContent = createSelector(selectSelf, clipboardContentSelector)

export const selectSelectedEventsSelector = (state: ICalendarState): IEventModel[] => {
    const selectedCells = state.selectedCells

    const itemMap = itemMapSelector(state.items)

    const selectedNodeIds = selectedCells
        .map((cell) => {
            return state.idMap[cell.y][cell.x]
        })
        .filter((x) => x !== 'null')

    const selectedNodeObjects = selectedNodeIds
        .map((id) => {
            return itemMap.get(id)?.data
        })
        .filter((x) => x) as IEventModel[]

    return selectedNodeObjects
}

export const selectSelectedEvents = createSelector(selectSelf, selectSelectedEventsSelector)

export const selectOverallMetrics = createSelector(selectSelf, (state) => state.metrics.overallMetrics)
export const selectWorkUnitMetrics =
    (workUnitId: number) =>
    (state: RootState): IDataSourceGroupData[] => {
        return state.calendarv2.metrics.workUnitMetrics
            .filter((workUnitMetric) => workUnitMetric.workUnitId.toString() === workUnitId.toString())
            .map((metric) => metric.metrics)[0]
    }
export const selectSelectedDaysAndGroupsSelector = (state: ICalendarState): { day: Moment; group: IGroupData }[] => {
    return state.selectedCells.map((cell) => {
        return { day: state.startDay.clone().add(cell.x, 'days'), group: state.groups[cell.y] }
    })
}

export const selectSelectedDaysAndGroups = createSelector(selectSelf, selectSelectedDaysAndGroupsSelector)

export const selectCalendarGroup =
    (groupId: number | null) =>
    (state: RootState): IGroupData | null =>
        groupId === null ? null : state.calendarv2.groups[groupId]

export const selectEmptyGroup =
    () =>
    (state: RootState): IDataSourceWindowFunctionData[] =>
        state.calendarv2.totalGroup

export const selectCalendarContent =
    (contentId: string) =>
    (state: RootState): ICalendarNodeContent | undefined | null =>
        contentId === null ? null : selectItemMap(state).get(contentId)

export const selectCalendarContentId =
    (xCoord: number, yCoord: number) =>
    (state: RootState): string =>
        state.calendarv2.idMap[yCoord][xCoord]

const transformValue = (value: any, transformation?: string | undefined) => {
    if (transformation === 'date') {
        return moment(value).format('LL')
    } else {
        return value
    }
}

export const calendarReducer = createReducer<ICalendarState>(calendarInitialState, (builder) => {
    builder
        .addCase(contentSelectorApplied, (state, action) => {
            const selectorProperty = action.payload.selectorProperty

            const valueTransformation = action.payload.propertyTransformation

            const selectedCells: { x: number; y: number }[] = []

            const selectedNodeIds = state.selectedCells
                .map((cell) => {
                    return state.idMap[cell.y][cell.x]
                })
                .filter((x) => x !== 'null')

            const itemMap = new Map<string, ICalendarNodeContent>()

            state.items.forEach((x) => {
                itemMap.set(x.id, x)
            })

            const currentlySelectedEvents = selectedNodeIds
                .map((id) => {
                    return itemMap.get(id)?.data
                })
                .filter((x) => x) as IEventModel[]

            const selectAllCells = selectorProperty === null

            const testValues = selectAllCells
                ? []
                : currentlySelectedEvents.map((x) =>
                      transformValue(get(x, selectorProperty as string), valueTransformation)
                  )

            for (let i = 0; i < state.sizeX; i = i + 1) {
                for (let j = 0; j < state.sizeY; j = j + 1) {
                    const objId = state.idMap[j][i]

                    if (objId !== 'null') {
                        const item = itemMap.get(objId)

                        if (item && selectAllCells) {
                            selectedCells.push({ x: i, y: j })
                        } else if (item && !selectAllCells) {
                            const testValue = transformValue(
                                get(item.data, selectorProperty as string),
                                valueTransformation
                            )

                            if (testValues.find((x) => isEqual(x, testValue))) {
                                selectedCells.push({ x: i, y: j })
                            }
                        }
                    }
                }
            }

            state.selectedCells = selectedCells

            return state
        })
        .addCase(selectionCleared, (state) => {
            state.selectedCells = []
            state.cursorIndexX = null
            state.cursorIndexY = null
            state.clipBoardContent = []

            return state
        })
        .addCase(selectedCellsSetToClipboardForCut, (state) => {
            state.clipBoardContent = state.selectedCells
                .map((cell) => {
                    return state.idMap[cell.y][cell.x]
                })
                .filter((x) => x !== 'null')

            state.clipBoardAction = 'cut'

            return state
        })
        .addCase(selectedCellsSetToClipboardForCopy, (state) => {
            state.clipBoardContent = state.selectedCells
                .map((cell) => {
                    return state.idMap[cell.y][cell.x]
                })
                .filter((x) => x !== 'null')

            state.clipBoardAction = 'copy'

            return state
        })
        .addCase(nodeSelected, (state, action) => {
            state.isCreateOpen = false
            state.isEditOpen = false

            state.cursorIndexX = action.payload.cursorIndexX
            state.cursorIndexY = action.payload.cursorIndexY

            if (action.payload.retainOldSelection) {
                const newSelectedCells = [
                    ...state.selectedCells,
                    { x: action.payload.cursorIndexX, y: action.payload.cursorIndexY },
                ]
                state.selectedCells = uniqWith(newSelectedCells, isEqual)
            } else {
                state.selectedCells = [{ x: action.payload.cursorIndexX, y: action.payload.cursorIndexY }]
            }

            return state
        })
        .addCase(nodeDeSelected, (state, action) => {
            state.cursorIndexX = action.payload.cursorIndexX
            state.cursorIndexY = action.payload.cursorIndexY
            state.isEditOpen = false
            state.isCreateOpen = false
            if (action.payload.retainOthers) {
                const newSelectedCells = state.selectedCells.filter(
                    (cell) => !(cell.x === action.payload.cursorIndexX && cell.y === action.payload.cursorIndexY)
                )

                state.selectedCells = newSelectedCells
            } else {
                state.selectedCells = []
            }

            return state
        })
        .addCase(eventTemplatesFetched, (state, action) => {
            state.eventTemplates = action.payload

            return state
        })
        .addCase(holidaysFetched, (state, action) => {
            state.holidays = action.payload

            return state
        })
        .addCase(dateRangeChanged, (state, action) => {
            state.nextStartDay = action.payload.startDate
            state.nextEndDay = action.payload.endDate

            const size = action.payload.endDate.diff(action.payload.startDate, 'days') + 1
            state.nextSizeX = size < 0 ? 14 : size

            if (size < 0) {
                state.nextEndDay = state.nextStartDay.clone().add(13, 'days')
            }

            return state
        })
        .addCase(cursorMovedUp, (state) => {
            if (!state.isCreateOpen && !state.isEditOpen) {
                if (state.cursorIndexX !== null && state.cursorIndexY !== null && state.cursorIndexY !== 0) {
                    state.cursorIndexY = state.cursorIndexY - 1
                    state.selectedCells = [{ x: state.cursorIndexX, y: state.cursorIndexY }]
                }
            }
            return state
        })
        .addCase(cursorMovedDown, (state) => {
            if (!state.isCreateOpen && !state.isEditOpen) {
                if (
                    state.cursorIndexX !== null &&
                    state.cursorIndexY !== null &&
                    state.cursorIndexY !== state.sizeY - 1
                ) {
                    state.cursorIndexY = state.cursorIndexY + 1
                    state.selectedCells = [{ x: state.cursorIndexX, y: state.cursorIndexY }]
                }
            }
            return state
        })
        .addCase(cursorMovedLeft, (state) => {
            if (!state.isCreateOpen && !state.isEditOpen) {
                if (state.cursorIndexX !== null && state.cursorIndexY !== null && state.cursorIndexX !== 0) {
                    state.cursorIndexX = state.cursorIndexX - 1
                    state.selectedCells = [{ x: state.cursorIndexX, y: state.cursorIndexY }]
                }
            }

            return state
        })
        .addCase(cursorMovedRight, (state) => {
            if (!state.isCreateOpen && !state.isEditOpen) {
                if (
                    state.cursorIndexX !== null &&
                    state.cursorIndexY !== null &&
                    state.cursorIndexX !== state.sizeX - 1
                ) {
                    state.cursorIndexX = state.cursorIndexX + 1
                    state.selectedCells = [{ x: state.cursorIndexX, y: state.cursorIndexY }]
                }
            }
            return state
        })
        .addCase(editOpened, (state) => {
            if (state.cursorIndexX !== null && state.cursorIndexY !== null) {
                const selectedId = state.idMap[state.cursorIndexY][state.cursorIndexX]

                if (selectedId !== 'null') {
                    const selectedEvent = state.items.find((x) => x.id === selectedId)
                    if (selectedEvent) {
                        const initialTimeText = formatEventTime(selectedEvent.data)

                        state.isEditOpen = true
                        state.editedTimeAsText = initialTimeText
                    }
                }
            }

            return state
        })
        .addCase(editClosed, (state) => {
            state.isEditOpen = false

            return state
        })
        .addCase(selectedCellsLoadingEnded, (state) => {
            state.areSelectedCellsLoading = false

            return state
        })
        .addCase(selectedCellsLoadingStarted, (state) => {
            state.areSelectedCellsLoading = true

            return state
        })
        .addCase(createOpened, (state) => {
            if (state.cursorIndexX !== null && state.cursorIndexY !== null) {
                //This might be slow if more than 100 cells selected and user tries to use hotkey to open in cell create input
                const isOtherContentInTheCell = state.idMap[state.cursorIndexY][state.cursorIndexX] !== 'null'
                if (!isOtherContentInTheCell) {
                    state.isEditOpen = true
                    state.editedTimeAsText = DEFAULT_TIME_INPUT
                }
            }

            return state
        })
        .addCase(createClosed, (state) => {
            state.isEditOpen = false

            return state
        })
        .addCase(structureInitialized, (state, action) => {
            const { startDay, endDay, sizeX, configuration } = action.payload

            state.sizeX = sizeX
            state.startDay = startDay
            state.endDay = endDay
            state.nextEndDay = endDay
            state.nextStartDay = startDay
            state.nextSizeX = sizeX
            state.isStructureInitialized = true
            state.calendarConfiguration = configuration

            return state
        })
        .addCase(eventTimeInLineEdited, (state, action) => {
            const newValue = action.payload

            let _refinedInput = ''

            let isOverwrite = false
            if (!state.isEventTimeEdited) {
                _refinedInput = newValue.replace(DEFAULT_TIME_INPUT, '')
                isOverwrite = true
            } else {
                _refinedInput = newValue
            }

            state.isEventTimeEdited = true

            const oldInputLength = state.editedTimeAsText?.length ?? 0
            const newInputLength = _refinedInput.length

            const isUserAddingText = newInputLength > oldInputLength || isOverwrite

            //autofill if user tries to write 8-  or 08- or 18:0-
            if (
                isUserAddingText &&
                newValue.length > 1 &&
                newValue.length < 5 &&
                newValue[newValue.length - 1] === '-'
            ) {
                const newValueWithoutDash = newValue.substring(0, newValue.length - 1)

                switch (newValueWithoutDash.length) {
                    case 4:
                        _refinedInput = newValueWithoutDash + '0'
                        break

                    case 3:
                        _refinedInput = newValueWithoutDash + '00'
                        break

                    case 2:
                        _refinedInput = newValueWithoutDash + ':00'
                        break

                    case 1:
                        _refinedInput = newValueWithoutDash.substring(0, 1) + '0' + newValueWithoutDash + ':00'
                        break

                    default:
                        break
                }
            }

            //If user tries to add "-" correct it to '–'
            if (isUserAddingText && _refinedInput[newInputLength - 1] === '-') {
                _refinedInput = _refinedInput.substring(0, newInputLength - 1) + '–'
            }

            const regexToMatch = /^([0-1]?[0-9]|2[0-3])(:|.)[0-5][0-9]–([0-1]?[0-9]|2[0-3])(.|:)[0-5][0-9]$/

            const isCompletedInput = _refinedInput === DEFAULT_TIME_INPUT ? false : regexToMatch.test(_refinedInput)

            //Change time separator to if manually written with '.'
            _refinedInput = _refinedInput.replaceAll('.', ':')

            const timePairs = _refinedInput.split('–')

            //If only start time given
            if (timePairs.length === 1) {
                const autofillResult = autoFillTimeInput(timePairs[0], isUserAddingText)

                if (!autofillResult.isOk) {
                    return
                }

                _refinedInput = autofillResult.newText
            }

            //Autofill dash if start time is valid and cannot have more characters
            if (
                isUserAddingText &&
                (_refinedInput.length === 5 ||
                    (_refinedInput.length === 4 && _refinedInput[3] === '0' && _refinedInput[2] === '0'))
            ) {
                _refinedInput = _refinedInput + '–'
            }

            //If both start and endtime is given, validate input and ensure formating
            if (timePairs.length === 2) {
                const secondPart = timePairs[1]

                const autofillResult = autoFillTimeInput(secondPart, isUserAddingText)

                if (!autofillResult.isOk) {
                    return
                }

                _refinedInput = timePairs[0] + '–' + autofillResult.newText
            }

            if (isCompletedInput) {
                state.IsEditedTextValid = true
            } else {
                state.IsEditedTextValid = false
            }

            state.editedTimeAsText = _refinedInput

            return state
        })
        .addCase(setShowDayCalculations, (state, { payload }) => {
            state.showDayCalculations = payload
        })
        .addMatcher(
            (action: AnyAction) => action.type === 'data-source.DataFetched',
            (state, action) => {
                const { dataSourceId, data, groupData, dataStatus } = action.payload

                if (dataSourceId === CALENDAR_DATASOURCE_ID && dataStatus === 'LimitExceeded') {
                    state.isTooMuchDataToRender = true
                    state.selectedCells = []
                    state.isContentInitialized = true
                } else if (dataSourceId === CALENDAR_DATASOURCE_ID && data) {
                    const calendarStart = state.nextStartDay
                    const calendarEnd = state.nextEndDay
                    const calendarSizeX = state.nextSizeX
                    const groupingConfiguration = state.calendarConfiguration.groupingConfiguration

                    const { groups, idMap, items, sizeY, totalGroup, metrics } =
                        formatDataSourceResponseToCalendarContent(
                            calendarSizeX,
                            calendarStart,
                            calendarEnd,
                            { GroupingResponse: groupData, ListData: data },
                            groupingConfiguration
                        )

                    if (sizeY !== state.sizeY || state.sizeX !== state.nextSizeX) {
                        state.selectedCells = []
                    }

                    state.startDay = state.nextStartDay
                    state.endDay = state.nextEndDay
                    state.sizeX = state.nextSizeX
                    state.groups = groups
                    state.idMap = idMap
                    state.items = items
                    state.sizeY = sizeY
                    state.isContentInitialized = true
                    state.totalGroup = totalGroup
                    state.metrics = metrics
                    state.isTooMuchDataToRender = false
                    return state
                }

                return state
            }
        )
})
