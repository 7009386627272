import styled from '@emotion/styled'

import {
    Checkbox,
    Icon,
    RadioButton,
    ReadOnlyText,
    TextInputV2,
    Tooltip,
    TranslatedButton,
} from '../../generic-components'
import { light_grey_120, primary_100 } from 'constants/Styles'
import { useDispatch, useSelector } from 'react-redux'
import {
    newMessageFormMessageChanged,
    newMessageFormMessageTypeChanged,
    newMessageFormSendEmailChanged,
    newMessageFormTitleChanged,
} from '../State/MessagingReducer'
import {
    selectMessageRecipients,
    selectNewMessageFormMessage,
    selectNewMessageFormMessageType,
    selectNewMessageFormSendEmail,
    selectNewMessageFormTitle,
} from '../State/MessagingSelectors'
import { EMessageType } from '../Types/IMessageThread'
import { startNewMessageThread } from '../State/MessagingThunks'
import RecipientPicker from './RecipientPicker'
import { isEmpty } from 'lodash-es'
import { translate } from '../../localization'

const Container = styled.div<{ height?: number }>`
    margin: 0;
    transition: height 0.4s ease-in-out;
    min-height: ${({ height }) => `${height}px` ?? '50px'};
`

const ActionButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    margin-top: 12px;
    border-top: 1px solid ${light_grey_120};
    padding: 24px 32px;
`

const NewMessageFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    border-bottom: 1px solid ${light_grey_120};
`

const MessageTypeSelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: -10px;
`

const MessageTypeSelection = styled.div`
    display: flex;
    flex-direction: row;
`

const InlineNotification = styled.div`
    display: flex;
    margin-left: 32px;
    flex-direction: row;
    align-items: center;
    gap: 6px;
`

const InputContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 25px;
    padding: 0 32px 32px 32px;
    overflow: hidden;
`

const StyledTooltip = styled(Tooltip)`
    align-items: center;
    justify-content: center;
    margin-left: 13px;
`

type Props = {
    onClose: () => void
    cancelButtonText: string
}

const NewMessageForm = ({ onClose, cancelButtonText }: Props): JSX.Element => {
    const dispatch = useDispatch()

    const message = useSelector(selectNewMessageFormMessage)
    const title = useSelector(selectNewMessageFormTitle)
    const messageType = useSelector(selectNewMessageFormMessageType)
    const sendEmail = useSelector(selectNewMessageFormSendEmail)
    const recipients = useSelector(selectMessageRecipients)

    const formValid = !isEmpty(recipients) && Boolean(title?.trim()) && Boolean(message?.trim())

    const handleNewMessageFormMessageChanged = (value: string) => {
        dispatch(newMessageFormMessageChanged(value))
    }

    const handleNewMessageFormTitleChanged = (value: string) => {
        dispatch(newMessageFormTitleChanged(value))
    }

    const handleNewMessageFormEmailChanged = () => {
        dispatch(newMessageFormSendEmailChanged(!sendEmail))
    }

    const handleSendNewMessageForm = () => {
        dispatch(startNewMessageThread())
    }

    const messageTypeOptions = [
        {
            label: 'messaging.mobileMessage',
            tooltip: 'messaging.mobileMessageTooltip',
            checked: messageType === EMessageType.MobileMessage,
            onValueSet: () => dispatch(newMessageFormMessageTypeChanged(EMessageType.MobileMessage)),
        },
        {
            label: 'messaging.announcement',
            tooltip: 'messaging.infoMessageTooltip',
            checked: messageType === EMessageType.InfoMessage,
            onValueSet: () => dispatch(newMessageFormMessageTypeChanged(EMessageType.InfoMessage)),
        },
    ]

    const messageTypeBlock = (
        <MessageTypeSelectionContainer>
            {messageTypeOptions.map(({ checked, label, tooltip, onValueSet }) => (
                <MessageTypeSelection key={label}>
                    <RadioButton checked={checked} label={label} onValueSet={onValueSet} />
                    <StyledTooltip title={tooltip} translate="yes">
                        <Icon size={18} color={primary_100}>
                            info_outline
                        </Icon>
                    </StyledTooltip>
                </MessageTypeSelection>
            ))}

            {messageType === EMessageType.InfoMessage && (
                <MessageTypeSelection>
                    <InlineNotification>
                        <Checkbox
                            onClick={handleNewMessageFormEmailChanged}
                            value={sendEmail}
                            id="send-email-checkbox"
                        />
                        <label htmlFor="send-email-checkbox">
                            <ReadOnlyText translate usage="dropdown_selection">
                                messaging.sendEmail
                            </ReadOnlyText>
                        </label>
                    </InlineNotification>
                </MessageTypeSelection>
            )}
        </MessageTypeSelectionContainer>
    )

    return (
        <Container>
            <RecipientPicker />

            <NewMessageFormContainer>
                <InputContainer>
                    {messageTypeBlock}

                    <TextInputV2
                        value={title ?? ''}
                        label={translate('messaging.title')}
                        placeholder="write"
                        overrideStyle={{ width: '100%' }}
                        onChange={handleNewMessageFormTitleChanged}
                        isRequiredField
                    />
                    <TextInputV2
                        value={message ?? ''}
                        label={translate('messaging.messageContent')}
                        placeholder="write"
                        overrideStyle={{ width: '100%' }}
                        onChange={handleNewMessageFormMessageChanged}
                        maxRows={20}
                        multiline
                        isRequiredField
                    />
                </InputContainer>

                <ActionButtonContainer>
                    <TranslatedButton
                        onClick={handleSendNewMessageForm}
                        variant="contained"
                        disabled={!formValid}
                        color="white"
                    >
                        messaging.formSend
                    </TranslatedButton>

                    <TranslatedButton onClick={onClose} variant="text" color="primary_120">
                        {cancelButtonText}
                    </TranslatedButton>
                </ActionButtonContainer>
            </NewMessageFormContainer>
        </Container>
    )
}

export default NewMessageForm
