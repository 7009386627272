import { Chip, DayRangePickerV2, PopoverWithComponent, TranslatedTypography } from '../../generic-components'
import { useQuery } from '../../generic-utilities'
import styled from '@emotion/styled'

import moment, { Moment } from 'moment'
import { formatForDataUsage, formatForDisplay, toDateObject } from '../../dates'
import { IValuePickerCommonOwnProps } from '../../value-picker'
import Dropdown from '../../generic-components/Inputs/Dropdown/Dropdown'
import { ERequestMethod } from '../../rest-api'
import { useEffect, useState } from 'react'
import { removeDuplicateObjectsInArrayBasedOnKey } from '../../generic-utilities/ArrayUtils'
import { getWeeksCountBetweenDates } from '../../dates/Utilities/DateUtilities'
import { translate } from '../../localization'

const StyledPopoverWithComponent = styled(PopoverWithComponent)``

const TitleContainer = styled.div`
    padding-bottom: 20px;
`

const getDisplayValue = (calendarDates: { startDate: Moment; endDate: Moment }) => {
    const { startDate, endDate } = calendarDates
    return `${formatForDisplay(startDate)}–${formatForDisplay(endDate)}`
}

const getDisplayValueWithWeekCount = (calendarDates: { startDate: Moment; endDate: Moment }) => {
    const { startDate, endDate } = calendarDates
    const weeksCount = getWeeksCountBetweenDates(startDate, endDate, 0)
    return `${formatForDisplay(startDate)}–${formatForDisplay(endDate)} - ${weeksCount} ${translate('viikkoa')}`
}

export interface IDateRangePickerProps extends IValuePickerCommonOwnProps<{ startDate: Moment; endDate: Moment }> {}
interface IPeriods {
    Id: number
    StartDate: string
    EndDate: string
}

const DateRangeV2Picker = (props: IDateRangePickerProps): JSX.Element => {
    const [internalValue, setInternalValue] = useState({ startDate: moment(), endDate: moment() })

    const [internalValueDidChange, setInternalValueDidChange] = useState(false)

    const formattedValue = {
        start: formatForDataUsage(internalValue.startDate),
        end: formatForDataUsage(internalValue.endDate),
    }

    const dateString = getDisplayValue(internalValue)

    useEffect(() => {
        setInternalValue(props.value)
    }, [props.value])

    const _onChange = (newValues: { start: string; end: string }) => {
        const newValueObjects = { startDate: toDateObject(newValues.start), endDate: toDateObject(newValues.end) }

        setInternalValue(newValueObjects)

        setInternalValueDidChange(true)
    }

    const onDialogClose = () => internalValueDidChange && props.onChange(internalValue)

    const { data, isLoading } = useQuery<{ ListData: IPeriods[] }>('Period/Search', ERequestMethod.POST, {
        Filters: {
            PeriodTypes: [1],
            OverlapsDateRange: { Alku: moment().subtract(3, 'months'), Loppu: moment().add(6, 'months') },
        },
    })

    const options = removeDuplicateObjectsInArrayBasedOnKey(
        data?.ListData.map((period) => {
            return {
                Id: period.Id,
                startDate: period.StartDate,
                Name: getDisplayValueWithWeekCount({
                    startDate: moment(period.StartDate),
                    endDate: moment(period.EndDate),
                }),
                endDate: period.EndDate,
            }
        }) ?? [],
        'Name'
    )

    const handleOnPeriodChange = (selectedRowId: number[]) => {
        const index = options.findIndex((option) => option.Id === selectedRowId[0])
        _onChange({ start: options[index].startDate, end: options[index].endDate })
    }

    return (
        <StyledPopoverWithComponent
            anchorOriginHorizontal="left"
            onClose={onDialogClose}
            openPopoverRenderer={(onMenuOpen) => (
                <Chip disabled={false} isSelectionIconRendered label={dateString} onClick={onMenuOpen} />
            )}
            closeWithEnter
        >
            <TitleContainer>
                <TranslatedTypography variant="subtitle1">
                    workforce-scheduling.ActionBar.DatePicker.Title
                </TranslatedTypography>
            </TitleContainer>
            <DayRangePickerV2
                endDay={formattedValue.end}
                isEndDayErrored={false}
                isFieldErrored={false}
                isStartDayErrored={false}
                onChange={_onChange}
                startDay={formattedValue.start}
                valuePickerId={props.valuePickerId}
            />
            <Dropdown
                label={translate('Jaksot')}
                isMultiselect={false}
                value={[0]}
                options={options}
                placeholder="Valitse luoduista jaksoita"
                onChange={handleOnPeriodChange}
                disabled={isLoading}
                showClearSelection={false}
            />
        </StyledPopoverWithComponent>
    )
}

export default DateRangeV2Picker
