import { Moment } from 'moment'
import makeRequest, { ERequestMethod } from '../../../rest-api'
import { ITransactionPricingDependencies } from './usePricingDependencies'
import { CancelToken } from 'axios'

interface ITransactionPrice {
    salaryPrice?: number
    salaryBasePrice?: number
    salaryPersentage?: number
    salaryBasisId?: number
    isSalaryEnabled?: boolean
    billingPrice?: number
    billingBasePrice?: number
    billingCoefficient?: number
    billingBasisId?: number
    isBillingEnabled?: boolean
}

interface IInternalPricingResult {
    PalkkaperusteId?: number
    Peruspalkka?: number
    SalaryCoefficientPercentage?: number
    Palkkatulo: number
    LaskutusperusteId: number
    Peruslaskutushinta: number
    Laskutuskerroin: number
    Laskutustulo: number
    Palkkahyvaksytty: boolean
    Laskutushyvaksytty: boolean
}

interface IInterfacePricingRequest {
    TyontekijaIdt: number[]
    TallennettavaToteumaRivi: {
        ToteumaId?: number
        MainRowId?: number
        Alkuaika?: Moment
        Loppuaika?: Moment
        AsiakasId?: number
        AmmattinimikeId?: number
        TesId?: number
        PalkkalajiId?: number
        PalkkaperusteId?: number
        SalaryCoefficientPercentage?: number
        Peruspalkka?: number
        Tuntipalkka?: number
        LaskutusperusteId?: number
        Laskutuskerroin?: number
        Peruslaskutushinta?: number
        aLaskutushinta?: number
        aPalkkahinta?: number
        TyontekijaId?: number
        Paivamaara: Moment[]
        ProductId?: number
    }
}

const mapDependenciesToPricingRequest = (dep: ITransactionPricingDependencies): IInterfacePricingRequest => {
    return {
        TyontekijaIdt: dep.employeeId ? [dep.employeeId] : [],
        TallennettavaToteumaRivi: {
            Paivamaara: dep.dates ? dep.dates : [],
            Alkuaika: dep.startTime ?? undefined,
            Loppuaika: dep.endTime ?? undefined,
            AsiakasId: dep.workunitId,
            TesId: dep.claId,
            PalkkalajiId: dep.salaryCategoryId,
            Tuntipalkka: dep.salaryPrice,
            AmmattinimikeId: dep.jobtitleId,
            TyontekijaId: dep.employeeId,
            PalkkaperusteId: dep.salaryPrice === undefined || dep.salaryPrice === null ? undefined : 3,
            Peruspalkka: dep.salaryBasisPrice,
            SalaryCoefficientPercentage: dep.salaryPersentage,
            aPalkkahinta: dep.salaryPrice,
            ProductId: dep.productId,
            ToteumaId: dep.rowId,
            MainRowId: dep.mainRowId,
        },
    }
}

const mapInternalPricingResultToTransactionPrice = (result: IInternalPricingResult): ITransactionPrice => {
    return {
        billingBasePrice: result.Peruslaskutushinta,
        billingCoefficient: result.Laskutuskerroin,
        billingBasisId: result.LaskutusperusteId,
        billingPrice: result.Laskutustulo,
        isBillingEnabled: result.Laskutushyvaksytty,
        salaryBasePrice: result.Peruspalkka,
        salaryPersentage: result.SalaryCoefficientPercentage,
        salaryBasisId: result.PalkkaperusteId,
        salaryPrice: result.Palkkatulo,
        isSalaryEnabled: result.Palkkahyvaksytty,
    }
}

const enoughDependenciesDefinedForPricing = (deps: ITransactionPricingDependencies): boolean => {
    return Boolean(
        deps.workunitId &&
            deps.employeeId &&
            deps.jobtitleId &&
            deps.dates &&
            deps.dates.length > 0 &&
            deps.startTime &&
            deps.endTime &&
            deps.salaryCategoryId
    )
}

const FetchTransactionPricing = async (
    dependencies: ITransactionPricingDependencies,
    cancelToken: CancelToken
): Promise<ITransactionPrice | null> => {
    let pricingResult = null

    if (enoughDependenciesDefinedForPricing(dependencies)) {
        const requestData = mapDependenciesToPricingRequest(dependencies)
        try {
            const pricingResponse = await makeRequest<IInternalPricingResult>({
                url: 'palkka/Hinnoittelu',
                method: ERequestMethod.POST,
                data: requestData,
                cancelToken: cancelToken,
            })

            if (pricingResponse) {
                pricingResult = mapInternalPricingResultToTransactionPrice(pricingResponse)
            }
        } catch {
            //Log error
        }
    }

    return pricingResult
}

export default FetchTransactionPricing

export const fetchSalaryRoundingPrecision = async (): Promise<number | undefined> => {
    try {
        const decimalPlaces = await makeRequest<number>({
            url: 'palkka/Salary/RoundingPrecision',
            method: ERequestMethod.GET,
        })

        return decimalPlaces
    } catch {
        //Log error

        return undefined
    }
}

export const fetchInvoicingRoundingPrecision = async (): Promise<number | undefined> => {
    try {
        const decimalPlaces = await makeRequest<number>({
            url: 'palkka/Invoicing/RoundingPrecision',
            method: ERequestMethod.GET,
        })

        return decimalPlaces
    } catch {
        //Log error

        return undefined
    }
}
