import * as React from 'react'

import Typography, { TypographyProps } from '@material-ui/core/Typography'
import styled from '@emotion/styled'
import { getNavigationSectionId } from '../../../utils/NavigationSectionIds'

interface ISectionLabelProps {
    children: React.ReactNode
    to: string
    isCurrentPage: boolean
    className?: string
    variant?: string
    sectionName: string
    isDrawerOpen?: boolean
}

interface IStyledLinkProps extends TypographyProps {
    component: 'a'
    href: string
    isCurrentPage: boolean
    isDrawerOpen?: boolean
}

const StyledLink = styled<React.FC<IStyledLinkProps>>(Typography, {
    shouldForwardProp: (prop: keyof IStyledLinkProps) => prop !== 'isCurrentPage' && prop !== 'isDrawerOpen',
})`
    display: flex;
    align-items: center;
    height: 36px;
    line-height: 36px;
    padding-left: 6px;
    margin-left: 2px;
    margin-right: 2px;

    margin-bottom: 4px;
    text-decoration: none;
    border-radius: ${({ theme }) => theme.tokens.radiusDefault};
    transition: background-color ${({ theme }) => theme.tokens.transitionQuick};

    ${({ isCurrentPage, isDrawerOpen, theme }) =>
        isCurrentPage &&
        !isDrawerOpen &&
        `
        color: ${theme.componentExtensions.text.primary};
        background-color: ${theme.colors.primaryTeal30};
    `}

    > * {
        margin-right: 12px;
    }

    :hover {
        background-color: ${({ isCurrentPage, isDrawerOpen, theme }) =>
            !isCurrentPage && !isDrawerOpen && theme.colors.primaryCyan60};
    }
`

const TabLabel = ({
    className,
    isCurrentPage,
    children,
    to,
    variant = 'bodyM',
    sectionName,
    isDrawerOpen,
}: ISectionLabelProps): JSX.Element => {
    const testId = getNavigationSectionId(sectionName ?? '')
    return (
        <StyledLink
            data-testid={testId}
            className={className}
            component="a"
            href={to}
            isCurrentPage={isCurrentPage}
            isDrawerOpen={isDrawerOpen}
            variant={variant}
        >
            {children}
        </StyledLink>
    )
}

export default TabLabel
