import { useDispatch } from 'react-redux'
import * as React from 'react'
import { MouseEvent, useContext, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import { Icon } from '../../../../generic-components'
import { IConfigurableListHeaderComponent } from '../../../interfaces/IList'
import ConfigurableListComponentRegistry from '../../../ConfigurableListComponentRegistry'
import { setColumnSortedAndFetchSortedDataThunk } from '../../../Thunks/ConfigurableListThunks'
import SortIcon from './SortIcon'
import { IDataSourceSortParameter } from '../../../../data-source-types'
import { setColumnWidthAction } from '../../../State/ConfigurableListActions'

export interface IRowHeaderElementProps {
    headerComponent: IConfigurableListHeaderComponent | null
    id: string
    listId: string
    text: string
    width: number
    sortByParameter: IDataSourceSortParameter['Property']
    sortOrder: IDataSourceSortParameter['Order'] | undefined
    tooltip: string | null
    marginRightOverride?: number
    isDefaultWidth?: boolean
}

const Container = styled.div<{ width: number }>`
    width: ${({ width }) => `${width}px`};
    min-width: ${({ width }) => `${width}px`};
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
    padding-right: 25px;
    cursor: pointer;
`

const HeaderText = styled(Typography)`
    color: ${({ theme }) => theme.colors.neutralsGrey65};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

const InfoIconTooltipContainer = styled.span`
    margin-left: 4px;
`

/**
 * Renderöi yksittäisen sarakkeen otsikon.
 */
const RowHeaderElement = ({
    id,
    listId,
    sortByParameter,
    sortOrder,
    tooltip,
    headerComponent,
    text,
    width,
    isDefaultWidth,
}: IRowHeaderElementProps): JSX.Element => {
    const dispatch = useDispatch()
    const componentContext = useContext(ConfigurableListComponentRegistry)

    const contentRef = useRef<HTMLDivElement | null>(null)

    // automatically increase column width if the default width is too small to fit the entire text without overflowing
    useEffect(() => {
        const element = contentRef.current

        if (!element || !isDefaultWidth) {
            return
        }

        const minDefaultWidth = 60
        const isOverflowing = element.offsetWidth < element.scrollWidth || width < minDefaultWidth

        if (isOverflowing) {
            /*
             * element.scrollWidth * 2 is not really based on anything - just doubles the width and hopes it doesn't overflow anymore.
             * Seems to work well enough, but it can probably be improved if needed.
             */
            dispatch(setColumnWidthAction(id, Math.max(element.scrollWidth * 2, minDefaultWidth), listId))
        }
    }, [dispatch, id, isDefaultWidth, listId, width])

    const getHeaderComponent = () => {
        if (!headerComponent) {
            return null
        }

        const Component = componentContext.getComponent(headerComponent.ComponentId)

        if (!Component) {
            return null
        }

        const staticParameters = headerComponent.StaticParameters.reduce((accumulatedValue, currentValue) => {
            const { Name, Value } = currentValue
            accumulatedValue[Name] = Value

            return accumulatedValue
        }, {} as { [key: string]: unknown })

        return <Component {...staticParameters} />
    }

    const handleHeaderClick = (event: MouseEvent) => {
        event.stopPropagation()
        sortByParameter && dispatch(setColumnSortedAndFetchSortedDataThunk(listId, sortOrder, sortByParameter))
    }

    const headerContent = headerComponent ? (
        <div ref={contentRef}>{getHeaderComponent()}</div>
    ) : (
        <HeaderText variant="listHeader" ref={contentRef}>
            {text}
        </HeaderText>
    )

    const infoIcon =
        tooltip === null ? null : (
            <Icon
                colorTheme="primary"
                size={16}
                tooltip={tooltip}
                tooltipChildWrapperElement={InfoIconTooltipContainer}
            >
                info_outlined
            </Icon>
        )

    return (
        <Container onClick={handleHeaderClick} role="button" width={width}>
            {headerContent}
            {infoIcon}
            <SortIcon handleHeaderClick={handleHeaderClick} sortOrder={sortOrder} />
        </Container>
    )
}

export default RowHeaderElement
