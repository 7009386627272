import * as React from 'react'

import ITyontekija from 'interfaces/ITyontekija'
import { ListElementText } from '../../../configurable-list-components'

interface IEmployeeIdProps {
    tyontekija: ITyontekija | null
}

const WorkUnitId: React.FC<IEmployeeIdProps> = ({ tyontekija }) => {
    return tyontekija ? <ListElementText displayValue={tyontekija.Id} /> : null
}

export default WorkUnitId
