import * as React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { selectIsModalOpen } from '../../../../modal'
import { selectSelectedEvents } from '../../State/CalendarState'

import { CALENDAR_DATASOURCE_ID } from '../../Constants'

import { IEventModel } from '../../Types/types'
import { EventDeletionModal } from '../../../../event'

interface IDeleteEventProps {
    formId: string
}

const DELETE_PERMISSION = 'DELETE'

const PublishEvents: React.FC<IDeleteEventProps> = ({ formId }) => {
    const isOpen = useSelector((state: RootState) => selectIsModalOpen(state, formId))
    const selectedEvents = useSelector(selectSelectedEvents)

    if (!isOpen || selectedEvents.length === 0) {
        return null
    }

    const deletableEvents = selectedEvents.filter((x) =>
        x.Kayttooikeudet.find((y) => y.Toiminto === DELETE_PERMISSION && y.OnkoSallittu)
    )

    const deletableEventsIds = deletableEvents.map((x) => x.Id) as string[]

    //eslint-disable-next-line @typescript-eslint/no-empty-function
    const resetCalendarNodeSelectionsForActiveCalendar = () => {}

    const getAreDeletionReasonsDisplayed = (events: IEventModel[]) => events.some((event) => event.IsPublished)

    return (
        <EventDeletionModal
            dataSourceId={CALENDAR_DATASOURCE_ID}
            eventIds={deletableEventsIds}
            getAreDeletionReasonsDisplayed={getAreDeletionReasonsDisplayed}
            modalId={formId}
            resetSelectedEventsAfterSubmit={resetCalendarNodeSelectionsForActiveCalendar}
        />
    )
}

export default PublishEvents
