import { RootState } from 'typesafe-actions'

import IStoredOptionsOptionIdentifierObject from '../Types/IStoredOptionsOptionIdentifierObject'
import { TDataSourceItemId } from '../../data-source-types'
import { every, get } from 'lodash-es'

export const selectDidStoredOptionsComponentFetchFail = (
    state: RootState,
    storedOptionsComponentId: string
): boolean => {
    const storedOptionsComponent = state.storedOptions[storedOptionsComponentId]

    return storedOptionsComponent ? storedOptionsComponent.fetchFailed : false
}

export const selectIsStoredOptionsComponentFetching = (state: RootState, storedOptionsComponentId: string): boolean => {
    const storedOptionsComponent = state.storedOptions[storedOptionsComponentId]

    return storedOptionsComponent ? storedOptionsComponent.fetching : false
}

export const selectStoredOptionsOptionByPropertyMatchingValue = <
    TOption extends IStoredOptionsOptionIdentifierObject = IStoredOptionsOptionIdentifierObject
>(
    state: RootState,
    storedOptionsComponentId: string,
    propertyName: keyof TOption,
    value: unknown
): TOption | null => {
    const allOptions = selectStoredOptionsComponentAllOptions<TOption>(state, storedOptionsComponentId)

    return allOptions.find((option) => get(option, propertyName) === value) ?? null
}

export const selectAreStoredOptionsComponentsDataFetchesCompleted = (
    state: RootState,
    storedOptionsComponentIds: string[]
): boolean =>
    every(storedOptionsComponentIds, (storedOptionsComponentId) => {
        const storedOptions = state.storedOptions[storedOptionsComponentId]

        return !storedOptions?.fetching && (storedOptions?.fetchSucceeded || storedOptions?.fetchFailed)
    })

const defaultEmptyArray: unknown[] = []
export const selectStoredOptionsComponentAllOptions = <TOption extends IStoredOptionsOptionIdentifierObject>(
    state: RootState,
    storedOptionsComponentId: string
): TOption[] => {
    const storedOptionsComponent = state.storedOptions[storedOptionsComponentId]

    return (storedOptionsComponent?.options ?? defaultEmptyArray) as TOption[]
}

export const selectStoredOptionsOptionById = <TOption extends IStoredOptionsOptionIdentifierObject>(
    state: RootState,
    storedOptionsComponentId: string,
    optionId: TDataSourceItemId
): TOption | null => {
    const allOptions = selectStoredOptionsComponentAllOptions<TOption>(state, storedOptionsComponentId)

    return allOptions.find(({ Id }) => Id === optionId) ?? null
}
