import icons, { isSvgIcon, SvgIconOptions } from '../../../icons/index'
import { ColorOptions } from '../../theme/MepcoLightTheme'
import { useAppTheme } from '../../theme'

type Props = {
    icon: SvgIconOptions
    color?: ColorOptions[keyof ColorOptions]
}

const SvgIcon = ({ icon, color }: Props): JSX.Element | null => {
    const { componentExtensions } = useAppTheme()

    if (!isSvgIcon(icon)) {
        return null
    }

    const IconComponent = icons[icon]

    return <IconComponent color={color || componentExtensions.icons.iconNeutral} />
}

export default SvgIcon
