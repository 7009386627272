import * as React from 'react'
import SpecialCalendarNode from './SpecialCalendarNode'
import { Translation } from '../../localization'
import { useAppTheme } from '../../theme/'

export interface IEstoCalendarNodeProps {
    endDatetime: string
    startDatetime: string
}

const EstoCalendarNode: React.FunctionComponent<IEstoCalendarNodeProps> = ({ endDatetime, startDatetime }) => {
    const { colors } = useAppTheme()

    return (
        <SpecialCalendarNode
            backgroundColor={colors.semanticWarning11}
            hoverColor={colors.neutralsGrey11}
            rawColor={colors.semanticWarning140}
            label={Translation.translateKey('calendar.Esto')}
            startDatetime={startDatetime}
            endDatetime={endDatetime}
        />
    )
}

export default EstoCalendarNode
