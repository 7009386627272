import { createAction } from 'typesafe-actions'

import IFormViewConfigurationV2 from '../Types/V2/IFormViewConfigurationV2'

import IFormView from '../Types/IFormView'

export const setFormViewAction = createAction('form-view.SetFormView')<IFormViewConfigurationV2>()
export const resetFormViewAction = createAction('form-view.ResetFormView')<TFormViewId>()
type TFormViewId = string
export const formViewInitializationFailed = createAction('form-view.InitializationFailed')<TFormViewId>()

export const finishLoadingData = createAction('form-view.FinishLoadingData')<TFormViewId>()

export const resetLoadingData = createAction('form-view.resetLoadingData')<TFormViewId>()

export const setFormViewFieldStatesAction = createAction('form-view.SetFieldStates')<
    IFormView['fieldStates'],
    TFormViewId
>()

export const applyFormViewStateModificationAction = createAction('form-view.applyFormViewStateModification')<{
    selector: string
    modification: 'hidden' | 'visible' | 'required'
}>()
