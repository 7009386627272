import styled from '@emotion/styled'

import IMessage, { EUserGroup } from '../Types/IMessage'
import { ReadOnlyText } from '../../generic-components'
import { formatDateTimeStringForDisplay } from '../../dates'
import { translate } from '../../localization'
import { PlanierTheme, useAppTheme } from '../../theme/'

type Props = {
    message: IMessage
    isOwn: boolean
}

const getBackgroundColorForMessage = (message: IMessage, theme: PlanierTheme) => {
    const { colors } = theme

    return message.CreatedByUserGroup === EUserGroup.Employee ? colors.primaryCyan11 : colors.primaryChampion11
}

const Container = styled.div<{ color: string; isOwn: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 4px;
    background-color: ${({ color }) => color};
    ${({ isOwn }) => (isOwn ? `margin-left: 32px` : `margin-right: 32px;`)}
`

const SpreadContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const MessageContent = styled.div`
    margin-top: 6px;
`

const Message = ({ message, isOwn }: Props): JSX.Element => {
    const theme = useAppTheme()
    const messageColor = getBackgroundColorForMessage(message, theme)

    return (
        <Container color={messageColor} isOwn={isOwn}>
            <SpreadContent>
                <ReadOnlyText usage="bodyS" rawColor={theme.colors.neutralsGrey100}>
                    {message.CreatedByLogin?.Name || `[${translate('user_deleted')}]`}
                </ReadOnlyText>
                <ReadOnlyText usage="bodyS" rawColor={theme.colors.neutralsGrey100}>
                    {formatDateTimeStringForDisplay(message.CreatedTimestamp)}
                </ReadOnlyText>
            </SpreadContent>
            <MessageContent>
                <ReadOnlyText usage="bodyM" rawColor={theme.colors.neutralsGrey100} textOverflow="pre-line">
                    {message.Content}
                </ReadOnlyText>
            </MessageContent>
        </Container>
    )
}

export default Message
