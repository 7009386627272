import React from 'react'

import Styles from 'constants/Styles'
import BasicCalendarNode from './BasicCalendarNode'

export interface IEmptyCalendarNodeProps {
    startDatetime: string
    endDateTime: string
    employeeId?: number | null
    workunitId?: number | null
    jobtitleId?: number | null
}

const unfilledHoverColor = '#F3F9FA'
const mainColor = Styles.planierColor.white

const EmptyCalendarNode: React.FunctionComponent<IEmptyCalendarNodeProps> = () => {
    return (
        <BasicCalendarNode
            backgroundColor={Styles.mainColor.white}
            borderColor={Styles.mainColor.white}
            hoverColor={unfilledHoverColor}
            isCoveredOnLeft={false}
            mainColor={mainColor}
            upperText={''}
            warnings={[]}
        />
    )
}

export default EmptyCalendarNode
