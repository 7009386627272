import { useCallback, useEffect } from 'react'
import moment, { Moment } from 'moment'
import styled from '@emotion/styled'
import { IFormViewCustomFormComponentProps } from '../../form-view-types'
import { IEmployeeDataSourceItem } from '../../messaging/Types/IEmployeeDataSourceItem'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, pickersDayClasses, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { closeModalAction, FormModal } from '../../modal'
import { getEventsForEmployee } from '../State/EmployeeCalendarThunks'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectEventsForDay,
    selectSelectedDays,
    selectSelectedDaysEventsGroupedByDays,
    selectSelectedMonth,
} from '../State/EmployeeCalendarSelectors'
import { RootState } from 'typesafe-actions'
import { fillDaysBetween, useModifierKeyListener } from '../Utils/utils'
import { modalClosed, setSelectDays, setSelectedMonth } from '../State/EmployeeCalendarReducer'
import CalendarDay from './CalendarDay'
import { InfoBox, TranslatedButton } from '../../generic-components'
import SelectedDaysEvents from './SelectedDaysEvents'
import { SummaryLabel } from '../../generic-components/SummaryLabel/SummaryLabels'
import EventTypeCategories from './EventTypeCategories'
import { translate } from '../../localization'
import { initializeDataSourceThunk } from '../../data-source'
import { EActionFunctionalityType, handleViewEngineActionThunk, TFunctionalityAction } from '../../view-engine'
import { compact, head, isEmpty } from 'lodash-es'
import IconButton from '../../generic-components/Buttons/IconButton/IconButton'

interface ICommmentingFormProps extends IFormViewCustomFormComponentProps<IEmployeeDataSourceItem> {}

// days are offset by one due to the finnish format
const dayMap: Record<string, string> = {
    Sun: 'Ma',
    Mon: 'Ti',
    Tue: 'Ke',
    Wed: 'To',
    Thu: 'Pe',
    Fri: 'La',
    Sat: 'Su',
}

const headerHeight = 70
const footerHeight = 80
const modalMargin = 64

const EmployeeCalendarModalComponent = styled(FormModal)`
    .MuiDialogContent-root {
        padding: 0;
    }

    .MuiDialog-paperWidthMd {
        max-width: 1440px;
        height: 100vh;
    }
`

const ModalContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100vh - ${headerHeight}px - ${footerHeight}px - ${modalMargin}px);
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
`

const EventsColumn = styled(Column)`
    flex: 1;
    overflow-y: auto;
`

const CalendarColumn = styled(Column)`
    width: 469px;
    border-right: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
`

const SelectionInfoBox = styled(InfoBox)`
    margin: 24px 20px;
`

const FORM_ID = 'EmployeeCalendarForm'
const modalTitle = 'Työntekijän kalenteri'

const StyledCalendar = styled(DateCalendar)`
    width: 100%;
    max-height: 100%;
    overflow: visible;

    .MuiDayCalendar-weekNumber {
        ${({ theme }) => theme.typography.bodyS}
        color: ${({ theme }) => theme.componentExtensions.inputs.textInputDisabled};
    }

    .MuiDayCalendar-weekDayLabel {
        ${({ theme }) => theme.typography.bodyS}
        width: 46px;
        height: 46px;
    }

    .MuiDayCalendar-weekContainer {
        display: flex;
        align-items: center;
        margin: 10px 0;
    }

    .MuiDayCalendar-weekNumberLabel {
        visibility: hidden;
    }

    .MuiDayCalendar-slideTransition {
        min-height: 355px;
    }

    .MuiPickersCalendarHeader-root {
        margin-bottom: 0;
        padding-left: 55px;
        padding-right: 32px;
    }

    .MuiPickersCalendarHeader-label {
        text-transform: capitalize;
    }

    .MuiYearCalendar-root {
        max-height: 400px;
        width: 100%;
    }

    .MuiPickersArrowSwitcher-button {
        color: ${({ theme }) => theme.componentExtensions.icons.iconAction};
    }

    .MuiDayCalendar-header {
        gap: 5px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
`

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 80px;
    border-top: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
    padding: 0 32px;
`

const EventInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 32px 12px 32px;
`

const CustomPickersDay = styled(PickersDay, { shouldForwardProp: (propName) => propName !== 'selectedDays' })`
    border-radius: ${({ theme }) => theme.tokens.radiusSmall};
    width: 46px;
    height: 46px;
    margin: 0 5px;

    &.MuiPickersDay-today {
        border: none;
    }
`

const CreateEventAction: TFunctionalityAction = {
    FunctionalityType: EActionFunctionalityType.ConfigurableModal,
    DataSourceActionId: 'Event_Create',
    FormId: 'Event_Create',
    PermissionId: 'CREATE',
    AdditionalInitialValueSourceProperties: [
        { key: 'Tyontekija.Id', PropertyPath: 'EmployeeId', liftToArray: true },
        { key: 'Alkamisaika', PropertyPath: 'StartTime', liftToArray: true, format: 'toDate' },
    ],
}

const Day = (props: PickersDayProps<Moment> & { selectedDays: Moment[] }): JSX.Element => {
    const { day, selectedDays } = props

    const events = useSelector((state: RootState) => selectEventsForDay(state, day, false))

    const index = selectedDays.findIndex((selectedDay) => selectedDay.isSame(day, 'date'))
    const selected = index > -1

    const today = moment(new Date())
    const isToday = day.clone().startOf('day').isSame(today.startOf('day'))

    return (
        <CustomPickersDay
            {...props}
            sx={{
                [`&&.${pickersDayClasses.dayOutsideMonth}`]: {
                    visibility: 'hidden',
                    pointerEvents: 'none',
                },
                [`&&.${pickersDayClasses.selected}`]: {
                    backgroundColor: 'transparent',
                },
            }}
        >
            <CalendarDay
                day={day}
                events={events}
                selected={selected}
                isFirstSelected={index === 0}
                isLastSelected={index === selectedDays.length - 1}
                isToday={isToday}
            />
        </CustomPickersDay>
    )
}

const EmployeeCalendarForm = ({ selectedItems }: ICommmentingFormProps): JSX.Element => {
    const formikProps = {
        initialValues: {},
        onSubmit: () => {
            // ploi
        },
    }

    const getDateRange = (day?: Moment) => {
        return {
            startDate: moment(day).subtract(1, 'months').startOf('month'),
            endDate: moment(day).add(1, 'months').endOf('month'),
        }
    }

    const dispatch = useDispatch()
    const selectedDays = useSelector(selectSelectedDays)

    const initializeDataSource = useCallback(
        (day?: Moment) => {
            const dateRange = getDateRange(day)

            dispatch(
                initializeDataSourceThunk('EmployeeCalendar', {
                    fetchData: false,
                    additionalDataSourceDataRequestParameters: {},
                    additionalDataSourceDataRequestFiltersParameters: {
                        AikavaliAlkuaika: { Alku: dateRange.startDate, Loppu: dateRange.endDate },
                        EmployeeIds: [selectedItems?.[0].Id],
                    },
                })
            )
        },
        [dispatch, selectedItems]
    )

    useEffect(() => {
        initializeDataSource()
    }, [initializeDataSource])

    const selectedMonth = useSelector(selectSelectedMonth)

    const fetchEvents = useCallback(() => {
        if (!selectedItems) {
            return
        }

        dispatch(getEventsForEmployee({ employee: selectedItems[0], month: selectedMonth }))
    }, [dispatch, selectedItems, selectedMonth])

    const { shiftPressed, controlPressed } = useModifierKeyListener()

    useEffect(() => {
        fetchEvents()
    }, [dispatch, fetchEvents])

    const handleSelectionChange = (selectedDay: Moment) => {
        if (shiftPressed) {
            const oldSelection = compact([head(selectedDays)])

            dispatch(setSelectDays(fillDaysBetween(oldSelection, selectedDay)))
            return
        }

        if (controlPressed) {
            const alreadySelected = selectedDays.some((day) => day.isSame(selectedDay))

            const newSelection = !alreadySelected
                ? [...selectedDays, selectedDay]
                : selectedDays.filter((day) => !day.isSame(selectedDay))

            dispatch(setSelectDays(newSelection))
            return
        }

        const alreadySelected = selectedDays.length === 1 && head(selectedDays)?.isSame(selectedDay)
        const newSelection = !alreadySelected ? [selectedDay] : []

        dispatch(setSelectDays(newSelection))
    }

    const handleFocusedMonthChange = (day: Moment) => {
        initializeDataSource(day)

        dispatch(setSelectedMonth(day))

        // need to reset selection as the data for the selected days gets unloaded when month changes
        dispatch(setSelectDays([]))

        fetchEvents()
    }

    const selectionEvents = useSelector(selectSelectedDaysEventsGroupedByDays)

    const employee = selectedItems?.[0]
    const employeeName = employee?.EmployeeName || ''

    const handleOnClose = () => {
        dispatch(modalClosed())
        dispatch(closeModalAction(FORM_ID))
    }

    const handleCreateNewClick = () => {
        dispatch(
            handleViewEngineActionThunk({
                items: selectedDays.map((day, index) => ({
                    Id: index,
                    Kayttooikeudet: [{ Toiminto: 'CREATE', OnkoSallittu: true, SyyKieltoon: null }],
                    Tyontekija: { Id: employee?.Id, Name: employeeName },
                    Alkamisaika: day,
                    CreatedTimestamp: '',
                })),
                functionalityAction: CreateEventAction,
                dataSourceId: 'EmployeeCalendar',
                onSubmitCallback: fetchEvents,
                overrideDataSourceId: true,
            })
        )
    }

    return (
        <EmployeeCalendarModalComponent
            formId={FORM_ID}
            formikProps={formikProps}
            modalId={FORM_ID}
            title={modalTitle}
            onClose={handleOnClose}
            muiDialogProps={{ maxWidth: 'md', fullWidth: true }}
            disableDefaultActionButtons
            open
        >
            <ModalContent>
                <CalendarColumn>
                    <EventInfo>
                        <SummaryLabel
                            noPadding
                            fontUsage="bodyM"
                            summaryLabel={{ Icon: 'account_circle', Text: employeeName }}
                        />
                        <IconButton onClick={handleCreateNewClick} size="medium" icon="add" outline />
                    </EventInfo>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <StyledCalendar
                            slots={{ day: Day }}
                            slotProps={{
                                day: {
                                    selectedDays,
                                } as any,
                            }}
                            dayOfWeekFormatter={(day: string) => dayMap[day]}
                            onChange={handleSelectionChange}
                            onMonthChange={handleFocusedMonthChange}
                            onYearChange={handleFocusedMonthChange}
                            displayWeekNumber
                        />
                    </LocalizationProvider>
                    <EventTypeCategories />
                </CalendarColumn>

                <EventsColumn>
                    <Content>
                        {isEmpty(selectedDays) ? (
                            <SelectionInfoBox body={translate('employeeCalendar.selectionInfo')} />
                        ) : (
                            <SelectedDaysEvents events={selectionEvents} employee={employee} />
                        )}
                    </Content>
                </EventsColumn>
            </ModalContent>

            <Footer>
                <TranslatedButton onClick={handleOnClose} type="button" variant="text">
                    button-sulje
                </TranslatedButton>
            </Footer>
        </EmployeeCalendarModalComponent>
    )
}

export default EmployeeCalendarForm
