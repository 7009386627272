import INavigointiItemLegacy from '../Types/INavigointiItemLegacy'
import makeRequest, { ERequestMethod } from '../../rest-api'

const NAVIGOINTI_ITEM_URL = '/NavigointiItem'

// TODO: should refactor to use the new navigation system at some point
export const haeNavigointiItem = async (id: number): Promise<INavigointiItemLegacy> => {
    const navigointiItem = await makeRequest<INavigointiItemLegacy>({
        method: ERequestMethod.GET,
        url: `${NAVIGOINTI_ITEM_URL}/${id}`,
    })

    return navigointiItem
}
