/* eslint-disable no-restricted-syntax */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment, { Moment } from 'moment'
import { IEvent } from '../../event'
import { getEventsForEmployee, getEventTypeCategories } from './EmployeeCalendarThunks'
import { IIdentifier } from '../../event/Types/IEvent'
import { Cancel } from 'axios'
import { difference, isEmpty, uniqBy } from 'lodash-es'

export interface IEmployeeCalendarState {
    events: IEvent[]
    selectedEvents: IEvent[]
    isLoadingEvents: boolean
    isLoadingCategories: boolean
    eventTypeCategories: IIdentifier[]
    selectedDays: Moment[]
    hoveredDay: Moment | undefined
    selectedMonth: Moment | undefined
}

export const initialState = <IEmployeeCalendarState>{
    events: [],
    selectedEvents: [],
    isLoadingEvents: false,
    isLoadingCategories: false,
    eventTypeCategories: [],
    selectedDays: [],
    hoveredDay: undefined,
    selectedMonth: undefined,
}

const employeeCalendarSlice = createSlice({
    name: 'employeeCalendar',
    initialState,
    reducers: {
        setSelectDays: (state, { payload }: PayloadAction<Moment[]>) => {
            state.selectedDays = payload
            state.selectedEvents = []
        },
        setHoveredDay: (state, { payload }: PayloadAction<Moment>) => {
            state.hoveredDay = payload
        },
        modalClosed: (state) => {
            state.selectedDays = []
            state.events = []
            state.selectedEvents = []
        },
        setSelectedMonth: (state, { payload }: PayloadAction<Moment>) => {
            state.selectedMonth = payload
        },
        setSelectedEvents: (state, { payload }: PayloadAction<IEvent[]>) => {
            state.selectedEvents = payload
        },
        toggleSelectedEvent: (state, { payload }: PayloadAction<IEvent>) => {
            const isSelected = state.selectedEvents.some(({ Id }) => Id === payload.Id)

            if (isSelected) {
                state.selectedEvents = state.selectedEvents.filter(({ Id }) => Id !== payload.Id)
                return
            }

            state.selectedEvents = [...state.selectedEvents, payload]
        },
        selectEventsByType: (state, { payload }: PayloadAction<IIdentifier>) => {
            // find all events of the given type, and map it to selectedDays -compatible moment
            const eventsOfType = state.events
                .map(({ EventTypeCategory, StartTime }) => {
                    return {
                        eventTypeId: EventTypeCategory.Id,
                        startTime: moment(StartTime).startOf('day'),
                    }
                })
                .filter(({ eventTypeId, startTime }) => {
                    return eventTypeId === payload.Id && startTime.isSame(state.selectedMonth, 'month')
                })
                .map(({ startTime }) => startTime)

            // check which events are already selected
            const alreadySelectedEvents = eventsOfType.filter((event) =>
                state.selectedDays.some((day) => day.isSame(event))
            )

            const unselectedEvents = difference(eventsOfType, alreadySelectedEvents)

            state.selectedEvents = []

            // if all events of the type are selected, unselect them
            if (isEmpty(unselectedEvents)) {
                state.selectedDays = state.selectedDays.filter(
                    (day) => !alreadySelectedEvents.some((alreadySelected) => day.isSame(alreadySelected))
                )

                return
            }

            // same day may get selected multiple times if there are multiple events with different starting times
            state.selectedDays = uniqBy([...state.selectedDays, ...unselectedEvents], (day) => day.toString())
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEventsForEmployee.pending, (state) => {
            state.isLoadingEvents = true
        }),
            builder.addCase(getEventsForEmployee.fulfilled, (state, { payload }) => {
                state.events = payload
                state.isLoadingEvents = false
            }),
            builder.addCase(getEventsForEmployee.rejected, (state, { payload }) => {
                // stay in loading mode if user navigated to another month
                if ((payload as Cancel)?.message !== 'canceled by user') {
                    state.isLoadingEvents = false
                }
            }),
            builder.addCase(getEventTypeCategories.pending, (state) => {
                state.isLoadingCategories = true
            }),
            builder.addCase(getEventTypeCategories.fulfilled, (state, { payload }) => {
                state.isLoadingCategories = false
                state.eventTypeCategories = payload
            }),
            builder.addCase(getEventTypeCategories.rejected, (state) => {
                state.isLoadingCategories = false
            })
    },
})

export const {
    setSelectDays,
    setHoveredDay,
    modalClosed,
    setSelectedMonth,
    setSelectedEvents,
    toggleSelectedEvent,
    selectEventsByType,
} = employeeCalendarSlice.actions

export default employeeCalendarSlice.reducer
