import { Location } from 'history'
import { useDispatch } from 'react-redux'
import { useEffect, useMemo } from 'react'
import { compact, isEmpty } from 'lodash-es'
import { setValuePickerInitialValueAction } from '../../value-picker/State/ValuePickerActions'
import { removeQueryParamsFromUrl } from '../../browser-utilities'

type ParamProps = {
    param: string
    valuePickerId: string
    mapValue?: (value: string | null) => unknown
}

const paramsMap: ParamProps[] = [
    {
        param: 'workUnit',
        valuePickerId: 'workUnit_filter',
        mapValue: (value: string) => new Set([Number(value)]),
    },
]

export const useValuesFromParams = (location: Location<unknown>): void => {
    const dispatch = useDispatch()

    const routeParams = useMemo(() => new URLSearchParams(location.search), [location.search])

    const paramsInURL = compact(
        [...routeParams.entries()].map(([key]) => {
            return paramsMap.find(({ param }) => param === key)
        })
    )

    useEffect(() => {
        if (isEmpty(paramsInURL)) {
            return
        }

        paramsInURL.forEach(({ param, mapValue, valuePickerId }: ParamProps) => {
            const value = routeParams.get(param)

            const mappedValue = mapValue ? mapValue(value) : value

            dispatch(setValuePickerInitialValueAction(mappedValue, valuePickerId))
        })

        removeQueryParamsFromUrl()
    }, [dispatch, paramsInURL, routeParams])
}
