import { createReducer } from 'typesafe-actions'

import { initializeApplicationAction, setCookiesAcceptedAction, userPilotInitializedAction } from 'action-creators'
import { getCookieSettings } from '../../generic-utilities/StorageUtils'

export const INITIAL_STATE = new Map<string, string | number | boolean | null>()

const asetusReducer = createReducer(INITIAL_STATE)
    .handleAction([initializeApplicationAction], (state, action) => {
        const cookieSettings = getCookieSettings()

        return new Map([
            ...Array.from(state),
            ...Array.from(action.payload.asetukset),
            ['logoutUrl', action.payload.logoutUrl],
            ['cookiesAccepted', cookieSettings?.categories.functional ?? null],
        ])
    })
    .handleAction(userPilotInitializedAction, (state) => {
        const newState = new Map(Array.from(state))
        newState.set('userPilotInitialized', true)

        return newState
    })
    .handleAction(setCookiesAcceptedAction, (state, action) => {
        const cookiesAccepted = action.payload
        const newState = new Map(Array.from(state))
        newState.set('cookiesAccepted', cookiesAccepted)

        return newState
    })

export default asetusReducer
