import PropTypes from 'prop-types'
import * as React from 'react'
import reactCss from 'reactcss'

interface IProps {
    height?: string
}

/**
 * Tyhjä elementti.
 * Voidaan käyttää esimerkiksi ruudukkoasettlun tyhjien solujen täyttämiseen.
 *
 * Esimerkki:
 * <Empty />
 */
export default class Empty extends React.Component<IProps> {
    static displayName = 'Empty'
    static propTypes: any

    private styles(): any {
        const height = this.props.height

        return reactCss({
            default: {
                atom: {
                    display: height ? 'block' : 'none',
                    height: height ? height : '',
                    lineHeight: height ? height : undefined,
                },
            },
        })
    }

    render(): React.ReactNode {
        return <span style={this.styles().atom}>&nbsp;</span>
    }
}

Empty.propTypes = {
    height: PropTypes.string,
}
