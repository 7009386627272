import styled from '@emotion/styled'
import { IEvent } from '../../event'
import { Moment } from 'moment'
import { Icon, ReadOnlyText } from '../../generic-components'
import { loadingPlaceholder } from 'constants/Styles'
import { dayColorLogic, eventTypeIndicatorProps, getEventColor, parseDayState } from './DayLogic'
import { useSelector } from 'react-redux'
import { selectIsLoadingEvents } from '../State/EmployeeCalendarSelectors'
import { useAppTheme } from '../../theme/'
import { isEmpty, sortBy } from 'lodash-es'

type Props = {
    events: IEvent[]
    day: Moment
    selected: boolean
    isFirstSelected: boolean
    isLastSelected: boolean
    isToday: boolean
}

const CalendarDayContainer = styled.div<{
    selected: boolean
    isFirstSelected: boolean
    isLastSelected: boolean
    isToday: boolean
    dayColor: string
    hasOtherNotWorktime: boolean
    isLoading: boolean
}>`
    width: 46px;
    height: 46px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};

    outline: ${({ selected, theme }) =>
        selected && `2px solid ${theme.componentExtensions.buttons.borderButtonSecondaryDefault}`};

    background-color: ${({ dayColor }) => dayColor};

    ${({ isLoading }) => isLoading && loadingPlaceholder({ width: 50, height: 50 })}
`

const DayNumber = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    z-index: 1000;
`

const IndicatorsContainer = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    height: 100%;
    width: 100%;
`

const IconsContainer = styled.div`
    position: absolute;
    top: -6px;
    right: -6px;
    height: 19px;
    padding-left: 1px;
    background-color: ${({ theme }) => theme.componentExtensions.global.bgPrimary};
    border-radius: ${({ theme }) => theme.tokens.radiusSmall};
`

const BorderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 1px;
    left: 1px;
    width: calc(100% - 2px);
    height: 10px;
    background-color: ${({ theme }) => theme.componentExtensions.global.bgPrimary};
    border-bottom-left-radius: ${({ theme }) => theme.tokens.radiusSmall};
    border-bottom-right-radius: ${({ theme }) => theme.tokens.radiusSmall};
`

const CalendarDay = ({ events, day, selected, isFirstSelected, isLastSelected, isToday }: Props): JSX.Element => {
    const theme = useAppTheme()

    const isLoadingEvents = useSelector(selectIsLoadingEvents)

    const dayState = parseDayState(events)
    const dayColorEvent = dayColorLogic(dayState)
    const dayColor = getEventColor(dayColorEvent, theme)

    const indicators = events.map(({ EventTypeCategory }) =>
        eventTypeIndicatorProps(EventTypeCategory.Id as number, theme)
    )

    const icons = sortBy(
        indicators.filter(({ icon }) => !icon?.includes('Border')),
        ({ icon }) => icon === 'cancel'
    )

    const borders = indicators.filter(({ icon }) => icon?.includes('Border'))

    const textColor =
        theme.componentExtensions.text[!dayState.hasWorkShiftEvent || isLoadingEvents ? 'primary' : 'primaryAlt']

    const iconsBlock = !isEmpty(icons) && (
        <IconsContainer>
            {icons.map(({ icon, color }) => {
                if (!icon) {
                    return null
                }

                return (
                    <Icon key={icon} color={color}>
                        {icon}
                    </Icon>
                )
            })}
        </IconsContainer>
    )

    const bordersBlock = !isEmpty(borders) && (
        <BorderContainer>
            <Icon color={theme.colors.primaryTeal120}>
                {borders.some(({ icon }) => icon === 'BorderSolid') ? 'BorderSolidLarge' : 'BorderDashedLarge'}
            </Icon>
        </BorderContainer>
    )

    return (
        <CalendarDayContainer
            selected={selected}
            isFirstSelected={isFirstSelected}
            isLastSelected={isLastSelected}
            isToday={isToday}
            dayColor={dayColor}
            hasOtherNotWorktime={dayState.hasOtherNotWorktime}
            isLoading={isLoadingEvents}
        >
            <DayNumber>
                <ReadOnlyText
                    usage="bodyM"
                    textDecoration={isToday ? 'underline' : 'none'}
                    fontWeight={isToday ? 700 : 400}
                    rawColor={textColor}
                >
                    {day.date().toString()}
                </ReadOnlyText>
            </DayNumber>

            {!isLoadingEvents && (
                <IndicatorsContainer>
                    {iconsBlock}
                    {bordersBlock}
                </IndicatorsContainer>
            )}
        </CalendarDayContainer>
    )
}

export default CalendarDay
