import { createReducer } from 'typesafe-actions'

import IFormViewReducerState from '../Types/IFormViewReducerState'
import {
    finishLoadingData,
    formViewInitializationFailed,
    resetFormViewAction,
    resetLoadingData,
    setFormViewAction,
    setFormViewFieldStatesAction,
} from './FormViewActions'
import IFormViewConfigurationV2 from '../Types/V2/IFormViewConfigurationV2'

export const INITIAL_STATE: IFormViewReducerState = new Map<string, IFormViewConfigurationV2>()

const getEmptyFormView = (id: string): IFormViewConfigurationV2 => {
    return {
        Id: id,
        Title: '',
        Sections: [],
        DataSourceId: '',
        SubmitActionId: '',
        HasSaveAndContinue: false,
        SubmitOnlyChangedValues: false,
        IsLoadingData: true,
        Tabs: [],
    }
}
const formViewReducer = createReducer(INITIAL_STATE)
    .handleAction(setFormViewAction, (state, action) => {
        const formView = action.payload

        const newState = new Map(state)

        newState.set(formView.Id, formView)

        return newState
    })
    .handleAction(resetFormViewAction, (state, action) => {
        const formViewId = action.payload

        const newState = new Map(state)

        newState.delete(formViewId)

        return newState
    })
    .handleAction(formViewInitializationFailed, (state, action) => {
        const formViewId = action.payload

        const formView = state.get(formViewId)

        return new Map(state).set(formViewId, formView ? { ...formView } : { ...getEmptyFormView(formViewId) })
    })
    .handleAction(finishLoadingData, (state, action) => {
        const formViewId = action.payload

        const formView = state.get(formViewId)

        return new Map(state).set(
            formViewId,
            formView ? { ...formView, IsLoadingData: false } : { ...getEmptyFormView(formViewId) }
        )
    })
    .handleAction(resetLoadingData, (state, action) => {
        const formViewId = action.payload

        const formView = state.get(formViewId)

        return new Map(state).set(
            formViewId,
            formView ? { ...formView, IsLoadingData: true } : { ...getEmptyFormView(formViewId) }
        )
    })
    .handleAction(setFormViewFieldStatesAction, (state, action) => {
        const formViewId = action.meta

        const formView = state.get(formViewId)

        if (!formView) {
            return state
        }

        return state
    })

export default formViewReducer
