import PropTypes from 'prop-types'
import { Component } from 'react'
import reactCss from 'reactcss'
import { countBy, filter, map, size, values } from 'lodash-es'
import styled from '@emotion/styled'

import Empty from 'components/atoms/Empty'
import Icon from 'components/atoms/Icon'
import { Tooltip } from '../../../generic-components'
import { Translation } from '../../../localization'

// the default tooltip child has width: inherit. In client portal in some places this causes
// the validation component to take 100% of the width and the validatable component to have only
// a very minimum amount of width. Easier to avoid the issue by just overwriting the width here.
const TooltipWrapperElement = styled.div`
    width: 16px;
`

/**
 * Näyttää validointivirheen punaisen huutomerkki-ikonin tooltipissä.
 * Suunniteltu toimimaan yhdessä Validator-luokkien kanssa. Komponenttiin voi siis asettaa
 * suoraan jonkin _errors arvon. Komponentti piilottaa itsensä, jos virhettä ei ole.
 *
 * Buttoneilla ikonin asettelu poikkeaa muista komponenteista. ButtonStyles-propsi
 * aktivoi buttonien vaihtoehtoiset tyylit.
 *
 * Käytä ValidationErrorAfter-komponenttia, jos haluat asetella validointivirheen jonkin
 * komponentin viereen.
 *
 * Esimerkki:
 * <ValidationError>{ tiketti._errors.Otsikko }></ValidationError>
 */
export default class ValidationError extends Component {
    constructor(props) {
        super(props)
    }

    styles() {
        return reactCss(
            {
                default: {
                    errorIcon: {
                        cursor: 'default',
                        display: 'inline',
                        marginLeft: '0.571rem',
                    },
                    list: {
                        textAlign: 'left',
                    },
                },
                buttonStyles: {
                    errorIcon: {
                        marginLeft: '0.143rem',
                    },
                },
                noErrorDisplayed: {
                    errorIcon: {
                        display: 'none',
                    },
                },
            },
            this.props,
            {
                noErrorDisplayed: !this.props.children,
            }
        )
    }

    _isChildString() {
        return this.props.children instanceof String || typeof this.props.children === 'string'
    }

    _translateErrorText(errorAmount, rawMessage) {
        const errorMessageTranslated = rawMessage ? Translation.translateKey(rawMessage) : rawMessage

        return errorAmount > 1 ? errorMessageTranslated + ' x ' + errorAmount : errorMessageTranslated
    }

    _generateErrorWithMoreThanOneRow(countObject) {
        const listItems = map(countObject, (errorAmount, rawMessage) => {
            const errorMessageTranslated = this._translateErrorText(errorAmount, rawMessage)

            return <li key={`${errorAmount}_${rawMessage}`}>{errorMessageTranslated} </li>
        })
        return <ul style={this.styles().list}>{listItems}</ul>
    }

    _generateObjectErrorList() {
        if (this.props.children === true) {
            return Translation.translateKey('validation-generic-error')
        }

        const valueList = values(this.props.children)
        const filteredValueList = filter(valueList, (value) => value !== undefined)
        const countObject = countBy(filteredValueList, (value) => value) // {'error': errorCount, 'otherError': otherErrorCount}

        const multipleDifferentErrors = size(countObject) > 1

        if (multipleDifferentErrors) {
            return this._generateErrorWithMoreThanOneRow(countObject)
        }

        return map(countObject, (errorAmount, rawMessage) => {
            const errorMessageTranslated = this._translateErrorText(errorAmount, rawMessage)

            return errorMessageTranslated
        })
    }

    _isObjectWithAllValuesUndefined(children = {}) {
        if (typeof children !== 'object') {
            return false
        }

        return filter(values(children), (value) => value !== undefined).length < 1
    }

    _renderWithTooltip(errorText) {
        return (
            <Tooltip childWrapperElement={TooltipWrapperElement} title={errorText}>
                <span style={this.styles().errorIcon}>
                    <Icon align={this.props.buttonStyles ? 'middle' : 'top'} color={this.props.color} size={'1.1rem'}>
                        {this.props.errorIcon}
                    </Icon>
                </span>
            </Tooltip>
        )
    }

    render() {
        if (!this.props.children) {
            return <Empty />
        }

        if (this._isChildString()) {
            const errorAmount = undefined
            const errorText = this._translateErrorText(errorAmount, this.props.children)
            return this._renderWithTooltip(errorText)
        }

        const childIsEmptyObject = this._isObjectWithAllValuesUndefined(this.props.children)
        if (childIsEmptyObject) {
            return <Empty />
        }

        const errorText = this._generateObjectErrorList()
        return this._renderWithTooltip(errorText)
    }
}

ValidationError.displayName = 'ValidationError'

ValidationError.defaultProps = {
    color: 'red',
}

ValidationError.propTypes = {
    buttonStyles: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.bool]),
    color: PropTypes.string,
    errorIcon: PropTypes.string,
}
