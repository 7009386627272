import InputLabel from '@material-ui/core/InputLabel'
import * as React from 'react'
import styled from '@emotion/styled'

import { IOsionAsetus } from '../../reducers/OsiokohtaisetAsetuksetReducer'
import { Icon } from '../../../generic-components'
import InputWrapperFormik from './OsiokohtaisetAsetuksetInputWrapperFormik'
import { Tooltip } from '../../../generic-components'

interface ISarakeProps {
    elementId: string
    asetus: IOsionAsetus
}

const ColumnContainer = styled.div`
    width: 50%;
`

const InfoIconWrapper = styled.span`
    margin-left: 0.25rem;
`

export const LabelColumn: React.FunctionComponent<ISarakeProps> = ({ elementId, asetus }) => (
    <ColumnContainer>
        <Tooltip title={asetus.KuvausAsiakkaalle || ''}>
            <InputLabel htmlFor={elementId}>
                {asetus.NimiAsiakkaalle}
                <InfoIconWrapper>
                    <Icon>info</Icon>
                </InfoIconWrapper>
            </InputLabel>
        </Tooltip>
    </ColumnContainer>
)

export const InputColumn: React.FunctionComponent<ISarakeProps> = ({ elementId, asetus }) => (
    <ColumnContainer>
        <InputWrapperFormik asetus={asetus} id={elementId} />
    </ColumnContainer>
)

export interface IOsiokohtaisetAsetuksetAsetusRiviProps {
    asetus: IOsionAsetus
}

const RowContainer = styled.div`
    display: flex;
    margin: 1rem 0;
`

export default class OsiokohtaisetAsetuksetAsetusRivi extends React.Component<IOsiokohtaisetAsetuksetAsetusRiviProps> {
    render(): React.ReactNode {
        const { asetus } = this.props

        const elementId = `osionAsetus_${asetus.Nimi}`

        return (
            <RowContainer key={asetus.Nimi}>
                <LabelColumn asetus={asetus} elementId={elementId} />
                <InputColumn asetus={asetus} elementId={elementId} />
            </RowContainer>
        )
    }
}
