import { FC, useState, useRef, useEffect } from 'react'
import * as React from 'react'

import Tooltip from './Tooltip'

type TTooltipProps = Omit<React.ComponentProps<typeof Tooltip>, 'children'>

interface ITooltipWithEllipsisProps extends TTooltipProps {
    children: (refForTextElement: React.MutableRefObject<HTMLDivElement | null>) => React.ReactElement
    enforceTooltip?: boolean
}

/**
 * Like Tooltip component, but displays the tooltip only if the wrapped
 * element has ellipsis present.
 *
 * Pass the ref object parameter to the text element that Tooltip wraps,
 * for example:
 *
 * ```
 * <TooltipWithEllipsis>
 *     (ref) => <Typography ref={ref}>some text</Typography>
 * </TooltipWithEllipsis>
 * ```
 */
const TooltipWithEllipsis: FC<ITooltipWithEllipsisProps> = ({
    children,
    title,
    childWrapperElement,
    wrapperElementClassName,
    enforceTooltip,
    ...rest
}) => {
    const textElementRef = useRef<HTMLDivElement | null>(null)
    const [titleToUse, setTitleToUse] = useState(title)

    useEffect(() => {
        setTitleToUse(title)
    }, [title])

    const onOpen = () => {
        const textElement = textElementRef.current

        if (!textElement) {
            return
        }

        const isEllipsisPresent = enforceTooltip || textElement.offsetWidth < textElement.scrollWidth

        if (!isEllipsisPresent && titleToUse !== '') {
            setTitleToUse('')
        } else if (isEllipsisPresent && titleToUse === '' && title !== '') {
            setTitleToUse(title)
        }
    }

    return (
        <Tooltip
            childWrapperElement={childWrapperElement}
            onOpen={onOpen}
            title={titleToUse}
            wrapperElementClassName={wrapperElementClassName}
            {...rest}
        >
            {children(textElementRef)}
        </Tooltip>
    )
}

export default TooltipWithEllipsis
