import * as React from 'react'
import { Field, FieldProps } from 'formik'
import { RadioProps } from '@material-ui/core/Radio/Radio'
import styled from '@emotion/styled'

import FormikRadioButtonGroup from 'components/atoms/RadioButtonGroup/FormikRadioButtonGroup'
import IDeletionReason from '../Types/IDeletionReason'
import { FormikTextInput } from '../../generic-components'
import { TranslatedTypography } from '../../generic-components'

interface IDeletionFormProps {
    isLoading: boolean
    deletionReasons: IDeletionReason[]
    reasonsQueryFailed: boolean
}

const Container = styled.div`
    margin-top: 30px;
`

const radioProps: RadioProps = { color: 'primary' }

const SelectionHeaderContainer = styled.div`
    margin-bottom: 10px;
`

const SelectForm = styled.div`
    margin-bottom: 10px;
`

const DeletionReasons: React.FunctionComponent<IDeletionFormProps> = ({
    reasonsQueryFailed,
    isLoading,
    deletionReasons,
}) => {
    if (reasonsQueryFailed) {
        return (
            <Container>
                <TranslatedTypography variant="subtitle2">
                    modal-deletion-confirmation.DeletionReasons.ReasonsQueryFailed
                </TranslatedTypography>
            </Container>
        )
    }

    if (!deletionReasons || deletionReasons.length === 0) {
        return null
    }

    return (
        <Container>
            <SelectForm>
                <SelectionHeaderContainer>
                    <TranslatedTypography variant="bodyXS">
                        Palkkahallinto.DeletionModal.ReasonLabel
                    </TranslatedTypography>
                </SelectionHeaderContainer>
                <Field
                    component={FormikRadioButtonGroup}
                    disabled={isLoading}
                    name="reason"
                    radioButtonsProps={deletionReasons.map(({ id, name }) => ({
                        disabled: isLoading,
                        key: id,
                        label: name,
                        radioProps: { ...radioProps },
                        value: id,
                    }))}
                />
            </SelectForm>

            <Field name="additionalInfo">
                {(passProps: FieldProps) => (
                    <FormikTextInput
                        {...passProps}
                        disabled={isLoading}
                        fullWidth
                        placeholder="modal-deletion-confirmation.TextField.Label"
                    />
                )}
            </Field>
        </Container>
    )
}

export default DeletionReasons
