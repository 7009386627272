import { useState, useEffect } from 'react'
import * as React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import DayRangePickerWithWeekDaysV2 from '../DayRangePickerWithWeekDays/DayRangePickerWithWeekDaysV2'
import DayPickerV2 from '../DayPicker/DayPickerV2'
import { Translation } from '../../../localization'
import { TDateStringDataFormat } from '../../../dates'
import Checkbox from '../Checkbox'
import styled from '@emotion/styled'
import { getTestId } from '../../../test'

const ModeSwitchContainer = styled(FormControl)`
    padding-bottom: 20px;
    padding-left: 10px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

type TDayRangePickerWithWeekDaysProps = React.ComponentProps<typeof DayRangePickerWithWeekDaysV2>

type TPickerMode = 'range' | 'days'
interface IDayPickerWithRangeAndWeekDaysProps
    extends Pick<
        TDayRangePickerWithWeekDaysProps,
        | 'defaultSelectedWeekDays'
        | 'onBlur'
        | 'shouldDateBeDisabled'
        | 'isStartDayErrored'
        | 'isEndDayErrored'
        | 'validationErrorStartDay'
        | 'validationErrorEndDay'
        | 'isFieldErrored'
        | 'validationError'
    > {
    labelRangeStart?: string
    labelRangeEnd?: string
    labelDayPickerTextInput?: string
    labelToggle?: string
    value: TDateStringDataFormat[]
    onChange: (selectedDays: TDateStringDataFormat[]) => void
    required?: boolean
    defaultPickerMode?: TPickerMode
    onModeChange?: (newMode: TPickerMode) => void
    errors?: string
    valuePickerId?: string
}

/**
 * Component to combine the DayPicker with DayRangePickerWithWeekDays.
 */
const DayPickerWithRangeAndWeekDays: React.FC<IDayPickerWithRangeAndWeekDaysProps> = ({
    labelRangeStart,
    labelRangeEnd,
    labelToggle = 'generic-components.DayPickerWithRangeAndWeekDays.ToggleLabel.DefaultValue',
    value,
    onChange,
    defaultSelectedWeekDays,
    required = false,
    onBlur,
    shouldDateBeDisabled,
    defaultPickerMode = 'days',
    onModeChange,
    isEndDayErrored,
    isStartDayErrored,
    validationErrorEndDay,
    validationErrorStartDay,
    isFieldErrored,
    validationError,
    errors,
    valuePickerId,
}) => {
    const [pickerMode, setPickerMode] = useState<TPickerMode>(defaultPickerMode)

    // The default value for the mode shouldn't change in a normal situation, but it could change
    // for example due to an http request resolving after this component has mounted, so make sure
    // to update the mode in that situation.
    useEffect(() => {
        setPickerMode(defaultPickerMode)
    }, [defaultPickerMode])

    const handleModeChange = () => {
        if (value.length !== 0) {
            onChange([])
        }

        const newPickerMode = pickerMode === 'range' ? 'days' : 'range'
        setPickerMode(newPickerMode)

        onModeChange && onModeChange(newPickerMode)
    }

    const toggleLabel = Translation.has(labelToggle) ? Translation.translateKey(labelToggle) : labelToggle

    const testId = getTestId(['VALUE_PICKER'], valuePickerId)

    return (
        <Container data-testid={testId}>
            <ModeSwitchContainer>
                <FormControlLabel
                    control={<Checkbox onClick={handleModeChange} value={pickerMode === 'range'} />}
                    label={toggleLabel}
                />
            </ModeSwitchContainer>
            <div>
                {pickerMode === 'range' ? (
                    <DayRangePickerWithWeekDaysV2
                        defaultSelectedWeekDays={defaultSelectedWeekDays}
                        endLabel={labelRangeEnd}
                        isEndDayErrored={isEndDayErrored}
                        isFieldErrored={isFieldErrored}
                        isStartDayErrored={isStartDayErrored}
                        onBlur={onBlur}
                        onChange={onChange}
                        required={required}
                        shouldDateBeDisabled={shouldDateBeDisabled}
                        startLabel={labelRangeStart}
                        validationErrorEndDay={validationErrorEndDay || validationError}
                        validationErrorStartDay={validationErrorStartDay || validationError}
                        value={value}
                        errors={errors}
                    />
                ) : (
                    <DayPickerV2
                        isFieldErrored={isFieldErrored}
                        label={'Päivämäärä'}
                        onBlur={onBlur}
                        onChange={onChange}
                        required={required}
                        shouldDateBeDisabled={shouldDateBeDisabled}
                        validationError={validationError}
                        errors={errors}
                        value={value}
                    />
                )}
            </div>
        </Container>
    )
}

export default DayPickerWithRangeAndWeekDays
