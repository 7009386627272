import moment from 'moment'
import { round } from 'lodash-es'

import {
    DEFAULT_DATE_DISPLAY_FORMAT,
    DEFAULT_DATE_TIME_DISPLAY_FORMAT,
    GENERAL_DATA_FORMAT,
} from '../Constants/DateConstants'
import TDateStringDataFormat from '../Types/TDateStringDataFormat'

import TDateObject from '../Types/TDateObject'
import { MomentInput } from 'moment/moment'
import { Translation } from '../../localization'

type TGranularity = 'day' | 'year' | 'hour' | 'month' | 'week'

const SERVER_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
const STRICK_MODE = true

export const isDateObject = (possibleDateObject: unknown): possibleDateObject is TDateObject => {
    return moment.isMoment(possibleDateObject)
}

export const isValidDateInput = (value: string | undefined): boolean => {
    if (value === undefined) {
        return false
    }
    return moment(value, DEFAULT_DATE_DISPLAY_FORMAT, STRICK_MODE).isValid()
}

export const convertDateInputForDataUsage = (value: string | undefined): TDateStringDataFormat => {
    return moment(value, DEFAULT_DATE_DISPLAY_FORMAT, false).format(GENERAL_DATA_FORMAT)
}

export const shallowCopyDateObject = (dateObject: TDateObject): TDateObject => {
    return moment(dateObject)
}

const isStrictFormatUsed = true
export const toDateObject = (dateString: TDateStringDataFormat): TDateObject => {
    return moment(dateString, GENERAL_DATA_FORMAT, isStrictFormatUsed)
}

export const ensureIsDateObject = (dateStringOrObject: TDateStringDataFormat | TDateObject): TDateObject => {
    if (isDateObject(dateStringOrObject)) {
        return shallowCopyDateObject(dateStringOrObject)
    }

    return toDateObject(dateStringOrObject)
}

export const startOf = (
    dateObject: TDateStringDataFormat | TDateObject,
    granularity: TGranularity = 'day'
): TDateStringDataFormat => {
    return formatForDataUsage(ensureIsDateObject(dateObject).startOf(granularity))
}

export const format = <T extends string>(
    dateStringOrObject: TDateStringDataFormat | TDateObject,
    formatToUse: T,
    resetTime = true
): T => {
    if (!resetTime && typeof dateStringOrObject === 'string') {
        return dateStringOrObject as T
    }

    const dateObject = ensureIsDateObject(dateStringOrObject)
    return (resetTime ? ensureIsDateObject(dateObject).startOf('day') : dateObject).format(formatToUse) as T
}

export const formatForDisplay = (
    dateStringOrObject: TDateStringDataFormat | TDateObject,
    formatToUse: string = DEFAULT_DATE_DISPLAY_FORMAT,
    resetTime = true
): string => {
    return format(dateStringOrObject, formatToUse, resetTime)
}

export const formatDateTimeStringForDisplay = (dateTimeString: TDateStringDataFormat): string => {
    const dateObject = ensureIsDateObject(dateTimeString)
    return dateObject.format(DEFAULT_DATE_TIME_DISPLAY_FORMAT)
}

export const formatForDataUsage = (
    dateStringOrObject: TDateObject | TDateStringDataFormat,
    formatToUse = GENERAL_DATA_FORMAT,
    resetTime = true
): TDateStringDataFormat => {
    return format<TDateStringDataFormat>(dateStringOrObject, formatToUse, resetTime)
}

export const resetTimeToDayStart = (dateObject: TDateObject): TDateObject => {
    return shallowCopyDateObject(dateObject).startOf('day')
}

export const areSame = (
    dateStringOrObject1: TDateStringDataFormat | TDateObject,
    dateStringOrObject2: TDateStringDataFormat | TDateObject,
    granularity: TGranularity = 'day'
): boolean => {
    return ensureIsDateObject(dateStringOrObject1).isSame(dateStringOrObject2, granularity)
}

export const isBefore = (
    dateStringOrObjectMaybeBefore: TDateStringDataFormat | TDateObject,
    dateStringOrObjectMaybeAfter: TDateStringDataFormat | TDateObject,
    granularity: TGranularity = 'day'
): boolean => {
    return ensureIsDateObject(dateStringOrObjectMaybeBefore).isBefore(dateStringOrObjectMaybeAfter, granularity)
}

export const isAfter = (
    dateStringOrObjectMaybeAfter: TDateStringDataFormat | TDateObject,
    dateStringOrObjectMaybeBefore: TDateStringDataFormat | TDateObject,
    granularity: TGranularity = 'day'
): boolean => {
    return ensureIsDateObject(dateStringOrObjectMaybeAfter).isAfter(dateStringOrObjectMaybeBefore, granularity)
}

export const isWithinRange = (
    dateStringOrObject: TDateStringDataFormat | TDateObject,
    startOfRange: TDateStringDataFormat | TDateObject,
    endOfRange: TDateStringDataFormat | TDateObject,
    { isInclusive = false, granularity = 'day' as TGranularity } = {}
): boolean => {
    const dateObject = ensureIsDateObject(dateStringOrObject)
    const startDateObject = ensureIsDateObject(startOfRange)
    const isBeforeStart = isBefore(dateObject, startDateObject, granularity)

    if (isBeforeStart) {
        return false
    }

    const endDateObject = ensureIsDateObject(endOfRange)
    const isAfterEnd = dateObject.isAfter(endOfRange, granularity)

    if (isAfterEnd) {
        return false
    }

    const isSameAsStart = areSame(dateObject, startDateObject, granularity)
    if (isSameAsStart && isInclusive) {
        return true
    } else if (isSameAsStart && !isInclusive) {
        return false
    }

    const isSameAsEnd = areSame(dateObject, endDateObject, granularity)
    if (isSameAsEnd && isInclusive) {
        return true
    } else if (isSameAsEnd && !isInclusive) {
        return false
    }

    return true
}

export const getDatesWithinRange = (
    startOfRange: TDateStringDataFormat | TDateObject,
    endOfRange: TDateStringDataFormat | TDateObject,
    { isInclusive = true } = {}
): TDateStringDataFormat[] => {
    const startDateObject = ensureIsDateObject(startOfRange)
    const endDateObject = ensureIsDateObject(endOfRange)

    const diff = endDateObject.diff(startDateObject, 'day')

    if (diff === 0) {
        return isInclusive ? [formatForDataUsage(startOfRange)] : []
    }

    const numbersRange = [...Array(diff + 1).keys()]

    if (!isInclusive) {
        numbersRange.shift()
        numbersRange.pop()
    }

    return numbersRange.map((dayIndex) => {
        return formatForDataUsage(shallowCopyDateObject(startDateObject).add(dayIndex, 'day'))
    })
}

export const getCurrentDay = (): TDateStringDataFormat => {
    return formatForDataUsage(moment().startOf('day'))
}

export const getStartOfCurrentWeek = (): TDateStringDataFormat => {
    return startOf(getCurrentDay(), 'week')
}

export const addToDateTimeByUnit = (
    dateStringOrObject: TDateStringDataFormat | TDateObject,
    amountToAdd: number,
    unit: TGranularity
): TDateStringDataFormat => {
    const dateObject = ensureIsDateObject(dateStringOrObject)

    dateObject.add(amountToAdd, unit)
    return formatForDataUsage(dateObject)
}

export const getWeeksCountBetweenDates = (
    start: TDateStringDataFormat | TDateObject,
    end: TDateStringDataFormat | TDateObject,
    decimals?: number
): number => {
    const startDate = ensureIsDateObject(start)
    const endDate = ensureIsDateObject(end)

    const daysCount = endDate.diff(startDate, 'days')
    return round(daysCount / 7, decimals ?? 2)
}

/**
 * Palauttaa päivämäärän muodossa '<päivä>.<kuukausi>.<vuosi>'. Esimerkiksi '1.5.2015'.
 *
 * @param date Aika/päivämäärä.
 */
export const asFullDate = (date: MomentInput | null | undefined): string => {
    if (date === null || date === undefined) {
        return Translation.translateKey('information-not-given')
    }

    return moment(date, SERVER_DATE_FORMAT, STRICK_MODE).format('D.M.YYYY')
}

/**
 * Palauttaa päivämäärän muodossa '<päivämäärän lyhennelmä> <päivä>.<kuukausi>.<vuosi>.
 * Esim. 'pe 5.6.2018'
 */
export const asFullDateAndDayAbbreviation = (date: MomentInput | null | undefined): string => {
    if (date === null || date === undefined) {
        return Translation.translateKey('information-not-given')
    }

    return moment(date, SERVER_DATE_FORMAT, STRICK_MODE).format('dd D.M.YYYY')
}

/**
 * Palauttaa päivämäärän ja ajan muodossa '<päivämäärän lyhennelmä> <päivä>.<kuukausi>.<vuosi> <tunti>:<minuutti>.
 * Esim. 'pe 5.6.2018 13:50'
 */
export const asFullDateTimeWithDayAbbreviation = (date: MomentInput | null | undefined): string => {
    if (date === null || date === undefined) {
        return Translation.translateKey('information-not-given')
    }

    return moment(date, SERVER_DATE_FORMAT, STRICK_MODE).format('dd D.M.YYYY HH:mm')
}
