import * as React from 'react'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import styled from '@emotion/styled'

import URL from 'common/URL'
import INavigationSectionWithSubsections from '../../../../Types/INavigationSectionWithSubsections'

interface ISectionLabelProps {
    children: string
    currentPageSection: INavigationSectionWithSubsections | null
    section: INavigationSectionWithSubsections
}

const StyledLink = styled<React.FC<TypographyProps & { component: 'a'; href: string }>>(Typography)`
    text-decoration: none;
`

const SectionLabel: React.FC<ISectionLabelProps> = ({ children, section, currentPageSection }) => {
    const sectionIsCurrentlySelected = section.Id === currentPageSection?.Id
    const colorTheme = sectionIsCurrentlySelected ? 'primary' : undefined
    const variant = sectionIsCurrentlySelected ? 'subtitle1' : 'subtitle1'

    return (
        <StyledLink
            color={colorTheme}
            component="a"
            href={URL.oldClient(section.subsectionObjects[0].Url)}
            onClick={(e) => e.stopPropagation()}
            variant={variant}
        >
            {children}
        </StyledLink>
    )
}

export default SectionLabel
