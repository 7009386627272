import makeRequest, { ERequestMethod } from '../../rest-api'
import { TDataSourceItemId } from '../../data-source-types'
import { ICreatedItemIdsResponse } from '../Types/IAsyncData'
import IMessage from '../Types/IMessage'
import IMessageThread, { EMessageType, IMessageThreadStatusUpdatePayload } from '../Types/IMessageThread'
import INewComment from '../Types/INewComment'
import INewMessage, { IReplyMessage } from '../Types/INewMessage'

export const fetchMessageThreadsForEmployees = async (
    EmployeeIds: TDataSourceItemId[],
    ThreadIds: TDataSourceItemId[] | null
): Promise<{ ListData: IMessageThread[] }> =>
    makeRequest<{ ListData: IMessageThread[] }>({
        method: ERequestMethod.POST,
        url: '/Messaging/Thread/Search',
        data: {
            Filters: {
                EmployeeIds,
                ThreadIds,
                Types: [EMessageType.InfoMessage, EMessageType.MobileMessage],
            },
        },
    })

export const fetchMessagesForThread = async (ThreadIds: number[]): Promise<{ ListData: IMessage[] }> =>
    makeRequest<{ ListData: IMessage[] }>({
        method: ERequestMethod.POST,
        url: '/Messaging/Message/Search',
        data: {
            Filters: {
                ThreadIds,
            },
        },
    })

export const postNewMessageThread = async (Items: { Data: INewMessage }[]): Promise<ICreatedItemIdsResponse> =>
    makeRequest<ICreatedItemIdsResponse>({
        method: ERequestMethod.POST,
        url: '/Messaging/Thread',
        data: {
            Items,
        },
    })

export const replyToThread = async (Items: { Data: IReplyMessage }[]): Promise<ICreatedItemIdsResponse> =>
    makeRequest<ICreatedItemIdsResponse>({
        method: ERequestMethod.POST,
        url: '/Messaging/Message/Message',
        data: {
            Items,
        },
    })

export const fetchCommentsForEvents = async (EventIds: TDataSourceItemId[]): Promise<{ ListData: IMessageThread[] }> =>
    makeRequest<{ ListData: IMessageThread[] }>({
        method: ERequestMethod.POST,
        url: '/Messaging/Thread/Search',
        data: {
            Filters: {
                EventIds,
                Types: [EMessageType.Comment],
            },
        },
    })

export const postNewComment = async (Items: { Data: INewComment }[]): Promise<boolean> =>
    makeRequest<boolean>({
        method: ERequestMethod.POST,
        url: '/Messaging/Thread',
        data: {
            Items,
        },
    })

export const patchMessageThreadStatus = async (
    Items: { Data: IMessageThreadStatusUpdatePayload }[]
): Promise<boolean> =>
    makeRequest<boolean>({
        method: ERequestMethod.POST,
        url: '/Messaging/Message/ChangeMessageStatus',
        data: {
            Items,
        },
    })
