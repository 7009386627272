import styled from '@emotion/styled'

import Styles from 'constants/Styles'

interface INodesContainerProps {
    hasContent: boolean
    totalHeaderNodesCount: number
    childHeadersCount: number
}

const NodesContainer = styled.div<INodesContainerProps>`
    display: flex;
    flex-direction: row;
    ${({ totalHeaderNodesCount, childHeadersCount }) => `
        max-width: calc(${childHeadersCount} / ${totalHeaderNodesCount} * 100%);
    `}

    ${({ hasContent }) =>
        hasContent &&
        `
        width: 100%;
    `}
`

export const NonInitialNodesContainer = styled(NodesContainer)`
    background-color: ${Styles.calendar.greyFiller};
`

export default NodesContainer
