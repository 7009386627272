import moment from 'moment'
import makeRequest, { ERequestMethod } from '../../rest-api'

import {
    deselectCalendarNodeAction,
    initializeConfigurableCalendarAction,
    resetNonActiveCalendarsNodeSelectionsAction,
    setCalendarDataTemplates,
    setEmptyCellNodesAction,
    setSelectedCalendarNodesAction,
} from '../State/ConfigurableCalendarActions'
import { IThunkBaseAction } from '../../generic-state'
import { displayErrorToaster, displayWarningToaster } from '../../notifications'

import { queryCalendarConfiguration } from '../WebApi/ConfigurableCalendarApi'
import {
    selectCalendarAdditionalDataFetchParameters,
    selectCalendarConfiguration,
    selectCalendarDataSourceId,
    selectCalendarDates,
    selectCalendarInitialDates,
    selectCalendarNodeActions,
    selectCalendarValuePickerIds,
    selectIsCalendarNodeSelected,
    selectSelectedCalendarNodes,
} from '../State/ConfigurableCalendarSelectors'
import { Translation } from '../../localization'
import {
    dataSourceSetValuePickerIdsAction,
    fetchDataSourceDataThunk,
    fetchDataToExcel,
    filterWithDataSourceProperties,
    IFetchDataSourceDataOptions,
    initializeDataSourceThunk,
    selectDataItemIds,
    selectDataSourceItems,
    selectDataSourceItemsByIds,
    selectIsDataSourceInitialized,
} from '../../data-source'
import { handleViewEngineActionThunk } from '../../view-engine'
import { ICalendarDataTemplate } from '../Types/ICalendarDataTemplate'

import { ISelectorProperty, TDataSourceItemId } from '../../data-source-types'
import { setValuePickerValueAction } from '../../value-picker'
import { getLogger } from '../../log'
import IEmptyCellNode from '../Types/IEmptyCellNode'

const Log = getLogger('calendar.ConfigurableCalendarThunks')
interface IResponse {
    ListData: ICalendarDataTemplate[]
}

export const reInitializeCalendarDataThunk =
    (calendarId: string): IThunkBaseAction =>
    async (dispatch) => {
        try {
            const dataTemplateResponse = await makeRequest<IResponse>({
                url: '/v2/Tapahtuma/EventTemplate/Search',
                method: ERequestMethod.POST,
                data: {
                    Filters: {
                        CheckSuitability: true,
                    },
                },
            })

            if (dataTemplateResponse && dataTemplateResponse.ListData) {
                dispatch(setCalendarDataTemplates(calendarId, dataTemplateResponse.ListData))
            }
        } catch (e) {
            dispatch(displayWarningToaster(e))
        }
    }

export const initializeCalendarThunk =
    (calendarId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const configuration = selectCalendarConfiguration(getState(), calendarId)

        if (configuration) {
            return
        }

        try {
            const queriedConfiguration = await queryCalendarConfiguration(calendarId)

            if (!queriedConfiguration) {
                throw new Error(Translation.translateKey('calendar.InitializationFailed'))
            }

            dispatch(initializeConfigurableCalendarAction(calendarId, queriedConfiguration))

            const state = getState()
            const valuePickerIds = selectCalendarValuePickerIds(state, calendarId)
            const additionalDataSourceDataRequestParameters = selectCalendarAdditionalDataFetchParameters(
                state,
                calendarId
            )

            await dispatch(
                initializeDataSourceThunk(queriedConfiguration.DataSourceId, {
                    valuePickerIds,
                    additionalDataSourceDataRequestParameters,
                    fetchData: false,
                })
            )
        } catch (e) {
            dispatch(displayErrorToaster(e))
        }
    }

export const updateCalendarNodeSelectionsThunk =
    (calendarId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const selectedNodeIds = selectSelectedCalendarNodes(state, calendarId) as ReadonlySet<TDataSourceItemId>
        if (selectedNodeIds.size === 0) {
            // No need to remove selections if no nodes are currently selected.
            return
        }

        const dataSourceId = selectCalendarDataSourceId(state, calendarId)
        const dataSourceItemIds = selectDataItemIds(state, dataSourceId)

        const remainingItemIds = [...selectedNodeIds].filter((nodeId) => dataSourceItemIds?.includes(nodeId))

        const resetPreviousSelection = true
        dispatch(setSelectedCalendarNodesAction(calendarId, remainingItemIds, resetPreviousSelection))
    }

export const queryCalendarDataThunk =
    (calendarId: string, queryOptions?: IFetchDataSourceDataOptions): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const dataSourceId = selectCalendarDataSourceId(state, calendarId)
        const dataSourceInitialized = selectIsDataSourceInitialized(state, dataSourceId)

        if (dataSourceId === null || !dataSourceInitialized) {
            return
        }

        await dispatch(fetchDataSourceDataThunk(dataSourceId, queryOptions))
        dispatch(updateCalendarNodeSelectionsThunk(calendarId))
    }

export const downloadCalendarDataToExcelThunk =
    (isEmployee: boolean): IThunkBaseAction =>
    async (dispatch) => {
        const dataSourceId = isEmployee ? 'TyontekijanakymaDataSource' : 'TarvenakymaDataSource'
        const url = isEmployee ? 'v2/Tapahtuma/TyontekijanakymaExcel' : 'v2/Tapahtuma/TarvenakymaExcel'

        dispatch(fetchDataToExcel(dataSourceId, url))
    }

export const selectCalendarNodeThunk =
    (calendarId: string, nodeId: TDataSourceItemId, multiselect = false, deSelectOnClick = true): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()
        const nodeCurrentlySelected = selectIsCalendarNodeSelected(state, calendarId, nodeId)
        const selectedNodeAmount = selectSelectedCalendarNodes(getState(), calendarId).size

        if (!deSelectOnClick) {
            if (selectedNodeAmount > 1 && nodeCurrentlySelected) {
                return
            }
            dispatch(setSelectedCalendarNodeThunk(calendarId, nodeId))
            return
        }

        if (multiselect) {
            if (nodeCurrentlySelected) {
                dispatch(deselectCalendarNodeAction(calendarId, nodeId))
                return
            }

            const resetPreviousSelection = false

            dispatch(setSelectedCalendarNodeThunk(calendarId, nodeId, resetPreviousSelection))
            return
        }

        // In case the user performs a normal click on this node with multiple nodes selected,
        // only the clicked node will remain selected.
        if (selectedNodeAmount > 1) {
            dispatch(setSelectedCalendarNodeThunk(calendarId, nodeId))
            return
        }

        if (nodeCurrentlySelected) {
            dispatch(deselectCalendarNodeAction(calendarId, nodeId))
            return
        }

        dispatch(setSelectedCalendarNodeThunk(calendarId, nodeId))
    }

const setSelectedCalendarNodeThunk =
    (calendarId: string, nodeId: TDataSourceItemId, resetPreviousSelection?: boolean): IThunkBaseAction =>
    async (dispatch) => {
        dispatch(resetNonActiveCalendarsNodeSelectionsAction(calendarId))
        dispatch(setSelectedCalendarNodesAction(calendarId, [nodeId], resetPreviousSelection))
    }

export const doubleClickActionThunk =
    (calendarId: string, nodeId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const dataSourceId = selectCalendarDataSourceId(state, calendarId)

        const items = selectDataSourceItemsByIds(state, dataSourceId, [nodeId])

        const nodeActions = selectCalendarNodeActions(state, calendarId)

        const action = nodeActions.find((x) => x.IsDoubleClickAction)

        if (action && items && items.length === 1) {
            dispatch(
                handleViewEngineActionThunk({
                    items,
                    functionalityAction: action.Functionality,
                    dataSourceId,
                    additionalRequestData: undefined,
                })
            )
        }
    }

export const selectCalendarNodesBySelectorObjectThunk =
    (calendarId: string, selectorProperties: ISelectorProperty[]): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()
        const dataSourceId = selectCalendarDataSourceId(state, calendarId)

        if (dataSourceId === null) {
            Log.error('Data source isn’t initialized')
            return
        }

        const allNonDisabledNodes = selectDataSourceItems(state, dataSourceId).filter((item) => !item.Disabled)

        const currentlySelectedNodeIds = [...selectSelectedCalendarNodes(state, calendarId).values()]
        const currentlySelectedNodeObjects = selectDataSourceItemsByIds(state, dataSourceId, currentlySelectedNodeIds)

        const nodesMatchingSelectorProperties = filterWithDataSourceProperties(
            allNonDisabledNodes,
            selectorProperties,
            currentlySelectedNodeObjects
        )

        dispatch(setSelectedCalendarNodesAction(calendarId, nodesMatchingSelectorProperties))
    }

export const registerDateHandlerForCalendarsDataSourceThunk =
    (calendarIds: string[]): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()
        calendarIds.forEach((calendarId) => {
            const calendarDataSourceId = selectCalendarDataSourceId(state, calendarId)

            if (!calendarDataSourceId) {
                Log.error('No data source for the calendar $0', calendarId)
                return
            }

            dispatch(dataSourceSetValuePickerIdsAction(calendarDataSourceId, ['CalendarDatePicker']))
        })
    }

export const setCalendarsDatesThunk =
    (startOrEndDateString: 'start' | 'end'): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const value = selectCalendarDates(state)

        if (!value) {
            Log.error('Calendar has no dates')
            return
        }

        const copiedValue = moment(value[startOrEndDateString])

        if (startOrEndDateString === 'start') {
            // TODO: the amount of days to add/subtract should come from a configuration
            copiedValue.add(-7, 'days').startOf('day')
        } else {
            copiedValue.add(7 + 1, 'days').endOf('day')
        }

        const newValue = {
            ...value,
            [startOrEndDateString]: copiedValue,
        }

        dispatch(setValuePickerValueAction(newValue, 'CalendarDatePicker')) // TODO: use proper ID
    }
export const setEmptyCellNodesThunk =
    (calendarId: string, emptyCellNodes: IEmptyCellNode[]): IThunkBaseAction =>
    async (dispatch) => {
        dispatch(setEmptyCellNodesAction(calendarId, emptyCellNodes))
    }

export const resetToCalendarInitialStartDateThunk = (): IThunkBaseAction => async (dispatch, getState) => {
    const state = getState()

    const currentCalendarDates = selectCalendarDates(state)
    const { start: initialStartDate } = selectCalendarInitialDates(state)

    const newDates = {
        ...currentCalendarDates,
        start: initialStartDate,
    }

    dispatch(setValuePickerValueAction(newDates, 'CalendarDatePicker')) // TODO: use proper ID
}

export const resetToCalendarInitialEndDateThunk = (): IThunkBaseAction => async (dispatch, getState) => {
    const state = getState()

    const currentCalendarDates = selectCalendarDates(state)
    const { end: initialEndDate } = selectCalendarInitialDates(state)

    const newDates = {
        ...currentCalendarDates,
        end: initialEndDate,
    }

    dispatch(setValuePickerValueAction(newDates, 'CalendarDatePicker')) // TODO: use proper ID
}
