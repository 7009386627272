import { useEffect, useState, useCallback } from 'react'
import * as React from 'react'
import moment, { Moment } from 'moment'

import { isValidTimeRange } from '../../../generic-utilities'
import TimeRangeInputBaseV2, { IValue, ITimeRangeValidationError } from './TimeRangeInputBaseV2'

interface ITimeRangeInputV2Props {
    disabled?: boolean
    required?: boolean
    value: IValue
    onChange: (val: IValue) => void
    errors?: ITimeRangeValidationError
    valuePickerId?: string
    over24hHidden?: boolean
}

const initialTimeRange: IValue = {
    startTime: '',
    endTime: '',
    timeLength: 0,
    isOver24Hours: false,
}

const minutesInDay = 60 * 24

const getMinutesInDay = () => {
    return minutesInDay // wrong when moving to / from summer time but use this for now
}

const isOver24Hours = (diffInMinutes: number) => {
    return diffInMinutes > getMinutesInDay()
}

const getInitialValue = (possibleInitialValue: Pick<IValue, 'startTime' | 'endTime'> | null): IValue => {
    if (!isValidTimeRange(possibleInitialValue)) {
        return initialTimeRange
    }

    const diffInMinutes = (possibleInitialValue.endTime as Moment).diff(
        possibleInitialValue.startTime as Moment,
        'minutes'
    )

    return {
        ...possibleInitialValue,
        timeLength: diffInMinutes,
        isOver24Hours: isOver24Hours(diffInMinutes),
    }
}

const TimeRangeInput: React.FunctionComponent<ITimeRangeInputV2Props> = ({
    value,
    disabled = false,
    over24hHidden = false,
    errors,
    required,
    onChange,
    valuePickerId,
}) => {
    const [timeRange, setTimeRange] = useState<IValue>(getInitialValue(value))

    const _internalHandleChange = useCallback((newTimeRangeValue: IValue) => {
        const startTime = isValidInput(newTimeRangeValue.startTime) ? moment(newTimeRangeValue.startTime) : null
        const endTime = isValidInput(newTimeRangeValue.endTime) ? moment(newTimeRangeValue.endTime) : null

        if (startTime !== null && endTime !== null) {
            if (endTime.diff(startTime) < 0) {
                endTime.add(1, 'days')
            }
        }

        const diffInMinutes = newTimeRangeValue.timeLength
            ? newTimeRangeValue.timeLength
            : startTime === null || endTime === null
            ? 0
            : endTime.diff(startTime, 'minutes')

        const _isOver24hours = diffInMinutes > minutesInDay

        const newValue = {
            startTime,
            endTime,
            timeLength: diffInMinutes,
            isOver24Hours: _isOver24hours,
        }

        setTimeRange(newValue)

        return newValue
    }, [])

    const handleChange = useCallback(
        (newTimeRangeValue: IValue) => {
            const newValue = _internalHandleChange(newTimeRangeValue)
            onChange(newValue)
        },
        [_internalHandleChange, onChange]
    )

    useEffect(() => {
        if (value && value.startTime && value.endTime && !timeRange.endTime && !timeRange.startTime) {
            _internalHandleChange(value)
        }

        if (!timeRange.endTime || !timeRange.startTime) {
            return
        }

        if (value === null) {
            setTimeRange(getInitialValue(null))
        }
    }, [value, timeRange.endTime, timeRange.startTime, handleChange, _internalHandleChange])

    const isValidInput = (input: Moment | string | null | undefined) => {
        if (input === null || input === undefined || input === '') {
            return false
        }

        const momentInput = moment(input)

        return momentInput.isValid()
    }

    return (
        <TimeRangeInputBaseV2
            disabled={disabled}
            required={required}
            handleChange={handleChange}
            value={timeRange}
            validationErrorStartTime={errors?.startTimeError}
            validationErrorEndTime={errors?.endTimeError}
            valuePickerId={valuePickerId}
            over24hHidden={over24hHidden}
        />
    )
}

export default TimeRangeInput
