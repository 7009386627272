import { createReducer } from 'typesafe-actions'
import { setValuePickerInitialValueAction } from './ValuePickerActions'
import TValuePickerId from '../types/TValuePickerId'

type ValuePickerInitialValuesState = Record<TValuePickerId, unknown>

const initialState = {}

const valuePickerInitialValuesReducer = createReducer<ValuePickerInitialValuesState>(initialState).handleAction(
    setValuePickerInitialValueAction,
    (state, action) => {
        const { value, id } = action.payload

        return { ...state, [id]: value }
    }
)

export default valuePickerInitialValuesReducer
