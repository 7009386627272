import { MomentInput } from 'moment'
import * as React from 'react'

import DesktopTimeInput from './DesktopTimeInput'

export interface ITimeInputProps {
    changeAction: any
    rawTimeValue: MomentInput
    description: string
    disabled?: boolean
    onBlur?: (e: React.SyntheticEvent) => void
    translate?: boolean
    width?: string
    validationError?: string | null
    isSpaceAlwaysReservedForError?: boolean
}

const TimeInput: React.FC<ITimeInputProps> = ({ disabled = false, validationError = null, ...rest }) => {
    return <DesktopTimeInput {...rest} disabled={disabled} validationError={validationError} />
}

export default TimeInput
