import * as React from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'

import { useSyncedScrolling } from '../../../../generic-utilities'
import IMainAxisHeader from '../../../Types/IMainAxisHeader'
import { setCalendarsDatesThunk } from '../../../Thunks/ConfigurableCalendarThunks'
import { BoundThunk } from '../../../../generic-state'
import { LoadMoreCalendarDataButtonLeft, LoadMoreCalendarDataButtonRight } from './LoadMoreDataButtons'
import HeaderNodes from './HeaderNodes'
import { CALENDAR_HORIZONTAL_SCROLL_GROUPS } from '../../../Constants/CalendarScrollGroups'

interface IDispatchProps {
    setCalendarsDates: BoundThunk<typeof setCalendarsDatesThunk>
}

interface ICalendarHeadersProps extends IDispatchProps {
    headers: IMainAxisHeader[]
}

const HeadersContainer = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    height: 49px;
    z-index: 2;
`

const Header = styled.div`
    justifycontent: center;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
`

const ScrollContainer = styled.div`
    overflow: auto;
    display: inherit;
    height: inherit;
    overflow-x: hidden;
    background-color: white;
    box-sizing: initial;
    width: 100%;
`

const CalendarHeaders: React.FunctionComponent<ICalendarHeadersProps> = ({ headers, setCalendarsDates }) => {
    const scrollContainerRef = useSyncedScrolling(CALENDAR_HORIZONTAL_SCROLL_GROUPS)

    const headerWidth = headers && headers.length > 0 ? headers[0].width : 0

    return (
        <HeadersContainer>
            <ScrollContainer ref={scrollContainerRef}>
                <LoadMoreCalendarDataButtonLeft
                    onClick={() => setCalendarsDates('start')}
                    relatedExtraNodes={headers[0].nodes.beforeInitialNodes}
                />

                {headers.map(({ component, level, nodes }) => (
                    <Header key={level}>
                        <HeaderNodes width={headerWidth} component={component} nodes={nodes} />
                    </Header>
                ))}

                <LoadMoreCalendarDataButtonRight
                    onClick={() => setCalendarsDates('end')}
                    relatedExtraNodes={headers[0].nodes.afterInitialNodes}
                />
            </ScrollContainer>
        </HeadersContainer>
    )
}

const mapStateToProps = null

// TODO: make this whole stuff nicer
const mapDispatchToProps = {
    setCalendarsDates: setCalendarsDatesThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarHeaders)
