import * as React from 'react'
import styled from '@emotion/styled'

import SubHeading from 'components/atoms/SubHeading'
import Styles from 'constants/Styles'

interface INoContentTextProps {
    isLoading: boolean
    noContentFoundText?: string
}

const Container = styled.div`
    padding-left: 0.5rem;
`

const NoContentText: React.FunctionComponent<INoContentTextProps> = ({
    isLoading,
    noContentFoundText = 'generic-components.Dropdown.NoContentFoundText',
}) => {
    const text = isLoading ? 'generic-components.Dropdown.LoadingContentText' : noContentFoundText

    return (
        <Container>
            <SubHeading {...Styles.layout.placeholderText}>{text}</SubHeading>
        </Container>
    )
}

export default NoContentText
