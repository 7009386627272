import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import Button from '@material-ui/core/Button'
import * as React from 'react'
import { useCallback, useState } from 'react'
import styled from '@emotion/styled'

import { IMuuttuneetAsetustenArvot } from './OsiokohtaisetAsetuksetMuuttuneetAsetukset'
import { IAsetusArvo, IOsionAsetus } from '../../reducers/OsiokohtaisetAsetuksetReducer'
import { haeTietotyyppiMaarittely } from '../../OsiokohtaisetAsetuksetTietotyyppiMaaritykset'
import { tallennaOsiokohtaisetAsetukset } from '../../thunks/OsiokohtaisetAsetuksetThunks'
import OsiokohtaisetAsetuksetRyhma from '../organisms/OsiokohtaisetAsetuksetRyhma'
import OsiokohtaisetAsetuksetTallennusDialogi from './OsiokohtaisetAsetuksetTallennusDialogi'
import { Translation } from '../../../localization'
import { BoundThunk } from '../../../generic-state'
import { isEmpty, map, mapValues, orderBy } from 'lodash-es'
import { chain } from 'lodash'

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const StyledButton = styled(Button)`
    margin-left: 1rem;
`

interface IDispatchProps {
    tallennaOsiokohtaisetAsetukset: BoundThunk<typeof tallennaOsiokohtaisetAsetukset>
}

export interface IOsiokohtaisetAsetuksetFormProps extends IDispatchProps {
    asetukset: ReadonlyMap<string, IOsionAsetus>
}

export const OsiokohtaisetAsetuksetFormUnconnected: React.FunctionComponent<IOsiokohtaisetAsetuksetFormProps> = (
    props
) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [onkoAsetuksetTallennettu, setOnkoAsetuksetTallennettu] = useState(false)

    /**
     * Luodaan Yup-validaatioskeema formille asetuksien tietotyyppien perusteella.
     */
    const generateValidationSchema = () => {
        const asetuksetNimenMukaan = chain(Array.from(props.asetukset.values()))
            .mapKeys((asetus) => asetus.Nimi)
            .value()

        const validationSchema = Yup.object().shape(
            // Haetaan jokaiselle asetukselle sen tietotyyppiä vastaava validaattori.
            mapValues(asetuksetNimenMukaan, (asetus) => haeTietotyyppiMaarittely(asetus.Tietotyyppi).validaattori)
        )

        return validationSchema
    }

    /**
     * Palauttaa asetusten avaimet ja niiden arvot key-value pareina.
     */
    const getInitialValues = () => {
        const initialValues = chain(Array.from(props.asetukset.values()))
            .mapKeys((asetus) => asetus.Nimi)
            .mapValues((asetus) => {
                const { parser, arvoProperty } = haeTietotyyppiMaarittely(asetus.Tietotyyppi)

                // Otetaan arvo tietotyyppiä vastaavasta propertystä (ArvoString, ArvoInt, jne...)
                const raakaArvo = asetus[arvoProperty]

                // Parsitaan arvo formin käyttämään muotoon (esim. string timestampit Moment-objekteiksi)
                const arvo = parser(raakaArvo)

                return arvo
            })
            .value()

        return initialValues
    }

    /**
     * Palauttaa dictionaryn, joka sisältää muutetut asetukset sekä niiden vanhat että uudet arvot.
     */
    const haeMuuttuneetAsetustenArvot = (values: any): IMuuttuneetAsetustenArvot => {
        const initialValues = getInitialValues()

        const muuttuneetAsetustenArvot = chain(initialValues)
            .mapValues((value, key) => ({
                asetus: props.asetukset.get(key) as IOsionAsetus,
                vanha: value,
                uusi: values[key],
            }))
            // Filtteröidään muuttumattomat arvot pois.
            .pickBy(({ asetus, vanha, uusi }) => {
                const { vertailija } = haeTietotyyppiMaarittely(asetus.Tietotyyppi)

                return vertailija(vanha, uusi, asetus)
            })
            .value()

        return muuttuneetAsetustenArvot as IMuuttuneetAsetustenArvot
    }

    const onSubmit = async (values: any, { setSubmitting }: any) => {
        const muuttuneetArvot = haeMuuttuneetAsetustenArvot(values)

        const tallennettavatAsetukset = map(muuttuneetArvot, ({ asetus, uusi }) => {
            const { arvoProperty } = haeTietotyyppiMaarittely(asetus.Tietotyyppi)

            const asetusArvo: IAsetusArvo = { [arvoProperty]: uusi }

            const tallennettavaAsetus = {
                Nimi: asetus.Nimi,
                ...asetusArvo,
            }

            return tallennettavaAsetus
        })

        try {
            await props.tallennaOsiokohtaisetAsetukset(tallennettavatAsetukset)

            setOnkoAsetuksetTallennettu(true)
        } catch (error) {
            // Ei tarvitse tehdä mitään, koska `tallennaOsiokohtaisetAsetukset` thunkin dispatchaama
            // `errorOnPromiseFailed` näyttää jo virhenotifikaation.
        }

        // Jos `setSubmitting(false)` ei kutsuta tässä, jää spinneri looppaamaan ikuisesti virhetilanteessa.
        setSubmitting(false)
    }

    const openDialog = () => {
        setIsDialogOpen(true)
        setOnkoAsetuksetTallennettu(false)
    }

    const closeDialog = useCallback(() => setIsDialogOpen(false), [setIsDialogOpen])

    const ryhmatAakkosjarjestyksessa = chain([...props.asetukset.values()])
        .groupBy('Ryhma')
        .map((asetukset, ryhmanNimi) => ({
            ryhmanNimi,
            asetukset: orderBy(asetukset, 'NimiAsiakkaalle'), // Asetukset aakkosjärjestykseen ryhmän sisällä
        }))
        .orderBy('ryhmanNimi')
        .value()

    const initialValues = getInitialValues()

    const validationSchema = generateValidationSchema()

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ values, handleReset, isSubmitting, submitForm, errors }) => {
                const muuttuneetAsetustenArvot = haeMuuttuneetAsetustenArvot(values)

                const areButtonsDisabled = Boolean(isEmpty(muuttuneetAsetustenArvot) || isSubmitting)
                const isSaveButtonDisabled = areButtonsDisabled || !isEmpty(errors)

                return (
                    <Form id="OsiokohtaisetAsetuksetForm">
                        {ryhmatAakkosjarjestyksessa.map(({ asetukset, ryhmanNimi }) => (
                            <OsiokohtaisetAsetuksetRyhma
                                asetukset={asetukset}
                                key={ryhmanNimi}
                                ryhmanNimi={ryhmanNimi}
                            />
                        ))}

                        <ButtonsContainer>
                            <StyledButton disabled={areButtonsDisabled} onClick={handleReset} variant="contained">
                                {Translation.translateKey('OsiokohtaisetAsetukset.kumoa')}
                            </StyledButton>

                            <StyledButton
                                color="primary"
                                disabled={isSaveButtonDisabled}
                                onClick={openDialog}
                                variant="contained"
                            >
                                {Translation.translateKey('OsiokohtaisetAsetukset.tallenna')}
                            </StyledButton>
                        </ButtonsContainer>

                        <OsiokohtaisetAsetuksetTallennusDialogi
                            isSubmitting={isSubmitting}
                            muuttuneetAsetustenArvot={muuttuneetAsetustenArvot}
                            onClose={closeDialog}
                            onkoAsetuksetTallennettu={onkoAsetuksetTallennettu}
                            open={isDialogOpen}
                            submit={submitForm}
                        />
                    </Form>
                )
            }}
        </Formik>
    )
}

const mapDispatchToProps = {
    tallennaOsiokohtaisetAsetukset,
}

export default connect(undefined, mapDispatchToProps)(OsiokohtaisetAsetuksetFormUnconnected)
