import * as React from 'react'
import styled from '@emotion/styled'

import { Button, IStyledButtonProps as ButtonProps } from '../Button'
import Icon from '../../Icon'
import Styles from 'constants/Styles'

interface IAnimatedHoverButtonProps extends ButtonProps {
    icon: string
    variant?: 'outlined'
    backgroundColor?: string
}

const labelClassName = 'animatedhoverbutton-label'

interface IStyledButtonProps
    extends React.ComponentProps<typeof Button>,
        Pick<IAnimatedHoverButtonProps, 'backgroundColor'> {}

const StyledButton = styled<React.FC<IStyledButtonProps>>(({ backgroundColor, ...rest }) => <Button {...rest} />)`
    min-width: 40px;
    max-width: 40px;

    background-color: ${({ backgroundColor }) => backgroundColor};

    .${labelClassName} {
        display: none;
    }

    & .MuiButton-startIcon {
        margin-left: 2px;
        color: ${Styles.mainColor.blue};
    }

    :hover {
        min-width: unset;
        max-width: none;
        background-color: ${({ backgroundColor }) => backgroundColor};

        .${labelClassName} {
            display: inherit;
            color: ${Styles.mainColor.blue};
            font-weight: 600;
            white-space: nowrap;
        }

        & .MuiButton-startIcon {
            margin-left: -12px;
        }
    }
`

const AnimatedHoverButton: React.FC<IAnimatedHoverButtonProps> = ({
    variant,
    icon,
    children,
    muiButtonProps,
    backgroundColor = 'white',
    ...props
}) => {
    const iconElement = <Icon>{icon}</Icon>

    return (
        <StyledButton
            {...props}
            backgroundColor={backgroundColor}
            muiButtonProps={muiButtonProps}
            startIcon={iconElement}
            variant={variant}
        >
            <span className={labelClassName}>{children}</span>
        </StyledButton>
    )
}

export default AnimatedHoverButton
