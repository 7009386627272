import * as React from 'react'
import Skeleton from '@material-ui/core/Skeleton'
import styled from '@emotion/styled'

import CalendarLoadingGroupNodes from './CalendarLoadingGroupNodes'
import CalendarLoadingSegments from './CalendarLoadingSegments'

interface ICalendarLoadingProps {
    segmentAmount?: number
}

const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 5px 45px;
    display: flex;
    flex-direction: column;
`

const Header = styled.div`
    margin-left: 220px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const HeaderSkeleton = styled(Skeleton)`
    margin-left: 2px;
`

const MainContent = styled.div`
    display: flex;
    flex-direction: row;
    height: calc(100% - 70px);
`

const HeaderSkeletonWithDimensions = () => <HeaderSkeleton height="48px" variant="rectangular" width="16.6%" />

const CalendarLoading: React.FunctionComponent<ICalendarLoadingProps> = ({ segmentAmount = 10 }) => (
    <LoadingContainer>
        <Header>
            <HeaderSkeletonWithDimensions />
            <HeaderSkeletonWithDimensions />
            <HeaderSkeletonWithDimensions />
            <HeaderSkeletonWithDimensions />
            <HeaderSkeletonWithDimensions />
            <HeaderSkeletonWithDimensions />
        </Header>

        <MainContent>
            <CalendarLoadingGroupNodes segmentAmount={segmentAmount} />

            <CalendarLoadingSegments segmentAmount={segmentAmount} />
        </MainContent>
    </LoadingContainer>
)

export default CalendarLoading
