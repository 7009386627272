export const ADD_EMPLOYEE_DATA_SOURCE_ACTION_ID = 'Tarvenakyma_Action_Add_Tyontekija'
export const REMOVE_EMPLOYEE_DATA_SOURCE_ACTION_ID = 'Tyontekijanakyma_Action_Remove_Tyontekija'
export const SUITABLE_EMPLOYEES_LIST_ID = 'SoveltuvatTyontekijatList'
export const SUITABLE_EVENTS_LIST_ID = 'SuitableEvents'
export const CALENDAR_DATEPICKER_ID = 'CalendarDatePicker'
export const WORKUNIT_VALUEPICKER_ID = 'WorkforceScheduling_Calendar_Filter_WorkUnit'
export const PLANNING_PERIOD_LIST_ID = 'Suunnittelujakso'
export const PLANNING_PERIODS_VALUE_PICKER_ID = 'WorkforceScheduling_PlanningPeriods'
export const TARVENAKYMA_PUBLISH_EVENTS_ACTION_ID = 'Publish_Events'
export const TYONTEKIJA_PUBLISH_EVENTS_ACTION_ID = 'Tyontekijanakyma_Action_Publish_Events'
export const EVENT_CREATE_FORM_VIEW = 'WorkforceSchedulingEventCreateFormView'
export const EVENT_CREATE_FORM_VIEW_V2 = 'Event_Create'
export const EVENT_CREATE_EVENTS_FROM_EVENT_TEMPLATE_GROUP_VIEW = 'CreateEventsFromEventTemplateGroupFormView'
export const TARVENAKYMA_CALENDAR_ID = 'TarvenakymaCalendar'
export const TARVENAKYMA_DATA_SOURCE_ID = 'TarvenakymaDataSource'
export const TARVENAKYMA_REMOVE_TAPAHTUMA_ACTION_ID = 'Tarvenakyma_Action_Remove_Tapahtuma'
export const TARVENAKYMA_EVENT_EDIT_FORM_VIEW = 'TarvenakymaTapahtumaEditFormView'
export const TYONTEKIJANAKYMA_CALENDAR_ID = 'TyontekijanakymaCalendar'
export const TYONTEKIJANAKYMA_DATA_SOURCE_ID = 'TyontekijanakymaDataSource'
export const TYONTEKIJANAKYMA_REMOVE_TAPAHTUMA_ACTION_ID = 'Tyontekijanakyma_Action_Remove_Tapahtuma'
export const TYONTEKIJANAKYMA_TAPAHTUMA_EDIT_FORM_VIEW = 'TyontekijanakymaTapahtumaEditFormView'
export const TARVENAKYMA_COPY_TAPAHTUMA_ACTION_ID = 'Tarvenakyma_Action_Copy_Tapahtuma'
export const TYONTEKIJANAKYMA_COPY_TAPAHTUMA_ACTION_ID = 'Tyontekijanakyma_Action_Copy_Tapahtuma'
export const TYONTEKIJANAKYMA_SWAP_TAPAHTUMA_ACTION_ID = 'Tyontekijanakyma_Action_Swap_Tapahtuma'
export const PLANNING_PERIOD_PUBLISHING_CONFIRMATION_DIALOG_ID = 'PlanningPeriodPublishingConfirmationDialog'
export const EVENTS_PUBLISHING_CONFIRMATION_DIALOG_ID = 'PublishEventsConfirmationDialog'
export const EVENT_TEMPLATE_GROUP_CREATE_VIEW = 'EventTemplateGroupCreateView'
export const CONFIRM_TRANSFER_TO_EVENT_ACTION_ID = 'ConfirmTransfersToEvents'
export const EVENT_DELETION_CONFIRMATION_DIALOG_ID = 'WorkforceSchedulingDeletionConfirmationDialog'

export const FORM_VIEW_CREATE_PLANNING_PERIOD = 'SuunnittelujaksoCreateForm'
export const FORM_VIEW_PLANNING_PERIOD_MODIFY = 'SuunnittelujaksoEditForm'
export const DATA_SOURCE_PLANNING_PERIOD = 'SuunnittelujaksoDataSource'

export const FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_WORK_UNITS = 'SuunnittelujaksoCreateForm_WorkUnits'
export const FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_WORK_EMPLOYEES = 'SuunnittelujaksoCreateForm_Employees'
export const FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_POOLS = 'SuunnittelujaksoCreateForm_Pools'
export const FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_START_DATE = 'SuunnittelujaksoCreateForm_StartDate'
export const FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_END_DATE = 'SuunnittelujaksoCreateForm_EndDate'
