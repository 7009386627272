/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PropTypes from 'prop-types'
import * as React from 'react'
import Styles from 'constants/Styles'

type TAction = (any: any) => any

type TChangeAction = TAction | { dispatch: TAction }

export interface IAbstractInputProps {
    changeAction?: TChangeAction
    value: string | number
}

/**
 * Input-elementtien kantaluokka.
 * Tarjoaa actionin kutsun erilaisille syötteille. Jos action on flux-action (olio),
 * sille kutsutaan dispatch-funktiota. Jos se on funktio, sitä kutsutaan suoraan.
 *
 * @note Tällä luokalla ei voi olla omaa render()-metodia. Jos on, React hajoaa.
 *
 */
class AbstractInput<IProps extends IAbstractInputProps> extends React.Component<IProps> {
    static displayName = 'AbstractInput'

    static propTypes = {
        changeAction: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }

    static defaultProps = {
        value: '',
    }

    static Styles = Styles

    constructor(props: IProps) {
        super(props)

        this._onChanged = this._onChanged.bind(this)
        this._onChecked = this._onChecked.bind(this)
        this._onSelected = this._onSelected.bind(this)
        this._onInput = this._onInput.bind(this)
    }

    /**
     * Kutsu tätä lapsiluokassa kun input-elementti saa uuden diskreetin arvon.
     * Esimerkiksi päivämäärän valinta.
     */
    protected _onChanged(newValue: any): void {
        this._dispatch(newValue)
    }

    /**
     * Kutsu tätä lapsiluokassa kun input-elementin valinnan tila vaihtuu.
     * Esimerkiksi kun checkbox valitaan.
     */
    protected _onChecked(newValue: boolean, event: React.ChangeEvent): void {
        this._dispatch(newValue)
        //Estetään eventin kuplminen, jotta ylemmän kontainerin onClick eventit ei laukea samaan aikaan.
        event.stopPropagation()
    }

    /**
     * Kutsu tätä lapsiluokassa kun input-elementti valitaan joukosta.
     * Esimerkiksi radio button valinnassa.
     */
    protected _onSelected(): void {
        this._dispatch(this.props.value)
    }

    /**
     * Kutsu tätä lapsiluokassa kun input-elementin arvo muuttuu.
     * Esimerkiksi tekstiä syötettäessä.
     */
    protected _onInput(event: any): void {
        this._dispatch(event.target.value)
    }

    private _dispatch(value: any): void {
        const changeAction = this.props.changeAction
        if (!changeAction) {
            return
        }

        if (typeof changeAction === 'function') {
            changeAction(value)
        } else {
            changeAction.dispatch(value)
        }
    }
}

export default AbstractInput
