import * as React from 'react'
import styled from '@emotion/styled'

import { ISelectRowsFunction } from './../../../../../Interfaces'
import CheckBox from 'components/atoms/CheckBox'

interface IRowCheckboxProps {
    handleClick: ISelectRowsFunction<any>
    value: boolean
    disabled: boolean
}

const Container = styled.div`
    display: flex;
    padding-left: 10px;
`

export default class RowCheckbox extends React.Component<IRowCheckboxProps> {
    render(): React.ReactNode {
        const { handleClick, value, disabled } = this.props

        return (
            <Container className="ctr-dropdown-row-checkbox">
                <CheckBox changeAction={handleClick} disabled={disabled} value={value} />
            </Container>
        )
    }
}
