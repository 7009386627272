import * as React from 'react'

import MuiDialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'

import { Icon, ReadOnlyText, TranslatedButton } from '../../generic-components'
import { isTranslatable, Translate, Translation, TTranslatable } from '../../localization'
import { closeModalAction } from '../State/ModalActions'
import { useAppTheme } from '../../theme/'

interface IRenderButtons {
    (extraButtons?: React.ReactNode): React.ReactNode
}

interface IContentRenderer {
    (renderProps: { renderButtons: IRenderButtons }): React.ReactNode
}

export interface IModalBaseOwnProps {
    disableDefaultActionButtons?: boolean
    actionButtons?: React.ReactNode
    children: React.ReactNode | IContentRenderer
    /**
     * Function to close the modal. If not given, will close the modal
     * by its ID.
     */
    handleClose?: () => void
    /**
     * Callback run immediately when the modal has closed.
     */
    onClose?: () => void
    modalId: string
    muiDialogProps?: Partial<Omit<DialogProps, 'title'>>
    title?: string
    subtitle?: TTranslatable | React.ReactNode
    className?: string
    isClosedOnEscapeDown?: boolean
    classNameTitleContainer?: string
    classNameContentContainer?: string
    classNameSubtitleContainer?: string
    classNameTitle?: string
    bottomAlert?: React.ReactNode
    cancelButtonText?: string
}

const ContentContainer = styled(DialogContent)`
    padding: 0 32px 0;
`

const AlertContainer = styled.div`
    padding: 0 32px 0;
`

const TitleContainer = styled(DialogTitle)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 32px;
    border-bottom: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
`

const ActionButtons = styled(DialogActions)`
    padding: 8px 32px 24px;

    > * {
        margin-left: 8px;
    }
`

const childrenIsFunction = (children: IContentRenderer | React.ReactNode): children is IContentRenderer => {
    return typeof children === 'function'
}

const isTitleStringNotToBeTranslated = (title: unknown) => {
    return typeof title === 'string' && !Translation.has(title)
}

const ModalBase = ({
    disableDefaultActionButtons,
    actionButtons,
    children,
    handleClose,
    modalId,
    muiDialogProps,
    title,
    subtitle,
    onClose,
    className,
    isClosedOnEscapeDown = true,
    classNameTitleContainer,
    classNameContentContainer,
    classNameSubtitleContainer,
    bottomAlert,
    cancelButtonText = 'button-cancel',
}: IModalBaseOwnProps): JSX.Element => {
    // TODO: disable scrolling when open (conditional under props)
    const dispatch = useDispatch()
    const { colors, componentExtensions } = useAppTheme()

    const handleModalClose = () => {
        if (handleClose) {
            handleClose()
        } else {
            dispatch(closeModalAction(modalId))
        }

        onClose && onClose()
    }

    const renderButtons = (extraButtons?: React.ReactNode) => (
        <ActionButtons>
            <TranslatedButton onClick={handleModalClose} variant="text" testId="STC_MODAL_CLOSE_BTN">
                {cancelButtonText}
            </TranslatedButton>
            {extraButtons}
        </ActionButtons>
    )

    const handleCloseOnEscapeKeyDown: DialogProps['onClose'] = (event, reason) => {
        if (reason === 'escapeKeyDown') {
            handleModalClose()
        }
    }

    return (
        <MuiDialog
            {...muiDialogProps}
            className={className}
            transitionDuration={200}
            onClose={isClosedOnEscapeDown ? handleCloseOnEscapeKeyDown : undefined}
            data-testid={`modal-${modalId}`}
            open
        >
            {title && (
                <TitleContainer
                    className={classNameTitleContainer}
                    disableTypography
                    data-testid="STC_FORM_MODAL_TITLE"
                >
                    <ReadOnlyText
                        translate={Translation.has(title)}
                        usage="H1"
                        rawColor={colors.neutralsGrey100}
                        data-testid="STC_FORM_MODAL_TITLE"
                    >
                        {title}
                    </ReadOnlyText>
                    <button onClick={handleModalClose} data-testid="STC_FORM_MODAL_CLOSE">
                        <Icon size={24} color={componentExtensions.icons.iconAction}>
                            close
                        </Icon>
                    </button>
                </TitleContainer>
            )}
            {subtitle && (
                <TitleContainer
                    className={classNameSubtitleContainer}
                    disableTypography
                    data-testid="STC_FORM_MODAL_SUBTITLE"
                >
                    {isTitleStringNotToBeTranslated(subtitle) || !isTranslatable(subtitle) ? (
                        subtitle
                    ) : (
                        <Translate translate={{ label: subtitle }}>
                            {({ label }) => <Typography variant="h3">{label}</Typography>}
                        </Translate>
                    )}
                </TitleContainer>
            )}

            <ContentContainer className={classNameContentContainer}>
                {childrenIsFunction(children) ? children({ renderButtons }) : children}
            </ContentContainer>

            {bottomAlert && <AlertContainer>{bottomAlert}</AlertContainer>}

            {!disableDefaultActionButtons && renderButtons(actionButtons)}
        </MuiDialog>
    )
}

export default ModalBase
