import * as React from 'react'

import FormModal, { IFormModalOwnProps } from '../FormModal'
import Typography from '@material-ui/core/Typography'
import { TTranslatable } from '../../../localization'

type TFormModalProps = React.ComponentProps<typeof FormModal>

export interface IDialogProps {
    confirmationText?: React.ReactNode
    confirmButtonText: string
    extraContent?: IFormModalOwnProps['children']
    modalId: string
    modalOpen: boolean
    onClose?: TFormModalProps['handleClose']
    onSubmit: TFormModalProps['formikProps']['onSubmit']
    title?: TTranslatable
    subtitle?: TFormModalProps['subtitle']
    initialValues?: Record<string, unknown>
    disableSubmit?: TFormModalProps['disableSubmit']
}

export const ConfirmationDialog: React.FunctionComponent<IDialogProps> = ({
    confirmationText,
    confirmButtonText,
    modalId,
    modalOpen,
    onClose,
    onSubmit,
    extraContent,
    title,
    subtitle,
    initialValues = {},
    disableSubmit,
}) => {
    const confirmationTextRendered =
        typeof confirmationText === 'string' ? (
            <Typography variant="bodyS">{confirmationText}</Typography>
        ) : (
            confirmationText
        )

    const formikProps = {
        initialValues,
        isInitialValid: true,
        onSubmit,
    }

    return (
        <FormModal
            disableSubmit={disableSubmit}
            formId="confirmationDialogForm"
            formikProps={formikProps}
            handleClose={onClose}
            modalId={modalId}
            open={modalOpen}
            submitLabel={confirmButtonText}
            subtitle={subtitle}
            title={title}
        >
            {(formikPropsInRender) => (
                <React.Fragment>
                    {typeof extraContent === 'function' ? extraContent(formikPropsInRender) : extraContent}
                    {confirmationTextRendered}
                </React.Fragment>
            )}
        </FormModal>
    )
}

export default ConfirmationDialog
