import styled from '@emotion/styled'
import MuiButton from '@material-ui/core/IconButton'
import Icon from '../../Icon'
import { useAppTheme } from '../../../theme'
import Tooltip from '../../Tooltip'

type Props = {
    icon: string
    size: 'small' | 'medium'
    outline?: boolean
    tooltip?: string
    disabled?: boolean
    testId?: string
    onClick?: () => void
}

const Button = styled(MuiButton, { shouldForwardProp: (propName) => propName !== 'outline' })<{ outline?: boolean }>`
    border: ${({ outline, theme }) =>
        outline && `1px solid ${theme.componentExtensions.buttons.borderButtonSecondaryDefault}`};

    &.MuiIconButton-sizeMedium {
        padding: 0;
        width: 32px;
        height: 32px;
    }
`

const IconButton = ({ icon, size, outline, tooltip, disabled, testId, onClick }: Props): JSX.Element => {
    const { componentExtensions } = useAppTheme()

    return (
        <Tooltip title={tooltip}>
            <Button size={size} onClick={onClick} outline={outline} disabled={disabled} data-testid={testId}>
                <Icon color={componentExtensions.icons.iconAction}>{icon}</Icon>
            </Button>
        </Tooltip>
    )
}

export default IconButton
