import * as React from 'react'
import styled from '@emotion/styled'

import Tag from './Tag'
import { get } from 'lodash-es'

type TTagId = string | number

interface IOwnProps {
    disabled?: boolean
    size?: 'small' | 'medium'
    tagInfo: Record<string, unknown>[]
    onChange?: (value: ReadonlySet<TTagId>) => void
    value?: ReadonlySet<TTagId>
    itemIdField?: string
    itemLabelFields: string[]
    mainColorField: string
    supportColorField: string
}

const StyledContainer = styled.span`
    & .MuiChip-root {
        margin-left: 8px;
        margin-right: 8px;
    }
`

const TagList: React.FunctionComponent<IOwnProps> = ({
    itemIdField = 'Id',
    itemLabelFields,
    mainColorField,
    value,
    disabled = false,
    size = 'medium',
    tagInfo,
    onChange,
    supportColorField,
}) => {
    if (tagInfo.length === 0) {
        return null
    }

    const handleChange = (id: TTagId, selected: boolean) => {
        const copiedValue = new Set(value)
        selected ? copiedValue.add(id) : copiedValue.delete(id)

        onChange && onChange(copiedValue)
    }

    const tags = tagInfo.map((tag) => {
        const id = get(tag, itemIdField) as string
        const label = itemLabelFields.map((labelField) => get(tag, labelField, '')).join(' ')
        const mainColor = get(tag, mainColorField, '') as string
        const supportColor = get(tag, supportColorField, '') as string

        const onTagSelectToggle = value ? (selected: boolean) => handleChange(id, selected) : undefined
        const onDelete = value ? () => handleChange(id, false) : undefined

        return (
            <Tag
                isDisabled={disabled}
                key={id}
                label={label}
                mainColor={mainColor}
                onDelete={onDelete}
                selected={value && value.has(id)}
                setSelected={onTagSelectToggle}
                size={size}
                supportColor={supportColor}
                {...tag}
            />
        )
    })

    return <StyledContainer>{tags}</StyledContainer>
}

export default TagList
