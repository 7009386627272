import PropTypes from 'prop-types'
import { createRef, Component } from 'react'
import styled from '@emotion/styled'

import { OPEN_FALSE, OPEN_TRUE } from 'constants/Params'
import { Translation } from '../../../localization'
import TextInput from 'components/atoms/TextInput'

const MENU_CONTAINER_CLASS = 'ctr-dropdown'

const MainContainer = styled.div`
    display: inline-block;
    position: relative;
`

const MenuContainer = styled.div`
    position: absolute;
`

/**
 * Yleinen dropdown-komponentti. HUOM. Ei ole vielä ollenkaan valmis - ei sisällä
 * renderöintiä valikolle. Toimii kuitenkin ainakin jollain tasolla jos sille annetaan
 * menun renderöijä - tällä hetkellä renderöi pelkän TextInputin yksittäiselle valitulle
 * arvolle, mikä ei ole välttämättä toivottu tilanne.
 *
 * Huom. komponentti käyttää hyväkseen valikon sulkemisessa Javascriptin tapahtumien
 * kuplimistoimintoa - kun esimerkiksi klikkaa jotain, tapahtuma kuplii kaikille klikatun
 * elementin vanhemmille. Eli kun jotakin valikon lasta klikataan (valittaessa jokin rivi),
 * tapahtuma kuplii tässä komponentissa määritetylle container-elementille. Näin käydessä
 * suljetaan dropdown. Tämä edellyttää, että klikatun lapsielementin tapahtumakuuntelijalle
 * ei ole estetty kuplimista (eventin stopPropagation-metodia käyttämällä).
 */
class Dropdown extends Component {
    constructor(props) {
        super(props)

        this._openDropdownMenu = this._openDropdownMenu.bind(this)
        this._closeOptionsMenu = this._closeOptionsMenu.bind(this)

        this._mainElementRef = createRef()
        this._handleClickOutside = this._handleClickOutside.bind(this)

        this.state = {}
    }

    componentWillUnmount() {
        this._removeEventListenerForClosingDropdownMenuFromAnywhere()
    }

    _handleClickOutside(e) {
        if (!this._mainElementRef.current || !this._mainElementRef.current.contains(e.target)) {
            this._closeOptionsMenu(e)
        }
    }

    _removeEventListenerForClosingDropdownMenuFromAnywhere() {
        document.removeEventListener('click', this._handleClickOutside)
    }

    _addEventListenerForClosingDropdownFromAnywhere() {
        document.addEventListener('click', this._handleClickOutside)
    }

    _closeOptionsMenu(e) {
        this._toggleDropdownOpen(OPEN_FALSE)

        this._removeEventListenerForClosingDropdownMenuFromAnywhere()

        const { onBlur } = this.props
        if (onBlur) {
            onBlur(e)
        }
    }

    _openDropdownMenu(e) {
        if (this.props.onMainContainerClick) {
            this.props.onMainContainerClick(e, this.state.open)
        }

        if (!this.state.open) {
            this._toggleDropdownOpen(OPEN_TRUE)

            this._addEventListenerForClosingDropdownFromAnywhere()
        }
    }

    _toggleDropdownOpen(setOpen) {
        this.setState({ open: setOpen })
    }

    _renderDropdownMenu(disabled, menuRenderer) {
        if (disabled || !this.state.open) {
            return undefined
        }

        const menu = menuRenderer ? menuRenderer() : null // TODO (oletusrenderöinti)

        return (
            <MenuContainer className={MENU_CONTAINER_CLASS} onClick={disabled ? undefined : this._closeOptionsMenu}>
                {menu}
            </MenuContainer>
        )
    }

    /**
     * Without this the dropdown wouldn't close when moving to the next field
     * with tab key.
     * We use specifically keyDown event and not blur event, because blur
     * would also trigger when the dropdown menu is touched.
     */
    closeDropdownWhenMovingToAnotherFieldWithTabKey = (e) => {
        if (e.key === 'Tab') {
            this._closeOptionsMenu(e)
        }
    }

    render() {
        const { changeAction, disabled, label, menuRenderer, textFieldId, translate, width } = this.props
        const labelText = translate ? Translation.translateKey(label) : label

        return (
            <MainContainer
                onClick={disabled ? undefined : this._openDropdownMenu}
                onFocus={disabled ? undefined : this._openDropdownMenu}
                ref={this._mainElementRef}
            >
                <TextInput
                    autoComplete="off"
                    changeAction={changeAction}
                    disabled={disabled}
                    floatingLabelText={labelText}
                    id={textFieldId}
                    onKeyDown={this.closeDropdownWhenMovingToAnotherFieldWithTabKey}
                    value={this.props.value}
                    width={width}
                />
                {this._renderDropdownMenu(disabled, menuRenderer)}
            </MainContainer>
        )
    }
}

Dropdown.displayName = 'Dropdown'

Dropdown.propTypes = {
    changeAction: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    disabled: PropTypes.bool,
    menuRenderer: PropTypes.func,
    label: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onMainContainerClick: PropTypes.func,
    textFieldId: PropTypes.string,
    textInputChange: PropTypes.bool,
    translate: PropTypes.bool,
    value: PropTypes.string,
    width: PropTypes.string,
}

Dropdown.defaultProps = {
    disabled: false,
    translate: false,
}

export default Dropdown
