import {
    asetaAsiakaskohtainenSanasto,
    asetaLocale,
    getAsetukset,
    getLoggedInUser,
    getTyylit,
    setAnyGlobalSettings,
} from './utilility/InitialisingHelper'
import Asetukset from 'constants/Asetukset'
import DateUtility from 'common/DateUtility'
import { fetchNavigationItemsThunk } from '../../../../navigation'
import _Log from 'common/Log'
import URL from 'common/URL'
import { initializeApplicationAction } from './initializeApplicationAction'
import { GLOBAL_SETTING_KEYS } from '../../../../global-settings'
import makeRequest, { ERequestMethod } from '../../../../rest-api'
import ISessio from 'interfaces/ISessio'
import IAsetus from 'interfaces/IAsetus'
import ITyyli from 'interfaces/ITyyli'
import IOhjeinfo from 'interfaces/IOhjeinfo'
import { asyncOperationStartedAction, asyncOperationSucceededAction } from '../../../../async-operation'

const Log = new _Log('ApplicationInitialised')

let applicationInitialisedSuoritettu = false

const settingsToFetch: string[] = [
    Asetukset.LoginsivuLogo,
    Asetukset.PalautteenJattaminenSallittu,
    Asetukset.PalautteenJattaminenNappiNaytetaanToistaiseksi,
    Asetukset.Asiakas,
    Asetukset.Tietosuojaseloste_Linkki,
    Asetukset.ActiveInstructionsEnabled,
    Asetukset.IsMepcoAikaEnabled,
    ...GLOBAL_SETTING_KEYS,
]

const getSession = async (): Promise<ISessio> =>
    makeRequest({
        method: ERequestMethod.GET,
        url: '/Sessio',
    })

const getSettings = async (): Promise<IAsetus[]> => {
    const settingsForQueryString = settingsToFetch.map(
        (setting, index) => `asetukset=${setting}${index !== settingsToFetch.length - 1 ? '&' : ''}`
    )

    return makeRequest({
        method: ERequestMethod.GET,
        url: `/Asetus/Useampi?${settingsForQueryString}`,
    })
}

const getStyles = async (): Promise<ITyyli[]> =>
    makeRequest({
        method: ERequestMethod.GET,
        url: '/Tyyli',
    })

const getInstructionFiles = async (): Promise<IOhjeinfo[]> =>
    makeRequest({
        method: ERequestMethod.GET,
        url: '/Tiedosto/ohjeinfot',
    })

const getLogoutUrl = async (): Promise<string> =>
    makeRequest({
        method: ERequestMethod.GET,
        url: '/Login/LogoutRedirectUrl',
    })

/**
 * Thunk that is dispatched only once when the application is launched.
 * Fetches all the required data for the application to work. Also sets the locale as defined
 * by the user and loads up any possible client specific translations.
 */
// NOTE: can't use IThunkBaseAction here because of circular dependencies
const applicationInitialised =
    (reInitialize = false) =>
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async (dispatch: (action: any) => void) => {
        if (applicationInitialisedSuoritettu && !reInitialize) {
            Log.debug('Not dispatching applicationInitialised since it was already dispatched')
            return
        }

        applicationInitialisedSuoritettu = true

        dispatch(asyncOperationStartedAction('legacy.ApplicationInitialized'))

        DateUtility.setLocale('fi') //Locale voi olla aluksi nopalla heitetty, joten valitaan tässä suomi

        Log.debug('Making requests to initialise the application')

        try {
            const [sessio, planierSettingsFromServer, stylesFromServer, instructions, logoutUrl] = await Promise.all([
                getSession(),
                getSettings(),
                getStyles(),
                getInstructionFiles(),
                getLogoutUrl(),
                dispatch(fetchNavigationItemsThunk()),
            ])

            asetaLocale(sessio.publicInfo.Kieli as number)

            setAnyGlobalSettings(planierSettingsFromServer)
            const contactorSettings = getAsetukset(planierSettingsFromServer)

            asetaAsiakaskohtainenSanasto(contactorSettings)

            const styles = getTyylit(stylesFromServer)
            const loginUser = getLoggedInUser(sessio)

            dispatch(
                initializeApplicationAction({
                    sisaanKirjautunutKayttaja: loginUser,
                    tyylit: styles,
                    ohjeet: instructions,
                    asetukset: contactorSettings,
                    logoutUrl,
                })
            )

            dispatch(asyncOperationSucceededAction('legacy.ApplicationInitialized'))
        } catch (e) {
            Log.error('Fetching initial data failed')
            URL.goto(URL.oldClient(''))
        }
    }

export default applicationInitialised

/**
 * HUOM. Käytettäväksi vain testeissä!
 * Resetoi moduulin sisäisesti käyttämän applicationInitialisedSuoritettu-muuttujan
 * arvon, niin että applicationInitialised-funktion kutsuminen onnistuu
 * ongelmitta useamman kerran testeissä.
 */
export const resetVariableapplicationInitialisedSuoritettu = (): void => {
    applicationInitialisedSuoritettu = false
}
