export const legacySupplementaryColors = {
    haahtelaBlueLight: '#54b8e6',
    checkboxGrey: '#a1b4c0',
    opaqueDarkGrey: 'rgba(109, 110, 113, 0.4)',
    blueGrey: '#7a95a6',
    concreteGrey: '#d0dbe2',
    brown: '#b9975b',
    darkBlue: '#026063',
    darkOrange: '#d3734e',
    green: '#00ab84',
    smokeyBlue: '#38a3ce',
    lightBlue: '#72d0f4',
    lightBlueGrey: '#eff6fc',
    lightCyan: '#e0f5ff',
    brightBlue: '#4bbbed',
    lightGreen: '#71ac00',
    lightViolet: '#ab619c',
    orange: '#e87722',
    tyrkkyGreen: '#BDECB6',
    red: '#ee5d7f',
    turquoise: '#5fbecc',
    seaGreen: '#59c8af',
    yellow: '#fdc230',
    basicGrey: '#ccc',
    blackGrey: '#333',
    disabledGrey: '#f9f9f9',
    buttonDisabled: '#dce3e7',
    buttonEnabled: '#7095a6',
    pagePlaceHolderText: '#9f9fa4',
    rowDivider: '#edf0f2',
    rowDividerDark: '#dce3e7',
    columnHeader: '#939398',
    listRowActionDisabledGrey: '#939398',
}

export type LegacySupplementaryColorsOptions = typeof legacySupplementaryColors
