const messagingTranslations = {
    messaging: {
        messages: {
            fi: 'Viestit',
            sv: '',
            en: 'Messages',
            ru: '',
            sk: '',
            pl: '',
        },
        newMessage: {
            fi: 'Uusi viesti',
            sv: '',
            en: 'New Message',
            ru: '',
            sk: '',
            pl: '',
        },
        messageSent: {
            fi: 'Viesti lähetetty',
            sv: '',
            en: 'Message sent',
            ru: '',
            sk: '',
            pl: '',
        },
        commentVisibility: {
            fi: 'Näkyvyys',
            sv: '',
            en: 'Visibility',
            ru: '',
            sk: '',
            pl: '',
        },
        visibilityInternal: {
            fi: 'Sisäinen',
            sv: '',
            en: 'Internal',
            ru: '',
            sk: '',
            pl: '',
        },
        visibilityExternal: {
            fi: 'Ulkoinen',
            sv: '',
            en: 'External',
            ru: '',
            sk: '',
            pl: '',
        },
        visibilityEmployee: {
            fi: 'Työntekijä',
            sv: '',
            en: 'Employee',
            ru: '',
            sk: '',
            pl: '',
        },
        formSend: {
            fi: 'Lähetä',
            sv: '',
            en: 'Send',
            ru: '',
            sk: '',
            pl: '',
        },
        formClose: {
            fi: 'Sulje',
            sv: '',
            en: 'Close',
            ru: '',
            sk: '',
            pl: '',
        },
        showFormButton: {
            fi: 'Kirjoita uusi',
            sv: '',
            en: 'New Message',
            ru: '',
            sk: '',
            pl: '',
        },
        new: {
            fi: 'Uusi',
            sv: '',
            en: 'New',
            ru: '',
            sk: '',
            pl: '',
        },
        title: {
            fi: 'Otsikko',
            sv: '',
            en: 'Title',
            ru: '',
            sk: '',
            pl: '',
        },
        message: {
            fi: 'Viesti',
            sv: '',
            en: 'Message',
            ru: '',
            sk: '',
            pl: '',
        },
        messageContent: {
            fi: 'Viestin sisältö',
            sv: '',
            en: 'Message content',
            ru: '',
            sk: '',
            pl: '',
        },
        messageBig: {
            fi: 'VIESTI',
            sv: '',
            en: 'MESSAGE',
            ru: '',
            sk: '',
            pl: '',
        },
        mobileMessage: {
            fi: 'Mobiiliviesti',
            sv: '',
            en: 'Mobile message',
            ru: '',
            sk: '',
            pl: '',
        },
        announcement: {
            fi: 'Tiedote',
            sv: '',
            en: 'Announcement',
            ru: '',
            sk: '',
            pl: '',
        },
        announcementBig: {
            fi: 'TIEDOTE',
            sv: '',
            en: 'ANNOUNCEMENT',
            ru: '',
            sk: '',
            pl: '',
        },
        sendEmail: {
            fi: 'Lähetä sähköpostiin',
            sv: '',
            en: 'Send via email',
            ru: '',
            sk: '',
            pl: '',
        },
        tabAll: {
            fi: 'Kaikki',
            sv: '',
            en: 'All',
            ru: '',
            sk: '',
            pl: '',
        },
        tabMobileMessages: {
            fi: 'Mobiiliviestit',
            sv: '',
            en: 'Mobile messages',
            ru: '',
            sk: '',
            pl: '',
        },
        tabAnnouncements: {
            fi: 'Tiedotteet',
            sv: '',
            en: 'Announcements',
            ru: '',
            sk: '',
            pl: '',
        },
        comments: {
            fi: 'Kommentit',
            sv: '',
            en: 'Comments',
            ru: '',
            sk: '',
            pl: '',
        },
        writeAnswer: {
            fi: 'Kirjoita vastaus',
            sv: '',
            en: 'Write answer',
            ru: '',
            sk: '',
            pl: '',
        },
        markAsUnread: {
            fi: 'Merkitse lukemattomaksi',
            sv: '',
            en: 'Mark as unread',
            ru: '',
            sk: '',
            pl: '',
        },
        mobileMessageTooltip: {
            fi: 'Jos työntekijällä on käytössä mobiilisovellus, niin viesti lähetetään sovellukseen. Jos työntekijällä ei ole käytössä mobiilisovellusta, niin viesti lähetetään työntekijälle sähköpostilla. Työntekijöillä on mahdollisuus vastata viesteihin vain, jos toimitustapana on mobiilisovellus.',
            sv: '',
            en: 'If employees have the mobile application downloaded the message will be sent to the application. If employees do not have the mobile application the message will be sent via email. Employees can answer the messages only with the mobile application.',
            ru: '',
            sk: '',
            pl: '',
        },
        infoMessageTooltip: {
            fi: 'Työntekijöillä ei ole mahdollisuutta vastata tiedotteisiin. Lähetä sähköpostiin -valinnalla voit lähettää tiedotteen kaikille vastaanottajille sähköpostiin eli työntekijät, joilla on mobiilisovellus ladattuna, saavat tiedotteen mobiilisovelluksen lisäksi sähköpostilla.',
            sv: '',
            en: 'Employees cannot answer info messages. With the “Send to email” checkbox selection the info message can be sent to all employees via email. In this case employees who have the mobile application downloaded will receive the info message to both email and the mobile application.',
            ru: '',
            sk: '',
            pl: '',
        },
        newCommentsDisabled: {
            fi: 'Vuorolle ei voi lisätä uusia kommentteja vuoron alkamisen jälkeen',
            sv: '',
            en: 'New comments cannot be added after the shift has started',
            ru: '',
            sk: '',
            pl: '',
        },
    },
}

export default messagingTranslations
