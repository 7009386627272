import * as React from 'react'
import styled from '@emotion/styled'

import CalendarSeparators from './CalendarSeparators'
import { MIN_COLUMN_WIDTH } from '../../../Constants/CalendarConstants'

interface ICalendarContentProps {
    columnAmount: number
    mainAxisContent: React.ReactNodeArray
}

const MainAxisSegments = styled.div<{ columnAmount: number }>`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: ${({ columnAmount }) => `${columnAmount * MIN_COLUMN_WIDTH.value}${MIN_COLUMN_WIDTH.unit}`};
`

const CalendarContent: React.FunctionComponent<ICalendarContentProps> = ({ columnAmount, mainAxisContent }) => (
    <div>
        <MainAxisSegments columnAmount={columnAmount}>
            <CalendarSeparators amount={columnAmount} />

            {mainAxisContent}
        </MainAxisSegments>
    </div>
)

export default CalendarContent
