import { TimerangeInputV2, ITimerangeValue, ITimeRangeValidationError } from '../../generic-components'
import { IValuePickerCommonOwnProps } from '../../value-picker'

interface ITimeRangePickerProps extends IValuePickerCommonOwnProps<ITimerangeValue> {}

const ITimeRangePicker: React.FC<ITimeRangePickerProps> = ({
    required,
    value,
    onChange,
    errors,
    valuePickerId,
    over24hHidden,
}) => {
    const typedError = errors as ITimeRangeValidationError
    return (
        <TimerangeInputV2
            required={required}
            value={value}
            errors={typedError}
            onChange={onChange}
            valuePickerId={valuePickerId}
            over24hHidden={over24hHidden}
        />
    )
}

export default ITimeRangePicker
