export default {
    'asx-laheta-hotline-viesti': { fi: 'Lähetä Hotline-viesti Seurelle', sv: '', en: '', ru: '', sk: '', pl: '' },
    'crm-palaute-koskee-valittaja': { fi: 'Seurea', sv: 'Seure', en: 'Seure', ru: '', sk: '', pl: '' },
    kommentti: { fi: 'Kommentit Seurelle', sv: '', en: '', ru: '', sk: '', pl: '' },
    'metaselitys-asiakas-ei-saa-ehdottaa-tesn-tilausta': {
        fi: 'Tilauksille jotka ovat yli 16 vrk pitkiä ei voi ehdottaa työntekijöitä asiakkaan extranetistä.',
        sv: '',
        en: '',
        ru: '',
        sk: '',
        pl: '',
    },
    'crm-tiketti-teksti-placeholder-employee': {
        fi:
            'Tästä voit jättää palautetta Seureen. Voit jättää meille palautetta liittyen työkohteeseen, työvuoroon tai Seuren toimintaan.\n\nMuistathan kuitenkin, että muokkausehdotukset työvuoroihin tehdään Tehdyt työvuorot-kohdan muokkaa-painikkeen kautta. Näin voit ilmoittaa työvuoron muuttuneen alkamis- tai päättymisajan tai syömäsi aterian. Mikäli työkohteessa on jo ehditty hyväksyä työvuorosi virheellisillä tiedoilla, ilmoita siitä meille hotline-viestillä.\n\nMuistathan olla akuuteissa asioissa yhteydessä puhelimitse! Ajantasaiset puhelinnumerot löydät Yhteystiedot-pikalinkistä.\n\nKiitos jo etukäteen arvokkaasta palautteestasi!',
        sv: '',
        en: '',
        ru: '',
        sk: '',
        pl: '',
    },
}
