import { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { Button, Checkbox, Icon, NumberInput, ReadOnlyText } from '../../../generic-components'

import makeRequest, { createCancelToken, ERequestMethod } from '../../../rest-api'
import { Dropdown } from '../../../generic-components-dropdown'

import { Grid } from '@material-ui/core'
import fetchTransactionPricing, { fetchInvoicingRoundingPrecision } from './FetchTransactionPricing'
import { usePriceDependencies } from './usePricingDependencies'
import IPricingDependencyConfiguration from './IPricingDependencyConfiguration'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import { CancelTokenSource } from 'axios'
import { round } from '../../../generic-utilities'

interface IPrice {
    totalPrice: number
    pricingBasisId: number
    basisPrice: number
    coefficient: number
    billingEnabled: boolean
}

const GridWithMargin = styled(Grid)`
    margin-right: 25px;
`

const GridWithSmallMargin = styled(Grid)`
    margin-right: 8px;
`

const StyledContainerGrid = styled(Grid)``

const EditContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.primaryChampion11};
    padding: 12px 56px 12px 32px;
    margin: 8px -56px 12px -32px;
`

interface ITransactionPricePickerProps {
    onChange: (value: IPrice) => void
    value: IPrice | null
    dependencyConfiguration: IPricingDependencyConfiguration
    error: string
    isInitialValueSet: boolean
}

interface IPricingBasisIdentifier {
    Id: number
    Name: string
}

interface IPricingRequestResponse {
    ListData: IPricingBasisIdentifier[]
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const OtherPricingBasisId = 6
const DefaultRoundingPrecision = 2

const TransactionPricePicker: React.FC<ITransactionPricePickerProps> = ({
    value,
    isInitialValueSet,
    onChange,
    dependencyConfiguration,
}) => {
    const dependencyValues = usePriceDependencies(dependencyConfiguration)

    const [isEditOpen, setIsEditOpen] = useState<boolean>(false)

    const [isIdentifierRequestLoading, setIsIdentifierRequestLoading] = useState<boolean>(false)
    const [isNewPriceFetchLoadng, setIsNewPriceFetchLoadng] = useState<boolean>(false)

    const [roundingPrecision, setRoundingPrecision] = useState<number>(DefaultRoundingPrecision)

    const [pricingBasisIdentifiers, setpricingBasisIdentifiers] = useState<IPricingBasisIdentifier[]>([])

    const isLoading = isNewPriceFetchLoadng || isIdentifierRequestLoading

    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>()

    useEffect(() => {
        ;(async () => {
            const precision = await fetchInvoicingRoundingPrecision()

            if (precision) {
                setRoundingPrecision(precision)
            }
        })()
    }, [])

    const fetchPricingData = () => {
        if (isInitialValueSet) {
            return
        }

        const fetchData = async () => {
            setIsNewPriceFetchLoadng(true)

            if (cancelTokenSource) {
                cancelTokenSource.cancel()
            }

            const newTokenSource = createCancelToken()

            setCancelTokenSource(newTokenSource)

            const transactionPrice = await fetchTransactionPricing(dependencyValues, newTokenSource.token)

            setIsNewPriceFetchLoadng(false)

            if (transactionPrice) {
                const newValue = {
                    pricingBasisId: transactionPrice.billingBasisId ?? OtherPricingBasisId,
                    totalPrice: transactionPrice.billingPrice ?? 0,
                    basisPrice: transactionPrice.billingBasePrice ?? 0,
                    coefficient: transactionPrice.billingCoefficient ?? 0,
                    billingEnabled: transactionPrice.isBillingEnabled ?? false,
                }

                onChange(newValue)
            }
        }

        fetchData()
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchPricingData()
    }, [dependencyValues])

    const createTotalPriceWithCorrectPrecision = (coefficient: number, basisPrice: number) => {
        return round(coefficient * basisPrice, roundingPrecision)
    }

    const onPriceCoefficientChange = (newValue: number) => {
        const newTotalValue = {
            pricingBasisId: OtherPricingBasisId,
            totalPrice: createTotalPriceWithCorrectPrecision(newValue, value?.basisPrice ?? 0),
            basisPrice: value?.basisPrice ?? 0,
            coefficient: newValue,
            billingEnabled: value?.billingEnabled ?? false,
        }

        onChange(newTotalValue)
    }

    const onBillingEnabledChange = () => {
        const newTotalValue = {
            totalPrice: value?.totalPrice ?? 0,
            pricingBasisId: value?.pricingBasisId ?? OtherPricingBasisId,
            basisPrice: value?.basisPrice ?? 0,
            coefficient: value?.coefficient ?? 0,
            billingEnabled: !value?.billingEnabled,
        }

        onChange(newTotalValue)
    }

    const onBasisPriceChange = (newValue: number) => {
        const newTotalValue = {
            pricingBasisId: OtherPricingBasisId,
            totalPrice: createTotalPriceWithCorrectPrecision(value?.coefficient ?? 0, newValue),
            basisPrice: newValue,
            coefficient: value?.coefficient ?? 0,
            billingEnabled: value?.billingEnabled ?? false,
        }

        onChange(newTotalValue)
    }

    const onPricingBasisChange = (newValue: number) => {
        const newTotalValue = {
            pricingBasisId: newValue,
            totalPrice: createTotalPriceWithCorrectPrecision(value?.coefficient ?? 0, value?.basisPrice ?? 0),
            basisPrice: value?.basisPrice ?? 0,

            //If we change the pricing basis to users input value, change the coefficient to 1
            coefficient: newValue === OtherPricingBasisId ? 1 : value?.coefficient ?? 0,
            billingEnabled: value?.billingEnabled ?? false,
        }

        onChange(newTotalValue)
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsIdentifierRequestLoading(true)

            try {
                const pricingResponse = await makeRequest<IPricingRequestResponse>({
                    url: 'palkka/Laskutusperuste/Identifier',
                    method: ERequestMethod.POST,
                    data: {},
                })

                if (pricingResponse && pricingResponse.ListData) {
                    setpricingBasisIdentifiers(pricingResponse.ListData)
                }
            } finally {
                setIsIdentifierRequestLoading(false)
            }
        }

        fetchData()
    }, [])

    const internalOnPricingBasisChange = (newValue: Set<number>) => {
        if (newValue.size !== 0) {
            const valueToSelect = newValue.values().next().value
            onPricingBasisChange(valueToSelect)
        }
    }

    const editIcon = isEditOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'

    return (
        <Container>
            <StyledContainerGrid container paddingBottom="12px" alignItems="flex-end">
                <GridWithMargin item>
                    <NumberInput value={value?.totalPrice ?? 0} label={'Laskutus'} unit={'€'} disabled={true} />
                </GridWithMargin>
                <GridWithMargin paddingBottom="8px" item>
                    <Button variant="text" onClick={() => setIsEditOpen(!isEditOpen)} endIcon={editIcon}>
                        Muokkaa
                    </Button>
                </GridWithMargin>
                <GridWithMargin paddingBottom="8px" item>
                    <LoadingIndicatorInline disableShrink isLoading={isLoading} paddingLeft="0" size="25px" />
                </GridWithMargin>
            </StyledContainerGrid>
            <StyledContainerGrid container alignItems="center">
                <GridWithSmallMargin item>
                    <Checkbox value={value?.billingEnabled ?? false} onClick={onBillingEnabledChange} />
                </GridWithSmallMargin>
                <GridWithSmallMargin item>
                    <ReadOnlyText usage={'dropdown_label'}>Laskutus hyväksytty</ReadOnlyText>
                </GridWithSmallMargin>
                <GridWithSmallMargin item>
                    <Icon
                        size={14}
                        colorTheme={'primary'}
                        tooltip={
                            'Poistamalla laskutuksen hyväksynnän, toteumarivin laskumäärä käsitellään nollana sen rivin laskutuksen arvosta riippumatta'
                        }
                    >
                        info_outlined
                    </Icon>
                </GridWithSmallMargin>
            </StyledContainerGrid>
            {isEditOpen ? (
                <EditContainer>
                    <div style={{ paddingBottom: '12px' }}>
                        <Dropdown<IPricingBasisIdentifier>
                            defaultText={'Hinnoittelutapa'}
                            itemOptionLabelFields={['Name']}
                            itemValueLabelFields={['Name']}
                            textForMultipleSelected={''}
                            itemIdField={'Id'}
                            required={false}
                            disabled={true}
                            isLoading={isIdentifierRequestLoading}
                            multiselect={false}
                            selectAction={internalOnPricingBasisChange}
                            items={pricingBasisIdentifiers}
                            values={new Set(value ? [value.pricingBasisId] : [OtherPricingBasisId])}
                            valueFieldType={'form'}
                        />
                    </div>
                    <StyledContainerGrid alignItems="flex-end" container>
                        <GridWithSmallMargin item>
                            <NumberInput
                                value={value?.basisPrice ?? 0}
                                label={'Määräytyminen'}
                                disabled={isLoading}
                                fireChangeOnBlur={true}
                                onChange={onBasisPriceChange}
                                unit={'€'}
                            />
                        </GridWithSmallMargin>
                        <GridWithSmallMargin item paddingBottom="14px">
                            <ReadOnlyText usage="h4">X</ReadOnlyText>
                        </GridWithSmallMargin>
                        <GridWithSmallMargin item>
                            <NumberInput
                                value={value?.coefficient ?? 0}
                                label={''}
                                disabled={isLoading}
                                fireChangeOnBlur={true}
                                onChange={onPriceCoefficientChange}
                            />
                        </GridWithSmallMargin>
                        <GridWithSmallMargin item paddingBottom="14px">
                            <ReadOnlyText usage="h4">=</ReadOnlyText>
                        </GridWithSmallMargin>
                        <GridWithSmallMargin item>
                            <NumberInput value={value?.totalPrice ?? 0} label={''} disabled={true} unit={'€'} />
                        </GridWithSmallMargin>
                    </StyledContainerGrid>
                </EditContainer>
            ) : null}
        </Container>
    )
}

export default TransactionPricePicker
