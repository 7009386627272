import { isEqual } from 'lodash-es'

import AbstractTitle, { IAbstractTitleProps } from 'components/atoms/AbstractTitle'
import Styles from 'constants/Styles'

/**
 * 3. tason otsikko.
 *
 * Käytä sivun sisällä. Huomaa, että voit käyttää myös vastaavan tasoista
 * Label-komponenttia esimerkiksi kenttien otsikkona.
 */
export default class SubHeading extends AbstractTitle<IAbstractTitleProps> {
    static readonly displayName = 'SubHeading'

    constructor(props: IAbstractTitleProps) {
        super(props)
    }

    shouldComponentUpdate(nextProps: IAbstractTitleProps): boolean {
        return !isEqual(this.props, nextProps)
    }

    protected styles(): any {
        const classes = super.styles()
        classes.heading.fontSize = Styles.font.levelThreeTitle.size
        classes.icon.fontSize = Styles.font.levelThreeTitle.size
        classes.subHeading.fontSize = Styles.font.levelThreeTitle.size
        return classes
    }
}
