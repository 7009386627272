import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import moment, { Moment, MomentInput } from 'moment'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'

import TimeInput from 'components/molecules/TimeInput'
import Label from 'components/atoms/Label'
import { isNullableDateTimeRange } from '../../../generic-utilities'
import { selectValuePickerValue, setValuePickerValueAction, IPageTimeRangePickerOwnProps } from '../../../value-picker'
import { getTestId } from '../../../test'

export interface IPageTimeRangePickerProps extends IPageTimeRangePickerOwnProps {}

const Hyphen = styled.div`
    display: flex;
    align-items: flex-end;
    padding-bottom: 6px;
`

const ContainerGrid = styled(Grid)`
    max-width: 200px;
`

const TIME_PICKER_WIDTH = '88px'

const PageTimeRangePicker = ({ text, valuePickerId }: IPageTimeRangePickerProps): JSX.Element => {
    const dispatch = useDispatch()

    const pickerValue = useSelector((state: RootState) => selectValuePickerValue(state, valuePickerId))
    const { end: endTime, start: startTime } = isNullableDateTimeRange(pickerValue)
        ? pickerValue
        : {
              start: null,
              end: null,
          }

    const [startTimeState, setStartTime] = useState<Moment | string | null>(startTime)
    const [endTimeState, setEndTime] = useState<Moment | string | null>(endTime)

    const setValuePickerValue = (value: any) => dispatch(setValuePickerValueAction(value, valuePickerId))

    const handleEndTimeSelection = (newTime: Moment | string): void => {
        setEndTime(newTime)

        // Update the global state only when the user removes the chosen time
        // or when it is a valid Moment object.
        if (newTime === '' || moment.isMoment(newTime)) {
            setValuePickerValue({
                start: startTime,
                end: newTime ? newTime : null,
            })
        }
    }

    const handleStartTimeSelection = (newTime: Moment | string): void => {
        setStartTime(newTime)

        if (newTime === '' || moment.isMoment(newTime)) {
            setValuePickerValue({
                start: newTime ? newTime : null,
                end: endTime,
            })
        }
    }

    const testId = getTestId(['VALUE_PICKER'], valuePickerId)

    return (
        <ContainerGrid container data-testid={testId}>
            {text.Label && <Label doNotTranslate>{text.Label}</Label>}
            <Grid item>
                <TimeInput
                    changeAction={handleStartTimeSelection}
                    description={text.StartPlaceholder}
                    rawTimeValue={startTimeState as MomentInput}
                    translate={false}
                    width={TIME_PICKER_WIDTH}
                />
            </Grid>
            <Hyphen>&nbsp; - &nbsp;</Hyphen>
            <Grid item>
                <TimeInput
                    changeAction={handleEndTimeSelection}
                    description={text.EndPlaceholder}
                    rawTimeValue={endTimeState as MomentInput}
                    translate={false}
                    width={TIME_PICKER_WIDTH}
                />
            </Grid>
        </ContainerGrid>
    )
}

export default PageTimeRangePicker
