import IDataSourceConfiguration from '../Types/IDataSourceConfiguration'

const tyovuorolistapakettiDataSourceMetadata: IDataSourceConfiguration = {
    Id: 'TyovuorolistapakettiDataSource',
    Actions: [],
    Url: '',
    Properties: [],
    DependsOn: [],
}

export default tyovuorolistapakettiDataSourceMetadata
