import * as React from 'react'
import styled from '@emotion/styled'
import { range } from 'lodash-es'

interface ISeparatorProps {
    amount: number
}

const SeparatorContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const Separator = styled.div`
    visibility: ${({ first }: { first: boolean }) => (first ? 'hidden' : 'visible')};

    flex-grow: 1;

    &::after {
        position: absolute;
        content: '';

        width: 0;
        height: 100%;

        /* 1px borders are buggy on Chrome and not visible on some zoom levels. */
    }
`

const CalendarSeparators: React.FunctionComponent<ISeparatorProps> = ({ amount }) => {
    return (
        <SeparatorContainer>
            {range(amount).map((orderNumber, index) => (
                <Separator first={index === 0} key={orderNumber} />
            ))}
        </SeparatorContainer>
    )
}

export default CalendarSeparators
