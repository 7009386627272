import { round } from '../../../generic-utilities'
import moment, { Moment } from 'moment'
import {
    createOpened,
    cursorMovedDown,
    cursorMovedLeft,
    cursorMovedRight,
    cursorMovedUp,
    editOpened,
    executeClipboardActionThunk,
    selectedCellsSetToClipboardForCopy,
    selectedCellsSetToClipboardForCut,
    selectionCleared,
} from '../State/CalendarState'
import { IDataSourceGroupData, IDataSourceWindowFunctionData } from '../../../data-source-types'
import DateConstants from 'constants/DateConstants'
import {
    EventClassId,
    ICalendarNodeContent,
    IDataSourceReponse,
    IEventModel,
    IEventModelRaw,
    IFormattedCalendarData,
    IGroupData,
    IGroupingConfiguration,
    IGroupProperties,
    IMetrics,
    IWorkUnitMetrics,
} from '../Types/types'

import { editCompletedThunk } from '../State/CalendarThunks'
import { get } from 'lodash-es'

export const chooseActionForKeyboardEvent = (event: KeyboardEvent): unknown => {
    if (event.key === 'ArrowUp') {
        return cursorMovedUp()
    } else if (event.key === 'ArrowDown') {
        return cursorMovedDown()
    } else if (event.key === 'ArrowRight') {
        return cursorMovedRight()
    } else if (event.key === 'ArrowLeft') {
        return cursorMovedLeft()
    } else if (event.key === 'c' && (event.ctrlKey || event.metaKey)) {
        return selectedCellsSetToClipboardForCopy()
    } else if (event.key === 'x' && (event.ctrlKey || event.metaKey)) {
        return selectedCellsSetToClipboardForCut()
    } else if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
        return executeClipboardActionThunk()
    } else if (event.key === 'Escape') {
        return selectionCleared()
    } else if (event.key === 'e') {
        return editOpened()
    } else if (event.key === 'c') {
        return createOpened()
    } else if (event.key === 'Enter') {
        return editCompletedThunk()
    } else {
        return null
    }
}

export const getGroupRepresentativeEventForGivenDay = (group: IGroupData, day: Moment): IEventModel => {
    const groupRepresentativeEvent = {
        EventClassId: EventClassId.Shift,
        WorkUnit: group.groupProperties.workunitId
            ? {
                  Id: group.groupProperties.workunitId ?? '',
                  Name: group.groupProperties.workunitName ?? '',
              }
            : undefined,
        Employee: group.groupProperties.employeeId
            ? {
                  Id: group.groupProperties.employeeId ?? '',
                  Name: group.groupProperties.employeeName ?? '',
              }
            : undefined,
        JobTitle: group.groupProperties.jobTitleId
            ? {
                  Id: group.groupProperties.jobTitleId ?? '',
                  Name: group.groupProperties.jobTitleName ?? '',
                  Lyhenne: '',
              }
            : undefined,
        HasEmployee: !!group.groupProperties.employeeId,
        IsPublished: false,
        Info: '',
        IsRecurringEvent: false,
        ColorId: 'event_base',
        StartTime: day.clone(),
        EndTime: day.clone(),
        EventType: {
            Id: -1,
            Name: 'Vuoro',
        },
        Skills: [],
        WorkTimeRuleViolations: [],
        Id: 'empty',
        Kayttooikeudet: [],
    }

    return groupRepresentativeEvent
}

export const getNodeWidthStyle = (length: number): string => `${length}%`

export const getNodeLeftStyle = (indent: number): string => `${indent}%`

export const calculateOnePortionOfTotal = (totalQuantity: number): number => calculatePortionOfTotal(1, totalQuantity)

export const calculatePortionOfTotal = (portion: number, totalQuantity: number): number =>
    totalQuantity > 0 ? round((100 * portion) / totalQuantity, 5) : 100

export const calculateRatio = (input: number, total: number): number => Math.round(total / input)

const STRICT_DATE_PARSE = true

export const parseDatetimeFromBackend = (dateTime: string | Moment): Moment =>
    moment(dateTime, DateConstants.BACKEND_DATE_FORMAT, STRICT_DATE_PARSE)

const enrichApiResponseModel = (input: IEventModelRaw): IEventModel => {
    return {
        ...input,
        StartTime: parseDatetimeFromBackend(input.StartTime),
        EndTime: parseDatetimeFromBackend(input.EndTime),
    }
}

const eventClassToColorId = (eventClassId: number) => {
    switch (eventClassId) {
        case EventClassId.Shift:
            return 'event_base'
        case EventClassId.Block:
            return 'event_block'
        case EventClassId.Wish:
            return 'event_wish'
        case EventClassId.AssignableOther:
        case EventClassId.Other:
        default:
            return 'event_other'
    }
}
export const createCalendarContentItem = (
    rawItem: IEventModelRaw,

    calendarStart: Moment,
    calendarEnd: Moment
): ICalendarNodeContent | undefined => {
    const item = enrichApiResponseModel(rawItem)

    const infoText = item.Info
    const startValue = item.StartTime.clone()
    const endValue = item.EndTime.clone()

    //shifts can override base colorId
    const colorId =
        item.ColorId && item.EventClassId === EventClassId.Shift ? item.ColorId : eventClassToColorId(item.EventClassId)

    const startDate = startValue.startOf('day')

    const endDate = endValue.startOf('day')

    const indent = calculateNodeIndent(calendarStart, calendarEnd, startDate)

    const length = calculateNodePercentageLength(calendarStart, calendarEnd, startDate, endDate)

    const node: ICalendarNodeContent = {
        id: item.Id as string,
        day: startDate,
        type: 'event',
        width: length,
        info: infoText,
        indent: indent,
        colorId: colorId ?? 'event_base',
        isDisabled: item.Disabled ?? false,
        isSelectable: item.Disabled !== false,
        data: item,
    }

    return node
}

const calculateNodeIndent = (calendarStart: Moment, calendarEnd: Moment, nodeStart: Moment): number => {
    if (!calendarStart.isValid() || !calendarEnd.isValid() || !nodeStart.isValid()) {
        return 0
    }

    if (calendarEnd.isSameOrBefore(calendarStart)) {
        return 0
    }

    //Calendar end is the last date.
    const hoursCalendar = calendarEnd.diff(calendarStart, 'hours') + 24

    const daysCalendar = Math.round(hoursCalendar / 24)

    const diffFromCalendarStart = nodeStart.diff(calendarStart, 'days')

    const percentageIndent = calculatePortionOfTotal(diffFromCalendarStart, daysCalendar)

    return percentageIndent
}

export const constructGroupsXAxisMap = (
    xAxisSize: number,
    nodes: ICalendarNodeContent[],
    sizeOfBlock: number
): string[] => {
    const indexArray: string[] = []

    let nodeIndex = 0

    const allowedOverlapIndent = 0.01

    for (let xCoord = 0; xCoord < xAxisSize; xCoord = xCoord + 1) {
        const nextNode = nodes.length > nodeIndex ? nodes[nodeIndex] : undefined

        if (nextNode && nextNode.indent + allowedOverlapIndent < (xCoord + 1) * sizeOfBlock) {
            nodeIndex = nodeIndex + 1
            indexArray.push(nextNode.id)
        } else {
            indexArray.push('null')
        }
    }

    return indexArray
}

const processDataSourceGroupByProperties = (
    input: { Property: string; Value: string }[],
    groupProperties: IGroupProperties
) => {
    input.forEach((field) => {
        const fieldProperty = field.Property.toLocaleLowerCase()
        if (fieldProperty === 'workunitid') {
            groupProperties.workunitId = parseInt(field.Value)
        }
        if (fieldProperty === 'jobtitleid') {
            groupProperties.jobTitleId = parseInt(field.Value)
        }
        if (fieldProperty === 'employeeid') {
            groupProperties.employeeId = parseInt(field.Value)
        }
    })

    return groupProperties
}

const findGroupProperties = (input: unknown, groupProperties: IGroupProperties) => {
    const jobtitleName = get(input, 'JobTitleName')
    const workunitName = get(input, 'WorkUnitName')
    const employeeName = get(input, 'EmployeeName')

    if (jobtitleName) {
        groupProperties.jobTitleName = jobtitleName
    }

    if (workunitName) {
        groupProperties.workunitName = workunitName
    }

    if (employeeName) {
        groupProperties.employeeName = employeeName
    }
}

export const processSubGroupMetrics = (group: IDataSourceGroupData): IWorkUnitMetrics => {
    const metrics =
        group.SubGroupByData?.map((c) => c).filter((subGroup) => subGroup.Target === 'WorkUnitDates') ??
        ([] as IDataSourceGroupData[])
    return {
        workUnitId: group.Data?.WorkUnitId as string,
        metrics,
    }
}

export const processGroup = (
    group: IDataSourceGroupData,
    parentGroup: IGroupData | null,
    groupConfig: IGroupingConfiguration
): IGroupData[] => {
    const returnValues: IGroupData[] = []
    if (group.GroupByProperties.find((x) => x.Value === null)) {
        return []
    }

    const groupKeyValuePairs = group.GroupByProperties.map((x) => {
        return { Property: x.Property, Value: x.Value as string }
    })

    const groupProperties: IGroupProperties = parentGroup ? parentGroup.groupProperties : {}
    processDataSourceGroupByProperties(groupKeyValuePairs, groupProperties)
    findGroupProperties(group.Data, groupProperties)

    const currentGroup: IGroupData = {
        type: groupConfig.type,
        itemIds: group.DataItemIds ? (group.DataItemIds as string[]) : null,
        displayGroupData: true,
        displayType:
            groupKeyValuePairs.length === 0 || group.Target === 'WorkUnitDates' ? 'None' : groupConfig.displayType,
        groupProperties: groupProperties,
        infoText: group.Data?.InfoText as string,
        metrics: [
            ...group.CustomFunctionData.map((x) => {
                return { value: x.Value as string, unit: x.Unit, label: x.Label }
            }),
            ...group.WindowFunctionData.map((x) => {
                return { value: x.Value as string, unit: x.Unit, label: x.Label }
            }),
            ...(group.Data && group.Data.CounterData
                ? (group.Data.CounterData as string[]).map((x) => {
                      return { value: x, unit: '', label: '' }
                  })
                : []),
        ],
    }

    returnValues.push(currentGroup)

    if (group.SubGroupByData && groupConfig.subGroup) {
        group.SubGroupByData.forEach((subGroup) => {
            const subGroupValues = processGroup(
                subGroup,
                JSON.parse(JSON.stringify(currentGroup)),
                groupConfig.subGroup as IGroupingConfiguration
            )

            returnValues.push(...subGroupValues)
        })
    }

    return returnValues
}

export const formatDataSourceResponseToCalendarContent = (
    sizeX: number,
    calendarStart: Moment,
    calendarEnd: Moment,
    datasourceResponse: IDataSourceReponse,
    groupingConfiguration: IGroupingConfiguration
): IFormattedCalendarData => {
    const groups: IGroupData[] = []
    const metrics: IMetrics = { overallMetrics: [], workUnitMetrics: [] }

    const itemMap = new Map<string, ICalendarNodeContent>()

    const items: ICalendarNodeContent[] = []

    datasourceResponse.ListData.forEach((item) => {
        const calendarItem = createCalendarContentItem(item, calendarStart, calendarEnd)

        if (calendarItem) {
            itemMap.set(calendarItem.id, calendarItem)
            items.push(calendarItem)
        }
    })

    datasourceResponse.GroupingResponse.forEach((group) => {
        if (group.Target === 'OverallDates') {
            metrics.overallMetrics.push(...processGroup(group, null, groupingConfiguration))
        } else {
            groups.push(...processGroup(group, null, groupingConfiguration))
        }
        if (group.Target === 'WorkUnits') {
            metrics.workUnitMetrics.push(processSubGroupMetrics(group))
        }
    })

    const globalNonOverlappingGroups: IGroupData[] = []

    const blockSize = calculatePortionOfTotal(1, sizeX)

    const idMap: string[][] = []

    const totalGroup = datasourceResponse.GroupingResponse.find((x) => x.Target === null)
        ?.WindowFunctionData as IDataSourceWindowFunctionData[]

    groups.forEach((group) => {
        if (group.displayType === 'MetricsOnly') {
            globalNonOverlappingGroups.push(group)

            const xAxisMap = constructGroupsXAxisMap(sizeX, [], blockSize)

            idMap.push(xAxisMap)
        } else if (group.displayType !== 'None') {
            const groupsItems = (group.itemIds ?? [])
                .map((id) => itemMap.get(id))
                .filter((x) => x) as ICalendarNodeContent[]

            const _groupsItems =
                group.displayType === 'EmptyEventsOnly' ? groupsItems.filter((x) => !x.data.HasEmployee) : groupsItems

            const nonOverlappingGroups = constructNonOverlappingNodeGroups(_groupsItems)

            nonOverlappingGroups.forEach((nonOverlappingGroup, index) => {
                const newIds = nonOverlappingGroup.map((x) => x.id)

                const newGroup = {
                    ...group,
                    itemIds: newIds,
                    displayGroupData: index === 0,
                }

                globalNonOverlappingGroups.push(newGroup)

                const xAxisMap = constructGroupsXAxisMap(sizeX, nonOverlappingGroup, blockSize)

                idMap.push(xAxisMap)
            })
        }
    })

    return {
        startDate: calendarStart,
        endDate: calendarEnd,
        idMap,
        sizeY: globalNonOverlappingGroups.length,
        items,
        groups: globalNonOverlappingGroups,
        metrics,
        sizeX,
        totalGroup,
    }
}

export const constructNonOverlappingNodeGroups = (nodes: ICalendarNodeContent[]): ICalendarNodeContent[][] => {
    // Copy the array before sorting in order not to mutate arguments.
    const sortedNodes = [...nodes].sort((node, nextNode) => node.indent - nextNode.indent)

    const itemGroups: ICalendarNodeContent[][] = []

    let currentItemGroupIndex = 0

    if (sortedNodes.length === 0) {
        return [[]]
    }

    sortedNodes.forEach((currentNode, index) => {
        if (!Array.isArray(itemGroups[currentItemGroupIndex])) {
            itemGroups.push([])
        }

        const currentItemGroup = itemGroups[currentItemGroupIndex]
        const isFirstNode = index === 0

        if (isFirstNode) {
            currentItemGroup.push(currentNode)
            return
        }

        const currentNodeStart = currentNode.indent

        const acceptableDiff = 0.01

        // Find the first item group where the current node can be placed
        for (const itemGroup of itemGroups) {
            const lastNodeOfGroup = itemGroup[itemGroup.length - 1]
            const lastNodeEnd = lastNodeOfGroup.indent + lastNodeOfGroup.width

            const deltaEndStart = lastNodeEnd - currentNodeStart
            if (deltaEndStart - acceptableDiff < 0) {
                itemGroup.push(currentNode)
                return
            }
        }

        // When the current node fits no existing item group, create
        // a new group.
        itemGroups.push([currentNode])
        currentItemGroupIndex++
    })

    return itemGroups
}

const calculateNodePercentageLength = (
    calendarStart: Moment,
    calendarEnd: Moment,
    startValue: Moment,
    endValue: Moment
): number => {
    if (!calendarStart.isValid() || !calendarEnd.isValid() || !startValue.isValid() || !endValue.isValid()) {
        return 0
    }

    const hoursCalendar = calendarEnd.diff(calendarStart, 'hours') + 24

    const daysCalendar = Math.round(hoursCalendar / 24)

    const hoursNode = endValue.diff(startValue, 'hours')

    //Exactly 24h shift is still one day
    const daysNode = hoursNode % 24 === 0 ? 1 : Math.round(hoursNode / 24)

    const percentageLength = calculatePortionOfTotal(daysNode, daysCalendar)

    return percentageLength
}
