/**
 * Contains translation keys for the titles of each page recognized
 * by their paths
 */
export default {
    '/raportit': 'raportit',
    '/asx/tilaukset': 'tilaukset',
    '/asx/tyontekijat': 'asx-tyontekijat',
    '/asx/vuorot': 'vuorot-ja-vapaat',
    '/asx/yksikontiedot': 'asx-yksikontiedot-otsikko',
    '/asx/viestit': 'viestit',
    '/asx/uutiset': 'asx-uutiset-otsikko',
    '/crm/asiakkaanyhteyshenkilot': 'crm-asiakkaan-yhteyshenkilot-otsikko',
    '/crm/asiakkaanyhteyshenkilot/:asiakas?': 'crm-asiakkaan-yhteyshenkilot-otsikko',
    '/crm/tagihallinta': 'tagihallinta',
    '/crm/tiketit': 'crm-tiketit-otsikko',
    '/crm/tiketit/:id?': 'crm-tiketit-otsikko',
    '/crm/yhteyshenkilot': 'crm-yhteyshenkilot',
    '/crm/yhteyshenkilot/:yhteyshenkilo?': 'crm-yhteyshenkilot',
    '/integrations/siirrot': 'int-siirrot-otsikko',
    '/integrations/yhteyskumppanit': 'int-yhteyskumppanit-otsikko',
    '/palkkahallinto/vuorotjavapaat': 'palkkahallintopage-vuorot-ja-vapaat',
    '/payrolladministration/events': 'palkkahallintopage-vuorot-ja-vapaat',
    '/palkkahallinto/toteuma': 'palkkahallintopage-toteuma',
    '/palkkahallinto/toteumat': 'palkkahallintopage-toteuma',
    '/osiokohtaisetasetukset/:osionId': 'OsiokohtaisetAsetukset.otsikko',
    '/tyovuorolistapaketti': 'Tyovuorolistapaketti.title',
    '/yllapito/poikkeusloki': 'yllapito-poikkeuslokiselain',
}
