import * as React from 'react'
import modernizr from 'modernizr'
import styled from '@emotion/styled'

import { TDataSourceId } from '../Types/IDataSource'
import AggregatedDataRow from './AggregatedDataRow'

interface IAggregatedDataOnPageBottomProps {
    dataSourceId: TDataSourceId
}

const StickyAggregatedDataRow = styled(AggregatedDataRow)`
    position: sticky;
    bottom: 0;
    height: 40px;
    background-color: #f5f7fb;
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    overflow: hidden;
    padding-left: 23px;
`

const AggregatedDataOnPageBottom: React.FC<IAggregatedDataOnPageBottomProps> = ({ dataSourceId }) => {
    // for now since position: sticky doesn't work on IE11 and Edge until versions 15,
    // we won't render these indicators for those browsers at all.
    if (!modernizr.csspositionsticky) {
        return null
    }

    return <StickyAggregatedDataRow dataSourceId={dataSourceId} />
}

export default AggregatedDataOnPageBottom
