import styled from '@emotion/styled'

import ReadOnlyText from './ReadOnlyText'
import { useAppTheme } from '../theme/'

const StyledText = styled(ReadOnlyText)`
    margin-left: 4px;
`

const RequiredIndicator = (): JSX.Element => {
    const { colors } = useAppTheme()

    return (
        <StyledText usage="dropdown_label" rawColor={colors.semanticWarning100} testId="required-indicator">
            *
        </StyledText>
    )
}

export default RequiredIndicator
