import { createReducer } from 'typesafe-actions'

import { initializeApplicationAction } from 'action-creators'

const INITIAL_STATE = new Map<string, string>()

const tyyliReducer = createReducer(INITIAL_STATE).handleAction([initializeApplicationAction], (state, action) => {
    return action.payload.tyylit
})

export default tyyliReducer
