import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'

import IDeletionReason from '../Types/IDeletionReason'
import queryDeletionReasons from '../WebApi/queryDeletionReasons'
import DeletionConfirmationDialogBase from './DeletionConfirmationDialogBase'
import { get } from 'lodash-es'

type TDeletionConfirmationDialogBaseProps = React.ComponentProps<typeof DeletionConfirmationDialogBase>

interface IStateProps {
    isOpen: boolean
}

interface IOwnProps {
    areDeletionReasonsDisplayed: boolean
    dialogId: string
    deletionReasonsUrl: string
    onClose: TDeletionConfirmationDialogBaseProps['onClose']
    onSubmit: TDeletionConfirmationDialogBaseProps['onSubmit']
    title?: TDeletionConfirmationDialogBaseProps['title']
    subtitle?: TDeletionConfirmationDialogBaseProps['subtitle']
    confirmButtonText?: TDeletionConfirmationDialogBaseProps['confirmButtonText']
    isLoading?: boolean
}

interface IDeletionConfirmationDialogProps extends IStateProps, IOwnProps {}

const DeletionConfirmationDialog: React.FC<IDeletionConfirmationDialogProps> = ({
    dialogId,
    isOpen,
    deletionReasonsUrl,
    onSubmit,
    onClose,
    title,
    subtitle,
    confirmButtonText,
    isLoading: isLoadingProp = false,
    areDeletionReasonsDisplayed,
}) => {
    // sync state isLoading to isLoadingProp whenever it changes
    useEffect(() => {
        setIsLoading(isLoadingProp)
    }, [isLoadingProp])

    const [deletionReasons, setDeletionReasons] = useState<IDeletionReason[]>([])
    const [isLoading, setIsLoading] = useState(isLoadingProp)
    const [reasonsQueryFailed, setReasonsQueryFailed] = useState(false)
    const [initialValues, setInitialValues] = useState<Record<string, unknown>>({})

    const fetchDeletionReasons = useCallback(async () => {
        setIsLoading(true)

        try {
            const reasons = await queryDeletionReasons(deletionReasonsUrl)
            setDeletionReasons(reasons)

            setInitialValues({
                reason: get(reasons, '[0].id'),
                additionalInfo: '',
            })
            setReasonsQueryFailed(false)
        } catch (e) {
            setReasonsQueryFailed(true)
        } finally {
            setIsLoading(false)
        }
    }, [deletionReasonsUrl])

    useEffect(() => {
        if (areDeletionReasonsDisplayed && deletionReasons.length === 0 && !isLoading) {
            fetchDeletionReasons()
        } else if (!areDeletionReasonsDisplayed && Object.keys(initialValues).length > 0) {
            setInitialValues({})
        }
    }, [fetchDeletionReasons, areDeletionReasonsDisplayed, deletionReasons.length, initialValues, isLoading])

    return (
        <DeletionConfirmationDialogBase
            areDeletionReasonsDisplayed={areDeletionReasonsDisplayed}
            confirmButtonText={confirmButtonText}
            deletionReasons={deletionReasons}
            dialogId={dialogId}
            initialValues={initialValues}
            isLoading={isLoading}
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            reasonsQueryFailed={reasonsQueryFailed}
            subtitle={subtitle}
            title={title}
        />
    )
}

export default DeletionConfirmationDialog
