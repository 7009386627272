import makeRequest, { ERequestMethod } from '../../rest-api'
import INavigationSection from '../Types/INavigationSection'
import INavigationSubsection from '../Types/INavigationSubsection'

export const fetchNavigationSectionsFromServer = async (): Promise<INavigationSection[]> => {
    const navigationSections = await makeRequest<INavigationSection[]>({
        method: ERequestMethod.GET,
        url: '/NavigointiItem/Sections',
    })

    return navigationSections
}

export const fetchNavigationSubsectionsFromServer = async (): Promise<INavigationSubsection[]> => {
    const navigationSubsections = await makeRequest<INavigationSubsection[]>({
        method: ERequestMethod.GET,
        url: '/NavigointiItem/Subsections',
    })

    return navigationSubsections
}

export const fetchStartPage = async (): Promise<INavigationSubsection | null> => {
    const subsection = await makeRequest<INavigationSubsection>({
        method: ERequestMethod.GET,
        url: '/NavigointiItem/Subsections/First',
    })

    return subsection
}
