import * as React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import BasicCalendarNode from '../BasicCalendarNode'

export interface ITapahtumaAggregateCalendarNodeProps {
    backgroundColor: string
    endDatetime: string
    hoverColor: string
    published: boolean
    mainColor: string
    startDatetime: string
    upperText: string
    extraText: string
    warnings: string[] | null
    isOnPlanningPeriod: boolean
}

const StyledText = styled(Typography)`
    line-height: 12px;
    color: #026063;
    font-size: 10px;
    font-weight: 600;
`
const TapahtumaAggregateCalendarNode: React.FunctionComponent<ITapahtumaAggregateCalendarNodeProps> = ({
    backgroundColor,
    hoverColor,
    published,
    upperText,
    extraText,
    warnings,
    mainColor,
    isOnPlanningPeriod,
}) => (
    <BasicCalendarNode
        backgroundColor={backgroundColor}
        extraTextNode={<StyledText>{extraText}</StyledText>}
        hoverColor={hoverColor}
        isBottomRightSignifierDisplayed={isOnPlanningPeriod}
        isCoveredOnLeft={published}
        mainColor={mainColor}
        upperText={upperText}
        warnings={warnings}
    />
)

export default TapahtumaAggregateCalendarNode
