import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { selectOpenListModals } from '../State/ConfigurableListModalSelectors'
import TListModalReducerState from '../Types/TListModalReducerState'
import ConfigurableListModal from './ConfigurableListModal'
import { IConfigurableListModalProps } from '../../configurable-list'

interface IStateProps {
    openListModals: TListModalReducerState
}

interface IListModalProps extends IStateProps {}

const ListModalsManager: React.FC<IListModalProps> = ({ openListModals }) => {
    const listModals = [...openListModals]
        .filter(([, { props }]) => Boolean(props?.listId))
        .map(([modalId, modal]) => {
            const { listId, summaryLabels } = modal.props as IConfigurableListModalProps

            return (
                <ConfigurableListModal
                    key={modalId}
                    listId={listId}
                    modalId={modalId}
                    summaryLabels={summaryLabels ?? []}
                />
            )
        })

    return <React.Fragment>{listModals}</React.Fragment>
}

const mapStateToProps = (state: RootState): IStateProps => ({
    openListModals: selectOpenListModals(state),
})

export default connect(mapStateToProps)(ListModalsManager)
