import * as React from 'react'

import { IDataSourceItem } from '../../../data-source-types'
import IListViewColumnModel from '../../interfaces/IListViewColumnModel'
import Row from './Row'
import { TListIconData } from '../../interfaces/IList'
import { IListViewIconAction } from './../../interfaces/TDisplayAction'
import TFunctionalityAction from '../../interfaces/TFunctionalityAction'
import IListViewAction from '../../interfaces/IListViewAction'
import { IDataSourceAction, TDataSourceId } from '../../../data-source'

type Props = {
    dataItemIds: (string | number)[]
    massSelectable: boolean
    metadataForColumns: IListViewColumnModel[]
    onRowSelect?: (item?: IDataSourceItem) => void
    iconData: TListIconData | undefined
    listId: string
    rowActions: IListViewAction<IListViewIconAction, TFunctionalityAction>[]
    rowsAreSelectable: boolean
    actionConfigurations: IDataSourceAction[]
    dataSourceId: TDataSourceId | null
    hiddenSubrows?: string[]
}

const Rows = ({
    listId,
    dataItemIds,
    massSelectable,
    metadataForColumns,
    onRowSelect,
    iconData,
    rowActions,
    rowsAreSelectable,
    actionConfigurations,
    dataSourceId,
}: Props): JSX.Element => {
    return (
        <>
            {dataItemIds.map((id, index) => (
                <Row
                    actionConfigurations={actionConfigurations}
                    dataSourceId={dataSourceId}
                    firstRow={index === 0}
                    iconData={iconData}
                    isSelectable={rowsAreSelectable}
                    itemId={id}
                    key={id}
                    listId={listId}
                    massSelectable={massSelectable}
                    metadataForColumns={metadataForColumns}
                    onRowSelect={onRowSelect}
                    rowActions={rowActions}
                />
            ))}
        </>
    )
}

export default Rows
