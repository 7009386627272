import { createAction } from 'typesafe-actions'

import INavigationSection from '../Types/INavigationSection'
import INavigationSubsection from '../Types/INavigationSubsection'

export const setNavigationSectionsAction = createAction('navigation.NavigationSectionsSet')<INavigationSection[]>()
export const setNavigationSubsectionsAction = createAction('navigation.NavigationSubsectionsSet')<
    INavigationSubsection[]
>()

export const setCurrentPageAction = createAction('navigation.SetCurrentPage')<string>()
