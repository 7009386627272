import * as React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import { TDataSourceId } from '../Types/IDataSource'
import AggregatedData from './AggregatedData'
import { IDataSourceAggregatedDataRow } from '../../data-source-types'
import { SideWaysMoving } from '../../generic-components'

interface IOwnProps {
    dataSourceId: TDataSourceId | null
    className?: string
}

export interface IAggregatedDataRowProps extends IOwnProps {}

const DataItem = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 48px;
`

const DataItemText = styled(Typography)`
    color: ${({ theme }) => theme.colors.neutralsGrey60};
    font-style: normal;
`

const LabelText = styled(DataItemText)`
    margin-right: 8px;
`

interface IAggregatedDataRowRenderedProps {
    aggregatedData: IDataSourceAggregatedDataRow[]
    className?: string
}

const AggregatedDataRowRendered: React.FC<IAggregatedDataRowRenderedProps> = ({ className, aggregatedData }) => {
    return (
        <SideWaysMoving className={className}>
            {aggregatedData.map(({ Label, Unit, Value }) => (
                <DataItem key={Label + Unit}>
                    <LabelText variant="bodyM">{Label}</LabelText>
                    <DataItemText variant="bodyM">{`${Value} ${Unit}`}</DataItemText>
                </DataItem>
            ))}
        </SideWaysMoving>
    )
}

/**
 * Renders dataSource's aggregated data vertically in a row.
 */
export const AggregatedDataRow: React.FunctionComponent<IAggregatedDataRowProps> = ({ className, dataSourceId }) => {
    return (
        <AggregatedData
            dataSourceId={dataSourceId}
            renderData={(aggregatedData) => (
                <AggregatedDataRowRendered aggregatedData={aggregatedData} className={className} />
            )}
        />
    )
}

export default AggregatedDataRow
