import * as React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'

import { TranslatedButton } from '../../../generic-components'
import ColumnManagementForm from './ColumnManagementForm'
import EConfigurableListModal from '../../Types/EConfigurableListModal'
import { FormModal, selectIsModalOpen } from '../../../modal'
import {
    resetUserCustomSettingsForColumnsThunk,
    saveSettingsForColumnsThunk,
} from '../../Thunks/ColumnManagementThunks'
import { selectListConfigurationForColumns } from '../../State/ConfigurableListSelectors'
import IListViewColumnModel from '../../interfaces/IListViewColumnModel'
import IListViewUserSettingsForColumns from '../../interfaces/IListViewUserSettingsForColumns'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

export interface IColumnManagementModalOwnProps {
    listId: string
}

export interface IColumnManagementModalProps extends IColumnManagementModalOwnProps {}

const FormContainer = styled.div`
    margin: 30px 0;
`

export const modalTitle = 'configurable-list.ColumnManagementModal.EditColumns'

const compileColumnUserSettings = (columnData: IListViewColumnModel[] | null): IListViewUserSettingsForColumns => {
    if (!columnData) {
        return {}
    }

    return columnData.reduce((acc, currentColumn) => {
        const { Order, Width, Visible, Id, Header, Pinned } = currentColumn

        acc[Id] = {
            Width,
            ColumnOrder: Order,
            Visible,
            ListViewColumnId: Id,
            Header,
            Pinned,
        }

        return acc
    }, {} as IListViewUserSettingsForColumns)
}

const ColumnManagementModal = ({ listId }: IColumnManagementModalProps): JSX.Element | null => {
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>()

    const open = useSelector((state: RootState) =>
        selectIsModalOpen(state, `${EConfigurableListModal.ColumnManagement}-${listId}`)
    )

    const columnSettings = useSelector((state: RootState) => selectListConfigurationForColumns(state, listId))

    const handleFormSubmission = (initialValues: IListViewUserSettingsForColumns) => {
        return async (columnData: IListViewUserSettingsForColumns) =>
            await dispatch(saveSettingsForColumnsThunk(listId, columnData, initialValues))
    }

    const resetDefaultSettingsForColumns = async () => {
        await dispatch(resetUserCustomSettingsForColumnsThunk(listId))
    }

    if (!open || columnSettings.length === 0) {
        return null
    }

    const initialValues = compileColumnUserSettings(columnSettings)

    const formikProps = {
        initialValues,
        onSubmit: handleFormSubmission(initialValues),
    }

    const resetDefaultColumnSettings = (
        <TranslatedButton onClick={resetDefaultSettingsForColumns} variant="outlined">
            configurable-list.ColumnManagementModal.resetDefaultColumnSettingsButton
        </TranslatedButton>
    )

    return (
        <FormModal
            extraButtons={resetDefaultColumnSettings}
            formId="columnManagementModalForm"
            formikProps={formikProps}
            modalId={`${EConfigurableListModal.ColumnManagement}-${listId}`}
            open={columnSettings && open}
            title={modalTitle}
        >
            {({ setValues, values }) => (
                <FormContainer>
                    <ColumnManagementForm values={values} setValues={setValues} />
                </FormContainer>
            )}
        </FormModal>
    )
}

export default ColumnManagementModal
