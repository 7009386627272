import * as React from 'react'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

interface IProgressProps extends CircularProgressProps {}

const Animation = styled(CircularProgress)`
    display: inline-block;
    position: relative;
    z-index: 0;
`

/**
 * Komponentti jonka voi pistää placeholderiks kun ladataan tietoa
 */
const Progress: React.FunctionComponent<IProgressProps> = ({ size = 40, ...circularProgressProps }) => (
    <Animation {...circularProgressProps} size={size} />
)

Progress.propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Progress
