import { createReducer } from 'typesafe-actions'

import { setTyovuorolistapaketti } from '../actions/TyovuorolistapakettiActions'
import ITyovuorolistapaketti from '../types/ITyovuorolistapaketti'

export interface ITyovuorolistapaketitState {
    readonly tyovuorolistapaketit: ITyovuorolistapaketti[]
}

export const INITIAL_STATE: ITyovuorolistapaketitState = {
    tyovuorolistapaketit: [],
}

const tyovuorolistapakettiReducer = createReducer(INITIAL_STATE).handleAction(
    setTyovuorolistapaketti,
    (state, action) => {
        const tyovuorolistapaketit = action.payload

        return {
            ...state,
            tyovuorolistapaketit,
        }
    }
)

export default tyovuorolistapakettiReducer
