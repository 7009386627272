import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel'
import * as React from 'react'
import Switch, { SwitchProps } from '@material-ui/core/Switch'

export interface IStyledToggleProps extends Partial<FormControlLabelProps> {
    label: FormControlLabelProps['label']
    switchProps?: SwitchProps
}

const StyledToggle: React.FunctionComponent<IStyledToggleProps> = ({ switchProps, ...passProps }) => (
    <FormControlLabel {...passProps} control={<Switch {...switchProps} />} />
)

export default StyledToggle
