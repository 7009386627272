export const tyovuorolistapakettiValuePickerIds = [
    'Tyovuorolistapaketti.valuePicker.dateRange',
    'Tyovuorolistapaketti.valuePicker.area',
    'Tyovuorolistapaketti.valuePicker.organization',
    'Tyovuorolistapaketti.valuePicker.personsInCharge',
    'Tyovuorolistapaketti.valuePicker.customer',
    'Tyovuorolistapaketti.valuePicker.toimiala',
    'Tyovuorolistapaketti.valuePicker.ammattinimike',
    'Tyovuorolistapaketti.valuePicker.tyosopimustyyppi',
]
