import IListViewColumnModel from '../interfaces/IListViewColumnModel'
import IListViewAction from '../interfaces/IListViewAction'
import { IListViewIconAction } from '../interfaces/TDisplayAction'
import TFunctionalityAction from '../interfaces/TFunctionalityAction'
import {
    ROW_CHECKBOX_RIGHT_MARGIN,
    ROW_CHECKBOX_WIDTH,
    ROW_ELEMENTS_LEFT_MARGIN,
    ROW_ELEMENTS_MARGIN_PER_COLUMN,
    ROW_ICON_BUTTON_MARGINS,
    ROW_ICON_BUTTON_WIDTH,
    ROW_ICON_BUTTONS_MIN_WIDTH,
    ROW_LEFT_PADDING,
} from '../Constants/RowConstants'
import FontSizeUtility from 'utilities/FontSizeUtility'
import { memoize } from 'lodash-es'

const calculateWidthForVisibleColumns = memoize((columns: IListViewColumnModel[]) => {
    const htmlElementFontSize = FontSizeUtility.getFontSizeAsNumber()

    return columns
        .filter((column) => column.Visible)
        .reduce((totalWidth, column) => {
            return totalWidth + ROW_ELEMENTS_MARGIN_PER_COLUMN + column.Width * htmlElementFontSize
        }, 0)
})

const calculateRowActionWidths = (rowActions: IListViewAction<IListViewIconAction, TFunctionalityAction>[]) => {
    const rowActionWidths = rowActions.length * (ROW_ICON_BUTTON_MARGINS + ROW_ICON_BUTTON_WIDTH)

    return rowActionWidths > ROW_ICON_BUTTONS_MIN_WIDTH ? rowActionWidths : ROW_ICON_BUTTONS_MIN_WIDTH
}

const getCheckboxWidth = (listIsSelectable: boolean) => {
    return listIsSelectable ? ROW_CHECKBOX_WIDTH + ROW_CHECKBOX_RIGHT_MARGIN : 0
}

export const calculateRowWidth = (
    columns: IListViewColumnModel[],
    rowActions: IListViewAction<IListViewIconAction, TFunctionalityAction>[],
    listIsSelectable: boolean
): number => {
    const columnWidths = calculateWidthForVisibleColumns(columns)
    const rowActionWidths = calculateRowActionWidths(rowActions)
    const checkboxWidth = getCheckboxWidth(listIsSelectable)

    const requiredWidth = ROW_LEFT_PADDING + checkboxWidth + ROW_ELEMENTS_LEFT_MARGIN + columnWidths + rowActionWidths

    return requiredWidth
}
