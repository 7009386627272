import { changePassword } from '../../WebApi/PasswordChangeWebApi'
import { PASSWORD_CHANGE_IN_PROGRESS_OPERATION_ID, PASSWORD_MODAL_ID } from '../../Constants/PasswordChangeConstants'
import { IThunkBaseAction } from '../../../../../generic-state'
import {
    asyncOperationStartedAction,
    asyncOperationFailedWithErrorDisplayThunk,
    asyncOperationSucceededWithSuccessDisplayThunk,
} from '../../../../../async-operation'
import { closeModalAction } from '../../../../../modal'

export const changePasswordThunk =
    (currentPassword: string, newPassword: string): IThunkBaseAction =>
    async (dispatch) => {
        dispatch(asyncOperationStartedAction(PASSWORD_CHANGE_IN_PROGRESS_OPERATION_ID))

        try {
            await changePassword(currentPassword, newPassword)

            dispatch(closeModalAction(PASSWORD_MODAL_ID))
            dispatch(
                asyncOperationSucceededWithSuccessDisplayThunk(
                    'password-change.ChangeSucceeded.Message',
                    PASSWORD_CHANGE_IN_PROGRESS_OPERATION_ID
                )
            )
        } catch (error) {
            await dispatch(
                asyncOperationFailedWithErrorDisplayThunk(
                    error,
                    PASSWORD_CHANGE_IN_PROGRESS_OPERATION_ID,
                    'password-change.ChangeFailed.Message'
                )
            )
        }
    }
