import { RefObject, useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash-es'

type Size = { width: number; height: number }

const useResizeObserver = (elementRef: RefObject<HTMLElement>): Size => {
    const observerRef = useRef<ResizeObserver | null>(null)
    const [elementSize, setElementSize] = useState<Size>({ width: 0, height: 0 })

    useEffect(() => {
        if (!elementRef.current || Boolean(observerRef.current) || !window.ResizeObserver) {
            return
        }

        const callback: ResizeObserverCallback = (entries) => {
            if (!elementRef.current) {
                return
            }

            window.requestAnimationFrame(() => {
                if (isEmpty(entries)) {
                    return
                }

                const { width, height } = entries[0].contentRect
                setElementSize({ width, height })
            })
        }

        const observer = new ResizeObserver(callback)

        observerRef.current = observer

        observer.observe(elementRef.current)

        return () => observer.disconnect()
    }, [elementRef, observerRef])

    return elementSize
}

export default useResizeObserver
