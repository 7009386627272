import PropTypes from 'prop-types'
import * as React from 'react'
import reactCss from 'reactcss'

import Styles from 'constants/Styles'
import { Translation } from '../../../localization'

export interface IAbstractTitleProps {
    children: string
    color?: string
    context?: string
    doNotTranslate?: boolean
    doNotTranslateSubHeading?: boolean
    translationParameters?: any[] | Record<string, unknown>
    icon?: string
    margin?: string
    subHeading?: string
}

/**
 * Kaikkien otsikokomponenttien kantaluokka.
 *
 * Vastaa otsikoiden yleisestä asettelusta. Yliaja ainakin styles()-metodi
 * aliluokassa ja aseta oikeat fonttikoot ja -värit.
 *
 * Anna otsikon käännöskoodi tai teksti elementin sisällä.
 * Anna mahdollisen ikonin nimi icon-propsissa. Käytettävissä ovat Googlen
 * material iconit.
 * Anna mahdollisen aliotsikon käännöskoodi tai teksti subHeading-propsissa.
 * Anna doNotTranslate-lippu, jos et käytä käännöskoodeja.
 */
export default class AbstractTitle<IProps extends IAbstractTitleProps> extends React.Component<IProps> {
    static defaultProps: any
    static propTypes: any

    protected styles(): any {
        return reactCss(
            {
                default: {
                    heading: {
                        color: this.props.color,
                        fontFamily: Styles.font.levelOneTitle.family,
                        fontSize: Styles.font.levelOneTitle.size,
                        fontStyle: 'italic',
                        margin: this.props.margin,
                        fontWeight: 'normal',
                    },
                    icon: {
                        fontSize: Styles.font.levelOneTitle.size,
                        margin: this.props.margin,
                    },
                    subHeading: {
                        color: 'rgba(109, 110, 113, 0.5)',
                        fontFamily: Styles.font.levelOneTitle.family,
                        fontSize: Styles.font.levelOneTitle.size,
                        fontStyle: 'italic',
                    },
                },
            } as any,
            this.props
        )
    }

    private renderIcon() {
        if (!this.props.icon) {
            return undefined
        }
        return (
            <span className="material-icons" style={this.styles().icon}>
                {this.props.icon}
            </span>
        )
    }

    private renderHeading() {
        const heading = this.props.doNotTranslate
            ? this.props.children
            : Translation.translateKey(this.props.children, this.props.translationParameters)
        return <span style={this.styles().heading}>{heading}</span>
    }

    private renderSubHeading() {
        if (!this.props.subHeading) {
            return undefined
        }

        const subHeading =
            this.props.doNotTranslate || this.props.doNotTranslateSubHeading
                ? this.props.subHeading
                : Translation.translateKey(this.props.subHeading as string)
        return <span style={this.styles().subHeading}>{subHeading}</span>
    }

    render(): React.ReactNode {
        return (
            <span style={this.styles().root}>
                {this.renderIcon()}
                {this.renderHeading()}
                {this.renderSubHeading()}
            </span>
        )
    }
}

AbstractTitle.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
        PropTypes.string,
    ]).isRequired,
    color: PropTypes.string,
    context: PropTypes.string,
    doNotTranslate: PropTypes.bool,
    doNotTranslateSubHeading: PropTypes.bool,
    icon: PropTypes.string,
    margin: PropTypes.string,
    subHeading: PropTypes.string,
    translationParameters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

AbstractTitle.defaultProps = {
    doNotTranslate: false,
    doNotTranslateSubHeading: false,
    color: Styles.mainColor.darkGrey,
    margin: `1rem ${Styles.layout.defaultMargin} 0 0`,
}
