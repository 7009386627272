import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import { alueValuePickerConfiguration } from './AlueValuePickerConfiguration'

const tyontekijaAlueValuePickers: TValuePickerConfiguration[] = [
    {
        ...alueValuePickerConfiguration,
        ValuePickerId: 'TyontekijaAlue_Alue',
    },
    {
        ...alueValuePickerConfiguration,
        ValuePickerId: 'TyontekijaAlue_Form_Alue',
    },
]

export default tyontekijaAlueValuePickers
