import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import Grid, { GridProps } from '@material-ui/core/Grid'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'

import EValuePickerType from '../../types/EValuePickerType'
import TValuePickerId from '../../types/TValuePickerId'
import { TValuePickerConfiguration } from '../../types/TValuePickerConfiguration'
import { selectValuePickerConfigurations } from '../../State/ValuePickerSelectors'
import VisibilityToggleButton from './VisibilityToggleButton'
import useAllValuePickersFitFirstRow from '../../Hooks/useAllValuePickersFitFirstRow'
import { isEmpty } from 'lodash-es'
import { ValuePickerRenderer } from '../../index'

interface IStateProps {
    valuePickerConfigurations: TValuePickerConfiguration[]
}

interface IOwnProps {
    valuePickerIds: TValuePickerId[]
    isFullWidth?: boolean
    visibilityTogglable?: boolean
    fetchContentButton?: React.ReactNode
    onAfterVisibilityWasToggled?: () => void
}

export interface IValuePickerGroupProps extends IStateProps, IOwnProps {}

interface IContainerGridPropsOwnProps {
    isFullWidth: boolean
    limitHeightToFirstLine: boolean
}

const valuePickerMaxWidth = 209
const dropdownWidth = 200

// eslint-disable-next-line react/display-name
const GridWithoutCertainProps = React.forwardRef<HTMLDivElement, IContainerGridPropsOwnProps & GridProps>(
    ({ ...passProps }, ref) => {
        return <Grid {...passProps} ref={ref} />
    }
)

// The 'width: 100vw' prevents the scroll bar from messing things with the width.
// Without it, the value pickers might jump around unexpectedly when the scroll bar appears and disappears.
const ValuePickersContainerGrid = styled(GridWithoutCertainProps, {
    shouldForwardProp: (propName) => propName !== 'limitHeightToFirstLine' && propName !== 'isFullWidth',
})`
    padding: 0 15px 15px 0;
    margin-left: inherit;
    padding-left: inherit;
    max-width: 1980px;
    display: inline-flex;

    ${({ isFullWidth }) =>
        isFullWidth &&
        `
    /* The negative margins could be dropped if this used a normal div instead of Grid  */
        width: 100vw;
        margin-left: -22px;
        padding-left: 30px;
    `}

    ${({ limitHeightToFirstLine }) =>
        limitHeightToFirstLine &&
        `
        height: 76px;
        overflow: hidden;
    `}
`

interface IGridItemProps extends React.ComponentProps<typeof Grid> {
    valuePickerType: EValuePickerType
}
const GridItem = styled<React.FC<IGridItemProps>>(({ ...rest }) => <Grid {...rest} />, {
    shouldForwardProp: (propName) => propName !== 'valuePickerType',
})`
    margin-top: ${({ valuePickerType }) => (valuePickerType === EValuePickerType.Dropdown ? '33px' : '-8px')};
    max-width: ${valuePickerMaxWidth}px;
`

const ButtonsContainer = styled.div<{ isFiltersHeightLimitedToFirstLine: boolean }>`
    margin-right: 31px;
    margin-left: auto;

    ${({ isFiltersHeightLimitedToFirstLine }) =>
        isFiltersHeightLimitedToFirstLine &&
        `
        position: absolute;
        right: 0;
    `}
`

const ValuePickersAndFilterTextContainerGrid = styled.div<{
    isFetchContentButtonRendered: boolean
    isFiltersHeightLimitedToFirstLine: boolean
    isMoreFiltersButtonRendered: boolean
}>`
    display: flex;
    padding-top: 10px;
    ${({ isFetchContentButtonRendered, isFiltersHeightLimitedToFirstLine, isMoreFiltersButtonRendered }) => {
        if (!isFiltersHeightLimitedToFirstLine || (!isFetchContentButtonRendered && !isMoreFiltersButtonRendered)) {
            return null
        }
        const widthReservedForButtons = isFetchContentButtonRendered && isMoreFiltersButtonRendered ? '330px' : '200px'
        return `
            max-width: calc(100% - ${widthReservedForButtons});
        `
    }}
`

export const ValuePickerGroupUnconnected: React.FC<IValuePickerGroupProps> = ({
    valuePickerConfigurations,
    isFullWidth = true,
    visibilityTogglable = false,
    fetchContentButton,
    onAfterVisibilityWasToggled,
}) => {
    const [allVisible, setAllVisible] = useState(false)
    const valuePickerContainerRef = useRef<HTMLDivElement>(null)

    const doAllValuePickersFitFirstRow = useAllValuePickersFitFirstRow(
        valuePickerConfigurations,
        valuePickerContainerRef
    )

    useEffect(() => {
        onAfterVisibilityWasToggled && onAfterVisibilityWasToggled()
    }, [allVisible, onAfterVisibilityWasToggled])

    if (isEmpty(valuePickerConfigurations)) {
        return null
    }

    const handleSetAllFiltersVisible = () => {
        setAllVisible(!allVisible)
    }

    const valuePickers = valuePickerConfigurations.map(({ ValuePickerId, ValuePickerType, ...other }) => (
        <GridItem item key={ValuePickerId} valuePickerType={ValuePickerType}>
            <ValuePickerRenderer
                dropdownMenuWidth={dropdownWidth}
                dropdownWidth={dropdownWidth}
                useCachedValuesForOptions
                valuePickerId={ValuePickerId}
                ValuePickerType={ValuePickerType}
                fallbackComponent="ValuePicker"
                {...other}
            />
        </GridItem>
    ))

    const limitHeightToFirstLine = visibilityTogglable ? !allVisible : false

    const isFilterVisibilityToggleButtonRendered = visibilityTogglable && !doAllValuePickersFitFirstRow
    const isFetchContentButtonRendered = Boolean(fetchContentButton)

    return (
        <div>
            <ValuePickersAndFilterTextContainerGrid
                isFetchContentButtonRendered={isFetchContentButtonRendered}
                isFiltersHeightLimitedToFirstLine={limitHeightToFirstLine}
                isMoreFiltersButtonRendered={isFilterVisibilityToggleButtonRendered}
            >
                <ValuePickersContainerGrid
                    alignItems="flex-end"
                    container
                    isFullWidth={isFullWidth}
                    limitHeightToFirstLine={limitHeightToFirstLine && !doAllValuePickersFitFirstRow}
                    ref={valuePickerContainerRef}
                    spacing={2}
                >
                    {valuePickers}

                    {(isFetchContentButtonRendered || isFilterVisibilityToggleButtonRendered) && (
                        <ButtonsContainer isFiltersHeightLimitedToFirstLine={limitHeightToFirstLine}>
                            {fetchContentButton}
                            <VisibilityToggleButton
                                allValuePickersVisible={allVisible}
                                onClick={handleSetAllFiltersVisible}
                                valuePickersVisibilityToggable={isFilterVisibilityToggleButtonRendered}
                            />
                        </ButtonsContainer>
                    )}
                </ValuePickersContainerGrid>
            </ValuePickersAndFilterTextContainerGrid>
        </div>
    )
}

const mapStateToProps = (state: RootState, { valuePickerIds }: IOwnProps): IStateProps => ({
    valuePickerConfigurations: selectValuePickerConfigurations(state, valuePickerIds),
})

export default connect(mapStateToProps)(ValuePickerGroupUnconnected)
