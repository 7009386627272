import * as React from 'react'
import { FieldProps } from 'formik'

import TimeLength, { ITimeLengthProps } from './TimeLength'
import ErrorableField from '../../Form/ErrorableField'

interface IFormikTimeLengthOwnProps extends FieldProps {}

type IFormikTimeLengthProps = IFormikTimeLengthOwnProps & Pick<ITimeLengthProps, 'disabled'>

const FormikTimeLength: React.FunctionComponent<IFormikTimeLengthProps> = (props) => {
    const handleChange = (minutes: number) => {
        const {
            form: { setFieldValue },
            field: { name },
        } = props

        setFieldValue(name, minutes)
    }

    const { form, field } = props

    return (
        <ErrorableField field={field} form={form}>
            <TimeLength {...props} handleChange={handleChange} valueInMinutes={props.field.value} />
        </ErrorableField>
    )
}

export default FormikTimeLength
