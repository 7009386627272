import IFetchDataSourceDataOptions from '../Types/IFetchDataSourceDataOptions'

export const DEFAULT_OVERRIDE_REQUEST_PARAMETERS: Exclude<
    IFetchDataSourceDataOptions['dynamicRequestParameters'],
    undefined
> = {
    overridePerField: false,
    parameters: {},
    overrideEverything: false,
}
