import { TDataSourceItemId } from '../../data-source-types'
import IMessageThread, { EMessageType } from '../Types/IMessageThread'
import { INewMessageForm } from '../Types/IMessagingState'
import INewMessage from '../Types/INewMessage'
import { IEmployeeDataSourceItem } from '../Types/IEmployeeDataSourceItem'
import { IEvent } from '../../event'
import { IMessagingDataSourceItem } from '../Types/IMessagingDataSourceItem'

export const isSelectedIdsEmployees = (
    ids: IEmployeeDataSourceItem | IEvent | IMessagingDataSourceItem
): ids is IEmployeeDataSourceItem => {
    return 'Etunimi' in ids && 'Sukunimi' in ids
}

export const isSelectedIdsEvents = (
    ids: IEmployeeDataSourceItem | IEvent | IMessagingDataSourceItem | IMessageThread
): ids is IEvent => {
    return 'StartTime' in ids && 'EndTime' in ids && 'WorkUnit' in ids && 'Employee' in ids
}

export const isSelectedIdsMessages = (
    ids: IEmployeeDataSourceItem | IEvent | IMessagingDataSourceItem
): ids is IMessagingDataSourceItem => {
    return 'LatestMessage' in ids && 'Employee' in ids
}

export const isSelectedIdsMessageThreads = (
    id: IEvent | IMessageThread | IMessagingDataSourceItem
): id is IMessageThread => {
    return 'MessageCount' in id && 'Event' in id
}

export const createNewMessageThreadsForEmployeeIds = (
    form: INewMessageForm,
    EmployeeIds: TDataSourceItemId[]
): { Data: INewMessage }[] => {
    return [
        {
            Data: {
                Content: form.message ?? '',
                Title: form.title ?? '',
                Type: form.messageType ?? EMessageType.MobileMessage,
                SendByEmail: form.sendEmail ?? false,
                EmployeeIds,
            },
        },
    ]
}
